import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NebularModule } from '@InfoSlips/nebular';
import { AuthGuard, SharedModule } from '@InfoSlips/shared';
import { AdminShellComponent } from './containers/admin-shell/admin-shell.component';
import { UsersContainer } from './containers/users/users.component';
import { UsersComponent } from './components/users/users.component';
import { CustomersContainer} from './containers/customers/customers.component';
import { CustomersComponent } from './components/customers/customers.component';
import { TemplatesContainer } from './containers/templates/templates.component';
import { TemplatesCrudComponent } from './components/templates-crud/templates-crud.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { PreProcessorComponent } from './components/pre-processor/pre-processor.component';
import { PostProcessorComponent } from './components/post-processor/post-processor.component';
import { PreviewTemplateComponent } from './components/preview-template/preview-template.component';
import { SmsTemplateComponent } from './components/sms-template/sms-template.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { ProcessorCrudComponent } from './components/processor-crud/processor-crud.component';
import { ControlsModule } from '@InfoSlips/controls';
import { CmsModule } from '@InfoSlips/cms';
import { RunSearchDataComponent } from './components/run-search-data/run-search-data.component';
import { RecipientsModule } from '@InfoSlips/recipients';
import { RecipientsComponent } from './components/recipients/recipients.component';
import MonacoConfig from 'libs/shared/src/lib/monacoConfig';
import { QueueStatusComponent } from './components/queue-status/queue-status.component';
import { RunSearchDataCrudComponent } from './components/run-search-data-crud/run-search-data-crud.component';
import { DigitalSignaturesComponent } from './components/digital-signatures/digital-signatures.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RecipientBlacklistComponent } from './components/recipient-blacklist/recipient-blacklist.component';
import { TestingRestrictionsComponent } from './components/testing-restrictions/testing-restrictions.component';

export const iiabAdminRoutes: Route[] = [
  {
    path: '',
    component: AdminShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        component: UsersContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'customers',
        component: CustomersContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'users/:id',
        component: UsersContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'customers/:id',
        component: CustomersContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'templates',
        component: TemplatesContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'templates/:id',
        component: TemplatesContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'runtemplates',
        component: TemplatesContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'runtemplates/:id',
        component: TemplatesContainer,
        canActivate: [AuthGuard]
      },
      {
        path: 'preprocessor',
        component: ProcessorCrudComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'preprocessor/:id',
        component: ProcessorCrudComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'postprocessor',
        component: ProcessorCrudComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'postprocessor/:id',
        component: ProcessorCrudComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tools/run-search-data/:runTemplateId',
        component: RunSearchDataComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recipients/:id',
        component: RecipientsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'run-recipients/:id',
        component: RecipientsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tools/queue-status',
        component: QueueStatusComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recipient-blacklist',
        component: RecipientBlacklistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tools/testing-restrictions/:id',
        component: TestingRestrictionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tools/testing-restrictions',
        component: TestingRestrictionsComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  // { 
  //   path: 'users', 
  //   component: null, 
  //   outlet: 'admin'
  // },
  // { 
  //   path: 'companies', 
  //   component: null, 
  //   outlet: 'admin'
  // },
];

@NgModule({
  imports: [
    CommonModule, 
    RouterModule,
    NebularModule,
    FormsModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    EditorModule,
    MonacoEditorModule.forRoot(MonacoConfig),
    SharedModule,
    ControlsModule,
    CmsModule,
    RecipientsModule  
  ],
  declarations: [AdminShellComponent, UsersContainer, UsersComponent, CustomersContainer, CustomersComponent, TemplatesContainer, PreProcessorComponent, PostProcessorComponent, PreviewTemplateComponent, SmsTemplateComponent, EmailTemplateComponent, ProcessorCrudComponent, TemplatesCrudComponent, RunSearchDataComponent, RecipientsComponent, QueueStatusComponent, RunSearchDataCrudComponent, DigitalSignaturesComponent, RecipientBlacklistComponent, TestingRestrictionsComponent],
})
export class IiabAdminModule {}
