import { PersonalDetailsService } from '@InfoSlips/iiab-api';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TwoFAService } from '@InfoSlips/iiab-auth';
@Component({
  selector: 'ifs-security-form',
  templateUrl: './security-form.component.html',
  styleUrls: ['./security-form.component.css']
})
export class SecurityFormComponent implements OnInit {

  profileModel;
  verificationCode;
  otpInfo;
  otpInfoEnabled;
  securityForm: NgForm;
  authenticator = false;

  constructor(
    private personalDetailService: PersonalDetailsService, 
    private otpService: TwoFAService
  ) {}

  ngOnInit() {
    this.profileModel = this.personalDetailService
      .getProfile()
      .subscribe((data: any) => {
        this.authenticator = data.OtpEnabled;
      });
  }

  save(securityForm: NgForm) {
    if(securityForm.value.verificationCode === ''){
      return
    }
    
    this.otpService.saveOtp({
        OtpEnabled: true,
        VerificationCode: securityForm.value.verificationCode 
    });
  }

  switch(){
    this.authenticator = !this.authenticator;

    this.otpService.toggleOtp(this.authenticator).subscribe(res=>{
      this.otpService.getOtpInfo().subscribe(data => {
        this.otpInfo = data;
      });
    });
  }
}