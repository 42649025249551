import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  Component,
  OnInit,
  Input
} from '@angular/core';

@Component({
  selector: 'ifs-verify-detail-form',
  templateUrl: './verify-detail-form.component.html',
  styleUrls: ['./verify-detail-form.component.scss']
})
export class VerifyDetailFormComponent implements OnInit {

  @Input() verified: boolean;

  constructor() {}

  ngOnInit() {}
}
