<div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Run</th>
        <th>Customer</th>
        <th>Billing Group</th>
        <th>Billed</th>
        <th>Run Status</th>
        <th>New Recipients</th>
        <th>Total Run Recipients</th>
        <th>Total Attachment Count</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of (reportDetails$ | async)">
        <td><a [routerLink]="'../../../run/' + field?.run.id">{{ field?.run.name }}</a></td>
        <td><a [routerLink]="'../../../admin/customers/' + field?.customer.id">{{ field?.customer.name }}</a></td>
        <td>{{ field?.billingGroup }}</td>
        <td>{{ field?.billed }}</td>
        <td>{{ field?.runStatus }}</td>
        <td>{{ field?.newRecipients }}</td>
        <td>{{ field?.totalRunRecipients }}</td>
        <td>{{ field?.totalAttachmentCount }}</td>
      </tr>
    </tbody>
  </table>

  <div class="row action-buttons">
    <div>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasPreviousPage" nbButton (click)="pageReport('previous')">Previous</button>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasNextPage"  nbButton (click)="pageReport('next')">Next</button>
    </div>
    <p>{{(reportDetailsPaging$ | async)?.totalCount}} Items</p>
  </div>
</div>