import { Component, Input, OnInit } from '@angular/core';
import { MonacoEditorConfig } from 'libs/dashboard/src/lib/monacoConfig';

@Component({
  selector: 'ifs-admin-sms-template',
  templateUrl: './sms-template.component.html',
  styleUrls: ['./sms-template.component.scss']
})
export class SmsTemplateComponent implements OnInit {

  @Input('template') template;
  @Input('isSmsEnabled') isSmsEnabled: boolean = false;
  @Input() isNew = false;

  constructor(
    public monacoConfig: MonacoEditorConfig
  ) { }

  ngOnInit(): void {
    if (this.template && this.template.SmsTemplate) {
      if (typeof this.template.SmsTemplate.Body === 'undefined' || this.template.SmsTemplate.Body === null) {
          this.template.SmsTemplate.CharacterCount = 0;
          this.template.SmsTemplate.SmsCount = 0;
      } else {
        this.calculateSms();
      }
  }
  }
  
  onChange(event){
    this.template.SmsTemplate.Body = event;
    this.calculateSms();
  }

  calculateSms(){
    this.template.SmsTemplate.CharacterCount = this.template.SmsTemplate.Body.replace(/@Model[A-Za-z.]*/g, '').length;

    let additonalCharRequired = this.template.SmsTemplate.CharacterCount;
    const headerToAdd = Math.ceil((this.template.SmsTemplate.Body.replace(/@Model[A-Za-z.]*/g, '').length / 160)) -1;

    additonalCharRequired += headerToAdd * 10;
    this.template.SmsTemplate.SmsCount = Math.ceil((additonalCharRequired / 160));
  }
}
