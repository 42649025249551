<div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Run Id</th>
        <th>Recipient Id</th>
        <th>Sequence</th>
        <th>User Agent</th>
        <th>Coordinates</th>
        <th>Html Element Id</th>
        <th>Parent Html Element Id</th>
        <th>Label</th>
        <th>Tags</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of (reportDetails$ | async)">
        <td>{{ field?.created }}</td>
        <td><a [routerLink]="'../../../run/' + field?.runId">{{ field?.runId }}</a></td>
        <td>{{ field?.recipientId }}</td>
        <td>{{ field?.sequence }}</td>
        <td>{{ field?.userAgent }}</td>
        <td>{{ field?.coordinates | json }}</td>
        <td>{{ field?.htmlElementId }}</td>
        <td>{{ field?.parentHtmlElementId }}</td>
        <td>{{ field?.label }}</td>
        <td>{{ field?.tags?.toString() }}</td>
        <td>{{ field?.action }}</td>
      </tr>
    </tbody>
  </table>

  <div class="row action-buttons">
    <div>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasPreviousPage" nbButton (click)="pageReport('previous')">Previous</button>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasNextPage"  nbButton (click)="pageReport('next')">Next</button>
    </div>
    <p>{{(reportDetailsPaging$ | async)?.totalCount}} Items</p>
  </div>
</div>