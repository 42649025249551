<h6 *ngIf="loadingRecipients == true">Loading <nb-icon 
    icon="loading-half" 
    pack="oakmoon" 
    class="rotate"
  ></nb-icon>
</h6>

<ng-container *ngIf="!loadingRecipients">
    <ng-container *ngIf="recipientData | async as runRecipients">
        <div class="row no-margin scroll p-10"
            infiniteScroll
            [infiniteScrollDistance]="1" 
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false" 
            (scrolled)="onScroll()" >
            <div *ngFor="let recipient of runRecipients" class="col-md-6">
                <ifs-recipient [recipientType]="recipientType" [recipientData]="recipient" [selectedRunService]="selectedRunService" [allowActions]="allowActions"></ifs-recipient>
            </div>
        </div>
    </ng-container>
</ng-container>