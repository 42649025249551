import { CustomerReference } from "./customer";
import { IiabEntity } from "./iiab-reference";

export interface TemplateSummary extends IiabEntity {
    customer:      CustomerReference;
}

export interface TemplateSourceFile{
    name: string,
    icon: string,
    ext: string 
}