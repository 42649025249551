<form #securityForm="ngForm" (ngSubmit)="save(securityForm)">
  <div class="form-block">
    <h3>Manage Additional Security</h3>
    <div class="form-group">
      <nb-toggle (change)="switch()" [checked]="authenticator" labelPosition="start">Enable Google authenticator</nb-toggle>
    </div>
    <ng-container *ngIf="authenticator">
      <img src="{{otpInfo?.Url}}" alt="" />
      <p>
        <strong>Key: {{ otpInfo?.Code }}</strong>
      </p>
      <p>
        Scan this QR Code with your app or alternatively, you can enter the
        key manually. Once you have your verification code, please fill it
        in below to enable your additional security.
      </p>
      <div class="form-group">
        <label class="label">Verification code</label><br />
        <input nbInput fullWidth fieldSize="medium" type="text" name="verificationCode" required placeholder="Verification Code" ngModel #formModel="ngModel"/>
      </div>
      <button nbButton status="primary" type="submit">
        Update
      </button>
    </ng-container>
  </div>
</form>