<ifs-loading-screen *ngIf="!runTemplate"></ifs-loading-screen>
<div class="general-page-container" *ngIf="runTemplate">
    <div class="general-page-header">
      <div class="d-flex">
          <div class="flex-1">
            <h4 class="heading">Run Template: <b>{{ runTemplate.Name }}</b></h4>
            <br/>
            <p class="sub-heading">Run Template ID: <a class="pointer-cursor" routerLink="/admin/runtemplates/{{runTemplate.Id}}">{{ runTemplate.Id }}</a></p>
          </div>
          <div class="text-right">
            <button size="medium" outline nbButton routerLink="/">
              Cancel
            </button>
          </div>
      </div>
    </div>
</div>

<div *ngIf="runTemplate" class="container">
    <div class="row enquiry-fields">
        <div class="col-md-6">
            <div class="content-box">
                <h4>Select fields to enquire <small *ngIf="dataFilter.length <= 0">*minimum of 1 required.</small></h4>

                <hr />

                <nb-tag-list>
                    <nb-tag appearance="filled" *ngFor="let item of searchFields" [text]="item.Key" [status]="item.Selected ? 'primary' : 'basic'" (click)="selectField(item)"></nb-tag>
                </nb-tag-list>

                <div class="input-enquiry-fields">
                    <ng-container *ngFor="let item of dataFilter">
                        <nb-form-field>
                            <nb-icon *ngIf="item.protectionType !== 'None'" nbPrefix pack="protection-icons" [icon]="item.protectionType" nbTooltip="{{item.protectionType}} protected field, exact match value required." nbTooltipStatus="primary"></nb-icon>
                            <input type="text" nbInput [(ngModel)]="item.value" placeholder="{{item.key}}" style="margin-right: 12px;">
                        </nb-form-field>
                    </ng-container>
                </div>
            </div>
        </div> 

        <div class="col-md-6">
            <div class="content-box">
                <h4>Default Enquiry Fields</h4>

                <hr/>

                <div class="input-enquiry-fields">
                    <nb-form-field>
                        <input id="fromDateInput" fullWidth nbInput placeholder="From Date" [nbDatepicker]="fromDateTimePicker" [(ngModel)]="optionalFromDate">
                        <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('fromDateInput')">
                            <nb-datepicker #fromDateTimePicker></nb-datepicker>
                        </nb-icon>
                    </nb-form-field>
                    <nb-form-field>
                        <input id="toDateInput" fullWidth nbInput placeholder="To Date" [nbDatepicker]="toDateTimePicker" [(ngModel)]="optionalToDate" [disabled]="!optionalFromDate">
                        <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('toDateInput')">
                            <nb-datepicker #toDateTimePicker></nb-datepicker>
                        </nb-icon>
                    </nb-form-field>
                    <nb-form-field *ngFor="let item of optionalFields">
                        <!-- <nb-icon nbPrefix pack="protection-icons" [icon]="item.protectionType" nbTooltip="{{item.protectionType}} protected field, exact match value required." nbTooltipStatus="primary"></nb-icon> -->
                        <input type="text" nbInput [(ngModel)]="item.value" placeholder="{{item.name}}" style="margin-right: 12px;">
                    </nb-form-field>
                </div>
            </div>   
        </div>    
    </div>

    <br />

    <row class="row enquire-button">
        <div class="col-md-12">            
            <button class="save" size="medium" nbButton status="primary" (click)="enquire()" [disabled]="dataFilter.length <= 0">
                Enquire
            </button>
        </div>
    </row>

    <div *ngIf="hasRunSearch" class="row recipients">
        <div class="col-md-12">
            <div class="content-box">
                <h4>Recipients</h4>

                <hr/>

                <h6 *ngIf="(loadingRunSearchData$ | async) == true">
                    Loading <nb-icon icon="loading-half" pack="oakmoon" class="rotate"></nb-icon>
                </h6>
            
                <ng-container *ngIf="!(loadingRunSearchData$ | async) && (runSearchData$ | async) as runSearchData; else noData;">
                    <div class="row recipients" *ngIf="runSearchData.length > 0; else noData">
                        <div *ngFor="let recipient of runSearchData" class="col-md-6">
                            <ifs-recipient [recipientType]="true" [recipientData]="recipient" [displayAdditionalRunInfo]="true"></ifs-recipient>
                        </div>
                    </div>
                </ng-container>
            
                <ng-template #noData>
                    <p *ngIf="!(loadingRunSearchData$ | async)">No recipients found.</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>