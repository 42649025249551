import { Type } from '@angular/core';

export interface MenuItemModel {
    title: string,
    description: string,
    actions: any,
    icon: string,
    pack: string,
    enabled?: boolean,
    route?: string,
    createComponent?: MenuItem,
    isRoute?: boolean,
    context?: string,
    service?: any
}

export interface GenericComponentModel {
  data: any;
  context?: string
}

export class MenuItem {
  constructor(public component: Type<any>, public data: MenuItemModel) {}
}