import { ToastrService } from 'ngx-toastr';

import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiBaseService, BookmarkService, RunActionsService, RunsService, SelectedRunService } from '@InfoSlips/iiab-api';
import { NbDialogService } from '@nebular/theme';
import { RunStatusService } from '../../services/run-status.service';
import { Bookmark, RunModel } from '@InfoSlips/models';
import { WizardService } from 'libs/wizard/src/lib/services/wizard.service';
import { LoadingScreenService } from 'libs/shared/src/lib/services/loading-screen.service';
import { TestModels } from '@InfoSlips/iiab-tests-helpers';
import { Apollo } from 'apollo-angular';
import { AuthService } from '@InfoSlips/iiab-auth';
import { RunStateService } from '@InfoSlips/iiab-state';
@Component({
  selector: 'ifs-run',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit {
  public innerWidth: number;
  selectedRunService: SelectedRunService;
  run: any;
  selectedRun$:Observable<RunModel>;
  optionsContext = '';
  dialogRef;
  readyForLive: boolean;
  navigatorEvents;
  recipientCount$: Observable<Number>;
  recipientCount: Number;

  expand = false;
  isRunSelected = false;

  @ViewChild('forwardDialog') private runOptionsDialog: TemplateRef<any>;
  displayRunStatus$;
  isStar$: Observable<Bookmark> = this.bookmarkService.isStar$;

  constructor(
    apiBaseService:ApiBaseService,
    private route: ActivatedRoute, 
    private dialogService: NbDialogService,
    private router: Router,
    public displayStatus: RunStatusService,
    private toastr: ToastrService,
    private bookmarkService: BookmarkService,
    public wizardService: WizardService,
    public loadingService: LoadingScreenService,
    runsService: RunsService,
    public runActionsService:RunActionsService,
    apollo: Apollo,
    authService: AuthService,
    public state: RunStateService
  ) {

    this.selectedRunService = new SelectedRunService(
      apiBaseService, 
      runsService,
      toastr,
      bookmarkService,
      router,
      apollo,
      authService,
      state
      );
    this.selectedRun$ = this.selectedRunService.selectedRun$;
    this.selectedRunService.runError$.subscribe(error=>{
      if(error===null)
        return;
        this.router.navigateByUrl("/");
        this.toastr.info('Selected run does not exist!');        
    });
    this.navigatorEvents = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        const runId = this.route.snapshot.params.id;
        if(runId==null || runId.length==0)
        {
          this.router.navigateByUrl("/");
          this.toastr.info('Selected run does not exist!');    
        }
        this.selectedRunService.setRun({id: runId});  
      }
    });

    this.displayRunStatus$ = this.displayStatus.displayStatus$;
  }

  runMenu = [];

  ngOnInit() {    
    this.innerWidth = window.innerWidth;
    this.selectedRun$.subscribe(res => {
      this.run = res;

      if(this.run?.Status === "Created"){
        this.setTab(3);
      } else if(this.run?.Status === "Complete" || this.run?.Status === "Open"){
        this.run?.IsTrial ? this.setTab(1) : this.setTab(0);
      }
      else if(this.run?.Status === "Processed"){
        this.setTab(1);
      }
    });
    
    this.selectedRunService.getRecipientCount();
  }

  ngOnDestroy(){
    this.navigatorEvents.unsubscribe()
  }

  setTab(id: number){
    this.state.setTab(id)
    this.displayStatus.closeRunStatus()
  }

  openRunOptions(ref, context) {
   this.dialogRef = this.dialogService.open(ref, {});
   this.optionsContext = context;
  }

  closeRunOptions(){
    this.dialogRef.close();
  }

  copyErrorMessage(run){
    const el = document.createElement('textarea');
    el.value = run.Instance.LastException;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toastr.success(
      'Error Copied to Clipboard',
    );
  }

  favorite(name: string){
    const bookmark: Bookmark = {
      name: name,
      context: 'Run',
      icon: 'run',
      isStar: false,
      route: this.router.url
    };
    this.bookmarkService.favorite( bookmark);
  }

  syncRunWithTemplate() {
    this.selectedRunService.syncRunWithTemplate(this.run.Id);
  }

  async scheduleRunDeletion(){
    this.toastr.info("Run scheduled for deletion!");
    this.dialogRef.close();

    if(this.run.Instance.Status === 3){
      this.toastr.info("The specified run has not been reset yet. The run has been queued to reset and delete.");
      const isRunReset = await this.runActionsService.resetRun(this.run.Id);
      isRunReset ? this.deleteRun() : this.toastr.error('Run deletion failed.');
    } else {
      this.deleteRun();
    }
  }

  deleteRun(){
    this.runActionsService.deleteRun(this.run.Id).subscribe(res => {
      if(res){
        this.toastr.info("Run Deleted!");
        this.bookmarkService.removeRun(this.run.Id);
      }
      else {
        this.toastr.error("Run Delete Failed!");
      }

      this.router.navigateByUrl("/");
      this.toastr.info("Navigating to Home.");
    });
  }

  resetRun(){
    this.runActionsService.resetRun(this.run.Id);
  }

  resetOpenRun(){
    this.runActionsService.resetOpenRun(this.run.Id);
    this.dialogRef.close();
  }

  cancelRun(){
    this.runActionsService.cancelRun(this.run.Id);
  }

  toggleRuns(){
    this.expand = !this.expand; 
  }
}
