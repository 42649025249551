import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@InfoSlips/iiab-auth';
import { routeAnimation } from '../../shared.module';

@Component({
  selector: 'InfoSlips-sso',
  animations: [routeAnimation],
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements AfterViewInit {

  error: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService:AuthService) { }

    ngAfterViewInit(): void {
    const ClientId = this._getRequiredRouteParam('client_id');
    const Code = this._getRequiredRouteParam('code');

    this.authService.sso({ClientId, Code});
  }

  private _getRequiredRouteParam(key:string):string{
    const value = this.activatedRoute.snapshot.queryParamMap.get(key);
    if (!value || value.length == 0) {
      this.error = `Invalid ${key}.`;
      throw new Error(this.error);
    }
    return value;

  }
}
