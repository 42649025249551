import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RunReportService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-run-report-download',
  templateUrl: './run-report-download.component.html',
  styleUrls: ['./run-report-download.component.scss']
})
export class RunReportDownloadContainer {

  reportDownloaded = false;
  reportDownloading = false;

  constructor(
    private route: ActivatedRoute,
    private reportService: RunReportService
  ) { }

  async download(){
    this.reportDownloading = true;
    this.reportService.asyncReport(this.route.snapshot.paramMap.get('id')).subscribe(res => {
      this.reportDownloading = false;
    });
  }
}
