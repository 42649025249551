import { CmsEntitySummary, CmsLinkedToItem, PageGraphQLData } from "@InfoSlips/models";

export function _cmsEntitiesRecentsPagedQuery(pageSize:number=10):string{
    return `
        query CmsEntitiesSummary ($afterCursor: String = null, $where: CmsEntityFilterInput = null, $order: CmsEntitySortInput = {
            lastUpdated: DESC
          }) {
            cmsEntities (first: ${pageSize}, order: [$order], where: $where, after: $afterCursor) {
                totalCount,
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,
                  endCursor
                },
                edges{
                    node {
                        id
                        publicId
                        name
                        display
                        version
                        cmsEntityType
                        cmsContentType
                        lastUpdated
                        lastUpdatedBy
                        isDraft
                        customer {
                            name
                            id
                        }
                        runTemplate {
                            name
                            id
                        }
                        customerGroup {
                            id
                            name
                        }
                    }
                }
            }
        }`;
}

export function _cmsRevisionsSummaryPagedQuery(publicId:string, pageSize:number=10):string{
    return `
        query CmsRevisions ($where: CmsEntityFilterInput = null) {
            cmsRevisions (publicId: "${publicId}", first: ${pageSize}, order: {created: DESC}, where: $where) {
                totalCount,
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,
                  endCursor
                },
                edges{
                    node {
                        id
                        publicId
                        name
                        display
                        version
                        cmsEntityType
                        cmsContentType,
                        created
                        createdBy
                        lastUpdated
                        lastUpdatedBy
                        isDraft
                        customer {
                            name
                            id
                        }
                        runTemplate {
                            name
                            id
                        }
                        customerGroup {
                            id
                            name
                        }
                    }
                }
            }
        }`;
}

export function _cmsLinkedRunTemplatesPagedQuery(publicId: string, pageSize: number = 10): string{
    return `
        query runTemplatesLinkedToEntity ($afterCursor: String = null) {
            runTemplatesLinkedToEntity (publicId: "${publicId}", first: ${pageSize}, after: $afterCursor) {
                totalCount,
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,
                  endCursor
                },
                edges{
                    node {
                        referenceItem {
                            id
                            name
                          },
                          cmsEntityReferences {
                            id
                            name
                            version
                            cmsContentType
                            isDraft
                            useOffline
                          }
                    }
                }
            }
        }`;
}

export function _cmsLinkedRunsPagedQuery(publicId: string, pageSize: number = 10): string{
    return `
        query runsLinkedToEntity ($afterCursor: String = null) {
            runsLinkedToEntity (publicId: "${publicId}", first: ${pageSize}, after: $afterCursor) {
                totalCount,
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,
                  endCursor
                },
                edges{
                    node {
                        referenceItem {
                            id
                            runDate
                            name
                          },
                          cmsEntityReferences {
                            id
                            name
                            version
                            cmsContentType
                            isDraft
                            useOffline
                          }
                    }
                }
            }
        }`;
}

export function _fetchCmsEntitiesData(rawData: any): PageGraphQLData<CmsEntitySummary> {
    try {
        return rawData.data.cmsEntities as PageGraphQLData<CmsEntitySummary>;
    }
    catch (e) {
        console.log('cmsEntitiesData That FAILED', rawData);
        return null;
    }
}

export function _fetchCmsRevisionsData(rawData: any): PageGraphQLData<CmsEntitySummary> {
    try {
        return rawData.data.cmsRevisions as PageGraphQLData<CmsEntitySummary>;
    }
    catch (e) {
        console.log('cmsEntitiesData That FAILED', rawData);
        return null;
    }
}

export function _fetchCmsLinkedRunsData(rawData: any): PageGraphQLData<CmsLinkedToItem> {
    try {
        return rawData.data.runsLinkedToEntity as PageGraphQLData<CmsLinkedToItem>;
    }
    catch (e) {
        console.log('CmsLinkedToItem That FAILED', rawData);
        return null;
    }
}

export function _fetchCmsLinkedRunTemplateData(rawData: any): PageGraphQLData<CmsLinkedToItem> {
    try {
        return rawData.data.runTemplatesLinkedToEntity as PageGraphQLData<CmsLinkedToItem>;
    }
    catch (e) {
        console.log('CmsLinkedToItem That FAILED', rawData);
        return null;
    }
}
