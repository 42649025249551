export interface PageInfo{
    hasNextPage:boolean;
    hasPreviousPage:boolean;
    startCursor:string;
    endCursor:string;
}

export interface Edge<T>{
    cursor:string,
    node: T
}

export interface PageGraphQLData<T>{
    totalCount:number;
    pageInfo:PageInfo;
    edges: Edge<T>[];
}