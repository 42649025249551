import { Component } from '@angular/core';

@Component({
  selector: 'ifs-report-shell',
  template: `
  <ifs-activity-bar-shared context="reports">
    <ifs-report></ifs-report>
  </ifs-activity-bar-shared>
`
})
export class ReportShellComponent {}