import { Injectable } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { CmsThemeImageModel, CmsThemeModel } from '@InfoSlips/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiBaseService } from './base/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themeSubject = new BehaviorSubject<any>(null);
  theme$ = this.themeSubject.asObservable();

  constructor(
    private apiBaseService: ApiBaseService,
    private environment: EnvironmentService
  ) { }

  getTheme(subdomain: string){
    let theme = new Subject<any>();

    this.apiBaseService.executeGet<CmsThemeModel>(`themes/${subdomain}`, "Retrieve theme by domain.").subscribe((res: any) => {
      if(res?.Images){
        res.Images.map((image:CmsThemeImageModel) => {
          image.Path = `${this.environment.apiUrl}api/cms/publicid/${image.Path.split('cms/publicid/')[1]}`;
        })
      }

      this.themeSubject.next(res);

      if(res.ColorPalette){
        const colorPalette = res.ColorPalette;
        const root: any = document.querySelector('.nb-theme-light');
        for (const [key, value] of Object.entries(colorPalette)) {
      
          if(key === 'login-logo-text-image'){
            // @ts-ignore
            root.style.setProperty(`--${key}`, value.replace('{url}', res.Images.find(image => image.Name == `${subdomain}-image`)?.Path));
          } else {
            root.style.setProperty(`--${key}`, value);
          }
        }
      }
      theme.next({
        ...res,
        isReady: true
      });
    })

    return theme.asObservable();
  }
}
