
export interface TrialOptionsModel {
    IsAutoTrialEnabled: boolean;
    RecipientSelection: number;
    UserKeyOption: number;
    FilterOutputType: number;
    SendToUser: string[];
    EmailGroups: string[];
    EmailGroupEmails: string[];
    RecipientList: string[];
    SkipRefreshTemplateFolder?: boolean;
}
