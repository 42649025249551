import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { RunsService } from '@InfoSlips/iiab-api';
import * as models from '@InfoSlips/models';
import { AuthService } from '@InfoSlips/iiab-auth';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';
import { IIABCacheKey } from '@InfoSlips/models';
import { RunFilterService } from '../../services/run-filter.service';

interface TreeNode<T> {
  data: T;
  // children?: TreeNode<T>[];
  expanded?: boolean;
}

@Component({
  selector: 'ifs-runs-grid',
  templateUrl: './runs-grid.component.html',
  styleUrls: ['./runs-grid.component.scss']
})

export class RunsGridComponent implements OnInit, OnDestroy {  
  @Input() isFilterExpanded: boolean;
  @Output() runId = new EventEmitter<any>();
  @ViewChild('searchBox', { static: true }) searchBox: ElementRef;
  @ViewChild('pagingComponent') pagingComponent;
  
  runSummaries$: Observable<models.RunSummary[]> = null;
  runSummariesCount$: Observable<number> = this.runService.runSummaries.totalCount$;
  lastUpdated$: Observable<string> = this.runService.runSummaries.lastUpdated$;
  runCount$: Observable<number> = this.runService.runSummaries.count$;
  loading = true;
  error: any;
  ghosts = [] = new Array(10);
  
  showGrid = this.localStorageService.get(IIABCacheKey.RunsGrid);

  constructor(
    private router: Router, 
    private runService: RunsService,
    private authService: AuthService,
    private localStorageService: IiabLocalStorageService,
    private runFilterService: RunFilterService,
    public dataSourceBuilder: NbTreeGridDataSourceBuilder<models.RunSummary>
    ) { }

  selectedRun;
  activeTab = 'All';

  runTypeOptions = [
    {
      label: "All",
      filter: null
    }
  ];
  
  defaultHeaders = [ 'Run Template Name', 'Description', 'Status', 'Last Updated' ]
  defaultColumns = [ 'runTemplateName', 'name', 'instanceStatus', 'lastUpdated' ];
  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  sortASC = false;

  dataSource: NbTreeGridDataSource<models.RunSummary>;

  userInputAction = this.runFilterService.userInputAction.asObservable();
  hasNextPage$ = this.runService.runSummaries.hasNextPage$;
  hasPreviousPage$ = this.runService.runSummaries.hasPreviousPage$;
  runsPerPage: number;

  gridSort = {
    runTemplate: null,
    name: null,
    status: null,
    stage: null,
    lastUpdated: 'ASC'
  }
  
  ngOnInit() {
    this.runsPerPage = this.runService.runSummariesPerPage = 10;
    this.runSummaries$ = this.runService.runSummaries.Items$();

    this.runService.selectedRun$.subscribe(res=>{
      this.selectedRun = res;
    })

    this.authService.loggedInUser$.subscribe(res => {
      
      if (!res) {
        this.runService.clearMemory();
        this.runSummaries$ = null;
      }

      this.runTypeOptions.push({
        label: "My",
        filter: {
          createdBy: {
            eq: res?.userName
          }
        }
      },
      {
        label: "Scheduled",
        filter: { 
          instance: {
            status: { 
              eq: "SCHEDULED"
            }
          }
        }
      })
    });

    this.runSummaries$.subscribe(res => {
      const newData = res.map(item => {
        const object = {...item, ... { 
          runTemplateName: item.runTemplate.name,
          instanceStatus: item.instance.status
        }}
        delete object.runTemplate;
        return {
          data: object,
          expanded: false
        }
      })
      this.dataSource = this.dataSourceBuilder.create(newData);
    })

    this.runService.getRecentRuns();

    this.runService.runSummaries.isLoading$.subscribe(res => {
      this.loading = res;
      if (res == false) this.runFilterService.userInputAction.next(false);
    });
  }

  ngOnDestroy(): void {
    this.runService.unsubscribeAll(true);
  }

  pageChange($event: string){
    this.runFilterService.userInputAction.next(true);
    this.runService.pageRunSummaries($event);
  }

  changePageSize($event) {
    this.runFilterService.userInputAction.next(true);
    this.runsPerPage = this.runService.runSummariesPerPage = $event;
    this.runService.pageRunSummaries("first");
    this.pagingComponent.currentPage = 1;
  }

  selectRun(run){
    this.router.navigateByUrl('run/'+run.id);
  }
  
  toggleFilter(){
    this.isFilterExpanded = !this.isFilterExpanded
  }

  changeRunType(event){
    this.activeTab = event.tabTitle;
    const option = this.runTypeOptions.find(item => item.label === event.tabTitle);

    if(option.filter != null){
      if (option.label.includes("Scheduled")) {
        this.runFilterService.pushSelectedRunStatusValue("Scheduled");
        this.runFilterService.customFilter = null;
      } else {
        this.runFilterService.customFilter = option.filter;
        this.runFilterService.removeValueFromSelectedRunStatus("Scheduled");
      }
    } 
    else {
      this.runFilterService.customFilter = null;
      this.runFilterService.removeValueFromSelectedRunStatus("Scheduled");
    }
    
    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadSortedItems({
      whereObject: Object.keys(this.runService.runsFilterObject).length !== 0 ? this.runService.runsFilterObject : null
    }, this.runsPerPage);
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
     return NbSortDirection.NONE;
  }

  sortGrid(column: string){
    this.sortASC = !this.sortASC;

    const order = {};
    order[column] = this.sortASC ? 'ASC' : 'DESC';

    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadSortedItems({
      order
    })
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  filterField(fieldName: string){
    switch (this.gridSort[fieldName]) {
      case 'ASC':
        this.gridSort[fieldName] = 'DESC'
        break;

      case 'DESC':
        this.gridSort[fieldName] = null
        break;

      default:
        this.gridSort[fieldName] = 'ASC'
        break;
    }
    
    let order = {};

    if(this.gridSort[fieldName] && (fieldName !== 'customer' && fieldName !== 'runTemplate')){
      order[fieldName] = this.gridSort[fieldName];
    } else {
      order[fieldName] = {
        name: this.gridSort[fieldName]
      }
    }

    if(!this.gridSort[fieldName]){
      order = {
        lastUpdated: 'DESC'
      };
    }

    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadSortedItems({
      whereObject: Object.keys(this.runService.runsFilterObject).length !== 0 ? this.runService.runsFilterObject : null,
      order
    })
  }

  toggleGrid(event: boolean) {
    this.localStorageService.set(IIABCacheKey.RunsGrid, event, false);
  }
}
