import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'ifs-data-prompts',
  template: `
  <ifs-logged-out-view style="margin-top: -20%">
    <form (ngSubmit)="onSubmit()" class="login">
      <p class="pull-left title" data-e2e="cardTitle" *ngIf="!InfoSlip.HideDataPromptsTitle">
        {{ dataPrompTitle }}
      </p>
      <br style="clear: both;"/>
      <div class="form-body row">
        <div class="col-md-12">
          <ng-container *ngFor="let question of dataPrompts; let i = index">
            <p>{{question}}</p>
            <nb-form-field>
              <input 
                class="custom-input" nbInput fullWidth placeholder="Password" 
                autocomplete="off" [style]="{'-webkit-text-security': (showPassword == true) ? 'disc' : 'none'}"
                type="text" data-e2e="password" name="answer{{ i }}" [(ngModel)]="dataPromptAnswers[i]"/>
              <button nbSuffix nbButton ghost (click)="toggleShowPassword()" class="icon" type="button">
                <nb-icon [icon]="showPassword ? 'view' : 'view-off'"
                        pack="oakmoon"
                        [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                </nb-icon>
              </button>
            </nb-form-field>
          </ng-container>
          <ng-container *ngIf="error">
            <p class="caption status-danger">
            At least one question should contain an answer
            </p>
          </ng-container>
        </div>
      </div>


      <div class="form-footer">
        <div class="row">
          <div class="col-md-12">
            <button nbSpinnerStatus="info" nbButton fullWidth
              status="primary" data-e2e="submit" type="submit">
              Sign in
            </button>
          </div>
        </div>
      </div>
    </form>
  </ifs-logged-out-view>
  `,
  styleUrls: ['./data-prompts.component.scss']
})
export class DataPromptsComponent implements OnInit, OnChanges {
  showPassword = true;

  @Input() error;
  @Input() loading;
  @Input() dataPrompts;
  @Input() InfoSlip;
  dataPrompTitle = "Complete the following to access your document:";
  dataPromptAnswers = [];
  @Output() submitDataPrompts = new EventEmitter<any>();

  readonlyFields: boolean[] = [];

  constructor() {}

  ngOnInit() {
    if(this.InfoSlip.DataPromptsTitle != null && this.InfoSlip.DataPromptsTitle != ""){
      this.dataPrompTitle = this.InfoSlip.DataPromptsTitle;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.dataPrompts.currentValue !== changes.dataPrompts.previousValue
    ) {
      this.dataPromptAnswers = [];
    }
  }

  onSubmit() {
    if (!this.dataPromptAnswers.length) {
      this.error = true;
      return;
    }
    this.submitDataPrompts.emit(this.dataPromptAnswers);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
