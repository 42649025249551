import { Component, OnInit } from '@angular/core';
import { Country, RegisterModel } from '@InfoSlips/models';
import { RegisterService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-register',
  styleUrls: ['./register.component.scss'],
  template: `
    <ifs-logged-out-view>
      <ifs-register-form class="full-width"
        (userRegister)="register($event)"
        [loadingState]="loadingState"
        [countryDetail]="countryDetail"
        [registerOptions]="registerOptions"
        (registerOption)="setRegisterOption($event)"
      ></ifs-register-form>
    </ifs-logged-out-view>
  `
})
export class RegisterComponent implements OnInit {
  countryDetail: Country[] = [
    {
      Id: '',
      Name: '',
      CallingCode: [''],
    }
  ];
  loadingState = false;
  registerOptions = [{label: 'Email', value: 'Email', checked: true},{label: 'Mobile', value: 'Mobile'}];
  isEmail = true;

  constructor(
    private registerService: RegisterService,
  ) {}

  ngOnInit() {}

  register(event: RegisterModel) {
     this.loadingState = true;
     this.loadingState = this.registerService.register(event, this.isEmail);
  }

  setRegisterOption(event: String){
    this.isEmail ? this.isEmail = !this.isEmail : this.isEmail = !this.isEmail;

    if(event === 'Mobile' && this.countryDetail.length < 2){
        this.registerService.getCountries().subscribe((values: any) => {
          // console.log(values);
          
        this.countryDetail = values;
     });
    }
  }
}
