import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiBaseService } from './base/api-base.service';

export interface MobileNumber {
  Number: string;
  CountryCode: string;
  CountryPrefix: number;
}

export interface Profile {
  UserName: string;
  Email: string;
  Mobile: object;
  DisplayName: string;
  DateOfBirth: null;
  OtpEnabled: boolean;
  UserEmails: string[];
  MobileNumbers: MobileNumber[];
}

export interface Detail {
  Detail: string;
  Country: string;
}

export interface Country {
  Id: string;
  Name: string;
  CallingCode: string[];
}

export interface InfoSlipDetail {
  Detail: string,
  Country: string
}

@Injectable({
  providedIn: 'root'
})
export class PersonalDetailsService {
  constructor(
    private apiBaseService: ApiBaseService,
    private router: Router,
    private toaster: ToastrService
  ) {}

  verifyInfoSlipDetailSubject = new BehaviorSubject<boolean>(null);
  verifyInfoSlipDetail$ = this.verifyInfoSlipDetailSubject.asObservable();

  getProfile() {
    return this.apiBaseService.executeGet<Profile>(`profile`, "Get User Profile");
  }

  getCountries() {
    return this.apiBaseService.executeGet<Country[]>(`Country/?$top=500`, "Get Countries");
  }

  verifyInfoSlipDetail(token: string) {
    return this.apiBaseService.executePost<Profile>(`user/VerifyInfoSlipDetail/${token}`, null, "Save Profile Details").subscribe(response => {
        this.toaster.success(
          'Information Validated!'
        );
        this.verifyInfoSlipDetailSubject.next(true);
        setTimeout(() => this.router.navigateByUrl('profile'), 3000);
    }, error => {
      this.toaster.error(
        'Verification Failed, please sign in and open the link again!'
      );
      this.verifyInfoSlipDetailSubject.next(false);
      setTimeout(() => this.router.navigateByUrl('login'), 10000);
    });
  }

  addInfoSlipDetail(detail: InfoSlipDetail) {
    return this.apiBaseService.executePost<Profile>(`user/AddInfoSlipDetail`, detail, "Add Profile Details").subscribe(response => {
      this.toaster.success(
        'Verification request sent!'
      );
      }, error => {
    this.toaster.error(
      'Unable to send verification message!', error
    );
    });
  }
}
