<nb-card class="block">
    <nb-card-header>
        <h4>Your Recents Entities</h4>
        <input placeholder="Search recent items..." [(ngModel)]="searchPhrase" 
        (ngModelChangeDebounced)="filterRecent()"
        [ngModelChangeDebounceTime]="1000"/>
    </nb-card-header>
    <ng-container *ngIf="data">
        <ng-container *ngIf="data.length > 0 ">
            <cms-grid-item *ngFor="let cmsEntity of data" [cmsEntity]="cmsEntity" type="small" (click)="selectEntity(cmsEntity)"></cms-grid-item>
        </ng-container>
    </ng-container>
</nb-card>