export interface BaseEntityModel extends BaseEntityIdNameModel{
    Created?: string;
    CreatedBy?: string;
    LastUpdated?: string;
    LastUpdatedBy?: string
}

export interface BaseEntityIdNameModel extends BaseEntityIdModel{
    Name:string;    
}

export interface BaseEntityIdModel{
    Id?:string;
}
