<nb-card class="card-container" *ngIf="selectedEntity">
  <nb-card-body class="padded">
    <ng-container *ngIf="displayName" [ngTemplateOutlet]="nameTemplate"></ng-container>

    <label><p>Display Name</p></label>
    <input nbInput fullWidth type="text" fullWidth name="entityDisplay" placeholder="Entity Display Name" required [(ngModel)]="selectedEntity.Display"
    />

    <ng-container *ngIf="showSecure()" [ngTemplateOutlet]="secureTemplate"></ng-container>

    <ng-container *ngIf="showCustGroup()" [ngTemplateOutlet]="customerGroupTemplate"></ng-container>

    <label><p>Tags</p></label>
    <nb-tag-list (tagRemove)="onTagRemove($event)">
      <nb-tag *ngFor="let tag of selectedEntity.Tags" [text]="tag" removable></nb-tag>
      <input type="text" nbTagInput (tagAdd)="onTagAdd($event)" fullWidth />
    </nb-tag-list>

    <label><p>Notes</p></label>
    <textarea name="entityNotes" placeholder="Notes" nbInput fullWidth [(ngModel)]="selectedEntity.Notes"></textarea>

    <ng-template #nameTemplate>
      <label><p>Name</p></label>
      <input autofocus [disabled]="isExisting" nbInput fullWidth type="text" fullWidth name="entityName" placeholder="Entity Name" required [(ngModel)]="selectedEntity.Name" (blur)="checkDisplay()"/>
    </ng-template>

    <ng-template #secureTemplate>
      <label>
        <p>Customer</p>
      </label>
      <input *ngIf="isExisting" [readonly]="true" nbInput fullWidth type="text" class="clickable" fullWidth [(ngModel)]="selectedEntity.Customer.Name" [routerLink]="'../../../admin/customers/' + selectedEntity.Customer.Id"/>
      <ifs-customer-lookup *ngIf="!isExisting" [showLabel]="false" (ItemSelected)="onCustomerSelected($event)"></ifs-customer-lookup>

      <div *ngIf="selectedEntity.RunTemplate">
        <label>
          <p>Run Template</p>
        </label>
        <input *ngIf="isExisting" [readonly]="true" nbInput fullWidth type="text" class="clickable" fullWidth [(ngModel)]="selectedEntity.RunTemplate.Name" [routerLink]="'../../../admin/runtemplates/' + selectedEntity.RunTemplate.Id"/>
        <ifs-run-template-lookup *ngIf="!isExisting" [autoSelect]="false" [showLabel]="false" (ItemSelected)="onRunTemplateSelected($event)"></ifs-run-template-lookup>
      </div>
    </ng-template>

    <ng-template #customerGroupTemplate>
      <label>
        <p>Customer Group</p>
      </label>
      <input *ngIf="isExisting" [disabled]="true" nbInput fullWidth type="text" fullWidth [(ngModel)]="selectedEntity.CustomerGroup.Name"/>
    </ng-template>
  </nb-card-body>
</nb-card>
