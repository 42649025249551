<div class="general-page-container fit-absolute">
  <div class="general-page-header">
    <ifs-trial-form [selectedRunService]="selectedRunService" (emitRecipients)="setRecipients($event)" (loadingRecipients)="setRecipientLoading($event)"></ifs-trial-form>
  </div>
  <div class="general-page-body">
    <div class="scroll row no-margin p-10">
      <ifs-run-recipients 
        [recipientType]="true"
        [recipientData]="runRecipients$"
        [selectedRunService]="selectedRunService"
        [loadingRecipients]="loading"
      ></ifs-run-recipients>
    </div>
  </div>
</div>
