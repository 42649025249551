/* eslint-disable max-len */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiBaseService, RunReportService, UserService } from '@InfoSlips/iiab-api';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReportObject, Reports } from '../report.config';
import { CompaniesFormComponent } from './../../../../wizard/src/lib/components/companies-form/companies-form.component';
import { MenuItem } from './../../../../wizard/src/lib/services/menu-item';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService,
    private runReportService: RunReportService,
    private userService: UserService,
    private router: Router
  ) { 
    this.userService.allowedMainMenu$.subscribe(res => {
      if (res) {
        const allowedReports = res.find(item => item.id == "REPORTS")?.items;
        this.allowedReportsForMenu.next(this.getAllAllowedReportsForMenu(allowedReports));
        this.allowedReportsForComponent.next(this.getAllAllowedReportsForComponent(allowedReports));
      }
    })
  }
  
  private reports = Reports.AllReports;
  private currentQuery = null;
  private currentFilters = null;
  private pageInfo = null;
  public allowedReportsForMenu = new BehaviorSubject<any>(null);
  public allowedReportsForComponent = new BehaviorSubject<ReportObject[]>(null);

  public filterObject = {};
  public reportDetailsSubject = new BehaviorSubject<any>(null);
  public reportDetails$ = this.reportDetailsSubject.asObservable();
  public reportDetailsPagingSubject = new BehaviorSubject<any>(0);
  public reportDetailsPaging$ = this.reportDetailsPagingSubject.asObservable();
  public allowedReportsForMenu$ = this.allowedReportsForMenu.asObservable();
  public allowedReportsForComponent$ = this.allowedReportsForComponent.asObservable();

  public get runFilterObject() {    
    let runFilter = {};

    if (this.filterObject["run"]) {
      runFilter = {...runFilter, ...{ id: { ...this.filterObject["run"]["id"] } } };
    }
    if (this.filterObject["customer"]) {
      runFilter = {...runFilter, ...{ customer : { ...this.filterObject["customer"] } } };
    }
    if (this.filterObject["runTemplate"]) {
      runFilter = {...runFilter, ...{ runTemplate : { ...this.filterObject["runTemplate"] } } };
    }
    
    return runFilter;
  }

  // Get currently selected report.
  getSelectedReport(runId?: string){
    const currentReport = this.reports.find(item => {
      const route = runId ? this.router.url.split(`reports/${runId}/`)[1] : this.router.url.split('reports/report/')[1];
      return item.route === route;
    });   

    return Object.assign(currentReport);
  }

  // Retrieve report by searching with graphql query.
  search(graphql?, Filters?, context?, reportId?){
    this.reportDetailsSubject.next(null);
    let workingFilters = {...Filters};
    workingFilters ? this.currentFilters = workingFilters : null;
    graphql ? this.currentQuery = graphql : null;

    if (!workingFilters?.run && this._routeHasRunId()) workingFilters = {...workingFilters, ...{run: {id: {eq: `${this._getRunIdFromRoute()}`}}}}

    const queryParameters = {};
    switch (reportId) {
      case "RUN_STATISTICS":
        if (workingFilters?.run?.id) {
          queryParameters['runId'] = workingFilters?.run?.id?.eq;
          delete workingFilters.run;
        }
        if (workingFilters?.runTemplate?.id) {
          queryParameters['runTemplateId'] = workingFilters?.runTemplate?.id?.eq;
          delete workingFilters.runTemplate;
        }
        if (workingFilters?.customer?.id) {
          queryParameters['customerId'] = workingFilters?.customer?.id?.eq;
          delete workingFilters.customer;
        }
        if (workingFilters?.sendDate) {
          queryParameters['startDate'] = workingFilters.sendDate.fromDate;
          queryParameters['endDate'] = workingFilters.sendDate.toDate;
          delete workingFilters.sendDate;
        }
        break;

      case "HOT_SPOT_REPORTING":
        if (workingFilters?.run?.id) {
          workingFilters['runId'] = workingFilters?.run?.id?.eq;
          delete workingFilters.run;
        }
        if (workingFilters?.customer?.id) {
          queryParameters['customerId'] = workingFilters?.customer?.id?.eq;
          delete workingFilters.customer;
        }
        break;

      case "ADHOC_VIEW_LOG_REPORTING":
      case "ADHOC_FORWARD_DELIVERIES_REPORT":
        if (workingFilters?.customer?.id) {
          queryParameters['customerId'] = workingFilters?.customer?.id?.eq;
          delete workingFilters.customerId;
        }
        break;
    }

    if (workingFilters.billingPeriod){
      queryParameters['billingPeriod'] = this._getBillingPeriod(workingFilters.billingPeriod.DateTime);
      delete workingFilters.billingPeriodSelect;
    }
    const filterString = this._buildFilterString(workingFilters);

    if (!filterString && !queryParameters) {
      this.toaster.warning(`Please add some filter criteria`);
      return;
    }
    
    const model = {
      query: graphql.query(filterString),
      operationName: graphql.operationName,
      variables: {
        'afterCursor': null,
        'beforeCursor': null,
        ...queryParameters
      },
    }

    if(context === 'next'){
      'beforeCursor' in model.variables ? delete model.variables.beforeCursor : undefined;
      model.variables.afterCursor = this.pageInfo.endCursor;
    } else if(context === 'previous') {
      'afterCursor' in model.variables ? delete model.variables.afterCursor : undefined;
      model.variables.beforeCursor = this.pageInfo.startCursor;
    }

    this.apiBaseService.executeGQLPost(model, 'Get Reports.').subscribe(async (res: any) => {
      const mappedData = await graphql.mapData(res, graphql.model);

      this.pageInfo = res.data[graphql.model].pageInfo;
      this.reportDetailsPagingSubject.next({
        totalCount: res.data[graphql.model]?.totalCount ? res.data[graphql.model]?.totalCount : -1,
        pageInfo: res.data[graphql.model].pageInfo
      });
      this.reportDetailsSubject.next(mappedData);
    });
  }

  // Download report by searching with graphql query.
  download(graphql?, Filters?, reportId?){
    let workingFilters = {...Filters};
    workingFilters ? this.currentFilters = workingFilters : null;
    graphql ? this.currentQuery = graphql : null;
    
    if (!workingFilters?.run && this._routeHasRunId()) workingFilters = {...workingFilters, ...{run: {id: {eq: `${this._getRunIdFromRoute()}`}}}}

    const queryParameters = {};
    switch (reportId) {
      case "RUN_STATISTICS":
        if (workingFilters?.run?.id) {
          queryParameters['runId'] = workingFilters?.run?.id?.eq;
          delete workingFilters.run;
        }
        if (workingFilters?.runTemplate?.id) {
          queryParameters['runTemplateId'] = workingFilters?.runTemplate?.id?.eq;
          delete workingFilters.runTemplate;
        }
        if (workingFilters?.customer?.id) {
          queryParameters['customerId'] = workingFilters?.customer?.id?.eq;
          delete workingFilters.customer;
        }
        if (workingFilters?.sendDate) {
          queryParameters['startDate'] = workingFilters.sendDate.fromDate;
          queryParameters['endDate'] = workingFilters.sendDate.toDate;
          delete workingFilters.sendDate;
        }
        break;

      case "HOT_SPOT_REPORTING":
        if (workingFilters?.run?.id) {
          workingFilters['runId'] = workingFilters?.run?.id?.eq;
          delete workingFilters.run;
        }
        if (workingFilters?.customer?.id) {
          queryParameters['customerId'] = workingFilters?.customer?.id?.eq;
          delete workingFilters.customer;
        }
        break;

      case "ADHOC_VIEW_LOG_REPORTING":
      case  "ADHOC_FORWARD_DELIVERIES_REPORT":
        if (workingFilters?.customer?.id) {
          queryParameters['customerId'] = workingFilters?.customer?.id?.eq;
          delete workingFilters.customerId;
        }
        break;
    }

    if (workingFilters.billingPeriod){
      queryParameters['billingPeriod'] = this._getBillingPeriod(workingFilters.billingPeriod.DateTime);
      delete workingFilters.billingPeriodSelect;
    }
    const filterString = this._buildFilterString(workingFilters);

    if (!filterString && !queryParameters) {
      this.toaster.warning(`Please add some filter criteria`);
      return;
    }

    const model = {
      query: graphql.download(filterString),
      operationName: graphql.operationName,
      variables: {
        'afterCursor': null,
        'beforeCursor': null,
        ...queryParameters
      },
    }

    this.apiBaseService.executeGQLPost(model, 'Download Report').subscribe(async (res: any) => {
      if (res.errors){
        this.toaster.error('Report Download Failed', res.errors[0].message);
      }
      else this.toaster.success('Please check your email inbox for report', 'Report sent');
    });
  }

  apiDownload(relativeUrl: string, requestTitle: string){
    this.apiBaseService.executeGetAny<any>(relativeUrl, requestTitle, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(tap(this.triggerDownloadEvent), catchError((error: HttpErrorResponse): Observable<any> => {
      if(error.status === 400){
        error.error.text().then((text:string) => {
          this.toaster.info(text);
        });
      }
      return throwError(error);
    })).subscribe();;
  }

  // Page graphql reports.
  pageReport(context: string){
    this.search(this.currentQuery, this.currentFilters, context);
  }

  // Helper function to format the date retrieved by reports in Human readable format.
  formatDate(dateTime){
    const date = new Date(dateTime);
    // eslint-disable-next-line max-len
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth()}-${date.getDate()}T00:00:00`;
  }

  // Build the graphql filter to query report.
  private _buildFilterString(Filters): string{
    let filterString = ``;

    if('run' in Filters){
      filterString += `
        run: {
          id: {
            eq: "${Filters.run.id.eq}"
          }
        }
      `;
    }

    if('customer' in Filters){
      filterString += `
        customer: {
          id: {
            eq: "${Filters.customer.id.eq}"
          }
        }
      `;
    }

    if('customerFilter' in Filters){
      filterString += `
      customerFilter: {
          some: {
            eq: "${Filters.customerFilter}"
          }
        }
      `;
    }

    if('runRecipientLike' in Filters && Filters.runRecipientLike.length > 0){
      filterString += `
        runRecipient: {
          name: {
            like: "${Filters.runRecipientLike}"
          }
        }
      `;
    }

    if('sendToInput' in Filters){
      filterString += `
        sendLog: {
          sendTo: {
            like: "${Filters.sendToInput}"
          }
        }
      `;
    }

    if('channel' in Filters && Filters.channel.length > 0){
      filterString += `
        channel: {
          eq: ${Filters.channel}
        }
      `;
    }

    if('isDelivered' in Filters && Filters.isDelivered){
      filterString += `
      isDelivered: {
        eq: ${Filters.isDelivered}
        }
      `;
    }

    if('isSent' in Filters && Filters.isSent){
      filterString += `
      isSent: {
          eq: ${Filters.isSent}
        }
      `;
    }

    if('isTrial' in Filters && Filters.isTrial.length > 0){
      filterString += `
      isTrial: {
          eq: ${Filters.isTrial == 1 ? true : false}
        }
      `;
    }

    if('isResend' in Filters){
      filterString += `
      isResend: {
          eq: ${Filters.isResend}
        }
      `;
    }

    if('mailChannel' in Filters){
      filterString += `
      type: {
          eq: ${Filters.mailChannel}
        }
      `;
    }

    if('actionSelect' in Filters){
      filterString += `
      action: {
          eq: "${Filters.actionSelect}"
        }
      `;
    }

    if('action' in Filters){
      filterString += `
      action: {
          like: "${Filters.action}"
        }
      `;
    }
    
    if('runId' in Filters){
      filterString += `
      runId: {
          eq: "${Filters.runId}"
        }
      `;
    }

    if('customerId' in Filters){
      filterString += `
      customerId: {
          eq: "${Filters.customerId}"
        }
      `;
    }

    if('runTemplateId' in Filters){
      filterString += `
      runTemplateId: {
          eq: "${Filters.runTemplateId}"
        }
      `;
    }

    if('recipient' in Filters){
      filterString += `
      recipient: {
          eq: "${Filters.recipient}"
        }
      `;
    }

    if('recipientId' in Filters){
      filterString += `
      recipientId: {
          eq: "${Filters.recipientId}"
        }
      `;
    }

    if('externalId' in Filters){
      filterString += `
      externalId: {
          eq: "${Filters.externalId}"
        }
      `;
    }

    if('sequence' in Filters){
      filterString += `
      sequence: {
          eq: ${Filters.sequence}
        }
      `;
    }

    if('label' in Filters){
      filterString += `
      label: {
          like: "${Filters.label}"
        }
      `;
    }

    if('userAgent' in Filters){
      filterString += `
      userAgent: {
          like: "${Filters.userAgent}"
        }
      `;
    }

    if('tags' in Filters){
      filterString += `
      tags: {
          some: {
            like: "${Filters.tags}"
          }
        }
      `;
    }

    if('email' in Filters){
      filterString += `
        email: {
            eq: "${Filters.email}"
        }
      `;
    }

    if('displayName' in Filters){
      filterString += `
      displayName: {
          like: "${Filters.displayName}"
        }
      `;
    }

    if('sendDate' in Filters){
      const startDate = new Date(Filters.sendDate.fromDate);
      const endDate = new Date(Filters.sendDate.toDate);
      if (Filters.sendDate.fromDate || Filters.sendDate.toDate) filterString += `
        sendDate: {
          gte: ${Filters.sendDate.fromDate ? '"' + startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate() + '"' : null},
          lte: ${Filters.sendDate.toDate ? '"' + endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() + 'Z23:59:59"' : null},
        }`;
    }

    if('created' in Filters){
      const startDate = new Date(Filters.created.fromDate);
      const endDate = new Date(Filters.created.toDate);
      filterString += `
      created: {
        gte: ${Filters.created.fromDate ? '"' + startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate() + '"' : null},
        lte: ${Filters.created.toDate ? '"' + endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() + 'Z23:59:59"' : null},
      }
      `;
    }

    return filterString;
  }

  private _routeHasRunId(): boolean {
    return !this.router.url.includes('reports/report/');
  }

  private _getRunIdFromRoute() : string {
    return this.router.url.split(`reports/`)[1].split(`/`)[0];
  }

  private _getBillingPeriod(billingPeriod): any {
    try {
      const dbg = billingPeriod.substring(0, 7).replace('-','_');
      console.log("_getBillingPeriod",dbg)
      return dbg;
    }
    catch{
      return null
    }
  }

  private getAllAllowedReportsForMenu(allowedReports){
    const reports = [];

    Reports.AllReports.forEach((report: ReportObject) => {
      if(allowedReports.some(item => item.id == report.id)){
        reports.push(
          new MenuItem(CompaniesFormComponent, {
            title: report.name,
            description: report.description,
            actions:['filter'],
            icon: report.icon, 
            pack: 'oakmoon',
            route: 'reports/report/' + report.route,
            isRoute: true
          }),
        )
      } 
    });

    return reports
  }

  private getAllAllowedReportsForComponent(allowedReports) : ReportObject[]{
    const reports = [];
    Reports.AllReports.forEach((report: ReportObject) => {
      if(allowedReports.some(item => item.id == report.id)){
        reports.push(report)
      } 
    });

    return reports
  }
  
  private triggerDownloadEvent(data:any) {
    let filename = data.headers.get('Content-Disposition') || 'download.zip';
    const foundEq = filename.indexOf('=');
    if (foundEq > -1) {
      filename = filename.substr(foundEq + 1);
    }

    const url = window.URL.createObjectURL(data.body); // <-- work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }
}