import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ifs-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterContainer implements OnChanges {

  @Input() report;

  ngOnChanges(changes: SimpleChanges) {
    if ('report' in changes) {
        // console.log(changes);
        this.report = changes.report.currentValue;
     }
}    

}
