import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule, Route } from '@angular/router';
import { DashboardContainer } from './containers/dashboard/dashboard.container';
import { EntityCardContainer } from './containers/entity-card/entity-card.container';
import { EntityDetailContainer } from './containers/entity-detail/entity-detail.container';
import { EntityCardComponent } from './components/entity-card/entity-card.component';
import { EntityDetailComponent } from './components/entity-detail/entity-detail.component';
import { DashboardShellComponent } from './containers/cms-shell/cms-shell.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { SharedModule, AuthGuard } from '@InfoSlips/shared';
import { NebularModule } from '@InfoSlips/nebular';
import { ThemeConfigComponent } from './containers/theme-config/theme-config.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { InfoslipModule } from '@InfoSlips/infoslip';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { FileHandlerComponent } from './components/file-handler/file-handler.component';
import { ContentHandlerComponent } from './components/content-handler/content-handler.component';
import { EntityContainerComponent } from './containers/entity-container/entity-container.component';
import MonacoConfig from 'libs/shared/src/lib/monacoConfig';
import { EntityLinkComponent } from './components/entity-link/entity-link.component';
import { RunTemplateCardComponent } from './components/run-template-card/run-template-card.component';
import { UpdateRunTemplateComponent } from './containers/update-run-template/update-run-template.component';
import { ControlsModule } from '@InfoSlips/controls';
import { CmsPreviewEntityComponent } from './components/cms-preview-entity/cms-preview-entity.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { CmsHomeComponent } from './containers/cms-home/cms-home.component';
import { CmsGridComponent } from './components/cms-grid/cms-grid.component';
import { CmsFilterComponent } from './components/cms-filter/cms-filter.component';
import { CmsRecentComponent } from './components/cms-recent/cms-recent.component';
import { NgModelChangeDebouncedDirective } from './directives/ng-model-changed-dbounced';
import { CmsEditComponent } from './containers/cms-edit/cms-edit.component';
import { CmsEntitiesComponent } from './components/cms-entities/cms-entities.component';
import { CmsCrudComponent } from './components/cms-crud/cms-crud.component';
import { CmsEntityDetailsComponent } from './components/cms-entity-details/cms-entity-details.component';
import { CmsEntityFileComponent } from './components/cms-entity-file/cms-entity-file.component';
import { CmsEntityRevisionsComponent } from './components/cms-entity-revisions/cms-entity-revisions.component';
import { CmsEntityReferencesComponent } from './components/cms-entity-references/cms-entity-references.component';
import { CmsEntityCodeComponent } from './components/cms-entity-code/cms-entity-code.component'
import { CmsGridItemComponent } from './components/cms-grid-item/cms-grid-item.component';


export const cmsRoutes: Route[] = [
  {
    path: '',
    component: DashboardShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CmsHomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'edit/:id',
        component: EntityContainerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'entity/:id',
        component: CmsEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'add/:entityType',
        component: EntityContainerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'link',
        component: EntityLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'runtemplates/:id',
        component: UpdateRunTemplateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'theme',
        component: ThemeConfigComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    FormsModule,
    InfiniteScrollModule,
    NebularModule,
    SharedModule,
    MonacoEditorModule.forRoot(MonacoConfig),
    ColorPickerModule,
    ReactiveFormsModule,
    InfoslipModule,
    ControlsModule
  ],
  declarations: [
    DashboardShellComponent,
    DashboardContainer,
    EntityCardContainer,
    EntityDetailContainer,
    EntityCardComponent,
    EntityDetailComponent,
    ThemeConfigComponent,
    ColorInputComponent,
    FileHandlerComponent,
    ContentHandlerComponent,
    EntityContainerComponent,
    EntityLinkComponent,
    RunTemplateCardComponent,
    UpdateRunTemplateComponent,
    CmsPreviewEntityComponent,
    EntityListComponent,
    CmsHomeComponent,
    CmsGridComponent,
    CmsFilterComponent,
    CmsRecentComponent,
    NgModelChangeDebouncedDirective,
    CmsEditComponent,
    CmsEntitiesComponent,
    CmsCrudComponent,
    CmsEntityDetailsComponent,
    CmsEntityFileComponent,
    CmsEntityRevisionsComponent,
    CmsEntityReferencesComponent,
    CmsEntityCodeComponent,
    CmsGridItemComponent
  ],
  exports: [
    EntityCardContainer,
    EntityCardComponent,
    EntityListComponent
  ]
})
export class CmsModule {}
