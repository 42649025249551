import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoginModel, AuthError } from '@InfoSlips/models';
import { AuthService } from '@InfoSlips/iiab-auth';
import { routeAnimation } from '../../shared.module';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ifs-login',
  animations: [routeAnimation],
  template: `
    <ifs-logged-out-view @routeAnimation>
      <ifs-login-form class="full-width"
        [error]="error$ | async"
        (userLogin)="login($event)"
        (openId)="openIdNavigate($event)"
        [hasOtp]="hasOtp$"
        [loading]="isLoaded$"
      >
      </ifs-login-form>
    </ifs-logged-out-view>
  `,
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  error$: Observable<AuthError>;

  hasOtpSubject = new BehaviorSubject<Boolean>(false);
  hasOtp$ = this.hasOtpSubject.asObservable();

  isLoadedSubject = new BehaviorSubject<Boolean>(true);
  isLoaded$ = this.isLoadedSubject.asObservable();

  echoResult: Observable<any>;

  constructor(
    private iiabAuthService: AuthService, 
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute
    ) {}

  ngOnInit() {
    if(this.activatedRoute.snapshot.url.toString().toLocaleLowerCase().includes('logout')){
      console.log('Logged out');
      this.iiabAuthService.logout();
    }
  }

  login(auth: LoginModel) {
    this.isLoadedSubject.next(false);
    this.iiabAuthService.login(auth);
    this.iiabAuthService.lastError$.subscribe(error=>{
      if(!error)
        return;
      if(error.error.includes('otp')){
        this.hasOtpSubject.next(true);
      }
      this.isLoadedSubject.next(true);
    });
  }

  openIdNavigate(platform){
    throw new Error("Not implemeneted: login.component.ts");
    // window.location.href = this.iiabAuthService.openId(platform);
  }
}
