import { PackageModel, TabModel } from "../rest/PackageModel";

export interface InfoSlipEntity {
    slip: PackageModel;
    id: string;
    hasRequestedOtp?: boolean;
    urlId: string;
  }

  export interface InfoSlipState {
    activeTab: TabModel | null;
    currentInfoSlip: PackageModel | null;
    historyAgent: string;
  }
  
