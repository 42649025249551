import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProcessorService } from '@InfoSlips/iiab-api';
import { NbDialogService } from '@nebular/theme';
import { MonacoEditorConfig } from 'libs/dashboard/src/lib/monacoConfig';
import { Observable, of, Subject } from 'rxjs';
import { Class, PreProcessor } from '../../models/pre-processor';
import { exhaustMap } from 'rxjs/operators';


@Component({
  selector: 'ifs-admin-pre-processor',
  templateUrl: './pre-processor.component.html',
  styleUrls: ['./pre-processor.component.scss']
})
export class PreProcessorComponent implements OnInit {

  // Two Way Binded Pre-Processor
  @Input('PreProcessor') _PreProcessor;
  @Input() isNew = false;
  @Input() ConfigForProcessing;

  @Output('onChange') onChange = new EventEmitter<PreProcessor>();
  @Output() setConfig = new EventEmitter<string>();
  @Output() setCollectableAssembly = new EventEmitter<string>();

  @ViewChild('autoInput') input;

  isEnabled = false;
  // Read-Only Variables.
  currentPreProcessor: PreProcessor = null;
  currentPreProcessorConfig: string = null; 

  // Auto Complete Objects.
  preProcessors$: Observable<any[]>;
  preProcessorClassesSubject = new Subject<Class[]>();
  preProcessorClasses$ = this.preProcessorClassesSubject.asObservable();

  updateProcessorForm = this.fb.group({
    Id: [null],
    Name: [null],
    UseRawStreams: [false],
    Classes: [[{
      Id: [null],
      ClassName: [null],
      AssemblyName: [null],
      AssemblyIsNotCollectable: [false],
      ExpectConfiguration: [false],
      ConfigurationSample: [''],
      Interfaces: [[]],
      RequiredFiles: [[]],
    }]],
    ClassData: this.fb.group({
      Id: [null],
      ClassName: [null],
      AssemblyName: [null],
      AssemblyIsNotCollectable: [false],
      ExpectConfiguration: [false],
      ConfigurationSample: [''],
      Interfaces: [[]],
      RequiredFiles: [[]],
    })
  });

  processorFilter = '';

  constructor(
    private fb: FormBuilder,
    public monacoConfig: MonacoEditorConfig,
    private processorService: ProcessorService,
    private dialogService: NbDialogService
  ) { }

   ngOnChanges(changes: SimpleChanges){
     this._PreProcessor = changes._PreProcessor?.currentValue;
     this.getPreProcessors();
  }

  ngOnInit(): void {
    this.processorService.preProcessors.loadPagedItems();
    this.getPreProcessors();

    // Define Read-Only Variables
    this.currentPreProcessor = Object.assign({}, this._PreProcessor);
    this.currentPreProcessorConfig = `${this.ConfigForProcessing}`;
    this.isEnabled = true;
    this._PreProcessor = null;

    if(Object.keys(this.currentPreProcessor).length === 0){
      this.isNew = true;
    }
  }

  onPreProcessorChange() {
    const wordSearch = this.input.nativeElement.value;

    this.preProcessors$ = null;

    const FilterObject = wordSearch.length === 24 ? { id: { eq: this.input.nativeElement.value } } : { name: { like: this.input.nativeElement.value } }

    setTimeout(() => {
      if (wordSearch == this.input.nativeElement.value) {
        this.processorService.preProcessors.loadPagedItems(this.input.nativeElement.value ? FilterObject : null);
        this.preProcessors$ = this.processorService.preProcessors.Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 600);
  }

  selectProcessor(processor){
    processor = this.updateProcessorCase(processor);
    processor.ClassData = processor.Classes[0];

    this.updateProcessorForm.patchValue(processor);
    this.onChange.emit({...this.updateProcessorForm.value});
  }

  selectClass(processorClass){
    this.updateProcessorForm.patchValue({
      ClassData: processorClass
    })
    this.onChange.emit({...this.updateProcessorForm.value});
  }

  open(dialog: TemplateRef<any>, context: string) {
    const config = `${this.currentPreProcessorConfig}`;

    this.dialogService.open(dialog, { context: {
      context: context,
      config: config
    } });
  }

  updateProcessorCase(processor){
    processor = this.formatObjectCase(processor);
    processor.Classes = processor.Classes.map(item => {
      item = this.formatObjectCase(item);
      item.RequiredFiles = item.RequiredFiles.map(file => file = this.formatObjectCase(file));
      delete item.__typename;
      return item;
    });
    processor.Files = processor.Files.map(item => item = this.formatObjectCase(item));
    
    return processor;
  }

  formatObjectCase(object){
    const keys = Object.entries(object);
    const capsKeys = keys.map((key) => [key[0][0].toUpperCase() + key[0].slice(1), key[1]]);
    return Object.fromEntries(capsKeys);
  }

  enableConfig(e){
    this.setConfig.emit(e ? this.updateProcessorForm.value.ClassData.ConfigurationSample : null);
  }

  assemblyNonCollectable(e){
    this.setCollectableAssembly.emit(e);
  }

  saveConfig(ref){
    this.setConfig.emit(this.updateProcessorForm.value.ClassData.ConfigurationSample);
    ref.close();
  }

  applyConfig(ref, updatedConfig){
    this.currentPreProcessorConfig = updatedConfig;
    this.setConfig.emit(updatedConfig);
    ref.close();
  }

  getPreProcessors(){
    this.processorService.preProcessors.Items$().subscribe((res: any) => {
      this.preProcessors$ = of(res);
      this.preProcessorClassesSubject.next(res.find(processor => {
        if(processor?.name === this._PreProcessor?.Name){
          const processorUppercase = this.updateProcessorCase(processor)?.Classes;
          this._PreProcessor.Classes = processorUppercase;
          return processorUppercase;
        }
      }));
    });
  }

  downloadProcessor() {
    const fileName = this.currentPreProcessor.ClassData.Id.split(',')[1].trim();
    this.processorService.download("pre", this.currentPreProcessor.Id, fileName);
  }
}
