<main class="content-area">
  <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-block">
        <h3>Change Password</h3>
        <h4 class="password-warning" *ngIf="(auth.weakPassword$ | async)?.weakPassword">
          {{ (auth.weakPassword$ | async)?.weakPasswordError }}
        </h4>
  
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="label">Current Password</label><br/>
              <input
                nbInput
                fullWidth
                fieldSize="medium"
                type="password"
                formControlName="currentPassword"
                required
                placeholder="current password"
              />
            </div>
      
              <div class="form-group">
                <label class="label">New Password</label><br/>
                <input
                  nbInput
                  fullWidth
                  fieldSize="medium"
                  type="password"
                  formControlName="password"
                  required
                  placeholder="password"
                />
              </div>
      
              <div class="form-group">
                <label class="label">Confirm Password</label><br/>
                <input
                  nbInput
                  fullWidth
                  fieldSize="medium"
                  type="password"
                  formControlName="confirmPassword"
                  required
                  placeholder="password"
                />
              </div>
            <p *ngIf="form.confirmPassword?.errors?.PasswordMatch">Passwords must match</p>
      
            <button
              outline
              nbButton
              status="primary"
              [disabled]="changePasswordForm.invalid"
            >
              Save
            </button>
          </div>

          <div class="col-md-6 pl-0">
            <div class="password-text">
              <p><b>New Password Requirements:</b></p>
              <ol>
                  <li *ngFor="let rule of passwordRules">
                      <p>{{ rule.label }} <b class="password-rule">{{ rule.value }}</b></p>
                  </li>
                  <li>
                      <p>At least <b class="password-rule">10</b> characters in length</p>
                  </li>
              </ol>  
            </div>
          </div>
        </div>
      </div>
    </form>
</main>
