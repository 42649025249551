import { Component } from '@angular/core';
import { slideInAnimation } from '@InfoSlips/shared';

@Component({
  selector: 'ifs-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css'],
  animations: [slideInAnimation]
})
export class SecurityComponent {

  constructor() {}
}
