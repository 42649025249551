<ng-container *ngTemplateOutlet="type === 'default' ? default : grid"></ng-container>

<ng-template #default>
  <div class="general-page-container full-height default-filters">
    <div class="general-page-body">
      <div class="scroll">
        <div class="input-group col-md-12">
          <div>
            <label>Date From</label>
            <input
              [nbDatepicker]="startDatePicker"
              [(ngModel)]="dateRangeStart"
              fullWidth
              nbInput
            />
            <nb-datepicker #startDatePicker></nb-datepicker>
          </div>
          <ng-container *ngIf="dateRangeStart">
            <label>Date To</label>
            <input
            [nbDatepicker]="endDatePicker"
            [(ngModel)]="dateRangeEnd"
            fullWidth
            nbInput
          />
            <nb-datepicker #endDatePicker></nb-datepicker>
          </ng-container>
          <!-- <nb-rangepicker #rangepicker></nb-rangepicker> -->
        </div>

        <div class="input-group col-md-12">
          <label>Customer</label>
          <input
            #customerAutoInput
            nbInput
            type="text"
            (input)="onChange('customer')"
            placeholder="Enter Customer Name"
            fullWidth
            [nbAutocomplete]="customerAuto"
            name="selectedCustomer"
            autocomplete="off"
            [(ngModel)]="selectedCustomer"
          />
          <nb-autocomplete #customerAuto (selectedChange)="selectedChange('customer')" [handleDisplayFn]="getIiabName">
            <nb-option
              *ngFor="let option of customerOptions"
              [value]="option"
            >
              {{ option.name }}
            </nb-option>
          </nb-autocomplete>
        </div>

        <div class="input-group col-md-12">
          <label>Template</label>
          <input
            #templateAutoInput
            nbInput
            type="text"
            (input)="onChange('template')"
            placeholder="Enter Run Template Name"
            fullWidth
            [nbAutocomplete]="templatesAuto"
            name="selectedTemplate"
            autocomplete="off"
            [(ngModel)]="selectedTemplate"
          />
          <nb-autocomplete #templatesAuto (selectedChange)="selectedChange('template')" [handleDisplayFn]="getIiabName">
            <nb-option
              *ngFor="let option of templateOptions"
              [value]="option"
            >
              {{ option.name }}
            </nb-option>
          </nb-autocomplete>
        </div>

        <div class="input-group col-md-12">
          <label>Status</label>
          <nb-select placeholder="Run Status"  multiple [(ngModel)]="selectedRunStatus" [ngModelOptions]="{standalone: true}" nbSelect fullWidth>
            <nb-option *ngFor="let status of runStatusOptions()" [value]="status">{{status}}</nb-option>
          </nb-select>
        </div>

        <div class="input-group col-md-12">
          <label>Trial and Live</label>
          <nb-select placeholder="Trial and Live" [(ngModel)]="selectedRunState" [ngModelOptions]="{standalone: true}" nbSelect fullWidth>
            <nb-option *ngFor="let runState of runStateOptions()" [value]="runState">{{runState}}</nb-option>
          </nb-select>
        </div>
        <div class="input-group col-md-12">
          <button status="secondary" nbButton (click)="clear()">Clear Filters</button>
          
          <button status="primary" class="apply-button" nbButton (click)="activateFilter();toggleFilter.emit();">Search</button>
        </div>
        <div class="input-group col-md-6">
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #grid>
  <form autocomplete="nope">
    <div class="row m-0 filters grid-filters">

      <div class="form-group col-lg-2">
        <label>Date From</label>
        <input
          [nbDatepicker]="startDatePicker"
          [(ngModel)]="dateRangeStart"
          name="Date Start"
          fullWidth
          nbInput
        />
        <nb-datepicker #startDatePicker></nb-datepicker>
      </div>
      
      <div class="form-group col-lg-2" [ngClass]="{'greyed-out' : !dateRangeStart}">
          <label>Date To</label>
          <input
          [nbDatepicker]="endDatePicker"
          [(ngModel)]="dateRangeEnd"
          name="Date End"
          fullWidth
          nbInput
          [disabled]="dateRangeStart == null"
        />
        <nb-datepicker #endDatePicker></nb-datepicker>
      </div>

      <div class="form-group col-lg-2">
        <label>Customer</label>
        <input
          #customerAutoInput
          nbInput
          type="text"
          (input)="onChange('customer')"
          placeholder="Enter Customer Name"
          fullWidth
          [nbAutocomplete]="customerAuto"
          name="selectedCustomer"
          autocomplete="off"
          [(ngModel)]="selectedCustomer"
        />
        <nb-autocomplete #customerAuto (selectedChange)="selectedChange('customer')" [handleDisplayFn]="getIiabName">
          <nb-option
            *ngFor="let option of customerOptions"
            [value]="option"
          >
            {{ option.name }}
          </nb-option>
        </nb-autocomplete>
      </div>

      <div class="form-group col-lg-2">
        <label>Template</label>
        <input
          #templateAutoInput
          nbInput
          type="text"
          (input)="onChange('template')"
          placeholder="Enter Run Template Name"
          fullWidth
          [nbAutocomplete]="templatesAuto"
          name="selectedTemplate"
          autocomplete="off"
          [(ngModel)]="selectedTemplate"
        />
        <nb-autocomplete #templatesAuto (selectedChange)="selectedChange('template')" [handleDisplayFn]="getIiabName">
          <nb-option
            *ngFor="let option of templateOptions"
            [value]="option"
          >
            {{ option.name }}
          </nb-option>
        </nb-autocomplete>
      </div>

      <div class="form-group col-lg-2">
        <label>Status</label>
        <nb-select placeholder="Run Status" multiple [(ngModel)]="selectedRunStatus" [ngModelOptions]="{standalone: true}" nbSelect fullWidth>
          <nb-option *ngFor="let status of runStatusOptions()" [value]="status">{{status}}</nb-option>
        </nb-select>
      </div>
    
      <div class="form-group col-lg-2">
        <label>Trial and Live</label>
        <nb-select placeholder="Trial and Live" [(ngModel)]="selectedRunState" [ngModelOptions]="{standalone: true}" nbSelect fullWidth>
          <nb-option *ngFor="let runState of runStateOptions()" [value]="runState">{{runState}}</nb-option>
        </nb-select>
      </div>

      <div class="form-group col-lg-6 filter-buttons">
        <button status="primary" class="apply-filters" nbButton (click)="activateFilter()">
          Search
        </button>

        <button class="clear-filters" nbButton (click)="clear()">
          Clear Filters
        </button>         
      </div>

    </div>
  </form>
</ng-template>