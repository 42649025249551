<div class="runs-container">
  <nb-card class="block" [ngClass]="isFilterExpanded ? 'isFilterExpanded' : ''">
    <nb-card-header>
      <div class="run-header">
        <div class="header">
          <h4>RUNS</h4>
          <nb-toggle [(ngModel)]="showGrid" (checkedChange)="toggleGrid($event)">Show Grid</nb-toggle>
        </div>
        <nb-tabset class="white-tabs" fullWidth (changeTab)="changeRunType($event)">
          <nb-tab tabTitle="All" class="tab"></nb-tab>
          <nb-tab tabTitle="My" class="tab"></nb-tab>
          <nb-tab tabTitle="Scheduled" class="tab"></nb-tab>
        </nb-tabset>
      </div>
      <div class="grid header-fields" *ngIf="!showGrid">
        <div class="flex" style="width: 50%;">
              <p class="item-text">
                  Run Template Name / 
                  <a (click)="filterField('name')">Run Name</a> <nb-icon *ngIf="gridSort.name" pack="eva" [icon]="gridSort.name === 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon>
              </p>
        </div>

        <div class="flex item-info text-center ">
              <p class="item-text">
                Status / Stage 
              </p>
        </div>
    
        <div class="text-right item-info">
            <p class="item-text">
              <a (click)="filterField('lastUpdated')">Last Updated</a> <nb-icon *ngIf="gridSort.lastUpdated" pack="eva" [icon]="gridSort.lastUpdated === 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon>
            </p>
        </div>

        <div class="text-right item-actions">
          <p class="item-text">
            Actions
          </p>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body 
      infiniteScroll 
      [infiniteScrollDistance]="1" 
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
    >
      <ng-container *ngIf="loading && (this.userInputAction | async) == true">
        <div class="row" *ngIf="(runSummariesCount$ | async) > 1 && loading">
          <div *ngFor="let it of ghosts" class="col-md-12">
            <ifs-ghost-cards> </ifs-ghost-cards>
          </div>
        </div>
      </ng-container>


      <div class="no-runs" *ngIf="(runSummariesCount$ | async) === 0">
        <div>
          There are no runs matching your filter options.
        </div>
      </div>

      <ng-container *ngIf="!showGrid">
        <run-grid-item 
          *ngFor="let runDetails of runSummaries$ | async"
          [runDetails]="runDetails" 
          (click)="selectRun(runDetails)"></run-grid-item>
      </ng-container>

      <!-- <div *ngIf="runSummaries$ | async as runSummaries"> -->
      <table *ngIf="showGrid" [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">
        <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="defaultColumns"></tr>
        <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: defaultColumns"></tr>
  
        <ng-container *ngFor="let column of defaultColumns; let index = index"
                      [nbTreeGridColumnDef]="column"
                      [showOn]="getShowOn(index)">

          <ng-container *ngIf="column === 'runTemplateName'">
            <th class="table-heading" (click)="sortGrid('name')" nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
              {{defaultHeaders[0]}}
            </th>
          </ng-container>

          <ng-container *ngIf="column === 'name'">
            <th class="table-heading" nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
              {{defaultHeaders[1]}}
            </th>
          </ng-container>

          <ng-container *ngIf="column === 'instanceStatus'">
            <th class="table-heading" nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
              {{defaultHeaders[2]}}
            </th>
          </ng-container>

          <ng-container *ngIf="column === 'lastUpdated'">
            <th class="table-heading" (click)="sortGrid('lastUpdated')" nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
              {{defaultHeaders[3]}}
            </th>
          </ng-container>
      


          <td (click)="selectRun(row.data)"
            [ngClass]="{'status-trial-fade': row.data.isTrial, 'status-live-fade': !row.data.isTrial}" 
            [class]="row.data.instance.status === 'FAILED' ? 'status-failed-fade' : ''" 
            nbTreeGridCell *nbTreeGridCellDef="let row">

            <ng-container *ngIf="column === 'instanceStatus'">
              <div class="status-pill pull-right text-center full-width">
                <div class="top" [ngClass]="{'bg-warning': row.data.isTrial, 'bg-success': !row.data.isTrial}" [class]="row.data.instance.status === 'FAILED' ? 'bg-danger' : ''">
                    {{row.data.isTrial ? 'Trial' : 'Live'}}
                </div>
                <div class="bottom text-only" [class]="row.data.instance.status">
                    {{ row.data.instance.status }}
                </div>
              </div>
            </ng-container>
            
            <ng-container *ngIf="column !== 'instanceStatus' && column !== 'lastUpdated'">
              {{ row.data[column] || '-' }}
            </ng-container>

            <ng-container *ngIf="column === 'lastUpdated'">
              {{row.data.lastUpdated | timeago}}
            </ng-container>
              
          </td>
        </ng-container>
  
      </table>
  
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-2">
          <p class="total">Total Runs: <span>{{(runSummariesCount$ | async | number)}}</span></p>
        </div>
        <div class="col-8 pagination-container">
          <ifs-pagination #pagingComponent size="minimal" [isLoading]="userInputAction | async" [pageData]="{ itemsPerPage : runsPerPage, totalItems: (runSummariesCount$ | async | number), hasNextPage: (hasNextPage$ | async), hasPreviousPage : (hasPreviousPage$ | async)}" (pageChange)="pageChange($event)"></ifs-pagination>
        </div>
        <div class="col-2 page-size-container">
          <label for="page-size">Page Size</label>
          <nb-select id="page-size" [selected]="runsPerPage" (selectedChange)="changePageSize($event)">
            <nb-option [value]="5">5</nb-option>
            <nb-option [value]="10">10</nb-option>
            <nb-option [value]="15">15</nb-option>
            <nb-option [value]="25">25</nb-option>
            <nb-option [value]="50">50</nb-option>
          </nb-select>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</div>