<div *ngIf="innerWidth > 845" class="desktop" style="display: flex;">
    <div class="actions-container" style="max-width: 35%; background: #e1e1e1; min-width: 30%;">
        <div class="dashboard-detail-container">
            <div class="intro-container">
                <div class="intro"><h4>CMS Dashboard</h4>
                    <p>What would you like to do today?</p>
                </div>
            </div>

            <nb-card>
                <nb-card-body>
                    <div class="grid">
                        <button nbButton class="btn w-50" size="small" status="basic" routerLink="/cms/add/file">
                            <nb-icon pack="oakmoon" icon="file"></nb-icon> Add File
                        </button>
                
                        <button nbButton class="btn w-50" size="small" status="basic" routerLink="/cms/add/content">
                            <nb-icon pack="oakmoon" icon="content"></nb-icon> Add Content
                        </button>        

                        <button nbButton class="btn col-md-12" size="small" status="basic" routerLink="/cms/link">
                            <nb-icon pack="oakmoon-v2" icon="run"></nb-icon> Update Run Template
                        </button>
                    </div>

                </nb-card-body>
            </nb-card>

            <cms-recent></cms-recent>
        </div>
    </div>

    <div class="content-container">
        <cms-filter (expandFilter)="isFilterExpanded = !$event" type="grid"></cms-filter>
        <cms-grid [(isFilterExpanded)]="isFilterExpanded"></cms-grid>  
    </div>
    
</div>