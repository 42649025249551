import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'run-grid-item',
  templateUrl: './run-grid-item.component.html',
  styleUrls: ['./run-grid-item.component.scss']
})
export class RunGridItemComponent implements OnInit {

  @Input() runDetails: any = null;
  @Input() type = 'default';

  status: string;
  icon: string;

  constructor(
  ) { }

  ngOnInit() {
    if (this.runDetails.isTrial) {
      this.status = 'warning';
      this.icon = 'clock-outline';
    } else {
      this.status = 'success';
      this.icon = 'checkmark-circle';
    }

    if (this.runDetails.instance.status === 'FAILED') {
      if (!this.runDetails.isTrial) this.status = 'danger';
      this.icon = 'alert-triangle-outline';
    }
  }
}