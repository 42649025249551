import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { ToastrService } from 'ngx-toastr';
import { ForgotPasswordModel, ChangePasswordModel, ResetPasswordModel } from '@InfoSlips/models';
import { EnvironmentService } from '@InfoSlips/env';
import { AuthService } from './auth.service';
import { AuthUrlsService } from './auth-urls-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  // weakPasswordSubject = new BehaviorSubject<any>(null);
  // weakPassword$ = this.weakPasswordSubject.asObservable();

  constructor(
    private router: Router,
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService,
    private environment: EnvironmentService,
    private auth: AuthService,
    private urls: AuthUrlsService
  ) { }

  forgotPassword(email: string) {
    const requester = this.environment.applicationName == "admin" ? 0 : 1; 
    this.apiBaseService.executeGet<boolean>(this.urls.RequestForgotPassword(email, requester), "Forgot Password").subscribe(
      {
        next: result => {
          if (result) {
            this.router.navigateByUrl('/auth/login')
            this.toaster.success(
              'You will receive a reset email shortly',
              'Password reset success!'
            );
            return true;
          }

          this.toaster.error(
            'This email is not found on our system',
            'Email not found!'
          );
          return false;
        },
        error: error => {
          this.toaster.error(
            'This email is not found on our system',
            'Email not found!'
          );
          return false;
        }
      }
    );
  }

  forgotPasswordToken(token: string): Observable<ForgotPasswordModel> {
    return this.apiBaseService.executeGet<ForgotPasswordModel>(this.urls.SendForgotPasswordToken(token), "Forgot Password Token");
  }

  changePassword(model: ChangePasswordModel): void {
    this.apiBaseService.executePut<boolean>(this.urls.ChangePassword(), model, "Put Change Password")
      .subscribe(results => {
        
        if (this.environment.applicationName === 'admin' && results) {

          this.auth.weakPasswordSubject.next({
            weakPassword: false,
            weakPasswordError: null
          });

        }
        
        if (results) { 
          this.toaster.success('Password successfully changed!'); 
          const originalReRouteStrat = this.router.routeReuseStrategy;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/']);
          this.router.onSameUrlNavigation = 'ignore';
          this.router.routeReuseStrategy = originalReRouteStrat;
        } else {
          this.toaster.error('Password change failed!');
        }
      });
  }

  resetPassword(model: ForgotPasswordModel):void {
    this.apiBaseService.executePut<boolean>(this.urls.ResetPassword(), model, "Put Reset Password").subscribe(results => {
      if (results) {
        this.toaster.success('Password reset successfully!');
        this.router.navigateByUrl("/login")
      }
      else {
        this.toaster.error('Password reset failed!');
      }
    });
  }
}