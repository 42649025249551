import { Component, Input, OnInit } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import { RunRecipientSummary } from '@InfoSlips/models';
import { Observable } from 'rxjs';
import { RunModel } from '../../../../../models/src/lib/rest/Run';

@Component({
  selector: 'ifs-recipient-details',
  templateUrl: './recipient-details.component.html',
  styleUrls: ['./recipient-details.component.scss']
})
export class RecipientDetailsComponent implements OnInit {

  @Input() selectedRunService: SelectedRunService;
  selectedRun: RunModel;
  runRecipients$: Observable<RunRecipientSummary[]>;

  constructor(  ) { }

  ngOnInit() {
    this.selectedRunService.selectedRun$.subscribe(run=>{
      this.selectedRun = run;
      this.selectedRunService.filterRunRecipients(null);
      this.runRecipients$ = this.selectedRunService.filteredRunRecipients$;
    });
  }

  loadNextPage() {
    this.selectedRunService.pageFilteredRunRecipients();
  }
}
