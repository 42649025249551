<div class="d-flex">
    <div class="runs-column row">
        <cms-entities></cms-entities>
    </div>
    <div class="dashboard-detail-container" [ngClass]="{'full-width' : 'true'}" [class.active]="true">
        <div class="row flex-column">
           <cms-crud></cms-crud>        
        </div>
    </div>
</div>


