<form [formGroup]="loginForm" class="login" autocomplete="off">
  <div class="row">
    <div class="col-md-6">
      <p class="pull-left title" data-e2e="cardTitle">
        Sign in
      </p>
    </div>
    <div class="col-md-6">
      <a class="pull-right text-basic" data-e2e="registerButton" routerLink="/auth/register">
        Create an account?
      </a>
    </div>
  </div>
  <div class="form-body row">
    <div class="col-md-12">
      <nb-form-field>
        <input nbInput class="custom-input" fullWidth data-e2e="username" type="text" name="username"
          formControlName="username" placeholder="Username/Email/Mobile" />
      </nb-form-field>
      <ng-container *ngIf="username.invalid && username.touched">
        <p class="caption status-danger" *ngIf="username.errors?.required">
          Username is required!
        </p>
      </ng-container>
      <nb-form-field>
        <input [type]="getInputType()" class="custom-input" nbInput fullWidth data-e2e="password" formControlName="password" role="presentation" name="password" placeholder="Password" autocomplete="off" [style]="{'-webkit-text-security': (showPassword == true) ? 'disc' : 'none'}"/>
        <button type="button" nbSuffix nbButton ghost (click)="toggleShowPassword()">
          <nb-icon [icon]="showPassword ? 'view' : 'view-off'"
                   pack="oakmoon"
                   [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
          </nb-icon>
        </button>
      </nb-form-field>
      <ng-container *ngIf="password.invalid && password.touched">
        <p class="caption status-danger" *ngIf="password.errors?.required">
          Password is required!
        </p>
      </ng-container>
    </div>
    <div class="col-md-12">
      <a routerLink="/auth/forgot-password" class="pull-right">
        Forgot password?
      </a>
    </div>
    <div class="col-md-12 otp" *ngIf="hasOtp | async">
    <nb-form-field>
      <label class="warning">One time pin required</label>
      <input class="custom-input text-center" fullWidth nbInput formControlName="otp" type="text" name="otp" placeholder="OTP" />
    </nb-form-field>
    </div>
  </div>


  <div class="form-footer">
    <div class="row">
      <div class="col-md-12">

        <ng-container *ngIf="environment.applicationName === 'admin'">
          <ng-container *ngIf="!isProd; else productionSignIn">
            <button id="signIn" [nbSpinner]="!isLoaded" nbSpinnerStatus="info" nbButton fullWidth status="primary" (click)="login()">{{!isLoaded ? 'Loading' : 'Sign In'}}</button>
            <button class="mt-10" *ngIf="oldAdminEnabled" [nbSpinner]="!isLoaded" nbSpinnerStatus="info" nbButton fullWidth status="secondary" id="signInOldAdmin" data-e2e="submit" type="submit" (click)="login(true)" >{{!isLoaded ? 'Loading' :  'Use the old admin portal'}}</button>
          </ng-container>

          <ng-template #productionSignIn>
            <button id="signIn" [nbSpinner]="!isLoaded" nbSpinnerStatus="info" nbButton fullWidth status="primary" (click)="login()">{{!isLoaded ? 'Loading' : 'Sign In'}}</button>
            <button class="mt-10" *ngIf="oldAdminEnabled" [nbSpinner]="!isLoaded" nbSpinnerStatus="info" nbButton fullWidth status="secondary" id="signInOldAdmin" data-e2e="submit" type="submit" (click)="login(true)" >{{!isLoaded ? 'Loading' :  'Use the old admin portal'}}</button>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="environment.applicationName === 'viewer'">
          <button class="mt-10" id="signIn" [nbSpinner]="!isLoaded" nbSpinnerStatus="info" nbButton fullWidth
            status="primary" (click)="login()">
            {{!isLoaded ? 'Loading' : 'Sign In'}}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</form>