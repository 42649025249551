import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface clipItem {
  value: string,
  name: string
}

@Component({
  selector: 'ifs-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss']
})
export class ClipboardComponent {

  @Input() items: clipItem[] = [];

  constructor(
    private toastr: ToastrService,
  ) { }

  copy(value){
    navigator.clipboard.writeText(value);
    this.toastr.info('Copied to Clipboard!');
  }

}
