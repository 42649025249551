<div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Customer</th>
        <th>Date</th>
        <th>Runtemplate ID</th>
        <th>Success</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of (reportDetails$ | async)">
        <td><a [routerLink]="'../../../admin/customers/' + field?.customer.id">{{ field?.customer.name }}</a></td>
        <td>{{ field?.created.split("T")[0] }} ({{ field?.created.split("T")[1].split(".")[0] }})</td>
        <td><a [routerLink]="'../../../admin/runtemplates/' + field?.runTemplateId">{{ field?.runTemplateId }}</a></td>
        <td>{{ field?.isSucess ? '✅' : '❌' }}</td>
      </tr>
    </tbody>
  </table>

  <div class="row action-buttons">
    <div>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasPreviousPage" nbButton (click)="pageReport('previous')">Previous</button>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasNextPage"  nbButton (click)="pageReport('next')">Next</button>
    </div>
    <p *ngIf="(reportDetailsPaging$ | async)?.totalCount > 0">{{(reportDetailsPaging$ | async)?.totalCount}} Items</p>
  </div>
</div>