import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RunActionsService, SelectedRunService } from '@InfoSlips/iiab-api';
import { RunModel, RunRecipientSummary } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ifs-trial-form',
  templateUrl: './trial-form.component.html',
  styleUrls: ['./trial-form.component.scss']
})
export class TrialFormComponent implements OnInit {

  @Input() selectedRunService: SelectedRunService;  
  @Output() emitRecipients: EventEmitter<RunRecipientSummary[]> = new EventEmitter();
  @Output() loadingRecipients: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('autoInput') input;

  selectedRun: RunModel;

  // #region FormValues  
  trialForm: FormGroup;
  submitted = false;

  recipientOptions = [
    "1 Random Recipient",
    "5 Random Recipients",
    "10 Random Recipients",
    "30 Random Recipients",
    "Individual Recipient Search",
    undefined,
    "Batch Recipient Search"
  ];
  get filteredTrialOptions() {
    return this.recipientOptions.filter(option => option !== undefined);
  }

  selectedRecipientOption = this.recipientOptions[0];

  securityOptions = [
    "My Personal User Key",
    "Sample Personal User Key",
    "No Personal User Key"
  ]

  overrideBranch;
  //#endregion FormValues
  isRunCompiled$;

  //#region Recipient Select Filter
  options: any[];

  constructor(
    private fb: FormBuilder, 
    private runActionsService: RunActionsService,
    private toastr: ToastrService
  ) {}


  ngOnInit() {      
    this.trialForm = new FormGroup({
      OverrideRunTemplateBranch: new FormControl(),
      SkipRefreshTemplateFolder: new FormControl(),
    });

    this.trialForm = this.fb.group({
      OverrideRunTemplateBranch: ['', Validators.required],
      SkipRefreshTemplateFolder: [false, Validators.required],
    });

    this.selectedRunService.selectedRun$.subscribe(run=>{
      this.selectedRun=run;

      if(run.TrialOptions !== null)
        this.selectedRecipientOption =  this.recipientOptions[run.TrialOptions.RecipientSelection];
      
      if (run.OverrideRunTemplateBranch != null && run.OverrideRunTemplateBranch.length > 0) {
        this.overrideBranch = true;
        this.trialForm.patchValue({
          OverrideRunTemplateBranch: run.OverrideRunTemplateBranch
        })
      } else {
        this.overrideBranch = false;
        this.trialForm.patchValue({
          OverrideRunTemplateBranch: ""
        })
      }

      this.isRunCompiled$ = this.selectedRunService.runIsCompiled$;
    });

    this.selectedRunService.runTrialRecipients$.subscribe(res => {
      if (this.selectedRun.Instance.Status === 6 && res.length == 0) { //newly processed run without trial recipients
        this.refreshTrialRecipients()
      } else {
        this.emitRecipients.emit(res);
        this.loadingRecipients.emit(false);
      }
    });
  }

  onChange() {
    const FilterObject = {
      externalId: {
        nin: this.selectedRun.TrialOptions.RecipientList
      },
      or: [
        { externalId: { like: this.input.nativeElement.value }},
        { name: { like:  this.input.nativeElement.value }}
      ]
    }
    this.selectedRunService.filterRunRecipients(this.input.nativeElement.value ? FilterObject : null);
  }

  onSelectionChange($event) {   
    this.input.nativeElement.value = null;
    this._add($event);
  }

  //#endregion Recipient Select Filter

  setEmailUsers($event){
    this.selectedRun.TrialOptions.SendToUser = $event.replace(/\s/g, "");;
  }

  send(){
    const OverrideRunTemplateBranch = this.overrideBranch && this.trialForm.value.OverrideRunTemplateBranch != "" ? this.trialForm.value.OverrideRunTemplateBranch : "";
    const TrialOptions = this.getTrialOptions();

    if ((TrialOptions.RecipientSelection == 4 || TrialOptions.RecipientSelection == 6) && TrialOptions.RecipientList.length < 1) {
      this.toastr.warning("No recipients selected", "Warning");
      return;
    }

    this.runActionsService.sendTrial(this.selectedRun, true, TrialOptions, OverrideRunTemplateBranch);
  }

  downloadExportFiles(){
    this.runActionsService.downloadExportFiles(this.selectedRun.Id);
  }

  refreshTrialRecipients(){

    this.loadingRecipients.emit(true);

    const TrialOptions = this.getTrialOptions();
    if (TrialOptions.RecipientSelection === 4 || TrialOptions.RecipientSelection === 6) {
      this.selectedRunService.filterRunRecipients(null);
      this.emitRecipients.emit([]);
      this.loadingRecipients.emit(false);
      this.selectedRun.TrialOptions = TrialOptions;
      this.selectedRunService.broadCastRunChanged(this.selectedRun);
    }
    else {
      this.runActionsService.refreshTrialRecipients(this.selectedRun.Id, TrialOptions).subscribe(res => {
        this.emitRecipients.emit(res);
        this.loadingRecipients.emit(false);        
        this.selectedRun.TrialOptions = TrialOptions;
        this.selectedRunService.broadCastRunChanged(this.selectedRun);
      });
    }
  }

  batchExternalIds(): string {
    return this.selectedRunService.selectedRunRecipients.map(recipient => recipient.externalId).join(',');
  }

  updateBatchRecipients($event :string) {
    const ids = $event.split(/[,;]/).map(id => id.trim()).filter(id => id);
    this.selectedRunService.selectedRunRecipients = ids.map(id => this.dummyRunRecipientSummary(id));
  }

  // #region Private

  private getRecipientList(recipientSummaryList: RunRecipientSummary[]){
    let recipientList;
    
    try {
      recipientList = recipientSummaryList.map((recipient) => {
        return recipient['externalId'];
      })
    } catch (error) {
      recipientList = [];
    }

    return recipientList;
  }

  private getTrialOptions(){
    const isRandom = this.isRandomRecipients();
    
    var retval = {
      "IsAutoTrialEnabled": true,
      "RecipientSelection": this.recipientOptions.findIndex((item) => item === this.selectedRecipientOption),
      "UserKeyOption":  2,
      "FilterOutputType": 0,
      "SendToUser": this.selectedRun?.TrialOptions.SendToUser.toString().split(','),
      "EmailGroups": [],
      "EmailGroupEmails": [],
      "RecipientList": isRandom ? [] : this.getRecipientList(this.selectedRunService.selectedRunRecipients),
      "SkipRefreshTemplateFolder": this.trialForm?.value.SkipRefreshTemplateFolder ?? false
    };
    return retval;
  }

  private isRandomRecipients(){
    if(this.selectedRecipientOption === undefined){
      this.selectedRecipientOption = this.recipientOptions[this.selectedRun?.TrialOptions.RecipientSelection];
    }
    return this.selectedRecipientOption === "Individual Recipient Search" || this.selectedRecipientOption === "Batch Recipient Search" ? false : true;
  }

  private _add(item:RunRecipientSummary){
    const TrialOptions = this.getTrialOptions();
    if(this.selectedRunService.selectedRunRecipients.indexOf(item) === -1 && TrialOptions.RecipientList.indexOf(item.externalId) === -1){
      TrialOptions.RecipientList.push(item.externalId);
      this.selectedRun.TrialOptions.RecipientList.push(item.externalId);
      this.selectedRunService.selectedRunRecipients.push(item);
      this.selectedRunService.broadCastRunChanged(this.selectedRun);
      this.emitRecipients.emit(this.selectedRunService.selectedRunRecipients);
      
      const FilterObject = {
        externalId: {
          nin: TrialOptions.RecipientList
        }
      }
      this.selectedRunService.filterRunRecipients(FilterObject);
    }
  }

  private dummyRunRecipientSummary(externalId: string, onlineId?: string, id?: string, name?: string, email?: string, mobile?: string): RunRecipientSummary{
    const rrSum : RunRecipientSummary = {
      externalId : externalId,
      onlineId : onlineId ? onlineId : null,
      id : id ? id : null,
      name : name ? name : null,
      email : email ? email : null,
      mobile : mobile ? mobile : null,
    }
    return rrSum;
  }
  // #endregion
}
