<!-- 
<div class="row deliveryContainer">

 
</div> -->


<nb-card class="shadow">
  <nb-card-header>
    <h1 class="col-md-12 title">DELIVERABILITY</h1>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-3">
        <div class="chart-wrapper">
            <h1 class="percentage">
              {{ donutPercentage }}<small>%</small>
            </h1>
            <p class="description">
              DELIVERED
            </p>
            <canvas baseChart 
            [data]="doughnutChartData"
            [labels]="doughnutChartLabels"
            [chartType]="doughnutChartType"
            [colors]="doughnutColors"
            [legend]="false"
            [options]="doughnutChartOptions"
            >
          </canvas>
        </div>
    </div>
    <div class="col-md-2 deliveryDetails">
      <h6>SENT</h6>
      <h4>{{chartData.emailTotalCount | number}}</h4>
      <hr/>
      <h6>DELIVERED</h6>
      <h4>{{chartData.emailSuccessCount| number}}</h4>
    </div>
    <div class="col-md-4">
        <div class="chart-wrapper barChart">
            <canvas baseChart 
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType"
            [colors]="barChartColors">
          </canvas>
        </div>
    </div>
    </div>
  </nb-card-body>
</nb-card>