
<div class="expand-filter" [class.active]="filter">
  <div class="row"> 
    <div class="col-md-8">
      <h6 class="m-0 p-10">FILTER BY</h6>
    </div>
    <div class="col-md-4">
      <button nbButton ghost class="close pull-right m-5" (click)="toggleFilter()">
        <nb-icon icon="close" pack="oakmoon" size="28"></nb-icon>
    </button>    
    </div>
  </div>
  <ifs-run-filter (toggleFilter)="toggleFilter()"></ifs-run-filter>
</div>
<div class="runs-container">
  <div class="runs-header">

    <div class="run-header">
      <h4>RUNS</h4>
      <div #searchBox class="search-box">
        <nb-form-field>
          <input nbInput fullWidth type="text" name="search" placeholder="search by name or id" [(ngModel)]="searchPhrase" class="search" (ngModelChange)="search()" />
          <button *ngIf="!filter"  nbSuffix nbButton ghost (click)="toggleFilter()" [class.active]="filter">
            <nb-icon icon="filter" pack="oakmoon"></nb-icon>
          </button>
        </nb-form-field>
      </div>
    </div>

    <nb-tabset fullWidth (changeTab)="changeRunType($event)">
      <nb-tab tabTitle="All" [badgeText]="activeTab === 'All' ? (runSummariesCount$ | async | number) : ''" badgeStatus="basic"></nb-tab>
      <nb-tab tabTitle="My" class="tab" [badgeText]="activeTab === 'My' ? (runSummariesCount$ | async | number) : ''" badgeStatus="basic"></nb-tab>
      <nb-tab tabTitle="Scheduled" [badgeText]="activeTab === 'Scheduled' ? (runSummariesCount$ | async | number) : ''" badgeStatus="basic"></nb-tab>
    </nb-tabset>
    
    <div *ngIf="selectedRun" class="selected-run">
      <ifs-run-card [selected]="true" [runDetail]="getSelectedRunSummary()"></ifs-run-card>
      <hr/>
    </div>

    <div 
      class="runs-body"
      infiniteScroll 
      [infiniteScrollDistance]="1" 
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false" 
    >
      <ng-container *ngIf="(loading$ | async) == true && (this.userInputAction | async) == true">
        <div class="row">
          <div *ngFor="let it of ghosts" class="col-md-12">
            <ifs-ghost-cards> </ifs-ghost-cards>
          </div>
        </div>
      </ng-container>
      <div *ngIf="runSummaries$ | async as runSummaries">
        <div *ngFor="let run of runSummaries">
          <ifs-run-card *ngIf="selectedRun?.Id !== run.id" [runDetail]="run" (click)="selectRun(run)"></ifs-run-card>
        </div>
      </div>
    </div>
    <hr style="margin: 10px 0px 0px 0px;"/>
  </div>
  <div class="runs-footer">
    <ifs-pagination [isLoading]="(userInputAction | async)" [size]="'minimal'" [pageData]="{ itemsPerPage : runsPerPage, totalItems: (runSummariesCount$ | async | number), hasNextPage: (hasNextPage$ | async), hasPreviousPage : (hasPreviousPage$ | async)}" (pageChange)="pageChange($event)"></ifs-pagination>
  </div>
</div>