import { Injectable } from '@angular/core';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';
import { CustomerModel, DefaultModel, Defaults, IIABCacheKey, RunTemplateModel, TemplateModel } from '@InfoSlips/models';
import { map } from 'rxjs/operators';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})

export class CrudBaseService {
  constructor(
    private apiBaseService: ApiBaseService,
    private localStorageService: IiabLocalStorageService
  ) {
    this.getDefault<CustomerModel>(0);
    this.getDefault<TemplateModel>(1);
    this.getDefault<RunTemplateModel>(2);
  }

  async getDefault<T>(type: Defaults){
    return this.localStorageService.getWithExpiry<T>(this.determineKey(type)) ?? await this.storeDefault<T>(type);
  }

  async storeDefault<T>(type: Defaults){
    const expiryDate = new Date();
    console.log(expiryDate);
    // Expire the cached item every 30 days.
    expiryDate.setDate(expiryDate.getDate() + 30);

    return await this.apiBaseService.executeGet<DefaultModel>(`default/${Defaults[type]}`, `Get base object: ${type}`).pipe(map(response => {
      this.localStorageService.setWithExpiry<T>(this.determineKey(type), response.Value, expiryDate ,false);
      return response.Value
    })).toPromise()
  }

  determineKey(type: Defaults){
    let key;

    switch (type) {
      case Defaults.Customer:
        key = IIABCacheKey.DefaultCustomer;
        break;
        
      case Defaults.Template:
        key = IIABCacheKey.DefaultTemplate;
        break;

      case Defaults.RunTemplate:
        key = IIABCacheKey.DefaultRunTemplate;
        break;
    }
    return key;
  }
}