import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, PasswordService } from '@InfoSlips/iiab-auth';
import { PasswordMatch } from '@InfoSlips/shared';
import { BehaviorSubject } from 'rxjs';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent implements OnInit {
  changePasswordForm: FormGroup;
  passwordRules = [
    {
      label: 'At least one digit',
      value: `[0-9]`
    },
    {
      label: 'At least one lowercase character',
      value: `[a-z]`
    },
    {
      label: 'At least one uppercase character',
      value: `[A-Z]`
    },
    {
      label: 'At least one special character',
      value: `[*!@#$%^&(){}[]:;<>,.?/~_-=|\\]`
    },
  ];

  weakPasswordHandled: boolean;

  constructor(
    private passwordService: PasswordService,
    private wizardService: WizardService,
    private fb: FormBuilder,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.weakPassword$.subscribe(res => {
      
      if(this.weakPasswordHandled)
        return;

      this.weakPasswordHandled=true;

      this.auth.weakPasswordSubject.next({
        weakPassword: false,
        weakPasswordError: res?.weakPasswordError
      })
    })


    this.changePasswordForm = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: PasswordMatch('password', 'confirmPassword')
      }
    );
  }

  get form(){
    return this.changePasswordForm.controls;
  }

  onSubmit() {
    const { currentPassword: CurrentPassword, password: NewPassword } = this.changePasswordForm.value;
    this.passwordService.changePassword({ CurrentPassword, NewPassword });
  }

  cancel(){  
    this.wizardService.setState(0);
  }
}
