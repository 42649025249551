<div class="content-container full-height">
    <div class="activity-bar">
        <nb-list>
            <nb-list-item>
                <nb-icon routerLink="" class="logo" pack="oakmoon" icon="ifs-icon-white" (click)="routeHome()"></nb-icon>
            </nb-list-item>
            <nb-list-item (click)="backToAdmin()" *ngIf="menuConfig[context].backButton">
                <nb-icon pack="oakmoon" icon="navigate-left"></nb-icon>
            </nb-list-item>
            <nb-list-item routerLink="theme" *ngIf="menuConfig[context].cmsThemeButton">
                <nb-icon pack="eva" icon="color-palette"></nb-icon>
            </nb-list-item>
        </nb-list>
        <nb-list class="notifications">
            <nb-list-item (click)="changeTheme()">
                <nb-icon pack="oakmoon" icon="theme"></nb-icon>
              </nb-list-item>
            <nb-list-item (click)="logout()">
                <nb-icon nbTooltip="Sign Out" nbTooltipStatus="success" icon="power" pack="oakmoon"></nb-icon>
            </nb-list-item>
        </nb-list>
    </div>

    <div class="content-area full-height">
        <ng-content></ng-content>
    </div>

    <div class="mobile-nav">
        <nb-actions size="medium" fullWidth>
          <nb-action>
            <nb-icon routerLink="" pack="oakmoon" icon="ifs-icon-white" class="logo" (click)="routeHome()"></nb-icon>
          </nb-action>
          <nb-action (click)="backToAdmin()">
            <nb-icon pack="oakmoon" icon="navigate-left"></nb-icon>
          </nb-action>
          <nb-action (click)="changeTheme()">
            <nb-icon pack="oakmoon" icon="theme" class="theme"></nb-icon>
          </nb-action>
          <nb-action (click)="logout()">
            <nb-icon nbTooltip="Sign Out" nbTooltipStatus="success" icon="power" pack="oakmoon" class="logout"></nb-icon>
          </nb-action>
        </nb-actions>
      </div>
</div>
