import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { PageGraphQLData, RazorSMS, RazorTransform, RunRecipientModel, RunRecipientOutputChannel, RunRecipientReference, RunRecipientSummary } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import { RunsService } from '../services/runs.service';
import { ObservableWrapper } from '@InfoSlips/iiab-api';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';

interface RunRecipientResponse {
  RunRecipient: {
    ExternalId: string;
    Name: string;
    Id: string;
  };
  Run: {
    BilingDateDescription: string;
    RunDate: string;
    Name: string;
    Id: string;
  };
  FilesOnServer: any[];
}

@Injectable({
  providedIn: 'root'
})
export class RunRecipientService {

  runRecipients: ObservableWrapper<RunRecipientReference>;
  runRecipientSummaries: ObservableWrapper<RunRecipientSummary>;

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private runsService: RunsService,
    private router: Router
  ) {
    this.runRecipients = new ObservableWrapper<RunRecipientReference>(
      this.apiBaseService,
      this._pageRunRecipientsQuery(),
      this._fetchRunRecipientsData,
    false);

    this.runRecipientSummaries = new ObservableWrapper<RunRecipientSummary>(
      this.apiBaseService,
      this._pageRunRecipientSummaryQuery(),
      this._fetchRunRecipientSummaryData,
    false);
  }

  razorFilesSubject = new BehaviorSubject<any>(null);
  razorFiles$ = this.razorFilesSubject.asObservable();

  razorInputSubject = new BehaviorSubject<any>(null);
  razorInput$ = this.razorInputSubject.asObservable();

  razorPreviewSubject = new BehaviorSubject<any>(null);
  razorPreview$ = this.razorPreviewSubject.asObservable();

  selectedRunRecipientSubject = new BehaviorSubject<RunRecipientModel>(null);
  selectedRunRecipient$ = this.selectedRunRecipientSubject.asObservable();

  previewSMS(recipientId: string, smsBody: RazorSMS){
    const subject = new Subject<RazorSMS>();
    smsBody != null ? smsBody : this.runsService.selectedRun$.subscribe(res => smsBody = res.SmsTemplate);
    
    this.apiBaseService.executePut<RazorSMS>(`runRecipient/${recipientId}/PreviewSMS`, smsBody, "Get SMS Preview.").subscribe((results: RazorSMS) => {
      if(!results)
        this.toastr.error('Preview processed failed!', results.Message);

      subject.next(results);
    });

    return subject.asObservable();
  }

  previewRazor(recipientId: string, razorBody: any, sequence = 0){
    razorBody != null ? razorBody : razorBody = "Your InfoSlip for @Model.Sequence.Data.ACCOUNT_NUMBER is now ready";

    this.apiBaseService.executePut<any>(`runRecipient/${recipientId}/previewTemplate/${sequence}`, { "Body": razorBody }, "Get Razor Preview.").subscribe(results => {
      if(results.error)
        this.razorPreviewSubject.next(results.error.Message);
      else 
        this.razorPreviewSubject.next(results.Body);
    });
  }

  getTemplateFiles(templateId: string){
    this.apiBaseService.executeGetAny<string>(`template/gettemplatefiles/${templateId}`, "Get Template Files.").subscribe(results => {
      results ? this.toastr.success('Template files retrieved!') : this.toastr.error('Preview processed failed!');
      //@ts-ignore
      this.razorFilesSubject.next(results.filter(this.razorFilter));
    });
  }

  getTemplateFile(templateId: string, fileName: string){
    this.apiBaseService.executeGetAny<string>(`template/gettemplatefile/${templateId}/${fileName}/`, "Get Template File.", {responseType: 'text'}).subscribe((results) => {
      results ? this.toastr.success('Template files retrieved!') : this.toastr.error('Preview processed failed!');
      this.razorInputSubject.next(results);
    });
  }

  ifsFileData(recipientData: RunRecipientReference, sequence: number){
    this.apiBaseService.executeGetAny<any>(`runRecipient/${recipientData.id}/IfsFileData/${sequence}`, "Get IFS file data.", {responseType: 'text'}).subscribe(results => {
      results ? this.toastr.success('IFS data downloaded!') : this.toastr.error('IFS data download failed!');
      this.download(results, recipientData.externalId, "json.txt");
    });
  }

  runRecipientSpecificFiles(recipientData: RunRecipientReference){
    this.apiBaseService.executeGetAny<any>(`runRecipient/DownloadRunRecipientSpecificFiles/${recipientData.id}`, "Get Run Recipient Specific Files.", {responseType: 'blob'}).subscribe(results => {
      results ? this.toastr.success('Files downloaded!') : this.toastr.error('Files download failed!');
      this.download(results, `${recipientData.id}_SpecificFiles`, "zip");
    });
  }

  async hasRunRecipientSpecificFiles(recipientData: RunRecipientReference) {
    return this.apiBaseService.executePost<any>(`runRecipient/GetListOfRunRecipientSpecificFiles/${recipientData.id}`, null, "Get List of Run Recipient Specific Files.").pipe(map(res => {
      if (res && res.FilesOnServer.length > 0) return true;
      else return false;
    })).toPromise();
  }

  downloadIfsFile(recipientData: RunRecipientReference, sequence: number) {
    this.apiBaseService.executeGetAny<any>(`runRecipient/${recipientData.id}/IfsFile/${sequence}`, "Get IFS file.", {responseType: 'blob'}).subscribe(results => {
      results ? this.toastr.success('IFS downloaded!') : this.toastr.error('IFS download failed!');
      this.download(results, recipientData.externalId, "ifs");
    });
  }

  downloadExportedPdf(recipientData: RunRecipientReference, sequence: number){
    this.apiBaseService.executeGetAny<any>(`runRecipient/${recipientData.id}/PdfFileFromUrl/${sequence}`, "Download Export PDF", {responseType: 'blob', observe: 'response'}).subscribe((results: any) => {
      let fileName = results.headers.get('content-disposition') || recipientData.externalId + '_' + sequence;

      const foundEq = fileName.includes('=');
      if (foundEq) {
        fileName = fileName.split('filename=')[1];
      }

      if(!fileName.includes('.pdf')){
        fileName = fileName.concat('.pdf');
      }

      results.status === 200 ? this.toastr.success('Export download started!') : this.toastr.error('Export download failed!');
      
      this.download(results.body, fileName, null);
    });
  }

  download(results: any, fileName: string, extension: string){
     //@ts-ignore
     const file = new Blob([results], {type: extension});
     const a = document.createElement("a"), url = URL.createObjectURL(file);
         a.href = url;
         a.download = extension ? `${fileName}.${extension}` : fileName;
         document.body.appendChild(a);
         a.click();
         setTimeout(function() {
             document.body.removeChild(a);
             window.URL.revokeObjectURL(url);  
         }, 0); 
  }

  razorFilter(file: string){
    return file.includes("razor");
  }

  createRegisterRun(runId: string, runTemplateId: string){
    this.apiBaseService.executePut<any>(`${runId}/createRegisterRun/${runTemplateId}`, null, "Get Razor Preview.").subscribe(results => {
      results ? this.toastr.success('Recipient run created!') : this.toastr.error('Recipient run creation failed!', results.Message);
    });
  }

  deleteAndDecrementRunCounters(runRecipientId: string){
    return this.apiBaseService.executeDelete<any>(`runRecipient/DeleteAndDecrementRunCounters/${runRecipientId}`, "Delete and Decrement Run Counters.");
  }

  expireLiveRunRecipient(runRecipientId: string, message: string){
    const expirationMessage = {
      "ExpirationMessage": message
    }
    return this.apiBaseService.executePost<any>(`runRecipient/${runRecipientId}/ExpireRunRecipient`, expirationMessage, "Expire LIve Run Recipient.");
  }

  postRecipientFile(runId: string, file: File, isInclusion: boolean){
    const fileToUpload = file;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name); 

    const request = isInclusion ? 
      this.apiBaseService.executePostAny<string>(`runRecipient/PostRecipientIncludtionFile/${runId}`, formData, "Post Recipient Inclusion File.", null, { responseType: 'text'}) :
      this.apiBaseService.executePostAny<string>(`runRecipient/PostRecipientRemovalFile/${runId}`, formData, "Post Recipient Exclusion File.", null, { responseType: 'text'});

    request.subscribe((res: any) => {
      this.toastr.success(res)
    })
  }

  postExpireRecipientsFile(runId: string, file: File, expirationMessage: string){
    const fileToUpload = file;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name); 
    formData.append('ExpirationMessage', expirationMessage); 

    this.apiBaseService.executePostAny<string>(`run/${runId}/ExpireRunRecipients`, formData, "Post Recipient Expiration File.", null, { responseType: 'text'}).subscribe(result => {
      if (result) {
        this.toastr.success("Recipients Successfully Expired");
      } else {
        this.toastr.error('Failed to expire recipients!');
      }
    });
  }

  selectedRunRecipients(){
    let subject = new Subject<any>();
    this.apiBaseService.executeGetAny<any>(`RunRecipient?%24inlinecount=allpages&%24format=json&%24top=10&%24filter=(Run%2FId+eq+%275fb36a1156865d00018aa602%27+and+IsMarkedForTrial+eq+true)`, "Get Selected Recipients", {responseType: 'text'}).subscribe(results => {
      results ? this.toastr.success('IFS data downloaded!') : this.toastr.error('IFS data download failed!');
      //@ts-ignore
      subject.next(results);
    });
    return subject.asObservable();
  }

  getIfsInfo(recipientId: string){
    this.toastr.info("Retrieving InfoSlip Details.")
    return this.apiBaseService.executeGet(`runRecipient/${recipientId}/ifsInfo`, "IFS Info");
  }

  getRunRecipient(id:string){
    this.apiBaseService.executeGet<RunRecipientModel>(`runRecipient/${id}`, "GetRunRecipient").subscribe((res: any) => {
        this.selectedRunRecipientSubject.next(res);
      });
  }

  getOutputChannels(runRecipientId: string, runId: string){
    const subject = new Subject<any>();
    
    const model = {
      query: this._getOutputChannelLogsQuery(runRecipientId, runId),
      operationName: 'OutputChannelLogs'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Output Channels.').subscribe(async (res: any) => {
      subject.next(await this._fetchOutputChannelLogsData(res));
    })

    return subject.asObservable();
  }

  getOutputChannelsDistinct(recipientId: string, runId: string){
    const subject = new Subject<any>();
    
    const model = {
      query: this._getDistinctOutputChannelLogsQuery(recipientId, runId),
      operationName: 'DistinctOutputChannelLogs'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Output Channels.').subscribe(async (res: any) => {
      subject.next(await this._fetchOutputChannelLogsDistinctData(res));
    })

    return subject.asObservable();
  }

  getRunRecipientById(id: string, previousRoute?: string){
    const subject = new Subject<RunRecipientReference>();
    
    const model = {
      query: this._pageRunRecipientsQuery(),
      variables: {
        'where': {
          'id': {
              "eq": id
          }
        }
      },
      operationName: 'RunRecipients'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Run Recipient By ID.').subscribe((res: any) => {
      if(res.data.runRecipients && res.data.runRecipients.edges.length > 0){
         subject.next(res.data.runRecipients.edges[0].node);
      } else {
        this.handleNotExists(id, previousRoute)
      }
    })
  
    return subject.asObservable();
  }

  handleNotExists(id: string, previousRoute?: string){
    const redirectUrl = previousRoute ? `admin/recipients/${previousRoute}` : '/' 

    this.toastr.error(`The specified run recipient: ${id} does not exist.`);
    this.router.navigateByUrl(redirectUrl);
  }

  resendInfoSlip(id:string){
    this.apiBaseService.executeGet(`runRecipient/${id}/resendInfoSlip`, "Resend InfoSlip").subscribe();
  }

  downloadRemovedRunRecipients(runId: string) {
    this.apiBaseService.executeGetAny<any>(`runRecipient/DownloadRemovedRunRecipients/${runId}`, "Download Removed Run Recipients", {
      responseType: 'blob', observe: 'response'
    })
    .pipe(tap(this.triggerDownloadEvent), catchError((error: HttpErrorResponse): Observable<any> => {
      if(error.status === 400){
        error.error.text().then((text:string) => {
          this.toastr.info(text);
        });
      }
      return throwError(error);
    })).subscribe();
  }

  //#region Private
  private _pageRunRecipientsQuery() {
    return `query RunRecipients($where:RunRecipientFilterInput=null) { 
      runRecipients(where: $where){
        edges {
          node {
          name,
          id,
          externalId,
          email,
          mobile,
          isCompiled,
          isExported,
          isMarkedForTrial,
          onlineId
          publicId
          puk
          customer {
            name
            id
          }
          run {
            name
            id
          }
          runTemplate {
            id
            name
          }
          ifsFileData {
            sequence
            label
            groupName
          }
            possibleOutputChannels {
               key
              value
            }
            
          }
        }
      }
    }`
  }
  private _pageRunRecipientSummaryQuery() {
    return `query RunRecipients($where:RunRecipientFilterInput=null) { 
      runRecipients(where: $where){
        edges {
          node {
            id,
            name,
            created,
            createdBy,
            lastUpdated,
            lastUpdatedBy,
            externalId,
            email,
            mobile,
            onlineId,
            run {
              runDate
              name
            }
          }
        }
      }
    }`
  }

  private _getRunRecipientByIdQuery(){
    return `query UserById($where: UserFilterInput = null) {
      users(where: $where) {
        totalCount
        edges {
          node {
            id
            createdBy
            puk
            roleGroups
            rolesAdded
            rolesRemoved
            customerFilter
            runTemplateFilter
            password
            emails
            mobileNumbers
            country {
              id
              name
              callingCode
            }
            importToken
            otpEnabled
            otpSecret
            userName
            email
            isApproved
            isLockedOut
            forcePasswordReset
            failedPasswordAttemptCount
            isDeleted
            deleted
            lastPasswordChangedDate
            lastActivityDate
            lastLoginDate
            lastLockedOutDate
            failedPasswordAttemptWindowStart
            mobile
            displayName
            dateOfBirth
            isClientId
            created
            createdBy
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }
    `;
  }

  private _fetchRunRecipientsData(rawData: any): PageGraphQLData<RunRecipientReference> {
    try{
      return rawData.data.runRecipients as PageGraphQLData<RunRecipientReference>;
    }
    catch(e)
    {
      console.log("runRecipients That FAILED", rawData);
      return null;
    }
  }
  private _fetchRunRecipientSummaryData(rawData: any): PageGraphQLData<RunRecipientSummary> {
    try{
      return rawData.data.runRecipients as PageGraphQLData<RunRecipientSummary>;
    }
    catch(e)
    {
      console.log("runRecipients That FAILED", rawData);
      return null;
    }
  }

  private _getOutputChannelLogsQuery(runRecipientId: string, runId: string){
    return `query OutputChannelLogs { 
            outputChannelLogs(where: {
              runRecipient: {
                id: {
                  eq: "${runRecipientId}"
                }
              }
              run: {
              id: {
                eq: "${runId}"
              }
            }}){
          edges {
            node {
              id
              channel
              isTrial
              sendTo
              isSent
              isDelivered
              isTrial
              sendDate
              messageId
              resultMessage
              isResend
              isForward
              sequence
              filesSent {
                fileName
                sequence
                size
                pages
                contentType
                reportingMetaData
              }
              runRecipient {
                name
                id
              }
            }
          }
        }
      }`
  }

  private _getDistinctOutputChannelLogsQuery(recipientId: string, runId: string){
    return `query DistinctOutputChannelLogs { 
      distinctOutputChannelLogs(runRecipientId: "${recipientId}", runId:  "${runId}"){
        id
        channel
        isTrial
        sendTo
        isSent
        isDelivered
        isTrial
        sendDate
        messageId
        resultMessage
        isResend
        isForward
        sequence
        filesSent {
          fileName
          sequence
          size
          pages
          contentType
        }
        runRecipient {
          name
          id
        }
        failureType
      }
    }`
  }

  private _fetchOutputChannelLogsData(rawData: any): Promise<PageGraphQLData<RunRecipientOutputChannel>> {
    try{
      return new Promise(resolve => {
        resolve(rawData.data.outputChannelLogs.edges.map((item: any) => item.node) as PageGraphQLData<RunRecipientOutputChannel>)
      })
    }
    catch(e)
    {
      console.log("outputChannelLogs FAILED", rawData);
      return null;
    }
  }

  private _fetchOutputChannelLogsDistinctData(rawData: any): Promise<PageGraphQLData<RunRecipientOutputChannel>> {
    try{
      return new Promise(resolve => {
        resolve(rawData.data.distinctOutputChannelLogs as PageGraphQLData<RunRecipientOutputChannel>)
      })
    }
    catch(e)
    {
      console.log("outputChannelLogs FAILED", rawData);
      return null;
    }
  }
  
  private triggerDownloadEvent(data:any) {
    let filename = data.headers.get('Content-Disposition') || 'download.zip';
    const foundEq = filename.indexOf('=');
    if (foundEq > -1) {
      filename = filename.substr(foundEq + 1);
    }

    const url = window.URL.createObjectURL(data.body); // <-- work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }

  //#endregion
}