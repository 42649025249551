import { IiabId } from "./iiab-reference";
import { RunHistoryReference } from "./run";

export interface UserReference extends IiabId {
    createdBy:                        string;
    puk:                              null;
    roleGroups:                       string[];
    rolesAdded:                       string[];
    rolesRemoved:                     any[];
    customerFilter:                   string[];
    runTemplateFilter:                any[];
    password:                         string;
    emails:                           string[];
    mobileNumbers:                    any[];
    country:                          null;
    importToken:                      string;
    otpEnabled:                       boolean;
    otpSecret:                        null;
    userName:                         string;
    email:                            string;
    isApproved:                       boolean;
    isLockedOut:                      boolean;
    forcePasswordReset:               boolean;
    failedPasswordAttemptCount:       number;
    isDeleted:                        boolean;
    deleted:                          null;
    lastPasswordChangedDate:          null;
    lastActivityDate:                 null;
    lastLoginDate:                    null;
    lastLockedOutDate:                null;
    failedPasswordAttemptWindowStart: null;
    mobile:                           null;
    displayName:                      string;
    dateOfBirth:                      null;
    isClientId:                       boolean;
    created:                          string;
    lastUpdated:                      string;
    lastUpdatedBy:                    string;
}

export interface UserHistory {
    id:                    string;
    customerName:          string;
    customerId:            string;
    label:                 string;
    run:                   RunHistoryReference;
    historyAgent:          null;
    sequenceCommunication: SequenceCommunication[];
}

export interface SequenceCommunication {
    hasMailBody:           boolean;
    hasSmsBody:            boolean;
    hasCompiledIfs:        boolean;
    communicationChannels: any[];
}

export interface UserHistoryReference {
    data: {
        userHistory: {
            nodes: UserHistory[]
        }
    }
}