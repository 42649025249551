import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MonacoEditorConfig } from 'libs/dashboard/src/lib/monacoConfig';
import { Observable, of } from 'rxjs';
import { PreProcessor } from '../../models/pre-processor';
import { ProcessorService } from '../../services/processor.service';
import { PostProcessorReferenceModel } from '@InfoSlips/models';

@Component({
  selector: 'ifs-admin-post-processor',
  templateUrl: './post-processor.component.html',
  styleUrls: ['./post-processor.component.scss']
})
export class PostProcessorComponent implements OnInit, AfterViewInit {

  // Two Way Binded Post-Processor
  @Input('PostProcessor') _PostProcessor;
  @Output() updatePostProcessors = new EventEmitter<PostProcessorReferenceModel[]>();

  constructor(
    public monacoConfig: MonacoEditorConfig,
    private processorService: ProcessorService
  ) { }

  // Temp Variables.
  postProcessors: PreProcessor[] = [];

  // Auto Complete Objects.
  filteredPostProcessors: PreProcessor[] = [];
  postProcessors$: Observable<PreProcessor[]>;

  disabled = true;

  ngOnInit(): void {
    // Define Temp Variables
    this.postProcessors = Object.assign([], this._PostProcessor);

    this.processorService.postProcessorAssemblies().subscribe((res: any) => {
      this._PostProcessor.forEach((item, index) => {
        if(this.postProcessors.length !== 0){
          this.postProcessors[index].Classes = res.find(processor => processor.Name === item.Name).Classes;
        }
      });
      
      this.filteredPostProcessors = res;
      this.postProcessors$ = of(res);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.disabled = false;
    }, 500);
  }

  private filter(value: string): PreProcessor[] {
    const filterValue = value.toLowerCase();
    return this.filteredPostProcessors.filter(optionValue => optionValue.Name.toLowerCase().includes(filterValue));
  }

  onPostProcessorChange(value: string) {
    this.postProcessors$ = of(this.filter(value));
  }

  selectProcessor(processor, index){        
    this.postProcessors[index] = processor;
    this.postProcessors[index].ClassData = processor.Classes[0];
    this.updatePostProcessors.emit(this.mapProcessorsForRunTemplateModel());
  }

  selectClass(processorClass, index){
    this.postProcessors[index].ClassData = processorClass;
    this._PostProcessor[index] = Object.assign({}, this.postProcessors[index]);
    delete this._PostProcessor[index].Classes;
    this.updatePostProcessors.emit(this.mapProcessorsForRunTemplateModel());
  }

  selectNonCollectableAssembly() {
    this.updatePostProcessors.emit(this.mapProcessorsForRunTemplateModel());
  }

  add(){
    this.postProcessors.push(this.getBlankProcessor());
  }

  remove(index){
    this.postProcessors.splice(index, 1); 
    this.updatePostProcessors.emit(this.mapProcessorsForRunTemplateModel());
  }

  private getBlankProcessor(): PreProcessor {
    return {
      ClassData: {} 
    } as PreProcessor;
  }

  private mapProcessorsForRunTemplateModel(): PostProcessorReferenceModel[] {

    return this.postProcessors.map((proc) => ({
      ClassData: {
        Id: proc.ClassData.Id,
        ClassName: proc.ClassData.ClassName,
        AssemblyName: proc.ClassData.Id.split(',')[1],
        Interfaces: proc.ClassData.Interfaces,
        AssemblyIsNotCollectable: proc.ClassData.AssemblyIsNotCollectable
      },
      Id: proc.Id,
      Name: proc.Name
    }));
  }
}
