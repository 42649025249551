
import { Injectable } from '@angular/core';
import { ProgressCounters } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RunStatusService {

  displayRunStatus: boolean = false;
  displayStatusSubject = new BehaviorSubject<boolean>(false);
  displayStatus$ = this.displayStatusSubject.asObservable();

  constructor(){}

  toggleRunStatus() {
    this.displayRunStatus = !this.displayRunStatus;
    this.displayStatusSubject.next(this.displayRunStatus); 
  }

  closeRunStatus() {
    this.displayRunStatus = false;
    this.displayStatusSubject.next(false); 
  }
}

