
<ifs-logged-out-view style="margin-top: -20%">
    <div class="login-wrapper">
      <form class="login">
        <p class="title">
          Whoops!
        </p>
        <div class="row form-body">
          <div *ngIf="error.status === 400" class="col-md-12 text-center">
            <p>Invalid InfoSlip</p>
            <p>{{ error.error.Message }}</p>
          </div>
          <div *ngIf="error.status != 400" class="col-md-12 text-center">
            <p>The selected InfoSlip has expired.</p>
          </div>
          </div>
      </form>
    </div>
  </ifs-logged-out-view>