<div class="row">
  <div class="col-md-12">
    <span>FILTER BY</span>
  </div>
</div>
<ng-container *ngIf="filterContext === 'Recipients'">
  <div class="row">
    <div class="col-md-12">
      <form>
        <div class="row">
          <div class="col-md-3">
            <input
              nbInput
              name="externalId"
              [ngModel]="externalId"
              (ngModelChange)="onChange($event, 'externalId')"
              placeholder="Filter by external id"
            />
          </div>
          <div class="col-md-3" *ngIf="innerWidth >= 480">
            <input
              nbInput
              name="name"
              [ngModel]="name"
              (ngModelChange)="onChange($event, 'name')"
              placeholder="Filter by name"
            />
          </div>
          <div class="col-md-3">
            <input
              nbInput
              name="email"
              [ngModel]="email"
              (ngModelChange)="onChange($event, 'email')"
              placeholder="Filter by email"
            />
          </div>
          <div class="col-md-3" *ngIf="innerWidth >= 480">
            <input
              nbInput
              name="mobile"
              [ngModel]="mobile"
              (ngModelChange)="onChange($event, 'mobile')"
              placeholder="Filter by mobile"
            />
          </div>
        </div>
      </form>
    </div>
  </div>

  <br />

  <div class="button-container">
    <button nbButton class="btn" status="primary" (click)="clear(filterContext)" [size]="innerWidth <= 480 ? 'small' : 'medium'">
      Clear
    </button>
  
    <input class="file-upload" type="file" id="Inclusion" (change)="postRecipientFile($event, 'Inclusion')"/>
    <input class="file-upload" type="file" id="Exclusion" (change)="postRecipientFile($event, 'Exclusion')"/>
    <input class="file-upload" type="file" id="Expiration" [disabled]="!expirationReason.value" (change)="postExpireRecipientFile($event, expirationReason.value)"/>
  
    <button *ngIf="selectedRun?.IsTrial" [size]="innerWidth <= 480 ? 'small' : 'medium'" nbButton class="btn" outline status="primary">
      <label for="Inclusion">Include Recipients</label>
    </button>
    <button *ngIf="selectedRun?.IsTrial" [size]="innerWidth <= 480 ? 'small' : 'medium'" nbButton class="btn" outline status="primary">
      <label for="Exclusion">Remove Recipients</label>
    </button>
    <button *ngIf="authService.isSystemAdmin && !selectedRun?.IsTrial && confirmExpiration == false" 
      [size]="innerWidth <= 480 ? 'small' : 'medium'" 
      (click)="confirmExpiration = true"
      nbButton class="btn" outline status="primary">
      Expire Recipients
    </button>
    <button *ngIf="confirmExpiration" 
      [size]="innerWidth <= 480 ? 'small' : 'medium'" 
      (click)="confirmExpiration = false"
      nbButton class="btn" outline status="warning">
      Cancel
    </button>
    <input nbInput type="text" #expirationReason fullWidth [style.display]="confirmExpiration ? 'inline' : 'none'">
    <button *ngIf="confirmExpiration" [disabled]="!expirationReason.value"
      [size]="innerWidth <= 480 ? 'small' : 'medium'" style="margin-left: 15px;" nbButton class="btn" outline status="danger">
      <label for="Expiration">Upload Recipients to Expire</label>
    </button>
    <button *ngIf="selectedRun?.Instance.HasRemovedRunRecipients" [size]="innerWidth <= 480 ? 'small' : 'medium'" nbButton class="btn" status="primary" (click)="downloadRemovedRunRecipients()">
      Download Removed Recipients
    </button>
  </div>
</ng-container>

<ng-container *ngIf="filterContext === 'NotToSend'">
  <div class="row">
    <div class="col-md-12">
      <form>
        <div class="row">
          <div class="col-md-3">
            <input nbInput name="recipient" [ngModel]="recipient" (ngModelChange)="onIgnoredChange($event, 'recipient')" placeholder="Recipient"/>
          </div>
          <div class="col-md-3">
            <input nbInput name="reason" [ngModel]="reason" (ngModelChange)="onIgnoredChange($event, 'reason')" placeholder="Reason" />
          </div>
          <div class="col-md-3">
            <input nbInput name="externalId" [ngModel]="externalId" (ngModelChange)="onIgnoredChange($event, 'runRecipientExternalId') " placeholder="External id" />
          </div>
          <div class="col-md-3">
            <input nbInput name="runRecipient" [ngModel]="runRecipient" (ngModelChange)="onChangeIgnoredAddtional($event, 'name')" placeholder="Run Recipient"/>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <nb-radio-group name="channel" [ngModel]="channelOption" (ngModelChange)="onIgnoredChange($event, 'channel', true)">
        <nb-radio *ngFor="let option of channelOptions" value="{{ option }}">
          {{ option }}
        </nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <br />
  <button [size]="innerWidth <= 480 ? 'small' : 'medium'" nbButton class="btn" outline status="primary" (click)="clear(filterContext)">
    Clear
  </button>
</ng-container>
