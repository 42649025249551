import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService } from '@InfoSlips/env';

@Pipe({
  name: 'safeIframe'
})
export class SafeIframePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private environmentService: EnvironmentService
  ) {}
  transform(url: string): any {
    let cleanURL = url;

    if (!cleanURL.startsWith('http')) {
      if (window.location.hostname.includes("infoslipscloud")) {
        cleanURL = window.location.href.split("/#/")[0] + url;
      } else {   
        let t = 0;
        cleanURL = `${this.environmentService.apiUrl}${url}`.replace(/\/\//g, match => ++t !== 1 ? '\/' : match);
      }
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(cleanURL);
  }
}