<div class="general-page-container fit-absolute">
    <div class="general-page-header">
        <form class="p-10">
            <div class="form-block">
              <div class="form-group">
                <label>Find {{ data.title }}</label>
                <nb-form-field>
                    <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth name="runTemplate" placeholder="Filter by Name or ID" [(ngModel)]="selectedProcessor" [ngModelOptions]="{standalone: true}"/>
                    <button nbSuffix nbButton ghost (click)="clearFilter()">
                        <nb-icon icon="close-outline" pack="eva"></nb-icon>
                    </button>
                </nb-form-field>
                <button *ngIf="hasKeys(filterObject)" class="col-md-4" size="small" nbButton status="primary" (click)="searchProcessors()">
                    Search
                </button>
              </div>
            </div>
          </form>
    </div>
    <div class="general-page-body">
        <div class="general-page-body">
            <div class="scroll p-10">
            <ng-container *ngFor="let item of processors$ | async">
              <ifs-wizard-generic-card [cardInfo]="item" [context]="data"></ifs-wizard-generic-card>
            </ng-container>
          </div>
        </div>
    </div>
    <div class="general-page-footer">
        <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
            Search
        </button>
        <button class="col-md-6" size="small" nbButton status="primary" [routerLink]="data.route">
            Create
        </button>
    </div>
  </div>