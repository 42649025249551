import { RunLogAction } from "@InfoSlips/models";
import { CustomerReference } from "./customer";
import { IiabId, IiabIdName } from "./iiab-reference";

export interface RunSummary  extends IiabIdName{
    runTemplate: IiabIdName;
    isTrial:     boolean;
    created:     string;
    createdBy:   string;
    customer:    CustomerReference;
    instance: {
        status: string
    }
    scheduleDateTime: string;
    lastUpdated: string;
}

export interface RunHistoryReference {
    id:                    string;
    name:                  string;
    runDate:               string;
    bilingDateDescription: string;
}

export interface RunLogs {
    id:                             string;
    action:                         RunLogAction;
    additionalActionInformation:    string;
    created:                        string;
    createdBy:                      string;
    isTrial:                        boolean;
    runId:                          string;
    lastUpdated:                    string;
    lastUpdatedBy:                  string;
}