import { Component, OnInit, Input } from '@angular/core';
import { BookmarkService } from '@InfoSlips/iiab-api';
import { Bookmark, BookmarkContext } from '@InfoSlips/models';

@Component({
  selector: 'ifs-bookmark-list',
  templateUrl: './bookmark-list.component.html',
  styleUrls: ['./bookmark-list.component.scss']
})
export class BookmarkListComponent implements OnInit {

  context = 'Recents';
  recents$ = this.bookmarkService.recents$;
  stars$ = this.bookmarkService.stars$;
  contextVal: string;
  searchValue;
  searchEnabled = false;

  data = this.bookmarkService.recents$;

  constructor(
    private bookmarkService: BookmarkService
  ) { }

  ngOnInit() {
    this.setBookmarkTab();
  }

  changeContext(e){
    this.context = e.tabTitle;
    this.setBookmarkTab();
  }

  favorite(item: Bookmark){
    this.bookmarkService.favorite(item);
  }

  clear(){
    this.bookmarkService.clear(BookmarkContext[this.contextVal]);
  }

  search(){
    this.searchEnabled = !this.searchEnabled;
    if(!this.searchEnabled){
      this.searchValue = null;
    }
  }

  setBookmarkTab(){
    if(this.context.toLowerCase()==="stars"){
      this.contextVal = BookmarkContext[0];
      this.data = this.stars$;
    } else {
      this.contextVal = BookmarkContext[1];
      this.data = this.recents$;
    }
    this.contextVal = this.context.toLowerCase()=== "stars" ?
    BookmarkContext[1]:
    BookmarkContext[0];
  }

  isArray(data: any): boolean {
    return Array.isArray(data);
  }

}
