import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { WizardComponent } from './containers/wizard/wizard.component';
import { NebularModule } from '@InfoSlips/nebular';
import { SetupFormComponent } from './components/setup-form/setup-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompaniesFormComponent } from './components/companies-form/companies-form.component';
import { UsersFormComponent } from './components/users-form/users-form.component';
import { MenuDirective } from './services/menu.directive';
import { MenuService } from './services/menu.service';
import { RunTemplateFormComponent } from './components/run-template-form/run-template-form.component';
import { TemplateFormComponent } from './components/template-form/template-form.component';
import { RunTemplateCardComponent } from './directives/run-template-card/run-template-card.component';
import { SharedModule } from '@InfoSlips/shared';
import { ProcessorFormComponent } from './components/processor-form/processor-form.component';
import { GenericWizardCardComponent } from './directives/generic-wizard-card/generic-wizard-card.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { ControlsModule } from '@InfoSlips/controls';
import { RecipientsFormComponent } from './components/recipients-form/recipients-form.component';
import { TestingRestrictionsFormComponent } from './components/testing-restrictions-form/testing-restrictions-form.component';

export const wizardRoutes: Route[] = [];

@NgModule({
  imports: [
    CommonModule, 
    RouterModule,
    NebularModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ControlsModule
  ],
  declarations: [
    WizardComponent,
    SetupFormComponent,
    CompaniesFormComponent,
    UsersFormComponent,
    MenuDirective,
    RunTemplateFormComponent,
    TemplateFormComponent,
    RunTemplateCardComponent,
    ProcessorFormComponent,
    GenericWizardCardComponent,
    PasswordFormComponent,
    RecipientsFormComponent,
    TestingRestrictionsFormComponent
  ],
  providers: [MenuService],
  entryComponents: [CompaniesFormComponent, SetupFormComponent],
  exports: [WizardComponent, RunTemplateFormComponent]
})
export class WizardModule {}
