import { AuthService } from '@InfoSlips/iiab-auth';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { IiabIdName, MiscellaneousModel, PageGraphQLData, RunTemplateModel, TemplateMergeFieldsModel, TemplateMergeFieldsNebular } from '@InfoSlips/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ObservableWrapper } from './base/observable-wrapper';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RunTemplateService {

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {

    this.runRunTemplates = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageRunTemplatesQuery(),
      this._fetchRunTemplatesData,
    false);


    this.runRunTemplatesWithSearchData = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageRunTemplatesWithSearchDataQuery(),
      this._fetchRunTemplatesData,
    false);

    this.authService.isAuthenticated$.subscribe(res => {
      if (res == true) {
        this.runRunTemplatesWithSearchData.loadPagedItems();
      }
    });
  }

  public runRunTemplates: ObservableWrapper<IiabIdName>;
  public runRunTemplatesWithSearchData: ObservableWrapper<IiabIdName>;

  selectedRunTemplateSubject = new BehaviorSubject<RunTemplateModel>(null);
  selectedRunTemplate$ = this.selectedRunTemplateSubject.asObservable();

  private _pageRunTemplatesQuery(first: number = 16) {
    return `
    query RunTemplates($first: Int = ${first}, $where: RunTemplateFilterInput = null) { 
      runTemplates(first: $first, where: $where, order: {name: ASC})
      {
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges{
          node{
            name
            id
            runSearchDataEnabled
            customer {
              name
              id
            }
            cmsEntities {
              type
              scope
              cmsContentType
              publicId
              useOffline
              specificVersion
              version
              length
              isDraft
              name
              id
            }
            template {
              name
            }
            created
            createdBy
            lastUpdated
            lastUpdatedBy
            useCms
          }
        }
      }
    }`;
  }

  private _pageRunTemplatesWithSearchDataQuery() {
    return `
    query RunTemplates($where: RunTemplateFilterInput = null) { 
      runTemplates(useSearchData: true, where: $where, order: {name: ASC})
      {
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges{
          node{
            name
            id
            runSearchDataEnabled
            customer {
              name
              id
            }
            cmsEntities {
              type
              scope
              cmsContentType
              publicId
              useOffline
              specificVersion
              version
              length
              isDraft
              name
              id
            }
            template {
              name
            }
            created
            createdBy
            lastUpdated
            lastUpdatedBy
            useCms
          }
        }
      }
    }`;
  }

  private _fetchRunTemplatesData(rawData: any): PageGraphQLData<IiabIdName> {
    try{
      return rawData.data.runTemplates as PageGraphQLData<IiabIdName>;
    }
    catch(e)
    {
      console.log("runRunTemplatesData That FAIlED", rawData);
      return null;
    }
  }

  selectRunTemplate(runTemplate: RunTemplateModel){
    this.selectedRunTemplateSubject.next(runTemplate);
  }

  deleteRunTemplate(runTemplateId: string){
    this.apiBaseService.executeDelete<boolean>(`RunTemplate/${runTemplateId}`, 'Delete Run Template').subscribe((res) => {
      if(res){
        this.toastr.info("Run Template Deleted!");
        this.runRunTemplates.loadPagedItems();
      }
      else {
        this.toastr.error("Run Template Delete Failed!");
      }
    })
  }

  getRunTemplate(runTemplateId: string){
    return this.apiBaseService.executeGet<RunTemplateModel>(`RunTemplate/${runTemplateId}`, "Get Run Template.");
  }
  
  templateMergeFields(runId: string){
    let subject = new Subject<TemplateMergeFieldsNebular>();
    
    this.apiBaseService.executeGet<TemplateMergeFieldsModel>(`runTemplate/TemplateMergeFields/${runId}`, "Get template merge fields.").subscribe((results: any) => {
      const map:any = (arr: TemplateMergeFieldsModel[]) => {
        return arr ? arr.map(fix) : null;
      }
      
      const fix:any = (item:TemplateMergeFieldsModel) => {
        return {
          data: {
            name: item.Name,
            path: item.Property
          },
          children: map(item.Children),
        };
      }
      
      subject.next(map(results));
    });

    return subject.asObservable();
  }

  miscellaneousContext(context: string){
    return this.apiBaseService.executeGet<MiscellaneousModel>(`miscellaneous/${context}/EnumValues`, "Get Miscellaneous.");
  }

  clearTemplateCache(runTemplateId: string){
    this.apiBaseService.executeGet(`RunTemplate/${runTemplateId}/ClearTemplateCache`, "Clear Run Template Cache.").subscribe(res => {
      res ? this.toastr.info("Template Cache Cleared") : this.toastr.error("Failed to clear template cache")
    });
  }

  updateRunTemplate(runTemplateModel: RunTemplateModel){
    return this.apiBaseService.executePut(`RunTemplate`, runTemplateModel ,"Update Run Template.").subscribe(res => {
      res ? this.toastr.success('Run Template Updated!') : this.toastr.error('Run Template Update Failed!');
    });
  }

  createRunTemplate<T>(runTemplateModel: RunTemplateModel){
    return new Promise<T>((resolve: any) => {
      this.apiBaseService.executePost(`RunTemplate`, runTemplateModel ,"Create RunTemplate.").subscribe((res: any) => {
        if(res){
          this.toastr.success("Run Template Created!")
          resolve({ 
            context: {
              title: 'Run Template Created!',
              body: 'Do you want to make additional changes? or Create a Run?',
              button: 'Create Run',
              routeEdit: `/admin/runtemplates/${res.Id}`,
              runTemplate: res
            }
          });
        } else {
          this.toastr.error("Run Template Creation Failed!");
        }
      });
    });
  }

  runTemplateContext(filter:any, pageSize: number = null):Observable<IiabIdName[]>{
    const contextWrapper = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageRunTemplatesQuery(),
      this._fetchRunTemplatesData,
    false);


    pageSize != null ? contextWrapper.loadPagedItems(filter, null, null, undefined, pageSize) : contextWrapper.loadPagedItems(filter);

    return contextWrapper.Items$();
  }

  filterByRunTemplateIds(runTemplateIds: string[]){
    const filter = {
      or: [] as any[]
    }
    
    runTemplateIds.forEach(id => {
      filter.or.push({
        id: {
          eq: id
        }
      })
    });

    const runTemplates = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageRunTemplatesQuery(),
      this._fetchRunTemplatesData,
    false);

    runTemplates.loadPagedItems(filter, null, null, undefined, 100);
    return runTemplates.Items$();
  }
}