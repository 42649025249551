import { NebularModule } from '@InfoSlips/nebular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SideNavComponent } from './containers/side-nav/side-nav.component';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { RunsComponent } from './containers/runs/runs.component';
import { DashboardShellComponent } from './containers/dashboard-shell/dashboard-shell.component';
import { RunComponent } from './containers/run/run.component';
import { StatsComponent } from './containers/stats/stats.component';
import { DashboardDetailComponent } from './containers/dashboard-detail/dashboard-detail.component';
import { RunCardComponent } from './components/run-card/run-card.component';
import { RunFilterComponent } from './components/run-filter/run-filter.component';
import { TrialDetailsComponent } from './containers/trial-details/trial-details.component';
import { TrialFormComponent } from './components/trial-form/trial-form.component';
import { RunUploadFormComponent } from './components/run-upload-form/run-upload-form.component';
import { ChartsModule } from 'ng2-charts';
import { DeliveryChartComponent } from './components/delivery-chart/delivery-chart.component';
import { ActivityBarComponent } from './containers/activity-bar/activity-bar.component';
import { BookmarkListComponent } from './components/bookmark-list/bookmark-list.component';
import { OverlayComponent } from './containers/overlay/overlay.component';
import { SharedModule, AuthGuard } from '@InfoSlips/shared';
import { RecipientsModule } from '@InfoSlips/recipients';
import { RunStatusComponent } from './components/run-status/run-status.component';
import { RunOptionsComponent } from './containers/run-options/run-options.component';
import { SmsTemplateComponent } from './components/sms-template/sms-template.component';
import { RazorTemplateComponent } from './components/razor-template/razor-template.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CreateRecipientsComponent } from './components/create-recipients/create-recipients.component';
import MonacoConfig from './monacoConfig';
import { SendLiveComponent } from './containers/send-live/send-live.component';
import { SendLiveFormComponent } from './components/send-live-form/send-live-form.component';
import { RunReportDownloadContainer } from './containers/run-report-download/run-report-download.component';
import { RunReportDownloadComponent } from './components/run-report-download/run-report-download.component';
import { RunReportComponent } from './containers/run-report/run-report.component';
import { ProcessorLogsComponent } from './containers/processor-logs/processor-logs.component';
import { RunLogsComponent } from './containers/run-logs/run-logs.component';
import { WizardModule} from '@InfoSlips/wizard';
import { RunsGridComponent } from './containers/runs-grid/runs-grid.component';
import { RunStageCardComponent } from './components/run-stage-card/run-stage-card.component';
import { RunGridItemComponent } from './components/run-grid-item/run-grid-item.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
//import { RunV2Component } from './containers/run-v2/run.component';


export const DashboardRoutes: Route[] = [
  {
    path: '',
    component: DashboardShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'run/:id',
        data: {
          context: 'Run',
          icon: 'run',
          canBookmark: true
        },
        component: RunComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'asyncreport/:id',
        component: RunReportDownloadContainer,  
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    ChartsModule,
    FormsModule,
    RecipientsModule,
    SharedModule,
    NebularModule,
    MonacoEditorModule.forRoot(MonacoConfig), // use forRoot() in main app module only.
    EditorModule,
    WizardModule,
  ],
  declarations: [
    RunsComponent,
    RunsGridComponent,
    DashboardShellComponent,
    RunComponent,
    StatsComponent,
    DashboardDetailComponent,
    SideNavComponent,
    RunCardComponent,
    RunFilterComponent,
    TrialDetailsComponent,
    TrialFormComponent,
    RunUploadFormComponent,
    DeliveryChartComponent,
    ActivityBarComponent,
    BookmarkListComponent,
    OverlayComponent,
    RunStatusComponent,
    RunOptionsComponent,
    SmsTemplateComponent,
    EmailTemplateComponent,
    RazorTemplateComponent,
    CreateRecipientsComponent,
    SendLiveComponent,
    SendLiveFormComponent,
    RunReportDownloadContainer,
    RunReportDownloadComponent,
    RunReportComponent,
    ProcessorLogsComponent,
    RunLogsComponent,
    RunStageCardComponent,
    RunGridItemComponent,
    ConfirmationDialogComponent
  ],
  exports: [InfiniteScrollModule, ActivityBarComponent ],
  providers: [DatePipe]
})
export class DashboardModule {}
