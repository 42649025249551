import { IiabId } from "./iiab-reference";
import { OutputChannelCodes } from "../Enums";

export interface BlacklistReference extends IiabId {
    channel?:                          OutputChannelCodes;
    created?:                          string;
    createdBy?:                        string;
    lastUpdated?:                      string;
    lastUpdatedBy?:                    string;
    reason?:                           string;
    recipient?:                        string;
    uniqueId?:                         string;
}