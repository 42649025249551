import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnvironmentService } from '@InfoSlips/env';
import { AuthService } from '@InfoSlips/iiab-auth';
import { LoginModel } from '@InfoSlips/models';

@Component({
  selector: 'ifs-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  showPassword = true;
  isLoaded = true;
  oldAdminEnabled = this.environment.oldAdminEnabled;
  @Input()
  error;
  @Input()
  loading;
  @Output()
  openId = new EventEmitter<string>();
  @Output() 
  userLogin = new EventEmitter<LoginModel>();
  @Input()
  hasOtp;
  isViewerLogin = true;
  isProd = false;

  constructor(
    private fb: FormBuilder,
    public environment: EnvironmentService,
    private authService: AuthService,
    private router: Router
    ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      otp: ['']
    });

    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      if(isAuthenticated){
        this.router.navigateByUrl("/");
      }
    })

    if(this.environment.oldAdminEnabled){
      this.isViewerLogin = false;
    }
    
    if(!window.location.href.includes("dev") && !window.location.href.includes("uat") && !window.location.href.includes("local")){
      this.isProd = true;
    } 
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get otp() {
    return this.loginForm.get('otp');
  }

  login(oldAdmin?: boolean){
    this.loading.subscribe(loading => {this.isLoaded = loading});
    let form = this.loginForm;

    if(oldAdmin){
      form.value.oldAdmin = true;
    }

    if (form.invalid) {
      return;
    }
    this.userLogin.emit({ ...form.value });
  }

  getInputType() {
    //important to not use (!this.environment.passwordAutoComplete) because the setting is only used on viewer
    if (this.environment.passwordAutoComplete == false) {
      return 'text';
    }
    else if (this.showPassword) {
      return 'password';
    }
    return 'text';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

}
