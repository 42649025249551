import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvConfig, EnvConfigToken } from './env-token.service';
import { EnvironmentService } from './environment.service';

@NgModule({
  imports: [CommonModule],
  providers: [EnvironmentService]
})
export class EnvModule {
  static forRoot(config: EnvConfig): ModuleWithProviders<EnvModule> {
    return {
      ngModule: EnvModule,
      providers: [
        {
          provide: EnvConfigToken,
          useValue: config
        },
        EnvironmentService
      ]
    };
  }
}
