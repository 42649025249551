import { OutputChannelCodes, OutputContentCodes, RunRecipientOpenRunStatus } from "../Enums";
import { BaseEntityIdNameModel, BaseEntityModel } from "./BaseEntity";
import { CustomerReferenceModel } from "./CustomerModels";
import { UploadedFileModel } from "./Processors";
import { RunReferenceModel } from "./Run";

export interface RunRecipientReferenceModel extends BaseEntityIdNameModel {
    ExternalId: string;
}

export interface RunRecipientModel extends BaseEntityModel{
    Puk: string;
    IsNew: boolean;
    IsCompiled: boolean;
    IsLive: boolean;
    LastException?: string;
    IsMarkedForTrial: boolean;
    MailSentCount: number;
    OnlineId: string;
    IsExported: boolean;
    HasExpired: boolean;
    IfsFileData: IfsFileDataModel[];
    StaticAttachments: StaticAttachmentModel[];
    OutputChannels: OutputChannelsModel;
    Customer: CustomerReferenceModel;
    ExternalId: string;
    Email: string;
    Mobile?: string;
    PublicId: string;
    Run: RunReferenceModel;
    RunTemplate: BaseEntityIdNameModel;
    OpenRunEnabled: boolean;
    OpenRunStatus: RunRecipientOpenRunStatus;
}

export interface OutputChannelsModel {
    Sms: OutputChannelSummaryModel[];
    Email: OutputChannelSummaryModel[];
    Export: OutputChannelSummaryModel[];
}

export interface OutputChannelSummaryModel {
    Channel: OutputChannelCodes;
    IsSent: boolean;
    IsDelivered: boolean;
    ResultMessage?: string;
    FilesSent: OutputFileMetaData[];
    SendDate: Date;
}

export interface OutputFileMetaData {
    Sequence: number;
    FileName: string;
    Size: number;
    Pages?: number;
    ContentType: OutputContentCodes;
    ReportingMetaData?: string;
}

export interface IfsFileDataModel {
    Sequence: number;
    Label: string;
    ProcessedFiles: string[];
    GroupName?: string;
    Data?: any;
}

export interface StaticAttachmentModel extends UploadedFileModel{
    IsSent: boolean;
}

export interface RunRecipientOutputChannel {
    channel:       string;
    isTrial:       boolean;
    sendTo:        string;
    isSent:        boolean;
    isDelivered:   boolean;
    sendDate:      string;
    sequence:      number;
    messageId:     null | string;
    resultMessage: null | string;
    isResend:      boolean;
    isForward:     boolean;
    filesSent:     FilesSent[] | null;
    failureType?:  string;
}

export interface FilesSent {
    fileName:          string;
    sequence:          number;
    size:              number;
    pages:             number;
    contentType:       string;
    reportingMetaData: null;
}
