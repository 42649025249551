import { INPUT_MODALITY_DETECTOR_DEFAULT_OPTIONS } from "@angular/cdk/a11y";

export interface ForgotPasswordModel {
    Email: string;
    Token: string;
    NewPassword: string;
    ConfirmPassword: string;
    UserName: string;
    DuplicateUsers: string[];
}

export interface ChangePasswordModel {
    CurrentPassword: string;
    NewPassword: string;
}

export interface ResetPasswordModel {
    email: string;
    token: string;
    newPassword: string;
    confirmPassword: string;
    userName: string;
    duplicateUsers: string[];
    selectedDuplicateUserId: string;
}
export interface WeakPasswordModel {
    weakPassword: boolean;
    weakPasswordError: string;
};