<div class="general-page-container fit-absolute">
  <div class="general-page-header">
      <h5><nb-icon pack="oakmoon" icon="send-email-envelope"></nb-icon> Create Run</h5>
    </div>
    <div class="general-page-body">
          <form [formGroup]="createRunForm" class="p-10">
            <div class="form-block">
              <div class="form-group">
                  <label>Run Template</label>
                  <ifs-run-template-lookup (ItemSelected)="onRunTemplateSelected($event)" [showLabel]="false"></ifs-run-template-lookup>  
              </div>
        
              <div class="form-group">
                <nb-form-field>
                  <label>Billing Period</label>
                  <nb-select placeholder="Run Period" [(ngModel)]="billingDate" [ngModelOptions]="{standalone: true}" nbSelect fullWidth (ngModelChange)="updateDescription($event)">
                    <nb-option *ngFor="let period of runPeriodList" [value]="period">{{period.Description}}</nb-option>
                  </nb-select>
                </nb-form-field>
              </div>
        
              <div class="form-group">
                <nb-form-field>
                  <label>Run Description</label>
                  <input nbInput fullWidth type="text" fullWidth name="runDescription" [(ngModel)]="runDescription" placeholder="" [ngModelOptions]="{standalone: true}" />
                </nb-form-field>
              </div>
              <div class="form-group">  
                <nb-checkbox (checkedChange)="toggleNotifications($event)"><label>Receive Run Report Emails?</label></nb-checkbox>
              </div>
            </div>
          </form>
    </div>
    <div class="general-page-footer">
      <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
        Cancel
      </button>
      <button (click)="createRun()" [disabled]="!selectedRunTemplate || !runDescription" class="col-md-6" size="small" nbButton status="primary">
        Create Run
      </button>
    </div>
  </div>