import { Component, ElementRef, ViewChild, AfterViewInit, Input} from '@angular/core';
import { CustomerService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { IiabLookupBaseComponent } from '../base/iiab-look-up-base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ifs-customer-lookup',
  templateUrl: './customer-lookup.component.html',
  styleUrls: ['./customer-lookup.component.scss']
})
export class CustomerLookupComponent extends IiabLookupBaseComponent<IiabIdName> implements AfterViewInit{

  @ViewChild('customerAutoInput')
  customerAutoInput!: ElementRef<HTMLInputElement>;

  @Input() showLabel = true;
  @Input() showClear = false;
  ngModel: IiabIdName | undefined;

  disabled = true;

  constructor(customerService: CustomerService) {
    super(customerService.customers);   
  }

  ngAfterViewInit(): void {
    if(this.ngModel){
      this.setSelectedItem(this.ngModel);
    }
    
    this.addInputElement(this.customerAutoInput.nativeElement);
    setTimeout(() => {
      this.disabled = false;
    }, 500);
  }

}