import { RecipientsModule } from './../../../../libs/recipients/src/lib/recipients.module';
import { DashboardModule, DashboardRoutes } from './../../../../libs/dashboard/src/lib/dashboard.module';
import { ProfileModule } from '@InfoSlips/profile';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EnvModule } from '@InfoSlips/env';
import { NxModule } from '@nrwl/angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SharedModule, AuthGuard, AuthModule, authRoutes, ShortUrlComponent  } from '@InfoSlips/shared';
import { CmsModule, cmsRoutes } from '@InfoSlips/cms'
import { GraphqlModule } from '@InfoSlips/graphql';
import { NebularModule } from '@InfoSlips/nebular';
import { FormsModule } from '@angular/forms';
import { reportsRoutes } from '@InfoSlips/reports';
import { iiabAdminRoutes } from '@InfoSlips/iiab-admin'
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DashboardModule,
    RecipientsModule,
    HttpClientModule,
    SharedModule,
    CmsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    RouterModule.forRoot(
      [
        {path: '', canActivate:[AuthGuard], children: [
          {
            path: '',
            children: DashboardRoutes,
            data: {
              animation: 'overview'
            }
          },
          {
            path: 'overview',
            children: DashboardRoutes,
            data: {
              animation: 'overview'
            }
          },
        {
          path: 'auth',
          children: authRoutes,
          data: {
            animation: 'auth'
          }
        },
        {
          path: 'cms',
          children: cmsRoutes,
          data: {
            animation: 'overview'
          }
        },
        {
          path: 'reports',
          children: reportsRoutes,
          data: {
            animation: 'overview'
          }
        },
        {
          path: 'admin',
          children: iiabAdminRoutes,
          data: {
            animation: 'overview'
          }
        },
        { path: '', redirectTo: 'auth', pathMatch: 'full' },
        { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
        { path: 'logout', redirectTo: 'auth/login', pathMatch: 'full' },
        { path: 'resetPassword/:token', redirectTo: 'auth/reset-password/:token', pathMatch: 'full' },
        { path: 'verifydetail/:token', redirectTo: 'auth/verify-detail/:token', pathMatch: 'full' },
        { path: 'completeregistration/:token', redirectTo: 'auth/register/confirm/:token', pathMatch: 'full' },
        { path: 't/:shortCode', component: ShortUrlComponent},
      ]}
    ],
      { initialNavigation: 'enabled',  useHash: true, relativeLinkResolution: 'legacy' }
    ),
    AuthModule,
    EnvModule.forRoot(environment),
    NxModule.forRoot(),
    NebularModule,
    GraphqlModule,
    ProfileModule,
    FormsModule ,
    NgxPaginationModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
