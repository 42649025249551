import { Component, Input, OnInit } from '@angular/core';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { CmsEntityModel } from '@InfoSlips/models';
import { MonacoEditorConfig } from 'libs/dashboard/src/lib/monacoConfig';

@Component({
  selector: 'ifs-admin-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {

  @Input('template') template;
  @Input('tinyEnabled') tinyEnabled: boolean;
  @Input() isNew = false;
  @Input() disabled = false;
  monacoEnabled: boolean = false;
  emailEntity: CmsEntityModel;

  constructor(
    public monacoConfig: MonacoEditorConfig,
    private apiBaseService: ApiBaseService
  ) { }

  emailSettings;

  ngOnInit(): void {
    this.emailSettings = [
      {
        "name": "ReplyTo",
        "label": "Reply to email address"
      },
      {
        "name": "From",
        "label": "Sent from email address"
      },
      {
        "name": "Cc",
        "label": "Email adresses to CC"
      },
      {
        "name": "Bcc",
        "label": "Email adresses to BCC"
      },
      {
        "name": "EmailCategoryHeader",
        "label": "Email category"
      },
      {
        "name": "Subject",
        "label": "Email subject line"
      },
      {
        "name": "MaximumAttachmentsPerEmail",
        "label": "Maximum number of email attachments"
      },

      {
        "name": "FromCondition",
        "label": "Code from condition"
      },
      {
        "name": "ReplyToCondition",
        "label": "Code reply to condition"
      },
      {
        "name": "CcCondition",
        "label": "Code CC condition"
      },
      {
        "name": "BccCondition",
        "label": "Code BCC condition"
      },
    ];

    if(this.disabled){
      this.emailEntity = this.template.CmsEntities.find(({CmsContentType}) => CmsContentType === 0);
      this.apiBaseService.executeGetAny<string>(`cms/publicid/${this.emailEntity.PublicId}/${this.emailEntity.Version}`, 'Get Online Content', {responseType: 'text'}).subscribe(content => {
         this.template.EmailTemplate.Body = content;
      });
    }
  }
  
  settingChange($event, name, type = 'template'){    
    if(type === "template"){
      this.template[name] = $event;
    } else {
      this.template.EmailTemplate[name] = $event; 
    }
  }

  toggleSource(){
    this.monacoEnabled = !this.monacoEnabled;
    this.tinyEnabled = !this.monacoEnabled;
  }
}
