import { LoadingScreenService } from './../../services/loading-screen.service';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ThemeService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-loading-screen',
  template: `
    <div class="loading-screen-wrapper">
      <div class="loading-screen-icon">
        <div *ngIf="!loader" class="loader load"></div>
        <img *ngIf="loader" [src]="loader">
      </div>
    </div>
  `,
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements AfterViewInit, OnDestroy {
  debounceTime = 200;
  loading = false;
  loadingSubscription: Subscription;
  loader = null;

  constructor(
    private loadingScreenService: LoadingScreenService,
    private _elmRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService
  ) {}

  ngOnInit(){
    this.themeService.theme$.subscribe(res => {
      this.loader = res?.Images.filter(image => image.Name.includes('-loader'))[0]?.Path || null;
    })
  }

  ngAfterViewInit(): void {
    // this._elmRef.nativeElement.style.display = 'none';
    this.loadingSubscription = this.loadingScreenService.loadingStatus
      .pipe(debounceTime(this.debounceTime))
      .subscribe((status: boolean) => {
        this._elmRef.nativeElement.style.display = status ? 'block' : 'none';
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
