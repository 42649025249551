//#region Run/RunRecipient
export enum OutputChannelCodes {
  Email,
  Sms,
  Export
}
export enum OutputContentCodes {
  Pdf,
  Ifs
}
//#endregion

//#region OpenRun
export enum OpenRunSchedule {
  NotSet,
  Monthly,
  Weekly,
  Daily
}
export enum RunRecipientOpenRunStatus {
  NotSet,
  Created,
  Processing,
  Complete,
  Failed
}
//#endregion

//#region CMS
export enum CmsContentType {
  EmailBody, // 0
  EmailSubject, // 1
  SmsBody, // 2
  DataModel, // 3
  Html, // 4
  Css, // 5
  Script, // 6
  Text, // 7
  Url, // 8
  Image, // 9
  Pdf, // 10
  Compressed, // 11
  Video, // 12
  VideoStream, // 13
  Audio, // 14
  AudioStream, // 15
  ThemeImages, // 16
  ThemePalette, // 17
  Markdown, // 18
  JsonSchema, // 19
  Font, // 20
  Xml, // 21
  Xslt // 22
}
export enum CmsEntityType {
  NotSet,
  File,
  Content
}
export enum CmsAudit {
  Created,
  Updated,
  Deleted
}
export enum CmsEntityScope {
  Public,
  Secure
}
//#endregion

//#region Dashboard
export enum BookmarkContext {
  recent,
  star
}
//#endregion

export enum ProtectionType {
  None,
  Hash,
  Mask,
  Encrypt
}

export enum Defaults {
  Customer,
  Template,
  RunTemplate
}

export enum ReprocessStageCodes{
  Skip,
  FailedOnly,
  EntireStage
}

export enum StateMenuContext {
  Menu,
  Runs,
  RunMenu,
  CMS
}

export enum RunLogAction {
    ATTACHMENT_UPLOADED,
    CANCELLED,
    COMPILE_STAGE_STARTED,
    COMPILE_STAGE_STOPPED,
    CONFIGURATION_UPLOADED,
    CONTROL_FILE,
    CREATE,
    CREATED,
    CUSTOM,
    DELETED,
    ENQUEUE,
    EXPORT_STAGE_STARTED,
    EXPORT_STAGE_STOPPED,
    FAILED,
    FILE_UPLOADED,
    HARD_DELETED,
    LAST_RECIPIENT_RECEIVED,
    POST_PROCESSOR_STAGE_STARTED,
    POST_PROCESSOR_STAGE_STOPPED,
    PRE_PROCESSOR_STAGE_STARTED,
    PRE_PROCESSOR_STAGE_STOPPED,
    PROCESSING_PROGRESS_COUNTERS,
    REMOVE,
    REMOVE_NEW_RECIPIENTS,
    REMOVE_OUTPUT_CHANNEL_LOGS,
    REMOVE_RUN_RECIPIENTS,
    REPROCESS_STAGE,
    RUN_BRANCH_CHANGED,
    RUN_DATA_REMOVED,
    RUN_SOURCE_DATA_REMOVED,
    SCHEDULED,
    SEND_STAGE_STARTED,
    SEND_STAGE_STOPPED,
    STARTED,
    STOPPED,
    WAITING
}