import { Component, OnInit, Input, ViewChild, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { NbDialogService, NbMenuItem, NbMenuService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { RunModel } from '@InfoSlips/models';
import { RunActionsService } from '@InfoSlips/iiab-api';
import { MonacoEditorConfig } from '../../monacoConfig';
import { EnvironmentService } from '@InfoSlips/env';

@Component({
  selector: 'ifs-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit, OnChanges {
  @Input() selectedRun: RunModel;
  @Input() events?: Observable<void>;
  @Input() theme?:string;

  @ViewChild('dialog') private dialog: TemplateRef<any>;
 
  emailHtml: string;
  emailSettings:any;
  menuItem = "Mailbody";
  code: string;

  // TinyMce 
  tinyMceConfig: any;
  tinyEnabled: boolean;

  items: NbMenuItem[] = [
    {
      title: 'Mailbody',
      icon: {
        icon: "view",
        pack:"oakmoon"
      }
    },
    {
      title: 'HTML',
      icon: {
        icon: "file-code-download-1",
        pack:"oakmoon"
      }
    },
    {
      title: 'Settings',
      icon: {
        icon: "multiple-actions-settings",
        pack:"oakmoon"
      }
    }
  ];

  constructor(
    private nbMenuService: NbMenuService,
    private dialogService: NbDialogService,
    public monacoConfig: MonacoEditorConfig,
    public environment: EnvironmentService,
    private runActionService: RunActionsService
  ) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.theme?.currentValue !== changes.theme?.previousValue){
      this.setEditor();
    }
  }

  ngOnInit() {
    this.emailHtml = this.selectedRun.EmailTemplate.Body;
    // console.log(this.theme);
    this.emailSettings = [
      {
        "name": "ReplyTo",
        "label": "Reply to email address",
        // "label": "If a recipient replies to an email sent to them, where should those replies go?",
        "model": this.selectedRun.EmailTemplate.ReplyTo
      },
      {
        "name": "From",
        "label": "Sent from email address",
        // "label": "What email address should these emails appear to be sent from?",
        "model": this.selectedRun.EmailTemplate.From
      },
      {
        "name": "Cc",
        "label": "Email addresses to CC",
        // "label": "What email addresses would you like to Cc?",
        "model": this.selectedRun.EmailTemplate.Cc
      },
      {
        "name": "Bcc",
        "label": "Email addresses to BCC",
        // "label": "What email addresses would you like to Bcc?",
        "model": this.selectedRun.EmailTemplate.Bcc
      },
      {
        "name": "EmailCategoryHeader",
        "label": "Email category",
        // "label": "What category does your email relate to?",
        "model": this.selectedRun.EmailTemplate.EmailCategoryHeader
      },
      {
        "name": "Subject",
        "label": "Email subject line",
        "model": this.selectedRun.EmailTemplate.Subject
      },
      {
        "name": "MaximumAttachmentsPerEmail",
        "label": "Maximum number of email attachments",
        "model": this.selectedRun.EmailTemplate.MaximumAttachmentsPerEmail
      },
    ];

    this.nbMenuService.onItemClick()
    .subscribe(menu => {
      this.menuItem = menu.item.title.toLowerCase();
    });

    this.tinyEnabled = true;
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  
  settingChange($event, name){
    this.selectedRun.EmailTemplate[name] = $event;
  }

  save(){
    // if(this.runDetails.EmailTemplate.Body !== this.emailHtml){
      this.selectedRun.EmailTemplate.Body = this.emailHtml;
      this.runActionService.updateRunTemplate(this.selectedRun);
    // }
  }

  setMenuItem(item){
    this.menuItem = item;
    // console.log(item);
  }

  setEditor(){
    this.tinyEnabled = false;
    if(this.tinyMceConfig){
      this.tinyMceConfig.skin = this.theme === 'light' ?  'oxide' : 'oxide-dark';
      this.tinyMceConfig.content_css = this.theme === 'light' ?  'default' : 'dark';
      setTimeout(() => {
        this.tinyEnabled = true;
      }, 0);
    }
    this.monacoConfig.editorOptions = {theme: this.theme === 'light' ?  'vs' : 'vs-dark', language: 'razor'};
  }
}