import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'ifs-forgot-password-form',
  template: `

      <form #forgotForm="ngForm" (ngSubmit)="onSubmit()" class="login">
      <div class="row">
        <div class="col-md-8">  
          <p class="title pull-left">
            Forgot Password
          </p>
        </div>
        <div class="col-md-4">
            <a
              routerLink="/auth/login"
              class="text-basic pull-right"
            >
              Login?
</a>
        </div>
      </div>
      <div class="form-body row">
        <div class="col-md-12">
            <input
              type="email"
              name="email"
              class="custom-input"
              [(ngModel)]="emailField"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
              placeholder="Please enter your email address"
            />
            <p
              >Email address required.</p
            >
        </div>
    </div>

        <div class="form-footer row">
          <div class="col-md-12">
            <button nbButton fullWidth status="primary" type="submit">
              Recover Password
            </button>
          </div>
        </div>
      </form>
  `,
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  @Output()
  forgotPassword = new EventEmitter<string>();

  emailField;
  @ViewChild('forgotForm') form: NgForm;

  constructor() {}

  ngOnInit() {}

  onSubmit() {
    const email = this.form.control.get('email');
    if (email.valid) {
      this.forgotPassword.emit(email.value);
      return;
    }
    email.markAsTouched();
    email.markAsDirty();
    email.updateValueAndValidity();
  }
}
