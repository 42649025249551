import { AccessTokenModel } from "./AccessTokens";
import { BaseEntityIdNameModel } from "./BaseEntity";
import { CmsEntityReferenceModel } from "./Cms";
import { ProcessingContainerModel } from "./ProcessingContainerModel";
import { PostProcessorReferenceModel, PreProcessorReferenceModel } from "./Processors";
import { TemplateModel } from "./TemplateModel";
import { TrialOptionsModel } from "./TrialOptionsModel";
import { DigitalSignaturesModel } from "./DigitalSignaturesModels";

export interface RunTemplateModel extends TemplateModel {
    Template:                       BaseEntityIdNameModel;
    TrialOptions:                   TrialOptionsModel;
    PreProcessor:                   PreProcessorReferenceModel;
    PostProcessors:                 PostProcessorReferenceModel[];
    AccessTokens:                   AccessTokenModel[];
    NotificationEmailRecipients:    string[];
    IsAllowedAdhocForward:          boolean;
    DisableApiForward:              boolean;
    TemplateBranch:                 string;
    TemplateFolder:                 string;
    IsCompileOnly:                  boolean;
    ExcludeIfsFile:                 boolean;
    IsPrivate:                      boolean;
    EmailAttachmentBatchSize:       number;
    CostCentre:                     string;
    Tags:                           string[];
    PackageVersion:                 string;
    Subdomain:                      string;
    DisableUrlSecurity:             boolean;
    HideRunNameOnViewer:            boolean;
    HideDataPromptsTitle:           boolean;
    DataPromptsTitle:               string;
    PushNotificationSenderType:     number;
    PreferredSmsSender:             number;
    MaxConcurrentRuns:              number;
    Meta1:                          string;
    Meta2:                          string;
    Meta3:                          string;
    Meta4:                          string;
    Meta5:                          string;
    ForceDataPrompts:               boolean;
    DontCreateHistory:              boolean;
    ConfigForProcessing:            string;
    HasExternalProcessing:          boolean;
    OtpSenderType:                  number;
    ProcessingContainer:            ProcessingContainerModel;
    CmsEntities:                    CmsEntityReferenceModel[];
    JsonSchema:                     CmsEntityReferenceModel;
    DataSchema:                     string;
    UseCms:                         boolean;
    OpenRunSchedule:                number;
    OpenRunNameTemplate:            string;
    AllowEmptyQuestionsForAdhoc:    boolean;
    AntiForgeryEnabled:             boolean;
    SearchFields:                   string[];
    RunSearchDataEnabled:           boolean;
    ProtectedFields:                ProtectedFields[];
    SearchDataCmsEntity:            CmsEntityReferenceModel;
    Signatures:                     DigitalSignaturesModel;
    ExcludeNewRecipientsFile:       boolean;
    ProcessSequencesSeparately:     boolean;
}

export interface ProtectedFields {
    SearchFieldLinkedDescription: string;
    Path:                         string;
    ProtectionType:               number;
    MaskCharacter:                null | string;
    MaskStartIndex:               number;
    MaskLength:                   number;
    IsDataPrompt:                 boolean;
    DataPromptIndex:              number;
}
