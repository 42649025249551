import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AggregatedRunStats } from '@InfoSlips/models';
import { ChartOptions, ChartType, ChartDataSets, ChartScales } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifs-delivery-chart',
  templateUrl: './delivery-chart.component.html',
  styleUrls: ['./delivery-chart.component.scss']
})
export class DeliveryChartComponent implements OnInit {

  @Input() chartData$: Observable<AggregatedRunStats>;
  chartData:AggregatedRunStats; 
  total: number;
  success: number;
  failed: number;

  doughnutChartLabels: Label[] = ['Delivered', 'Not Delivered'];
  doughnutChartData: MultiDataSet = [];
  doughnutColors: any;

  doughnutChartType: ChartType = 'doughnut';

  doughnutChartOptions: ChartOptions = {
    cutoutPercentage: 75
  }

  barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        ticks: {
          display: true
        },
        gridLines: {
          display: false
        },

      }],
      yAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    }
  };

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartColors = [];
  barChartData: ChartDataSets[] = [];
  donutPercentage: number = 0;

  constructor() {
    this.doughnutColors = [{ backgroundColor: ['#707070', '#F8F8F8'] }];
    this.barChartColors = [{ backgroundColor: ['#707070', '#707070', '#707070', '#707070', '#707070'] }];
  }

  ngOnInit() {

    if(!this.chartData$)
      return;

    this.chartData$.subscribe(chartData=>{

      this.chartData = chartData;

      this.doughnutChartData = [
        [
          this.getPercentage(chartData.emailTotalCount, chartData.emailSuccessCount),
          this.getPercentage(chartData.emailTotalCount, chartData.emailTotalCount - chartData.emailSuccessCount)
        ]
      ];
  
      if (isNaN(this.donutPercentage[0]) || isNaN(this.donutPercentage[1])) {
        //@ts-ignore
        this.doughnutChartData[0] = 0;
        //@ts-ignore
        this.doughnutChartData[1] = 100;
      }
  
      isNaN(this.getPercentage(chartData.emailTotalCount, chartData.emailSuccessCount)) ? 
        '' : 
        this.donutPercentage = this.getPercentage(chartData.emailTotalCount, chartData.emailSuccessCount);
    
      this.barChartData = [
        {
          data: chartData.periods.reduce((result, item) => {
            return [...result, item.emailSuccessCount];
          }, []), label: 'Delivered'
        }
      ];
      this.barChartLabels = chartData.periods.reduce((result, item) => {
        return [...result, item.billingPeriod];
      }, []);

    })


  }

  ngOnChanges(changes: SimpleChanges) {
    if ('deliveryData' in changes) {
      this.chartData = changes.deliveryData.currentValue;
      this.doughnutChartData = [
        [
          this.getPercentage(this.chartData.emailTotalCount, this.chartData.emailSuccessCount),
          this.getPercentage(this.chartData.emailTotalCount, this.chartData.emailTotalCount - this.chartData.emailSuccessCount)
        ]      ];
    }
  }

  getPercentage(total, part) {
    return Number(((part / total) * 100).toFixed());
  }

}
