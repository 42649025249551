import { AllowedActionsModel } from "./AllowedActionsModel";
import { BaseEntityModel } from "./BaseEntity";
import { RunRecipientReferenceModel } from "./RunRecipient";

export interface PackageModel extends BaseEntityModel {
    StartPage: string;
    Tabs: TabModel[];
    DataPrompts: string[];
    IsPukLess: boolean;
    AllowAdhocForward: boolean;
    Version: number;
    DataPromptsIsValid: boolean;
    PukValidated: boolean;
    Period: string;
    RunTemplate: string;
    IsAdhoc: boolean;
    Sequence: number;
    HasMobileHigh: boolean;
    HasMobileLow: boolean;
    IsTrial: boolean;
    UrlSecurity: boolean;
    RunRecipient: RunRecipientReferenceModel;
    UnpackDateTime: string;
    HideRunNameOnViewer: boolean;
    HideDataPromptsTitle: boolean;
    DataPromptsTitle: string;
    RunTemplateId: string;
    OnlineId: null;
    OneTimePin: OneTimePinModel;
    GeneratedPdfs: GeneratedPdfModel[];
    PdfGenerationStarted: boolean;
    PdfsStarted: GeneratedPdfModel[];
    AllowedActions: AllowedActionsModel;
}

export interface GeneratedPdfModel {
    FileName:    string;
    Success:     boolean;
    Size:        number;
    Error:       string;
    MachineName: string;
}

export interface OneTimePinModel {
    Id:           string;
    Request:      OneTimePinRequestModel;
    SenderType:   number;
    Validated:    boolean;
    Expired:      boolean;
    NumbersOnly:  boolean;
    Asynchronous: boolean;
    Message:      string;
}

export interface OneTimePinRequestModel {
    OtpRequestType: number;
    To:             string;
    ClientDetails:  OneTimePinClientDetailsModel;
    UserName:       string;
}

export interface OneTimePinClientDetailsModel {
    ClientIp:  string;
    UserAgent: string;
}

export interface TabModel {
    TabName:                    string;
    StartPage:                  string;
    Printable:                  boolean;
    ExternalConnectionRequired: boolean;
    IsSinglePage:               boolean;
    PathName:                   string;
    QueryString:                string;
}

export interface DataPromptModel {
    PackageId: string,
    RunRecipientId: string,
    Answers: [
      string
    ],
    HistoryAgentId: string
  }