<ifs-logged-out-view style="margin-top: -20%">
    <div class="login-wrapper">
        <form (ngSubmit)="validatePUK.emit(pukCode)" class="login">
            <p class="title">Personal User Key Required</p>
            <div class="row form-body">
                <div class="col-md-12 text-center">
                    <label>Enter PUK</label>
                    <input nbInput class="custom-input" fullWidth type="text" name="puk" [(ngModel)]="pukCode" placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX" />
                </div>
            </div>
            <div class="form-footer">
                <div class="row">
                    <div class="col-md-12">
                        <button [disabled]="pukCode?.length < 1 || pukCode?.length > 36 ? true : false" nbButton fullWidth status="primary" type="submit">Validate</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ifs-logged-out-view>