import { MonacoEditorConfig } from '../../monacoConfig';
import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RunRecipientService, RunsService, RunTemplateService } from '@InfoSlips/iiab-api';
import { RunModel, RunRecipientSummary, SMSTemplateModel, TemplateMergeFieldsNebular } from '@InfoSlips/models';

@Component({
  selector: 'ifs-sms-template',
  templateUrl: './sms-template.component.html',
  styleUrls: ['./sms-template.component.scss']
})
export class SmsTemplateComponent implements OnInit, OnChanges {

  @Input() events: Observable<void>;
  @Input() smsEnabled: boolean;
  @Input() runRecipients: RunRecipientSummary[];
  @Input() selectedRun: RunModel;
  @Input() theme:string;
  @Input() selectedRecipientId:string;

  @ViewChild('autoInput') input;

  constructor(
    public runsService: RunsService,
    private runRecipientService: RunRecipientService,
    private runTemplateService: RunTemplateService,
    public monacoConfig: MonacoEditorConfig
  ) { }
  
  private eventsSubscription: Subscription;

  editorOptions;
  options: any;
  filteredOptions$: Observable<string[]>;
  smsPreview: SMSTemplateModel = {
    SmsCount: 0,
    CharacterCount: 0,
    Body: null,
    IsEnabled: true
  };
  dataModel = [];
  smsText: string;
  smsModelParent: string = '';
  smsModelPath: string = '';
  customColumn: string = 'name';
  allColumns = [ this.customColumn ];
  previewRecipient: string;
  data: TemplateMergeFieldsNebular;
  code: string;
  ngOnInit() {
    this.runsService.runRecipientSummaries.Items$().subscribe((res: RunRecipientSummary[]) => this.options = res);
    this.eventsSubscription = this.events.subscribe(() => this.transform());
    this.filteredOptions$ = of(this.options);
    this.smsText = this.selectedRun.SmsTemplate.Body;
    this.smsEnabled = this.selectedRun.SmsTemplate.IsEnabled;
    this.runTemplateService.templateMergeFields(this.selectedRun.RunTemplate.Id).subscribe((res: TemplateMergeFieldsNebular) => {
      this.data = res
    });

    if (this.selectedRun && this.selectedRun.SmsTemplate) {
      if (typeof this.selectedRun.SmsTemplate.Body === 'undefined' || this.selectedRun.SmsTemplate.Body === null) {
          this.selectedRun.SmsTemplate.CharacterCount = 0;
          this.selectedRun.SmsTemplate.SmsCount = 0;
      } else {
        this.calculateSms();
      }
  }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.theme?.currentValue !== changes.theme?.previousValue){
      this.setEditor();
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));;
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  onSelectionChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
    if($event){
      this.getFilteredOptions($event).subscribe(async (res: any) => {
        this.runRecipientService.previewSMS(this.selectedRecipientId, { Body: this.smsText }).subscribe((res: any) => this.smsPreview = res);
      });
    }
  }

  toggleSms(event: boolean){
    this.smsEnabled = event;
  }

  save(){
    console.log('');
  }

  addToSmsBody(dataPath){
    if(dataPath){
      this.smsText += ` ${dataPath}`;
    }
  }

  setEditor(){
    this.monacoConfig.editorOptions = {theme: this.theme === 'light' ?  'vs' : 'vs-dark', language: 'razor'};
  }

  onSMSChange(event){
    this.selectedRun.SmsTemplate.Body = event;
    this.calculateSms();
  }

  calculateSms(){
    this.selectedRun.SmsTemplate.CharacterCount = this.selectedRun.SmsTemplate.Body.replace(/@Model[A-Za-z.]*/g, '').length;

    let additonalCharRequired = this.selectedRun.SmsTemplate.CharacterCount;
    const headerToAdd = Math.ceil((this.selectedRun.SmsTemplate.Body.replace(/@Model[A-Za-z.]*/g, '').length / 160)) -1;

    additonalCharRequired += headerToAdd * 10;
    this.selectedRun.SmsTemplate.SmsCount = Math.ceil((additonalCharRequired / 160));
  }

  transform(){
    this.runRecipientService.previewSMS(this.selectedRecipientId, { Body: this.smsText }).subscribe((res: any) => {
      this.smsPreview = res;
      
      this.smsPreview.CharacterCount = this.smsPreview.Body.replace(/@Model[A-Za-z.]*/g, '').length;
      this.smsText.includes('@Model.InfoSlipUrl') ? this.smsPreview.CharacterCount += -73 : undefined;

      let additonalCharRequired = this.smsPreview.CharacterCount;
      const headerToAdd = Math.ceil((this.smsPreview.CharacterCount / 160)) -1;
  
      additonalCharRequired += headerToAdd * 10;
      this.smsPreview.SmsCount = Math.ceil((additonalCharRequired / 160));
    });
  }
}