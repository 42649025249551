import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { ThemeService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-logged-out-view',
  templateUrl: 'logged-out-view.component.html',
  styleUrls: ['./logged-out-view.component.scss']
})
export class LoggedOutViewComponent implements OnInit {
  returnIcons = false;
  rewind = false;
  showAnimation = false;
  isReady = false;
  version = this.environment.version;

  constructor(
    private themeService: ThemeService,
    private environment: EnvironmentService
  ) {}
  
  ngOnInit() {
    if(this.Check_Browser_Version() !== 12){
      this.showAnimation = false;
    }
    
    const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;

      if(subdomain != null && this.environment.applicationName === 'viewer'){
          this.themeService.getTheme(subdomain).subscribe(res => {
            if(res.ColorPalette){
              this.showAnimation = false;
            } else {
              this.showAnimation = true;
            }
          })
      } else {
        this.showAnimation = true;
      }
  }

  getEvent(event) {
    // console.log('mouseEvent', event);
  }

  rewindAnimation() {
    this.rewind = true;
    setTimeout(() => {
      this.rewind = false;
    }, 5000);
  }

  Check_Browser_Version(){
    let rv = -1; // Return value assumes failure.

    if (navigator.appName == 'Microsoft Internet Explorer'){

       let ua = navigator.userAgent,
           re  = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");

       if (re.exec(ua) !== null){
         rv = parseFloat( RegExp.$1 );
       }
    }
    else if(navigator.appName == "Netscape"){                       
       /// in IE 11 the navigator.appVersion says 'trident'
       /// in Edge the navigator.appVersion does not say trident
       if(navigator.appVersion.indexOf('Trident') === -1) rv = 12;
       else rv = 11;
    }       

    return rv;          
}
}
