<div class="static-container">
    <div class="header">
        <div class="left">
            <h4>REPORT DOWNLOAD</h4>
        </div>
        <div class="right">
            <nb-icon pack="oakmoon" icon="remove" routerLink=""></nb-icon>
        </div>
    </div>

    <h5>Your report will be downloaded soon.</h5>
    
    <p>If your download doesn't start automatically, please click "Download" to download your report.</p>

    <br />

    <button 
        nbButton status="primary" 
        (click)="downloadEvent.emit()"
        [nbSpinner]="reportDownloading == true"
        [disabled]="reportDownloading == true">Download</button>
</div>

