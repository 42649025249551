import { Component, OnInit } from '@angular/core';
import { CmsGridService } from '../../services/cms-grid.service';
import { CmsEntitySummary } from '@InfoSlips/models'
import { SelectedEntityService } from '../../services/selected-entity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cms-recent',
  templateUrl: './cms-recent.component.html',
  styleUrls: ['./cms-recent.component.scss']
})
export class CmsRecentComponent implements OnInit {

  searchPhrase:string|null;
  data:CmsEntitySummary[]=[];
  
  constructor(
    private cmsGridService:CmsGridService,
    private selectedEntityService: SelectedEntityService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.cmsGridService.loadRecent();
    this.cmsGridService.cmsRecents$.subscribe(recentItems=>{
      this.data=recentItems;
    });
  }

  getContentIcon(entity:CmsEntitySummary):string{
    return this.cmsGridService.getContentIcon(entity);
  }

  filterRecent(){
    this.cmsGridService.loadRecent(this.searchPhrase);
  }

  selectEntity(entity: CmsEntitySummary){
    this.router.navigateByUrl(`cms/entity/${entity.id}`);
    this.selectedEntityService.setEntity(entity.id);
  }

}
