<div class="login">
    <div class="row">
            <div class="col-md-10">  
              <p class="pull-left title">
                Create account
              </p>
            </div>
            <div class="col-md-2">
              <a routerLink="/auth/login"
                  class="pull-right text-basic">
                  Login
                  </a>
            </div>
          </div>
          <div class="row radio-group">
            <div class="col-md-12">
          <nb-radio-group [(ngModel)]="registerOptionSelected">
        <nb-radio
        class = "pull-left"
          (click)="setRegistrationOption(option.value)"
          *ngFor="let option of registerOptions"
          [value]="option.value"
          [checked]="option.checked">
          {{ option.label }}
        </nb-radio>
      </nb-radio-group>
      </div>
  </div>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <div class="form-body row">
        <div class="col-md-12" *ngIf="registerOptionSelected === 'Mobile'">
            <nb-form-field>
              <label class="pull-left">Country</label>
              <nb-select class="select" fullWidth formControlName="country" fieldSize="medium" placeholder="Select Country">
                <nb-option *ngFor="let c of countries" [value]="c.Id">{{
                  c.Name
                }}</nb-option>
              </nb-select>
            </nb-form-field>
            <br/>
            <nb-form-field>
              <label class="pull-left">Mobile number</label>
              <input nbInput class="custom-input" fullWidth type="text" 
          placeholder="Enter your number." formControlName="register" /> 
            </nb-form-field>
          </div>
  
              <div class="col-md-12" *ngIf="registerOptionSelected === 'Email'">
            <nb-form-field>
              <label class="pull-left">Email address</label>
              <input nbInput class="custom-input" fullWidth type="text" 
          placeholder="Enter your email" formControlName="register" />
            </nb-form-field>
          </div>
        </div>
        <div class="form-footer row">
    <div class="col-md-12">
            <button nbButton fullWidth status="primary" type="submit">
              Create account
            </button>
          </div>
        </div>
        </form>

              </div>