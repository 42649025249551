<ng-container *ngIf="template.Signatures.IsEnabled">
  <div class="row">
    <div class="col-md-12">
      <nb-card class="signature-card">
        <nb-card-header>Required Options</nb-card-header>
        <nb-card-body class="inline-check form-group">
          <nb-checkbox status="basic" [(ngModel)]="template.Signatures.RequireLocation">Require Location.</nb-checkbox>
          
          <label>Link Live Run Template</label>
          <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth
          [nbAutocomplete]="auto" name="runTemplate" placeholder="Filter by Name or ID" [(ngModel)]="liveRunTemplate" [ngModelOptions]="{standalone: true}"/>
          <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
            <nb-option *ngFor="let option of runTemplates$ | async" [value]="option?.name">
              {{ option?.name }}
            </nb-option>
          </nb-autocomplete>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
      
  <div class="row">
    <div class="col-md-6">
      <nb-card class="signature-card">
        <nb-card-header>Required Signatures</nb-card-header>
        
        <nb-card-body class="signatures-grid">
          <label><p>Name</p></label>
          <input fullWidth nbInput type="text" name="newRequiredSignatureName" placeholder="Signature Name" [(ngModel)]="newRequiredSignature.Name" />
                    
          <nb-checkbox class="bottom" status="basic" [(ngModel)]="newRequiredSignature.Required">Required</nb-checkbox>

          <button class="bottom" nbButton type="button" (click)="addRequiredSignature()">Add</button>

          <hr />
        
          <div class="grid" *ngFor="let requiredSigItem of template.Signatures?.RequiredSignatures; index as i">
            <input nbInput type="text" [(ngModel)]="requiredSigItem.Name" [disabled]="editRequiredSignature?.Name !== requiredSigItem?.Name" />

            <nb-checkbox status="basic" [(ngModel)]="requiredSigItem.Required" [disabled]="editRequiredSignature?.Name !== requiredSigItem?.Name">Required</nb-checkbox>

            <nb-actions>
              <nb-action icon="trash-2-outline" *ngIf="editRequiredSignature?.Name !== requiredSigItem?.Name" (click)="removeRequiredSigItem(i)"></nb-action>
              <nb-action icon="edit-2-outline" *ngIf="editRequiredSignature?.Name !== requiredSigItem?.Name" nbTooltip="Edit Required Signature" nbTooltipStatus="primary" (click)="activeEditSignatureField(requiredSigItem)"></nb-action>
              <nb-action icon="checkmark-circle-2" *ngIf="editRequiredSignature?.Name === requiredSigItem?.Name" (click)="confirmRequiredSigItemEdit()"></nb-action>
              <nb-action icon="close-square-outline" *ngIf="editRequiredSignature?.Name === requiredSigItem?.Name" (click)="cancelRequiredSigItemEdit(i)"></nb-action>
            </nb-actions>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-md-6">
      <nb-card class="signature-card">
        <nb-card-header>Additional Capture Fields</nb-card-header>
        
        <nb-card-body class="signatures-grid">
          <label><p>Name</p></label>
          <input fullWidth nbInput type="text" name="newCaptureFieldsName" placeholder="Capture Field Name" [(ngModel)]="newCaptureField.Name" />
                  
          <nb-select class="select" [(ngModel)]="newCaptureField.InputType" [selected]="newCaptureField?.InputType" placeholder="Input Type">
            <nb-option *ngFor="let inputTypeValue of InputTypeKeys()" [value]="captureFieldInputTypes[inputTypeValue]">{{inputTypeValue}}</nb-option>
          </nb-select>

          <button nbButton type="button" (click)="addCaptureField()">Add</button>

          <hr />
        
          <div class="grid" *ngFor="let reqCaptField of template.Signatures.AdditionalCaptureFields; index as i">
            <input nbInput type="text" [(ngModel)]="reqCaptField.Name" [disabled]="editCaptureField?.Name !== reqCaptField?.Name" />

            <nb-select class="select" [(ngModel)]="reqCaptField.InputType" [selected]="reqCaptField?.InputType" [disabled]="editCaptureField?.Name !== reqCaptField?.Name">
              <nb-option *ngFor="let inputTypeValue of InputTypeKeys()" [value]="captureFieldInputTypes[inputTypeValue]">{{inputTypeValue}}</nb-option>
            </nb-select>

            <nb-actions>
              <nb-action icon="trash-2-outline" *ngIf="editCaptureField?.Name !== reqCaptField?.Name" (click)="removeCaptureField(i)"></nb-action>
              <nb-action icon="edit-2-outline" *ngIf="editCaptureField?.Name !== reqCaptField?.Name" nbTooltip="Edit Capture Field" nbTooltipStatus="primary" (click)="activeEditCaptField(reqCaptField)"></nb-action>
              <nb-action icon="checkmark-circle-2" *ngIf="editCaptureField?.Name === reqCaptField?.Name" (click)="confirmCaptFieldEdit()"></nb-action>
              <nb-action icon="close-square-outline" *ngIf="editCaptureField?.Name === reqCaptField?.Name" (click)="cancelCaptFieldEdit(i)"></nb-action>
            </nb-actions>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</ng-container>