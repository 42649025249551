import { Component, EventEmitter, Output, Input } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import * as models from '@InfoSlips/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifs-run-recipients',
  templateUrl: './run-recipients.component.html',
  styleUrls: ['./run-recipients.component.scss']
})
export class RunRecipientsComponent {
  @Input() recipientType: boolean;
  @Input() selectedRunService:SelectedRunService;
  @Input() recipientData: Observable<models.RunRecipientSummary[]>;
  @Input() loadingRecipients: boolean;
  @Input() allowActions: boolean = true;
  @Output() loadNextPage = new EventEmitter();

  onScroll() {
    this.loadNextPage.emit(true)
  }
}