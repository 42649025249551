import { Injectable } from '@angular/core';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { ToastrService } from 'ngx-toastr';
import { RegisterModel, Country, RegisterActivation, UserRegistrationModel } from '@InfoSlips/models';
import { Router } from '@angular/router';
import { AuthUrlsService } from './auth-urls-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService,
    private router: Router,
    private urls: AuthUrlsService,
  ) 
  {}

  register(register: RegisterModel, isEmail: boolean):boolean {
    const { country, register: details } = register;

    let loadingState: boolean;

    this.apiBaseService.executeGet<boolean>(
      isEmail ? 
      this.urls.RegisterEmail(details) : 
      this.urls.RegisterMobile(details,country), "Register").subscribe(
      {
        next: result => {
          loadingState = false;
          if (result) {
            this.router.navigateByUrl('/auth/login')
            this.toaster.success(
              'Please check your email',
              'Account Creation Successful!'
            );
            return;
          }
          else
            this.toaster.error(`Registration failed`);
        },
        error: error => {
          loadingState = false;
          this.toaster.error(error.error.Message)

        }
      }
    );

    return loadingState;
  }

  registerConfirm(register: UserRegistrationModel) {
    this.apiBaseService.executePost<any>("registration", register, "Register Confirmation")
    .subscribe((results) => {
        results ? 
          this.toaster.success('Registration Confirmed!') : 
          this.toaster.error('Registration failed!');
        results ? 
          this.router.navigateByUrl('/auth/login') : null;
    });
  }

  getCountries(top:number=500) {
    return this.apiBaseService.executeGet<Country[]>(this.urls.GetCountries(top), "Get Countries");
  }

  getActivation(token: string):Observable<RegisterActivation> {
    return this.apiBaseService.executeGet<RegisterActivation>(this.urls.GetActivationLink(token), "Get Activation link");
  }
}