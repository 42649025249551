import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from './../../services/cms.service';
import { EnvironmentService } from '@InfoSlips/env';
import { Observable } from 'rxjs';
import { CmsContentType, CmsEntityModel, CmsRevision } from '@InfoSlips/models';

@Component({
  selector: 'entity-detail-component',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.scss']
})
export class EntityDetailComponent implements OnInit {

  @Input() entity: CmsEntityModel;

  baseUrl: string;
  dangerIcon = 'trash-2-outline';
  editIcon = 'edit-2-outline';
  closeIcon = 'close-square-outline';
  deleteConfirm = false;
  cmsRevisions$: Observable<CmsRevision[]>;

  constructor(
    private cmsService: CmsService,
    private environment: EnvironmentService) { }

  ngOnInit() {
    this.baseUrl = this.environment.baseUrl;   
    this.cmsRevisions$ = this.cmsService.getCmsRevisions(this.entity.PublicId); 
  }

  closeDetails(){
    this.cmsService.closeDetails();
  }

  delete(confirmed: boolean){
    if(confirmed){
      this.cmsService.deleteCmsEntity(this.entity.Id);
    }
    this.deleteConfirm = !this.deleteConfirm;
  }

  getCmsContentType(contentType: CmsContentType) {
    switch (+contentType) {
      case CmsContentType.EmailBody: return 'Email Body';
      case CmsContentType.EmailSubject: return 'Email Subject';
      case CmsContentType.SmsBody: return 'Sms Body';
      case CmsContentType.DataModel: return 'Data Model';
      case CmsContentType.Html: return 'Html';
      case CmsContentType.Css: return 'Css';
      case CmsContentType.Script: return 'Script';
      case CmsContentType.Text: return 'Text';
      case CmsContentType.Url: return 'Url';
      case CmsContentType.Image: return 'Image';
      case CmsContentType.Pdf: return 'Pdf';
      case CmsContentType.Compressed: return 'Compressed';
      case CmsContentType.Video: return 'Video';
      case CmsContentType.VideoStream: return 'Video Stream';
      case CmsContentType.Audio: return 'Audio';
      case CmsContentType.AudioStream: return 'Audio Stream';
      case CmsContentType.ThemeImages: return 'Theme Images';
      case CmsContentType.ThemePalette: return 'Theme Palette';
      case CmsContentType.Markdown: return 'Markdown';
      case CmsContentType.JsonSchema: return 'Json Schema';
      case CmsContentType.Font: return 'Font';
      case CmsContentType.Xml: return 'Xml';
      case CmsContentType.Xslt: return 'Xslt';
      default: return 'Not Set';
    }
  }

  getCmsCEntityType(contentType: number) {
    switch (+contentType) {
      case 1: return 'File';
      case 2: return 'Content';
      default: return 'Not Set';
    }
  }

  compareByVersion(v1, v2): boolean {    
    return v1 === v2;
  }

  selectVersion(e){
    this.entity.IsDraft = e === -1 ? true : false;
    this.entity.Version = e;
  }

  revertCmsEntity(){
    this.cmsService.revertCmsEntity(this.entity.Id, this.entity.Version);
  }
}
