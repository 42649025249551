import { AdditionalLocationRunReportModel } from "./AdditionalLocationRunReportModel";
import { AllowedActionsModel } from "./AllowedActionsModel";
import { BaseEntityModel } from "./BaseEntity";
import { CustomerReferenceModel } from "./CustomerModels";
import { EmailTemplateModel } from "./EmailTemplateModel";
import { ExportTemplateModel } from "./ExportTemplateModel";
import { SMSTemplateModel } from "./SMSTemplateModel";
import { TemplateSourceModel } from "./TemplateSourceModel";

export interface TemplateModel extends BaseEntityModel {
    Notes:                       string;
    HelpDeskMessage:             string;
    IsPukLess:                   boolean;
    ConsolidatedCountRazor:      string;
    Customer:                    CustomerReferenceModel;
    DocumentType:                number;
    DataRetentionPeriod:         number;
    SourceDataRetentionPeriod:   number;
    NoDataPromptsForSupport:     boolean;
    AdditionalLocationRunReport: AdditionalLocationRunReportModel;
    AllowedActions:              AllowedActionsModel;
    EmailTemplate:               EmailTemplateModel;
    SmsTemplate:                 SMSTemplateModel;
    ExportTemplate:              ExportTemplateModel;    
    TemplateSource:              TemplateSourceModel;
}


  