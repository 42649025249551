import { Component, OnInit } from '@angular/core';
import { RunsService } from '@InfoSlips/iiab-api';
import { WizardService } from 'libs/wizard/src/lib/services/wizard.service';

@Component({
  selector: 'ifs-dashboard-shell',
  templateUrl: './dashboard-shell.component.html',
  styleUrls: ['./dashboard-shell.component.scss']
})
export class DashboardShellComponent implements OnInit {
  expand = false;
  isRunSelected = false;

  constructor(
    private runsService: RunsService,
    public wizardSerivce: WizardService,
  ) { }

  ngOnInit() {
    this.wizardSerivce.wizardStatusSubject.next(false);
    this.runsService.selectedRun ? true : true;
  }


  setOverlay(event){
    this.wizardSerivce.wizardStatusSubject.next(event);
  }

  toggleRuns(){
    this.expand = !this.expand;

    if(!this.expand){
      this.runsService.getRecentRuns();
    } else {
      this.runsService.recentRunSubscription.unsubscribe();
    }    
  }

}
