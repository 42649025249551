import { Injectable } from '@angular/core';
import { ApiBaseService, ObservableWrapper } from '@InfoSlips/iiab-api';
import { AuthService } from '@InfoSlips/iiab-auth';
import { CmsEntitySummary } from '@InfoSlips/models';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  _cmsEntitiesRecentsPagedQuery,
  _fetchCmsEntitiesData,
} from '../graphql/cmsQueries';
import { CmsService } from './cms.service';

@Injectable({
  providedIn: 'root',
})
export class CmsGridService {
  public cmsRecents: ObservableWrapper<CmsEntitySummary>;

  //#region private
  private cmsRecentsSubject = new BehaviorSubject<CmsEntitySummary[]>(null);
  
  //#endregion

  cmsRecents$ = this.cmsRecentsSubject.asObservable();
  cmsEntitiesCount$: Observable<number>;
  isLoading$:   Observable<boolean>;


  constructor(
    private apiBaseService: ApiBaseService,
    private authService: AuthService,
    public cmsService: CmsService) {

    this.cmsRecents = new ObservableWrapper<CmsEntitySummary>(      
      this.apiBaseService,
      _cmsEntitiesRecentsPagedQuery(10),
      _fetchCmsEntitiesData,
      false
    );
    
    this.cmsRecents.Items$().subscribe(items=>{
      this.cmsRecentsSubject.next(items);
    });

    this.cmsEntitiesCount$ = this.cmsService.cmsEntities.totalCount$;

  }

  loadEntities(filter:any|null=null){
    this.cmsService.cmsEntities.loadPagedItems(filter);
  }
  loadEntitiesNextPage(){
    this.cmsService.cmsEntities.loadNextPage();
  }
  loadRecent(searchPhrase:string|null=null){
    if(!searchPhrase || searchPhrase.length==0){
      this.cmsRecents.loadPagedItems({lastUpdatedBy: {eq:this.authService.loggedInUser.userName}});
      return;
    }
    
    const filter={
      lastUpdatedBy: {
        eq:this.authService.loggedInUser.userName
      },
      or:[
        {name:{like:searchPhrase}},
        {display:{like:searchPhrase}},
      ]
    };

    this.cmsRecents.loadPagedItems(filter);    
  }

  getContentIcon(contentType:any) {

    switch (contentType) {
        case ('EMAIL_BODY'): case 0: return 'email';
        case ('EMAIL_SUBJECT'): case 1: return 'email';
        case ('SMS_BODY'): case 2: return 'document';
        case ('DATA_MODEL'): case 3: return 'json';
        case ('HTML'): case 4: return 'html';
        case ('CSS'): case 5: return 'css';
        case ('SCRIPT'): case 6: return 'js';
        case ('TEXT'): case 7: return 'document';
        case ('URL'): case 8: return 'document';

        case ('IMAGE'): case 9: return 'image';
        case ('PDF'): case 10: return 'pdf';
        case ('COMPRESSED'): case 11: return 'zip';
        case ('VIDEO'): case 12: return 'video';
        case ('VIDEO_STREAM'): case 13: return 'video';
        case ('AUDIO'): case 14: return 'audio';
        case ('AUDIO_STREAM'): case 15: return 'audio';

        case ('THEME_IMAGES'): case 16: return 'image';
        case ('THEME_PALETTE'): case 17: return 'folder-config';

        case ('MARKDOWN'): case 18: return 'md';
        case ('JSON_SCHEMA'): case 19: return 'json';
        case ('FONT'): case 20: return 'font';
        case ('XML'): case 21: return 'xml';
        case ('XSLT'): case 22: return 'xslt';

        default: return 'none';
    }
  }
}
