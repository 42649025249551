import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { CustomerModel, PageGraphQLData, IiabIdName } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../iiab-auth/src/lib/services/auth.service';
import { CrudBaseService } from './base/crud-base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  customers: ObservableWrapper<IiabIdName>;
  customerGroups: ObservableWrapper<IiabIdName>;

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private authService: AuthService,
    private crudBaseService: CrudBaseService
  ) {
    this.customers = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageRunCustomersQuery(),
      this._fetchRunCustomersData,
    false);

    this.customerGroups = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageCustomerGroupsQuery(),
      this._fetchCustomerGroupsData,
    false);

    this.customerGroups.loadPagedItems();
  }

  public selectedCustomer: any;
  selectedCustomerSubject = new BehaviorSubject<CustomerModel>(null);
  selectedCustomer$ = this.selectedCustomerSubject.asObservable();

  getCustomer(customerId: string){
    if(customerId){
      this.apiBaseService.executeGet<any>(`customer/${customerId}`, 'GetCustomer').subscribe((res: any) => {
        this.selectCustomer(res)
      });
    }
    else {
      this.selectCustomer(null)
    }
    return this.selectedCustomer$;
  }

  private _pageRunCustomersQuery() {
    return `
    query RunCustomers($where: CustomerFilterInput = null) { 
      customers(where: $where, order: {name: ASC})
      {
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges{
          node{
            name
            id
            billingGroup
            created
            createdBy
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }`;
  }

  private _pageCustomerGroupsQuery() {
    return `
    query CustomerGroups($where: CustomerGroupFilterInput = null){ 
      customerGroups(where: $where, order: {name: ASC})
      {
        edges{
          node{
            name
            id
						created
						createdBy
						lastUpdated
						lastUpdatedBy
						customers {
							name
							id
							billingGroup
						}
          }
        }
      }
    }`;
  }

  private _fetchRunCustomersData(rawData: any): PageGraphQLData<IiabIdName> {
    try{
      return rawData.data.customers as PageGraphQLData<IiabIdName>;
    }
    catch(e)
    {
      console.log('runCustomersData That FAIlED', rawData);
      return null;
    }
  }

  private _fetchCustomerGroupsData(rawData: any): PageGraphQLData<IiabIdName> {
    try{
      return rawData.data.customerGroups as PageGraphQLData<IiabIdName>;
    }
    catch(e)
    {
      console.log('runCustomerGroupsData That FAIlED', rawData);
      return null;
    }
  }

  createCustomer(customer: CustomerModel){
    return new Promise<boolean>((resolve: any) => {
      this.apiBaseService.executePost<CustomerModel>('customer', customer, 'Create Customer').subscribe((res: any) => {
        if(res){
          this.toastr.success('Customer Created!');
          this.selectCustomer(res);
          resolve(true)
        } else resolve(false);
      });
    });
  }

  updateCustomer(customer: CustomerModel){
    this.apiBaseService.executePut<CustomerModel>('customer', customer, 'Update Customer').subscribe((res: any) => {
      if(res){
        this.toastr.success('Customer Updated!');
       this.selectCustomer(res);
      }
    });
  }

  selectCustomer(customer: CustomerModel){
    this.selectedCustomerSubject.next(customer);
  }

  async createBaseCustomer(customerName: string){
    const customer: CustomerModel = await this.crudBaseService.getDefault<CustomerModel>(0);
    customer.Contacts.push({
      Email: this.authService.loggedInUser.email,
      Name: this.authService.loggedInUser.displayName,
      ContactType: 7,
      ContactTypeDescription: "Customer Creator",
      Phone: "0"
    });
    customer.Name = customerName;
    customer.ArchiveDisplayName = customerName.toLowerCase();

    return new Promise<CustomerModel>(resolve => {
      this.apiBaseService.executePost<CustomerModel>('customer', customer, 'Create Customer').subscribe((res: any) => {
        if(res){
          this.toastr.info('Customer Created!');
          setTimeout(() => {
            this.selectCustomer(res);
            resolve(res);
          }, 2000)
        }
      });
    });
  }

  filterByName(nameLike:string){
    if(nameLike!=null && nameLike.length>0){
      const where={
        name:{like:nameLike}
      };
      this.customers.loadPagedItems(where);
      return;
    }
    this.customers.loadPagedItems();
  }

  filterByCustomerIds(customerIds: string[]){
    const filter = {
      or:[] as any[]
    }
    
    customerIds.forEach((id:string) => {
      filter.or.push({
        id: {
          eq: id
        }
      })
    });

    const customers = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageRunCustomersQuery(),
      this._fetchRunCustomersData,
    false);

    customers.loadPagedItems(filter);
    return customers.Items$();
  }

  delete(customerId: string){
    this.apiBaseService.executeDelete(`customer/${customerId}`, `Delete Customer.`).subscribe(isDeleted => {
      isDeleted ? this.toastr.info(`Customer deleted!`) : this.toastr.error(`Customer deletion failed.`);
    });

    this.customers.loadPagedItems();
  }
}