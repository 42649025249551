import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiBaseService, ObservableWrapper } from '@InfoSlips/iiab-api';
import { CmsEntity, IiabIdName, MiscellaneousModel, PageGraphQLData } from '@InfoSlips/models';
import { CmsService } from './cms.service';

interface CmsFilterStateModel {
    selectedCustomer: any | IiabIdName;
    selectedCustomerGroup: any | IiabIdName;
    selectedRunTemplate: any | IiabIdName;
    cmsEntityType: any | string,
    cmsContentType: any | string,
    searchPhrase: string | null
}

@Injectable({
    providedIn: 'root'
})

export class CmsFilterService {
    public cmsFilters: ObservableWrapper<CmsEntity>;

    showDetailsSubject = new BehaviorSubject<boolean>(false);
    showDetails$ = this.showDetailsSubject.asObservable();

    contentTypesSubject = new BehaviorSubject<any>(null);
    contentTypes$ = this.contentTypesSubject.asObservable();

    entityTypesSubject = new BehaviorSubject<any>(null);
    entityTypes$ = this.entityTypesSubject.asObservable();

    CmsFilterState: CmsFilterStateModel = {
      selectedCustomer: {},
      selectedCustomerGroup: {},
      selectedRunTemplate: {},
      cmsEntityType: "",
      cmsContentType: "",
      searchPhrase: "",
    };
    
    private _cmsQueryVariables = {
      whereObject: null,
      order: null
    };

    constructor(
      private apiBaseService: ApiBaseService,
      private cmsService: CmsService
    ){
      this.cmsFilters = new ObservableWrapper<CmsEntity>(
          this.apiBaseService,
          this._pageCmsEntitiesQuery(),
          this._fetchCmsEntitiesData,
          true
      );

      this.getAvailableFilters();
    }

    set whereObject(value){
      if (value == null || !("cmsContentType" in value)) {
        value = {...value, ...this._getBaseFilter()}
      }
  
      this._cmsQueryVariables.whereObject = { ...this._cmsQueryVariables.whereObject, ...value };
    }

    set order(value){
      this._cmsQueryVariables.order = { ...this._cmsQueryVariables.order, ...value };
    }

    private _pageCmsEntitiesQuery() {
        return `
        query CmsEntitiesFilters ($where: CmsEntityFilterInput = null) {
            cmsEntities (first: 100, order: {cmsContentType: ASC}, where: $where) {
                edges{
                    node {
                        customer {
                            name
                            id
                        }
                        cmsEntityType
                        cmsContentType
                    }
                }
            }
        }`;
    }

    private _fetchCmsEntitiesData(rawData: any): PageGraphQLData<CmsEntity> {
        try{
          return rawData.data.cmsEntities as PageGraphQLData<CmsEntity>;
        }
        catch(e)
        {
          console.log('cmsEntitiesData That FAILED', rawData);
          return null;
        }
    }

    getAvailableFilters(){
        this.miscellaneousContext('CmsContentType').subscribe(contentTypes => this.contentTypesSubject.next(contentTypes));
        this.miscellaneousContext('CmsEntityType').subscribe(entityTypes => this.entityTypesSubject.next(entityTypes))
    }

    getUniques(array) {
        return [...new Set(array)];
    }

    miscellaneousContext(context: string){
        return this.apiBaseService.executeGet<MiscellaneousModel>(`miscellaneous/${context}/EnumValues`, 'Get Miscellaneous.');
    }

    loadFilter() {
      this.whereObject = this.buildFilter();
      this.cmsService.cmsEntities.loadSortedItems(this._cmsQueryVariables);
    }

    clearFilters() {
      this._cmsQueryVariables.whereObject = null;
      
      this.CmsFilterState = {
        selectedCustomer: {},
        selectedCustomerGroup: {},
        selectedRunTemplate: {},
        cmsEntityType: "",
        cmsContentType: "",
        searchPhrase: "",
      };

      this.loadFilter();
    }

    public buildFilter(){
      const filter: {[k: string]: any} = {};
  
      let hasRunTemplate = false; // If RunTemplateId is specified, ignore other ids
      
      if(this.CmsFilterState.selectedRunTemplate && this.CmsFilterState.selectedRunTemplate.id && this.CmsFilterState.selectedRunTemplate.id.length > 0){
        filter.runTemplate = this._buildIdFilter(this.CmsFilterState.selectedRunTemplate.id);
        hasRunTemplate = true; 
      }
  
      if(!hasRunTemplate && (this.CmsFilterState.selectedCustomer && this.CmsFilterState.selectedCustomer.id && this.CmsFilterState.selectedCustomer.id.length > 0))
        filter.customer=this._buildIdFilter(this.CmsFilterState.selectedCustomer.id)
  
      if(!hasRunTemplate && (this.CmsFilterState.selectedCustomerGroup && this.CmsFilterState.selectedCustomerGroup.id && this.CmsFilterState.selectedCustomerGroup.id.length > 0))
        filter.customerGroup=this._buildIdFilter(this.CmsFilterState.selectedCustomerGroup.id)
  
      if(this.CmsFilterState.searchPhrase && this.CmsFilterState.searchPhrase.length > 0)
        this.CmsFilterState.searchPhrase.length == 24 ?
          filter["id"] = {eq:this.CmsFilterState.searchPhrase}
        :
          filter["or"]=[
            {name:{like:this.CmsFilterState.searchPhrase}},
            {display:{like:this.CmsFilterState.searchPhrase}},
          ];
      if(this.CmsFilterState.cmsEntityType){
        filter.cmsEntityType={eq:this._enumerize(this.CmsFilterState.cmsEntityType)};
      }
  
      if(this.CmsFilterState.cmsContentType){
        filter.cmsContentType={eq:this._enumerize(this.CmsFilterState.cmsContentType)};
      }
      
      if(JSON.stringify(filter)==='{}')
        return null;
      return filter;
    }

    private _getBaseFilter() {
      return { 
        cmsContentType: {neq: 'THEME_PALETTE'}, 
        and: {cmsContentType: {neq: 'THEME_IMAGES'}}
      }
    }

    private _buildIdFilter(id:string):any{
      return {id:{eq:id}};
    }

    private _enumerize(word: string): string{
      if(word.includes(' ')){
        word = word.replace(' ', '_');
      }
      return word.toUpperCase();
    }
}