import { IiabIdName } from "./iiab-reference";
import { RecipientSummary } from "./recipient";
import { RunHistoryReference } from "./run";

export interface RunRecipientReference extends IiabIdName {
  externalId: string;
}

export interface RunRecipientSummary extends RecipientSummary{
  onlineId:           string;
  run?:               RunHistoryReference;
  lastException?:     string;
  staticAttachments?: RunRecipientStaticAttachment[];
}

export interface RunTrialRecipientSummary extends RunRecipientReference{
  email:            string;
  mobile:           null;
  isCompiled:       boolean;
  isExported:       boolean;
  isMarkedForTrial: boolean;
  onlineId:         string;
  run?:             RunHistoryReference;
}

export interface RunRecipientStaticAttachment {
  size?:     number;
  fileName?: string;
}