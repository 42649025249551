import { IiabId, PageGraphQLData } from "@InfoSlips/models";
import { BehaviorSubject } from 'rxjs';
import { ApiBaseService } from './api-base.service';

interface PagedItems {
  whereObject?: any;
  parentId?: string;
  childWhere?: any;
  order?: any
}

export abstract class ObservableStoreBase<T extends IiabId>{
  private _apiBaseService: ApiBaseService;
  private _pagedQuery: string;
  private _fetchGraphQLData: (data: any) => PageGraphQLData<T>;
  private _autoLoad: boolean;
  private _initialized = false;

  private _subject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _selectedItemSubject: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  private store:
    {
      lastWhere: any,
      lastCall: PageGraphQLData<T>,
      data: T[],
      selectedItem: T
      parentId: string
    } = {
      lastWhere: null,
      lastCall: null,
      data: [],
      selectedItem: null,
      parentId: null
    };

  //#region Status Observables
  private lastUpdatedSubject = new BehaviorSubject<string>(null);
  lastUpdated$ = this.lastUpdatedSubject.asObservable();

  private countSubject = new BehaviorSubject<number>(null);
  count$ = this.countSubject.asObservable();

  private totalCountSubject = new BehaviorSubject<number>(null);
  totalCount$ = this.totalCountSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  private hasNextPageSubject = new BehaviorSubject<boolean>(false);
  hasNextPage$ = this.hasNextPageSubject.asObservable();

  private hasPreviousPageSubject = new BehaviorSubject<boolean>(false);
  hasPreviousPage$ = this.hasPreviousPageSubject.asObservable();
  //#endregion

  onInit(
    apiBaseService: ApiBaseService,
    pagedQuery: string,
    fetchGraphQLData: (data: any) => PageGraphQLData<T>,
    autoload: boolean): void {
      this._apiBaseService = apiBaseService;
      this._pagedQuery = pagedQuery;
      this._fetchGraphQLData = fetchGraphQLData;
      this._autoLoad = autoload;
  }

  Items$(filter?: any, itemCount: number = undefined) {
    if (!this._initialized && this._autoLoad) {
      filter ? this.loadPagedItems(filter, undefined, undefined, undefined, itemCount) : this.loadPagedItems(undefined, undefined, undefined, undefined, itemCount)
      this._initialized = true;
    }
    return this._subject.asObservable();
  }

  GetItems():T[]{
    return this._subject?.getValue();
  }

  ClearItems$() {
    this._initialized = false;
    this._subject.next([]);
    this._selectedItemSubject.next(null);
    this.lastUpdatedSubject.next(null);
    this.countSubject.next(null);
    this.totalCountSubject.next(null);
    this.isLoadingSubject.next(false);
    this.hasNextPageSubject.next(false);
    this.hasPreviousPageSubject.next(false);

  }

  ClearSubject() {
    this._subject.next(null);
  }

  loadPagedItems(whereObject: any = null, parentId: string = null, childWhere?: any, order?: any, pageSize: number = 16) {

    this.store.lastWhere = whereObject;
    this.store.parentId = parentId;

    const vars: any = {
      first: pageSize,
      afterCursor: null,
      where: whereObject,
      parentWhere: { id: { eq: parentId ? parentId : null } },
      childWhere: childWhere ? childWhere : null,
    }

    order ? vars['order'] = order : undefined;

    this.isLoadingSubject.next(true);
    this._apiBaseService.getGraphQLPagedArray<T>(
      this._pagedQuery,
      vars
    ).subscribe(
      (data: any) => this._updateStore(data, true),
      (error: any) => console.log(`Error loading with loadPagedItems. ${this._pagedQuery}`, error)
    );
  }

  loadSortedItems(input?: PagedItems, pageSize: number = 16) {
    const { 
        whereObject = null, 
        parentId = null, 
        childWhere = null, 
        order = null
    } = input;

    const afterCursor: string = null;

    this.store.lastWhere = whereObject;
    this.store.parentId = parentId;

    const vars: any = {
        first: pageSize,
        afterCursor: afterCursor,
        where: whereObject,
        parentWhere: { id: { eq: parentId } },
        childWhere: childWhere,
    }

    order ? vars['order'] = order : undefined;

    this.isLoadingSubject.next(true);
    this._apiBaseService.getGraphQLPagedArray<T>(
      this._pagedQuery,
        vars
    )
    .subscribe(
      (data: any) => this._updateStore(data, true),
      (error: any) => console.log(`Error loading with loadPagedItems. ${this._pagedQuery}`, error)
    );
  }

  loadNextPage(pageSize: number = 10) {
    this.isLoadingSubject.next(true);

    if (this.hasNextPageSubject.getValue() == false) {
      this.isLoadingSubject.next(false);
      return;
    }

    const nextCursor = this.store.lastCall.pageInfo.endCursor;

    const vars: any = {
      first: pageSize,
      afterCursor: nextCursor,
      where: this.store.lastWhere,
      parentWhere: { id: { eq: this.store.parentId } }
    }

    this._apiBaseService.getGraphQLPagedArray<T>(
      this._pagedQuery,
      vars
      )
      .subscribe(
        (data: any) => this._updateStore(data, nextCursor ? false : true),
        (error: any) => console.error("Error loading with loadPagedItems.", error)
      );
  }

  loadSpecificPage(direction:string, first: number = null, last: number = null) {
    this.isLoadingSubject.next(true);

    if (first != null && last != null)  {
      this.isLoadingSubject.next(false);
      return
    }

    let vars: any = {
      where: this.store.lastWhere,
      parentWhere: { id: { eq: this.store.parentId } },
    }
    if (direction === "next") 
      vars.afterCursor = this.store.lastCall.pageInfo.endCursor;
    else if (direction === "previous") 
      vars.beforeCursor = this.store.lastCall.pageInfo.startCursor;
    else if (direction === "first") 
      vars.afterCursor = null;

    if (first != null) vars.first = first;
    if (last != null) vars.last = last;

    this._apiBaseService.getGraphQLPagedArray<T>(
      this._pagedQuery,
      vars
    )
    .subscribe(
      (data: any) => this._updateStore(data, true),
      (error: any) => console.error("Error loading with loadNextPageOnly.", error)
    );
  }

  selectedItem$() {
    return this._selectedItemSubject.asObservable();
  }
  getSelectedItem(): T {
    return this._selectedItemSubject.getValue();
  }
  selectItem(item: T) {
    this.store.selectedItem = item;
    this._selectedItemSubject.next(item);
  }
  selectItemById(id: string) {
    const filter = this.store.data.filter(x => x.id === id);
    if (filter.length >= 0) {
      this.selectItem(filter[0])
    }
  }

  filterByName(nameLike: string, additionalWhere: any = null): void {

    if (nameLike != null && nameLike.length > 0) {

      const where = additionalWhere ?
        { name: { like: nameLike }, ...additionalWhere } :
        { name: { like: nameLike } };

      this.loadPagedItems(where);
      return;
    }
    this.loadPagedItems();
  }

  filterById(id: string, additionalWhere: any = null): void {

    if (id != null && id.length == 24) {

      const where = additionalWhere ?
        { id: { eq: id }, ...additionalWhere } :
        { id: { eq: id } };

      this.loadPagedItems(where);
      return;
    }
    this.loadPagedItems();
  }


  private _updateStore(rawData: any, replace: boolean = true) {
    //TODO:Jdv - Decide what must be done if the pagedData is null
    if (!rawData || !rawData.data)
      return;

    const pagedData = this._fetchGraphQLData(rawData);
    if (!pagedData) {
      console.error("Failed to parse data as PageGraphQLData<T>", rawData);
      throw new Error("Failed to parse data as PageGraphQLData<T>");
    }

    this.store.lastCall = pagedData;

    if (replace === true) {
      // TODO: History Company Filter Small Object
      // if ('edges' in pagedData){
      //   this.store.data = pagedData.edges.map(item => item.node);
      // }
      // else{
      //   this.store.data = pagedData.nodes.map(item => item.customerName);
      // }
      this.store.data = pagedData.edges.map(item => item.node);

    }
    else {
      pagedData.edges.map(item => {
        this.store.data.push(item.node);
      })
    }

    this.countSubject.next(this.store.data.length);
    this.totalCountSubject.next(pagedData.totalCount);
    this.lastUpdatedSubject.next(Date());
    this._subject.next(Object.assign({}, this.store).data);
    this.isLoadingSubject.next(false);
    this.hasNextPageSubject.next(this.store?.lastCall?.pageInfo?.hasNextPage == true ? true : false);
    this.hasPreviousPageSubject.next(this.store?.lastCall?.pageInfo?.hasPreviousPage == true ? true : false);
  }
}