<ifs-loading-screen *ngIf="!template"></ifs-loading-screen>
<div class="general-page-container" *ngIf="template">
  <div class="general-page-header">
    <div class="d-flex">
      <ng-container *ngIf="isNew">
        <div class="flex-1">
          <h4 class="heading">Create {{isRunTemplate ? 'Run ' : ''}} Template: <b>{{ template?.Name }}</b></h4>
        </div>
        <div class="text-right">
          <button size="medium" outline nbButton status="basic" routerLink="/">
            Cancel
          </button>
          <button class="save" size="medium" nbButton status="primary" (click)="create()">
            Create
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="!isNew">
        <div class="flex-1">
          <h4 class="heading">Update {{isRunTemplate ? 'Run ' : ''}} Template: <b>{{ template?.Name }}</b>
            <nb-icon
              (click)="favoriteItem(template?.Name, isRunTemplate ? '/admin/runtemplates/' + template?.Id : '/admin/templates/' + template?.Id)"
              class="favorite" [icon]="(isStar$ | async)?.isStar ? 'star' : 'star-outline'" pack="eva">
            </nb-icon>
          </h4>
          <p class="sub-heading" *ngIf="isRunTemplate">Based on: <a class="pointer-cursor"
            routerLink="/admin/{{isRunTemplate ? 'templates' : 'runtemplate'}}/{{template?.Template?.Id}}">{{
            template?.Template?.Name + " - " + template?.Template?.Id}}</a></p>
          <p class="sub-heading" *ngIf="!isRunTemplate">{{runTemplateCount}} Run Templates dependent on this template</p>
        </div>
        <div class="text-right">
          <button *ngIf="isRunTemplate" size="medium" outline nbButton (click)="goToRuns()" style="margin-right: 15px;">
            View Runs
          </button>          
          <button size="medium" outline nbButton routerLink="/">
            Cancel
          </button>
          <button class="save" size="medium" nbButton status="primary" (click)="save()">
            Save
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<nb-card *ngIf="template" class="card-container">
  <nb-card-body>
    <div class="flex">
      <div class="scroll-nav">
        <div class="general-side-nav">
          <div class="context-menu" *ngFor="let option of options; let i = index" (click)="setMenuItem(option.title, i)"
            [class.active]="option.title === menuItem">
            <div class="body">
              <h4 class="title">{{ option.title }}</h4>
            </div>
            <div class="action text-center">
              <!-- <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon> -->
              <nb-toggle 
              [status]="option.title === menuItem ? 'control' : 'primary'" 
              *ngIf="option.hasToggle && !option.isRootLevel" 
              [(ngModel)]="template[option.model].IsEnabled">
              </nb-toggle>

              <nb-toggle 
              [status]="option.title === menuItem ? 'control' : 'primary'" 
              *ngIf="option.title === 'CMS'" 
              [(ngModel)]="template.UseCms" disabled>
              </nb-toggle>

              <nb-toggle 
                [status]="option.title === menuItem ? 'control' : 'primary'" 
                *ngIf="option.hasToggle && option.isRootLevel && option.title === 'Pre-Processor'" 
                [(ngModel)]="isPreProcEnabled"
                (checkedChange)="preProcEnabled($event)">
              </nb-toggle>
            </div>
          </div>
        </div>
      </div>
      

      <div [ngSwitch]="menuItem" class="content">
        <ng-template [ngSwitchCase]="'Details'">
          <div class="row">
            <div class="col-md-4">
              <nb-card class="input-box section-card">
                  <nb-card-header>General Details</nb-card-header>
                  <nb-card-body class="padded">
                    <div class="row">
                      <div class="col-md-12">
                        <nb-checkbox *ngIf="isNew && isRunTemplate" status="basic" [(ngModel)]="template.UseTemplate">Use existing template?</nb-checkbox>
                        <input *ngIf="template.UseTemplate" style="margin-top: 10px;" fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onExistingTemplateChange()" fullWidth
                          [nbAutocomplete]="auto" name="template" placeholder="Filter by Name or ID"/>
                        <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
                          <nb-option *ngFor="let option of templates$ | async" [value]="option">
                            {{ option?.name }}
                          </nb-option>
                        </nb-autocomplete>
        
                        <label>
                          <p>What do you want to name this template?</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.Name" required>
                        <label>
                          <p>What kind of document is this?</p>
                        </label>
                        <nb-select fullWidth [selected]="template.DocumentType" [(ngModel)]="template.DocumentType">
                          <nb-option *ngFor="let type of docTypes$ | async" [value]="type.id">{{ type.value }}</nb-option>
                        </nb-select>
            
                        <ng-container *ngIf="isRunTemplate">
                          <label>
                            <p>Data prompts title</p>
                          </label>
                          <input type="text" nbInput fullWidth [(ngModel)]="template.DataPromptsTitle">
                        </ng-container>
            
                        <label>
                          <p>Select alternate location for run report</p>
                        </label>
                        <nb-select fullWidth [selected]="template.AdditionalLocationRunReport?.AdditionalType" [(ngModel)]="template.AdditionalLocationRunReport.AdditionalType">
                          <nb-option *ngFor="let type of runReportLocations$ | async" [value]="type.id.toString()">{{ type.value }}</nb-option>
                        </nb-select>
                        <div *ngIf="template.AdditionalLocationRunReport?.AdditionalType === '1' || template.AdditionalLocationRunReport?.AdditionalType === 1">
                          <label>
                            <p>sFTP Url</p>
                          </label>
                          <input type="text" nbInput fullWidth [(ngModel)]="template.AdditionalLocationRunReport.sFtpUrl">
            
                          <label>
                            <p>sFTP Username</p>
                          </label>
                          <input type="text" nbInput fullWidth [(ngModel)]="template.AdditionalLocationRunReport.sFtpUsername">
            
                          <label>
                            <p>sFTP Password</p>
                          </label>
                          <input type="text" nbInput fullWidth [(ngModel)]="template.AdditionalLocationRunReport.sFtpPassword">
                        </div>
                        <div *ngIf="template.AdditionalLocationRunReport?.AdditionalType === '2' || template.AdditionalLocationRunReport?.AdditionalType === 2">
                          <label>
                            <p>API Url</p>
                          </label>
                          <input type="text" nbInput fullWidth [(ngModel)]="template.AdditionalLocationRunReport.ApiUrl">
                        </div>
        
                        <label>
                          <p>OTP Sender Type</p>
                        </label>
                        <nb-select fullWidth [selected]="template.OtpSenderType" [(ngModel)]="template.OtpSenderType">
                          <nb-option *ngFor="let type of otpSenderTypes$ | async" [value]="type.id">{{ type.value }}</nb-option>
                        </nb-select>
                      </div>
                    </div>
                  </nb-card-body>
                </nb-card>
            </div>

            <div class="col-md-4">
              <nb-card class="input-box section-card">
                <nb-card-header>Customer Details</nb-card-header>
                <nb-card-body class="padded">
                  <label>
                    <p>Customer</p>
                  </label>
                  <div class="customer-input">
                    <ifs-customer-lookup #customerDropdown class="customer-lookup" *ngIf="!isCustomerLocked" [showLabel]="false" [useRetain]="true"
                      (ItemSelected)="onCustomerSelected($event)" style="margin-bottom: 16px;">
                    </ifs-customer-lookup>
                    <input type="text" nbInput fullWidth *ngIf="isCustomerLocked" [(ngModel)]="this.template.Customer.Name" [readonly]="true">
                    <button nbButton *ngIf="!isNew && !isCustomerLocked" (click)="resetCustomerSelection()" style="margin-top: 0px !important; margin-bottom: 16px !important;">
                      <nb-icon icon="close-outline" pack="eva"></nb-icon>
                    </button>
                    <button #customerDropdown nbButton status="primary" *ngIf="!isCustomerLocked && !isNew" (click)="isCustomerLocked = !isCustomerLocked" style="margin-top: 0px !important; margin-bottom: 16px !important;">
                      <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>
                    </button>  
                    <button nbButton *ngIf="!isNew && isCustomerLocked" (click)="isCustomerLocked = !isCustomerLocked" style="margin-top: 0px !important; margin-bottom: 16px !important;">
                      <nb-icon icon="edit-outline" pack="eva"></nb-icon>
                    </button>
                  </div>
                  <button nbButton (click)="open(dialog)" *ngIf="isNew" status="primary"
                    style="margin-top: 0px;">
                    <nb-icon icon="plus-outline" pack="eva"></nb-icon> Create Customer
                  </button>
      
                  <ng-container *ngIf="isRunTemplate">
                    <label>
                      <p>Subdomain</p>
                    </label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.Subdomain">
                    <label>
                      <p>Cost Centre</p>
                    </label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.CostCentre">
                    <label>
                      <p>Push Notification Provider</p>
                    </label>
                    <nb-select fullWidth [(ngModel)]="template.PushNotificationSenderType" [selected]="template.PushNotificationSenderType">
                      <nb-option *ngFor="let type of pushNotificationProviders$ | async" [value]="type.id">{{ type.value }}</nb-option>
                    </nb-select>
                    <label>
                      <p>Preferred SMS Sender</p>
                    </label>
                    <nb-select fullWidth [(ngModel)]="template.PreferredSmsSender" [selected]="template.PreferredSmsSender">
                      <nb-option *ngFor="let type of smsSenderTypes$ | async" [value]="type.id">{{ type.value }}</nb-option>
                    </nb-select>
                    <label>
                      <p>InfoSlip Version</p>
                    </label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.PackageVersion">
                  </ng-container>
    
                  <label>
                    <p>How long should we keep your InfoSlips for?</p>
                  </label>
                  <nb-select fullWidth [selected]="template.DataRetentionPeriod" [(ngModel)]="template.DataRetentionPeriod">
                    <nb-option *ngFor="let type of dataRetentionPeriods$ | async" [value]="type.id">{{ type.value }}</nb-option>
                  </nb-select>
                  <label>
                    <p>Uploaded Data retention period</p>
                  </label>
                  <nb-select fullWidth [selected]="template.SourceDataRetentionPeriod" [(ngModel)]="template.SourceDataRetentionPeriod">
                    <nb-option *ngFor="let type of sourceDataRetentionPeriods$ | async" [value]="type.id">{{ type.value }}</nb-option>
                  </nb-select>
                  <ng-container *ngIf="isRunTemplate">
                    <nb-checkbox status="basic" [(ngModel)]="template.DontCreateHistory">Don't create history for this template.</nb-checkbox>
                    <p *ngIf="template.DontCreateHistory">
                      You are switching off history creation for this run which could impact the run. Please make sure this is what you want before you continue.
                    </p>
                  </ng-container>
                </nb-card-body>
              </nb-card>
            </div>

            <div class="col-md-4">
              <nb-card class="input-box section-card">
                <nb-card-header>Extra Settings</nb-card-header>
                <nb-card-body>
                  <nb-accordion>
                    <nb-accordion-item>
                      <nb-accordion-item-header>Additional Options</nb-accordion-item-header>
                      <nb-accordion-item-body>
                      <div class="inline-check">
                        <nb-checkbox status="basic" [(ngModel)]="template.IsPukLess">This template doesn't require a Personal User Key</nb-checkbox>
                        <nb-checkbox status="basic" [(ngModel)]="template.NoDataPromptsForSupport">Bypass passwords for Support role</nb-checkbox>
                        <ng-container *ngIf="isRunTemplate">
                          <nb-checkbox status="basic" [(ngModel)]="template.IsCompileOnly">Don't send my InfoSlips, create them only</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.IsPrivate">Disable online view for live run.</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.ExcludeIfsFile">Email body / SMS only?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.DisableUrlSecurity">Disable URL Security?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.HideRunNameOnViewer">Hide Run Name on Viewer?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.HideDataPromptsTitle">Hide data prompts title?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.ForceDataPrompts">Force Data Prompts</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.IsAllowedAdhocForward">Allow Adhoc Forward?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.DisableApiForward">Disable Api Forward?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.AllowEmptyQuestionsForAdhoc">Allow empty questions for Adhoc?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.AntiForgeryEnabled">Anti Forgery Enabled?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.ProcessSequencesSeparately">Process Recipient Sequences Separately?</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.ExcludeNewRecipientsFile">Exclude new recipients list from live run report?</nb-checkbox>
                        </ng-container>
                      </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Trial Options</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <nb-checkbox status="basic" [(ngModel)]="template.TrialOptions.IsAutoTrialEnabled">Would you like to send a trial automatically?</nb-checkbox>
                        <ng-container *ngIf="template.TrialOptions.IsAutoTrialEnabled">
                          <label>
                            <p>Send trial to</p>
                          </label>
                          <input type="text" nbInput fullWidth [ngModel]="template.TrialOptions.SendToUser" (ngModelChange)="setAutoTrialAddress($event)">
                          <label>
                            <p>Which InfoSlips should be included in the trial run?</p>
                          </label>
                          <nb-select fullWidth [(ngModel)]="template.TrialOptions.RecipientSelection" >
                            <nb-option *ngFor="let option of recipientOptions" [value]="option.value">{{ getAutoRecipientValue(option.value) }}</nb-option>
                          </nb-select>
                          <textarea *ngIf="template.TrialOptions.RecipientSelection === 6" #textareaElement (input)="onRecipientlistChange(textareaElement.value)" type="text" placeholder="Enter External ID's separated with commas (eg: 1234, 5678)" nbInput fullWidth></textarea>
                        </ng-container>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>MetaData</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <label>
                          <p>MetaData 1 Label</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.Meta1">
            
                        <label>
                          <p>MetaData 2 Label</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.Meta2">
            
                        <label>
                          <p>MetaData 3 Label</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.Meta3">
            
                        <label>
                          <p>MetaData 4 Label</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.Meta4">
            
                        <label>
                          <p>MetaData 5 Label</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.Meta5">
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item>
                    <nb-accordion-item-header>Notes</nb-accordion-item-header>
                    <nb-accordion-item-body>
                      <label>
                        <p>Is there a message that helpdesk users need to see when supporting this template?</p>
                      </label>
                      <textarea fullWidth [(ngModel)]="template.HelpDeskMessage"></textarea>
                      <label>
                        <p>Are there any other notes on this template?</p>
                      </label>
                      <textarea fullWidth [(ngModel)]="template.Notes"></textarea>
                    </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Access Tokens</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>API Endpoint</th>
                              <th>Remove</th>
                              <th>Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="template.AccessTokens.length < 1">
                              <td colspan="4">No tokens added</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="4">
                                <button nbButton status="primary">New token</button>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Consolidated</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <nb-checkbox status="basic" [(ngModel)]="isConsolidated" [checked]="template.ConsolidatedCountRazor === null || !template.ConsolidatedCountRazor ? false : true">Has consolidated items?</nb-checkbox>
                        
                        <div *ngIf="isConsolidated">
                          <label>
                            <p>Enter consolidated Key (Razor)</p>
                          </label>
                          <textarea  [(ngModel)]="template.ConsolidatedCountRazor"></textarea>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item  *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Run Notifications</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <label>
                          <p>Notification Email Recipients</p>
                        </label>
                        <input type="text" nbInput fullWidth [(ngModel)]="template.NotificationEmailRecipients" (ngModelChange)="buildEmailArray($event)">
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Allowed Actions</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="inline-check">
                          <nb-checkbox status="basic" [(ngModel)]="template.AllowedActions.DownloadIfs">Enable download InfoSlip</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.AllowedActions.DownloadPdf">Enable download PDF</nb-checkbox>
                          <nb-checkbox status="basic" [(ngModel)]="template.AllowedActions.Print">Enable print</nb-checkbox>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Processing Container</nb-accordion-item-header>
                      <nb-accordion-item-body>  
                        <nb-checkbox status="basic" (click)="toggleProcessingContainer()" [(ngModel)]="IsProcessingContainerEnabled">Enabled</nb-checkbox>
                        <ng-container *ngIf="template.ProcessingContainer?.IsEnabled">
                          <label>
                            <p>Container Size</p>
                          </label>
                          <input type="number" nbInput fullWidth [(ngModel)]="template.ProcessingContainer.ContainerSize">
                          <label>
                            <p>Message Handler </p>
                          </label>
                          <input type="number" nbInput fullWidth [(ngModel)]="template.ProcessingContainer.ContainerMessageHandler">
                        </ng-container>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
            
                    <nb-accordion-item *ngIf="isRunTemplate">
                      <nb-accordion-item-header>Open Run</nb-accordion-item-header>
                      <nb-accordion-item-body>  
                        <nb-checkbox status="basic" [(ngModel)]="isOpenRunEnabled" (checkedChange)="enableOpenRun($event, $event ? OpenRunSchedule.Monthly : OpenRunSchedule.NotSet)">Enabled</nb-checkbox>
                        <ng-container *ngIf="isOpenRunEnabled">
                          <label>
                            <p>Run Name Template</p>
                          </label>
                          <input type="text" nbInput fullWidth [(ngModel)]="template.OpenRunNameTemplate">
                          <label>
                            <p>Schedule</p>
                          </label>
                          <nb-select fullWidth [(ngModel)]="template.OpenRunSchedule" [selected]="template.OpenRunSchedule" (selectedChange)="scheduleOpenRun($event)">
                            <nb-option *ngFor="let type of openRunSchedule$ | async" [value]="type.id">{{ type.value }}</nb-option>
                          </nb-select>
                        </ng-container>
                      </nb-accordion-item-body>
                    </nb-accordion-item>

                    <nb-accordion-item *ngIf="isRunTemplate && authService.isSystemAdmin">
                      <nb-accordion-item-header>Advanced Settings</nb-accordion-item-header>
                      <nb-accordion-item-body>  
                        <label>
                          <p>Max Concurrent Runs</p>
                        </label>

                        <div class="clear-btn">
                          <input type="number" min="1" nbInput fullWidth [(ngModel)]="template.MaxConcurrentRuns" (ngModelChange)="template.MaxConcurrentRuns = setMaxConcurrentRuns($event)">
                          <button nbButton (click)="template.MaxConcurrentRuns = null" style="margin-top: 0px !important; height: 40px;">
                            <nb-icon icon="close-outline" pack="eva"></nb-icon>
                          </button>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
                  </nb-accordion>
                </nb-card-body>
              </nb-card>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Email Template'">
          <ng-container *ngIf="!template.EmailTemplate.IsEnabled">
            <ng-container  *ngTemplateOutlet="notEnabled; context: 
              { 
                heading: 'Email Template',
                model: 'EmailTemplate'
              }">
            </ng-container>
          </ng-container>
          <ifs-admin-email-template *ngIf="template.EmailTemplate.IsEnabled" [template]="template" [tinyEnabled]="tinyEnabled" [disabled]="template.UseCmsEmailBody"></ifs-admin-email-template>
        </ng-template>

        <ng-template [ngSwitchCase]="'SMS Template'">
          <ng-container *ngIf="!template.SmsTemplate.IsEnabled">
            <ng-container  *ngTemplateOutlet="notEnabled; context: 
              { 
                heading: 'SMS Template',
                model: 'SmsTemplate'
              }">
            </ng-container>
          </ng-container>
          <ifs-admin-sms-template *ngIf="template.SmsTemplate.IsEnabled" [template]="template" [isSmsEnabled]="isSmsEnabled"></ifs-admin-sms-template>
        </ng-template>

        <ng-template [ngSwitchCase]="'Export Template'">
          <ng-container *ngIf="!template.ExportTemplate.IsEnabled">
            <ng-container  *ngTemplateOutlet="notEnabled; context: 
              { 
                heading: 'Export Template',
                model: 'ExportTemplate'
              }">
            </ng-container>
          </ng-container>
          <nb-card class="input-box" *ngIf="template.ExportTemplate.IsEnabled">
            <nb-card-body class="padded">
              <div class="row">
                  <div class="col-md-6">
                    <label>
                      <p>Type</p>
                    </label>
                    <nb-select fullWidth [(ngModel)]="template.ExportTemplate.Type"
                      [selected]="template.ExportTemplate.Type">
                      <nb-option *ngFor="let type of exportTypeCodes$ | async" [value]="type.id">{{ type.value }}
                      </nb-option>
                    </nb-select>

                    <label>
                      <p>
                        {{ 
                          template.ExportTemplate.Type <= 2 ? "Host" : (template.ExportTemplate.Type === 4 ? "Bucket (optional)" : "Path")
                        }}
                      </p>
                    </label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.ExportTemplate.Host" />

                    <ng-container *ngIf="template.ExportTemplate.Type <= 2">
                      <label>
                        <p>Port</p>
                      </label>
                      <input type="text" nbInput fullWidth [(ngModel)]="template.ExportTemplate.Port" />
                    </ng-container>
                  </div>
                  <div class="col-md-6" *ngIf="template.ExportTemplate.Type <= 2 || template.ExportTemplate.Type ===  4">
                    <label>
                      <p>
                        {{ 
                          template.ExportTemplate.Type <= 2 ? "UserName" : "Access Key (optional)"
                        }}
                      </p>
                    </label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.ExportTemplate.UserName" />

                    <label>
                      <p>
                        {{
                          template.ExportTemplate.Type <= 2 ? "Password" : "Secret Access Key (optional)"
                        }}
                      </p>
                    </label>
                    <ifs-secure-keys model="Password" [isNew]="isNew" (updatedKey)="updateExportTemplate($event)" [secureKey]="template.ExportTemplate.Password" [type]="'runTemplate'" [id]="template.Id"></ifs-secure-keys>

                  </div>
                  <div class="col-md-12">
                    <label>
                      <p>Control file line template (Razor):</p>
                    </label>
                    <textarea nbInput fullWidth
                      [(ngModel)]="template.ExportTemplate.ControlFileLineRazorTemplate"></textarea>
                  </div>          
              </div>
            </nb-card-body>
          </nb-card>
        </ng-template>

        <ng-template [ngSwitchCase]="isRunTemplate ? 'Run Template Source' : 'Template Source'">
          <div class="row">
            <ng-container *ngIf="isNew || !isRunTemplate">
              <nb-card class="input-box">
                <nb-card-header>{{ isRunTemplate ? 'Run Template Source' : 'Template Source' }}</nb-card-header>
                <nb-card-body class="padded">
                  <label>Git Service Provider</label>
                  <nb-select fullWidth 
                    [(ngModel)]="template.TemplateSource.TemplateRepositoryType" 
                    [selected]="template.TemplateSource.TemplateRepositoryType"
                    (ngModelChange)="handleSourceProviderChange()">
                    <nb-option *ngFor="let type of templateRepositoryType$ | async" [value]="type.id">{{ type.value }}</nb-option>
                  </nb-select>
  
                  <label>Repository (Project)</label>
                  <input type="text" nbInput fullWidth [(ngModel)]="template.TemplateSource.Project">

                  <ng-container *ngIf="template.TemplateSource.TemplateRepositoryType === 3">
                    <label>Group</label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.TemplateSource.Group">
                  </ng-container>
  
                  <label>Branch</label>
                  <input type="text" nbInput fullWidth [(ngModel)]="template.TemplateSource.Branch" (ngModelChange)="template.TemplateBranch = template.TemplateSource.Branch">
                  
                  <label>Folder</label>
                  <input type="text" class="folder-input" #folderInput [nbTooltip]="shouldDisplayTooltip() ? template.TemplateFolder : ''" [nbTooltipTrigger]="!shouldDisplayTooltip() ? 'noop' : 'hover'" nbInput fullWidth [(ngModel)]="template.TemplateSource.Folder" (ngModelChange)="template.TemplateFolder = template.TemplateSource.Folder">
                </nb-card-body>
              </nb-card>
            </ng-container>

            <ng-container *ngIf="!isNew && isRunTemplate">
              <div class="col-md-6">
                <nb-card class="input-box">
                  <nb-card-header>
                    Run Template Source 
                    <button *ngIf="!isNew" nbButton status="primary" class="clearCache" (click)="clearTemplateCache()" style="margin-top: -10px !important;">Clear Cache</button>
                  </nb-card-header>
                  <nb-card-body class="padded">

                    <label><p>Branch</p></label>
                    <input type="text" nbInput fullWidth [(ngModel)]="template.TemplateBranch">

                    <ng-container *ngIf="!isNew">
                      <ng-container *ngIf="useDifferentFolder">
                        <label><p>Folder</p></label>
                        <input type="text" class="folder-input" #folderInput [nbTooltip]="shouldDisplayTooltip() ? template.TemplateFolder : ''" [nbTooltipTrigger]="!shouldDisplayTooltip() ? 'noop' : 'hover'" nbInput fullWidth [(ngModel)]="template.TemplateFolder">
                      </ng-container>
                      <nb-checkbox status="basic" fullWidth [(ngModel)]="useDifferentFolder" [checked]="template.TemplateFolder">Would you like to use a different source folder?</nb-checkbox>
                    </ng-container>
                  </nb-card-body>
                </nb-card>
              </div>
              
              <div class="col-md-6" *ngIf="!isNew">
                <nb-card size="medium" class="input-box">
                  <nb-card-header>
                    Base Configuration
                    <button nbButton status="primary" class="clearCache" (click)="testTemplateSource()" style="margin-top: -10px !important;" [nbSpinner]="fetchingTemplateFiles">Test Source</button>
                  </nb-card-header>
                  <nb-list>
                    <nb-list-item>
                      <p>{{ sourceTemplate?.TemplateSource?.Type}} (<span class="descriptor">Service</span>)</p>
                    </nb-list-item>
                    <nb-list-item>
                      <p>{{ sourceTemplate?.TemplateSource?.Branch}} (<span class="descriptor">Branch</span>)</p>
                    </nb-list-item>
                    <nb-list-item>
                      <p>{{ sourceTemplate?.TemplateSource?.Project}} (<span class="descriptor">Repository / Project</span>)</p>
                    </nb-list-item>
                    <nb-list-item *ngIf="sourceTemplate?.TemplateSource?.TemplateRepositoryType===3">
                      <p>{{ sourceTemplate?.TemplateSource?.Group}} (<span class="descriptor">Group</span>)</p>
                    </nb-list-item>
                    <nb-list-item>
                      <p>{{ sourceTemplate?.TemplateSource?.Folder}} (<span class="descriptor">Folder</span>)</p>
                    </nb-list-item>
                  </nb-list>
                </nb-card>
              </div>
            </ng-container>
        
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Run Templates'">
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <label>
                  <p>Linked Run Templates: {{runTemplateCount}}</p>
                </label>
                <ul>
                  <li *ngFor="let linked of linkedItems">
                    <a class="pointer-cursor" routerLink="/admin/runtemplates/{{linked.id}}">{{linked.name + " - " + linked.id}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'CMS'">
          <!-- <div class="row">
            <div class="col-md-6">
              <div class="input-box"> -->
                <nb-card class="entity-card">
                  <nb-card-header>
                    <div class="row">
                      <div class="col-md-6 entity-header">
                        Linked Entities: {{template.CmsEntities.length}} 
                        <nb-actions size="small">
                          <nb-action icon="edit-2-outline" routerLink="/cms/runtemplates/{{template.Id}}" nbTooltip="Manage Linked Entities" nbTooltipStatus="primary"></nb-action>
                        </nb-actions>
                      </div>
                    </div>
                    <nb-form-field>
                      <input nbInput fullWidth placeholder="Search linked entities..." [(ngModel)]="searchValue" (ngModelChange)="filterLinkedEntities($event)"  />
                      <button nbSuffix nbButton ghost (click)="clearSearch()">
                        <nb-icon icon="close-outline" pack="eva"></nb-icon>
                      </button>
                    </nb-form-field>                    
                  </nb-card-header>
                  
                  <nb-card-body class="padded">
                    <div class="row full-width m-0">
                      <div class="col-sm-12 col-md-12 col-lg-6" *ngFor="let entity of filteredLinkedEntities; let i = index">
                        <entity-card-component 
                          class="ifs-card p1"                             
                          [cmsEntity]="entity" 
                          type="linked"
                          [selectedRunTemplate]="template"
                          [readOnly]="true">
                        </entity-card-component>
                      </div>
                    </div>
                  </nb-card-body>
                </nb-card>
              <!-- </div>

            </div>
          </div> -->
        </ng-template>

        <ng-template [ngSwitchCase]="'Pre-Processor'" *ngIf="isRunTemplate">
          <ng-container *ngIf="template.HasExternalProcessing">
            <ng-container  *ngTemplateOutlet="notEnabled; context: 
              { 
                heading: 'Pre-Processor',
                model: 'PreProcessor'
              }">
            </ng-container>
          </ng-container>
          <ifs-admin-pre-processor *ngIf="!template.HasExternalProcessing" [PreProcessor]="template.PreProcessor" (onChange)="template.PreProcessor = $event" [ConfigForProcessing]="template.ConfigForProcessing" (setConfig)="template.ConfigForProcessing = $event" (setCollectableAssembly)="template.PreProcessor.ClassData.AssemblyIsNotCollectable = $event" [isNew]="isNew"></ifs-admin-pre-processor>
        </ng-template>

        <ng-template [ngSwitchCase]="'Post-Processor'" *ngIf="isRunTemplate">
          <nb-card class="input-box">
            <!-- <nb-card-header>
                <nb-checkbox (checkedChange)="disablePreProcessor($event)" name="smsEnabled" [ngModel]="isPreProcEnabled" status="basic">Enable Pre-Processor?</nb-checkbox>
              </nb-card-header> -->
            <nb-card-body class="padded">
              <ifs-admin-post-processor [PostProcessor]="template.PostProcessors" (updatePostProcessors)="this.template.PostProcessors = $event"></ifs-admin-post-processor>
            </nb-card-body>
          </nb-card>
        </ng-template>

        <ng-template [ngSwitchCase]="'Preview Template'" *ngIf="isRunTemplate && !isNew">
          <div style="margin-top: 10px; height: 100%;">
            <ifs-admin-preview-template [template]="template"></ifs-admin-preview-template>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Run Search Data'" *ngIf="isRunTemplate">
          <div style="margin-top: 10px;">
            <ifs-admin-run-search-data-crud 
              [(searchDataEntity)]="template.SearchDataCmsEntity" 
              [SearchFields]="template.SearchFields" 
              (updateSearchFields)="template.SearchFields = $event" 
              (addRunSearchEntity)="searchDataCmsEntity = template.SearchDataCmsEntity = $event"
              (removeRunSearchEntity)="template.SearchDataCmsEntity = null"
              ></ifs-admin-run-search-data-crud>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Signatures'" *ngIf="isRunTemplate">
          <ng-container *ngIf="!template.Signatures.IsEnabled">
            <ng-container *ngTemplateOutlet="notEnabled; context: 
              { 
                heading: 'Signatures',
                model: 'Signatures'
              }">
            </ng-container>
          </ng-container>
          <ifs-admin-digital-signatures [template]="template"></ifs-admin-digital-signatures>
        </ng-template>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>New Customer</nb-card-header>
    <nb-card-body>
      <input type="text" nbInput fullWidth placeholder="Enter Customer Name" [(ngModel)]="customerName">
    </nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="ref.close()" style="margin-right: 12px;">Close</button>
      <button nbButton (click)="createCustomer()" status="primary" [disabled]="!customerName || customerName?.length == 0">Add</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #notEnabled let-heading="heading" let-model="model">
  <nb-card class="input-box">
    <nb-card-header>
        <h4>{{heading}}</h4>
      </nb-card-header>
    <nb-card-body class="padded pv-16">
      This option is not currently enabled. Please toggle it in the menu or press "Enable" below.
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" (click)="model === 'PreProcessor' ? preProcEnabled(true) : template[model].IsEnabled = true">Enable</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #nextStep let-data let-ref="nextStepDialogRef">
    <nb-card class="last-step">
      <nb-card-header>
        <h5>{{ data.title }} <nb-icon (click)="modify(data.routeEdit, ref)" icon="close" class="close"></nb-icon></h5>
      </nb-card-header>
      <nb-card-body class="padded">
        <p>{{ data.body }}</p>
      </nb-card-body>
      <nb-card-footer class="button-padding">
        <!-- <button nbButton (click)="resetWizard()">Create New</button> -->
        <button nbButton (click)="modify(data.routeEdit, ref)" status="primary" outline>Modify</button>
        <button nbButton (click)="reset()" outline status="primary">Create Another</button>
        <button *ngIf="!isRunTemplate" nbButton (click)="handleDialogRouting(data.routeNext)" status="primary">{{ data.button }}</button>
        <button *ngIf="isRunTemplate" nbButton (click)="createRun(data.runTemplate, ref)" status="primary">{{ data.button }}</button>

      </nb-card-footer>
    </nb-card>
</ng-template>