import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import * as chroma from 'chroma-js';

@Component({
  selector: 'ifs-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss']
})
export class ColorInputComponent implements OnChanges {

  @Input('color') color;
  @Output('themeChange') themeChange = new EventEmitter();

  themePalette = {};
  themeImageConfig = {};
  colors =  {
    primary: '#7BB026',
    secondary: '#202120',
    success: '#78BE20',
    info: '#00B2A9',
    warning: '#F2A900',
    danger: '#D22630',
    modal: '#2b2b2b',
    'active tab': '#202120',
    actions: '#2b2b2b',
    'actions hover': '#2b2b2b',
    header: '#63921A'
  }
  root: any = document.querySelector('.nb-theme-light');

  ngOnChanges(changes: SimpleChanges) {
    if (this.colors[changes?.color?.currentValue?.name?.toLowerCase()] != undefined)
      this.colors[changes?.color?.currentValue?.name?.toLowerCase()] = changes?.color?.currentValue?.value;
  }

  getColors(color, propertyName){
    const scaleVarName = `color-${propertyName}-`;
    const colors = [ '#fff', color, '#000' ];
    const scale = chroma.scale(colors).domain([0,0.5,1]).mode('hsl').correctLightness(false);
    // Interpolate colors, skipping the generation of white and black.
    for (let i = 1, steps = 10; i < steps; i++) {
      const point = i / steps;
      // Convert point to numbering system value.
      const name = Math.round(point * 1000);
      // Generate RGB color from luminance location along scale.
      const hex = scale(point).hex();
      // Output.
      const varName = scaleVarName + name;

      if(propertyName === 'primary' && name === 500){
        this.themePalette['button-outline-primary-text-color'] =
        this.themePalette['button-outline-primary-border-color'] =
        this.themePalette['button-outline-primary-hover-border-color'] =
        this.themePalette['button-outline-primary-hover-text-color'] =
        this.themePalette['color-primary-default'] =
        this.themePalette['color-primary-default-border'] =
        this.themePalette['color-primary-active'] =
        this.themePalette['color-primary-active-border'] =
        this.themePalette['button-filled-primary-focus-background-color'] =
        this.themePalette['button-filled-primary-focus-border-color'] = hex;
        this.themePalette['button-outline-primary-background-color'] = this.hexToRgbA(hex, 0.08);
        this.themePalette['button-outline-primary-hover-background-color'] = this.hexToRgbA(hex, 0.16);
      }

      if(propertyName === 'primary' && name === 400){
        this.themePalette['color-primary-hover-border'] = 
        this.themePalette['color-primary-hover'] = hex;
      }

      if(propertyName === 'active tab' && name === 500){
        this.themePalette['modal-tab-color'] = hex; 
      }

      if(propertyName === 'modal' && name === 500){
        this.themePalette['modal-color'] = hex;
      }

      if(propertyName === 'actions' && name === 500){
        this.themePalette['modal-action-color'] = hex;
        this.themePalette['modal-action-opacity'] = 1;
      }

      if(propertyName === 'actions hover' && name === 500){
        this.themePalette['modal-action-hover-color'] = hex;
      }
      if(propertyName === 'header' && name === 500){
        this.themePalette['header-background-color'] = hex;
      }
      
      this.root.style.setProperty(`--${varName}`, hex);

      if(!varName.includes('actions') 
      && !varName.includes('header') 
      && !varName.includes('actions hover') 
      && !varName.includes('modal') 
      && !varName.includes('active tab')){
        this.themePalette[`${varName.replace(/ /g, '-')}`] = hex;
      }
    }

    this.themeChange.emit(this.themePalette);
  }

  hexToRgbA(hex, transparency){
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        let color = hex.substring(1).split('');
        if(color.length== 3){
          color = [color[0], color[0], color[1], color[1], color[2], color[2]];
        }
        color = `0x${color.join('')}`;
        return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',')}, ${transparency})`;
    }
  }

}
