<div class="row">
  <div class="col-md-3">
  <ifs-nav class="content-nav"></ifs-nav>
  </div>

  <div class="col-md-9">
    <div class="content-detail"  @slideInAnimation>
      <ifs-security-form></ifs-security-form>
    </div>
  </div>
</div>
