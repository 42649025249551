import { Component, Input, OnInit } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import { RunLogs, RunModel } from '@InfoSlips/models';
import { Observable } from 'rxjs';
@Component({
  selector: 'ifs-run-logs',
  templateUrl: './run-logs.component.html',
  styleUrls: ['./run-logs.component.scss']
})
export class RunLogsComponent implements OnInit {
  @Input() selectedRunService:SelectedRunService;

  selectedRun: RunModel;
  logs: RunLogs[];  
  isLoading$ = new Observable<boolean>();
  
  constructor() {}
  
  ngOnInit(): void {
    this.selectedRunService.selectedRun$.subscribe(run=>{
      this.selectedRun = run;
      this.selectedRunService.loadRunLogs();
    });
    
    this.selectedRunService.runAuditLog$.subscribe(res => {
      this.logs = res;
    });
  }

  onScroll() {
    this.selectedRunService.pageRunAuditLogs();
  }
}