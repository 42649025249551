import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { PageGraphQLData, PreProcessorModel } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export interface Processor {
  name:          string;
  id:            string;
  classes:       Class[];
  files:         File[];
  created:       string;
  createdBy:     string;
  lastUpdated:   string;
  lastUpdatedBy: string;
}

interface Class {
  assemblyIsNotCollectable: boolean;
}

interface File {
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProcessorService {

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.preProcessors = new ObservableWrapper<Processor>(
      this.apiBaseService,
      this._pageProcessorsQuery('Pre'),
      this._fetchPreProcessorData,
    false);

    this.postProcessors = new ObservableWrapper<Processor>(
      this.apiBaseService,
      this._pageProcessorsQuery('Post'),
      this._fetchPostProcessorData,
    false);

  }

  public preProcessors: ObservableWrapper<Processor>;
  public postProcessors: ObservableWrapper<Processor>;

  private _pageProcessorsQuery(context: string):string {
    return context.toLocaleLowerCase()==='pre'?
    `query ${context}Processors($first: Int, $where: ${context}ProcessorAssembliesFilterInput = null) {
      ${context.toLowerCase()}Processors(first: $first, where: $where, order: { name: ASC}){
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges {
          node {
            name
            id
            classes {
              requiredFiles {
                description
                fileName
                isRequired
                disableAutoUnzip
              }
              expectConfiguration
              configurationSample
              className
              assemblyName
              interfaces
              assemblyIsNotCollectable
              id
            }
            files {
              fileName
              fileId
              uploadFile
              size
              uploaded
              uploadedBy
              shortUrl
            }
            useRawStreams
            created
            createdBy
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }`:
    `query ${context}Processors($first: Int, $where: ${context}ProcessorAssembliesFilterInput = null) {
      ${context.toLowerCase()}Processors(first: $first, where: $where, order: { name: ASC}){
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges {
          node {
            name
            id
            classes {
              assemblyIsNotCollectable
            }
            files {
              fileName
            }
            created
            createdBy
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }`;
  }

  private _fetchPreProcessorData(rawData: any): PageGraphQLData<Processor> {
    try{
      return rawData.data.preProcessors as PageGraphQLData<Processor>;
    }
    catch(e)
    {
      console.log("runPreProcessorData That FAILED", rawData);
      return null;
    }
  }

  private _fetchPostProcessorData(rawData: any): PageGraphQLData<Processor> {
    try{
      return rawData.data.postProcessors as PageGraphQLData<Processor>;
    }
    catch(e)
    {
      console.log("runPostProcessorData That FAILED", rawData);
      return null;
    }
  }

  private _pageProcessorByIdQuery(processorId: string, context: string){

    return context==='pre'?
    `query ProcessorById {
      preProcessorById(id: "${processorId}"){
        name
        id
        classes {
          assemblyIsNotCollectable
          expectConfiguration
          configurationSample
          id
          className
          interfaces
          requiredFiles {
            description
            fileName
            isRequired
            disableAutoUnzip
          }
        }
        files {
          fileName
          size
          uploaded
          uploadedBy
          shortUrl
          uploadFile
        }
        created
        createdBy
        lastUpdated
        lastUpdatedBy
        useRawStreams
      }
    }`:
    `query ProcessorById {
      postProcessorById(id: "${processorId}"){
          name
          id
          classes {
            assemblyIsNotCollectable
            id
            className
            interfaces
    
          }
          files {
            fileName
            uploadFile
            size
            uploaded
            uploadedBy
            shortUrl
          }
          created
          createdBy
          lastUpdated
          lastUpdatedBy
        }
    }`;
      }

  upload(context: string, processorId: string, file: any){
    const fileToUpload = file;
    const formData = new FormData();
    
    formData.append('file', fileToUpload, fileToUpload.name); 
    return this.apiBaseService.executePostProgress(`${context}ProcessorAssemblies/${processorId}/AddOrUpdate${context}ProcessorFiles`, formData, 'Add or Update Processor.')
  }

  removeFile(context: string, processorId: string, fileName: string){
    let subject = new Subject<boolean>();
    this.apiBaseService.executePut<PreProcessorModel>(`${context}ProcessorAssemblies/${processorId}/Remove${context}ProcessorFiles/${fileName}`, null, 'Remove processor file.').subscribe((updated: PreProcessorModel) => {
      updated ? this.toastr.info('File Removed!') : this.toastr.error('File Removal Failed.');
      subject.next(updated? true : false);
    });

    return subject.asObservable();
  }

  delete(context: string, processorId: string){
    this.apiBaseService.executeDelete(`${context}ProcessorAssemblies/${processorId}`, `Delete ${context} Processor.`).subscribe(isDeleted => {
      isDeleted ? this.toastr.info(`${context} Processor deleted!`) : this.toastr.error(`${context} Processor deletion failed.`);
    });
  }

  getProcessorById(processorId: string, context: string){
    let subject = new Subject<Processor>();
    
    const model = {
      query: this._pageProcessorByIdQuery(processorId, context),
      operationName: 'ProcessorById'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Processor By ID.').subscribe((res: any) => {
      const obj = res.data, key = Object.keys(obj)[0];
      subject.next(obj[key] ? obj[key] : {
        name: null,
        files: [],
      })
    })

    return subject.asObservable();
  }

  calculateSize(size:number){
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (size === 0) size = Number.NaN;
    const i = Math.floor(Math.log(size) / Math.log(1024));
    if (i === 0) return `${size} ${sizes[i]})`;
    return `${(size / (1024 ** i)).toFixed(2)} ${sizes[i]}`;
  }

  download(context: string, processorId: string, fileName: string){    
    this.apiBaseService.executeGetAny(`${context}ProcessorAssemblies/${processorId}/Download/${fileName}`, 'Download Processor.', {
      responseType: 'blob',
      observe: 'response'
    }).pipe(tap(this.triggerDownloadEvent), catchError((error: HttpErrorResponse): Observable<any> => {
      if(error.status === 400){
        error.error.text().then((text:string) => {
          this.toastr.info(text);
        });
      }
      return throwError(error);
    })).subscribe();
  }
  
  triggerDownloadEvent(data:any) {
    let filename = data.headers.get('Content-Disposition') || 'download.zip';
    const foundEq = filename.indexOf('=');
    if (foundEq > -1) {
      filename = filename.substr(foundEq + 1);
    }

    const url = window.URL.createObjectURL(data.body); // <-- work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }

  save(context: string, model:PreProcessorModel){
    this.apiBaseService.executePut<PreProcessorModel>(`${context}ProcessorAssemblies`, model, 'Save Processor').subscribe((savedItem:PreProcessorModel) => {
      savedItem ? this.toastr.info(`${context}Processor Saved!`) : this.toastr.error(`${context}Processor Save Failed.`);
    })
  }

  async create(context: string, model: PreProcessorModel): Promise<any>{
    return await this.apiBaseService.executePost(`${context}ProcessorAssemblies`, model, 'Create Processor').pipe(
      map((processor: any) => {
        if(processor){
          this.toastr.info(`${context}Processor Created!`);
        } else {
          this.toastr.error(`${context.charAt(0).toUpperCase() + context.slice(1)}Processor Creation Failed.`);
        }
        return processor;
      }
    )).toPromise();
  }

}
