import { Component } from '@angular/core';
import { CmsEntityModel, CmsLinkedToItem } from '@InfoSlips/models';
import { SelectedEntityService } from '../../services/selected-entity.service';

@Component({
  selector: 'cms-entity-references',
  templateUrl: './cms-entity-references.component.html',
  styleUrls: ['./cms-entity-references.component.scss']
})
export class CmsEntityReferencesComponent {
  linkedRuns:CmsLinkedToItem[]=[];
  linkedRunTemplates:CmsLinkedToItem[]=[];
  selectedEntity:CmsEntityModel;

  constructor(
    private selectedEntityService: SelectedEntityService) { 
      this.selectedEntityService.selectedEntity$.subscribe(entity=>{
        this.selectedEntity=entity;      
        this.selectedEntityService.loadReferences(entity.PublicId);
      });
      this.selectedEntityService.linkedRunTemplates$.subscribe(linked=>{
          if(linked==null)
            return;
                  
          this.linkedRunTemplates=linked;
      });
      this.selectedEntityService.linkedRuns$.subscribe(linked=>{
        if(linked==null)
          return; 
                
        this.linkedRuns=linked;
      });
  }

  onScroll(context: String) {
    if (context === "Runs")
      this.selectedEntityService.onScrollRuns();
    else if (context === "RunTemplates") 
      this.selectedEntityService.onScrollRunTemplates();
  }
}
