import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { RunsService, RunTemplateService } from '@InfoSlips/iiab-api';
import { RunSummary, TemplateMergeFieldsNebular } from '@InfoSlips/models';
import { NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifs-tiny-editor',
  templateUrl: './tiny-editor.component.html',
  styleUrls: ['./tiny-editor.component.scss']
})
export class TinyEditorComponent implements OnInit {
  @Input('data') data: any;
  @Output('dataChange') dataChange = new EventEmitter<any>();

  @Input('theme') theme: string;
  @ViewChild('dialog') private dialog: TemplateRef<any>;

  @Input() disabled = false;

  dialogRef;
  enabled: boolean = false;
  tinyMceConfig: any;

  // TinyMce Replace Email Body
  templates: any;
  replaceTemplate: any;
  runSummaries$: Observable<RunSummary[]> = this.runsService.runSummaries.Items$();

  constructor(
    public environment: EnvironmentService,
    private runTemplateService: RunTemplateService,
    private dialogService: NbDialogService,
    public runsService: RunsService,
  ) { }

  ngOnInit(): void {
    this.runSummaries$.subscribe(data => this.templates = data);

    this.runTemplateService
    .templateMergeFields(null)
    .subscribe((res: TemplateMergeFieldsNebular) => {
      this.configureTinyMce(res);
    });
  }

  valueChanged(value) {
    this.dataChange.emit(value);
  }   

  open(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, {
      context: 'this is some additional data passed to dialog',
    });
  }

  copy(template){
    this.runsService.getRunDetails(template.id).subscribe((res: any) => {
      //@ts-ignore
      document.getElementById('iFrameCopyTemplate').srcdoc = res.EmailTemplate.Body;
      this.replaceTemplate = res.EmailTemplate.Body;
    })
  }

  replace(){
    this.data = this.replaceTemplate;
  }

  close() {
    this.dialogRef.close();
  }

  configureTinyMce(fields) {
    this.tinyMceConfig = {
      menubar: false,
      plugins: [
        'fullpage advlist autolink lists link image charmap print',
        'preview anchor searchreplace visualblocks code',
        'fullscreen insertdatetime media table paste',
        'help wordcount contextmenu codesample textpattern',
      ],
      toolbar:
        'mergefields ifscopy | fullpage undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | preview | help | codesample',
      contextmenu: 'link image imagetools table spellchecker lists mergefields',
      skin: this.theme === 'light' ? 'oxide' : 'oxide-dark',
      content_css: this.theme === 'light' ? 'default' : 'dark',
      codesample_languages: [{text: 'HTML/XML', value: 'markup'},],
      setup: (editor) => {
        const that = this;
        const mergeFields = [];

        fields.forEach((field) => {
          const name = field.data.name;
          let children = [];

          field.children.forEach((child) => {
            children.push({
              type: 'menuitem',
              text: child.data.name,
              onAction: () => {
                editor.insertContent(child.data.path);
              },
            });
          });

          const tinyFields = {
            type: 'nestedmenuitem',
            text: name,
            getSubmenuItems: () => {
              return children;
            },
          };
          mergeFields.push(tinyFields);
        });

        editor.ui.registry.addMenuButton('mergefields', {
          text: 'Merge Fields',
          fetch: (callback) => {
            callback(mergeFields);
          },
        });

        editor.ui.registry.addNestedMenuItem('mergefields', {
          text: 'Merge Fields',
          getSubmenuItems: function () {
            return mergeFields;
          },
        });

        editor.ui.registry.addButton('ifscopy', {
          icon: 'copy',
          tooltip: 'Copy Existing Email Body',
          onAction: function () {
            that.open(that.dialog);
          },
        });

        editor.ui.registry.addAutocompleter('specialchars', {
          ch: '@',
          minChars: 1,
          columns: 1,
          fetch: function (pattern) {
            const mergeFields = [];
            fields.forEach((field) => {
              const parent = field.data.name;
              field.children.forEach((child) => {
                mergeFields.push({
                  text: child.data.name,
                  value: child.data.path,
                  parent: parent,
                });
              });
            });
            const matchedChars = mergeFields.filter(function (char) {
              return char.text.indexOf(pattern) !== -1;
            });

            return new Promise(function (resolve) {
              const results = matchedChars.map(function (char) {
                return {
                  value: char.value,
                  text: `${char.parent}: ${char.text}`,
                };
              });
              resolve(results);
            });
          },
          onAction: function (autocompleteApi, rng, value) {
            editor.selection.setRng(rng);
            editor.insertContent(value);
            autocompleteApi.hide();
          },
        });
      },
    };

    this.enabled = true;
  }

}
