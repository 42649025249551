import { Injectable } from '@angular/core';
import { HistoryCard, PageGraphQLData } from '@InfoSlips/models';
import * as models from '@InfoSlips/models';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryCardsService {

  public isLoading$: Observable<boolean>;

  public  historyCardsWrapper: ObservableWrapper<HistoryCard>;
  public historyCards$: Observable<HistoryCard[]>;

  documentTypesSubject = new BehaviorSubject<any>(null);
  documentTypes$ = this.documentTypesSubject.asObservable();

  companiesSubject = new BehaviorSubject<any>(null);
  companies$ = this.companiesSubject.asObservable();

  refreshAsisHistorySubject = new BehaviorSubject<boolean>(false);
  refreshAsisHistory$ = this.refreshAsisHistorySubject.asObservable();

  constructor(private apiBaseService: ApiBaseService) { }

  private _historyCardsQuery(refresh = false):string{
    return `query HistoryPaged($first: Int, $afterCursor: String = null, $where: ViewerCardsFilterInput = null)
    {
      historyCards(first: $first, after: $afterCursor, order: {runDate: DESC}, where: $where, refresh: ${refresh}) {
            totalCount,
            pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
            }
            edges{
              cursor,
              node{
                customerId,
                customerName,
                documentType,
                id,
                image,
                period,
                publisherId,
                runDate,
                runName,
                sequences{
                  groupName,
                  label,
                  number,
                  overview{
                    body,
                    sequence,
                    title
                  }
                }
              }
            }
          }
    }
    `;
  }

  private _fetchHistoryCardsData(rawData:any){
    try{
      return rawData.data.historyCards as PageGraphQLData<HistoryCard>;
    }
    catch(e)
    {
      console.log("runData That FAIlED", rawData);
      return null;
    }
  }

  loadAllHistory(refresh = false) {
    this.historyCardsWrapper = new ObservableWrapper<models.HistoryCard>(
      this.apiBaseService,
      this._historyCardsQuery(refresh),
      this._fetchHistoryCardsData,
      true);
    this.historyCards$ = this.historyCardsWrapper.Items$(undefined, 100);

    this.isLoading$ = this.historyCardsWrapper.isLoading$;
    
    this.getFilters();
  
    combineLatest([this.historyCardsWrapper.hasNextPage$, this.historyCardsWrapper.isLoading$]).pipe(
      map(([hasNextPage, loading]) => hasNextPage && !loading ),
      distinctUntilChanged()
    ).subscribe(res => {
      if (res == true) this.historyCardsWrapper.loadNextPage(100);
    })
  }

  getFilters(){
    return this.historyCardsWrapper.Items$().subscribe(response => {
      this.documentTypesSubject.next(this.getUniques(this.documentTypesSubject.getValue(), response.map(item => item.documentType)));
      this.companiesSubject.next(this.getUniques(this.companiesSubject.getValue(), response.map(item => item.customerName)));
    })
  }
  
  getUniques(oldArray: any[], newArray: any[]) {
    const uniqueSet = new Set([...oldArray || [], ...newArray]);
    return Array.from(uniqueSet);
  }

  clearInfoSlipData(){
    this.documentTypesSubject.next(null);
    this.companiesSubject.next(null);
    this.historyCardsWrapper.ClearItems$();
  }

  refreshAsisHistory() {
    this.clearInfoSlipData();
    this.loadAllHistory(true);
  }
}


