import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@aspnet/signalr';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { MonacoEditorConfig } from '../../monacoConfig';

@Component({
  selector: 'ifs-monaco-editor',
  templateUrl: './monaco-editor.component.html',
  styleUrls: ['./monaco-editor.component.scss']
})
export class MonacoEditorComponent implements OnInit {
  @Input('data') data: any;
  @Input() url: string;
  @Input() language = 'plaintext';
  @Input() readonly = false;
  @Output('onChange') onChange = new EventEmitter<any>();

  editorExtension = '*.*';

  constructor(
    public monacoConfig: MonacoEditorConfig,
    private apiBaseService:ApiBaseService
  ) { }

  ngOnInit(): void {
    this.setMonacoLanguage(this.language);

    if(this.url)
    {
      this.apiBaseService
        .executeGetAny<string>(this.url, 'Get Online Content', {responseType: 'text'})
        .subscribe((content:any)=>this.data=content);
    }
  }

  valueChanged(value) {
    this.onChange.emit(value);
  }   

  setMonacoLanguage(editorLanguage: string) {
    this.monacoConfig.editorOptions = { ... this.monacoConfig.editorOptions, language: editorLanguage, readOnly: this.readonly };
        
  }
}
