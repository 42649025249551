import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'ifs-run-report-download-component',
  templateUrl: './run-report-download.component.html',
  styleUrls: ['./run-report-download.component.scss']
})
export class RunReportDownloadComponent implements OnInit {
  @Input() reportDownloading: boolean;

  @Output() downloadEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.downloadEvent.emit();
  }
}
