import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@InfoSlips/iiab-auth';
import { PasswordMatch } from '@InfoSlips/shared';

@Component({
  selector: 'ifs-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit {
  @Output()
  update = new EventEmitter<any>();

  changePasswordForm: FormGroup;
  passwordRules = [
    {
      label: 'At least one digit',
      value: `[0-9]`
    },
    {
      label: 'At least one lowercase character',
      value: `[a-z]`
    },
    {
      label: 'At least one uppercase character',
      value: `[A-Z]`
    },
    {
      label: 'At least one special character',
      value: `[*!@#$%^&(){}[]:;<>,.?/~_-=|\\]`
    },
  ]

  constructor(
    private fb: FormBuilder,
    public auth: AuthService) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: PasswordMatch('password', 'confirmPassword')
      }
    );
  }

get form(){
  return this.changePasswordForm.controls;
}

  onSubmit() {
    this.update.emit(this.changePasswordForm.value);
  }
}
