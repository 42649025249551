import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'ifs-side-nav',
  styleUrls: ['./side-nav.component.scss'],
  template: `
      <div class="expand" [class.active]="expand">

      </div>
      <div class="side-nav-container">
        <div class="toggler" (click)="toggleNav()">
            <!-- <clr-icon class="icon" [attr.shape]="sidebarState"></clr-icon> -->
        </div>
      </div>
  `
})
export class SideNavComponent implements OnInit {
  expand = false;
  sidebarState = "navigation-menu";
  constructor() { }

  ngOnInit() {
  }

  toggleNav(){
    this.expand = !this.expand;
    this.sidebarState = this.expand ? "remove" : "navigation-menu";
  }

}
