<div class="row">
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>Template Files <nb-icon icon="sync-arrows" pack="oakmoon" (click)="getTemplateFiles()"></nb-icon></nb-card-header>
            <nb-card-body>
                <nb-list>
                    <nb-list-item *ngFor="let file of templateService.templateFiles$ | async" (click)="getTemplateFile(file)">
                      <nb-icon nb-icon [icon]="file.icon" pack="file-icons"></nb-icon>{{ file.name }}
                    </nb-list-item>
                  </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-8">
        <nb-card class="preview-card">
            <nb-card-header>
                <nb-tabset (changeTab)="navigate($event, ext)">
                    <ng-container *ngIf="ext === 'razor'">
                        <nb-tab tabTitle="File Content"></nb-tab>
                        <nb-tab tabTitle="Sample Data"></nb-tab>
                        <nb-tab tabTitle="Preview Output"></nb-tab>
                    </ng-container>

                    <ng-container *ngIf="ext === 'html'">
                        <nb-tab tabTitle="HTML - Input"></nb-tab>
                        <nb-tab tabTitle="HTML - Output"></nb-tab>
                    </ng-container>
                    
                    <nb-tab tabTitle="CSS - Input"  *ngIf="ext === 'css'"></nb-tab>
                    <nb-tab tabTitle="Image - Preview"  *ngIf="ext === 'image'"></nb-tab>
                    <nb-tab tabTitle="XML - Input" *ngIf="ext === 'xml'"></nb-tab>
                    <nb-tab tabTitle="XSLT - Input" *ngIf="ext === 'xslt'"></nb-tab>
                </nb-tabset>
            </nb-card-header>
            <nb-card-body>
                <ifs-monaco-editor *ngIf="enabled && ext!=='image'" [(data)]="razorModel" (onChange)="razorModel = $event"></ifs-monaco-editor>

                <div class="upload-box row" *ngIf="sampleDataEnabled">
                    <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                        <p>
                            Data For Processing
                        </p>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-md-4">
                        <div class="file-actions flex-right">
                            <span>
                                <input type="file" id="files" (change)="fileChange($event)" accept=".json,.text,application/json" />
                                <label class="choose-file" nbButton outline for="files">Choose File</label>
                            </span>
                        </div>
                    </div>
                </div>
                <ifs-monaco-editor *ngIf="sampleDataEnabled" [(data)]="sampleDataModel" (onChange)="sampleDataModel = $event"></ifs-monaco-editor>            
                <ifs-monaco-editor *ngIf="previewEnabled && ext!=='image'" [(data)]="previewOutput" (onChange)="previewOutput = $event"></ifs-monaco-editor>            
                
                <img class="shadow preview-image" *ngIf="ext === 'image'" [attr.src]="nonTextSource | secure | async"><img>
            </nb-card-body>
        </nb-card>
    </div>
</div>