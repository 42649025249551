import { BaseEntityIdNameModel } from "./BaseEntity";

export interface DigitalSignaturesModel{
    IsEnabled:                  boolean;
    RequireLocation:            boolean;
    AdditionalCaptureFields?:    AdditionalCaptureFieldsModel[];
    RequiredSignatures?:         RequiredSignaturesModel[];
    LiveRunTemplate:           BaseEntityIdNameModel;
}

export interface AdditionalCaptureFieldsModel {
    Name?:       string;
    InputType:  CaptureFieldInputType | string;
}

export interface RequiredSignaturesModel {
    Name?:       string;
    Required:   boolean;
}

export enum CaptureFieldInputType {
    FreeText,
    DateTime
}