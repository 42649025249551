import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TestingRestrictionsService } from '@InfoSlips/iiab-api';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard-testing-restrictions-form',
  templateUrl: './testing-restrictions-form.component.html',
  styleUrls: ['./testing-restrictions-form.component.scss'],
  providers: [TestingRestrictionsService]
})
export class TestingRestrictionsFormComponent implements OnInit {

  @Input() form: string;
  @ViewChild('autoInput') input;

  constructor(
    private testingRestrictionService: TestingRestrictionsService,
    private wizardService: WizardService,
    private router: Router, 
  ) { }

  filteredDomains$: Observable<any[]>;
  searchPhrase: string;
  selectedDomain: string;
  selectedDomainId;
  filter = false;
  revealed = [];
  deleteConfirm = [];

  ngOnInit(): void {
    this.filteredDomains$ = this.testingRestrictionService.domains.Items$().pipe(
      exhaustMap((res) => of(res))
    )
  }

  onChange() {
    const wordSearch = this.input.nativeElement.value;
    this.filteredDomains$ = of([{ id: "Zero", name: "Loading.."}]);

    setTimeout(() => {
      if (wordSearch == this.input.nativeElement.value) {
          this.testingRestrictionService.domains.loadPagedItems(this.input.nativeElement.value ? {
            domain: {
              like: this.input.nativeElement.value
            }
          } : null);
          this.filteredDomains$ = this.testingRestrictionService.domains.Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 600);    
  }

  onSelectionChange($event) {
    if($event){
      this.filteredDomains$.subscribe((res: any) => {
        res.find(item => {
          if (item.domain === this.input.nativeElement.value) {
            this.selectedDomainId = item.id;
            this.selectedDomain = item.name;
            this.filteredDomains$ = of([item]);
            this.router.navigateByUrl(`/admin/tools/testing-restrictions/${item.id}`)
          }
        })
      })
    }
  }

  selectDomain(domain){
    this.router.navigateByUrl('/admin/tools/testing-restrictions/'+domain.id);
  }

  cancel(){
    this.wizardService.setState(0);
  }

  toggleView(index: number) { 
    this.revealed[index] = !this.revealed[index]; 
  }

  delete(id: string, confirmed: boolean, index: number){
    if(confirmed) this.testingRestrictionService.deleteTestingDomain(id);
    this.deleteConfirm[index] = !this.deleteConfirm[index];
  }
}
