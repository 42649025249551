<ifs-admin-customers [customer]="customer" [isNew]="isNew" (create)="createCustomer(customer)" (update)="updateCustomer($event)"></ifs-admin-customers>

<ng-template #nextStep let-data let-ref="nextStepDialogRef">
    <nb-card class="last-step">
      <nb-card-header>
        <h5>{{ data.customerName }} <nb-icon (click)="modify('/admin/customers/' + data.routeToModify)" icon="close" class="close"></nb-icon></h5>
      </nb-card-header>
      <nb-card-body class="padded">        
        <p>What would you like to do next?</p>
      </nb-card-body>
      <nb-card-footer class="button-padding">
        <button nbButton (click)="modify('/admin/customers/' + data.routeToModify)" status="primary" outline>Modify</button>
        <button nbButton (click)="reset()" routerLink="/admin/customers" outline status="primary">Create Another</button>
        <button nbButton (click)="home()" routerLink="/" status="primary">Home</button>
      </nb-card-footer>
    </nb-card>
</ng-template>