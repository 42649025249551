import { Component, Input } from '@angular/core';
import { RunTemplateSummary } from '@InfoSlips/models';

@Component({
  selector: 'cms-run-template-card',
  templateUrl: './run-template-card.component.html',
  styleUrls: ['./run-template-card.component.scss']
})
export class RunTemplateCardComponent  {

  @Input() runTemplate: RunTemplateSummary
  revealed = false;

  toggleView() { 
    this.revealed = !this.revealed; 
  }
}
