<div class="general-page-container fit-absolute">
  <div class="general-page-header">
    <form class="p-10">
      <div class="form-block">
        <div class="form-group">
          <label>Find {{ data.title }} - {{data.context}}</label>
          <br/>

          <ifs-customer-lookup #customerDropdown [showLabel]="false" [showClear]="true" (ItemSelected)="onCustomerSelected($event)"></ifs-customer-lookup>
          <br/>

          <nb-form-field>
              <input fullWidth autocomplete="off" #autoInput nbInput type="text" (ngModelChange)="buildFilter($event, 0)" fullWidth name="userName" placeholder="Filter by Name" [(ngModel)]="recipientName" [disabled]="!customerSelected && !runSelected && !runTemplateSelected">
              <button nbSuffix nbButton ghost (click)="clearFilter(0)">
                  <nb-icon icon="close-outline" pack="eva"></nb-icon>
              </button>
          </nb-form-field>
          <br/>

          <nb-form-field>
            <input fullWidth autocomplete="off" #autoInput nbInput type="text" (ngModelChange)="buildFilter($event, 1)" fullWidth name="userName" placeholder="Filter by External ID" [(ngModel)]="recipientExternalId">
            <button nbSuffix nbButton ghost (click)="clearFilter(1)">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
            </button>
          </nb-form-field>
          <br/>

          <nb-form-field>
            <input fullWidth autocomplete="off" #emailInput nbInput type="text" (ngModelChange)="buildFilter($event, 2)" fullWidth name="email" placeholder="Filter by Email" [(ngModel)]="recipientEmail" />
            <button nbSuffix nbButton ghost (click)="clearFilter(2)">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
            </button>
          </nb-form-field>
          <br/>

          <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header>
                Additional Filters
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <nb-form-field>
                  <input fullWidth autocomplete="off" #autoInput nbInput type="text" (ngModelChange)="buildFilter($event, 3)" fullWidth name="mobile" placeholder="Filter by Mobile" [(ngModel)]="recipientMobile" [disabled]="!customerSelected && !runSelected && !runTemplateSelected"/>
                  <button nbSuffix nbButton ghost (click)="clearFilter(3)">
                      <nb-icon icon="close-outline" pack="eva"></nb-icon>
                  </button>
                </nb-form-field>
                <br/>
                <ifs-run-lookup #runDropdown [showLabel]="false" [showClear]="true" (ItemSelected)="onLookupSelected($event, 'run')"></ifs-run-lookup><br/>
                <nb-form-field>
                  <input fullWidth autocomplete="off" #autoInput nbInput  
                    (ngModelChange)="buildFilter($event, 5)" fullWidth name="createdFrom" 
                    placeholder="Created on or after" [(ngModel)]="createdFrom" 
                    [disabled]="!customerSelected && !runSelected && !runTemplateSelected"
                    [nbDatepicker]="createdFromPicker"/>
                  <button nbSuffix nbButton ghost (click)="clearFilter(4)">
                      <nb-icon icon="close-outline" pack="eva"></nb-icon>
                  </button>
                  <nb-datepicker #createdFromPicker></nb-datepicker>
                </nb-form-field>
                <nb-form-field>
                  <input fullWidth autocomplete="off" #autoInput nbInput  
                    (ngModelChange)="buildFilter($event, 5)" fullWidth name="createdTo" 
                    placeholder="Created on or before" [(ngModel)]="createdTo" 
                    [disabled]="!customerSelected && !runSelected && !runTemplateSelected"
                    [nbDatepicker]="createdToPicker"/>
                  <button nbSuffix nbButton ghost (click)="clearFilter(5)">
                      <nb-icon icon="close-outline" pack="eva"></nb-icon>
                  </button>
                  <nb-datepicker #createdToPicker></nb-datepicker>
                </nb-form-field>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>

          <button *ngIf="hasKeys(filterObject)" class="col-md-4" size="small" nbButton status="primary" (click)="filterRecipients()">
            Search
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="general-page-body">
    <div class="general-page-body">
      <div class="scroll p-10">
        <ng-container *ngFor="let item of (isLoading$ | async) ? (loadingRecipients$ | async) : (recipients$) | async">
          <ifs-wizard-generic-card [cardInfo]="item" [context]="data" [allowDelete]="false"></ifs-wizard-generic-card>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="general-page-footer">
    <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
      Cancel
    </button>
  </div>
</div>