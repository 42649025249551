import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ifs-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() header: string;
  @Input() body: string;
  @Input() confirmText: string;
  @Input() cancelText: string;

  dialogRef;

  constructor(private dialog: NbDialogRef<any>) { }

  ngOnInit(): void {
    
  }

  confirmClick() {
    this.dialog.close(true);
  }

  cancelClick() {
    this.dialog.close(false);
  }

}
