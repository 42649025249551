export interface ICacheItem<T> {
    value:T;
    expiryDate?:Date
}

export enum IIABCacheKey {
    LOGGED_IN_STORE_KEY=0,
    AggregatedStats=1,
    CustomerRunStatistics=2,
    ReturnUrl=3,
    Recents=4,
    Stars=5,

    DefaultCustomer=6,
    DefaultTemplate=7,
    DefaultRunTemplate=8,

    UIMenu=9,
    QuickActions=10,
    RunsGrid=11,
    // Should always be last key
    TestKey=99999
}