import { Component, OnInit, Output, EventEmitter, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@InfoSlips/iiab-auth';
import { RunStateService, StateManagerService } from '@InfoSlips/iiab-state';
import { StateMenuContext } from '@InfoSlips/models';
import { NbThemeService } from '@nebular/theme';
import { WizardService } from 'libs/wizard/src/lib/services/wizard.service';
import { MonacoEditorConfig } from '../../monacoConfig';
import { MenuService } from 'libs/wizard/src/lib/services/menu.service';

@Component({
  selector: 'ifs-activity-bar',
  templateUrl: './activity-bar.component.html',
  styleUrls: ['./activity-bar.component.scss']
})
export class ActivityBarComponent implements OnInit, OnChanges{
  @Input()
  expand = false;
  @Output()
  status: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  profileOptions = [
    {title: 'Logout', icon: { icon: 'power', pack: 'oakmoon' }}
  ];
  context = "";
  lightTheme = true;

  mainMenuOptions;
  isMenuOpen = false;
  menuContext: StateMenuContext;

  constructor(
    private authService: AuthService,
    private themeService: NbThemeService,
    private monacoConfig: MonacoEditorConfig,
    private router: Router,
    public wizardSerivce: WizardService,
    public state: StateManagerService,
    public runState: RunStateService,    
    public menuService: MenuService,
  ) { }


  ngOnInit(){ }

  ngOnChanges(){
    if(!this.expand){
      this.closeWizard();
    }
  }

  launchWizard(context: string){
    this.wizardSerivce.toggleWizard(true);
    this.wizardSerivce.wizardContextSubject.next(context);
  }

  closeWizard(){
    this.status.emit(false);
    this.wizardSerivce.toggleWizard(false);
    this.wizardSerivce.wizardContextSubject.next("")
  }

  logout() {
    window.location.replace('/#/auth/logout');
  }

  changeTheme(){
    this.lightTheme ? this.lightTheme = !this.lightTheme : this.lightTheme = true;
    this.themeService.changeTheme(this.lightTheme ? 'light' : 'dark');
    this.monacoConfig.editorOptions.theme = this.lightTheme ? 'vs' : 'vs-dark'
  }

  launchCMS(){
    this.router.navigateByUrl('/cms');
  }

  openMobileMenu(context: StateMenuContext){
    this.menuContext = context;
    this.isMenuOpen = !this.isMenuOpen;
  }

  setRunTab(index: number){
    this.runState.setTab(index);
    this.isMenuOpen = !this.isMenuOpen;
  }

  routeHome() {    
    const originalReRouteStrat = this.router.routeReuseStrategy;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/']);
    this.router.onSameUrlNavigation = 'ignore';
    this.router.routeReuseStrategy = originalReRouteStrat;
  }
}
