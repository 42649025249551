import { Component, Input, OnInit } from '@angular/core';
import { MenuItemModel } from '../../services/menu-item';

interface CardInfo {
  name:          string;
  id:            string;
  externalId?: string;
  customer:      {
      name:       string;
      id:         string;
  };
  template:      {
      name:       string;
  };
  created:       string;
  createdBy:     string;
  lastUpdated:   string;
  lastUpdatedBy: string;
  files:         {
    fileName: string;
  };
  userName: string;
  email: string;
  run?: {
    name: string;
    runDate: string;
  }
}

@Component({
  selector: 'ifs-wizard-generic-card',
  templateUrl: './generic-wizard-card.component.html',
  styleUrls: ['./generic-wizard-card.component.scss']
})
export class GenericWizardCardComponent implements OnInit {

  @Input() cardInfo: CardInfo
  @Input() context: MenuItemModel;
  @Input() allowDelete = true;

  revealed = false;
  deleteConfirm = false;
  cardId: string;

  ngOnInit(): void{
    this.cardId = this.context.context.toLowerCase().includes('recipient') ? this.cardInfo.externalId : this.cardInfo.id
  }

  toggleView() { 
    this.revealed = !this.revealed; 
  }

  delete(id: string, confirmed: boolean){
    if(confirmed){
      this.context.service.delete(this.context.context, id);
    }
    this.deleteConfirm = !this.deleteConfirm;
  }
}
