import { CustomerReferenceModel } from "./CustomerModels";
import { RunReferenceModel } from "./Run";

export interface ExportTemplateModel {
    Type: number;
    Host: string;
    Port: string;
    UserName: string;
    Password: string;
    Run: RunReferenceModel;
    Customer: CustomerReferenceModel;
    ControlFileLineRazorTemplate: string;
    IsEnabled: boolean;
}