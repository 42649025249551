import { Component} from '@angular/core';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'reports-system-users',
  templateUrl: './system-users.component.html',
  styleUrls: ['./system-users.component.scss']
})
export class SystemUsersComponent {

  constructor(
    private reportsService: ReportsService
  ) {}

  reportDetails$ = this.reportsService.reportDetails$;
  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;

  pageReport(context: string){
    this.reportsService.pageReport(context);
  }
}
