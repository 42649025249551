import { Injectable } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { ICacheItem, IIABCacheKey, LoggedInModel, LoginModel } from '@InfoSlips/models';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/*
DOCS LINK: https://docs.infoslipscloud.com/e/en/internal/oakmoon-local-storage
*/

export class IiabLocalStorageService {

  loggedInTokenSubject = new BehaviorSubject<LoggedInModel|null>(null);
  loggedInToken$ =  this.loggedInTokenSubject.asObservable();

  constructor(private environment: EnvironmentService) {
    this.loggedInTokenSubject.next(this.getWithExpiry<LoggedInModel>(IIABCacheKey.LOGGED_IN_STORE_KEY));
   }

  set<T>(key:IIABCacheKey, value:T, perUser:boolean=false):void{
    if(key===IIABCacheKey.LOGGED_IN_STORE_KEY)
      this.loggedInTokenSubject.next(value as unknown as LoggedInModel);
    localStorage.setItem(this._getCacheKey(key, perUser), JSON.stringify(this._getCacheItem<T>(value,null)))    
  }

  setWithExpiryInSeconds<T>(key:IIABCacheKey, value:T, secondsFromNow: number, perUser:boolean=false):void {
    const expiryDate = new Date();
    expiryDate.setSeconds(secondsFromNow);
    this.setWithExpiry<T>(key, value, expiryDate, perUser);  
  }

  setWithExpiry<T>(key:IIABCacheKey, value:T, expiryDate: Date, perUser:boolean=false):void {
    if(key===IIABCacheKey.LOGGED_IN_STORE_KEY)
      this.loggedInTokenSubject.next(value as unknown as LoggedInModel);
    localStorage.setItem(this._getCacheKey(key, perUser), JSON.stringify(this._getCacheItem<T>(value,expiryDate)))
  };

  get<T>(key:IIABCacheKey, perUser:boolean=false):T{
    return this._getItemFromCache<T>(key, perUser)?.value;
  }

  remove(key:IIABCacheKey, perUser:boolean=false){
    const cacheKey = this._getCacheKey(key, perUser);
    localStorage.removeItem(cacheKey);
  }

  getWithExpiry<T>(key:IIABCacheKey, perUser:boolean=false):T {
    const cacheItem = this._getItemFromCache<T>(key, perUser);
    
    if(!cacheItem)
      return null;

    if(!cacheItem.expiryDate)
      return cacheItem.value; // No Expiry Date --> Return the item found

    const now = new Date();
    const expiryDate = new Date(cacheItem.expiryDate);
    // compare the expiry time of the item with the current time

    if (now > expiryDate) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(IIABCacheKey[key])
      return null
    }
    return cacheItem.value
  };

  getLoggedInTokenModel():LoggedInModel{
    return this.loggedInTokenSubject.getValue();
  }

  getPerUserCacheKey(key: IIABCacheKey, userName:string):string{
    return `${IIABCacheKey[key]}_${userName.toLowerCase()}`;
  }

  //#region Private
  private _getCacheItem<T>(value:T, expiryDate?: Date, perUser:boolean=false):ICacheItem<T>{
    return {
      value: value,
      expiryDate: expiryDate
    }
  }

  private _getItemFromCache<T>(key:IIABCacheKey, perUser:boolean=false):ICacheItem<T>{
    const itemStr = localStorage.getItem(this._getCacheKey(key, perUser));

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item:ICacheItem<T> = JSON.parse(itemStr);

    return item;
  }

  private _getCacheKey(key:IIABCacheKey, perUser:boolean=false):string{

    const envName = this.environment.name;

    if(perUser){
      const loggedInUser = this.getLoggedInTokenModel()?.userName;
      if(!loggedInUser){
        return 'No logged in user found';
      }
      const oldKey=this.getPerUserCacheKey(key, loggedInUser);
      //TODO: Remove in Dec 2022 - Previous keys will be removed by then
      localStorage.removeItem(oldKey);
      return `${envName}-${oldKey}`;
    }

    const oldKey=IIABCacheKey[key];
    //TODO: Remove in Dec 2022 - Previous keys will be removed by then
    localStorage.removeItem(oldKey);
    return `${envName}-${oldKey}`;
  }
  //#endregion
}
