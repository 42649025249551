import { Injectable } from '@angular/core';
import { PackageModel, ReProcessStagesModel, RunModel, RunRecipientModel, RunRecipientSummary, TrialOptionsModel, TrialRecipients } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiBaseService } from './base/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class RunActionsService {

  constructor(
    private apiBaseService:ApiBaseService,
    private toastr: ToastrService,
  ) { }

  deleteRun(runId: string){
    return this.apiBaseService.executeDelete<any>(`run/${runId}`,  "Delete Run");
  }

  cancelRun(runId: string){
    return this.apiBaseService.executePut<any>(`run/${runId}/StopRun`, null,  "Cancel Run").subscribe(
      res => {
        res ? this.toastr.success("Run Cancelled!") : this.toastr.error("Cancel Failed");
      }
    );
  }

  updateRunTemplate(body: RunModel){
    this.apiBaseService.executePut<RunModel>(`run`, body, "Update Run Template").subscribe((results: RunModel) => {
      results ? this.toastr.success('Template updated succesfully!') : this.toastr.error('Template update failed!');
    });
  }

  async resetRun(runId:string){
    return await this.apiBaseService.executePut<any>(`run/${runId}/ResetRun`, {
      ShouldClearNewRecipients: true,
      ShouldClearOutputChannelLogs: true,
      ShouldClearRunRecipients: true
  }, "Reset Run").pipe(
    map((res: boolean) => {
      res ? 
        this.toastr.success("Reset run succeeded") : 
        this.toastr.error("Reset Failed!");
      return res;
  }))
  .toPromise();
  }

  async resetOpenRun(runId:string){
    return await this.apiBaseService.executePost<any>(`run/${runId}/resetOpenRun`,null , "Reset Open Run").pipe(
    map((res: boolean) => {
      res ? 
        this.toastr.success("Reset Open Run Succeeded") : 
        this.toastr.error("Reset Failed!");
      return res;
  }))
  .toPromise();
  }

  uploadFile(runId: string, file: File, context: string, fileName?: string){
    // if (file.length === 0) {
    //   return this.toastr.error("Please select files to upload.");
    // }
    const fileToUpload = file;
    const formData = new FormData();
    
    formData.append('file', fileToUpload, fileToUpload.name); 

    if(context === "attachment"){
      return this.apiBaseService.executePostProgress<any>(`run/${runId}/UploadAttachment`, formData, "Upload Attachment");
    }
    else if(context === "recipientattachment"){
      return this.apiBaseService.executePostProgress<any>(`run/${runId}/UploadStaticRunRecipientAttachment/`, formData, "Upload Run Recipient Attachment");
    }
    else {
      return this.apiBaseService.executePostProgress<any>(`run/${runId}/UploadFiles/${fileName}`, formData, "Upload Data File");
    }
  }
  
  removeFile(runId:string, fileName: string, context:string){
    if(context === "attachment"){
      return this.apiBaseService.executePost<any>(`run/${runId}/RemoveAttachment/${fileName}`, null, "Remove Attachment");
    }
    else if(context === "recipientattachment"){
      return this.apiBaseService.executePost<any>(`run/${runId}/RemoveRunRecipientSpecificStaticAttachments`, null, "Remove Run Recipient Attachment");
    }
    else {
      return this.apiBaseService.executePost<any>(`run/${runId}/RemoveFile/${fileName}`, null, "Remove Data File");
    }
  }

  processData(run: RunModel){
    const subject = new Subject<boolean>();

    run.TrialOptions.IsAutoTrialEnabled = false;

    if (run.TrialOptions.RecipientSelection == 4 && run.TrialOptions.RecipientList.length == 0)
      run.TrialOptions.RecipientSelection = 0;

    this.apiBaseService.executePut<any>(`Run`, run, "Put Run Data").subscribe((results) => {
      if(results){
        this.apiBaseService.executePut<boolean>(`run/${run.Id}/startRun/true/0`, null, "Start Processing").subscribe(results => {
          results ? this.toastr.success('Run added to processing queue!') : this.toastr.error('Run failed to be added to process!');
          subject.next(results);
        });
      }
      else {
        this.toastr.error('Run processing failed, please try again!')
      }

    });

    return subject.asObservable();
  }

  downloadRunFile(runId: string, fileName: string){
    this.apiBaseService.executeGetAny<any>(`run/${runId}/RunFile/${fileName}`, "Download Run File", {responseType: 'blob'}).subscribe((res:any)=>{
      res ? this.toastr.success('Download started!') : this.toastr.error('Download failed!');
      const file = new Blob([res]);
      const a = document.createElement("a"), url = URL.createObjectURL(file);
          a.href = url;
          a.download = `${fileName}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);  
          }, 0); 
    })}
 
  sendTrial(run:RunModel, isTrial: boolean, TrialOptions: TrialOptionsModel, OverrideRunTemplateBranch: string){
    if(!run){
      this.toastr.error('No run currently selected!');
      return;
    }

    run.TrialOptions = TrialOptions;
    run.OverrideRunTemplateBranch = OverrideRunTemplateBranch;
    // TODO: Selected run to become this value here instead of in websocket service.
    this.startRun(run, isTrial, "Trial", "Trial has been sent!");
  }
  
  startRun(run:RunModel, isTrial: boolean, context: string, message: string){
    this.apiBaseService.executePut<any>(`Run`, run, "Put Run Data").subscribe((results) => {
      context === "Trial" ? this.toastr.success(`Trial emails added: ${results.TrialOptions.SendToUser}`) : undefined;
      this.toastr.success(`Saved ${context} data!`);

      this.apiBaseService.executePut<boolean>(`run/${run.Id}/startRun/${isTrial}/0`, null, context).subscribe(result => {
        result ? this.toastr.success(message) : this.toastr.error(`${context} has failed!`);
      });
    });
  }

  sendLive(run:RunModel, scheduleDate: Date){
    run.ScheduleDateTime = scheduleDate;
    run.IsTrial = false;
    this.startRun(run, false, "Live", "Live run has been scheduled!");
  }

  startScheduledRun(runId: string){
    this.apiBaseService.executePost<RunModel>(`run/${runId}/startScheduledRun`, null, 'Start Scheduled Run.').subscribe((res: RunModel) => {
      res ? this.toastr.success(`The selected Run will go live shortly.`, 'Live Run Started') : this.toastr.error(`We were unable to start the run as requested.`, 'Live Run Failed');
    })
  }

  cancelScheduledRun(runId: string){
    this.apiBaseService.executePost<RunModel>(`run/${runId}/cancelScheduledRun`, null, 'Cancel Scheduled Run.').subscribe((res: RunModel) => {
      res ? this.toastr.success(`The scheduled Run has been cancelled successfully.`, 'Run Cancelled') : this.toastr.error(`We were unable to cancel the run as requested.`, 'Run Cancellation Failed');
    })
  }

  expireRun(runId: string, expirationMessage: string) {
    this.apiBaseService.executePut<boolean>(`run/${runId}/ExpireRun/${expirationMessage}`, null, "Expire Selected Run").subscribe(result => {
      result ? this.toastr.success("Selected run expired successfully!") : this.toastr.error(`Failed to expire selected run`);
    })
  }

  updateScheduledRun(runId: string, scheduledDateTime: string){
    this.apiBaseService.executePost<RunModel>(`run/${runId}/updateScheduledRun`, {
      'ScheduledDateTime': scheduledDateTime
    }, 'Update Scheduled Run.').subscribe((res: RunModel) => {
      res ? this.toastr.success(`Run has been updated to go live on ${res.ScheduleDateTime}.`, 'Rescheduled') : undefined;
    })
  }

  downloadExportFiles(runId: string){
    this.apiBaseService.executeGetAny<any>(`run/DownloadExportFiles/${runId}`, "Download Export Files", {responseType: 'blob'}).subscribe((results: any) => {
      results ? this.toastr.success('Export download started!') : this.toastr.error('Export download failed!');
      
      const file = new Blob([results], {type: "zip"});
      const a = document.createElement("a"), url = URL.createObjectURL(file);
          a.href = url;
          a.download = `${runId}.zip`;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);  
          }, 0); 
    });
  }

  getTrialPreview(runRecipientId: string, sequence: number) {
    return this.apiBaseService.executeGet<PackageModel>(`viewer/trialpreview/${runRecipientId}/${sequence}`, "Get InfoSlip trial preview");
  }
  refreshTrialRecipients(runId:string, trialOptions: TrialOptionsModel){
    const subject = new Subject<RunRecipientSummary[]>();

    this.apiBaseService.executePostProgress<TrialRecipients>(`run/${runId}/RefreshTrialRecipients/`, trialOptions, "Get Trial Recipients")
    .subscribe((res: any) => {

      if(res.body != undefined){
        if(res.body.length != 0)
          this.toastr.success("Recipients Refreshed.");

        const trialRecipientSummaries: RunRecipientSummary[] = [];

        res.body.forEach((recipient:RunRecipientModel) => {
          // TODO: Remove. This is a temproary solution to GraphQL naming conventions.
          const keys = Object.entries(recipient);
          const capsKeys = keys.map((key) => [key[0][0].toLowerCase() + key[0].slice(1), key[1]]);
          // @ts-ignore
          trialRecipientSummaries.push(Object.fromEntries(capsKeys));
        });

        subject.next(trialRecipientSummaries)
      }
    });
    return subject.asObservable();
  }
  reCreateControlFile(runId:string){
    this.apiBaseService.executePost<any>(`run/RecreateControlFile/${runId}`, null, 'Recreate Control File').subscribe((res:string)=>{
      res ? this.toastr.success("Control file generation started") : this.toastr.error("Failed to restart control file generation:\n"+res);
    });
  }
  downloadControlFile(runId:string){

    this.apiBaseService.executeGetAny<any>(`run/DownloadControlFile/${runId}`, "Download Control File", {responseType: 'blob'}).subscribe((res:any)=>{
      res ? this.toastr.success('Download started!') : this.toastr.error('Download failed!');
      const file = new Blob([res]);
      const a = document.createElement("a"), url = URL.createObjectURL(file);
          a.href = url;
          a.download = `${runId}.json`;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);  
          }, 0); 
    });
  }
  resendRunConfirmationEmail(runId:string){
    this.apiBaseService.executePost<any>(`run/runResult/${runId}`, null, 'Recreate Control File').subscribe((res:string)=>{
      res ? this.toastr.success("Run result email resent") : this.toastr.error("Failed to resend the run result email:\n"+res);
    });
  }
  reProcessStage(model:ReProcessStagesModel, stageName:string){
    this.apiBaseService.executePost<any>('run/ReprocessStages', model, "Reprocess Run Stage").subscribe(res=>{
      res ? this.toastr.success(`Re-processing ${stageName}`) : this.toastr.error(`Failed to re-process ${stageName}:\n`+res);

    });
  }
}
