/* eslint-disable max-len */
import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard-processor-form',
  templateUrl: './processor-form.component.html',
  styleUrls: ['./processor-form.component.scss']
})
export class ProcessorFormComponent implements OnInit {
  @ViewChild('autoInput') input;
  @Input() data: any;

  constructor(
    private wizardService: WizardService,
  ) { }

  // TODO: @nicon add model
  processors$: Observable<any[]>;

  context: string;
  filterObject = {};

  selectedProcessor: string;
  selectedProcessorId: string;

  ngOnInit(): void {  
    this.context = `${this.data.context.toLowerCase()}Processors`;

    this.data.service[this.context].loadPagedItems(this.loadSearchHistory(), null, null, null, 300);
    
    this.processors$ = this.data.service[this.context].Items$().pipe(
      exhaustMap((res) => of(res))
    );
  }

  onChange() {
    const wordSearch = this.input.nativeElement.value;

    this.filterObject = wordSearch.length === 24 ? { id: { eq: this.input.nativeElement.value }} : { name: { like:  this.input.nativeElement.value }}  
  }

  searchProcessors() {    
    this.processors$ = of([{ id: 'Loading..', name: 'Loading..'}]);
    
    this.data.service[this.context].loadPagedItems(this.input.nativeElement.value ? this.filterObject : null);
    this.processors$ = this.data.service[this.context].Items$().pipe(exhaustMap((res) => of(res)));
    this.wizardService.addSearchHistory(this.context, this.filterObject)
  }
  
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.searchProcessors();
  }

  onSelectionChange($event) {
      this.processors$.subscribe((res: any) => {
         res.find(item => {
           if(item.name === this.input.nativeElement.value){
             this.selectedProcessorId = item.id;
             this.selectedProcessor = item.name;
             this.processors$ = of([item]);
          }
        })
      })
  }

  cancel(){  
    this.wizardService.setState(0);
    this.wizardService.removeSearchHistory(this.context)
  }

  clearFilter(){
    this.data.service[`${this.data.context.toLowerCase()}Processors`].loadPagedItems();
    this.input.nativeElement.value = '';
  }
  
  hasKeys(obj: any): boolean {
    return obj != null && Object.keys(obj).length > 0;
  }

  private loadSearchHistory() {
    if (this.wizardService.wizardSearchHistory[this.context]) {
      const searchHistory = this.wizardService.wizardSearchHistory[this.context];
    
      if (searchHistory.name != null) this.selectedProcessor = searchHistory.name.like;
      if (searchHistory.id != null) this.selectedProcessorId = searchHistory.id.eq;
      this.filterObject = searchHistory;
      return searchHistory;
    } else return null;
  }
}
