import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NebularModule } from '@InfoSlips/nebular';
import { CommonModule } from '@angular/common';
import { CustomerLookupComponent } from './customer-lookup/customer-lookup.component';
import { RunTemplateLookupComponent } from './run-template-lookup/run-template-lookup.component';
import { RunLookupComponent } from './run-lookup/run-lookup.component';
import { CustomerGroupLookupComponent } from './customer-group-lookup/customer-group-lookup.component';

@NgModule({
  imports: [
    CommonModule, 
    NebularModule,
    FormsModule],
  declarations: [
    CustomerLookupComponent,
    CustomerGroupLookupComponent,
    RunTemplateLookupComponent,
    RunLookupComponent
  ],
  exports: [
    CustomerLookupComponent,
    CustomerGroupLookupComponent,
    RunTemplateLookupComponent,
    RunLookupComponent
  ]
})
export class ControlsModule {}
