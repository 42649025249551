import { ApiBaseService } from './api-base.service';
import { PageGraphQLData, IiabId } from '@InfoSlips/models';
import { ObservableStoreBase } from './observable-store-base';


export class ObservableWrapper<T extends IiabId> extends ObservableStoreBase<T>{

  constructor(
    private apiBaseService: ApiBaseService,
    public pagedQuery: string,
    private fetchGraphQLDataMethod: (data: any) => PageGraphQLData<T>,
    private autoload: boolean) { 
      super();
      super.onInit(apiBaseService, pagedQuery, fetchGraphQLDataMethod, autoload);      
    }
}
