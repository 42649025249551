
<div class="cms-entity-details">
    <div style="display: flex;  justify-content: space-between">
        <h5>{{ deleteConfirm ? "Confirm Deletion?" : entity?.Display }}</h5>

        <ng-container *ngIf="!deleteConfirm">
            <nb-actions size="small">
                <nb-action (click)="delete(false)" (mouseenter)="dangerIcon = 'trash-2'" (mouseleave)="dangerIcon = 'trash-2-outline'" pack="eva" [icon]="dangerIcon" class="danger"></nb-action>
                <nb-action (mouseenter)="editIcon = 'edit-2'" (mouseleave)="editIcon = 'edit-2-outline'" pack="eva" [icon]="editIcon" class="primary" [routerLink]="'edit/'+ entity.Id"></nb-action>
                <nb-action (click)="closeDetails()" (mouseenter)="closeIcon = 'close-square'" (mouseleave)="closeIcon = 'close-square-outline'" pack="eva" [icon]="closeIcon" class="info"></nb-action>
              </nb-actions>
        </ng-container>
        
        <ng-container *ngIf="deleteConfirm">
            <nb-actions size="small">
                <nb-action (click)="delete(true)" (mouseenter)="dangerIcon = 'trash-2'" (mouseleave)="dangerIcon = 'trash-2-outline'" pack="eva" [icon]="dangerIcon" class="danger"></nb-action>
                <nb-action (click)="delete(false)" (mouseenter)="closeIcon = 'close-square'" (mouseleave)="closeIcon = 'close-square-outline'" pack="eva" [icon]="closeIcon" class="info"></nb-action>
              </nb-actions>
        </ng-container>

    </div>
    
    <p>{{ deleteConfirm ? entity?.Display + " will be deleted." : entity?.Notes }}</p>

    <nb-tag-list *ngIf="entity?.Tags.length !== 0">
        <nb-tag size="small" appearance="filled" *ngFor="let tag of entity.Tags" [text]="tag" status="primary"></nb-tag>
    </nb-tag-list>

    <hr/>

    <nb-accordion>
        <nb-accordion-item [expanded]="true">
         <nb-accordion-item-header>General Info</nb-accordion-item-header>
         <nb-accordion-item-body>
            <nb-list>
                <nb-list-item>ID: <small>{{ entity?.Id }}</small></nb-list-item>
            </nb-list>
            <nb-list>
                <nb-list-item>Public ID: <small>{{ entity?.PublicId }}</small></nb-list-item>
            </nb-list>
            <nb-list>
                <nb-list-item>Created: <small>{{ entity?.Created.split("T")[0] }} ({{ entity?.Created.split("T")[1].split(".")[0] }}) by {{ entity?.CreatedBy }}</small></nb-list-item>
            </nb-list>
            <nb-list *ngIf="entity.LastUpdatedBy !== null">
                <nb-list-item>Modified: <small>{{ entity?.LastUpdated.split("T")[0] }} ({{ entity?.LastUpdated.split("T")[1].split(".")[0] }}) by {{ entity?.LastUpdatedBy }}</small></nb-list-item>
            </nb-list>
            <nb-list>
                <nb-list-item>Type / Entity: <small>{{ getCmsContentType(entity?.CmsContentType) }} / {{ getCmsCEntityType(entity?.CmsEntityType) }}</small></nb-list-item>
            </nb-list>
            <nb-list>
                <nb-list-item>Version: <small>{{ entity?.Version !== -1 ? entity?.Version : 'Draft' }}</small></nb-list-item>
            </nb-list>
            <nb-list>
                <nb-list-item>Is Draft: <nb-toggle [checked]="entity?.IsDraft" disabled></nb-toggle></nb-list-item>
            </nb-list>
         </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item [expanded]="false">
            <nb-accordion-item-header>Revert Version</nb-accordion-item-header>
            <nb-accordion-item-body class="revert-container">
                <nb-select placeholder="Select Version" [selected]="entity.Version" [compareWith]="compareByVersion" (selectedChange)="selectVersion($event)">
                    <nb-option *ngFor="let revision of cmsRevisions$ | async" [value]="revision.version">{{ revision.version }}</nb-option>
                </nb-select>
                <button nbButton (click)="revertCmsEntity()">
                    <nb-icon pack="eva" icon="arrow-back-outline" class="danger"></nb-icon> Revert
                </button>
            </nb-accordion-item-body>
           </nb-accordion-item>
       </nb-accordion>

    <cms-preview-entity
        [publicId]="entity.PublicId"
        [version]="entity.Version"
        [cmsEntityType]="entity.CmsEntityType"
        [cmsContentType]="entity.CmsContentType"
        [contentType]="entity.ContentType"
        [baseUrl]="baseUrl"
        [cmsEntity]="entity">
    </cms-preview-entity>
</div>