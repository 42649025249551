<nb-reveal-card [showToggleButton]="false" [revealed]="revealed" class="card mb-10 shadow">
    <nb-card-front>
      <nb-card>
        <nb-card-header class="card-header p-10" [routerLink]="context.route + '/' + cardInfo?.id">
            <p class="card-title">
              {{cardInfo?.name}} {{cardInfo?.userName}} <br/>

              <ng-container *ngIf="cardInfo?.template">
                <small>{{cardInfo?.template?.name}}</small> <br/>
              </ng-container>

              <ng-container *ngIf="cardInfo?.files">
                <small>{{cardInfo?.files[0]?.fileName}}</small> <br/>
              </ng-container>

              <ng-container *ngIf="cardInfo?.email">
                <small>{{cardInfo?.email}}</small> <br/>
              </ng-container>

              <ng-container *ngIf="cardInfo?.run">
                <small>{{cardInfo?.run?.name}}<br />({{cardInfo?.run?.runDate | date: 'dd/MM/yyyy'}})</small>
              </ng-container>
            </p>
      
      </nb-card-header>
      <nb-card-body>
        <ng-container *ngTemplateOutlet="templateId"></ng-container>
        <div class="actions">
            <button nbButton (click)="delete(cardInfo?.id, false)" *ngIf="allowDelete">
              <nb-icon [icon]="deleteConfirm ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
            </button>
            <button nbButton (click)="deleteConfirm ? delete(cardInfo?.id, true) : toggleView()" [status]="deleteConfirm ? 'danger' : 'basic'">
              <nb-icon [icon]="deleteConfirm? 'check-circle' : 'info-circle'" pack="oakmoon" status="info"></nb-icon>
            </button>
        </div>
    </nb-card-body>
      </nb-card>
    </nb-card-front>
    <nb-card-back>
      <nb-card>
        <nb-card-header class="card-header p-10" [routerLink]="context.route + '/' + cardInfo?.id">
            <table *ngIf="cardInfo?.created">
                <tr>
                  <td>Created:</td>
                  <td style="font-size: smaller;"> {{cardInfo?.createdBy}} ({{cardInfo?.created?.split('T')[0]}})</td>
                </tr>
                <tr>
                  <td>Last Modified:</td>
                  <td style="font-size: smaller;">{{cardInfo?.lastUpdatedBy}} ({{cardInfo?.lastUpdated?.split('T')[0]}})</td>
                </tr>
                <tr *ngIf="cardInfo?.lastLoginDate">
                  <td>Last Login:</td>
                  <td style="font-size: smaller;">{{cardInfo?.lastLoginDate?.split('T')[0]}}</td>
                </tr>
                <tr *ngIf="cardInfo?.onlineId">
                  <td>OnlineId: <ifs-copy [value]="cardInfo?.onlineId"></ifs-copy></td>
                  <td style="font-size: smaller;">{{cardInfo.onlineId | truncate: [16,'...'] }}</td>
              </tr>
            </table>
      
      </nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="templateId"></ng-container>
          <div class="actions">
              <button nbButton (click)="delete(cardInfo?.id, false)">
                <nb-icon [icon]="deleteConfirm ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
              </button>
              <button nbButton (click)="deleteConfirm ? delete(cardInfo?.id, true) : toggleView()" [status]="deleteConfirm ? 'danger' : 'basic'">
                <nb-icon [icon]="deleteConfirm? 'check-circle' : 'navigate-left'" pack="oakmoon" status="info"></nb-icon>
              </button>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-reveal-card>

  <ng-template #templateId>
    <p class="template-id">
      <small>{{ cardId }}</small>
      <ifs-copy [value]="cardId"></ifs-copy>
    </p>
  </ng-template>