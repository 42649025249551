import { Injectable } from '@angular/core';
import { ApiBaseService, ObservableWrapper } from '@InfoSlips/iiab-api';
import { CmsEntityModel, CmsEntityScope, CmsEntitySummary, CmsEntityType, CmsLinkedToItem } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';
import { _cmsLinkedRunsPagedQuery, _cmsLinkedRunTemplatesPagedQuery, _cmsRevisionsSummaryPagedQuery,  _fetchCmsLinkedRunsData, _fetchCmsLinkedRunTemplateData, _fetchCmsRevisionsData } from '../graphql/cmsQueries';

@Injectable({
  providedIn: 'root'
})
export class SelectedEntityService {

  //#region private
  private revisions: ObservableWrapper<CmsEntitySummary>;
  private linkedRuns: ObservableWrapper<CmsLinkedToItem>;
  private linkedRunTemplates: ObservableWrapper<CmsLinkedToItem>;

  private selectedEntitySubject = new BehaviorSubject<CmsEntityModel>(null);
  private revisionsSubject = new BehaviorSubject<CmsEntitySummary[]>(null);
  private linkedRunsSubject = new BehaviorSubject<CmsLinkedToItem[]>(null);
  private linkedRunTemplatesSubject = new BehaviorSubject<CmsLinkedToItem[]>(null);
  private _entityType: string;
  //#endregion

  selectedEntity$ = this.selectedEntitySubject.asObservable();
  revisions$ = this.revisionsSubject.asObservable();
  linkedRuns$ = this.linkedRunsSubject.asObservable();
  linkedRunTemplates$ = this.linkedRunTemplatesSubject.asObservable();

  constructor(
    private apiBaseService:ApiBaseService
  ) { 
    this.selectedEntity$.subscribe(entity=>{
      if (entity == null)
        return;
        
      this.loadReferences(entity.PublicId);
    });
  }

  get entityType(){
    return this._entityType;
  }

  setEntity(id:string):void{
    setTimeout(() =>
      this.apiBaseService.executeGet<CmsEntityModel>(`cms/${id}`, 'Get Cms Entity').subscribe((data: any) => {
        this.selectedEntitySubject.next(data);
        this._entityType = data.CmsEntityType === 1 ? 'file' : 'content';
      }), 200);    
  }

  buildMenu(entity:CmsEntityModel):void{
    if(entity==null)
      return;

    const isCodeEntity =  this._showCodeWindow(entity);

    entity = Object.assign((entity), {
      entityMenu: [
        {
          name: 'Overview',
          visible:true
        },
        {
          name: 'File',
          visible: entity.CmsEntityType===CmsEntityType.File
        },
        {
          name: 'Code Preview',
          visible: isCodeEntity
        },
        {
          name: 'References',
          visible: true
        },
        {
          name: 'Revisions',
          visible: true
        },
      ]
    });
  }

  getEntityScope(entity:CmsEntityModel){
    switch (entity.Scope) {
      case 0: 
        if (entity.CustomerGroup?.Id != null) return "Global Secure";
        else return "Global Public";
      case 1: return "Secure";
    }
  }

  loadRevisions(publicId:string){
    this.revisions = new ObservableWrapper<CmsEntitySummary>(
      this.apiBaseService,
      _cmsRevisionsSummaryPagedQuery(publicId),
      _fetchCmsRevisionsData,
      true);

      this.revisions.Items$().subscribe(revisions=>{      
        this.revisionsSubject.next(revisions);
      });
  }

  onScrollRevisions(){
    this.revisions.loadNextPage();
  }

  onScrollRunTemplates(){
    this.linkedRunTemplates.loadNextPage();
  }

  onScrollRuns(){
    this.linkedRuns.loadNextPage();
  }

  loadReferences(publicId:string){
    this.linkedRunTemplates = this._createLinkedObservableWrapper(publicId, false);
    this.linkedRuns = this._createLinkedObservableWrapper(publicId, true);

    //TODO: Refactor to a managed class - The code is identical
    this.linkedRuns.Items$().subscribe(linked=>{      
      this.linkedRunsSubject.next(linked);
    });

    this.linkedRunTemplates.Items$().subscribe(linked=>{
      this.linkedRunTemplatesSubject.next(linked);
    });
  }

  //#region Private methods
  private _showCodeWindow(entity: CmsEntityModel):boolean{
    if(entity?.CmsEntityType==null)
      return false;

    if(entity.CmsEntityType===CmsEntityType.Content)
      return true;
    return false; //TODO: Check if the file has code related content
  }
  private _createLinkedObservableWrapper(publicId:string, forRun:boolean, pageSize:number=10):ObservableWrapper<CmsLinkedToItem>{
    const fetchDataFunc = forRun? _fetchCmsLinkedRunsData: _fetchCmsLinkedRunTemplateData;
    const fetchDataQuery = forRun?  _cmsLinkedRunsPagedQuery(publicId, pageSize): _cmsLinkedRunTemplatesPagedQuery(publicId ,pageSize)
    
    return new ObservableWrapper<CmsLinkedToItem>(
      this.apiBaseService,
      fetchDataQuery,
      fetchDataFunc,
      true
    );
  }
  //#endregion
}
