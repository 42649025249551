<div class="row">
    <div class="col-md-3 p-10" *ngIf="filters?.companySelect">
        <ifs-customer-lookup [showLabel]="false" (ItemSelected)="onCustomerSelected($event)"></ifs-customer-lookup>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.customerFilter">
        <ifs-customer-lookup [showLabel]="false" (ItemSelected)="onCustomerFilterSelected($event)"></ifs-customer-lookup>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.runTemplateSelect">
        <ifs-run-template-lookup [showLabel]="false" (ItemSelected)="onRunTemplateSelected($event)"></ifs-run-template-lookup>
     </div>

     <div class="col-md-3 p-10" *ngIf="filters?.runSelect">
        <ifs-run-lookup  [showLabel]="false" [additionalWhere]="reportsService.runFilterObject" (ItemSelected)="onRunSelected($event)"></ifs-run-lookup>
     </div>

    <div class="col-md-3 p-10" *ngIf="filters?.billingPeriodSelect">
        <nb-select placeholder="Billing Period" nbSelect fullWidth [(selected)]="filterModels.billingPeriodSelect" [(ngModel)]="filterModels.billingPeriodSelect" (ngModelChange)="filterChange($event, 'billingPeriod')">
            <nb-option *ngFor="let period of runPeriodList" [value]="period">{{period.Description}}</nb-option>
        </nb-select>
    </div>
    
    <div class="col-md-3 p-10" *ngIf="filters?.recipientNameInput">
        <input fullWidth type="text" nbInput placeholder="Filter by run recipient name" [(ngModel)]="filterModels.recipientNameInput" (ngModelChange)="filterChange($event, 'runRecipientLike')">
    </div>
    
    <div class="col-md-3 p-10" *ngIf="filters?.displayNameInput">
        <input fullWidth type="text" nbInput placeholder="Filter by display name" [(ngModel)]="filterModels.displayNameInput" (ngModelChange)="filterChange($event, 'displayName')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.emailInput">
        <input fullWidth type="text" nbInput placeholder="Filter by email" [(ngModel)]="filterModels.emailInput" (ngModelChange)="filterChange($event, 'email')">
    </div>

    <!-- TODO: No filter for this on GraphQL -->
    <div class="col-md-3 p-10" *ngIf="filters?.filterByEmail">
        <input fullWidth type="text" nbInput placeholder="Filter by email" [(ngModel)]="filterModels.filterByEmail" (ngModelChange)="filterChange($event, 'recipient')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.sendToInput">
        <input fullWidth type="text" nbInput placeholder="Filter by SendTo" [(ngModel)]="filterModels.sendToInput" (ngModelChange)="filterChange($event, 'sendToInput')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.channelSelect">
        <nb-select fullWidth placeholder="Select Channel" [(selected)]="filterModels.channelSelect" [(ngModel)]="filterModels.channelSelect" (ngModelChange)="filterChange($event, 'channel')">
            <nb-option value="">All Channels</nb-option>
            <nb-option value="EMAIL">Email Channel Only</nb-option>
            <nb-option value="SMS">SMS Channel Only</nb-option>
            <nb-option value="EXPORT">Export Channel Only</nb-option>
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.isSentSelect">
        <nb-select fullWidth placeholder="Select Status" [(selected)]="filterModels.isSentSelect" [(ngModel)]="filterModels.isSentSelect" (ngModelChange)="filterChange($event, 'isSent')">
            <nb-option value="">Failed and Success</nb-option>
            <nb-option value="true">Success Only</nb-option>
            <nb-option value="false">Failed Only</nb-option>
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.isDeliveredSelect">
        <nb-select fullWidth placeholder="Select IsDelivered" [(selected)]="filterModels.isDeliveredSelect" [(ngModel)]="filterModels.isDeliveredSelect" (ngModelChange)="filterChange($event, 'isDelivered')">
            <nb-option value="">Delivered and Undelivered</nb-option>
            <nb-option value="true">Delivered only</nb-option>
            <nb-option value="false">Undelivered only</nb-option>
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.isResendSelect">
        <nb-select fullWidth placeholder="Is Resend" [(selected)]="filterModels.isResendSelect" [(ngModel)]="filterModels.isResendSelect" (ngModelChange)="filterChange($event, 'isResend')">
            <nb-option value="">Resend And Forward</nb-option>
            <nb-option value="true">Resend only</nb-option>
            <nb-option value="false">Forward only</nb-option>
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.mailChannelSelect">
        <nb-select fullWidth placeholder="Select Channel" [(selected)]="filterModels.mailChannelSelect" [(ngModel)]="filterModels.mailChannelSelect" (ngModelChange)="filterChange($event, 'mailChannel')">
            <nb-option value="">All Channels</nb-option>
            <nb-option value="0">Hard Bounce only</nb-option>
            <nb-option value="1">Soft Bounce only</nb-option>
            <nb-option value="2">Receipt only</nb-option>
            <nb-option value="2">Out Of Office only</nb-option>
            <nb-option value="2">Complaint only</nb-option>
            <nb-option value="2">Unsubscribe only</nb-option>   
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.isTrialSelect">
        <nb-select fullWidth placeholder="Is live" [(selected)]="filterModels.isTrialSelect" [(ngModel)]="filterModels.isTrialSelect" (ngModelChange)="filterChange($event, 'isTrial')">
            <nb-option value="">Trial and Live</nb-option>
            <nb-option value="1">Trial Only</nb-option>
            <nb-option value="0">Live Only</nb-option>
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.externalIdInput">
        <input fullWidth type="text" nbInput placeholder="External Id" [(ngModel)]="filterModels.externalIdInput" (ngModelChange)="filterChange($event, 'externalId')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.startDateInput">
        <nb-form-field>
            <input id="startDateInput" fullWidth nbInput placeholder="Start Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="filterModels.startDateInput" (ngModelChange)="sendDateFilter($event, 'sendDateStart')">
            <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('startDateInput')">
                <nb-datepicker #dateTimePicker></nb-datepicker>
            </nb-icon>
        </nb-form-field>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.endDateInput">
        <nb-form-field>
            <input id="endDateInput" fullWidth nbInput placeholder="End Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="filterModels.endDateInput" (ngModelChange)="sendDateFilter($event, 'sendDateEnd')">
            <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('endDateInput')">
                <nb-datepicker #dateTimePicker></nb-datepicker>
            </nb-icon>
        </nb-form-field>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.emailTrackingSelect">
        <nb-select fullWidth placeholder="Email Tracking" [(selected)]="filterModels.emailTrackingSelect" [(ngModel)]="filterModels.emailTrackingSelect" (ngModelChange)="filterChange($event, 'actionSelect')">
            <nb-option value="Selected">Url Tracking</nb-option>
            <nb-option value="Open">Email Tracking</nb-option>
        </nb-select>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.customerIdInput">
        <input fullWidth type="text" nbInput placeholder="Customer Id" [(ngModel)]="filterModels.customerIdInput" (ngModelChange)="filterChange($event, 'customerId')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.runIdInput">
        <input fullWidth type="text" nbInput placeholder="Filter by Run Id" [(ngModel)]="filterModels.runIdInput" (ngModelChange)="filterChange($event, 'runId')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.runTemplateIdInput">
        <input fullWidth type="text" nbInput placeholder="Filter by Run Template Id" [(ngModel)]="filterModels.runTemplateIdInput" (ngModelChange)="filterChange($event, 'runTemplateId')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.recipientIdInput">
        <input fullWidth type="text" nbInput placeholder="Recipient Id" [(ngModel)]="filterModels.recipientIdInput" (ngModelChange)="filterChange($event, 'recipientId')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.sequenceInput" >
        <input fullWidth type="number" min="0" nbInput placeholder="Filter by sequence" [(ngModel)]="filterModels.sequenceInput" (ngModelChange)="filterChange($event, 'sequence')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.userAgentInput">
        <input fullWidth type="text" nbInput placeholder="Filter by user agent" [(ngModel)]="filterModels.userAgentInput" (ngModelChange)="filterChange($event, 'userAgent')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.labelInput">
        <input fullWidth type="text" nbInput placeholder="Filter by label" [(ngModel)]="filterModels.labelInput" (ngModelChange)="filterChange($event, 'label')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.tagInput">
        <input fullWidth type="text" nbInput placeholder="Filter by tag" [(ngModel)]="filterModels.tagInput" (ngModelChange)="filterChange($event, 'tags')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.actionInput">
        <input fullWidth type="text" nbInput placeholder="Filter by action" [(ngModel)]="filterModels.actionInput" (ngModelChange)="filterChange($event, 'action')">
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.startCreatedDateInput">
        <nb-form-field>
            <input id="startCreatedDateInput" fullWidth nbInput placeholder="Start Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="filterModels.startDateInput" (ngModelChange)="createdDateFilter($event, 'startCreatedDateInput')">
            <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('startCreatedDateInput')">
                <nb-datepicker #dateTimePicker></nb-datepicker>
            </nb-icon>
        </nb-form-field>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.endCreatedDateInput">
        <nb-form-field>
            <input id="endCreatedDateInput" fullWidth nbInput placeholder="End Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="filterModels.endDateInput" (ngModelChange)="createdDateFilter($event, 'endCreatedDateInput')">
            <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('endCreatedDateInput')">
                <nb-datepicker #dateTimePicker></nb-datepicker>
            </nb-icon>
        </nb-form-field>
    </div>
    <!-- <div class="col-md-3 p-10">
        <button style="margin-top: 0px" nbButton status="primary" outline (click)="filterModels = {}" *ngIf="report.search">Clear Filter</button>
    </div> -->
</div>


<div class="row" *ngIf="false">
    <!-- <div class="col-md-3 p-10" *ngIf="filters?.companySelect">
       <ifs-customer-lookup [showLabel]="false" (ItemSelected)="onCustomerSelected($event)"></ifs-customer-lookup>
    </div>

    <div class="col-md-3 p-10" *ngIf="filters?.runTemplateSelect">
        <ifs-run-template-lookup (ItemSelected)="onRunTemplateSelected($event)"></ifs-run-template-lookup>
     </div>

     <div class="col-md-3 p-10" *ngIf="filters?.runSelect">
        <ifs-run-lookup (ItemSelected)="onRunSelected($event)"></ifs-run-lookup>
     </div>

    <div class="col-md-3 p-10" *ngIf="filters?.billingPeriodSelect">
        <nb-select placeholder="Billing Period" nbSelect fullWidth [(selected)]="filterModels.billingPeriodSelect">
            <nb-option *ngFor="let period of runPeriodList" [value]="period">{{period.Description}}</nb-option>
          </nb-select>
    </div> -->

    <div class="col-md-3 p-10" *ngIf="filters?.coordinatesInput">
        <input fullWidth type="text" nbInput placeholder="Filter by coordinates" [(ngModel)]="filterModels.coordinatesInput">
    </div>
    <div class="col-md-3 p-10" *ngIf="filters?.htmlElementIdInput">
        <input fullWidth type="text" nbInput placeholder="Filter by html element id" [(ngModel)]="filterModels.htmlElementIdInput">
    </div>
    <div class="col-md-3 p-10" *ngIf="filters?.parentHtmlElementIdInput">
        <input fullWidth type="text" nbInput placeholder="Filter by parewnt html element id" [(ngModel)]="filterModels.parentHtmlElementIdInput">
    </div>






    







        <div class="col-md-3 p-10" *ngIf="filters?.isDeliveredSelect">
        <nb-select fullWidth placeholder="Select IsDelivered" [(selected)]="filterModels.isDeliveredSelect">
            <nb-option value="">Both</nb-option>
            <nb-option value="true">Delivered only</nb-option>
            <nb-option value="false">Undelivered only</nb-option>
        </nb-select>
    </div>



    <div class="col-md-3 p-10" *ngIf="filters?.startCreatedDateInput">
        <nb-form-field>
            <input id="startCreatedDateInput" fullWidth nbInput placeholder="Start Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="filterModels.startCreatedDateInput">
            <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('startCreatedDateInput')">
                <nb-datepicker #dateTimePicker></nb-datepicker>
            </nb-icon>
        </nb-form-field>
      </div>
  
      <div class="col-md-3 p-10" *ngIf="filters?.endCreatedDateInput">
        <nb-form-field>
            <input id="endCreatedDateInput" fullWidth nbInput placeholder="End Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="filterModels.endCreatedDateInput">
            <nb-icon class="clickable" icon="calendar" nbSuffix pack="eva" (click)="focus('endCreatedDateInput')">
                <nb-datepicker #dateTimePicker></nb-datepicker>
            </nb-icon>
        </nb-form-field>
      </div>
</div>

<ng-container *ngIf="filters">
    <button nbButton status="primary" (click)="search()" *ngIf="report?.graphql?.query">Search</button>
    <button nbButton status="primary" outline (click)="download()" *ngIf="report?.graphql?.download">Download</button>
    <button nbButton status="primary" outline (click)="apiDownload()" *ngIf="report?.download">Download</button>
</ng-container>