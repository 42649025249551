import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'report-sms-response-log',
  templateUrl: './sms-response-log.component.html',
  styleUrls: ['./sms-response-log.component.scss']
})
export class SmsResponseLogComponent {
  constructor(
    private reportsService: ReportsService
  ) {}

  reportDetails$ = this.reportsService.reportDetails$;
  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;

  pageReport(context: string){
    this.reportsService.pageReport(context);
  }
}
