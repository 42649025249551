import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { RegisterModel, UserRegistrationModel, RegisterActivation, Country } from '@InfoSlips/models';
import { PasswordMatch } from '../../validators/confirm-password.validator';

@Component({
  selector: 'ifs-register-confirm-form',
  templateUrl: './register-confirm-form.component.html',
  styleUrls: ['./register-confirm-form.component.scss']
})
export class RegisterConfirmFormComponent implements OnInit, OnChanges {
  token: string;
  registerConfirmForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  @Output() userRegisterConfirm = new EventEmitter<RegisterModel>();

  @Input()
  activationDetailsForm$: UserRegistrationModel;
  @Input()
  countries: Country[];

  @Input()
  activationDetails$: RegisterActivation;

  passwordRules = [
    {
      label: 'At least one digit',
      value: `[0-9]`,
      matched: false
    },
    {
      label: 'At least one lowercase character',
      value: `[a-z]`,
      matched: false
    },
    {
      label: 'At least one uppercase character',
      value: `[A-Z]`,
      matched: false
    },
    {
      label: 'At least one special character',
      value: `[*!@#$%^&(){}[]:;<>,.?\/~_-=|\\]`,
      matched: false
    },
    {
      label: 'At least',
      value: `10 characters in length`,
      matched: false
    },
  ]

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.registerConfirmForm = this.fb.group(
      {
        displayname: ['', Validators.required],
        username: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        country: ['', Validators.required],
        mobile: ['', Validators.minLength(9)],
        email: ['', [Validators.email, Validators.minLength(5), Validators.required]],
        token: ['', Validators.required]
      },
      {
        validator: PasswordMatch('password', 'confirmPassword')
      }
    );

    this.onChanges();
  }

  onChanges(): void {
    this.registerConfirmForm.get('password').valueChanges.subscribe(val => {
      this.passwordCheck(val);
    });
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPass.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.activationDetailsForm$) {
      const {
        DisplayName,
        UserName,
        Email,
        Mobile,
        Country: userCountry,
        Token
      } = this.activationDetailsForm$;

      const patchForm = {
        displayname: DisplayName,
        username: UserName,
        country: userCountry,
        mobile: Mobile,
        email: Email,
        token: Token
      };

      this.registerConfirmForm.patchValue(patchForm);
    }
  }

  get username() {
    return this.registerConfirmForm.get('username');
  }

  get email() {
    return this.registerConfirmForm.get('email');
  }

  get password() {
    return this.registerConfirmForm.get('password');
  }

  onSubmit() {
    this.submitted = true;
    const form = this.registerConfirmForm;
    if (form.invalid) {
      return;
    }
    this.loading = true;
    this.userRegisterConfirm.emit({ ...form.value });
  }

  passwordCheck(value){
    this.passwordRules.forEach((rule, index) => {
      switch (index){
        case 0: 
          if (/\d/.test(value)) rule.matched = true; else rule.matched = false;
          break;

        case 1: 
          if (/[a-z]/.test(value)) rule.matched = true; else rule.matched = false;
          break;

        case 2: 
          if (/[A-Z]/.test(value)) rule.matched = true; else rule.matched = false;
          break;

        case 3: 
          if (/[!*@#$%^&(){}[\]:;<>,.?\/~_\-=|\\]/.test(value)) rule.matched = true; else rule.matched = false;
          break;

        case 4: 
          if (value.length >= 10) rule.matched = true; else rule.matched = false;
          break;
      }
    })
  }

  passwordValidCheck(): boolean {
    return this.passwordRules.every(rule => rule.matched === true);
  }
}
