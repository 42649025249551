<button *ngIf="innerWidth <= 480" nbButton status="primary" class="toggle-status" (click)="showStatus = !showStatus">
  {{showStatus ? 'Hide' : 'Show'}} Status
</button>


<div class="status-container expanded" *ngIf="selectedRun" [ngClass]="innerWidth <= 480 && showStatus ? 'visible' : 'hidden'">
  <div class="status-card upload" 
    [nbPopover]="uploadMessage"
    nbPopoverTrigger="hover" 
    nbPopoverPlacement="bottom">

    <div class="status-info">
      <nb-icon class="status-icon" pack="oakmoon" [icon]="
        hasAllRequiredFiles ? 'check-circle' : 
        ( !selectedRun.PreProcessor ? 'check-circle' : 'time-clock-circle')">
      </nb-icon>
      <h1>
        {{selectedRun?.RunFiles[0]?.Size ? 'Data Uploaded' : (selectedRun.RunProcessingType == 1 ? 'Uploading Data' :
        'Upload Data')}}
      </h1>
    </div>

    <div class="status-counter" *ngIf="innerWidth <= 480" >
      <span>{{selectedRun?.RunFiles?.length}}</span> / <span>{{selectedRun?.PreProcessor?.ClassData?.RequiredFiles?.length}}</span>
    </div>

    <ng-template #uploadMessage>
      <div class="run-hover-message">
        {{selectedRun?.RunFiles.length}} {{selectedRun?.RunFiles.length > 1 ? 'Files' : 'File'}}
      </div>
    </ng-template>
  </div>

  <ng-container *ngTemplateOutlet="statusBlock; context: { 
    $implicit: {
      enabled: true,
      counter: getCounter('PREPARING', progressCounters),
      statusLabel: {
          'PROCESSING': 'Processing Data',
          'COMPLETE': 'Processed Data',
          'PENDING': 'Process Data'
      },
      message: {
          'COMPLETE': 'Recipients',
          'PENDING': 'Recipients not yet processed.'
      }
    }
   }"></ng-container>

  <ng-container *ngTemplateOutlet="statusBlock; context: { 
    $implicit: {
      enabled: true,
      counter: getCounter('CREATED', progressCounters),
      statusLabel: {
          'PROCESSING': 'Building',
          'COMPLETE': 'Built',
          'PENDING': 'Build'
      },
      message: {
          'COMPLETE': 'Documents',
          'PENDING': 'Documents not yet built.'
      }
    }
   }"></ng-container>

  <ng-container *ngTemplateOutlet="statusBlock; context: { 
    $implicit: {
      enabled: (selectedRun.ScheduleDateTime !== null && selectedRun.Instance.Completed === null),
      counter: getCounter('SCHEDULED', progressCounters),
      statusLabel: {
          'PROCESSING': 'Scheduling',
          'COMPLETE': 'Scheduled',
          'PENDING': 'Schedule'
      },
      message: {
          'COMPLETE': getCounter('SCHEDULED', progressCounters)?.value.counter + 'Time: ' + selectedRun.ScheduleDateTime,
          'PENDING': 'Unscheduled.'
      }
    }
   }"></ng-container>

  <ng-container *ngTemplateOutlet="statusBlock; context: { 
    $implicit: {
      enabled: (!selectedRun.IsCompileOnly && progressCounters),
      counter: getCounter('SEND', progressCounters),
      statusLabel: {
          'PROCESSING': 'Sending',
          'COMPLETE': 'Sent',
          'PENDING': 'Send'
      },
      message: {
          'COMPLETE': 'Documents',
          'PENDING': 'Documents not yet sent.'
      }
    }
   }"></ng-container>

  <ng-container *ngTemplateOutlet="statusBlock; context: { 
    $implicit: {
      enabled: (selectedRun.PostProcessors.length > 0),
      counter: getCounter('POST_PROCESS', progressCounters),
      statusLabel: {
          'PROCESSING': 'Post-Processing Data',
          'COMPLETE': 'Post-Processed Data',
          'PENDING': 'Post-Process Data'
      },
      message: {
          'COMPLETE': 'Documents',
          'PENDING': 'Documents not yet processed.'
      }
    }
  }"></ng-container>

  <ng-container *ngTemplateOutlet="statusBlock; context: { 
    $implicit: {
      enabled: (selectedRun?.ExportTemplate?.IsEnabled),
      counter: getCounter('EXPORT', progressCounters),
      statusLabel: {
          'PROCESSING': 'Exporting',
          'COMPLETE': 'Exported',
          'PENDING': 'Export'
      },
      message: {
          'COMPLETE': 'Documents',
          'PENDING': 'Documents not yet exported.'
      }
    }
  }"></ng-container>
</div>

<ng-template #statusBlock let-data>
  <div class="status-card" *ngIf="data.enabled"
      [class.error-on-stage]="data.counter?.value.status == 'FAILED'"
      [nbPopover]="data.counter?.value.status !== 'FAILED' ? statusMessage : errorMessage" 
      nbPopoverTrigger="hover" 
      nbPopoverPlacement="bottom">

      <div class="status-info">
        <nb-icon class="status-icon" pack="oakmoon" *ngIf="data.counter?.value.status !== 'FAILED'"
          [ngClass]="[data.counter?.value.status === 'PROCESSING' ? 'rotate' : '', data.counter?.value.status === 'SCHEDULED' || progressCounters.length === 0 || data.counter === undefined ? 'not-started' : '']"
          [icon]="data.counter?.value.status === 'PROCESSING' ? 'loading-half' : (data.counter?.value.status === 'COMPLETE' ? 'check-circle' : 'time-clock-circle')">
        </nb-icon>

        <nb-icon pack="oakmoon" icon="copy" *ngIf="data.counter?.value.status === 'FAILED'" status="danger"></nb-icon>

        <h1>
            {{ 
              data.counter?.value.status === 'PROCESSING' ? 
                data.statusLabel.PROCESSING : (
                  data.counter?.value.status === 'COMPLETE' ? 
                    data.statusLabel.COMPLETE : data.statusLabel.PENDING) 
            }}
        </h1>
      </div>

      <div class="status-counter" *ngIf="innerWidth <= 480" >
        <span>{{data.counter?.value?.counter}}</span> / <span>{{data.counter?.value?.expectedCount}}</span>
      </div>

      <ng-template #statusMessage>
          <div class="run-hover-message">
              <span *ngIf="selectedRun.Instance.Started != null || selectedRun.Status != null">{{ data.counter?.value.counter }}</span>
              {{ selectedRun.Instance.Started != null || selectedRun.Status != null ? data.message.COMPLETE : data.message.PENDING }}
          </div>
      </ng-template>

      <ng-template #errorMessage>
          <div class="run-error-message">
             {{data.counter?.value.lastException}}
          </div>
      </ng-template>
  </div>
</ng-template>