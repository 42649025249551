import { Injectable } from '@angular/core';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';

 @Injectable({
     providedIn: "root"
 })
 export class MonacoEditorConfig {
  editorOptions = { theme: 'vs-dark', language: 'razor', readOnly: false};
 }
    
const MonacoConfig: NgxMonacoEditorConfig = {
    onMonacoLoad: () => {
      const monaco = (<any>window).monaco; //TODO: Jdv|NicoN - Added to prevent build error

      monaco.languages.registerCompletionItemProvider('razor', {
        provideCompletionItems: (model, position) => createCompletionItems(model, position)
      });

      monaco.languages.registerCompletionItemProvider('javascript', {
        provideCompletionItems: (model, position) => createCompletionItems(model, position)
      });

      monaco.languages.registerCompletionItemProvider('markdown', {
        provideCompletionItems: (model, position) => createCompletionItems(model, position)
      });

      monaco.languages.registerCompletionItemProvider('plaintext', {
        provideCompletionItems: (model, position) => createCompletionItems(model, position)
      });
    }
};

export let editorOptions = { theme: 'vs-dark', language: 'razor', readOnly: false };


function createDependencyProposals(range) {
    // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
    // here you could do a server side lookup
    const suggestionFields = [];
    const mergeFields = [
        {
          "data": {
            "name": "Customer",
            "path": null
          },
          "children": [
            {
              "data": {
                "name": "Id",
                "path": "@Model.Run.Customer.Id"
              },
              "children": null
            },
            {
              "data": {
                "name": "Name",
                "path": "@Model.Run.Customer.Name"
              },
              "children": null
            }
          ]
        },
        {
          "data": {
            "name": "Recipient",
            "path": null
          },
          "children": [
            {
              "data": {
                "name": "Email",
                "path": "@Model.RunRecipient.Email"
              },
              "children": null
            },
            {
              "data": {
                "name": "Id",
                "path": "@Model.RunRecipient.Id"
              },
              "children": null
            },
            {
              "data": {
                "name": "InfoSlip Url",
                "path": "@Model.InfoSlipUrl"
              },
              "children": null
            },
            {
              "data": {
                "name": "Name",
                "path": "@Model.RunRecipient.Name"
              },
              "children": null
            },
            {
              "data": {
                "name": "Mobile",
                "path": "@Model.RunRecipient.Mobile"
              },
              "children": null
            }
          ]
        },
        {
          "data": {
            "name": "Run",
            "path": null
          },
          "children": [
            {
              "data": {
                "name": "Id",
                "path": "@Model.Run.Id"
              },
              "children": null
            },
            {
              "data": {
                "name": "Name",
                "path": "@Model.Run.Name"
              },
              "children": null
            },
            {
              "data": {
                "name": "Period",
                "path": "@Model.RunRecipient.Run.BilingDateDescription"
              },
              "children": null
            }
          ]
        },
        {
          "data": {
            "name": "Template",
            "path": null
          },
          "children": [
            {
              "data": {
                "name": "Id",
                "path": "@Model.Run.RunTemplate.Id"
              },
              "children": null
            },
            {
              "data": {
                "name": "Name",
                "path": "@Model.Run.RunTemplate.Name"
              },
              "children": null
            }
          ]
        },
        {
          "data": {
            "name": "Other",
            "path": null
          },
          "children": [
            {
              "data": {
                "name": "Date (short)",
                "path": "@System.DateTime.Now.ToString(\"dd/mm/yy\")"
              },
              "children": null
            },
            {
              "data": {
                "name": "Date (long)",
                "path": "@System.DateTime.Now.ToString(\"dddd dd MMMM yyyy\")"
              },
              "children": null
            },
            {
              "data": {
                "name": "Time",
                "path": "@System.DateTime.Now.ToString(\"HH:mm tt\")"
              },
              "children": null
            },
            {
              "data": {
                "name": "Date and Time (short)",
                "path": "@System.DateTime.Now.ToString(\"dd/mm/yy HH:mm tt\")"
              },
              "children": null
            },
            {
              "data": {
                "name": "Date and Time (long)",
                "path": "@System.DateTime.Now.ToString(\"dddd dd MMMM yyyy HH:mm tt\")"
              },
              "children": null
            },
            {
              "data": {
                "name": "Month",
                "path": "@System.DateTime.Now.ToString(\"MMMM\")"
              },
              "children": null
            },
            {
              "data": {
                "name": "Day",
                "path": "@System.DateTime.Now.ToString(\"dddd\")"
              },
              "children": null
            }
          ]
        }
      ]

        mergeFields.forEach((field: any) => {
            const parent = field.data.name;
            field.children.forEach(child => {
              const monaco = (<any>window).monaco; //TODO: Jdv|NicoN - Added to prevent build error

                suggestionFields.push({
                    // Prefered
                    label: `${parent}: ${child.data.name}`,
                    // Not Prefered
                    // label: child.data.path,
                    kind: monaco.languages.CompletionItemKind.Function,
                    documentation: `InfoSlips merge field, parent: ${parent}, path: ${child.data.path}`,
                    insertText: child.data.path.split('@')[1],
                    range: range
                })
            });
        });
    return suggestionFields;
}

function createCompletionItems(model, position){
  const textUntilPosition = model.getValueInRange({startLineNumber: 1, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column});
  const match = textUntilPosition.match("@");
  if (!match) {
      return { suggestions: [] };
  }
  const word = model.getWordUntilPosition(position);
  const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn
  };
  return {
      suggestions: createDependencyProposals(range)
  };
}

export default MonacoConfig;
export { editorOptions as EditorOptions };