/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'ifs',
  templateUrl: './run-statistics.component.html',
  styleUrls: ['./run-statistics.component.scss']
})
export class RunStatisticsComponent {
  
  constructor(
    private reportsService: ReportsService
  ) { }

  reportDetails$ = this.reportsService.reportDetails$;
  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;

  pageReport(context?: string){    
    this.reportsService.pageReport(context);
  }
}
