import { Component, Input, OnInit } from '@angular/core';
import { RunActionsService, SelectedRunService } from '@InfoSlips/iiab-api';
import { RunModel } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { NbDialogService } from '@nebular/theme';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ifs-send-live-form',
  templateUrl: './send-live-form.component.html',
  styleUrls: ['./send-live-form.component.scss']
})
export class SendLiveFormComponent implements OnInit {

  @Input() selectedRunService: SelectedRunService;
  
  selectedRun: RunModel;
  scheduleDate: any;
  isScheduled: boolean = false;
  isTrial: boolean = true;
  mayExpireRun: boolean = false;
  runResultMails = new BehaviorSubject<string[]>(null);

  expirationMessage = "";
  
  constructor(
    private apiBaseService: ApiBaseService,
    public runActionsService: RunActionsService,    
    private dialogService: NbDialogService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.selectedRunService.selectedRun$.subscribe(run=> {
      this.selectedRun = run;
      this.isTrial = run.IsTrial;
    
      this.setDefaultResendMailAddresses(run);
    });

    this.mayExpireRun = this.selectedRunService.mayExpireRun();
  }

  sendLive(){
    this.runActionsService.sendLive(this.selectedRun, this.scheduleDate);
  }

  scheduleRun(date){
    this.scheduleDate = date;
  }

  startScheduledRun(){
    this.runActionsService.startScheduledRun(this.selectedRun.Id);
  }

  cancelScheduledRun(){
    this.runActionsService.cancelScheduledRun(this.selectedRun.Id);
  }

  updateScheduledRun(){
    this.runActionsService.updateScheduledRun(this.selectedRun.Id, this.scheduleDate);
    this.isScheduled = false;
    this.scheduleDate = null;
  }

  toggleIsScheduled(isScheduled: boolean){
    this.isScheduled = !isScheduled;

    if(!isScheduled){
      this.scheduleDate = null;
      this.selectedRun.ScheduleDateTime = null;
    }
  }

  openConfirmationDialog(){
    if (this.expirationMessage != "")
      this.dialogService.open(ConfirmationDialogComponent, {
        context: {
          header: "Warning",
          body: "You're about to expire this run. Are you sure?",
          confirmText: "Yes",
          cancelText: "Cancel",
        }
      }).onClose.subscribe(returnedObj => {
        if (returnedObj == true) this.expireRun();
      });
    else 
      this.toastr.warning("Please add an expiration message");
  }

  expireRun() {
    this.runActionsService.expireRun(this.selectedRun.Id, this.expirationMessage);
    this.expirationMessage = "";
  }

  addAdditionalEmail() {
    let emails = this.runResultMails.getValue();
    emails.push("");
    this.runResultMails.next(emails);
  }

  removeAddAddress(index: number) {
    let oldEmails = this.runResultMails.getValue();
    oldEmails.splice(index, 1);
    this.runResultMails.next(oldEmails);
  }

  updateEmailEntry($event, index) {
    let usersToNotify = this.runResultMails.getValue();
    usersToNotify[index] = $event.target.value;
    this.runResultMails.next(usersToNotify);
  }

  resendRunResult() {
    this.apiBaseService.executePost<boolean>(`run/runResultToEmailReceipientsOnly/${this.selectedRun.Id}`, this.runResultMails.getValue(), 'Resend run result to custom emails.').subscribe((res: any) => {
      if (res) {
        this.selectedRunService.selectedRun$.subscribe(run=> this.setDefaultResendMailAddresses(run));
        this.toastr.success(`The selected Run result will be resent shortly.`, 'Success')
      } else this.toastr.error(`We were unable to resend the run result.`, 'Resend Failed');
    })
  }

  private setDefaultResendMailAddresses(run: RunModel) {
    let usersToNotify: string[] = [];
    
    if (run.RunStartedBy?.Name) usersToNotify.push(run.RunStartedBy.Name);
    if (run.RunCreatedBy?.Name) usersToNotify.push(run.RunCreatedBy.Name);
    usersToNotify = [...usersToNotify, ...run.NotificationEmailRecipients];
    
    this.runResultMails.next([...new Set(usersToNotify)]);
  }
}