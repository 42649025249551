import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiBaseService, RunsService } from '@InfoSlips/iiab-api';
import { IiabIdName, RunModel } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { Filters, graphqlFilters } from '../../report.config';
import { ReportsService } from '../../services/reports.service';
import { CustomerLookupComponent, RunTemplateLookupComponent, RunLookupComponent } from '@InfoSlips/controls';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ifs-filter-component',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() report: any;
  filters: Filters;
  requiredFilters: any[];
  options: string[];
  runPeriodList;

  @ViewChildren(CustomerLookupComponent) customerLookups: QueryList<CustomerLookupComponent>;
  @ViewChildren(RunTemplateLookupComponent) runTemplateLookups: QueryList<RunTemplateLookupComponent>;
  @ViewChildren(RunLookupComponent) runLookups: QueryList<RunLookupComponent>;

  @ViewChild('selectBilling') billingPeriod;

  selectedCustomer: IiabIdName;
  selectedRunTemplate: IiabIdName;
  selectedRun: IiabIdName;


  filteredOptions$ = {
    runCustomers: null,
    runSummaries: null,
    runTemplates: null,
    // billingPeriod: null
  }

  searchQuery = {
    runCustomers: null,
    runSummaries: null,
    runTemplates: null,
    // billingPeriod: null
  }

  filterModels = {
    sendToInput: '',
    channelSelect: '',
    isSentSelect: '',
    isTrialSelect: '',
    isDeliveredSelect: '',
    startDateInput: null,
    endDateInput: null,
    startCreatedDateInput: null,
    endCreatedDateInput: null,
    runCustomers: null,
    runSummaries: null,
    runTemplates: null,
    externalIdInput: null,
    isResendSelect: null,
    emailTrackingSelect: null,
    mailChannelSelect: null,
    runIdInput: '',

    actionInput: '',
    tagInput: '',
    labelInput: '',
    parentHtmlElementIdInput: '',
    htmlElementIdInput: '',
    coordinatesInput: '',
    userAgentInput: '',
    sequenceInput: '',
    recipientIdInput: '',
    recipientNameInput: '',
    runRecipientLikeInput: '',
    filterByEmail: '',
    billingPeriodSelect: ''
  }

  constructor(
    private runsService: RunsService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private apiBaseService: ApiBaseService
  ) { }

  ngOnChanges(changes: SimpleChanges) {    
    if ('report' in changes) {
      this.filters = changes.report.currentValue?.filters;
      this.requiredFilters = changes.report.currentValue?.requiredFilters;
    }
  }

  ngAfterViewInit(): void {
    this.customerLookups.forEach(x => x.showLabel = false);
    this.runTemplateLookups.forEach(x => x.showLabel = false);
    this.runLookups.forEach(x => x.showLabel = false);

    if (this.filterModels.runIdInput != null) {
      if (this.runsService.selectedRun == null) {
        this.runsService.selectedRun$.subscribe(run => {
          if (!run)
            return;

          this._setRunValuesAndLoadReport(run);
        });
        return;
      }
      this._setRunValuesAndLoadReport(this.runsService.selectedRun);
    }
  }

  ngOnInit(): void {
    this.filters = this.report?.filters;
    this.requiredFilters = this.report?.requiredFilters;
    this.filterModels.runIdInput = this.route.snapshot.paramMap.get('runId');
    this.getBillingPeriod();

    // FIXME: DEBUG 
    //   this.reportsService.filterObject = {
    //     "customer": {
    //         "id": {
    //             "eq": "57f4f41e31c2de094403efa1"
    //         }
    //     },
    //     "run": {
    //       "id": {
    //         "eq": "62c6ce85d13fef0d27d6293e"
    //       }
    //     },
    // }
    //   this.reportsService.search(this.report.graphql, this.reportsService.filterObject)
    // FIXME: DEBUG
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange(context: string, filterType: string) {
    this.searchQuery[context] = this[context].nativeElement.value;
    this.filteredOptions$[context] = of([{ id: "Zero", name: "Loading.." }]);

    setTimeout(() => {
      if (this.searchQuery[context] == this[context].nativeElement.value) {
        const filter = new graphqlFilters[filterType](this[context].nativeElement.value).filter;

        this.runsService[context].loadPagedItems(this[context].nativeElement.value ? filter : null);
        this.filteredOptions$[context] = this.runsService[context].Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 1000);
  }

  onSelectionChange($event, context) {
    this[context].nativeElement.value = $event.name;
    this.filterModels[context] = $event.id;
  }

  search() {
    if (Object.keys(this.reportsService.filterObject).length === 0 && (!this.filterModels.runIdInput || this.filterModels.runIdInput.length == 0)) {
      this.toastr.warning(`Please add some filter criteria`);
    }
    else if (this.requiredFilters && !(this.requiredFilters.every(key => Object.prototype.hasOwnProperty.call(this.reportsService.filterObject, key.filterName)))) {
      const missingKeys = this.requiredFilters.filter(key => !Object.prototype.hasOwnProperty.call(this.reportsService.filterObject, key.filterName));
      missingKeys.forEach(key => {
        this.toastr.warning(`${key.filterDescription} is required`);
      });
    }
    else {
      this.reportsService.search(this.report.graphql, this.reportsService.filterObject, undefined, this.report.id);
    }
  }

  download() {
    if (Object.keys(this.reportsService.filterObject).length == 0 && (!this.filterModels.runIdInput || this.filterModels.runIdInput.length == 0)) {
      this.toastr.warning(`Please add some filter criteria`);
    }
    else if (this.requiredFilters && !(this.requiredFilters.every(key => Object.prototype.hasOwnProperty.call(this.reportsService.filterObject, key.filterName)))) {
      const missingKeys = this.requiredFilters.filter(key => !Object.prototype.hasOwnProperty.call(this.reportsService.filterObject, key.filterName));
      missingKeys.forEach(key => {
        this.toastr.warning(`${key.filterDescription} is required`);
      });
    }
    else
      this.reportsService.download(this.report.graphql, this.reportsService.filterObject, this.report.id);
  }

  apiDownload() {
    if (Object.keys(this.reportsService.filterObject).length == 0 && (!this.filterModels.runIdInput || this.filterModels.runIdInput.length == 0)) {
      this.toastr.warning(`Please add some filter criteria`);
    }
    else if (this.requiredFilters && !(this.requiredFilters.every(key => Object.prototype.hasOwnProperty.call(this.reportsService.filterObject, key.filterName)))) {
      const missingKeys = this.requiredFilters.filter(key => !Object.prototype.hasOwnProperty.call(this.reportsService.filterObject, key.filterName));
      missingKeys.forEach(key => {
        this.toastr.warning(`${key.filterDescription} is required`);
      });
    }
    else {
      this.reportsService.apiDownload(this.getReportApiUrl(), "Download Report");
    }
  }

  getBillingPeriod() {
    this.apiBaseService.executeGet('runPeriod', 'Get billing period').subscribe((runPeriodOptions: any) => {
      this.runPeriodList = runPeriodOptions;
    });
  }

  focus(input) {
    document.getElementById(input).focus()
  }

  onCustomerSelected(event: IiabIdName): void{

    if(event === undefined){
      delete this.reportsService.filterObject['customer'];
    }

    if ((!event) || !event.id)
      return;

    this.reportsService.filterObject = {
      ...this.reportsService.filterObject,
      customer: {
        id: {
          eq: event.id
        }
      }
    }
  }

  onCustomerFilterSelected(event: IiabIdName): void{
    if(event === undefined){
      delete this.reportsService.filterObject['customerFilter'];
    }

    if ((!event) || !event.id)
      return;

    this.reportsService.filterObject['customerFilter'] = event.id;
  }

  onRunTemplateSelected(event: IiabIdName): void {
    if(event === undefined){
      delete this.reportsService.filterObject['runTemplate'];
    }

    if ((!event) || !event.id)
      return;

    this.reportsService.filterObject['runTemplate'] = {
      id: {
        eq: event.id
      }
    }

    // if (this.selectedRunTemplate === undefined && !event)
    //   return;

    // this.selectedRunTemplate = event;
    // this.runLookups.forEach(x => x.onRunTemplateSelected(event));
  }

  onRunSelected(event: IiabIdName) {
    if(event === undefined){
      delete this.reportsService.filterObject['run'];
    }

    if ((!event) || !event.id)
      return;

    this.reportsService.filterObject['run'] = {
      id: {
        eq: event.id
      }
    }

    this.selectedRun = event;
  }

  filterChange(value: string, model: string){
    this.reportsService.filterObject[model] = value;
  }

  sendDateFilter(){
    this.reportsService.filterObject['sendDate'] = {
      fromDate: this.filterModels.startDateInput,
      toDate: this.filterModels.endDateInput,
    }
  }

  createdDateFilter(){
    this.reportsService.filterObject['created'] = {
      fromDate: this.filterModels.startDateInput,
      toDate: this.filterModels.endDateInput,
    }
  }

  //#region Private
  private _setRunValuesAndLoadReport(run: RunModel): void {
    this.customerLookups.forEach(x => x.onItemSelected(this._getRef(run.Customer)));
    this.runTemplateLookups.forEach(x => x.onItemSelected(this._getRef(run.RunTemplate)));
    this.runLookups.forEach(x => x.onItemSelected(this._getRef(run)));

    this.search();
  }
  private _getRef(item: any): IiabIdName {
    return {
      id: item.Id,
      name: item.Name
    };
  }
  private getReportApiUrl():string {
    switch (this.report.id){
      case "BILLING_REPORTING":
        return `${this.report.download}${this.reportsService.filterObject["billingPeriod"].DateTime.substring(0, 7).replace("-","_")}`;
      default: console.log(`${this.report.id} download not yet implemented`);
    }
  }
  //#endregion
}