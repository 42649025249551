<nb-reveal-card [showToggleButton]="false" [revealed]="revealed" class="card mb-10 shadow">
    <nb-card-front>
      <nb-card>
        <nb-card-header class="card-header p-10" [routerLink]="'/cms/runtemplates/' + runTemplate?.id">
            

            <div class="row">
              <div class="col-md-6">
                <p class="card-title">
                  {{runTemplate?.name}} <br/>
                  <small>{{runTemplate?.template?.name}}</small>
                </p>
              </div>
              <div class="col-md-6">
                <p class="card-title">
                  CMS Entities: {{ runTemplate?.cmsEntities?.length}} <br/>
                  <small>Uses CMS: {{runTemplate?.useCms}}</small>
                </p>
              </div>
            </div>
      
      </nb-card-header>
      <nb-card-body>
        <ng-container *ngTemplateOutlet="templateId"></ng-container>
        <div class="actions">
            <button nbButton (click)="toggleView()" [status]="'basic'">
              <nb-icon [icon]="'info-circle'" pack="oakmoon" status="info"></nb-icon>
            </button>
        </div>
    </nb-card-body>
      </nb-card>
    </nb-card-front>
    <nb-card-back>
      <nb-card>
        <nb-card-header class="card-header p-10 reveal-card" [routerLink]="'/cms/runtemplates/' + runTemplate?.id">
            <table>
                <tr>
                    <td>Created:</td>
                    <td> {{runTemplate?.createdBy}} ({{runTemplate?.created?.split('T')[0]}})</td>
                </tr>
                <tr>
                    <td>Last Modified:</td>
                    <td>{{runTemplate?.lastUpdatedBy}} ({{runTemplate?.lastUpdated?.split('T')[0]}})</td>
                </tr>
            </table>
      
      </nb-card-header>
        <nb-card-body>
            <ng-container *ngTemplateOutlet="templateId"></ng-container>
          <div class="actions">
              <button nbButton (click)="toggleView()" [status]="'basic'">
                <nb-icon [icon]="'navigate-left'" pack="oakmoon" status="info"></nb-icon>
              </button>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-reveal-card>

  <ng-template #templateId>
    <p class="template-id">
      <small>{{ runTemplate?.id }}</small>
      <ifs-copy [value]="runTemplate?.id"></ifs-copy>
    </p>
  </ng-template>