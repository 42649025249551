import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { ForgotPasswordModel, IiabId, PageGraphQLData, UserHistory, UserModel, UserReference, OakmoonMain } from '@InfoSlips/models';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

interface UserCreatedSubject {
  isCreated: boolean;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  users: ObservableWrapper<UserReference>;

  private allowedMainMenu = new BehaviorSubject<OakmoonMain[]>(null);
  allowedMainMenu$ = this.allowedMainMenu.asObservable();

  countryOptionsSubject = new BehaviorSubject<any>(null);
  countryOptions$ = this.countryOptionsSubject.asObservable();
  
  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.users = new ObservableWrapper<UserReference>(
      this.apiBaseService,
      this._pageUsersQuery(),
      this._fetchUserData,
    false);
    
    this.loadMainMenu()
  }

  private _pageUsersQuery() {
      return `query Users($where: UserFilterInput = null) {
        users(where: $where, order: {userName: ASC}) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              userName
              id
              created
              createdBy
              lastUpdated
              lastUpdatedBy
              email
              lastLoginDate
            }
          }
        }
      }`
  }

  private _pageUserByIdQuery(){
    return `query UserById($where: UserFilterInput = null) {
      users(where: $where) {
        totalCount
        edges {
          node {
            id
            createdBy
            puk
            roleGroups
            rolesAdded
            rolesRemoved
            customerFilter
            runTemplateFilter
            password
            emails
            mobileNumbers
            country {
              id
              name
              callingCode
            }
            importToken
            otpEnabled
            otpSecret
            userName
            email
            isApproved
            isLockedOut
            forcePasswordReset
            failedPasswordAttemptCount
            isDeleted
            deleted
            lastPasswordChangedDate
            lastActivityDate
            lastLoginDate
            lastLockedOutDate
            failedPasswordAttemptWindowStart
            mobile
            displayName
            dateOfBirth
            isClientId
            created
            createdBy
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }
    `;
  }

  private _effectiveRolesQuery(roleGroups: string[], addedRoles: string[], removedRoles: string[]){
    return `query EffectiveRoles {
      effectiveRoles(
        roleGroups: ["${roleGroups.join('", "')}"],
        addedRoles: ["${addedRoles.join('", "')}"], 
        removedRoles: ["${removedRoles.join('", "')}"]
      ) {
        nodes {
          id
        }
      }
    }`;
  }

  private _userHistoryQuery(userId: string){
    return `query UserHistory {
      userHistory(userId: "${userId}") {
        nodes {
          id
          customerName
          customerId
          label
          run {
            id
            name
            runDate
            bilingDateDescription
          }
          historyAgent {
            name
          }
          sequenceCommunication {
            hasMailBody
            hasSmsBody
            hasCompiledIfs
            communicationChannels
          }
        }
      }
    }`;
  }

  private _fetchUserData(rawData: any): PageGraphQLData<UserReference> {
    try{
      return rawData.data.users as PageGraphQLData<UserReference>;
    }
    catch(e)
    {
      console.log("userData That FAILED", rawData);
      return null;
    }
  }

  private _mainMenuEnumQuery() {
    return `query FrontEndUi {
      frontendUi{
        id
        name
        items {
          id
          name
        }
      }
    }`;
  }

  getUserById(id: string){
    const subject = new Subject<UserReference>();
    
    const model = {
      query: this._pageUserByIdQuery(),
      variables: {
        'where': {
          'id': {
              "eq": id
          }
        }
      },
      operationName: 'UserById'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get User By ID.').subscribe((res: any) => {
      if(res.data.users){
        const keys = Object.entries(res.data.users.edges[0].node);
        const capsKeys = keys.map((key) => [key[0][0].toUpperCase() + key[0].slice(1), key[1]]);
        const userObject = Object.fromEntries(capsKeys);
        delete userObject.Password;
        subject.next(userObject);
      } else {
        this.toastr.error(`The specified user: ${id} does not exist.`);
        this.router.navigateByUrl('/');
      }
    })
  
    return subject.asObservable();
  }

  updateUser(user: UserModel){
    this.apiBaseService.executePut<UserModel>('user', user, 'Update User').subscribe((res: UserModel) => {
      if(res){
        this.toastr.success('User Updated!');
      }
    });
  }

  createUser(user: UserModel){
    let subject = new Subject<UserCreatedSubject>();
    //@ts-ignore -- TODO: Ask @Jdevil for advice.
    this.apiBaseService.executePost<UserModel>('user', user, 'Create User').subscribe((res: UserModel) => {
      if(res){
        this.toastr.success('User Created!');
        subject.next({
          id: res.Id,
          isCreated: true
        })
      } else {
        subject.next({
          id: null,
          isCreated: false
        })
      }
    });
    return subject.asObservable();
  }

  getEffectiveRoles(roleGroups: string[], addedRoles: string[], removedRoles: string[]){
    const subject = new Subject<string[]>();
    
    const model = {
      query: this._effectiveRolesQuery(roleGroups, addedRoles, removedRoles),
      operationName: 'EffectiveRoles'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Effective Roles.').subscribe((res: any) => {
      const roles: string[] = [];
      res.data.effectiveRoles.nodes.forEach((node:IiabId) => {
        roles.push(node.id);
      });

      subject.next(roles);
    })
  
    return subject.asObservable();
  }

  getUserHistory(userId: string){
    const subject = new Subject<UserHistory[]>();
    
    const model = {
      query: this._userHistoryQuery(userId),
      operationName: 'UserHistory'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get User History.').subscribe((res: any) => {
      subject.next(res.data.userHistory.nodes);
    })
  
    return subject.asObservable();
  }
  
  loadMainMenu() {
    const model = {
      query: this._mainMenuEnumQuery(),
      operationName: 'FrontEndUi'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Frontend UI Menu.').subscribe((res: any) => {
      const menuitems: OakmoonMain[] = [];
      res.data.frontendUi.forEach((menuitem:OakmoonMain) => {
        menuitems.push(menuitem);
      });

      this.allowedMainMenu.next(menuitems);
    })
  }

  //TODO: Move to password service and make it generic for all use cases.
  sendForgotPassword(userEmail: string){
    this.apiBaseService.executeGet<ForgotPasswordModel>(`forgotpassword/${userEmail}/0`, "Forgot Password").subscribe(
      {
        next: result => {
          if (result) {
            this.toastr.success(
              'A password reset email will be sent to the provided email address shortly.',
              'Password reset success!'
            );
            return;
          }
        },
        error: error => {
          this.toastr.error(
            'Unable to reset password, please refresh the page and try again.',
            'Password reset failed!'
          );
        }
      }
    );
  }

  getCountry(countryName: string){
    this.apiBaseService.executeGet('Country', 'Get Country', {
      params: {
        $inlinecount: 'allpages',
        $format: 'json',
        $top: 10,
        $orderby: 'Name',
        $filter: `substringof('${countryName}',tolower(tolower(Name)))`
      }
    }).subscribe(countries => {
      //@ts-ignore
      this.countryOptionsSubject.next(countries.Items);
    });
  }

  delete(context: string, userId: string){
    this.apiBaseService.executeDelete(`user/${userId}`, `Delete User.`).subscribe(isDeleted => {
      isDeleted ? this.toastr.info(`User deleted!`) : this.toastr.error(`User deletion failed.`);
    });

    this.users.loadPagedItems();
  }

  rebuildUserHistory(userId: string) {
    let subject = new Subject<boolean>();
    this.apiBaseService.executePost<boolean>(`User/rebuildUserHistory/${userId}`, null, 'Rebuild User History').subscribe((res: boolean) => {
      if(res){
        this.toastr.success('Success','User History Rebuilt!');
        subject.next(true)
      } else {
        this.toastr.error('Failed','Unable to rebuild User History');
        subject.next(false)
      }
    });
    return subject.asObservable();
  }
}