import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AntiForgeryService {

  public VERIFICATION_TOKEN_HEADER_NAME = 'x-requestiiabverificationtoken';
  public verificationTokenSubject$ = new BehaviorSubject<string | null>(null);

  verificationTokenQueryInjector(url: string):string {
    return this.verificationToken ? 
      `${url}?${this.VERIFICATION_TOKEN_HEADER_NAME}=${this.verificationToken}` : 
      url;
  }

  setVerificationToken(headers: HttpHeaders):void {
    const token = headers.get(this.VERIFICATION_TOKEN_HEADER_NAME);
    if(token)
      this.verificationTokenSubject$.next(token);
  }

  get verificationToken(): string | null {
    return this.verificationTokenSubject$.getValue();
  }

}
