import { infoslipAnimation } from './../../../../../shared/src/lib/shared.module';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ViewModalComponent } from '../../components/view-modal/view-modal.component';
import { InfoSlip } from '../../models/infoslip';
import { InfoSlipState } from '../../models/infoslip-state';
import { InfoSlipsService } from '@InfoSlips/iiab-api';
import { query } from '@angular/animations';

@Component({
  selector: 'ifs-view',
  animations: [infoslipAnimation],
  //need something better for data prompt show/hide to prevent flashing
  template: `
  <ifs-loading-screen></ifs-loading-screen>
    <ifs-error-message *ngIf="error$ | async" [error]="error$ | async"></ifs-error-message>
    <ifs-one-time-pin
      *ngIf="(hasOneTimePin$ | async) === true"
      [otpState]="getCurrentOtp$ | async"
      [error]="error$ | async"
      (requestOTP)="requestOTP()"
      (validateOTP)="validateOTP($event)"
      (resendOTP)="resendOTP()"
    ></ifs-one-time-pin>
    <ifs-data-prompts
      [InfoSlip]="selectedInfoSlip"
      [dataPrompts]="getDataPrompts$ | async"
      (submitDataPrompts)="submitDataPrompts($event)"
      *ngIf="(hasDataPrompts$ | async) && (hasOneTimePin$ | async) === false"
    ></ifs-data-prompts>
    <ifs-puk-prompt
      [pukState]="hasPukPrompt$ | async"
      (validatePUK)="validatePUK($event)"
      *ngIf="(hasPukPrompt$ | async)"
    ></ifs-puk-prompt>
    <ifs-pre-register
      [preRegisterData$]="getPreRegData$"
      (submitRegistration$)="submitPreRegister($event)"
      *ngIf="getPreRegData$ | async"
    ></ifs-pre-register>
    <ifs-view-modal
      *ngIf="
        (hasOneTimePin$ | async) === false &&
        (hasDataPrompts$ | async) === false &&
        (hasPukPrompt$ | async) === false &&
        (getPreRegData$ | async) === null
      "
      [infoslipDetails$]="getCurrentInfoslip$"
      (tabChange)="updateTabState($event)"
      [theme]="theme"
    >
      <ifs-document-actions
        [theme]="theme"
        (documentAction)="documentAction($event)"
        [actionStates]="actionStates"
      ></ifs-document-actions>
      <ifs-document-search></ifs-document-search>
    </ifs-view-modal>
  `,
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  //url params
  token: string;
  sequence: number;
  historyAgent: string;
  adhocId: string;

  theme: 'Silica' | 'InfoSlips';
  //data prompts valid
  isDataPromptValid: boolean;
  dataPrompts;
  hasDataPrompts$;
  hasOneTimePin$;
  getDataPrompts$;
  hasPukPrompt$;
  getPreRegData$;
  getCurrentInfoslip$;
  getCurrentOtp$;
  error$;
  actionStates;

  selectedInfoSlip;
  activeTab;

  //viewChild for printing of iframe
  @ViewChild(ViewModalComponent, { static: false })
  viewModalComponent: ViewModalComponent;

  infoslipDetails$: Observable<InfoSlip>;
  preRegisterData$: Observable<any>;
  //state variables
  private infoslipStateSubject: BehaviorSubject<InfoSlipState>;
  public infoslipState: Observable<InfoSlipState>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private infoSlipsService: InfoSlipsService
  ) {
    
    //get route params
    this.token = this.route.snapshot.paramMap.get('id');
    this.sequence = Number(this.route.snapshot.paramMap.get('sequence'));
    this.historyAgent = this.route.snapshot.paramMap.get('historyAgent');
    this.adhocId = this.route.snapshot.paramMap.get('adhocId');
    
    // this.getCurrentInfoslip$ = this.infoSlipsService.getInfoslip(this.token, this.sequence, this.historyAgent, this.router);

    //init infoslip state
    this.infoslipStateSubject = new BehaviorSubject<InfoSlipState>({
      activeTab: null,
      currentInfoSlip: null,
      historyAgent: null
    });
    this.infoslipState = this.infoslipStateSubject.asObservable();
  }

  ngOnInit() {
    this.theme = window.location.hostname.includes('silica') ? 'Silica' : 'InfoSlips';

    // this.facade.loadInfoslip();
    this.hasDataPrompts$ = this.infoSlipsService.hasDataPrompts$;
    this.getDataPrompts$ = this.infoSlipsService.getDataPrompts$;
    this.hasPukPrompt$ = this.infoSlipsService.hasPukPrompt$;
    this.hasOneTimePin$ =  this.infoSlipsService.hasOneTimePin$;
    this.getCurrentOtp$ =  this.infoSlipsService.getCurrentOtp$;
    this.getPreRegData$ = this.infoSlipsService.getPreRegData$;
    this.error$ = this.infoSlipsService.error$;

    if(this.router.url.includes("link")){
      this.infoSlipsService.getInfoSlipFromUrl(this.token, this.sequence).subscribe();
    }
    else if(this.router.url.includes("adhoc")){
      this.infoSlipsService.getAdhoc(this.adhocId).subscribe();
    }
    else if(this.router.url.includes("onetime")){
      this.infoSlipsService.getOneTime(this.token).subscribe();
    } 
    else if(this.router.url.includes("completepreregistration")){
      this.infoSlipsService.getInfoSlipFromUrl(this.token, this.sequence).subscribe();
    }
    else{
      this.infoSlipsService.getInfoslip(this.token, this.sequence, this.historyAgent).subscribe();
    }

    this.getCurrentInfoslip$ = this.infoSlipsService.selectedInfoSlip$;
    this.infoSlipsService.selectedInfoSlip$.subscribe(infoSlip => this.selectedInfoSlip = infoSlip);
    this.infoSlipsService.actionStates$.subscribe(data => this.actionStates = data);


    // this.getCurrentInfoslip$ = this.facade.getCurrentInfoslip$.pipe(
    //   delay(0),
    //   tap(currentInfoSlip => {
    //     const activeTab =
    //       currentInfoSlip &&
    //       currentInfoSlip.activeTab &&
    //       currentInfoSlip.activeTab.Printable;
    //     this.actionStates = {
    //       download: this.router.url.toLowerCase().includes('adhoc') ? false : true,
    //       print: activeTab,
    //       downloadPDF: activeTab,
    //       searchDocument: false,
    //     };
    //     console.log('this.actionStates: ', this.actionStates);
    //   })
    // );
  }

  documentAction(event) {
    switch (event) {
      case 'print': {
        this.print();
        break;
      }
      case 'download': {
        this.download();
        break;
      }
      case 'downloadPDF': {
        this.downloadPDF();
        break;
      }
    }
  }

  print() {
    try{
      var iframe =  document.getElementById('infoslipIframe');

      if(iframe==null || iframe==undefined)
      {
        console.log("Failed to resolve the IFrame");
        return;
      } 

      //@ts-ignore 
      if(iframe.src.indexOf("infoslips.com")>-1){
        window.frames[0].postMessage('printASIS', '*');
      }
      else{
        window.frames[0].focus();
        window.frames[0].print();
      }
    }
    catch(e)
    {
      console.log("Print error",e);
    }
  }

  download() {
    this.infoSlipsService.downloadInfoSlip(this.router, this.selectedInfoSlip.RunRecipient, this.selectedInfoSlip.Id).subscribe();
  }

  downloadPDF() {
    if(this.activeTab === undefined){
      this.activeTab = this.selectedInfoSlip.Tabs[0];
    }

    this.infoSlipsService.downloadPDF({
      activeTab: this.activeTab,
      currentInfoSlip: this.selectedInfoSlip,
      historyAgent: this.historyAgent
    }).subscribe();
  }

  updateTabState(tab) {
    this.activeTab = tab;
    this.infoSlipsService.setTabState(tab.Printable, this.selectedInfoSlip.AllowedActions);
    try{
      if(tab.PathName || tab.QueryString){
        const message = {action:"NAVIGATE", path:tab.PathName, query:tab.QueryString};
        console.log("send message to iframe", message);
      //@ts-ignore 
      window.frames[0].postMessage(message, '*');

        //var iFrame =  document.getElementById('infoslipIframe');
      //iFrame.contentWindow.postMessage(message, '*');
      }
    }
    catch(e){
      console.log("Error sending NAVIGATE message", e);
    }
  }

  submitDataPrompts(answers) {
    // var packageIdParam = packageId ? "/cache/" + packageId : "";
    const dataPropmtModel = {
      "PackageId": this.selectedInfoSlip.Id,
      "RunRecipientId": this.selectedInfoSlip.RunRecipientId,
      "Answers": answers,
      "HistoryAgentId": this.historyAgent
    }
    this.infoSlipsService.validatePrompts(dataPropmtModel);
  }

  requestOTP() {    
    this.infoSlipsService.requestOTP(this.selectedInfoSlip.Id).subscribe();
  }
  validateOTP(pin) {
    this.infoSlipsService.validateOTP(this.selectedInfoSlip.Id, pin).subscribe();
  }
  resendOTP() {
    this.infoSlipsService.resendOTP(this.selectedInfoSlip.Id).subscribe();
  }

  validatePUK(pukCode) {
    this.infoSlipsService.validatePUK(this.selectedInfoSlip.Id, pukCode);
  }

  submitPreRegister(data) {
    this.infoSlipsService.submitPreRegistration(data).subscribe(value => {
      this.router.navigateByUrl('auth/login');
      this.toastr.success(
        'Login with the details you submitted.',
        'Successfully registered.'
      );
    });
  }
}

