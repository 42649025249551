<ifs-loading-screen *ngIf="!recipientDataForm.value.id"></ifs-loading-screen>
<div class="general-page-container">
  <div class="general-page-header">
    <div class="d-flex" *ngIf="isRecipient">
        <div class="flex-1">
          <h4 class="heading">Recipient Details: <b>{{recipientDataForm.value.name}}</b></h4>
        </div>
        <div class="text-right">
          <button size="medium" outline nbButton routerLink="/">
            Cancel
          </button>
        </div>
    </div>
    
    <div class="d-flex" *ngIf="!isRecipient">
      <div class="flex-1">
        <h4 class="heading">Run Recipient Details: <b>{{recipientDataForm.value.name}}</b></h4>
        <a *ngIf="isSystemAdmin" [routerLink]="'../../runtemplates/' + recipientDataForm.value.runTemplate.id">{{recipientDataForm.value.runTemplate.name}}</a>
      </div>
      <div class="text-right">
        <button size="medium" outline nbButton routerLink="/">
          Cancel
        </button>
        <button size="medium" nbButton status="primary" (click)="goBack()" style="margin-left: 12px;">
          Back
        </button>
      </div>
    </div>
  </div>
</div>

<nb-card>
  <nb-card-body>
    <div class="row">
      <div class="col-md-4">
        <div class="input-box" [nbSpinner]="!recipientDataForm.value.id" nbSpinnerStatus="primary" nbSpinnerSize="large">
          <div class="input-body">
            <ng-container *ngTemplateOutlet="formField; context: { label: 'External ID', formControlName: 'externalId' }"></ng-container>
            <ng-container *ngTemplateOutlet="formField; context: { label: 'Name', formControlName: 'name' }"></ng-container>
            <ng-container *ngTemplateOutlet="formField; context: { label: 'Email', formControlName: 'email' }"></ng-container>
            <ng-container *ngTemplateOutlet="formField; context: { label: 'Mobile', formControlName: 'mobile' }"></ng-container>
            <ng-container *ngIf="isRecipient">
              <ng-container *ngTemplateOutlet="formField; context: { label: 'Public ID', formControlName: 'publicId' }"></ng-container>
            </ng-container>
            <ng-container *ngIf="!isRecipient">
              <ng-container *ngTemplateOutlet="formField; context: { label: 'Online ID', formControlName: 'onlineId', runRecipient:true }"></ng-container>
            </ng-container>
            <ng-container *ngIf="allowPuk()">
              <ng-container *ngTemplateOutlet="formField; context: { label: 'PUK', formControlName: 'puk' }"></ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="formField; context: { label: 'Company', formControlName: 'name', formGroupName: 'customer', routerLink: '../../customers/'+recipientDataForm.value.customer.id }"></ng-container>
            <ng-container *ngTemplateOutlet="formField; context: { label: 'Run', formControlName: 'name', formGroupName: 'run', routerLink: '../../../run/'+recipientDataForm.value.run.id }"></ng-container>
          </div>
        
          <div class="actions-footer" *ngIf="!isRecipient">
            <nb-actions size="small" fullWidth>
              <nb-action icon="email-outline" (click)="resendInfoSlip()" nbTooltip="Resend InfoSlip"></nb-action>
              <nb-action icon="code-download-outline" (click)="downloadIfsSourceData()" nbTooltip="Download InfoSlip Data"></nb-action>
              <nb-action icon="download-outline" (click)="downloadIfs()" nbTooltip="Download InfoSlip"></nb-action>
              <nb-action icon="eye-outline" (click)="preview(previewDialog)" nbTooltip="Preview InfoSlip"></nb-action>
            </nb-actions>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="input-box no-padding" nbSpinnerStatus="primary" nbSpinnerSize="large">
          <table width="100%" *ngIf="isRecipient">
            <thead>
              <tr>
                <th>Run</th>
                <th>Billing Date</th>
                <th>Run Date</th>
                <th>Sent Status</th>
                <th>Options</th>
                <th>
                  <button nbButton [disabled]="hasRunRecipientIds() === 0" (click)="open(resendOptions)">
                    Resend <nb-icon icon="diagonal-arrow-right-up-outline"></nb-icon>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let field of outputChannels">
                <td class="runName">
                  <a [routerLink]="'../../../run/' + field.id">{{field.name}}</a>
                </td>
                <td>{{ field.bilingDateDescription }}</td>
                <td>{{ field.runDate | localDate }}</td>
                <td>

                  <p *ngFor="let channel of field.outputChannels" style="display: inline-flex; margin-right: 8px;">
                    <br/>
                      <nb-icon 
                        [nbTooltip]="channel.tooltip.text" 
                        [nbTooltipStatus]="channel.tooltip.status" 
                        [icon]="channel.tooltip.type" 
                        [class]="channel.tooltip.status"
                        [status]="channel.tooltip.status"></nb-icon>
                  </p>

                  <p *ngIf="field.outputChannels.length === 0" style="display: inline-flex; margin-right: 8px;">
                    <nb-icon pack="oakmoon" icon="cross-outline"></nb-icon>
                  </p>
                </td>
                <td>
                  <nb-icon class="hover" nbTooltip="Run Recipient Details" icon="person-outline" [routerLink]="'/admin/run-recipients/' + field.runRecipient.id" [queryParams]="{recipients: id}"></nb-icon>
                  <nb-icon class="hover" [nbTooltip]="'Preview Document:' + field.runRecipient.id" icon="book-outline" (click)="preview(previewDialog, field.runRecipient.id)"></nb-icon>
                </td>
                <td>
                  <nb-checkbox (checkedChange)="addRunRecipientId(field.runRecipient.id)"></nb-checkbox>
                </td>
              </tr>
            </tbody>
          </table>

          <table width="100%" *ngIf="!isRecipient" class="padded-table">
            <thead>
              <tr>
                <th style="text-align: center;">Success</th>
                <th>Sent</th>
                <th>Sent To</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let field of outputChannels">
                <td *ngIf="field.resultMessage" [nbTooltip]="field.resultMessage" nbTooltipStatus="primary" style="text-align: center;">
                  <nb-icon pack="oakmoon" [icon]="field.isDelivered ? 'check' : 'cross'"></nb-icon>
                </td>
                <td *ngIf="!field.resultMessage" style="text-align: center;">
                  <nb-icon pack="oakmoon" [icon]="field.isDelivered ? 'check' : 'cross'"></nb-icon>
                </td>
                <td>{{ field.sendDate | localDate }}</td>
                <td [nbTooltip]="field.isTrial ? 'Trial' : field.isResend ? 'Resend' : 'Live'" [nbTooltipStatus]="field.isTrial ? 'danger' : field.isResend ? 'secondary' : 'primary'" [class]="field.isDelivered ? '' : 'danger'">
                  <nb-icon *ngIf="field.resultMessage" [nbTooltip]="field.resultMessage" nbTooltipStatus="primary" [icon]="field.channel === 'SMS' ? 'smartphone-outline' : 'email-outline'" [status]="field.isDelivered ? 'basic' : 'danger'"></nb-icon>
                  <nb-icon *ngIf="!field.resultMessage" [icon]="field.channel === 'SMS' ? 'smartphone-outline' : 'email-outline'" [status]="field.isDelivered ? 'basic' : 'danger'"></nb-icon>: {{ field.sendTo }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  </div>
  </nb-card-body>
</nb-card>

<ng-template #formField let-label="label" let-formControlName="formControlName" let-formGroupName="formGroupName" let-routerLink="routerLink">
  <form [formGroup]="recipientDataForm">
    <div *ngIf="formGroupName && (!runRecipient || (runRecipient && !isRecipient))" [formGroupName]="formGroupName">
      <nb-form-field>
        <label>
          <p>{{label}}</p>
        </label>
        <input type="text" class="clickable" nbInput fullWidth [formControlName]="formControlName" readonly [routerLink]="routerLink">
        <button nbSuffix nbButton (click)="copyToClipboard(formControlName, formGroupName)">
          <nb-icon icon="copy-outline" pack="eva"></nb-icon>
        </button>
      </nb-form-field>
    </div>
  
    <div *ngIf="!formGroupName">
      <nb-form-field>
        <label>
          <p>{{label}}</p>
        </label>
        <input type="text" nbInput fullWidth [formControlName]="formControlName" readonly>
        <button nbSuffix nbButton (click)="copyToClipboard(formControlName)">
          <nb-icon icon="copy-outline" pack="eva"></nb-icon>
        </button>
      </nb-form-field>
    </div>
  </form>
</ng-template>

<ng-template #resendOptions let-data let-ref="dialogRef">
  <nb-card class="dialog-md">
    <nb-card-header>
      Resend Options
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="resendOptionsForm">
        <nb-checkbox [(ngModel)]="defaultEmailSet" [ngModelOptions]="{standalone: true}">Resend to default email</nb-checkbox>
        <nb-checkbox [(ngModel)]="defaultMobileSet" [ngModelOptions]="{standalone: true}">Resend to default mobile</nb-checkbox>
     
        <ng-container *ngIf="!defaultEmailSet">
          <label>
            <p>To Address</p>
          </label>
          <input type="text" nbInput fullWidth formControlName="ToAddress">
        </ng-container>

        <br/>
        <ng-container *ngIf="resendOptionsForm.value.ToAddress?.length > 3">
          <br/>
          <nb-checkbox (checkedChange)="setCheckbox('UpdateRecipient', $event)">Update recipients email address with above</nb-checkbox>
        </ng-container>

        <ng-container *ngIf="!defaultMobileSet"> 
          <label>
            <p>To Mobile Number</p>
          </label>
          <input type="text" nbInput fullWidth formControlName="ToMobile">
        </ng-container>
        <nb-checkbox *ngIf="resendOptionsForm.value.ToMobile?.length > 10" (checkedChange)="setCheckbox('UpdateRecipientMobile', $event)">Update recipients mobile address with above</nb-checkbox>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <button type="button" nbButton status="basic" (click)="ref.close()">
        Close
      </button>
      <button type="button" nbButton status="primary" (click)="send(ref)">
        Send
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template dialogClass="preview-dialog" #previewDialog let-ref="dialogRef" let-selectedId>
  <ifs-preview [context]="'ifs'" [recipientId]="selectedId" [sequence]="0" (closePreview)="closePreview()">
  </ifs-preview>
</ng-template>