/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiBaseService, ObservableWrapper } from '@InfoSlips/iiab-api';
import { CmsEntity, CmsEntityModel, PageGraphQLData, CmsRevision, CmsCreateUpdateContentModel, CmsCreateUpdateFileModel, CmsEntityReference, RunTemplateSummary, CmsEntityReferenceModel, CmsContentType, CmsEntityType } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class CmsService {
    public cmsEntities: ObservableWrapper<CmsEntity>;
    public cmsGlobalEntities: ObservableWrapper<CmsEntity>;

    activeEditor = false;
    activeEditorSubject = new BehaviorSubject<boolean>(false);
    activeEditor$ = this.activeEditorSubject.asObservable();
    selectedEntitySubject = new BehaviorSubject<CmsEntityModel>(null);
    selectedEntity$ = this.selectedEntitySubject.asObservable();

    showDetailsSubject = new BehaviorSubject<boolean>(false);
    showDetails$ = this.showDetailsSubject.asObservable();

    wizardStateSuject = new BehaviorSubject<any>(null);
    wizardState$ = this.wizardStateSuject.asObservable();

    cmsEntitiesSubject = new BehaviorSubject<CmsEntity[]>(null);
    cmsEntities$ = this.cmsEntitiesSubject.asObservable();

    constructor(
        private apiBaseService: ApiBaseService,
        private toastr: ToastrService
    ) {
        this.cmsEntities = new ObservableWrapper<CmsEntity>(
            this.apiBaseService,
            this._pageCmsEntitiesQuery(),
            this._fetchCmsEntitiesData,
            true
        );

        this.cmsGlobalEntities = new ObservableWrapper<CmsEntity>(
            this.apiBaseService,
            this._pageCmsEntitiesQuery(),
            this._fetchCmsEntitiesData,
            true
        );

        this.cmsEntities.Items$().subscribe(items=>{
          this.cmsEntitiesSubject.next(items);
        });
        this.cmsGlobalEntities.loadPagedItems();
    }

    activateEditor(activeEdit: boolean) {
        this.activeEditor = true;
        this.activeEditorSubject.next(this.activeEditor);
        !activeEdit ? this.selectedEntitySubject.next(null) : null;

    }

    deactivateEditor() {
        this.activeEditor = false;
        this.activeEditorSubject.next(this.activeEditor);
    }

    retrieveCmsEntity(id: string) {
        this.apiBaseService.executeGet<CmsEntityModel>(`cms/${id}`, 'Get Cms Entity').subscribe((data: any) => {
            this.selectedEntitySubject.next(data);
            this.showDetailsSubject.next(true);
        });
    }

    async cmsFileHandler(requestInput, file, isUpdate: boolean) {
        const fileToUpload = file;
        const formData = new FormData();
        if (file)
            formData.append('UploadFile', fileToUpload, fileToUpload.name);
        formData.append('Model', JSON.stringify(requestInput));
        
        if(isUpdate){
            return await this.updateCmsFile(formData)
        } else {
            return await this.createCmsFile(formData)
        }
    }

    async createCmsFile(formData) {
        return await this.apiBaseService.executePost<CmsCreateUpdateFileModel>(`cms/file`, formData, 'Create Cms File Entity').pipe(map(res => {
            if(res){
                this.toastr.info('File uploaded!')
                return {
                    title: 'CMS file setup completed!',
                    body: 'Do you want to setup another CMS Entity?',
                    cmsEntity: res,
                    success: true
                }
            } else {
                this.toastr.error('File upload failed.');
                return {
                    title: 'CMS file setup failed.',
                    body: null,
                    cmsEntity: res,
                    success: false
                }
            }

         
        })).toPromise();
    }

    async updateCmsFile(formData) {
        return await this.apiBaseService.executePut<CmsCreateUpdateFileModel>(`cms/file`, formData, 'Update Cms File Entity').pipe(map(res => {
            if(res){
                this.toastr.info('File updated!')
                return {
                    title: 'CMS file updated!',
                    body: 'Do you want to make additional changes?',
                    cmsEntity: res,
                    success: true
                }
            } else {
                this.toastr.error('File update failed.');
                return {
                    title: 'CMS file update failed',
                    body: null,
                    cmsEntity: res,
                    success: false
                }
            }
        })).toPromise();
    }

    async createCmsContent(cms: CmsCreateUpdateContentModel) {
        return this.apiBaseService.executePost<CmsCreateUpdateContentModel>(`cms/content`, cms, 'Create Cms Content Entity').pipe(map(res => {
            if(res){
                this.toastr.info('Content created!');
                return {
                    title: 'CMS content setup completed!',
                    body: 'Do you want to setup another CMS Entity?',
                    cmsEntity: res,
                    success: true
                }
            } else {
                this.toastr.error('Creation failed.');
                return {
                    title: 'CMS content setup failed',
                    body: null,
                    cmsEntity: res,
                    success: false
                }
            }
        })).toPromise();
    }

    async updateCmsContent(cms: CmsCreateUpdateContentModel) {
        return this.apiBaseService.executePut<CmsCreateUpdateContentModel>(`cms/content`, cms, 'Create Cms Content Entity').pipe(map(res => {
            if(res){
                this.toastr.info('Content updated!');
                return {
                    title: 'CMS content updated!',
                    body: 'Do you want to make additional changes?',
                    cmsEntity: res,
                    success: true
                }
            } else {
                this.toastr.error('Update failed.');
                return {
                    title: 'CMS content update failed.',
                    body: null,
                    cmsEntity: res,
                    success: false
                }
            }
        })).toPromise();
    }

    createTheme(cms: CmsCreateUpdateContentModel) {
        this.apiBaseService.executePost<CmsEntityModel>(`cms/theme`, cms, 'Create Cms Theme Entity').subscribe(res => {
            res ? this.toastr.success('Theme created!') : this.toastr.error('Theme creation failed.');
        })
    }

    createThemeFile(requestInput, file) {
        const fileToUpload = file;
        const formData = new FormData();
        formData.append('UploadFile', fileToUpload, fileToUpload.name);
        formData.append('Model', JSON.stringify(requestInput));
        return this.apiBaseService.executePut<CmsEntityModel>(`cms/file`, formData, 'Create Cms File Entity');
    }

    deleteCmsEntity(entityId: string) {
        return this.apiBaseService.executeDelete<string>(`cms/${entityId}`, 'Delete Cms Entity').subscribe(res => {
            if (res) {
                this.toastr.info('Item deleted!');
                this.closeDetails();
                this.cmsEntities.loadPagedItems();
            } else {
                this.toastr.error('Deletion failed.');
            }
        });
    }

    closeDetails() {
        this.showDetailsSubject.next(false);
        this.selectedEntitySubject.next(undefined);
    }

    linkCmsEntities(runTemplateId: string, entities: CmsEntityReference[]) {
        const referenceModels = entities.map(entity => this.toCmsEntityReferenceModel(entity));

        return this.apiBaseService.executePost<CmsEntityModel>(`cms/runtemplate/${runTemplateId}`, referenceModels, 'Update Run Template Linked Entities')
            .subscribe(res => {
                res ? this.toastr.info('Linked entities updated!') : this.toastr.error('Entity linking failed.');
            });
    }

    getCmsRevisions(entityPublicId: string) {
        const CmsRevisions = new ObservableWrapper<CmsRevision>(
            this.apiBaseService,
            this._pageCmsRevisionsQuery(entityPublicId),
            this._fetchCmsRevisionsData,
            false);

        CmsRevisions.loadPagedItems(entityPublicId);
        return CmsRevisions.Items$();
    }

    cmsContains(searchPhrase: string, customerId: string = null, runTemplateId: string = null): void {
        const cms = new ObservableWrapper<CmsEntity>(
            this.apiBaseService,
            this._pageCmsContainsQuery(searchPhrase),
            this._fetchCmsEntitiesData,
            true
        );

        cms.loadPagedItems(this._getWhere(searchPhrase, customerId, runTemplateId));
        cms.Items$().subscribe(items => {
            this.cmsEntitiesSubject.next(items);
        });
    }

    cmsSearch(nameLike: string, customerId: string = null, runTemplateId: string = null): void {
        const cms = new ObservableWrapper<CmsEntity>(
            this.apiBaseService,
            this._pageCmsEntitiesQuery(),
            this._fetchCmsEntitiesData,
            true
        );

        cms.loadPagedItems(this._getWhere(nameLike, customerId, runTemplateId));
        cms.Items$().subscribe(items => {
            this.cmsEntitiesSubject.next(items);
        });
    }

    //TODO: @jacodv, change to immutable apollo graphql call.
    async getLinkedCmsEntities(runTemplateId: string) {
        const model = {
            query: this._linkedCmsEntitiesQuery(runTemplateId),
            operationName: 'getLinkedCmsEntities'
          }

        return await this.apiBaseService.executeGQLPost(model, 'Get Linked Entities by ID.').pipe(map(res =>  {
            return this._fetchLinkedCmsEntities(res);
        }))
        .toPromise();
    }


    getCmsEntityCreateUpdateModel(cmsEntity: CmsEntityModel): CmsCreateUpdateContentModel {
        const result: CmsCreateUpdateContentModel = {
            Id: cmsEntity.Id,
            Name: cmsEntity.Name,
            Display: cmsEntity.Display,
            ContentType: cmsEntity.ContentType,
            Notes: cmsEntity.Notes,
            Language: cmsEntity.Language,
            IsDraft: cmsEntity.IsDraft,
            Tags: cmsEntity.Tags,
            CustomerId: cmsEntity.Customer?.Id,
            RunTemplateId: cmsEntity.RunTemplate?.Id,
            CustomerGroupId: cmsEntity.CustomerGroup?.Id,
            CmsContentType: cmsEntity.CmsContentType,
            CmsEntityType: cmsEntity.CmsEntityType,
            PublicId: cmsEntity.PublicId,
            Data: cmsEntity.Data,
            Version: cmsEntity.Version
        };

        return result;
    }
    getCmsEntityReference(input: CmsEntityReferenceModel):CmsEntityReference{
        const result: CmsEntityReference = {
            cmsContentType: input.CmsContentType,
            id:input.Id,
            isDraft:input.IsDraft,
            length:input.Length,
            lockLatestVersion:input.LockLatestVersion,
            name:input.Name,
            publicId:input.PublicId,
            scope:input.Scope,
            specificVersion:input.SpecificVersion,
            type:input.Type,
            useOffline:input.UseOffline,
            version:input.Version
        };
        return result;
    }
    getCmsEntityReferences(input: CmsEntityReferenceModel[]):CmsEntityReference[]{
        return input.map(item=>this.getCmsEntityReference(item));
    }

    async getCmsEntity(id: string) {
        return await this.apiBaseService.executeGet<CmsEntityReferenceModel>(`cms/${id}/`, 'Get Cms Entity').pipe(map((res: CmsEntityReferenceModel) =>  {
            return {
                ...res,
                ...{
                    type: res.CmsContentType
                }
            }
        }))
        .toPromise();
    }

    toCmsEntityReference(cmsEntity: CmsEntity): CmsEntityReference {
        if (cmsEntity.version === -1)
            cmsEntity = {...cmsEntity, ...{
                isDraft: true,
                type: this.getEntityType(cmsEntity.cmsEntityType.toString())
            }}
        const cmsEntityReference = (cmsEntity as unknown) as CmsEntityReference;
        return cmsEntityReference;
    }
    toCmsEntityReferenceModel(cmsEntityReference: CmsEntityReference): CmsEntityReferenceModel {
        const keys = Object.entries(cmsEntityReference);
        const capsKeys = keys.map((key) => {
            try{
                return [key[0][0].toUpperCase() + key[0].slice(1), key[1]];
            }
            catch(e){
                console.log('toCmsEntityReferenceModel',e);
                return null;
            }});

        const model = Object.fromEntries(capsKeys) as CmsEntityReferenceModel;
        model.CmsContentType = this.getContentType(cmsEntityReference);

        return model;
    }

    getContentTypeEnum(number):string {
        return CmsContentType[number];
    }

    getContentIcon(contentType) {
        switch (contentType) {
            case ('EMAIL_BODY'): case 0: return 'email';
            case ('EMAIL_SUBJECT'): case 1: return 'email';
            case ('SMS_BODY'): case 2: return 'document';
            case ('DATA_MODEL'): case 3: return 'json';
            case ('HTML'): case 4: return 'html';
            case ('CSS'): case 5: return 'css';
            case ('SCRIPT'): case 6: return 'js';
            case ('TEXT'): case 7: return 'document';
            case ('URL'): case 8: return 'document';

            case ('IMAGE'): case 9: return 'image';
            case ('PDF'): case 10: return 'pdf';
            case ('COMPRESSED'): case 11: return 'zip';
            case ('VIDEO'): case 12: return 'video';
            case ('VIDEO_STREAM'): case 13: return 'video';
            case ('AUDIO'): case 14: return 'audio';
            case ('AUDIO_STREAM'): case 15: return 'audio';

            case ('THEME_IMAGES'): case 16: return 'image';
            case ('THEME_PALETTE'): case 17: return 'folder-config';

            case ('MARKDOWN'): case 18: return 'md';
            case ('JSON_SCHEMA'): case 19: return 'json';
            case ('FONT'): case 20: return 'font';
            case ('XML'): case 21: return 'xml';
            case ('XSLT'): case 22: return 'xslt';
            case ('TRACK_URL'): case 23: return 'document';

            default: return 'none';
        }
    }

    getContentType(contentType) {
        switch (contentType) {
            case ('EMAIL_BODY'): return 0;
            case ('EMAIL_SUBJECT'): return 1;
            case ('SMS_BODY'): return 2;
            case ('DATA_MODEL'): return 3;
            case ('HTML'): return 4;
            case ('CSS'): return 5;
            case ('SCRIPT'): return 6;
            case ('TEXT'): return 7;
            case ('URL'): return 8;
            case ('IMAGE'): return 9;
            case ('PDF'): return 10;
            case ('COMPRESSED'): return 11;
            case ('VIDEO'): return 12;
            case ('VIDEO_STREAM'): return 13;
            case ('AUDIO'): return 14;
            case ('AUDIO_STREAM'): return 15;
            case ('THEME_IMAGES'): return 16;
            case ('THEME_PALETTE'): return 17;
            case ('MARKDOWN'): return 18;
            case ('JSON_SCHEMA'): return 19;
            case ('FONT'): return 20;
            case ('XML'): return 21;
            case ('XSLT'): return 22;
            case ('TRACK_URL'): return 23;
            default: return -1;
        }
    }

    getContentTypeString(contentType) {
        switch (contentType) {
            case  0: return 'EMAIL_BODY';
            case  1: return 'EMAIL_SUBJECT';
            case  2: return 'SMS_BODY';
            case  3: return 'DATA_MODEL';
            case  4: return 'HTML';
            case  5: return 'CSS';
            case  6: return 'SCRIPT';
            case  7: return 'TEXT';
            case  8: return 'URL';
            case  9: return 'IMAGE';
            case 10: return 'PDF';
            case 11: return 'COMPRESSED';
            case 12: return 'VIDEO';
            case 13: return 'VIDEO_STREAM';
            case 14: return 'AUDIO';
            case 15: return 'AUDIO_STREAM';
            case 16: return 'THEME_IMAGES';
            case 17: return 'THEME_PALETTE';
            case 18: return 'MARKDOWN';
            case 19: return 'JSON_SCHEMA';
            case 20: return 'FONT';
            case 21: return 'XML';
            case 22: return 'XSLT';
            case 23: return 'TRACK_URL';
            default: return -1;
        }
    }

    getEntityType(entityType: string): CmsEntityType {
        switch (entityType.toLocaleLowerCase()) {
            case 'file': return CmsEntityType.File;
            case 'content': return CmsEntityType.Content;
            default: return CmsEntityType.NotSet;
        }
    }

    getEntityTypeString(entityType: CmsEntityType): string  {
        switch (entityType) {
            case CmsEntityType.File: return 'FILE';
            case CmsEntityType.Content: return 'CONTENT';
            default: return 'NOT SET';
        }
    }

    revertCmsEntity(id: string, revisionVersion: number) {
        this.apiBaseService.executePost<CmsEntityModel>(`cms/revert/${id}/${revisionVersion}`, null, 'Revert Cms Entity').subscribe((response: CmsEntityModel) => {
            response ? this.toastr.info('CMS entity reverted successfully.') : this.toastr.error('Failed to revert the CMS entity.')
        });
    }

    async markdownAsHtml(id: string, revisionVersion: number) {
        return await this.apiBaseService.executePost<string>(`cms/markdownashtml/publicid/${id}/${revisionVersion}`, null, 'Transform Markdown to HTML', true, {
            headers: {
                accept:'*/*',
            },
            responseType: 'text' as 'json'
        }).pipe(
            map((response: string) => {
                response ? this.toastr.info('Markdown transformed successfully.') : this.toastr.error('Failed to transform markdown.');
            return response;
        })).toPromise();
    }

    async downloadCmsEntity(cmsEntity: CmsEntityModel){
        this.toastr.info('File download started.')
        const url = `cms/publicid/${cmsEntity.PublicId}/${cmsEntity.Version}`
        if(!cmsEntity.Data){
            cmsEntity.Data = await this.apiBaseService.executeGetAny(url, 'Get Online Content', {responseType: 'blob', observe: 'response'}).pipe(map((content:any)=> content.body)).toPromise();
        }            
        this.triggerDownloadEvent(cmsEntity.Name, cmsEntity.Data, cmsEntity.FileExtension);
    }

    triggerDownloadEvent(filename: string, data: any, ext: string) {
        const file = new Blob([data], {type: ext});
        const a = document.createElement('a'), url = URL.createObjectURL(file);

        a.href = url;
        a.download = ext ? `${filename}${ext}` : filename;
        
        document.body.appendChild(a);
        a.click();

        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 

        return null;
      }


    //#region Private
    private _getWhere(nameLike: string = null, customerId: string = null, runTemplateId: string = null): any {
        const where = {};
        let hasWhere = false;

        if (nameLike) {
            where["name"] = {
                like: nameLike
            }
            hasWhere = true;
        }

        if (runTemplateId) {
            where["runTemplate"] = {
                id: { eq: runTemplateId }
            }
            hasWhere = true;
        }
        else {
            if (customerId) {
                where["customer"] = {
                    id: { eq: customerId }
                }
                hasWhere = true;
            }
        }

        return hasWhere ?
            where :
            null;
    }
    private _fetchCmsEntitiesData(rawData: any): PageGraphQLData<CmsEntity> {
        try {
            return rawData.data.cmsEntities as PageGraphQLData<CmsEntity>;
        }
        catch (e) {
            console.log('cmsEntitiesData That FAILED', rawData);
            return null;
        }
    }

    private _pageCmsRevisionsQuery(filter) {
        return `query CmsRevisions {
                    cmsRevisions(publicId: "${filter}") {
                        edges {
                            node {
                                id
                                name
                                display
                                version
                            }
                        }
                }
            }`;
    }

    private _fetchCmsRevisionsData(rawData: any): PageGraphQLData<CmsRevision> {
        try {
            return rawData.data.cmsRevisions as PageGraphQLData<CmsRevision>;
        }
        catch (e) {
            console.log('CmsRevisionsData That FAIlED', rawData);
            return null;
        }
    }

    private _pageCmsEntitiesQuery(pageSize:number=16) {
        return `
        query CmsEntities ($afterCursor: String = null, $where: CmsEntityFilterInput = null, $order: CmsEntitySortInput = {
            lastUpdated: DESC
          }) {
            cmsEntities (first: ${pageSize}, order: [$order], where: $where, after: $afterCursor) {
                totalCount,
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,
                  endCursor
                },
                edges{
                    node {
                        name
                        display
                        contentType
                        notes
                        version
                        publicId
                        customer {
                            name
                            id
                        }
                        runTemplate {
                            name
                            id
                        }
                        customerGroup {
                            id
                            name
                        }
                        cmsEntityType
                        cmsContentType
                        data
                        fileExtension
                        id
                        created
                        createdBy
                        lastUpdated
                        lastUpdatedBy
                        scope
                        isDraft
                    }
                }
            }
        }`;
    }

    private _pageCmsContainsQuery(searchPhrase: string): string {
        return `
        query CmsContains ($where: CmsEntityFilterInput = null) {
            cmsEntities (searchPhrase: "${searchPhrase}", first: 100, order: {lastUpdated: DESC}, where: $where) {
                totalCount,
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,
                  endCursor
                },
                edges{
                    node {
                        name
                        display
                        contentType
                        notes
                        version
                        publicId
                        customer {
                            name
                            id
                        }
                        runTemplate {
                            name
                            id
                        }
                        cmsEntityType
                        cmsContentType
                        data
                        fileExtension
                        id
                        created
                        createdBy
                        lastUpdated
                        lastUpdatedBy
                        scope
                    }
                }
            }
        }`;
    }
    private _fetchLinkedCmsEntities(rawData: any): RunTemplateSummary {
        try {
            return rawData.data.runTemplateById as RunTemplateSummary;
        }
        catch (e) {
            console.log('linkedCmsEntities That FAILED', rawData);
            return null;
        }
    }
    private _linkedCmsEntitiesQuery(runTemplateId: string): string {
        return `
        query getLinkedCmsEntities {
            runTemplateById (id:"${runTemplateId}") {
                id,
                name,
                customer{
                    id,
                    name,
                    billingGroup
                },
                template{
                    id,
                    name
                }
                cmsEntities {
                  id,
                  name,
                  type,
                  cmsContentType,
                  publicId,
                  useOffline,
                  specificVersion,
                  version,
                  length,
                  isDraft,
                  lockLatestVersion
                },
                useCms
            }
        }`;
    }
    //#endregion

    public lowerCaseObjectKeys<T>(object: any) {
        // Helper function for detection objects
        const isObject = (obj) =>
            Object.prototype.toString.call(obj) === '[object Object]';

        // The entry point for recursion, iterates and maps object properties
        const lowerCaseObjectKeys = (obj) =>
            Object.fromEntries(Object.entries(obj).map(objectKeyMapper));

        // Converts keys to lowercase, detects object values
        // and sends them off for further conversion
        const objectKeyMapper = ([key, val]) => [
            key.charAt(0).toLowerCase() + key.slice(1),
            isObject(val) ? lowerCaseObjectKeys(val) : val,
        ];

        return lowerCaseObjectKeys(object) as T;
    }

}