<div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Customer</th>
        <th>Run</th>
        <th>Run Recipient</th>
        <!-- <th>External Id</th> -->
        <th>Sent To</th>
        <th>Sent Date</th>
        <th>Last Updated</th>
        <th class="text-center">Is Sent</th>
        <th class="text-center">Is Delivered</th>
        <th class="text-right">Mccmnc Code</th>
        <th class="text-right">Units</th>
        <th>Result Message</th>
        <th class="text-right">Cost</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of (reportDetails$ | async)">
        <td><a [routerLink]="'../../../admin/customers/' + field?.customer.id">{{ field?.customer.name }}</a></td>
        <td><a [routerLink]="'../../../run/' + field?.run.id">{{ field?.run.name }}</a></td>
        <td>{{ field?.runRecipient.name }}</td>
        <!-- <td>{{ field?.RunRecipient.ExternalId }}</td> -->
        <td>{{ field?.sendTo }}</td>
        <td>{{ field?.sendDate.split("T")[0] }} ({{ field?.sendDate.split("T")[1].split(".")[0]  }})</td>
        <td>{{ field?.lastUpdated.split("T")[0] }} ({{ field?.lastUpdated.split("T")[1].split(".")[0]  }})</td>
        <td class="text-center">{{ field?.isSent ? '✔️' : '❌'}}</td>
        <td class="text-center">{{ field?.isDelivered ? '✔️' : '❌' }}</td>
        <td class="text-right">{{ field?.mccmnc }}</td>
        <td class="text-right">{{ field?.units }}</td>
        <td>{{ field?.resultMessage }}</td>
        <td class="text-right">{{ field?.cost }}</td>
      </tr>
    </tbody>
  </table>

  <div class="row action-buttons">
    <div>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasPreviousPage" nbButton (click)="pageReport('previous')">Previous</button>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasNextPage"  nbButton (click)="pageReport('next')">Next</button>
    </div>
    <p>{{(reportDetailsPaging$ | async)?.totalCount}} Items</p>
  </div>
</div>