import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService, RunTemplateService, TemplateService } from '@InfoSlips/iiab-api';
import { CmsEntity } from '@InfoSlips/models';
import { CmsService } from 'libs/cms/src/lib/services/cms.service';
import { CrudBaseService } from 'libs/iiab-api/src/lib/services/base/crud-base.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'iiab-admin-templates-container',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesContainer implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private runTemplateService: RunTemplateService,
    private templateService: TemplateService,
    private bookmarkService: BookmarkService,
    private router: Router, 
    private cmsService: CmsService,
    private crudBaseService: CrudBaseService,
  ) { }

  template$: Observable<any>;
  template;
  isRunTemplate = false;
  isNew = false;

  async ngOnInit() {
    const selected = this.route.snapshot.paramMap.get('id');

    if(window.location.href.includes('runtemplates')){
      this.isRunTemplate = true;
      this.template$ = this.runTemplateService.selectedRunTemplate$;
    } else {
      this.template$ = this.templateService.selectedTemplate$;
    }

    if(selected){
      this.bookmarkService.isStar(this.router.url);
      
      if(this.isRunTemplate){
        this.runTemplateService.getRunTemplate(selected).subscribe((res: any) => {
          this.template = res;
          this.runTemplateService.selectRunTemplate(res);

          this.formatEntities(res.CmsEntities);

          if(this.template.AllowedActions === null){
            this.template.AllowedActions = {
              DownloadIfs: true,
              DownloadPdf: true,
              Print: true
            }
          }

          if(this.template.ExportTemplate === null){
            this.template.ExportTemplate = {
                "Type": 0,
                "Host": null,
                "Port": null,
                "UserName": null,
                "Password": null,
                "Run": null,
                "Customer": null,
                "ControlFileLineRazorTemplate": null,
                "IsEnabled": false
            }
          }

          if(this.template.AdditionalLocationRunReport === null){
            this.template.AdditionalLocationRunReport = {
              "AdditionalType": null,
              "sFtpUrl": null,
              "sFtpUsername": null,
              "sFtpPassword": null,
              "ApiUrl": null
            }
          }

          if(this.template.Signatures === null){
            this.template.Signatures = {
              "IsEnabled": false,
              "RequireLocation": false,
              "AdditionalCaptureFields": [],
              "RequiredSignatures": []
            }
          }
        });
      } else {
        this.templateService.getTemplate(selected);
      }
      this.template$.subscribe(res => this.template = res);
    } else {

      this.isNew = true;

      if(this.isRunTemplate){
        this.templateService.selectedTemplate$.subscribe(async (res: any) => {
          this.template = await this.crudBaseService.getDefault<any>(2);

          if(res){
            this.template.Template = res.Template;
            this.template.Customer = res.Customer;
            this.template.UseTemplate = true;
          }

          if(this.template.TemplateSource === null || !('TemplateSource' in this.template)){
            this.template.TemplateSource = {
              "TemplateRepositoryType": 2,
              "Project": null,
              "Group": null,
              "Branch": null,
              "Folder": null,
              "Type": null
            }
          }

          if(this.template.Signatures === null || !('Signatures' in this.template)){
            this.template.Signatures = {
              "IsEnabled": false,
              "RequireLocation": false,
              "AdditionalCaptureFields": [],
              "RequiredSignatures": []
            }
          }
        })
      } else {
        this.template = await this.crudBaseService.getDefault<any>(1);
          
        if(this.template.TemplateSource === null || !('TemplateSource' in this.template)){
          this.template.TemplateSource = {
            "TemplateRepositoryType": 2,
            "Project": null,
            "Group": null,
            "Branch": null,
            "Folder": null,
            "Type": null
          }
        }
      }
    }
  }

  formatEntities(entities: CmsEntity[]){
    const entityArray = [];
    entities.forEach(entity => {
      const keys = Object.entries(entity);
      const capsKeys = keys.map((key) => [key[0][0].toLowerCase() + key[0].slice(1), key[1]]);
      entityArray.push(Object.fromEntries(capsKeys))
    });

    entityArray.map(entity => {
      entity.display = entity.name;
    })
  
    this.cmsService.cmsEntitiesSubject.next(entityArray);
  }
}
