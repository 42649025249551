import { Injectable } from '@angular/core';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { PreProcessor } from '../models/pre-processor';

@Injectable({
  providedIn: 'root'
})
export class ProcessorService {

  constructor(
    private apiBaseService: ApiBaseService,

  ) {}

  postProcessorAssemblies(){
    return this.apiBaseService.executeGet<PreProcessor[]>(`postProcessorAssemblies`, "Get Post-Processors");
  }
}
