import { IiabId } from './iiab-reference';

export interface RunProgressCounters extends IiabId{
    id:string;
    instance:{
        progressCounters:ProgressCounters[];
    }
}

export interface ProgressCounters {
    key:string;
    value: ProgressCounter;
   
}

export interface ProgressCounter{
    status:string;
    counter:number;
    expectedCount:number;
    name:string;
    lastException:string
}