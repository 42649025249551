<ng-container *ngIf="isEnabled">
    <div class="row">
        <div class="col-md-6" [ngClass]="isNew ? 'col-md-12' : ''">
            <nb-card class="input-box">
                <nb-card-header>
                    Change Pre-Processor
                </nb-card-header>
                <nb-card-body class="padded">
                    <form [formGroup]="updateProcessorForm">
                        <table width="100%" class="vertical">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>
                                    <input formControlName="Name"
                                        #autoInput
                                        nbInput
                                        fullWidth
                                        type="text"
                                        (input)="onPreProcessorChange()"
                                        [nbAutocomplete]="preProcessorModel" 
                                        filled 
                                        [status]="currentPreProcessor.Name !== updateProcessorForm.value.Name ? 'info' : 'basic'"
                                    />
                                
                                    <nb-autocomplete #preProcessorModel>
                                        <nb-option *ngFor="let option of preProcessors$ | async" (click)="selectProcessor(option)">
                                        {{ option.name }}
                                        </nb-option>
                                    </nb-autocomplete>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Class</th>
                                    <td>
                                        <nb-select placeholder="Select Class" formGroupName="ClassData" fullWidth filled [status]="currentPreProcessor?.ClassData?.ClassName !== updateProcessorForm.value.ClassData.ClassName ? 'info' : 'basic'" (selectedChange)="selectClass($event)"> 
                                            <nb-option *ngFor="let option of updateProcessorForm.value.Classes" [value]="option" >
                                                {{ option.ClassName }}
                                            </nb-option>
                                        </nb-select>
                                    </td>
                                </tr>
                                <ng-container formGroupName="ClassData">
                                    <tr>
                                        <th>Non-Collectable</th>
                                        <td>
                                            <nb-checkbox status="basic" formControlName="AssemblyIsNotCollectable" (checkedChange)="assemblyNonCollectable($event)" [status]="currentPreProcessor?.ClassData?.AssemblyIsNotCollectable !== updateProcessorForm.value.ClassData.AssemblyIsNotCollectable ? 'info' : 'basic'">
                                                <span [style]="currentPreProcessor?.ClassData?.AssemblyIsNotCollectable !== updateProcessorForm.value.ClassData.AssemblyIsNotCollectable ? 'color: #00b3ff' : ''">Is Assembly Non-Collectable?</span>
                                            </nb-checkbox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Config</th>
                                        <td>
                                            <nb-checkbox status="basic" formControlName="ExpectConfiguration" (checkedChange)="enableConfig($event)" [style]="currentPreProcessor?.ClassData?.ExpectConfiguration !== updateProcessorForm.value.ClassData.ExpectConfiguration ? 'info' : 'basic'">
                                                <span [style]="currentPreProcessor?.ClassData?.ExpectConfiguration !== updateProcessorForm.value.ClassData.ExpectConfiguration ? 'color: #00b3ff' : ''">Auto upload default configuration file on run creation?</span>
                                            </nb-checkbox>
                                        </td>
                                    </tr>
                                </ng-container>
    
                            </tbody>
                            <tfoot *ngIf="updateProcessorForm.value.ClassData.ExpectConfiguration && updateProcessorForm.value.ClassData.Id">
                                <tr>
                                    <th>
                                        Default-Configuration
                                    </th>
                                    <td colspan="2">
                                        <button nbButton (click)="open(dialog, 'new')" status="basic">Open New Config</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                    
                </nb-card-body>
            </nb-card>
        </div>
        <div class="col-md-6" *ngIf="currentPreProcessor.Name">
            <nb-card class="input-box">
                <nb-card-header>
                    Current Pre-Processor

                    <nb-icon (click)="downloadProcessor()" pack="eva" icon="download" status="basic"></nb-icon>
                </nb-card-header>
                <nb-card-body class="padded">
                    <table width="100%" class="vertical">
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Current</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{{ currentPreProcessor?.Name }}</td>
                            </tr>
                            <tr>
                                <th>Class</th>
                                <td>{{ currentPreProcessor?.ClassData?.ClassName }}</td>
                            </tr>
                            <tr>
                                <th>Non-Collectable</th>
                                <td>
                                    <nb-checkbox status="basic" disabled [(ngModel)]="currentPreProcessor.ClassData.AssemblyIsNotCollectable">Is Assembly Non-Collectable?</nb-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <th>Config</th>
                                <td>
                                    <nb-checkbox status="basic" disabled [(ngModel)]="currentPreProcessorConfig">Auto upload default configuration file on run creation?</nb-checkbox>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="currentPreProcessorConfig">
                            <tr>
                                <th>
                                    Default-Configuration
                                </th>
                                <td colspan="2">
                                    <button nbButton (click)="open(dialog, 'current')" status="primary" outline>Edit Config</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="col-md-6" [ngClass]="isNew ? 'col-md-12': ''">
            <nb-card class="input-box">
                <nb-card-header>
                    New Run Files
                  </nb-card-header>
                <nb-card-body class="padded">
                    <table width="100%" style="margin-bottom: 50px;" *ngIf="updateProcessorForm.value.ClassData.RequiredFiles.length > 0">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>File Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let runFile of updateProcessorForm.value.ClassData.RequiredFiles">
                                <td>{{ runFile.Description }}<span status="danger" *ngIf="runFile.IsRequired">*</span></td>
                                <td>{{ runFile.FileName }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p *ngIf="updateProcessorForm.value.ClassData.RequiredFiles.length === 0">
                        Please select a Pre-Processor and Class to view Run Files.
                    </p>
                </nb-card-body>
            </nb-card>
        </div>

        <div class="col-md-6" *ngIf="!isNew">
            <nb-card class="input-box">
                <nb-card-header>
                    Current Run Files
                  </nb-card-header>
                <nb-card-body class="padded">
                    <table width="100%" style="margin-bottom: 50px;">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>File Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let runFile of currentPreProcessor.ClassData.RequiredFiles">
                                <td>{{ runFile.Description }}<span status="danger" *ngIf="runFile.IsRequired">*</span></td>
                                <td>{{ runFile.FileName }}</td>
                            </tr>
                        </tbody>
                    </table>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
    
    <ng-template #dialog let-ref="dialogRef" let-data>
        <nb-card class="editor">
          <nb-card-header>Default Configuration {{data.context}}</nb-card-header>
          <nb-card-body>
            <ngx-monaco-editor *ngIf="data.context === 'current'" class="monaco-editor" [options]="monacoConfig.editorOptions" [(ngModel)]="data.config"></ngx-monaco-editor>
            <ngx-monaco-editor *ngIf="data.context === 'new'" class="monaco-editor" [options]="monacoConfig.editorOptions" [(ngModel)]="updateProcessorForm.value.ClassData.ConfigurationSample"></ngx-monaco-editor>
          </nb-card-body>
          <nb-card-footer class="dialogFooter">
            <button fullWidth nbButton (click)="ref.close()">Cancel</button>
            <button fullWidth *ngIf="data.context !== 'new'" nbButton status="primary" (click)="applyConfig(ref, data.config)">Apply</button>
            <button fullWidth *ngIf="data.context === 'new'" nbButton status="primary" (click)="saveConfig(ref)">Save</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>
</ng-container>
