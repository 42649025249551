import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ifs-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent {

  @Input() value: string;

  constructor(
    private toastr: ToastrService,
  ) { }

  copy(value){
    navigator.clipboard.writeText(value);
    this.toastr.info('Copied to Clipboard!');
  }

}
