import { Component, OnInit, ViewChild, EventEmitter, Input, AfterViewInit, Output } from '@angular/core';
import { RunsService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { RunFilterService } from '../../services/run-filter.service';

@Component({
  selector: 'ifs-run-filter',
  templateUrl: './run-filter.component.html',
  styleUrls: ['./run-filter.component.scss']
})
export class RunFilterComponent implements OnInit, AfterViewInit {
  @Input() type = 'default';
  @ViewChild('templateAutoInput') templateAutoInput;
  @ViewChild('customerAutoInput') customerAutoInput;

  @Output("toggleFilter") toggleFilter: EventEmitter<any> = new EventEmitter();

  constructor(
    private runService: RunsService,
    private runFilterService: RunFilterService
  ) { }

  loggedInUser: string = null;
  
  applyFilter = new EventEmitter<any>();

  templateOptions;
  customerOptions;

  ngOnInit() {
    this.runService.runTemplates.Items$().subscribe((data: any)=> {
      this.templateOptions = data;
    });
    this.runService.runCustomers.Items$().subscribe((data: any)=> {
      this.customerOptions = data;
    });
  }

  ngAfterViewInit(): void {
    this.runService.runTemplates.ClearItems$();
    this.runService.runCustomers.ClearItems$();
  }

  get selectedCustomer(): IiabIdName {
    return this.runFilterService.selectedCustomer;
  }
  set selectedCustomer(input: any) {
    this.runFilterService.selectedCustomer = input;
  }

  get selectedTemplate(): IiabIdName {
    return this.runFilterService.selectedTemplate;
  }
  set selectedTemplate(input: any) {
    this.runFilterService.selectedTemplate = input;
  }

  get selectedRunStatus(): string[] {
    return this.runFilterService.selectedRunStatus;
  }
  set selectedRunStatus(input: string[]) {
    this.runFilterService.selectedRunStatus = input;
  }

  get selectedRunState(): string {
    return this.runFilterService.selectedRunState;
  }
  set selectedRunState(input: string) {
    this.runFilterService.selectedRunState = input;
  }

  get dateRangeStart(): any {
    return this.runFilterService.dateRange.start;
  }
  set dateRangeStart(input: any) {
    this.runFilterService.dateRange.start = input;
  }

  get dateRangeEnd(): any {
    return this.runFilterService.dateRange.end;
  }
  set dateRangeEnd(input: any) {
    this.runFilterService.dateRange.end = input;
  }

  runStatusOptions():Array<string>{
    return this.runService.runStatusOptions;
  }
  runStateOptions():Array<string>{
    return this.runService.runStateOptions;
  }
    
  onChange(context: string) {
    if(context === "template"){
      if (!this.templateAutoInput.nativeElement.value) this.selectedChange('template');
      this.runService.runTemplates.loadPagedItems(this.getAvailableTemplateFilter());
      this.runService.runTemplates.Items$().subscribe(res => this.templateOptions = res);
    }
    else {
      if (!this.customerAutoInput.nativeElement.value) this.selectedChange('customer');
      this.runService.runCustomers.loadPagedItems(this.customerAutoInput.nativeElement.value ? {
        name: {
          like: this.customerAutoInput.nativeElement.value
        }
      } : null);
      this.runService.runCustomers.Items$().subscribe(res => this.customerOptions = res);
    }
  }
    
  selectedChange(context: string) {
    if(context === "template")
      this.runService.runTemplates.Items$();
    else
      this.runService.runCustomers.Items$(); 
  }

  activateFilter(){
    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadPagedItems(Object.keys(this.runService.runsFilterObject).length !== 0 ? this.runService.runsFilterObject : null);
  }

  clear(){
    this.dateRangeStart =  null;
    this.dateRangeEnd = null;
    this.selectedCustomer = null;
    this.customerAutoInput.nativeElement.value = null;
    this.selectedTemplate = null;
    this.templateAutoInput.nativeElement.value = null;
    this.selectedRunStatus = [];
    this.selectedRunState = this.runService.runStateOptions[0];
    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadPagedItems(null);
    this.runFilterService.filterObjectSubject.next({});
    
  }

  getIiabName(value: any) {
    return value.name ? value.name : value;
  }

  private getAvailableTemplateFilter() {
    if (this.templateAutoInput.nativeElement.value) {
      if (this.selectedCustomer?.id) {
        return {
          name: {
            like: this.templateAutoInput.nativeElement.value
          },
          customer: {id: {eq: this.selectedCustomer.id}}
        }
      }
      else {
        return {
          name: {
            like: this.templateAutoInput.nativeElement.value
          }
        }
      }
    } else return null
  }
}