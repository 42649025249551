<div class="general-page-container">
  <div class="general-page-header">
    <div class="title-bar row heading-bar" *ngIf="selectedReport">
      <div class="col-md-12">
        <h4>CREATE {{selectedReport?.name}}</h4>
      </div>
    </div>
    <br style="clear: both;"/>
    <div class="row" >
      <div class="col-md-3 p-10">
          <input fullWidth type="text" nbInput placeholder="Filter by queue name" [(ngModel)]="filterModels.filterByQueueName">
      </div>
  
      <div class="col-md-3 p-10">
          <input fullWidth type="text" nbInput placeholder="Filter by machine name" [(ngModel)]="filterModels.filterByMachineName">
      </div>
  
      <div class="col-md-3 p-10">
          <input fullWidth type="text" nbInput placeholder="Filter by IP Address" [(ngModel)]="filterModels.filterByIpAddress">
      </div>
  
      <div class="col-md-3 p-10">
          <input fullWidth type="text" nbInput placeholder="Filter by threads" [(ngModel)]="filterModels.filterByThreads">
      </div>
  
      <div class="col-md-3 p-10">
          <nb-select fullWidth placeholder="All activities" [(selected)]="filterModels.filterByStatus" [(ngModel)]="filterModels.filterByStatus">
              <nb-option value="">All activities</nb-option>
              <nb-option value="EndTime">Completed activities</nb-option>
              <nb-option value="EndTime">Uncompleted activities</nb-option>
              <nb-option value="Error">Failed activities</nb-option>
          </nb-select>
      </div>
  
      <div class="col-md-3 p-10">
          <input fullWidth type="text" nbInput placeholder="Filter by time (ms) larger than" [(ngModel)]="filterModels.filterByTime">
      </div>
  
      <div class="col-md-3 p-10">
          <input fullWidth type="text" nbInput placeholder="Filter by message" [(ngModel)]="filterModels.filterByMessage">
      </div>
    </div>
    
    <button nbButton status="primary" (click)="search()">Search</button>
    <button nbButton status="primary" outline (click)="download()">Download</button>
  </div>
  <div class="router">
    <table width="100%">
      <thead>
        <tr>
          <th>Queue</th>
          <th>Start</th>
          <th>End</th>
          <th>Time Taken</th>
          <th>Machine</th>
          <th>IP</th>
          <th>Threads</th>
          <th>Error</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of (reportDetails$ | async)?.Items | paginate: { itemsPerPage: 10, currentPage: pageNumber, totalItems: (reportDetails$ | async)?.Count }">
          <!-- <td>{{ field?.Customer.Name }}</td>
          <td>{{ field?.Created }}</td>
          <td>{{ field?.RunTemplate.Id }}</td>
          <td>{{ field?.AdhocId }}</td>
          <td>{{ field?.SendLog.SendBy }}</td>
          <td>{{ field?.SendLog.SendTo }}</td>
          <td>{{ field?.IsDelivered }}</td>
          <td>{{ field?.IsDelivered }}</td>
          <td>{{ field?.IsDelivered }}</td> -->
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">
              <pagination-controls (pageChange)="pageReport($event)"></pagination-controls>
          </td>
          <td colspan="1">{{(reportDetails$ | async)?.Count}} Items</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>