import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, debounceTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@InfoSlips/env';

interface Runs {
    runs: any;
    loading: boolean;
    error: string;
  }

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  constructor(private apollo: Apollo,
    private http: HttpClient,
    private environment: EnvironmentService,) {}

  loading = false;
  error = '';

  getRuns() {
    const FeedQuery = gql`
    query Runs { 
      runs(first: 100, order: {lastUpdated: DESC}) {
        totalCount
        nodes{
          name
          id
          runTemplate{
            name
          }
          isTrial
          created
          createdBy
          customer{
            name
          }
          lastUpdated
        }
      }
    }
  `;

    return this.apollo
      .watchQuery<Runs>({ query: FeedQuery })
      .valueChanges.pipe(
        map(({ data }) => {
          this.loading = data.loading;
          this.error = data.error;
          return data.runs.nodes;
        })
      );
  }

  filterRuns(searchPhrase){
    const searchQuery = gql`
    query Runs { 
      runs(where: {name_contains: "${searchPhrase}"}) {
        totalCount
        nodes{
          name
          id
          runTemplate{
            name
          }
          isTrial
          created
          createdBy
          customer{
            name
          }
          lastUpdated
        }
      }
    }
  `

  return this.apollo
      .watchQuery<Runs>({ query: searchQuery })
      .valueChanges.pipe(
        map(({ data }) => {
          this.loading = data.loading;
          this.error = data.error;
          return data.runs.nodes;
        }),
        debounceTime(2000)
      );
  }

  // TODO: Make dynamic limit
  getRunTemplates(){
    const RunTemplateQuery = gql`
    query RunTemplates { 
      runTemplates(first: 500) {
        totalCount
        nodes{
          customer{
            name
          }
          id
          name
        }
      }
    }
  `;

    return this.apollo
      .watchQuery<any>({ query: RunTemplateQuery })
      .valueChanges.pipe(
        map(({ data }) => {
          return data;
        })
      );
  }
}
