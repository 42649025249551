import { Component, Input, OnInit } from '@angular/core';
import { RunActionsService, SelectedRunService } from '@InfoSlips/iiab-api';
import { ProgressCounterModel, ReprocessStageCodes, ReProcessStagesModel, RunModel } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ifs-run-stage-card',
  templateUrl: './run-stage-card.component.html',
  styleUrls: ['./run-stage-card.component.scss']
})
export class RunStageCardComponent implements OnInit {
  @Input() selectedRunService: SelectedRunService;
  @Input() stage: ProgressCounterModel;

  reProcessOptionSelected:number|null=0;
  selectedRun:RunModel;
  cardIsFlipped:boolean=false;

  constructor(
    private runActionsService:RunActionsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.selectedRunService.selectedRun$.subscribe(run=>this.selectedRun=run);
  }

  reProcess(){
    this.toastr.info(`Re-Process ${this.stage.Name} - ${this.reProcessOptionSelected}`);
    const model:ReProcessStagesModel={
      SendStage:this.getReProcessOption(this.stage.Name, "Send"),
      PostProcessingStage:this.getReProcessOption(this.stage.Name, "PostProcess"),
      ExportStage: this.getReProcessOption(this.stage.Name, "Export"),
      RunId:this.selectedRun.Id      
    };

    this.runActionsService.reProcessStage(model, this.stage.Name);
  }

  reCreateControlFile(){
    this.runActionsService.reCreateControlFile(this.selectedRun.Id);
  }

  downloadControlFile(){
    this.runActionsService.downloadControlFile(this.selectedRun.Id);
  }

  compareWith(v1,v2){
    return parseInt(v1)==parseInt(v2);    
  }

  getReProcessOption(selectedStage:string, stageProp:string):ReprocessStageCodes{
    if(selectedStage!==stageProp)
      return ReprocessStageCodes.Skip;
    return this.reProcessOptionSelected;
  }

  stageStatus(stage){
    const runStatusCodes = ["Created", "Scheduled", "Processing", "Complete", "Failed", "Cancelled", "Processed", "Resetting", "Expired"]
    return runStatusCodes[stage.Status]
  }

  toggleCard(){
    this.cardIsFlipped = !this.cardIsFlipped;
  }
}
