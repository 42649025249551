<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <div class="general-page-container fit-absolute">
        <div class="general-page-header">
            <p class="password-warning" *ngIf="(auth.weakPassword$ | async)?.weakPassword">
                {{ (auth.weakPassword$ | async)?.weakPasswordError }}
            </p>
            <div class="p-10" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                <div class="form-block">
                    <div class="form-group">
                        <label class="label">Current Password</label><br/>
                        <input nbInput fullWidth fieldSize="medium" type="password" formControlName="currentPassword" required placeholder="current password" />
                    </div>
                
                    <div class="form-group">
                    <label class="label">New Password</label><br/>
                        <input nbInput fullWidth fieldSize="medium" type="password" formControlName="password" required placeholder="password"/>
                    </div>
                
                    <div class="form-group">
                    <label class="label">Confirm Password</label><br/>
                        <input nbInput fullWidth fieldSize="medium" type="password" formControlName="confirmPassword" required placeholder="password"/>
                    </div>
                    <p class="error" *ngIf="form.confirmPassword?.errors?.PasswordMatch">* Passwords must match!</p>
                </div>
            </div>
        </div>
        <div class="general-page-body">
          <div class="password-text">
            <p><b>New Password Requirements:</b></p>
            <ol>
                <li *ngFor="let rule of passwordRules">
                    <p>{{ rule.label }} <b class="password-rule">{{ rule.value }}</b></p>
                </li>
                <li>
                    <p>At least <b class="password-rule">10</b> characters in length</p>
                </li>
            </ol>  
          </div>
        </div>
        <div class="general-page-footer">
            <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()" type="button">
                Cancel
            </button>
            <button class="col-md-6" size="small" nbButton status="primary" [disabled]="changePasswordForm.invalid" type="submit">
                Save
            </button>
        </div>
    </div>
</form>