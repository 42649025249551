<form
class="login"
>
    <div class="row">
      <div class="col-md-12">
        <p class="pull-left title" data-e2e="cardTitle">
          {{ verified ? "Verified Successfully": "Verification Failed"}}
        </p>
      </div>
    </div>
    <div class="form-body row">
      <div class="col-md-12">
        {{ verified ? "Redirecting to profile page in 3 seconds.": "Please log in and open the link again, redirecting to login screen in 10 seconds."}}
      </div>
  
    <div class="form-footer">
      <div class="row">
        <div class="col-md-12">
          <!-- <button [nbSpinner]="loading" nbSpinnerStatus="info" [disabled]="registerConfirmForm.invalid" nbButton fullWidth
            status="primary" data-e2e="submit" type="submit">
            Sign in
          </button> -->
    
        </div>
      </div>
    </div>
    </div>
  </form>
 