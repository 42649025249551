import { IiabIdName } from './iiab-reference';

export interface CustomerRunStatistics extends AggregatedPeriodRunStats {
    id:string;
    customer: IiabIdName;
}

export interface AggregatedRunStats extends StatsCounters {
    periods: AggregatedPeriodRunStats[]
}

export interface AggregatedPeriodRunStats extends StatsCounters{
    billingPeriod:string
}

export interface StatsCounters{
    totalCompiledCount: number;
    exportSuccessCount: number;
    emailSuccessCount: number;
    emailFailedCount: number;
    emailTotalCount: number;
}

