import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'cms-dashboard-container',
  templateUrl: './dashboard.container.html',
  styleUrls: ['./dashboard.container.scss']
})
export class DashboardContainer implements OnInit {
  showDetails$ = this.cmsService.showDetails$;

  isFullTextSearch:boolean;
  isFilterExpanded = false;

  constructor(
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.cmsService.showDetailsSubject.next(false);
  }
}
