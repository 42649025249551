import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TestingRestrictionsService } from '@InfoSlips/iiab-api';
import { DomainModel, DomainReference } from '@InfoSlips/models';

@Component({
  selector: 'ifs-admin-testing-restrictions',
  templateUrl: './testing-restrictions.component.html',
  styleUrls: ['./testing-restrictions.component.scss']
})
export class TestingRestrictionsComponent implements OnInit {
  
  domainId = this.route.snapshot.paramMap.get('id');

  currentDomain: DomainModel;

  constructor(
    private route: ActivatedRoute,
    private testingRestrictionService: TestingRestrictionsService
  ) {     
    this.currentDomain = {
      EmailAddress : null,
      Domain : null,
      AllowAllOnOnDomain : null,
      Id : null,
      Created : null,
      CreatedBy : null,
      LastUpdated : null,
      LastUpdatedBy : null
    };
  }

  async ngOnInit() {
    const domainById = await this.testingRestrictionService.getDomainById(this.domainId);

    const domainModel = {
      EmailAddress: domainById?.emailAddress,
      Domain : domainById?.domain,
      AllowAllOnOnDomain : domainById?.allowAllOnOnDomain,
      Id : domainById?.id,
      Created : domainById?.created,
      CreatedBy : domainById?.createdBy,
      LastUpdated : domainById?.lastUpdated,
      LastUpdatedBy : domainById?.lastUpdatedBy
    };
    this.testingRestrictionService.selectDomain(domainModel);
    this.testingRestrictionService.selectedDomain$.subscribe(res => this.currentDomain = res);
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  updateDomain() {
    this.testingRestrictionService.updateDomain(this.currentDomain);
  }

  createDomain() {
    this.testingRestrictionService.createDomain(this.currentDomain);
  }

  removeEmailAddress(index: number) {
    this.currentDomain.EmailAddress.splice(index, 1);
  }

  addEmailAddress() {
    this.currentDomain.EmailAddress = [...(this.currentDomain?.EmailAddress ?? []), ...[""]];
  }
}