import { Component, OnInit } from '@angular/core';
import { CmsService } from './../../services/cms.service';

@Component({
  selector: 'entity-detail-container',
  templateUrl: './entity-detail.container.html',
  styleUrls: ['./entity-detail.container.scss']
})
export class EntityDetailContainer implements OnInit {

  selectedEntity$ = this.cmsService.selectedEntity$;

  constructor(
    private cmsService: CmsService,
    ) {
  }

  ngOnInit() {}
}
