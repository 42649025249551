import { Component, EventEmitter, Input, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '@InfoSlips/iiab-api';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { IiabIdName } from '@InfoSlips/models';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard-companies-form',
  templateUrl: './companies-form.component.html',
  styleUrls: ['./companies-form.component.scss'],
  providers: [CustomerService]
})
export class CompaniesFormComponent implements OnInit {

  @Input() form: string;
  @Output() cancelForm: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('autoInput') input;

  constructor(
    private customerService: CustomerService,
    private wizardService: WizardService,
    private router: Router, 
  ) { }

  runCustomers$: Observable<IiabIdName[]>;
  filteredOptions$: Observable<any[]>;
  searchPhrase: string;
  selectedCustomer: string;
  selectedCustomerId;
  filter = false;
  filterObject: {};
  deleteConfirm = false;
  revealed = false;

  ngOnInit(): void {
    this.customerService.customers.loadPagedItems(this.loadSearchHistory());

    this.filteredOptions$ = this.customerService.customers.Items$().pipe(
      exhaustMap((res) => of(res))
    )
  }

  onChange() {
    this.filterObject = this.input.nativeElement.value.length === 24 ? { id: { eq: this.input.nativeElement.value }} : { name: { like:  this.input.nativeElement.value }};
  }

  searchCustomers() {    
    this.filteredOptions$ = of([{ id: "Loading..", name: "Loading.."}]);

    this.customerService.customers.loadPagedItems(this.input.nativeElement.value ? this.filterObject : null);
    this.filteredOptions$ = this.customerService.customers.Items$().pipe(exhaustMap((res) => of(res)));
    this.wizardService.addSearchHistory("Customers", this.filterObject)
  }
  
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.searchCustomers();
  }

  onSelectionChange($event) {
    if($event){
      this.filteredOptions$.subscribe((res: any) => {
        this.selectedCustomerId = res.find(item => item.name === this.input.nativeElement.value)?.id;
        this.filterObject = { id: { eq: this.selectedCustomerId }};
      })
    }
  }

  toggleView() { 
    this.revealed = !this.revealed; 
  }

  toggleFilter(){
    this.filter = !this.filter
  }

  selectCustomer(customer){
    this.router.navigateByUrl('admin/customers/'+customer.id);
  }

  cancel(){
    this.wizardService.setState(0);
    this.wizardService.removeSearchHistory("Customers")
  }

  delete(id: string, confirmed: boolean){
    if(confirmed){
      this.customerService.delete(id);
    }
    this.deleteConfirm = !this.deleteConfirm;
  }
  
  hasKeys(obj: any): boolean {
    return obj != null && Object.keys(obj).length > 0;
  }

  private loadSearchHistory() {
    if (this.wizardService.wizardSearchHistory['Customers']) {
      const searchHistory = this.wizardService.wizardSearchHistory['Customers']
      
      if (searchHistory.name != null) this.selectedCustomer = searchHistory.name.like;
      if (searchHistory.id != null) this.selectedCustomerId = searchHistory.id.eq;
      this.filterObject = searchHistory;
      return searchHistory;
    } else return null;
  }
}
