import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { RecipientService, RunRecipientService } from '@InfoSlips/iiab-api';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoggedInModel, RecipientOutputChannel, RecipientReference, RecipientReferenceHistory, recipientToolTip, RunRecipientReference } from '@InfoSlips/models';
import { AuthService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-admin-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit {

  isRecipient = false;
  id = this.route.snapshot.paramMap.get('id');
  recipientId = this.route.snapshot.queryParamMap.get('recipients');
  outputChannelsRetrieved = false;
  outputChannels: RecipientReferenceHistory[] = [];

  defaultEmailSet = true;
  defaultEmail = null;
  defaultMobileSet = true;
  defaultMobile = null;

  recipientDataForm = this.fb.group({
    id: [null],
    externalId: [null],
    name: ['', Validators.required],
    email: ['', Validators.required],
    mobile: [''],
    publicId: [''],
    onlineId:[''],
    puk: [''],
    customer: this.fb.group({
      name: [''],
      id: [''],
    }),
    run: this.fb.group({
      name: [''],
      id: [''],
    }),
    runTemplate: this.fb.group({
      name: [''],
      id: ['']
    })
  });

  private runRecipients = new Set<string>();
  dialogRef;

  resendOptionsForm = this.fb.group({
    Runs: [],
    ToAddress: null,
    ResendEmail: true,
    ResendMobile: true,
    ForwardPukLess: [false],
    ToMobile: null,
    UpdateRecipient: false,
    UpdateRecipientMobile: false
  });

  isSystemAdmin = false;
  loggedInUser:LoggedInModel;

  constructor(
    private fb: FormBuilder,
    private runRecipientService: RunRecipientService,
    private recipientService: RecipientService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private dialogService: NbDialogService,
    private authService:AuthService 
  ) { 

    authService.isSystemAdmin$.subscribe(val=>this.isSystemAdmin=val);
    authService.loggedInUser$.subscribe(val=>this.loggedInUser=val);
  }

  ngOnInit(): void {
    //@ts-ignore
    this.route.url.value[0].path === 'recipients' ? this.isRecipient = true : undefined;
    
    if(!this.isRecipient){
      this.runRecipientService.getRunRecipientById(this.id, this.recipientId).subscribe((runRecipientReference: RunRecipientReference) => {
        this.recipientDataForm.patchValue(runRecipientReference);
        this.getOutputChannels();
      })
    } else {
      this.recipientService.getRecipientById(this.id).subscribe((recipientReference: RecipientReference) => {
        this.recipientDataForm.patchValue(recipientReference);
        this.outputChannels = this.addToolTip(recipientReference.history);

        if (recipientReference.email) this.defaultEmail = recipientReference.email;
        if (recipientReference.mobile) this.defaultMobile = recipientReference.mobile;
      })
    }
   
  }

  getOutputChannels(){
    if(this.outputChannelsRetrieved)
      return;

    this.runRecipientService.getOutputChannels(this.recipientDataForm.value.id, this.recipientDataForm.value.run.id).subscribe(res => {
      this.outputChannelsRetrieved = true;
      this.outputChannels = this.addToolTip(res);
    })
  }

  copyToClipboard(formControlName, formGroupName){
    const value = formGroupName ? this.recipientDataForm.value[formGroupName][formControlName] : this.recipientDataForm.value[formControlName];
    navigator.clipboard.writeText(value);
    this.toastr.info('Copied to Clipboard!');
  }

  downloadIfsSourceData(){
    this.toastr.info('Download started!','IFS File Data');
    this.runRecipientService.ifsFileData(this.recipientDataForm.value, 0);
  }

  downloadIfs(){
    this.toastr.info('Download started!','IFS File');
    this.runRecipientService.downloadIfsFile(this.recipientDataForm.value, 0);
  }

  resendInfoSlip(){
    this.runRecipientService.resendInfoSlip(this.recipientDataForm.value.id);
  }

  open(dialog: TemplateRef<any>) {
    // this.resendOptionsForm.patchValue('Runs', field.id);
    this.dialogService.open(dialog);
  }

  addRunRecipientId(runId: string){
    if(this.runRecipients.has(runId)){
      this.runRecipients.delete(runId);
    } else {
      this.runRecipients.add(runId);  
    }
  }

  hasRunRecipientIds(){
    return [...this.runRecipients].length;
  }

  setCheckbox(field: string, value: boolean){
    if(!value){
      this.resendOptionsForm.patchValue({
        [field]: null
      });
    }
  }

  send(ref){

    this.resendOptionsForm.patchValue({
      'ResendMobile': this.resendOptionsForm.value.ToMobile || this.defaultMobileSet ? true : false,
      'ToMobile' : this.defaultMobileSet ? this.defaultMobile : this.resendOptionsForm?.value?.ToMobile
    });

    this.resendOptionsForm.patchValue({
      'ResendEmail': this.resendOptionsForm.value.ToAddress || this.defaultEmailSet ? true : false,
      'ToAddress' : this.defaultEmailSet ? this.defaultEmail : this.resendOptionsForm?.value?.ToAddress
    });

    this.resendOptionsForm.patchValue({
      Runs: [...this.runRecipients]
    });

    const resendOptionsObject = {...this.resendOptionsForm.value}

    if(resendOptionsObject.ToMobile === null){
      delete resendOptionsObject.ToMobile;
    }

    if(resendOptionsObject.ToAddress === null){
      delete resendOptionsObject.ToAddress;
    }

    if(!resendOptionsObject.ForwardPukLess){
      delete resendOptionsObject.ForwardPukLess;
    }

    if(!resendOptionsObject.UpdateRecipient){
      delete resendOptionsObject.UpdateRecipient;
    }

    if(!resendOptionsObject.UpdateRecipientMobile){
      delete resendOptionsObject.UpdateRecipientMobile;
    }

    this.toastr.info('Resending InfoSlips, please wait.')

    if (this.resendOptionsForm.value.ToAddress && this.defaultEmailSet == false) {
      this.runRecipients.forEach(runRecip => {
        let forwardOptionsObject = {};
        forwardOptionsObject["RunRecipientId"] = runRecip;
        if (this.resendOptionsForm.value.ToAddress?.length > 0) 
          forwardOptionsObject["ToEmailAddress"] = this.resendOptionsForm.value.ToAddress;
        if (this.resendOptionsForm?.value?.ToMobile?.length > 0) 
          forwardOptionsObject["ToMobileNumber"] = this.resendOptionsForm?.value?.ToMobile;

        this.recipientService.forwardInfoSlips(forwardOptionsObject).subscribe(res => {
          res ? ref.close() : undefined;
        });
      });
    }
    else
      this.recipientService.resendInfoSlips(this.id, resendOptionsObject).subscribe(res => {
        res ? ref.close() : undefined;
      });    
  }

  preview(ref:any, id?:string) {
    const dialogOptions = {
      closeOnBackdropClick: false,
      context: id ? id : this.recipientDataForm.value.id
    }

    this.dialogRef = this.dialogService.open(ref, dialogOptions);

    this.dialogRef.onBackdropClick.subscribe(res => {
      if (res.type === "click") {
        this.closePreview()
      }
    })
  }

  
  closePreview() {
    this.dialogRef.close();
  }

  goBack(){
    const redirectUrl = this.recipientId ? `admin/recipients/${this.recipientId}` : '/'
    this.router.navigateByUrl(redirectUrl);
  }

  addToolTip(items:RecipientReferenceHistory[]):RecipientReferenceHistory[]{  
    if(items==null)
      return [];  
    items.forEach(hist=>{
      if(hist.outputChannels==null)
        return;
      hist.outputChannels.forEach(oc=>{        
        oc["tooltip"]=this.formatTooltip(oc);
      })
    });

    items.sort((a,b) => new Date(b.runDate).getTime() - new Date(a.runDate).getTime());

    return items;
  }

  formatTooltip(channel:RecipientOutputChannel):recipientToolTip{

    const tooltip:recipientToolTip={
      type: channel.value[0].channel==='SMS'?'smartphone-outline':'email-outline',
      status: "primary",
      text:channel.value[0].resultMessage ?? "No Message"
    }
    const originalValue=channel.value[0].resultMessage?.toLowerCase() ?? JSON.stringify(channel);

    if(!originalValue || originalValue.length==0)
      return tooltip;

    if(originalValue.includes("not enabled") || originalValue.includes("ignored"))
      {
        tooltip.status = "warning";
      }
    else if(originalValue.includes("success") || originalValue.includes("delivered") )
    {
      tooltip.text = "Success";
    }
    else if(originalValue.includes("skip"))
      tooltip.status = "warning";
    else{
      tooltip.status="danger";
    }

    if(tooltip.text==='No Message'){
      tooltip.text='Success';
      tooltip.status='primary';
    }

    return tooltip;
  }

  allowPuk():boolean{
    return this.isSystemAdmin || this.loggedInUser?.permissions.includes('May_See_PUKs');
  }
}
