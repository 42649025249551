<div class="form-group">
    <nb-checkbox (checkedChange)="toggleCreateRecipients($event)" ngModel="createRecipients" status="basic">Create Registration Run?
    </nb-checkbox>
  </div>

<ng-container *ngIf="createRecipients">
    <div class="form-group">
    <nb-select ngModel="runTemplate" placeholder="Select Run Template">
        <nb-option *ngFor="let t of templates" [value]="t.id">{{
          t.name
        }}</nb-option>
      </nb-select>
    <button class="submit" nbButton status="primary" hero (click)="createRegistrationRun()">Create Recipients</button>
    </div>
</ng-container>


