import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RunRecipientService, SelectedRunService } from '@InfoSlips/iiab-api';
import { RunModel } from '@InfoSlips/models';
import { AuthService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-recipient-filter',
  templateUrl: './recipient-filter.component.html',
  styleUrls: ['./recipient-filter.component.scss']
})
export class RecipientFilterComponent implements OnInit {

  @Input() filterContext: string;
  @Input() selectedRunService: SelectedRunService;

  public innerWidth: number;

  externalId: string;
  name: string;
  email: string;
  mobile: string;
  selectedRun: RunModel;

  recipient: any;
  reason: any;
  runRecipient: any;

  FilterObject = {};

  constructor(
    private route: ActivatedRoute,
    private runRecipientService: RunRecipientService,
    public authService: AuthService
  ) { }

  filterForm: FormGroup;

  channelOptions = [
    "All channels",
    "Email",
    "SMS",
    "Export",
  ];

  channelOption = this.channelOptions[0];

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.selectedRunService.selectedRun$.subscribe(run=>{
      this.selectedRun = run;
    });   
  }

  onChange(value, field) {
    this.FilterObject[field] = {
      like: value
    };
    this.selectedRunService.filterRunRecipients(Object.keys(this.FilterObject).length !== 0 ? this.FilterObject : null);
  }

  onIgnoredChange(value: string, field: string, isChannel?: boolean) {
    this.FilterObject[field] = isChannel ? {
      eq: value.split(' ')[0].toString().toUpperCase()
    } : {
      like: value
    };

    value.includes("All") ? delete this.FilterObject["channel"] : "";
    this.selectedRunService.loadIgnored(Object.keys(this.FilterObject).length !== 0 ? this.FilterObject : null);
  }

  onChangeIgnoredAddtional(value, field) {
    this.FilterObject["runRecipient"] = {
      [field]: {
        like: value
      }
    };
    this.selectedRunService.loadIgnored(Object.keys(this.FilterObject).length !== 0 ? this.FilterObject : null);
  }

  postRecipientFile(event, context: string) {
    const dt = event.target;
    const file = dt.files[0];

    event.preventDefault();

    this.runRecipientService.postRecipientFile(this.selectedRun.Id, file, context === "Inclusion" ? true : false);
  }

  confirmExpiration = false;
  postExpireRecipientFile(event, expirationMessage: string) {
    const dt = event.target;
    const file = dt.files[0];

    event.preventDefault();

    this.runRecipientService.postExpireRecipientsFile(this.selectedRun.Id, file, expirationMessage);
  }

  clear(filterContext) {
    this.externalId = null;
    this.name = null;
    this.email = null;
    this.mobile = null;
    this.FilterObject = {};
    this.channelOption = this.channelOptions[0];

    filterContext === "Recipients" ?
      this.selectedRunService.filterRunRecipients(null) :
      this.selectedRunService.loadIgnored(null);
  }

  downloadRemovedRunRecipients() {
    this.runRecipientService.downloadRemovedRunRecipients(this.selectedRun.Id);
  }

}
