import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { IiabIdName, MiscellaneousModel, PageGraphQLData, RunTemplateModel, TemplateMergeFieldsModel, TemplateMergeFieldsNebular, BlacklistReference } from '@InfoSlips/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ObservableWrapper } from './base/observable-wrapper';
import { ToastrService } from 'ngx-toastr';
import { NbDialogService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class RecipientsBlacklistService {

  public blacklist: ObservableWrapper<BlacklistReference>;

  blacklistSubject = new BehaviorSubject<BlacklistReference[]>(null);
  blacklist$ = this.blacklistSubject.asObservable();

  constructor(
    private apiBaseService: ApiBaseService
  ) {
      
    this.blacklist = new ObservableWrapper<BlacklistReference>(
      this.apiBaseService,
      this._pageRecipientsBlacklistQuery(),
      this._fetchRecipientsBlacklistData,
    false);

    this.blacklist.loadPagedItems();
  }

  private _pageRecipientsBlacklistQuery() {
    return `
    query RecipientsBlacklist ($where: RecipientBlacklistFilterInput = null){ 
      recipientsBlackList (where: $where) {
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges{
          node{
            channel
            created
            createdBy
            id
            lastUpdated
            lastUpdatedBy
            reason
            recipient
            uniqueId
          }
        }
      }
    }`;
  }

  private _fetchRecipientsBlacklistData(rawData: any): PageGraphQLData<BlacklistReference> {
    try{
      return rawData.data.recipientsBlackList as PageGraphQLData<BlacklistReference>;;
    }
    catch(e)
    {
      console.log("recipientsBlacklistData That FAIlED", rawData);
      return null;
    }
  }
  
  uploadBatchFile(context: string, file: any){
    const fileToUpload = file;
    const formData = new FormData();
    
    formData.append('file', fileToUpload, fileToUpload.name); 
    return this.apiBaseService.executePostProgress(`recipientBlacklist/batch${context.toLowerCase()}`, formData, `Batch ${context} Recipient Blacklist.`)
  }
}