import { BaseEntityModel, BaseEntityIdNameModel, BaseEntityIdModel } from "./BaseEntity";

export interface CustomerReferenceModel extends BaseEntityIdNameModel {
    BillingGroup: string;
}

export interface CustomerModel extends BaseEntityModel {
    ArchiveDisplayName:           string;
    Subdomain:                    string;
    BillingGroup:                 string;
    SupportInstructions:          string;
    EnableEmailVerification:      boolean;
    SaveUnverifiedEmails:         boolean;
    IsPrivate:                    boolean;
    Contacts:                     ContactModel[];
    Addresses:                    AddressModel[];
    PreferedSmsSenders:           any[];
    AccessTokens:                 any[];
    S3UploadAccessId:             string;
    S3UploadAccessKey:            string;
    UseSmtpMailServer:            boolean;
    SmtpHost:                     null;
    SmtpPort:                     number;
    SmtpLoginDomain:              null;
    SmtpUsername:                 null;
    SmtpPassword:                 null;
    SmtpDefaultFrom:              null;
    SmtpUseSsl:                   boolean;
    SmtpBounceReturnEmailAddress: null;
}
//TODO: Why is this a separate interface
// export interface CustomerPost {
//     AccessTokens:        any[];
//     SupportInstructions: string;
//     Contacts:            ContactModel[];
//     Addresses:           AddressModel[];
//     PreferedSmsSenders:  null;
//     S3UploadAccessId:    string;
//     S3UploadAccessKey:   string;
//     Name:                string;
//     ArchiveDisplayName:  string;
//     Subdomain:           string;
// }

export interface ContactModel extends BaseEntityIdNameModel {
    ContactType:            number;
    ContactTypeDescription: string;
    Email:                  string;
    Phone?:                  string;
}

export interface AddressModel extends BaseEntityIdModel {
    AddressType:            number;
    AddressTypeDescription: string;
    Line1:                  string;
    Line2:                  string;
    Line3:                  string;
    City:                   string;
    Country:                string;
    Code:                   string;
    Coordinate:             CoordinateModel;
}

export interface CoordinateModel {
    Latitude:   number;
    Longditude: number;
}

