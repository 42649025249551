/* eslint-disable max-len */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiBaseService, RunsService } from '@InfoSlips/iiab-api';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { graphqlFilters } from '../../report.config';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'report-queue-activity',
  templateUrl: './queue-activity.component.html',
  styleUrls: ['./queue-activity.component.scss']
})
export class QueueActivityComponent implements OnInit {
  selectedReport: null;
  currentQuery = '';

  filterModels = {
    filterByQueueName: null,
    filterByMachineName: null,
    filterByIpAddress: null,
    filterByThreads: null,
    filterByStatus: null,
    filterByTime: null,
    filterByMessage: null
  }

  @ViewChild('selectCompany') runCustomers;
  @ViewChild('selectTemplate') runTemplates;

  constructor(
    private reportsService: ReportsService,
    private runsService: RunsService,
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService,
  ) { }

  reportDetails$ = this.reportsService.reportDetails$;
  pageNumber = 1;
  total: number;

  searchQueryRoute = `QueueActivity?$inlinecount=allpages&$format=json&$top=10&$filter=(`;
  downloadQuery = `QueueActivity/asyncreport/QueueActivityReport/?$inlinecount=allpages&$format=json&$filter=(`;

  ngOnInit(): void {
    this.reportsService.reportDetailsSubject.next(null);
    this.selectedReport = this.reportsService.getSelectedReport()
  }

  pageReport(index){    
    this.pageNumber = index;
    const skipAmount = this.searchQueryRoute.split('skip=')[1]?.split('&')[0];

    this.searchQueryRoute = this.searchQueryRoute.replace(skipAmount ? `top=10&$skip=${Number(skipAmount)}` : `top=10`, `top=10&$skip=${(index - 1) * 10}`);
    this.downloadQuery = this.downloadQuery.replace(skipAmount ? `top=10&$skip=${Number(skipAmount)}` : `top=10`, `top=10&$skip=${(index - 1) * 10}`);
    
    this.search();
  }
 
  onChange(filterType: string) {
    this.filterModels.filterByStatus = filterType;
  }

  search(){
    this.apiBaseService.executeGet(this.searchQueryRoute + this.getFilterString(), 'Get Run statistics').subscribe(res => {
      this.reportsService.reportDetailsSubject.next(res)
    })
  }

  download(){
    this.apiBaseService.executeGet(this.downloadQuery + this.getFilterString(), 'Download Run statistics').subscribe((res: any) => {
      this.toaster.success(res.Message);
    })
  }

  getFilterString(){
    const FilterArray = [];

    if (this.filterModels.filterByQueueName) {
      FilterArray.push(`substringof('${this.filterModels.filterByQueueName}',tolower(QueueName))`);
    }

    if (this.filterModels.filterByMachineName) {
      FilterArray.push(`substringof('${this.filterModels.filterByMachineName}',tolower(MachineName))`);
    }

    if (this.filterModels.filterByIpAddress) {
      FilterArray.push(`substringof('${this.filterModels.filterByIpAddress}',tolower(IpAddress))`);
    }

    if (this.filterModels.filterByThreads) {
      FilterArray.push(`ActiveItems eq ${this.filterModels.filterByThreads}`);
    }

    if (this.filterModels.filterByStatus) {
      FilterArray.push(`${this.filterModels.filterByStatus} ne null`);
    }

    if (this.filterModels.filterByTime) {
      FilterArray.push(`TimeTaken gt ${this.filterModels.filterByTime}`);
    }

    if (this.filterModels.filterByMessage) {
      FilterArray.push(`substringof('${this.filterModels.filterByMessage}',tolower(Message))`);
    }

    FilterArray.forEach((item, index )=> {
      if(index === (FilterArray.length - 1)){
        this.currentQuery += `${item})`;
      }
      else {
        this.currentQuery += `${item} and `;
      }
    });

    return this.currentQuery;
  }

}
