<div class="d-flex">
  <div *ngIf="innerWidth > 480" class="runs-column row" [class.expand]="expand">
    <ifs-runs></ifs-runs>
  </div>
  <div class="dashboard-detail-container" [ngClass]="{'full-width' : expand}" [class.active]="isRunSelected">
    <div class="row flex-column">

      <div class="general-page-container fit-absolute pb-65" *ngIf="selectedRun$ | async; else loading">
        <div [class]="run?.Status === 'Expired' ? 'general-page-header expired' : 'general-page-header'">
          <div class="title-bar row">
            <div class="col-md-8">
              <h4>
                <nb-icon *ngIf="!run.IsPukLess" nbTooltip="PUK Protected Run" nbTooltipStatus="warning" nbTooltipPlacement="bottom" class="puk-warning" icon="lock" pack="oakmoon"></nb-icon> 
                {{run?.Name}} 
                <small *ngIf="run?.Status === 'Expired'">(Expired)</small> 
                <small *ngIf="(selectedRunService.runRecipientSummariesCount$ | async) != null && (selectedRunService.runRecipientSummariesCount$ | async) != undefined"> : {{(selectedRunService.runRecipientSummariesCount$ | async)}} Recipients</small> 
              </h4>
              <a [routerLink]="'../../admin/runtemplates/' + run?.RunTemplate?.Id">{{run?.RunTemplate?.Name}}</a>
              <ng-container *ngIf="innerWidth >= 480">
                <button nbButton size="small" class="sync-button" status="primary"
                (click)="syncRunWithTemplate()" *ngIf="run?.IsTrial">
                Sync Template <nb-icon icon="sync"></nb-icon>
              </button>
              <button nbButton size="small" class="sync-button" status="primary"
                (click)="runActionsService.resendRunConfirmationEmail(run.Id)" 
                *ngIf="!run?.IsTrial">
                Resend Result Email <nb-icon icon="sync"></nb-icon>
              </button>
              </ng-container>
            </div>
            <div class="col-md-4 text-right header-action-container">
              <ng-template #information>
                <div class="row popover-info">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        Created By
                      </div>
                      <div class="col-md-8">
                        {{ run?.CreatedBy }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        Billing Date
                      </div>
                      <div class="col-md-8">
                        {{ run?.BilingDate.DateTime | date: 'short' }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        {{ run?.IsTrial ? 'Trialed By' : 'Released By' }}
                      </div>
                      <div class="col-md-8">
                        {{ run?.RunStartedBy?.Name }}
                      </div>
                    </div>

                    <div class="row" *ngIf="run?.Status === 'Expired'">
                      <div class="col-md-4">
                        Expired By
                      </div>
                      <div class="col-md-8">
                        {{ run?.LastUpdatedBy }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- <p class="header-action"> -->
              <!-- <nb-icon class="header-action" pack="eva" icon="close-outline"></nb-icon> -->
              <!-- </p> -->

              <nb-actions *ngIf="innerWidth > 480" size="small">

                <nb-action class="favorite loader">
                  <nb-icon 
                    *ngIf="loadingService.loading || (run.Instance.Status === 2)" 
                    icon="loading-half" 
                    pack="oakmoon" 
                    class="rotate"
                    [nbTooltip]="run.Instance.Status === 2 ? 'Processing...' : 'Loading...'" 
                    nbTooltipStatus="primary" 
                    nbTooltipPlacement="bottom" 
                  ></nb-icon>
                </nb-action>

                <nb-action nbTooltip="Favorite Run" nbTooltipStatus="primary" nbTooltipPlacement="bottom" class="favorite" [icon]="(isStar$ | async)?.isStar ? 'star' : 'star-outline'"
                  (click)="favorite(run?.Name)"></nb-action>

                <nb-action class="info" [nbPopover]="information" nbPopoverTrigger="click" nbPopoverPlacement="top"
                  icon="info"></nb-action>

                  <nb-action nbTooltip="Reset Open Run" nbTooltipStatus="warning" nbTooltipPlacement="bottom" class="warning" icon="sync" (click)="openRunOptions(resetOpenRunDialog, 'settings')" *ngIf="run.Instance.Status == 9">
                  </nb-action>

                <nb-action nbTooltip="Reset Run" nbTooltipStatus="warning" nbTooltipPlacement="bottom" class="info"  class="warning" icon="sync" (click)="resetRun()" *ngIf="
                  run.Instance.Status != 2 && 
                  run.Instance.Status != 7 && 
                  run.Instance.ProgressCounters && 
                  ((run.IsTrial && 
                  (run.Instance.ProgressCounters[1]?.Status == 3 || 
                  run.Instance.ProgressCounters[1]?.Status == 1 || 
                  run.Instance.ProgressCounters[1]?.Status == 4 || 
                  run.Instance.ProgressCounters[1]?.Status == 3 || 
                  run.Instance.ProgressCounters[1]?.Status == 5)) ||
                  (!run.IsTrial &&
                  (run.Instance.ProgressCounters[1]?.Status == 1 || 
                  run.Instance.ProgressCounters[1]?.Status == 4 || 
                  run.Instance.ProgressCounters[1]?.Status == 5)))">
                </nb-action>

                <nb-action nbTooltip="Cancel Run" nbTooltipStatus="warning" nbTooltipPlacement="bottom" class="warning" icon="slash" (click)="cancelRun()"
                  *ngIf="run.Instance.Status == 2 || run.Instance.Status == 1 || run.Instance.Status == 12">
                </nb-action>

                <nb-action nbTooltip="Delete Run" nbTooltipStatus="danger" nbTooltipPlacement="bottom" class="danger" icon="trash-2" (click)="openRunOptions(confirmDeleteDialog, 'settings')"
                  *ngIf="run.Instance.Status != 2 && run.Instance.Status != 7 && !run.DenyDelete && run.IsTrial">
                </nb-action>

                <nb-action class="close" icon="close-outline" routerLink=""></nb-action>
              </nb-actions>

              <nb-actions *ngIf="innerWidth <= 480" size="small">
                <nb-action class="warning" [nbPopover]="moreOptions" nbPopoverTrigger="click" nbPopoverPlacement="top"
                icon="more-horizontal"></nb-action>
                <nb-action class="close" icon="close-outline" routerLink=""></nb-action>
              </nb-actions>
            </div>
          </div>
        </div>

        <ifs-run-status [selectedRunService]="selectedRunService"></ifs-run-status>

        <div class="general-page-body">
          <div class="run-nav" *ngIf="innerWidth > 480">

            <div class="scroll-nav">
              <div class="general-side-nav">
                <ng-container *ngFor="let menuItem of state.RunMenu; index as i" >
                  <div class="context-menu" *ngIf="menuItem.visible" (click)="setTab(i)"
                  [class.active]="i === state.activeTab">
                    <div class="body">
                      <h4 class="title">{{ menuItem.name  }}</h4>
                    </div>
                    <div class="action text-center">
                      <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                    </div>
                  </div>
                </ng-container>
                
                <div class="context-menu" (click)="openRunOptions(runOptionsDialog, 'preview')">
                  <div class="body">
                    <h4 class="title">
                      Send Options
                    </h4>
                  </div>
                  <div class="action text-center">
                    <!-- <nb-icon icon="code-edit-file" pack="oakmoon" size="20"></nb-icon> -->
                    <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                  </div>
                </div>

                <div class="context-menu" (click)="openRunOptions(createRecipientsDialog, 'settings')" *ngIf="run.IsTrial">
                  <div class="body">
                    <h4 class="title">
                      Create Recipients
                    </h4>
                  </div>
                  <div class="action text-center">
                    <!-- <nb-icon icon="person-add" size="20"></nb-icon> -->
                    <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                  </div>
                </div>

                <div class="context-menu" routerLink="/reports/{{run?.Id}}/delivery-report">
                  <div class="body">
                    <h4 class="title">
                      Report
                    </h4>
                  </div>
                  <div class="action text-center">
                    <!-- <nb-icon icon="pie-line-graph" pack="oakmoon" size="20"></nb-icon> -->
                    <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="run-context-detail">
            <div class="run-content">
              <ifs-run-report [selectedRunService]="selectedRunService" *ngIf="state.activeTab===0"></ifs-run-report>
              <ifs-trial-details [selectedRunService]="selectedRunService" *ngIf="state.activeTab===1"></ifs-trial-details>
              <ifs-send-live [selectedRunService]="selectedRunService" *ngIf="state.activeTab===2"></ifs-send-live>
              <ifs-run-upload-form [selectedRunService]="selectedRunService" *ngIf="state.activeTab===3"></ifs-run-upload-form>
              <ifs-recipient-details [selectedRunService]="selectedRunService" *ngIf="state.activeTab===4" ></ifs-recipient-details>
              <ifs-recipient-not-to-send [selectedRunService]="selectedRunService" *ngIf="state.activeTab===5"></ifs-recipient-not-to-send>
              <ifs-processor-logs [selectedRunService]="selectedRunService" *ngIf="state.activeTab===6"></ifs-processor-logs>
              <ifs-run-logs [selectedRunService]="selectedRunService" *ngIf="state.activeTab===7"></ifs-run-logs>
            </div>
          </div>
        </div>
      </div>
      
      <ng-template #loading>
        <div class="ghost-card">
          <div class="general-page-header">
            <div class="title-bar row">
              <div class="col-md-4">
                <div class="ghost-card">
                  <div class="lines">
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="circles">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </ng-template>

      <ng-template dialogClass="run-options-dialog" #runOptionsDialog let-data let-ref="dialogRef">
        <ifs-run-options class="run-options" [context]="optionsContext" (closeRunOptions)="closeRunOptions()"
          [selectedRunService]="selectedRunService"></ifs-run-options>
      </ng-template>

      <ng-template dialogClass="run-options-dialog" #resetOpenRunDialog let-data let-ref="dialogRef">
        <nb-card>
          <nb-card-header class="create-recipient-dialogue">
            Confirm <nb-icon (click)="closeRunOptions()" icon="close" class="close"></nb-icon>
          </nb-card-header>
          <nb-card-body class="confirmDeleteBody">
            <p>Are you sure you want to reset this run: <span class="warning">{{run.Name}}</span>?</p>
            <p></p>
          </nb-card-body>
          <nb-card-footer class="confirmDeleteFooter">
            <button fullWidth nbButton status="warning" (click)="resetOpenRun()">Confirm</button>
            <button fullWidth nbButton (click)="closeRunOptions()">Cancel</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <ng-template dialogClass="run-options-dialog" #createRecipientsDialog let-data let-ref="dialogRef">
        <nb-card>
          <nb-card-header class="create-recipient-dialogue">
            Create Recipients <nb-icon (click)="closeRunOptions()" icon="close" class="close" ></nb-icon>
          </nb-card-header>
          <nb-card-body>
            <ifs-create-recipients></ifs-create-recipients>
          </nb-card-body>
        </nb-card>
      </ng-template>

      <ng-template dialogClass="run-options-dialog" #confirmDeleteDialog let-data let-ref="dialogRef">
        <nb-card>
          <nb-card-header class="create-recipient-dialogue">
            Confirm <nb-icon (click)="closeRunOptions()" icon="close" class="close"></nb-icon>
          </nb-card-header>
          <nb-card-body class="confirmDeleteBody">
            <p>Are you sure you want to delete the run: <span class="warning">{{run.Name}}</span>?</p>
            <p>This action can not be reverted.</p>
          </nb-card-body>
          <nb-card-footer class="confirmDeleteFooter">
            <button fullWidth nbButton status="warning" (click)="scheduleRunDeletion()">Delete</button>
            <button fullWidth nbButton (click)="closeRunOptions()">Cancel</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #moreOptions>
  <div class="row popover-info">
      <div class="row">
        <div class="col-md-4">
          Created By
        </div>
        <div class="col-md-8">
          {{ run?.CreatedBy }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          Created On
        </div>
        <div class="col-md-8">
          {{ run?.Created | date: 'short' }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          Billing Date
        </div>
        <div class="col-md-8">
          {{ run?.BilingDate.DateTime | date: 'short' }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          {{ run?.IsTrial ? 'Trialed By' : 'Released By' }}
        </div>
        <div class="col-md-8">
          {{ run?.RunStartedBy?.Name }}
        </div>
      </div>

      <div class="row" *ngIf="run?.Status === 'Expired'">
        <div class="col-md-4">
          Expired By
        </div>
        <div class="col-md-8">
          {{ run?.LastUpdatedBy }}
        </div>
      </div>
  </div>
  <div class="grid">
    <button nbButton class="sync-button" status="primary" (click)="syncRunWithTemplate()" *ngIf="run?.IsTrial">
      Sync Template <nb-icon icon="sync"></nb-icon>
    </button>

    <button nbButton class="sync-button" status="primary" (click)="runActionsService.resendRunConfirmationEmail(run.Id)" *ngIf="!run?.IsTrial">
      Resend Result Email <nb-icon icon="sync"></nb-icon>
    </button>

    <button nbButton class="sync-button"  (click)="resetRun()" *ngIf="
      run.Instance.Status != 2 && 
      run.Instance.Status != 7 && 
      run.Instance.ProgressCounters && 
      ((run.IsTrial && 
      (run.Instance.ProgressCounters[1]?.Status == 3 || 
      run.Instance.ProgressCounters[1]?.Status == 1 || 
      run.Instance.ProgressCounters[1]?.Status == 4 || 
      run.Instance.ProgressCounters[1]?.Status == 3 || 
      run.Instance.ProgressCounters[1]?.Status == 5)) ||
      (!run.IsTrial &&
      (run.Instance.ProgressCounters[1]?.Status == 1 || 
      run.Instance.ProgressCounters[1]?.Status == 4 || 
      run.Instance.ProgressCounters[1]?.Status == 5)))">
      Reset Run <nb-icon status="warning" class="warning" pack="eva" icon="sync"></nb-icon>
    </button>

    <button class="sync-button" *ngIf="run.Instance.Status == 2 || run.Instance.Status == 1" nbButton (click)="cancelRun()">
      Cancel Run <nb-icon status="warning" class="warning" pack="eva" icon="slash"></nb-icon>
    </button>
    
    <button class="danger" *ngIf="run.Instance.Status == 9" (click)="openRunOptions(resetOpenRun, 'settings')">     
      Reset Open Run<nb-icon status="warning" class="warning" pack="eva" icon="slash"></nb-icon>
    </button>

    <button class="sync-button" *ngIf="run.Instance.Status != 2 && run.Instance.Status != 7 && !run.DenyDelete && run.IsTrial" nbButton (click)="openRunOptions(confirmDeleteDialog, 'settings')">
      Delete Run <nb-icon status="danger" class="danger" pack="eva" icon="trash-2"></nb-icon>
    </button>
  </div>
</ng-template>