import { IiabEntity } from "./iiab-reference";

export interface RecipientReference {
    name:       string;
    id:         string;
    externalId: string;
    email:      string;
    mobile:     string;
    history: RecipientReferenceHistory[];
}

export interface RecipientSummary extends IiabEntity{
    externalId: string;
    email:      string;
    mobile:     string;    
}

export interface RecipientReferenceHistory {
    name:                  string;
    id:                    string;
    runRecipient:          RunRecipient;
    bilingDateDescription: string;
    runDate:               string;
    outputChannels:        RecipientOutputChannel[];
}

export interface RecipientOutputChannel {
    key:   string;
    value: OutputChannelItem[];
}

export interface OutputChannelItem {
    channel:       string;
    isSent:        boolean;
    isDelivered:   boolean;
    resultMessage: string;
    sendDate:      string;
}

export interface RunRecipient {
    id: string;
}

export interface recipientToolTip{
    status:string;
    text:string;
    type:string;
}
