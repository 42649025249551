import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'ifs-document-actions',
  template: `
    <ng-container *ngIf="actionStates as state">

      <div class="infoslips document-actions">
      <button
        nbButton
        (click)="onDocumentAction('docSearch')"
        *ngIf="state.searchDocument"
      >
        <nb-icon pack="oakmoon" icon="doc-search"></nb-icon>
        <span class="desktop">Search Document</span>
      </button>
      <button
        nbButton
        id="actionDownloadIFS"
        (click)="onDocumentAction('download')"
        *ngIf="state.DownloadIfs"
      >
        <nb-icon pack="oakmoon" icon="cloud-download"></nb-icon>
        <span class="desktop">Download</span>
      </button>
      <button
        nbButton
        id="actionPrint"
        (click)="onDocumentAction('print')"
        *ngIf="state.Print"
      >
        <nb-icon pack="oakmoon" icon="print-text"></nb-icon>
        <span class="desktop">Print</span>
      </button>
      <button
        id="actionDownloadPDF"
        nbButton
        (click)="onDocumentAction('downloadPDF')"
        *ngIf="state.DownloadPdf"
      >
        <nb-icon pack="oakmoon" icon="office-file-pdf"></nb-icon>
        <span class="desktop">Download PDF</span>
      </button>
      </div>
      </ng-container>
  `,
  styleUrls: ['./document-actions.component.scss']
})
export class DocumentActionsComponent implements OnInit {
  @Output() documentAction = new EventEmitter();
  @Input() actionStates;
  @Input() theme;
  constructor() {}

  ngOnInit() {}

  onDocumentAction(event) {
    this.documentAction.emit(event);
  }
}
