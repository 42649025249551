import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'ifs-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public reportsService: ReportsService
  ) {}

  reports;
  selectedReport = null;
  currentReport = null;
  runId: string = null;
  reportType = null;
  searchPhrase: string;

  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;

  ngOnInit(): void {
    this.reportsService.allowedReportsForComponent$.subscribe(res => {
      if (res) {
        this.reports = res;

        this.runId =  this.route.snapshot.paramMap.get('runId');
        const route = this.runId ? this.router.url.split(`reports/${this.runId}/`)[1] : this.router.url.split('reports/report/')[1];
        this.currentReport = this.reports.find(item => item.route === route);

        this.selectedReport = this.currentReport != null ? Object.assign(this.currentReport) : null;
      }
    }) 
  }

  select(report){
    this.selectedReport = report;
    this.reportsService.reportDetailsSubject.next(null);
    this.reportsService.reportDetailsPagingSubject.next(null);
    // // Reset filter on navigation.
    this.reportsService.filterObject = {};
  }
}
