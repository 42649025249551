import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ifs-cms-entity-code',
  templateUrl: './cms-entity-code.component.html',
  styleUrls: ['./cms-entity-code.component.scss']
})
export class CmsEntityCodeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
