import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@InfoSlips/env';
import { BehaviorSubject } from 'rxjs';
import { IiabIdName } from '@InfoSlips/models';

export enum State {
  Initial,
  Navigated,
}
@Injectable({
  providedIn: 'root'
})

export class WizardService {
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) {}
  
  wizardStatusSubject = new BehaviorSubject<boolean>(false);
  wizardStatus$ = this.wizardStatusSubject.asObservable();

  navigationStateSubject = new BehaviorSubject<State>(0);
  navigationState$ = this.navigationStateSubject.asObservable();

  wizardContextSubject = new BehaviorSubject<string>(null);
  wizardContext$ = this.wizardContextSubject.asObservable();

  wizardSearchHistory = {};

  setState(state: State){
    this.navigationStateSubject.next(state);
  }

  getRunPeriodList(){
    return this.http.get(`${this.environment.apiUrl}api/runPeriod`);
  }

  toggleWizard(expand: boolean){
    this.wizardStatusSubject.next(expand);
    this.setState(0);
  }

  addSearchHistory(historyType: string, searchObject: any, additionalOptions?: any) {
    this.wizardSearchHistory[historyType] = searchObject;

    if (additionalOptions != null) this.wizardSearchHistory[`${historyType}AdditionalOptions`] = additionalOptions;
  }

  removeSearchHistory(historyType: string) {
    if (this.wizardSearchHistory[historyType] != null)
      delete this.wizardSearchHistory[historyType];
  }
}
