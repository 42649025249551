import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from '@InfoSlips/iiab-api';
import { AuthService } from '@InfoSlips/iiab-auth';
import { LoggedInModel, IiabIdName } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
  providers: [TitleCasePipe, TemplateService]
})
export class TemplateFormComponent implements OnInit {
  @ViewChild('autoInput') input;
  @Input('context') context: string;
  
  constructor(
    public auth: AuthService,
    private router: Router, 
    private wizardService: WizardService,
    private templateService: TemplateService,
  ) { }

  ///////////////////////
  templates$: Observable<IiabIdName[]>;
  selectedTemplate: string;
  selectedTemplateId: string;
  //////////////////

  filterObject = {};

  runDescription: string;
  billingDate;
  user: LoggedInModel = this.auth.loggedInUser;

  // selectedTemplate: string;
  selectedCustomer: string;
  selectedCustomerId: string;

  ngOnInit(): void {
    this.templateService.templates.loadPagedItems(this.loadSearchHistory());

    this.templates$ = this.templateService.templates.Items$().pipe(
      exhaustMap((res) => of(res))
    );

  }

  onChange() {
    const wordSearch = this.input.nativeElement.value;

    this.filterObject = wordSearch.length === 24 ? { id: { eq: this.input.nativeElement.value }} : { name: { like:  this.input.nativeElement.value }}    
  }

  searchTemplates() {    
    this.templates$ = of([{ id: "Loading..", name: "Loading.."}]);
    
    this.templateService.templates.loadPagedItems(this.input.nativeElement.value ? this.filterObject : null);
    this.templates$ = this.templateService.templates.Items$().pipe(exhaustMap((res) => of(res)));
    this.wizardService.addSearchHistory("Templates", this.filterObject)
  }
  
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.searchTemplates();
  }

  onSelectionChange($event) {
    this.templates$.subscribe((res: any) => {
        res.find(item => {
          if(item.name === this.input.nativeElement.value){
            this.selectedCustomerId = item.id;
            this.selectedCustomer = item.name;
            this.templates$ = of([item]);
            this.router.navigateByUrl(`/admin/templates/${item.id}`)
        }
      })
    })
  }

  createTemplate(){
    this.router.navigateByUrl(`admin/templates`)
  }
  
  cancel(){  
    this.wizardService.setState(0);
  }
  
  hasKeys(obj: any): boolean {
    return obj != null && Object.keys(obj).length > 0;
  }

  private loadSearchHistory() {
    if (this.wizardService.wizardSearchHistory['Templates']) {
      const searchHistory = this.wizardService.wizardSearchHistory['Templates'];
      
      if (searchHistory.name != null) this.selectedTemplate = searchHistory.name.like;
      if (searchHistory.id != null) this.selectedTemplateId = searchHistory.id.eq;
      this.filterObject = searchHistory;
      return searchHistory;
    } else return null;
  }
}
