import { Component } from '@angular/core';
import { CustomerStatsService } from '@InfoSlips/iiab-api';
import { AggregatedPeriodRunStats, AggregatedRunStats, CustomerRunStatistics } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ifs-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent {

  selectedAggregatedStats:AggregatedPeriodRunStats

  aggregatedStatsSubject = new BehaviorSubject<AggregatedRunStats>(null);
  aggregatedStats$ = this.aggregatedStatsSubject.asObservable();
  
  constructor(
    customerStats: CustomerStatsService,
  ) { 
    customerStats.aggregatedStatistics$.subscribe(aggregatedStats=>{
      this.aggregatedStatsSubject.next(aggregatedStats);
      if(aggregatedStats && aggregatedStats.periods.length>0)
      {
        this.selectedAggregatedStats= aggregatedStats.periods[0];
      }
    })
  }

  changePeriod(event:CustomerRunStatistics){
    this.selectedAggregatedStats = event;
  }
}
