import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { PageGraphQLData, RecipientReference, RecipientSummary, RunRecipientModel, RunRecipientSummary } from '@InfoSlips/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { ObservableWrapper } from '@InfoSlips/iiab-api';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RecipientService {

  recipients: ObservableWrapper<RecipientReference>;
  recipientSummaries: ObservableWrapper<RunRecipientSummary>;

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.recipients = new ObservableWrapper<RecipientReference>(
      this.apiBaseService,
      this._pageRecipientsQuery(),
      this._fetchRecipientsData,
    false);
    this.recipientSummaries = new ObservableWrapper<RunRecipientSummary>(
      this.apiBaseService,
      this._pageRecipientSummaryQuery(),
      this._fetchRecipientSummaryData,
    false);

    this.recipients.loadPagedItems();
  }

  selectedRunRecipientSubject = new BehaviorSubject<RunRecipientModel>(null);
  selectedRunRecipient$ = this.selectedRunRecipientSubject.asObservable();

  getRecipientById(id: string){
    const subject = new Subject<RecipientReference>();
    
    const model = {
      query: this._pageRecipientsQuery(),
      variables: {
        'where': {
          'id': {
              "eq": id
          }
        }
      },
      operationName: 'Recipients'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Run Recipient By ID.').subscribe((res: any) => {
      if(res.data.recipients && res.data.recipients.edges.length > 0){
        res.data.recipients.edges[0].node.history.sort((a: { runDate: string | number | Date; },b: { runDate: string | number | Date; }) => new Date(b.runDate).getTime() - new Date(a.runDate).getTime());
        subject.next(res.data.recipients.edges[0].node);
      } else {
        this.toastr.error(`The specified run recipient: ${id} does not exist.`);
        this.router.navigateByUrl('/');
      }
    })
  
    return subject.asObservable();
  }

  resendInfoSlips(recipientId: string, resendOptions: any){
    const subject = new Subject<boolean>();
    this.apiBaseService.executePut<any>(`recipient/${recipientId}/resendInfoSlips`, resendOptions, "Resend InfoSlips").subscribe((results) => {
      results ? this.toastr.success(`InfoSlips have been resent!`) : this.toastr.error(`Resent failed.`);
      subject.next(results);
    });

    return subject.asObservable();
  }

  resendRunRecipientInfoSlip(runRecipientId: string){
    const subject = new Subject<any>();
    this.apiBaseService.executeGet<any>(`runRecipient/${runRecipientId}/resendInfoSlip`, "Resend InfoSlip").subscribe((results) => {
      results.IsResend ? this.toastr.success(`InfoSlip have been resent!`) : this.toastr.error(`Resent failed.`);
      subject.next(results);
    });

    return subject.asObservable();
  }

  forwardInfoSlips(forwardOptions: any){
    const subject = new Subject<any>();
    this.apiBaseService.executePost<any>(`runRecipient/Forward`, forwardOptions, "Forward InfoSlips").subscribe((results) => {
      results.length > 0 && results[0]?.IsForward ? this.toastr.success(`InfoSlip have been Forwarded!`) : this.toastr.error(`Forward failed.`);
      subject.next(results);
    });

    return subject.asObservable();
  }

  //#region Private
  private _pageRecipientsQuery() {
    return `query Recipients($where:RecipientFilterInput=null) { 
      recipients(where: $where){
        edges {
          node {
          name
          id
          externalId
          email
          mobile
          publicId
          puk
          history {
            name
            id
            runRecipient {
              id
            }
            bilingDateDescription
            runDate
            outputChannels {
              key
              value {
                channel
                isSent
                isDelivered
                resultMessage
                sendDate
                }
              }
            }
          customer {
            name
          }
          run {
            name
            id
          }
          }
        }
      }
    }`
  }  
  private _pageRecipientSummaryQuery() {
    return `query Recipients($where:RecipientFilterInput=null) { 
      recipients(where: $where){
        edges {
          node {
            id,
            name,
            created,
            createdBy,
            lastUpdated,
            lastUpdatedBy,
            externalId,
            email,
            mobile,
          }
        }
      }
    }`
  }

  private _fetchRecipientsData(rawData: any): PageGraphQLData<RecipientReference> {
    try{
      return rawData.data.recipients as PageGraphQLData<RecipientReference>;
    }
    catch(e)
    {
      console.log("recipients That FAILED", rawData);
      return null;
    }
  }
  private _fetchRecipientSummaryData(rawData: any): PageGraphQLData<RunRecipientSummary> {
    try{
      return rawData.data.recipients as PageGraphQLData<RunRecipientSummary>;
    }
    catch(e)
    {
      console.log("recipients That FAILED", rawData);
      return null;
    }
  }

  //#endregion

}