import { Component, Input, Output, OnInit, TemplateRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { CmsEntitySummary, IiabIdName } from '@InfoSlips/models';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { EnvironmentService } from '@InfoSlips/env';

@Component({
  selector: 'cms-grid-item',
  templateUrl: './cms-grid-item.component.html',
  styleUrls: ['./cms-grid-item.component.scss']
})
export class CmsGridItemComponent implements OnInit, OnChanges {

  @Input() cmsEntity: any = null;
  @Input() type = 'default';
  @Input() routerLinkUrl;
  @Output() checkedEntity: EventEmitter<IiabIdName> = new EventEmitter();

  parsedContentType: number;
  contentIcon: string;
  hasContentIcon = false;

  constructor(
    private cmsService: CmsService,
    private router: Router,
    private dialogService: NbDialogService,
    private environment: EnvironmentService,
  ) { }

  ngOnInit() {
    // Change CMS entity to GraphQL model if it's the rest model.
    if('Id' in this.cmsEntity){
      this.cmsEntity = this.cmsService.lowerCaseObjectKeys(this.cmsEntity);
      this.cmsEntity.cmsEntityType = this.cmsService.getEntityTypeString(this.cmsEntity.cmsEntityType);
      this.cmsEntity.cmsContentType = this.cmsService.getContentTypeString(this.cmsEntity.cmsContentType);
    }

    this.parsedContentType = this.cmsService.getContentType(this.cmsEntity.cmsContentType);
    this.contentIcon = this.cmsService.getContentIcon(this.cmsEntity.cmsContentType);

    if (this.contentIcon != 'none') {
      this.hasContentIcon = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.cmsEntity != undefined) this.contentIcon = this.cmsService.getContentIcon(changes?.cmsEntity?.currentValue?.cmsContentType);
  }

  selectEntity(entity: CmsEntitySummary){
    this.router.navigateByUrl('cms/entity/'+entity.id);    
  }

  openPreview(dialog: TemplateRef<any>) {
    const imgSrc = `${this.environment.baseUrl}api/cms/publicid/${this.cmsEntity.publicId}/${this.cmsEntity.version}`;
    this.dialogService.open(dialog, { context: imgSrc});
  }

  getVersion(version: number) {
    return version !== -1 ? version === 0 ? "Latest Version" : version : "Draft";
  }
}