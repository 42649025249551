import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RunsService } from '@InfoSlips/iiab-api';
import * as models from '@InfoSlips/models';
import { AuthService } from '@InfoSlips/iiab-auth';
import { RunModel } from '@InfoSlips/models';
import { RunFilterService } from '../../services/run-filter.service';

@Component({
  selector: 'ifs-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.scss']
})

export class RunsComponent implements OnInit, OnDestroy {  
  runSummaries$: Observable<models.RunSummary[]> = this.runService.runSummaries.Items$();
  runSummariesCount$: Observable<number> = this.runService.runSummaries.totalCount$;
  lastUpdated$: Observable<string> = this.runService.runSummaries.lastUpdated$;
  runCount$: Observable<number> = this.runService.runSummaries.count$;
  resultCount$: Observable<number> = this.runService.runSummaries.totalCount$;
  loading$: Observable<boolean> = this.runService.runSummaries.isLoading$;
  error: any;
  ghosts = [] = new Array(10);
  filter = false;
  @ViewChild('searchBox', { static: true }) searchBox: ElementRef;
  @Output() runId = new EventEmitter<any>();

  selectedRun: RunModel;
  activeTab = '';

  userInputAction = this.runFilterService.userInputAction.asObservable();
  hasNextPage$ = this.runService.runSummaries.hasNextPage$;
  hasPreviousPage$ = this.runService.runSummaries.hasPreviousPage$;
  runsPerPage: number;

  runTypeOptions = [
    {
      label: "All",
      filter: null
    }
  ];

  constructor(
    private router: Router, 
    private runService: RunsService,
    private authService: AuthService,
    private runFilterService: RunFilterService,
    ) { }

  get searchPhrase(): string {
    return this.runFilterService.searchPhrase;
  }
  set searchPhrase(input: string) {
    this.runFilterService.searchPhrase = input;
  }
  
  ngOnInit() {
    this.runsPerPage = this.runService.runSummariesPerPage = 10;
    this.runService.selectedRun$.subscribe(res=>{
      this.selectedRun = res;
    })
    
    this.authService.loggedInUser$.subscribe(res => {
      this.runTypeOptions.push({
        label: "My",
        filter: {
          createdBy: {
            eq: res?.userName
          }
        }
      },
      {
        label: "Scheduled",
        filter: { 
          instance: {
            status: { 
              eq: "SCHEDULED"
            }
          }
        }
      })
    });

    this.runService.getRecentRuns();

    this.runService.runSummaries.isLoading$.subscribe(res => {
      if (res == false) this.runFilterService.userInputAction.next(false);
    });
  }

  ngOnDestroy(): void {
    this.runService.unsubscribeAll(true);
  }

  pageChange($event: string){
    this.runFilterService.userInputAction.next(true);
    this.runService.pageRunSummaries($event);
  }

  selectRun(run){
    this.router.navigateByUrl('run/'+run.id);
  }
  
  toggleFilter(){
    this.filter = !this.filter
  }

  changeRunType(event){
    if (this.activeTab === event.tabTitle) return;

    this.activeTab = event.tabTitle;
    const option = this.runTypeOptions.find(item => item.label === event.tabTitle);

    if(option.filter != null){
      if (option.label.includes("Scheduled")) {
        this.runFilterService.pushSelectedRunStatusValue("Scheduled");
        this.runFilterService.customFilter = null;
      } else {
        this.runFilterService.customFilter = option.filter;
        this.runFilterService.removeValueFromSelectedRunStatus("Scheduled");
      }
    } 
    else {
      this.runFilterService.customFilter = null;
      this.runFilterService.removeValueFromSelectedRunStatus("Scheduled");
    }
    
    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadPagedItems(Object.keys(this.runService.runsFilterObject).length !== 0 ? this.runService.runsFilterObject : null, null, null, null, this.runsPerPage);
  }

  getSelectedRunSummary():models.RunSummary{
    return {
      created:this.selectedRun.Created,
      createdBy:this.selectedRun.CreatedBy,
      customer: {name:this.selectedRun.Customer.name, billingGroup:"",id:""},
      id:this.selectedRun.Id,
      isTrial:this.selectedRun.IsTrial,
      lastUpdated:this.selectedRun.LastUpdated,
      name:this.selectedRun.Name,
      runTemplate:{name:this.selectedRun.RunTemplate.Name, id:""},
      scheduleDateTime:this.selectedRun?.ScheduleDateTime?.toString(),
      instance:{status:this.runService.runStatusOptions[this.selectedRun.Instance.Status]}
    };    
  }

  search() {
    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadPagedItems(Object.keys(this.runService.runsFilterObject).length !== 0 ? this.runService.runsFilterObject : null);
  }
}
