/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'report-adhoc-forward-deliveries',
  templateUrl: './adhoc-forward-deliveries.component.html',
  styleUrls: ['./adhoc-forward-deliveries.component.scss']
})
export class AdhocForwardDeliveriesComponent {

  constructor(
    private reportsService: ReportsService
  ) {}

  reportDetails$ = this.reportsService.reportDetails$;
  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;

  pageReport(context: string){
    this.reportsService.pageReport(context);
  }
}
