import { Component, Input, OnInit } from '@angular/core';
import { BookmarkService, RunTemplateService, TemplateService } from '@InfoSlips/iiab-api';
import { Bookmark,RunTemplateSummary } from '@InfoSlips/models';

@Component({
  selector: 'ifs-wizard-run-template-card',
  templateUrl: './run-template-card.component.html',
  styleUrls: ['./run-template-card.component.scss']
})
export class RunTemplateCardComponent implements OnInit  {

  @Input() runTemplate: RunTemplateSummary
  @Input() isRunTemplate = true;
  @Input() context;
  revealed = false;
  deleteConfirm = false;
  stars = { isStar: false};

  constructor(
    private runTemplateService: RunTemplateService,
    private templateService: TemplateService,
    private bookmarkService: BookmarkService,
  ) { }

  ngOnInit(){
    const route = this.isRunTemplate ? 'admin/runtemplates/' + this.runTemplate?.id : 'admin/templates/' + this.runTemplate?.id;
    this.stars = this.bookmarkService.isStar(route);
  }

  toggleView() { 
    this.revealed = !this.revealed; 
  }

  delete(id: string, confirmed: boolean){
    if(confirmed){
      if (this.isRunTemplate) this.runTemplateService.deleteRunTemplate(id);
      else this.templateService.deleteTemplate(id);
    }
    this.deleteConfirm = !this.deleteConfirm;
  }

  favoriteItem(name: string, route: string){
    this.stars.isStar = !this.stars.isStar;
    const bookmark: Bookmark = {
      name: name,
      context: this.isRunTemplate ? 'Run Template' : 'Template',
      icon: this.isRunTemplate ? 'run-template' : 'template',
      isStar: false,
      route: route
    }
    this.bookmarkService.favorite(bookmark);
  }

  determineRoute(isRunTemplate, runTemplate){
    if(isRunTemplate){
      if(this.context.title.toLowerCase() === 'run search data'){
        return '/admin/tools/run-search-data/' + runTemplate?.id;
      } else {
        return '/admin/runtemplates/' + runTemplate?.id;
      }
    } else {
      return '/admin/templates/' + runTemplate?.id
    }
  }

}
