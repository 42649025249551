<ng-container class="run-options-dialog">
    <nb-card>
        <nb-card-header class="create-recipient-dialogue">
            {{ header }} <nb-icon (click)="cancelClick()" icon="close" class="close"></nb-icon>
        </nb-card-header>
        <nb-card-body class="confirmDeleteBody">
            <p>{{ body }}</p>
        </nb-card-body>
        <nb-card-footer class="confirmDeleteFooter">
            <button fullWidth nbButton status="warning" (click)="confirmClick()">{{ confirmText }}</button>
            <button fullWidth nbButton (click)="cancelClick()">{{ cancelText }}</button>
        </nb-card-footer>
    </nb-card>
</ng-container>