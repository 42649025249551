<div class="general-page-container fit-absolute">
  <div class="general-page-header">
    <form class="p-10">
      <div class="form-block">
        <div class="form-group">
          <label>Find {{ data.title }}</label>
          <nb-form-field>
              <input fullWidth autocomplete="off" #autoInput nbInput type="text" (ngModelChange)="filterResults($event, 0)" fullWidth name="userName" placeholder="Filter by Name or ID" [(ngModel)]="selectedUser" [ngModelOptions]="{standalone: true}"/>
              <button nbSuffix nbButton ghost (click)="clearFilter(0)">
                  <nb-icon icon="close-outline" pack="eva"></nb-icon>
              </button>
          </nb-form-field>

          <br/>

          <ifs-customer-lookup [showLabel]="false" #customerDropdown (ItemSelected)="filterResults($event, 3)"></ifs-customer-lookup>        

          <br/><br/>

          <nb-form-field>
            <nb-select placeholder="Is Approved" fullWidth (ngModelChange)="filterResults($event, 1)" [(ngModel)]="selectedUserIsApproved" [ngModelOptions]="{standalone: true}">
              <nb-option value="">Both</nb-option>
              <nb-option [value]="true">True</nb-option>
              <nb-option [value]="false">False</nb-option>
            </nb-select>
          </nb-form-field>

          <br/>

          <nb-form-field>
            <input fullWidth autocomplete="off" #emailInput nbInput type="text" (ngModelChange)="filterResults($event, 2)" fullWidth name="email" placeholder="Filter by Email" [(ngModel)]="selectedUserEmail" [ngModelOptions]="{standalone: true}"/>
            <button nbSuffix nbButton ghost (click)="clearFilter(2)">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
            </button>
          </nb-form-field>

          <button *ngIf="hasKeys(filterObject)" class="col-md-4" size="small" nbButton status="primary" (click)="searchUsers()">
              Search
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="general-page-body">
      <div class="general-page-body">
          <div class="scroll p-10">
          <ng-container *ngFor="let item of isLoading ? (loadingUsers$ | async) : (users$ | async)">
            <ifs-wizard-generic-card [cardInfo]="item" [context]="data"></ifs-wizard-generic-card>
          </ng-container>
        </div>
      </div>
  </div>
  <div class="general-page-footer">
      <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
          Cancel
      </button>
      <button class="col-md-6" size="small" nbButton status="primary" [routerLink]="data.route">
          Create
      </button>
  </div>
</div>
<!-- <div class="general-page-container fit-absolute">
  <div class="general-page-body">
    <div class="form-block scroll p-10">
      <form [formGroup]="findUserForm">
        <div class="form-group">
          <label>Customer</label>
          <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth
            [nbAutocomplete]="auto" name="Customer" placeholder="Customer name" [ngModel]="selectedCustomer" [ngModelOptions]="{standalone: true}"/>
          <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
            <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.name">
              {{ option.name }}
            </nb-option>
          </nb-autocomplete>
        </div>

      <div class="form-group">

        <label>User name or Id</label>
        <input nbInput fullWidth type="text" fullWidth name="name" formControlName="NameOrId" />

      </div>
      <div class="form-group">

        <label>Is Approved</label>
        <nb-select fullWidth name="name" formControlName="IsApproved">
          <nb-option value="0">False</nb-option>
          <nb-option value="1">True</nb-option>
        </nb-select>

      </div>
      <div class="form-group">
        <label>Email</label>
        <input nbInput fullWidth type="text" fullWidth name="email" />
      </div>
    </form>
    </div>
  </div>
  <div class="general-page-footer">
    <button size="small" outline nbButton status="default" (click)="cancel()">
      Cancel
    </button>
    <button size="small" outline nbButton status="primary" (click)="findUser()">
      Find
    </button>
  </div>
</div>
<div class="expand-filter" [class.active]="filter">
  <div class="general-page-container fit-absolute">
    <div class="p-10 d-flex">
      <div class="flex-1 d-flex align-items-center">
        <button size="small" outline nbButton status="default" (click)="toggleBulkActions()">
          Bulk Action
        </button>
      </div>
      <button nbButton ghost class="close pull-right m-5" (click)="toggleFilter()">
        <nb-icon icon="close" pack="oakmoon" size="28"></nb-icon>
      </button>
    </div>
    <div class="d-flex p-10 justify-content-between align-items-center" *ngIf="bulkActions">
      <p><nb-checkbox status="basic">Select All</nb-checkbox></p>
      <button size="small" outline nbButton status="danger" (click)="toggleBulkActions()">
        Delete
      </button>
    </div>
    <div class="general-page-body">
      <div class="scroll p-10">
        <div class="d-flex">
          <p class="mr-10" *ngIf="bulkActions"><nb-checkbox status="basic"></nb-checkbox></p>
          <nb-card class="card mt-0 shadow p-10 flex-1" [routerLink]="'admin/users'">
            <nb-card-body class="card-header">
              <div class="d-flex align-items-center">
                <div class="flex-1">
                  <p class="card-title">
                    name
                  </p>
                  <p class="card-sub-title">
                    id
                  </p>
                </div>
                <div class="flex-1">
                  <p class="card-sub-title">
                    email
                    <span>dist channel</span>
                  </p>
                  <p class="card-sub-title">
                    mobile
                    <span>reason</span>
                  </p>
                </div>
                <div>
                  <nb-icon class="action danger mr-10" pack="oakmoon" icon="bin"></nb-icon>
                  <nb-icon class="action primary mr-10" pack="oakmoon" icon="edit-user"></nb-icon>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
        </div>
      </div>
    </div>
  </div>
</div> -->