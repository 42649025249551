<div style="display: flex;" #cmsFilterComponent>
  <div class="actions-container">
      <div class="dashboard-detail-container">
          <div class="intro-container">
            <nb-form-field>
              <input
                #cmsSearchPhrase
                nbInput
                [(ngModel)]="searchPhrase"
                fullWidth
                placeholder="Search for Name, Display or Id"
                (ngModelChangeDebounced)="loadFilter()"
                [ngModelChangeDebounceTime]="1000"
              />
              <button nbSuffix nbButton ghost (click)="showFilter()">
                <nb-icon icon="filter-on" pack="oakmoon">
                </nb-icon>
              </button>
            </nb-form-field>
          </div>

          <ng-container *ngTemplateOutlet="type === 'default' ? default : grid"></ng-container>
      </div>
  </div>
</div>


<ng-template #default>
  <div *ngIf="toggleFilter" class="expand-filter" [class.active]="toggleFilter">
    <div class="flex"> 
      <h6 class="m-0">FILTER BY</h6>
      <button nbButton ghost class="close pull-right m-5" (click)="showFilter()">
        <nb-icon icon="close" pack="oakmoon" size="28"></nb-icon>
    </button>   
    </div>
    
    <div class="general-page-container full-height">
      <div class="general-page-body">
        <div class="scroll">
          <div class="input-group col-md-12">
            <ifs-customer-lookup
              #customerDropdown
              (ItemSelected)="onCustomerSelected($event)"
            >
            </ifs-customer-lookup>
          </div>
          <div class="input-group col-md-12">
            <ifs-run-template-lookup
              (ItemSelected)="onRunTemplateSelected($event)"
            >
            </ifs-run-template-lookup>
          </div>
          <div class="input-group col-md-12">
            <label>Entity Type</label>
            <nb-select
              [(selected)]="entityType"
              (selectedChange)="loadFilter()"
              fullWidth
              name="entityType"
            >
              <nb-option
                *ngFor="let item of filterService.entityTypes$ | async"
                [value]="item.Value"
                >{{ item.Value }}</nb-option
              >
            </nb-select>
          </div>
  
          <div class="input-group col-md-12">
            <label>Content Type</label> 
            <nb-select
              [(selected)]="contentType"
              (selectedChange)="loadFilter()"
              fullWidth
              name="contentType"
            >
              <nb-option
                *ngFor="let item of filterService.contentTypes$ | async"
                [value]="item.Value"
                >{{ item.Value }}</nb-option
              >
            </nb-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #grid>
  <nb-card *ngIf="toggleFilter">
    <nb-card-body>
      <form autocomplete="nope">
        <div class="row m-0 filters">
          <div class="form-group col-lg-2">
            <ifs-customer-lookup
              #customerDropdown
              (ItemSelected)="onCustomerSelected($event)"
            >
            </ifs-customer-lookup>
          </div>
          <div class="form-group col-lg-2">
            <ifs-run-template-lookup
              (ItemSelected)="onRunTemplateSelected($event)"
            >
            </ifs-run-template-lookup>
          </div>
          <div class="form-group col-lg-2">
            <label>Entity Type</label>
            <nb-select
              [(selected)]="entityType"
              (selectedChange)="loadFilter()"
              fullWidth
              name="entityType"
            >
              <nb-option
                *ngFor="let item of filterService.entityTypes$ | async"
                [value]="item.Value"
                >{{ item.Value }}</nb-option
              >
            </nb-select>
          </div>

          <div class="form-group col-lg-2">
            <label>Content Type</label> 
            <nb-select
              [(selected)]="contentType"
              (selectedChange)="loadFilter()"
              fullWidth
              name="contentType"
            >
              <nb-option
                *ngFor="let item of filterService.contentTypes$ | async"
                [value]="item.Value"
                >{{ item.Value }}</nb-option
              >
            </nb-select>
          </div>
          <!-- <div class="form-group col-md-12">
            <label>Tags</label>
            <nb-tag-list (tagRemove)="onTagRemove($event)">
              <nb-tag
                *ngFor="let tree of tags"
                [text]="tree"
                removable
              ></nb-tag>
              <input
                type="text"
                nbTagInput
                (tagAdd)="onTagAdd($event)"
                fullWidth
              />
            </nb-tag-list>
          </div> -->

          <div class="form-group col-lg-2">
            <button class="clear-filter" nbButton (click)="clearFilters()">
              Clear Filters
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
</nb-card>
</ng-template>