<div class="general-page-container fit-absolute">
    <div class="general-page-header">
        <ng-container>
          <div class="form-group">
            <label>Filter by Domain</label>
            <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth
              [nbAutocomplete]="auto" name="Domain" placeholder="Domain name" [ngModel]="selectedDomain" [ngModelOptions]="{standalone: true}"/>
            <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
              <nb-option *ngFor="let option of filteredDomains$ | async" [value]="option.domain">
                {{ option.name }}
              </nb-option>
            </nb-autocomplete>
          </div>
        </ng-container>
      </div>
    <div class="general-page-body">
      <div class="scroll p-10">
        <ng-container *ngIf="filteredDomains$ | async as domains">
          <ng-container  *ngFor="let domain of domains; index as i">
            <nb-reveal-card class="card mb-10 shadow" [showToggleButton]="false" [revealed]="revealed[i]" class="card mb-10 shadow">
              <nb-card-front>
                <nb-card>
                  <nb-card-header class="card-header p-10 reveal-card" (click)="selectDomain(domain)">
                    <p class="card-title">
                      {{ domain.domain }} <br/>
                      <small>Allow for all on Domain <input type="checkbox" [checked]="domain.allowAllOnOnDomain" disabled/></small>
                    </p>
                  </nb-card-header>
                  <nb-card-body>
                    <ng-container *ngTemplateOutlet="domainId; context: {domainId: domain?.id}"></ng-container>
                    <div class="actions">
                      <button nbButton (click)="delete(domain?.id, false, i)">
                        <nb-icon [icon]="deleteConfirm[i] ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
                      </button>
                      <button nbButton (click)="deleteConfirm[i] ? delete(domain?.id, true, i) : toggleView(i)" [status]="deleteConfirm[i] ? 'danger' : 'basic'">
                        <nb-icon [icon]="deleteConfirm[i] ? 'check-circle' : 'info-circle'" pack="oakmoon" status="info"></nb-icon>
                      </button>
                    </div>
                  </nb-card-body>
                </nb-card>
              </nb-card-front>
              <nb-card-back>
                <nb-card>
                  <nb-card-header class="card-header p-10 reveal-card" (click)="selectDomain(domain)">
                    <table *ngIf="domain?.created">
                      <tr>
                        <td>Created:</td>
                        <td style="font-size: smaller;"> {{domain?.createdBy}} ({{domain?.created?.split('T')[0]}})</td>
                      </tr>
                      <tr>
                        <td>Last Modified:</td>
                        <td style="font-size: smaller;">{{domain?.lastUpdatedBy}} ({{domain?.lastUpdated?.split('T')[0]}})</td>
                      </tr>
                    </table>
                  </nb-card-header>
                  <nb-card-body>
                    <ng-container *ngTemplateOutlet="domainId; context: {domainId: domain?.id}"></ng-container>
                    <div class="actions">
                        <button nbButton (click)="delete(domain?.id, false, i)">
                          <nb-icon [icon]="deleteConfirm[i] ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
                        </button>
                        <button nbButton (click)="deleteConfirm[i] ? delete(domain?.id, true, i) : toggleView(i)" [status]="deleteConfirm[i] ? 'danger' : 'basic'">
                          <nb-icon [icon]="deleteConfirm[i] ? 'check-circle' : 'navigate-left'" pack="oakmoon" status="info"></nb-icon>
                        </button>
                    </div>
                  </nb-card-body>
                </nb-card>
              </nb-card-back>
            </nb-reveal-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="general-page-footer">
      <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
        Cancel
      </button>
      <button class="col-md-6" size="small" nbButton status="primary" routerLink="/admin/tools/testing-restrictions">
        Create
      </button>
    </div>
  </div>

  <ng-template #domainId let-domainId="domainId">
    <p class="domain-id">
      <small>{{ domainId }}</small>
      <ifs-copy [value]="domainId"></ifs-copy>
    </p>
  </ng-template>