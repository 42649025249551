import { Component, Input, OnInit } from '@angular/core';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { CmsEntity, RunTemplateSummary } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CmsService } from './../../services/cms.service';

export interface EntityCardOptions {
  cardSize: string;
  heading: string;
  headingEnabled: boolean;
  type: string;
  data: any;
  assetCount: number;
  showDetailsEnabled: boolean
}

@Component({
  selector: 'entity-card-container',
  templateUrl: './entity-card.container.html',
  styleUrls: ['./entity-card.container.scss']
})
export class EntityCardContainer implements OnInit {

  @Input() type = 'general';
  @Input() options: EntityCardOptions = {
    cardSize: 'col-md-12',
    heading: 'Assets',
    headingEnabled: false,
    type: 'custom',
    data: this.cmsService.cmsEntities.Items$(),
    assetCount: 0,
    showDetailsEnabled: false
  }
  @Input() entities$: Observable<CmsEntity[]>;
  @Input() selectedRunTemplate: RunTemplateSummary;
  @Input() isFilterExpanded: boolean;


  loading$: Observable<boolean>;
  searchPhrase: string;
  assetCount = 0;
  showDetails$: Observable<boolean> = this.cmsService.showDetails$;;
  selectedEntity: CmsEntity;
  entitySummaries$ = this.cmsService.cmsEntities.Items$()

  ghosts = new Array(4);

  constructor(
    private cmsService: CmsService) {
  }

  ngOnInit() {
    this.searchPhrase = '';
    this.cmsService.cmsEntities.count$.subscribe(count => this.assetCount = count);
  }

  selectEntity(entity: CmsEntity){
    this.cmsService.retrieveCmsEntity(entity.id);
  }

  onScroll() {
    this.cmsService.cmsEntities.loadNextPage();
  }
}
