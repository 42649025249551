import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProcessorService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-admin-processor-crud',
  templateUrl: './processor-crud.component.html',
  styleUrls: ['./processor-crud.component.scss']
})
export class ProcessorCrudComponent implements OnInit {
  processorId = this.router.snapshot.paramMap.get('id');
  context = window.location.href.split('/admin/')[1].includes('pre') ? 'pre' : 'post';
  isUploading = false;
  processor$;
  fileList= [];
  filesAdded = false;

  constructor(
    public processorService: ProcessorService,
    private router: ActivatedRoute,
  ) { }

  ngOnInit(): void {
   this.processor$ = this.processorService.getProcessorById(this.processorId, this.context);
  }

  removeFile(context: string, processor, fileName: string, isQueued: boolean){
    if(isQueued){
      processor.files = processor.files.filter(file => file.fileName !== fileName);
      return;
    }
    const isRemoved = this.processorService.removeFile(context, this.processorId, fileName);
    isRemoved ? processor.files = processor.files.filter(file => file.fileName !== fileName) : undefined;
  }

  onDrop(event, processor, context){    
    const dt = event.dataTransfer || event.target;
    const dtf = dt.files[0];
    const file = {
      fileName: dtf.name,
      file: dtf,
      context: context
    }
    event.preventDefault();
    this.fileList.push(file);

    processor.files.push({
      fileName: file.fileName,
      size: file.file.size,
      queued: true,
      progress: 0,
      message: 'Upload Pending'
    });

    this.filesAdded = true
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  async uploadFiles(processor, context: string) {
    // this.processorService.save(context, processor);
    this.fileList.reduce(async (item: any, file: any) => {
      await item;
      await this.uploadFile(processor.id, context, processor.files, file);
    }, undefined);
  }

  async uploadFile(processorId: string, context: string, processorFiles, file){
    return new Promise(resolve => {
    
    this.processorService.upload(context, processorId, file.file).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {      
          const progress = Math.round(100 * event.loaded / event.total)
          processorFiles.find(item => item.fileName ===  file.fileName).progress = progress;
          processorFiles.find(item => item.fileName ===  file.fileName).queued = false;
          processorFiles.find(item => item.fileName ===  file.fileName).message = `${progress}%`;
      } else if (event instanceof HttpResponse) {
        processorFiles.find(item => item.fileName ===  file.fileName).message = 'Uploaded!';
        setTimeout(() => {
          processorFiles.find(item => item.fileName ===  file.fileName).message = '100%';
        }, 2000)
        resolve(() => {
          this.fileList = [];
          this.filesAdded = false;
        });
      }
    })
    });
  }

  async saveCreate(processor, context: string){
    if(this.processorId){
      this.filesAdded ? this.uploadFiles(processor, context) : this.processorService.save(context, processor);
    } else {
      const createdProcessor = this.processorService.create(context, processor);
      createdProcessor.then(res => {
        this.processorId = res.Id;
        const processorLowerCase = this.formatProcessor(res);
        processorLowerCase.files = processor.files;
        this.uploadFiles(processorLowerCase, context);
      })
    }
  }

  formatProcessor(processor){
    const keys = Object.entries(processor);
    const capsKeys = keys.map((key) => [key[0][0].toLowerCase() + key[0].slice(1), key[1]]);
    return Object.fromEntries(capsKeys);
  }
}
