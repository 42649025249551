import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsEntitySummary, IiabIdName } from '@InfoSlips/models';
import { Observable } from 'rxjs';
import { CmsGridService } from '../../services/cms-grid.service';
import { CmsFilterService } from '../../services/cms-filter.service';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'cms-grid',
  templateUrl: './cms-grid.component.html',
  styleUrls: ['./cms-grid.component.scss']
})
export class CmsGridComponent implements OnInit {
  @Input() isFilterExpanded: boolean;
  
  dialogRef;
  loading$: Observable<boolean>;
  cmsEntities$: Observable<CmsEntitySummary[]> = this.cmsGridService.cmsService.cmsEntities$;
  cmsEntitiesCount$:Observable<number>;
  selectedEntities: IiabIdName[] = [];
  
  gridSort = {
    name: null,
    customer: null,
    runTemplate: null,
    version: null,
    lastUpdated: 'DESC'
  }

  constructor(
    private router: Router,
    private cmsGridService: CmsGridService,
    private filterService: CmsFilterService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {
    this.cmsEntitiesCount$ = this.cmsGridService.cmsEntitiesCount$;
    this.cmsGridService.loadEntities();
  }

  onScroll(){
    this.cmsGridService.loadEntitiesNextPage();
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  getContentIcon(entity:CmsEntitySummary):string{
    return this.cmsGridService.getContentIcon(entity);
  }

  filterField(fieldName: string){
    switch (this.gridSort[fieldName]) {
      case 'ASC':
        this.gridSort[fieldName] = 'DESC'
        break;

      case 'DESC':
        this.gridSort[fieldName] = null
        break;

      default:
        this.gridSort[fieldName] = 'ASC'
        break;
    }
    
    let order = {};

    if(this.gridSort[fieldName] && (fieldName !== 'customer' && fieldName !== 'runTemplate')){
      order[fieldName] = this.gridSort[fieldName];
    } else {
      order[fieldName] = {
        name: this.gridSort[fieldName]
      }
    }

    if(!this.gridSort[fieldName]){
      order = {
        lastUpdated: 'DESC'
      };
    }

    this.filterService.order = order;
  }

  checkCmsEntity($event) {
    const index = this.selectedEntities.findIndex(x => x.id === $event.id);
    if (index === -1)
      this.selectedEntities.push($event);
    else 
      this.selectedEntities.splice(index, 1);
  }

  openDialog(ref){
    this.dialogRef = this.dialogService.open(ref, {});
  }

  deleteSelectedEntities() {
    this.selectedEntities.forEach(element => {
      this.cmsGridService.cmsService.deleteCmsEntity(element.id);
    });

    this.dialogRef.close();
    this.router.navigateByUrl('/cms');
  }
}
