import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RunTemplateService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { IiabLookupBaseComponent } from '../base/iiab-look-up-base';
import { CmsFilterService as FilterService } from 'libs/cms/src/lib/services/cms-filter.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ifs-run-template-lookup',
  templateUrl: './run-template-lookup.component.html',
  styleUrls: ['./run-template-lookup.component.scss']
})
export class RunTemplateLookupComponent extends IiabLookupBaseComponent<IiabIdName> implements AfterViewInit {

  @Input() showLabel = true;
  @Input() showClear = false;
  @Input() autoSelect = true;
  
  ngModel: IiabIdName | undefined;
  disabled = true;

  @ViewChild('runTemplateAutoInput')
  customerAutoInput!: ElementRef<HTMLInputElement>;

  constructor(runTemplateService: RunTemplateService, public filterService: FilterService) {
    super(runTemplateService.runRunTemplates);    
  }

  ngAfterViewInit(): void {
    if('selectedRunTemplate' in this.filterService.CmsFilterState){
      //@ts-ignore
      this.setSelectedItem(this.filterService.CmsFilterState.selectedRunTemplate);
    }

    if(this.ngModel){
      this.filterService.CmsFilterState.selectedRunTemplate = this.ngModel;
      this.setSelectedItem(this.ngModel);
    }

    this.addInputElement(this.customerAutoInput.nativeElement, this.autoSelect);
    setTimeout(() => {
      this.disabled = false;
    }, 500);
  }

  onRunTemplateSelected(event:IiabIdName):void{
    this.onItemSelected(event);
  }

  onCustomerSelected(selectedCustomer: IiabIdName):void{
    
    if(selectedCustomer && selectedCustomer.id===undefined)
      return;
    
    const additionalWhere = selectedCustomer?
      {customer:{id:{eq:selectedCustomer.id}}}:
      null;

    this.setWhere(additionalWhere);
  }
}


