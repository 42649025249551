<div class="grid">
    <div class="flex" style="width: 50%;">
        <nb-icon pack="eva" [icon]="icon" class="icon" [status]="status"></nb-icon>
    
        <div style="width: 100%;">
            <p class="item-name">
                {{ runDetails.runTemplate?.name }}
            </p>
            <p class="item-customer">
                {{ runDetails.name }}
            </p>
        </div>
    </div>

    <div class="flex item-info">
        <div style="width: 100%;">
            <div class="status-pill pull-right text-center full-width">
                <div class="top" [ngClass]="{'bg-warning': runDetails.isTrial, 'bg-success': !runDetails.isTrial}" [class]="runDetails.instance.status === 'FAILED' ? 'bg-danger' : ''">
                    {{runDetails.isTrial ? 'Trial' : 'Live'}}
                </div>
                <div class="bottom text-only" [class]="runDetails.instance.status">
                    {{ runDetails.instance.status }}
                </div>
              </div>
        </div>
    </div>

    <div class="text-right item-info">
        {{runDetails.lastUpdated | timeago}} 
    </div>

    <div class="text-right item-actions">
        <nb-icon 
            pack="oakmoon" 
            icon="info-circle" 
            style="margin-left: 8px;"
            [nbPopover]="entityInfo"
            nbPopoverPlacement="bottom"
            nbPopoverTrigger="hover"
        ></nb-icon>
    </div>
</div>

<ng-template #entityInfo>
    <div>Created By: {{runDetails.createdBy}}</div>
    <div>Customer Name: {{runDetails.customer?.name}}</div>
    <div>last Updated: {{runDetails.lastUpdated | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
    <div *ngIf="runDetails.instance.status === 'SCHEDULED' && runDetails.scheduleDateTime != null">Scheduled: {{runDetails.scheduleDateTime | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
</ng-template>