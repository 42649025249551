import { SharedModule } from './../../../shared/src/lib/shared.module';
import { NebularModule } from './../../../nebular/src/lib/nebular.module';
import { InfoslipModule } from './../../../infoslip/src/lib/infoslip.module';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RecipientComponent } from './components/recipient/recipient.component';
import { RunRecipientsComponent } from './containers/run-recipients/run-recipients.component';
import { RecipientDetailsComponent } from './containers/recipient-details/recipient-details.component';
import { RecipientFilterComponent } from './components/recipient-filter/recipient-filter.component';
import { RecipientNotToSendComponent } from './containers/recipient-not-to-send/recipient-not-to-send.component';
import { PreviewComponent } from './components/preview/preview.component';
import { OutputChannelSummaryComponent } from './components/outputchannel-summary/outputchannel-summary.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule, 
    FormsModule,
    InfiniteScrollModule,
    NebularModule,
    SharedModule,
    InfoslipModule
  ],
  declarations: [
    RecipientComponent,
    RunRecipientsComponent,
    RecipientDetailsComponent,
    RecipientFilterComponent,
    RecipientNotToSendComponent,
    PreviewComponent,
    OutputChannelSummaryComponent
  ],
  exports: [RecipientComponent, RunRecipientsComponent, RecipientDetailsComponent, RecipientNotToSendComponent, PreviewComponent],
})
export class RecipientsModule {}
