<ifs-loading-screen *ngIf="!runTemplateSummary"></ifs-loading-screen>

<div class="general-page-container full-height" *ngIf="runTemplateSummary">
  <div class="general-page-header">
    <div class="d-flex">
      <div class="flex-1">
          <h4>{{ runTemplateSummary.name }}</h4>
          <p *ngIf="runTemplateSummary.cmsEntities.length > 0">
            <a [routerLink]="'../../../admin/runtemplates/' + runTemplateId">{{ runTemplateId }}</a>
          </p>
      </div>
      <div class="text-right">
          <button size="medium" outline nbButton status="basic" routerLink="/cms">
          Cancel
          </button>
          <button size="medium" nbButton status="primary" class="save" (click)="save()"> 
          Save
          </button>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="row">
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>
            <div class="row">
              <div class="col-md-6">
                  Linked Entities: {{runTemplateSummary.cmsEntities.length}}
              </div>
              <div class="col-md-6">
                <nb-actions size="small">
                  <!-- <nb-action [icon]="searchEnabled ? 'close-circle-outline' : 'search-outline'" (click)="search()"></nb-action> -->
                  <nb-action (click)="clear()" style="padding-right: 0px; cursor: pointer;" 
                    (mouseenter)="clearHover(true)" (mouseleave)="clearHover(false)">
                    <p *ngIf="clearIconState" style="margin-right: 12px; color: #FCA00C;">
                      Clear Linked Entities
                    </p>
                    <nb-icon [status]="clearIconState ? 'warning' : ''" 
                      [icon]="clearIconState ? 'trash-2' : 'trash-2-outline'"></nb-icon>
                  </nb-action>
                </nb-actions>
              </div>
            </div>
            <nb-form-field class="search">
              <input nbInput fullWidth placeholder="Search linked entities..." [(ngModel)]="linkedFilterText" 
                (ngModelChange)="applyFilterLinkedEntities()"  />
              <button nbSuffix nbButton ghost (click)="clearSearch('linked')">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
              </button>
            </nb-form-field>
          </nb-card-header>
          <nb-card-body class="padded">
            <div class="row full-width m-0">
              <div class="cms-container item-container desktop" [class]="'col-md-12'">
                <entity-card-component class="ifs-card p1" 
                  *ngFor="let entity of filteredLinkedEntities; let i = index"
                  [cmsEntity]="entity" 
                  type="linked" 
                  [selectedRunTemplate]="runTemplateSummary" 
                  (modifiedEntity)="filteredLinkedEntities[i] = $event"
                  (entityRemovedEvent)="entityRemovedEvent($event)">
                </entity-card-component>
              </div>
          </div>
          </nb-card-body>
        </nb-card>
      </div>

      <div class="col-md-6">
        <nb-card>
          <nb-card-header>
            Available Entities: {{cmsTotalAvailable$ | async}}
            <nb-form-field class="search">
              <input nbInput fullWidth placeholder="Search available entities..." [(ngModel)]="searchAvailableValue" 
                (ngModelChange)="filterAvailableEntities($event)"  />
              <button nbSuffix nbButton ghost (click)="clearSearch('available')">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
              </button>
            </nb-form-field>
          </nb-card-header>
          <nb-card-body class="padded" 
            infiniteScroll
            [infiniteScrollDistance]="1" 
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false" 
            (scrolled)="onScroll()" >
            <div class="row full-width m-0">
              <div class="cms-container item-container desktop" [class]="'col-md-12'">
                <entity-card-component class="ifs-card p1" 
                  *ngFor="let entity of cmsGlobalEntities$ | async;"
                  [cmsEntity]="entity" 
                  type="available" 
                  [selectedRunTemplate]="runTemplateSummary" 
                  (entityAddEvent)="getLinkedEntityIds($event)">
                </entity-card-component>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</div>