import { AfterViewInit, Component, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsCreateUpdateContentModel, CmsRevision, IiabIdName } from '@InfoSlips/models';
import { NbDialogService, NbTagComponent, NbTagInputAddEvent } from '@nebular/theme';
import { CmsService } from '../../services/cms.service';
import { CmsFilterService as FilterService } from '../../services/cms-filter.service';
import { CustomerLookupComponent, RunTemplateLookupComponent } from '@InfoSlips/controls';
import { Observable } from 'rxjs';
import { AuthService } from '@InfoSlips/iiab-auth';

enum CmsEntitySecurity {
  GlobalPublic,
  GlobalSecure,
  Secure
}
@Component({
  selector: 'cms-entity-container',
  templateUrl: './entity-container.component.html',
  styleUrls: ['./entity-container.component.scss']
})
export class EntityContainerComponent implements AfterViewInit {

  @ViewChildren(CustomerLookupComponent) customerLookups: QueryList<CustomerLookupComponent>;
  @ViewChildren(RunTemplateLookupComponent) runTemplateLookups: QueryList<RunTemplateLookupComponent>;

  dialogRef;
  uploadedFile: any;

  entityId = this.router.snapshot.paramMap.get('id');
  entityType = this.router.snapshot.paramMap.get('entityType');

  fileCard = false;
  contentCard = false;

  cmsEntityModel: CmsCreateUpdateContentModel = {
    Id: '',
    Name: '',
    IsDraft: true,
    Display: null,
    ContentType: null
  };

  tags: Set<string> = new Set([]);
  invalidField = '';

  selectedCustomer: IiabIdName;
  selectedCustomerGroup: IiabIdName;
  selectedRunTemplate: IiabIdName;

  editEntity = false;
  wizardState$ = this.cmsService.wizardState$;
  cmsUploadFile = null;
  lastUrl = this.router.snapshot.queryParams['lastUrl'];
  cmsRevisions$: Observable<CmsRevision[]>;
  saveEnabled = false;
  menuItem = 'Overview';
  options = [
    {
      title: 'Overview',
    },
    {
      title: this.entityType === 'content' ? 'Content' : 'File',
    }
  ];

  entitySecurity: CmsEntitySecurity = this.authService.isSystemAdmin ? CmsEntitySecurity.GlobalPublic : CmsEntitySecurity.Secure;

  constructor(
    public filterService: FilterService,
    public cmsService: CmsService,
    private router: ActivatedRoute,
    public authService: AuthService,
    private dialogService: NbDialogService,
    private route: Router
  ) {
    if (this.entityId) {
      this.cmsService.retrieveCmsEntity(this.entityId);
      
    }
  }

  ngAfterViewInit() {
    if (this.entityId) {
      this.cmsService.selectedEntity$.subscribe(res => {
        if (res) {
          Promise.resolve().then(() => {
            this.editEntity = true;
            this.cmsEntityModel = this.cmsService.getCmsEntityCreateUpdateModel(res);
            this.entityType = res.CmsEntityType === 1 ? 'file' : 'content';
            this.tags = new Set(this.cmsEntityModel.Tags);
            this.cmsRevisions$ = this.cmsService.getCmsRevisions(res.PublicId); 

            if (res.RunTemplate) {
              const runTemplateReference = { id: res.RunTemplate.Id, name: res.RunTemplate.Name };
              this.selectedRunTemplate = runTemplateReference;
              this.runTemplateLookups.forEach(x => x.onItemSelected(runTemplateReference));
              const customerReference = { id: res.Customer.Id, name: res.Customer.Name };
              this.selectedCustomer = customerReference;
              return;
            }
            if (res.Customer) {
              const customerReference = { id: res.Customer.Id, name: res.Customer.Name };
              this.selectedCustomer = customerReference;
              this.customerLookups.forEach(x => x.onItemSelected(customerReference));
              this.runTemplateLookups.forEach(x => x.onCustomerSelected(customerReference));
            }
          });
        }
      })
    }
  }

  disableSave(){
    if((!this.cmsEntityModel.CustomerId && !this.authService.isSystemAdmin)){
      return true;
    }
    else {
      return false;
    }
  }

  resetState() {
    this.fileCard = false;
    this.contentCard = false;
    this.cmsService.showDetailsSubject.next(false);
    this.cmsService.wizardStateSuject.next(null);
    this.customerLookups.forEach(lookup => lookup.clear());
    this.runTemplateLookups.forEach(lookup => lookup.clear());
  }

  resetForm(stepper){
    this.resetState();
    this.cmsEntityModel.IsDraft = true;
    this.cmsEntityModel.Name = null;
    this.cmsEntityModel.Display = null;
    this.cmsEntityModel.Notes = null;
    this.tags.clear();
    stepper.reset();
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags.delete(tagToRemove.text);
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.tags.add(value)
    }
    input.nativeElement.value = '';
  }

  async saveCmsContent(dialog: TemplateRef<any>) {
    if (!this.setCmsEntity())
      return;

    this.uploadedFile = this.editEntity ?
      await this.cmsService.updateCmsContent(this.cmsEntityModel) :
      await this.cmsService.createCmsContent(this.cmsEntityModel);

    if (this.uploadedFile.success) {
      this.dialogRef = this.dialogService.open(dialog, { context: this.uploadedFile });
      this.resetState();
    }
    
    this.invalidField = '';
  }

  async saveCmsFile(dialog: TemplateRef<any>) {    
    if (!this.setCmsEntity())
      return;

    if (!this.editEntity && !this.cmsUploadFile) {
      this.invalidField = 'Please select a file to upload';
      return;
    }

    delete this.cmsEntityModel.Data;
    this.uploadedFile = await this.cmsService.cmsFileHandler(this.cmsEntityModel, this.cmsUploadFile, this.editEntity);

    if (this.uploadedFile.success) {
      this.dialogRef = this.dialogService.open(dialog, { context: this.uploadedFile });
      this.resetState();
    }

    this.invalidField = '';
  }

  onCustomerSelected(event: IiabIdName): void {
    if (!event?.id) {
      if (this.selectedCustomer){
        const customerReference = { id: this.selectedCustomer.id, name: this.selectedCustomer.name };
        this.cmsEntityModel.CustomerId = this.selectedCustomer.id;
        this.customerLookups.forEach(x => x.onItemSelected(customerReference));
        this.runTemplateLookups.forEach(x => x.onCustomerSelected(this.selectedCustomer));
        this.entitySecurity = CmsEntitySecurity.Secure;
      }
      return;
    }

    event === undefined ? event = null : undefined;

    this.selectedCustomer = event;
    this.cmsEntityModel.CustomerId = this.selectedCustomer.id;
    this.runTemplateLookups.forEach(x => x.onCustomerSelected(event));
    this.entitySecurity = CmsEntitySecurity.Secure;
  }

  onCustomerGroupSelected(event: IiabIdName): void {
    if (!event?.id) 
      return;

    event === undefined ? event = null : undefined;

    this.selectedCustomerGroup = event;
    this.cmsEntityModel.CustomerGroupId = this.selectedCustomerGroup.id;
    this.entitySecurity = CmsEntitySecurity.GlobalSecure;
  }

  onRunTemplateSelected(event: IiabIdName): void {
    if (!event?.id) {
      if (this.selectedRunTemplate){
        const runTemplateReference = { id: this.selectedRunTemplate.id, name: this.selectedRunTemplate.name };
        this.runTemplateLookups.forEach(x => x.onItemSelected(runTemplateReference));
        this.cmsEntityModel.RunTemplateId = this.selectedRunTemplate.id;
        this.entitySecurity = CmsEntitySecurity.Secure;
      }

      return;
    }

    event === undefined ? event = null : undefined;

    this.selectedRunTemplate = event;
    this.cmsEntityModel.RunTemplateId = this.selectedRunTemplate.id;
    this.entitySecurity = CmsEntitySecurity.Secure;
  }

  checkDisplay(): void {
    if ((!this.cmsEntityModel.Display || this.cmsEntityModel.Display.length == 0) && (this.cmsEntityModel.Name?.length > 0))
      this.cmsEntityModel.Display = this.cmsEntityModel.Name;
  }

  //#region Private
  private setCmsEntity(): boolean {
    if (!this.validateEntity())
      return false;

    this.cmsEntityModel.Tags = Array.from(this.tags);
    if (this.cmsEntityModel.Name.includes(' ')) {
      this.cmsEntityModel.Name = this.cmsEntityModel.Name.toLowerCase().replace(/ /g, '_');
    }

    this.cmsEntityModel.RunTemplateId = this.selectedRunTemplate?.id;
    this.cmsEntityModel.CustomerId = this.selectedCustomer?.id;
    this.cmsEntityModel.CustomerGroupId = this.selectedCustomerGroup?.id;

    if (!this.validateSecurity(this.entitySecurity)) {
      return false;
    }

    return true;
  }

  private validateEntity(): boolean {

    if(!this.cmsEntityModel.CustomerId && !this.cmsEntityModel.RunTemplateId && !this.authService.isSystemAdmin){
      this.invalidField = 'Please select a customer and/or run template before saving.'
      return false;
    }

    if (this.cmsEntityModel.Name == undefined || this.cmsEntityModel.Name == '') {
      this.invalidField = 'Please Enter a unique name for the CMS Entity';
      return false;
    }
    if (this.cmsEntityModel?.Data == null || this.cmsEntityModel?.Data == '') {
      this.invalidField = 'Please Enter some content for the CMS Entity';
      return false;
    }
    if (this.cmsEntityModel.Display == undefined || this.cmsEntityModel.Display == '') {
      this.invalidField = 'Please Enter a Display Name for the Cms Entity';
      return false;
    }
    if (this.cmsEntityModel.CmsContentType == undefined) {
      this.invalidField = 'Please select the type of your CMS Entity';
      return false;
    }

    return true;
  }

  private validateSecurity(entitySecurity: CmsEntitySecurity): boolean {

    if (entitySecurity == CmsEntitySecurity.GlobalSecure && this.cmsEntityModel.CustomerGroupId == undefined){
      this.invalidField = 'Please select the Customer Group of your CMS Entity';
      console.log(this.invalidField)
      return false;
    }

    if (entitySecurity == CmsEntitySecurity.Secure && this.cmsEntityModel.CustomerId == undefined && this.cmsEntityModel.RunTemplateId == undefined){
      this.invalidField = 'Please select the Run Template and/or Customer of your CMS Entity';
      console.log(this.invalidField)
      return false;
    }

    switch (this.entitySecurity) {
      case CmsEntitySecurity.GlobalPublic:
        this.cmsEntityModel.CustomerId = null;
        this.cmsEntityModel.RunTemplateId = null;
        this.cmsEntityModel.CustomerGroupId = null;
        break;
    
      case CmsEntitySecurity.GlobalSecure:
        this.cmsEntityModel.CustomerId = null;
        this.cmsEntityModel.RunTemplateId = null;
        break;
    }

    return true
  }

  private setFileName(e) {
    this.cmsUploadFile = e;
    this.cmsEntityModel.Name = e.name.includes('.') ? e.name.split('.')[0] : e.name;
    this.checkDisplay()
  }

  selectVersion(e){
    // this.cmsEntityModel.IsDraft = e === -1 ? true : false;
    this.cmsEntityModel.Version = e;
  }
  //#endregion

  compareByVersion(v1, v2): boolean {    
    return v1 === v2;
  }

  revertCmsEntity(){
    this.cmsService.revertCmsEntity(this.cmsEntityModel.Id, this.cmsEntityModel.Version);
  }

  toggleIsGlobal(event){
    if(event){
      this.customerLookups.forEach(x => {
        x.clear();
        this.selectedCustomer = 
        this.cmsEntityModel.CustomerId = null;
      });
      
      this.runTemplateLookups.forEach(x => {
        x.clear();
        this.selectedRunTemplate = 
        this.cmsEntityModel.RunTemplateId = null;
      });
    }
  }

  setMenuItem(item){
    this.menuItem = item;
  }

  dialogAction(context: string){
    this.dialogRef.close();
    if (context === 'home') this.route.navigateByUrl(`/cms`) 
    else {
      this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.route.navigate([`/cms/add/${this.entityType}`]);
      });
    } 
  }
}
