import { Component } from '@angular/core';

@Component({
  selector: 'ifs-admin-admin-shell',
  styleUrls: ['./admin-shell.component.scss'],
  template: `
    <ifs-activity-bar-shared context="admin">
      <router-outlet></router-outlet>
    </ifs-activity-bar-shared>
  `
})
export class AdminShellComponent {}
