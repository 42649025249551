import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@InfoSlips/iiab-auth';
import { RunStateService } from '@InfoSlips/iiab-state';
import * as models from '@InfoSlips/models';
import { IiabId, RunModel, ProgressCounters } from '@InfoSlips/models';
import { Apollo, gql } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { BookmarkService } from './bookmark.service';
import { fetchIgnoredRunRecipientsData, fetchRunRecipientsSummaryData, fetchRunTrialRecipientsData, _fetchProcessorAuditLogData, _fetchRunAuditLogData, _fetchProgressCountersData, _fetchRunCustomersData, _fetchRunsData, _fetchRunSearchData, _fetchRunSummaryData, _fetchRunTemplatesData, _pagedIgnoredRunRecipientsQuery, _pagedProcessorAuditLogQuery, _pagedRunAuditLogQuery, _pagedRunRecipientSummariesQuery, _pagedRunsQuery, _pagedRunSummariesQuery, _pagedTrialRecipientsQuery, _pageRunCustomersQuery, _pageRunSearchData, _pageRunTemplatesQuery, _progressCountersQuery, _recentRunsSubQuery, _runChangedSubQuery, _runProcessedSubQuery } from './graphql/run-queries';
import { RunsService } from './runs.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedRunService implements OnDestroy {

  runId:string;

  // #region Private member variables
  private selectedRunSubject = new BehaviorSubject<models.RunModel>(null);
  private runStatusSubject = new BehaviorSubject<any>(null);//TODO: Make a type
  private runIsCompiledSubject = new BehaviorSubject<boolean>(false);  
  private runErrorSubject = new BehaviorSubject<string>(null);
  
  private runRecipientSummaries: ObservableWrapper<models.RunRecipientSummary>;

  private runTrialRecipients: ObservableWrapper<models.RunRecipientSummary>;
  private ignoredRunRecipients: ObservableWrapper<models.RunRecipientSummary>;
  private filteredRunRecipients: ObservableWrapper<models.RunRecipientSummary>;
  public  selectedRunRecipients: models.RunRecipientSummary[]=[];
  public processorAuditLog: ObservableWrapper<models.ProcessorLogs>;
  public runAuditLog: ObservableWrapper<models.RunLogs>;
  private progressCounters: ObservableWrapper<models.RunProgressCounters>;
  runRecipientSummariesCount = new BehaviorSubject<number>(null);

  private runTemplates: ObservableWrapper<models.IiabIdName>;
  private runCustomers: ObservableWrapper<models.IiabIdName>;
  private runSearchData: ObservableWrapper<models.RunTrialRecipientSummary>;
  // #endregion
  
  selectedRun$ = this.selectedRunSubject.asObservable();
  runStatus$ = this.runStatusSubject.asObservable();
  runIsCompiled$ = this.runIsCompiledSubject.asObservable();
  processorAuditLog$ = new Observable<models.ProcessorLogs[]>();
  runAuditLog$ = new Observable<models.RunLogs[]>();
  runProgressCounters$ = new Observable<models.RunProgressCounters[]>(); 
  runTrialRecipients$ = new Observable<models.RunRecipientSummary[] | null>();
  ignoredRunRecipients$ = new Observable<models.RunRecipientSummary[]>();
  filteredRunRecipients$ = new Observable<models.RunRecipientSummary[]>();
  runError$ = this.runErrorSubject.asObservable();
  runRecipientSummariesCount$: Observable<number> = this.runRecipientSummariesCount.asObservable();

  runProcessingSubscription: Subscription
  runChangedSubscription: Subscription  

  constructor(
    private apiBaseService: ApiBaseService,
    private runsService: RunsService,
    private toastr: ToastrService,
    private bookmarkService: BookmarkService,
    private router: Router, 
    private apollo:Apollo,
    private authService: AuthService,
    private state: RunStateService
  ) {
    this._constructWrappers();

    this.authService.isAuthenticated$.subscribe(isAuthenticated=>{
      if(!isAuthenticated){
        this._unsubscribeAll();
      }
    });
    
    this.runRecipientSummaries.totalCount$.subscribe(res => this.runRecipientSummariesCount.next(res));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll();
  }

  setRun(run: IiabId) { //TODO: Combine with retrieveRunStatus
    if(run?.id==null)
      {
        this.runErrorSubject.next("Invalid Run Id");
        return;
      }
    this.retrieveRunStatus(run.id);
  }
  
  determineRequiredFiles(run:RunModel):boolean{
    
    let hasRequireFiles = true;
    const uploadedFiles: string[] = [];

    if(!run.PreProcessor)
    {
      hasRequireFiles=true;
      this.runStatusSubject.next({
        hasAllRequiredFiles: hasRequireFiles
      })
      return true;
    }

    run.RunFiles.forEach(res => {
      if(res.Size > 0){
        uploadedFiles.push(res.FileName);
      }
    });

    run.PreProcessor.ClassData.RequiredFiles.forEach(requiredFile => {
      
      run.RunFiles.find(file => {
        if(file.FileName === requiredFile.FileName){
          //@ts-ignore
          file.IsRequired = requiredFile.IsRequired;
        }
      })

      if(!uploadedFiles.includes(requiredFile.FileName)){
        if(requiredFile.IsRequired){
          hasRequireFiles = false;
        }
      }
    });

    this.runStatusSubject.next({
      hasAllRequiredFiles: hasRequireFiles
    })

    return hasRequireFiles;
  }

  isRunCompiled(recipients: any){
    if(recipients === undefined){
      return;
    }
    
    if(recipients.map((recipient:any) => recipient.isCompiled === true)){
      this.runIsCompiledSubject.next(true);
    }
    else {
      this.runIsCompiledSubject.next(false);
    }
  }  
  retrieveRunStatus(runId:string){
    this.apiBaseService.executeGet<RunModel>(`run/${runId}`, "GetRun").subscribe((data: any) => {
      if (data?.Id != this.selectedRunSubject.getValue()?.Id || data?.Status != this.selectedRunSubject.getValue()?.Status) {
        this._setRunSubject(data);
      }
    });
  }
  // TODO: Get the new template information and load as soon as the sync is complete.
  syncRunWithTemplate(id: string){
    this.apiBaseService.executeGet<any>(`run/syncRunWithTemplate/${id}`, "Sync Run With Template").subscribe((results: any) => {
      if (results) {
        this.apiBaseService.executeGet<RunModel>(`run/${id}`, "GetRun").subscribe((data: any) => {
          this.broadCastRunChanged(data);
        });
        this.toastr.success('Template synced successfully!');
      } else {
        this.toastr.error('Template sync failed!');
      }
    });
  }
  filterRunRecipients(filter:any){
    this.filteredRunRecipients.loadPagedItems(null, this.runId, filter);
    this.filteredRunRecipients$ = this.filteredRunRecipients.Items$();
  }
  pageFilteredRunRecipients(){
    this.filteredRunRecipients.loadNextPage();
  }
  loadProcessorLogs(){
    this.processorAuditLog.loadPagedItems(
      { 
        id: {
          eq: this.runId
        }
      }
    );    
    this.processorAuditLog$ = this.processorAuditLog.Items$();
  }
  loadRunLogs(){
    this.runAuditLog.loadPagedItems(
      { 
        id: {
          eq: this.runId
        }
      }
    );    
    this.runAuditLog$ = this.runAuditLog.Items$();
  }
  loadIgnored(filter:any){
    this.ignoredRunRecipients.loadPagedItems(filter, this.runId);
    this.ignoredRunRecipients$ = this.ignoredRunRecipients.Items$();
  }
  
  broadCastRunChanged(run:RunModel){
    this.selectedRunSubject.next(run);
  }

  getRecipientCount(){
    if (!this.runId) return;

    this.runRecipientSummaries.loadPagedItems(null, this.runId);
  }

  loadTrialRecipients(){
    this.runTrialRecipients.loadPagedItems({ isMarkedForTrial: { eq: true }}, this.runId);
    this.runTrialRecipients$ = this.runTrialRecipients.Items$();
  }

  mayExpireRun() {
    if (this.authService.loggedInUser.permissions.indexOf("System Administrator") != -1 || this.authService.loggedInUser.permissions.indexOf("May Expire Run") != -1)
      return true;
    else return false
  }

  pageProcessorAuditLogs() {
    this.processorAuditLog.loadNextPage();
  }

  pageRunAuditLogs() {
    this.runAuditLog.loadNextPage();
  }

  // #region Private
  private _setRunSubject = (run: RunModel): any => {
    if (run?.Id == null)
    {
      this.runErrorSubject.next("Run does not exist");
      this.runsService.setRunSubject(null);
      return;
    }

    const previousRunId = this.runId;
    this.runId = run.Id;
    
    if(previousRunId != this.runId){
      this._unsubscribeAll();
      this._subscribeTo(this.runId);
    }
    
    this.selectedRunSubject.next(run);
    this.runsService.setRunSubject(run);

    this._buildRunMenu(run);
    this.determineRequiredFiles(run);
    this.bookmarkService.isStar(this.router.url);
    
    this.runTrialRecipients$ = null;
    this.runTrialRecipients.loadPagedItems({ isMarkedForTrial: { eq: true }}, this.runId);
    this.runTrialRecipients$ = this.runTrialRecipients.Items$();

    this.runRecipientSummaries.loadPagedItems(null, this.runId);
    
    this.progressCounters.loadPagedItems({ id: { eq: run.Id}});    
    this.runProgressCounters$ = this.progressCounters.Items$();
  }
  private _determineCanBeSentLive(run: RunModel){
    this.apiBaseService.executeGet<any>(`run/MayStartRun/${run.Id}`, "May Start Run").subscribe((result: any) => {
      return false
    });

    const processedStatus = run.Instance.ProgressCounters.find(({Name}) => Name.toLowerCase() === "preparing")?.Status;
    
    if(run?.IsTrial && run?.Status === 'Complete' || !run?.IsTrial && run?.Status === 'Scheduled' || processedStatus == 3){
      return true;
    } else {
      return false;
    }
  }
  private _buildRunMenu(run: RunModel){
    const runMenu = [
      {
        name: "Overview", //0
        visible: true
      },
      {
        name: "Send a Trial", //1
        visible: run?.IsTrial && run?.Status !== 'Created'
      },
      {
        name: "Live Run", //2
        visible: this._determineCanBeSentLive(run)
      },
      {
        name: "Upload Data", //3
        visible: !run.PreProcessor ? false : true
      },
      {
        name: "Recipients", //4
        badge: true,
        visible: true
      },    {
        name: "Not to be Sent", //5
        visible: true
      },
      {
        name: "Processor Logs", //6
        visible: this._showProcLogs(run)
      },
      {
        name: "Run Logs", //7
        visible: true
      }
    ];

    this.state.RunMenu = runMenu;
    run = Object.assign(run, { runMenu: runMenu})
  }
  private _getTrialRecipientsQuery(runId:string): any {
    return {
      "run": {
        "id": {
          "eq": runId
        }
      },
      "and": {
        "isMarkedForTrial": {
          "eq": true
        }
      }
    };
  }
  private _constructWrappers() {
    this.runRecipientSummaries = new ObservableWrapper<models.RunRecipientSummary>(
      this.apiBaseService,
      _pagedRunRecipientSummariesQuery(),
      fetchRunRecipientsSummaryData,
      false);

    this.runTrialRecipients = new ObservableWrapper<models.RunRecipientSummary>(
      this.apiBaseService,
      _pagedTrialRecipientsQuery(),
      fetchRunTrialRecipientsData,
      false);

    this.ignoredRunRecipients = new ObservableWrapper<models.RunRecipientSummary>(
      this.apiBaseService,
      _pagedIgnoredRunRecipientsQuery(),
      fetchIgnoredRunRecipientsData,
      false);

    this.processorAuditLog = new ObservableWrapper<models.ProcessorLogs>(
      this.apiBaseService,
      _pagedProcessorAuditLogQuery(),
      _fetchProcessorAuditLogData,
      false);

    this.runAuditLog = new ObservableWrapper<models.RunLogs>(
      this.apiBaseService,
      _pagedRunAuditLogQuery(),
      _fetchRunAuditLogData,
      false);

    this.progressCounters = new ObservableWrapper<models.RunProgressCounters>(
      this.apiBaseService,
      _progressCountersQuery(),
      _fetchProgressCountersData,
      false);

    this.runSearchData = new ObservableWrapper<models.RunTrialRecipientSummary>(
      this.apiBaseService,
      _pageRunSearchData(),
      _fetchRunSearchData,
      false);

    if(this.filteredRunRecipients==null){
      this.filteredRunRecipients=new ObservableWrapper<models.RunRecipientSummary>(
        this.apiBaseService,
        _pagedRunRecipientSummariesQuery(),
        fetchRunRecipientsSummaryData,
        false); 
    }
  }
  private _subscribeTo(runId:string){
    this.runChangedSubscription = this.apollo.subscribe<string>(
      {
        query: gql(_runChangedSubQuery(runId))
      })
    .subscribe(
      data=>{
        this.retrieveRunStatus(runId);
      },
      err=>{console.error(err)}
    );
    this.runProcessingSubscription = this.apollo.subscribe<string>(
      {
        query: gql(_runProcessedSubQuery(runId))
      })
    .subscribe(
      data=>{
        this.progressCounters.loadPagedItems({ id: { eq: this.runId}});    
        this.runProgressCounters$ = this.progressCounters.Items$();
      },
      err=>{console.error(err)}
    );

  }
  private _unsubscribeAll(){    
    if(this.runChangedSubscription){
      this._unsubscribeFrom(this.runChangedSubscription,'runChangedSubscription');    
      this.runChangedSubscription=null;
    }
    if(this.runProcessingSubscription){
      this._unsubscribeFrom(this.runProcessingSubscription,'ProcessingStatus');
      this.runProcessingSubscription=null;
    }
  }
  private _unsubscribeFrom(sub:Subscription, name:string)
  {
    if(sub && !sub.closed)
    {
      try{
        sub.unsubscribe();
      }
      catch(e){console.error(e)}
    }
  }
  private _showProcLogs(run?:RunModel){
    if(run===null || run.RunFiles==null || run.RunFiles.length==0)
      return false;

    return run.RunFiles.some(file=>(file.FileName!=="ConfigForProcessing" && file.Size>0));
  }
  // #endregion
}
