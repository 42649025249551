import { PageGraphQLData } from '@InfoSlips/models';

/* eslint-disable @typescript-eslint/no-explicit-any */
const RunReports: ReportObject[] = [
  {
    id: 'DELIVERY_REPORT',
    name: 'Delivery Report',
    icon: 'task-checklist-check',
    description: 'Combination of Global and Run Delivery Reports.',
    graphql: {
      model: 'outputChannelLogs',
      query: _getOutputChannelLogsQuery,
      mapData: _genericDataMapper,
      operationName: 'OutputChannelLogs',
      download: _downloadOutputChannelLogsQuery,
    },
    filters: {
      companySelect: true,
      runRecipientLike: true,
      runSelect: true,
      channelSelect: true,
      isSentSelect: true,
      isDeliveredSelect: true,
      isTrialSelect: true,
      startDateInput: true,
      endDateInput: true,
    },
    isGeneric: true,
    wip: false,
  },
  {
    id: 'RESENDS',
    name: 'Resends',
    icon: 'filter-mail',
    description: 'placeholder',
    search:
      'OutputChannelLog?$inlinecount=allpages&$format=json&$top=10&$filter=(',
    download: 'OutputChannelLog/asyncreport/ResendsReport/?',
    graphql: {
      model: 'reportResendAndForwardBilling',
      query: _getResendAndForwardBillingReportQuery, // TODO: Can't do a where query on resends? Why?
      mapData: _genericDataMapper, // TODO: Can't do a where query on resends? Why?
      operationName: 'ResendAndForwardBillingReport',
    },
    filters: {
      companySelect: true,
      runSelect: true,
      // externalIdInput: true, : TODO: No filter for this on Graphql and no nodes.
      isResendSelect: true,
      billingPeriodSelect: true
    },
    requiredFilters: [
      {
        filterName: "billingPeriod",
        filterDescription: "Billing Period"
      }
    ],
    isGeneric: true,
    wip: true,
  },
  {
    id: 'RUN_STATISTICS',
    name: 'Run Statistics',
    icon: 'pie-line-graph-desktop',
    description: 'placeholder',
    download: 'Run/runstatisticsreport/asyncreport/RunStatisticsReport/?',
    graphql: {
      model: 'runStatistics',
      query: _getRunStatisticsReportQuery,
      mapData: _genericDataMapper,
      operationName: 'RunStatisticsReport'
    },
    filters: {
      companySelect: true,
      runSelect: true,
      runTemplateSelect: true,
      startDateInput: true,
      endDateInput: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'MAIL_RESPONSE_LOG',
    name: 'Mail Response Log',
    description: 'placeholder',
    icon: 'performance-increase-1',
    graphql: {
      model: 'mailResponseLog',
      query: _getMailResponseLogsQuery,
      mapData: _genericDataMapper,
      operationName: 'MailResponseLogs',
      download: _downloadMailResponseLogsQuery, // TODO: Not Implemented, awaiting backend endpoint
    },
    filters: {
      // companySelect: true, // TODO: No Graphql filter for this field.
      runSelect: true,
      // externalIdInput: true,  // TODO: No Graphql filter for this field.
      // mailChannelSelect: true, // TODO: No Graphql filter for this field.
      startDateInput: true,
      endDateInput: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'URL_TRACKING_REPORTING',
    name: 'Url Tracking Report',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'urlTracking',
      query: _getUrlTrackingQuery,
      mapData: _genericDataMapper,
      operationName: 'UrlTrackingReport',
      download: _downloadUrlTrackingReport, // TODO: Not Implemented, awaiting backend endpoint
    },
    filters: {
      startCreatedDateInput: true,
      endCreatedDateInput: true,
      companySelect: true,
      runSelect: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'VIEWER_POST_REPORTING',
    name: 'Viewer Post',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'viewerPosts',
      query: _getViewerPostsQuery,
      mapData: _genericDataMapper,
      operationName: 'ViewerPosts',
      download: _downloadViewerPostsQuery, // TODO: Not Implemented, awaiting backend endpoint
    },
    filters: {
      customerIdInput: true,
      runIdInput: true,
      runTemplateIdInput: true,
      externalIdInput: true,
      startCreatedDateInput: true,
      endCreatedDateInput: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'HOT_SPOT_REPORTING',
    name: 'Hot Spot report',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'hotSpotItems',
      query: _getHotSpotItemsQuery,
      mapData: _genericDataMapper,
      operationName: 'HotSpotItems',
      download: _downloadHotSpotItemsQuery, // TODO: Not Implemented, awaiting backend endpoint
    },
    filters: {
      runSelect: true,
      recipientIdInput: true,
      sequenceInput: true,
      companySelect: true,
      // coordinatesInput: true, No filter for this on Graphql
      // htmlElementIdInput: true,  No filter for this on Graphql
      // parentHtmlElementIdInput: true, No filter for this on Graphql
      labelInput: true,
      tagInput: true,
      actionInput: true,
    },
    isGeneric: true,
    wip: true,
  },
].filter(Boolean);

export const graphqlFilters = {
  nameLike: function (variable) {
    this.filter = {
      name: {
        like: variable,
      },
    };
  },
};

const GeneralReports: ReportObject[] = [
  {
    id: 'RECIPIENTS_BLACKLIST_REPORT',
    name: 'Recipients Blacklist Report',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'recipientsBlackList',
      query: _getRecipientsBlackListQuery,
      mapData: _genericDataMapper,
      operationName: 'RecipientsBlackList',
      download: _downloadRecipientBlacklistQuery,
    },
    filters: {
      companySelect: true,
      filterByEmail: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'SYSTEM_USERS_REPORT',
    name: 'System Users Report',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'users',
      query: _getSystemUsersQuery,
      mapData: _genericDataMapper,
      operationName: 'SystemUsers',
      download: _downloadSystemUsersQuery,
    },
    filters: {
      customerFilter: true,
      displayNameInput: true,
      emailInput: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'BILLING_REPORTING',
    name: 'Billing Reports',
    description: 'placeholder',
    icon: 'task-checklist-check',
    download: `report/downloadbillingreports/`,
    graphql: {
      model: 'reportRunBilling',
      query: _getBillingReportQuery,
      mapData: _genericDataMapper,
      operationName: 'BillingReport',
    },
    filters: {
      billingPeriodSelect: true,
    },
    requiredFilters: [
      {
        filterName: "billingPeriod",
        filterDescription: "Billing Period"
      }
    ],
    isGeneric: true,
    wip: true,
  },
  // {
  //   id: 'QUEUE_ACTIVITY_REPORTING',
  //   name: 'Queue Activity Report',
  //   description: 'placeholder',
  //   icon: 'task-checklist-check',
  //   graphql: {
  //     model: null,
  //     query: null,
  //     mapData: null,
  //     operationName: null,
  //     download: null,
  //   },
  //   filters: null,
  //   isGeneric: false,
  //   wip: true,
  // },
  // Temporarily removed waiting on DevOps
  {
    id: 'SMS_RESPONSE_LOG_REPORTING',
    name: 'Sms Response Log',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'smsResponseLog',
      query: _getSmsResponseLogQuery,
      mapData: _genericDataMapper,
      operationName: 'SmsResponseLog',
      download: _downloadSmsResponseLogQuery,
    },
    filters: {
      startCreatedDateInput: true,
      endCreatedDateInput: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'ADHOC_VIEW_LOG_REPORTING',
    name: 'Adhoc Views Log',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'adhocViewLogs',
      query: _getAdhocViewLogsQuery,
      mapData: _genericDataMapper,
      operationName: 'AdhocViewLogs',
      download: _downloadAdhocViewsLogQuery,
    },
    filters: {
      companySelect: true,
      runTemplateSelect: true,
      startCreatedDateInput: true,
      endCreatedDateInput: true,
    },
    isGeneric: true,
    wip: true,
  },
  {
    id: 'ADHOC_FORWARD_DELIVERIES_REPORT',
    name: 'Adhoc Forward Delivery Report',
    description: 'placeholder',
    icon: 'task-checklist-check',
    graphql: {
      model: 'adhocViewLogs',
      query: _getAdhocViewLogsQuery,
      mapData: _genericDataMapper,
      operationName: 'AdhocViewLogs',
      download: _downloadAdhocViewsLogQuery,
    },
    filters: {
      companySelect: true,
      runTemplateSelect: true,
      sendToInput: true,
      startCreatedDateInput: true,
      endCreatedDateInput: true,
    },
    isGeneric: true,
    wip: true,
  },
].filter(Boolean);

const AllReports: ReportObject[] = [...RunReports, ...GeneralReports];

export const Reports = {
  AllReports: defineRoutes(AllReports),
  RunReports: defineRoutes(RunReports),
  GeneralReports: defineRoutes(GeneralReports),
};

export interface ReportObject {
  id: string;
  name: string;
  search?: string;
  download?: string;
  graphql?: {
    model: string;
    query: any;
    mapData: any;
    operationName: string;
    download?: any;
  };
  description: string;
  endpoint?: string;
  icon: string;
  route?: any;
  filters?: any;
  requiredFilters?: RequiredFilter[];
  isGeneric: boolean;
  wip?: boolean;
}

export interface Filters {
  companySelect?: boolean;
  customerFilter?: boolean;
  runSelect?: boolean;
  runTemplateSelect?: boolean;
  sendToInput?: boolean;
  channelSelect?: boolean;
  isSentSelect?: boolean;
  isTrialSelect?: boolean;
  startDateInput?: boolean;
  endDateInput?: boolean;
  recipientNameInput?: boolean;
  isDeliveredSelect?: boolean;
  externalIdInput?: boolean;
  isResendSelect?: boolean;
  mailChannelSelect?: boolean;
  emailTrackingSelect: boolean;
  runIdInput?: boolean;
  customerIdInput?: boolean;
  runTemplateIdInput?: boolean;
  recipientIdInput?: boolean;
  sequenceInput?: boolean;
  userAgentInput?: boolean;
  coordinatesInput?: boolean;
  htmlElementIdInput?: boolean;
  parentHtmlElementIdInput?: boolean;
  labelInput?: boolean;
  tagInput?: boolean;
  actionInput?: boolean;
  filterByEmail?: boolean;
  emailInput?: boolean;
  startCreatedDateInput?: boolean;
  endCreatedDateInput?: boolean;
  billingPeriodSelect?: boolean;
  displayNameInput?: boolean;
  runRecipientLike?: boolean;
}

export interface RequiredFilter {
  filterName: string;
  filterDescription: string;
}

function defineRoutes(reports: any) {
  return reports.map((item) => {
    const report = Object.assign({}, item);
    report.route = `${item.name.toLowerCase().replace(/\s+/g, '-')}`;
    return report;
  });
}

function _genericDataMapper<T>(
  rawData: any,
  model: string
): Promise<PageGraphQLData<T>> {
  try {
    return new Promise((resolve) => {
      resolve(
        rawData.data[model].edges.map(
          (item: any) => item.node
        ) as PageGraphQLData<T>
      );
    });
  } catch (e) {
    console.log(`${model} FAILED`, rawData);
    return null;
  }
}

// Graphql Queries
function _getOutputChannelLogsQuery(filters: any) {
  return `query OutputChannelLogs($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null) { 
            outputChannelLogs(first: $first, after: $afterCursor, before: $beforeCursor, where: {
             ${filters}
            }){
              totalCount,
              pageInfo{
                hasNextPage,
                hasPreviousPage,
                startCursor,
                endCursor
              }
          edges {
            node {
              id
              channel
              customer {
                name
                id
              }
              run {
                name
                id
              }
              mccmnc
              units
              cost
              sendTo
              isSent
              isDelivered
              isTrial
              sendDate
              messageId
              resultMessage
              isResend
              isForward
              filesSent {
                fileName
                sequence
                size
                pages
                contentType
                reportingMetaData
              }
              runRecipient {
                name
                id
                externalId
              }
              lastUpdated
            }
          }
        }
      }`;
}

function _getResendAndForwardBillingReportQuery(filters: any) {
  return `query ResendAndForwardBillingReport($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $billingPeriod: String = null) { 
    reportResendAndForwardBilling(first: $first, after: $afterCursor, before: $beforeCursor, billingPeriod: $billingPeriod, where: {
      ${filters}
    }){
      totalCount,
      pageInfo{
        hasNextPage,
        hasPreviousPage,
        startCursor,
        endCursor
      }
      edges {
        node {
          id
          run {
            name
            id
          }
          customer {
            name
            id
          }
          lastUpdated
        }
      }
    }
  }`;
}

function _getViewerPostsQuery(filters: any) {
  return `query ViewerPosts($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null) { 
        viewerPosts(first: $first, after: $afterCursor, before: $beforeCursor, where: {
             ${filters}
            }){
              totalCount,
              pageInfo{
                hasNextPage,
                hasPreviousPage,
                startCursor,
                endCursor
              }
              edges {
                node {
                    created
                    createdBy
                    customerId
                    externalId
                    id
                    label
                    lastUpdated
                    lastUpdatedBy
                    processed
                    runId
                    runRecipientId
                    runTemplateId
                }
            }
        }
    }`;
}

function _getMailResponseLogsQuery(filters: any) {
  return `query MailResponseLogs($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null) { 
      mailResponseLog(first: $first, after: $afterCursor, before: $beforeCursor, where: {
        ${filters}
      }){
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges {
          node {
            id
            lastUpdated
            customer {
              name
              id
            }
            run {
              name
              id
            }
            runRecipient {
              name
              id
              externalId
            }
            to
            type
            responseText
          }
        }
      }
    }`;
}

function _downloadMailResponseLogsQuery(filters: any) {
  throw new Error('Not Implemented, awaiting backend endpoint.');
}

function _getHotSpotItemsQuery(filters: any) {
  return `query HotSpotItems($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $customerId: String = null) { 
        hotSpotItems(first: $first, after: $afterCursor, before: $beforeCursor, customerId: $customerId, where: {
             ${filters}
        }){
            totalCount,
            pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
            }
        edges {
          node {
            action
            coordinates {
                x
                y
            }
            created
            createdBy
            customerId
            htmlElementId
            id
            label
            lastUpdated
            lastUpdatedBy
            parentHtmlElementId
            recipientId
            runId
            sequence
            tags
            userAgent
          }
        }
      }
    }`;
}

function _getRecipientsBlackListQuery(filters: any) {
  return `query RecipientsBlackList($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $customerId: String = null) { 
      recipientsBlackList(first: $first, after: $afterCursor, before: $beforeCursor, customerId: $customerId, where: {
             ${filters}
        }){
            totalCount,
            pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
            }
            edges {
              node {
                channel
                created
                createdBy
                id
                lastUpdated
                lastUpdatedBy
                reason
                recipient
                uniqueId
              }
            }
          }
        }`;
}

function _getSystemUsersQuery(filters: any) {
  return `query SystemUsers($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null) { 
      users(first: $first, after: $afterCursor, before: $beforeCursor, where: {
             ${filters}
        }){
            totalCount,
            pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
            }
            edges {
              node {
                displayName
                email
                lastLoginDate
                roleGroups
                customerFilter
              }
            }
          }
        }`;
}

function _getBillingReportQuery(filters: any) {
  return `query BillingReport($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $billingPeriod: String = null) { 
      reportRunBilling(billingPeriod: $billingPeriod, first: $first, after: $afterCursor, before: $beforeCursor, where: {
        ${filters}
      }){
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges {
          node {
            autoLinkAttachmentCount
            billed
            billingGroup
            billingPeriod
            compiled
            consolidatedCount
            created
            createdBy
            customer {
              name
              billingGroup
              id
            }
            dynamicAttachmentCount
            emailCountDisabled
            emailCountForward
            emailCountResend
            emailIfsAttachments
            emailPdfAttachments
            emailPdfPages
            emailReprocessed
            emailReprocessedPdfPages
            emailSent
            emailSentFailed
            emailSentSuccess
            emailStageReprocessedCount
            emailTotal
            endTime
            exportDisabledCount
            exportIfsFail
            exportIfsTotal
            exportPdfFail
            exportPdfPages
            exportPdfTotal
            exportReprocessed
            exportReprocessedPdfPages
            exportStageReprocessedCount
            exportTotal
            id
            lastUpdated
            lastUpdatedBy
            monthToDate
            newRecipients
            processingTime
            processingTimeFormat
            run {
              bilingDateDescription
              runDate
              name
              id
            }
            runDate
            runStatus
            runTemplate {
              name
              id
            }
            startTime
            staticAttachmentCount
            totalAttachmentCount
            totalRunRecipients
          }
        }
      }
    }`;
}

function _getSmsResponseLogQuery(filters: any) {
  return `query SmsResponseLog($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null) { 
      smsResponseLog(first: $first, after: $afterCursor, before: $beforeCursor, where: {
             ${filters}
        }){
            totalCount,
            pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
            }
            edges {
              node {
                id
                created
                createdBy
                outputChannelLogId
                lastUpdated
                lastUpdatedBy
              }
            }
          }
        }`;
}

function _getAdhocViewLogsQuery(filters: string) {
  const useTotalCount = filters.includes("created") ? true : false;
  return `query AdhocViewLogs($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $customerId: String = null) { 
      adhocViewLogs(first: $first, after: $afterCursor, before: $beforeCursor, customerId: $customerId, where: {
             ${filters}
        }){
            ${useTotalCount ? 'totalCount,' : ''}
            pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
            }
            edges {
              node {
                id
                customer {
                  name
                  id
                }
                created
                runTemplateId
                isSucess
                adhocId
                sendLog {
                  sendTo
                  sendBy
                }
              }
            }
          }
        }`;
}

function _getRunStatisticsReportQuery(filters?: any) {
  return `query RunStatisticsReport($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $runId: String = null, $runTemplateId: String = null, $customerId: String = null, $startDate: DateTime = null, $endDate: DateTime = null, $downloadCsv: Boolean = false) { 
            runStatistics(first: $first, after: $afterCursor, before: $beforeCursor, runId: $runId, runTemplateId: $runTemplateId, customerId: $customerId, startDate: $startDate, endDate: $endDate, downloadCsv: $downloadCsv, where: {
             ${filters}
            }){
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                node {
                  id
                  name
                  bilingDate {
                    type
                    dateTime
                    description
                  }
                  instance {
                    completed
                    started
                    statistics {
                      newRecipientCount
                      mailProcessedCounter
                      mailSentFailCounter
                      smsTotalSent
                      smsFailed
                      exportPdfCount
                      exportPdfPages
                      exportPdfFail
                      exportIfsCount
                      exportIfsFail
                    }
                  }
                }
              }
            }
          }`;
}

function _getUrlTrackingQuery(filters?: any) {
  return `query UrlTrackingReport($first: Int = 10, $afterCursor: String = null, $beforeCursor: String = null, $customerId: String = null) { 
            urlTracking(first: $first, after: $afterCursor, before: $beforeCursor, customerId: $customerId, where: {
             ${filters}
            }){
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                node {
                  created
                  userAgent
                }
              }
            }
          }`;
}

function _downloadOutputChannelLogsQuery(filters: any) {
  return `query OutputChannelLogs { 
        downloadRunDeliveriesReport(where: {
          ${filters}
        }){
      id
    }
  }`;
}

function _downloadHotSpotItemsQuery(filters: any) {
  return `query HotSpotItems ($customerId: String = null) { 
        downloadHotSpotReport(customerId: $customerId, where: {
          ${filters}
        }){
      id
    }
  }`;
}

function _downloadViewerPostsQuery(filters: any) {
  return `query ViewerPosts { 
        downloadViewerPostsReport(where: {
          ${filters}
        }){
      id
    }
  }`;
}

function _downloadRecipientBlacklistQuery(filters: any) {
  return `query RecipientBlacklistReport ($customerId: String = null) { 
    downloadRecipientsBlackListReport(customerId: $customerId, where: {
      ${filters}
    }){
      id
    }
  }`;
}

function _downloadSystemUsersQuery(filters: any) {
  return `query SystemUsers { 
    downloadSystemUsersReport (where: {
      ${filters}
    }){
      id
    }
  }`;
}

function _downloadBillingPeriodQuery(filters: any) {
  throw new Error('Not Implemented, awaiting backend endpoint.');
}

function _downloadSmsResponseLogQuery(filters: any) {
  return `query SmsResponseLog { 
        downloadSmsResponseLogReport(where: {
          ${filters}
        }){
      id
    }
  }`;
}

function _downloadAdhocViewsLogQuery(filters: any) {
  return `query AdhocViewLogs ($customerId: String = null) { 
        downloadAdhocViewLogsReport(customerId: $customerId, where: {
          ${filters}
        }){
      id
    }
  }`;
}

function _downloadUrlTrackingReport(filters: any) {
  return `query UrlTrackingReport ($customerId: String = null) { 
        downloadUrlTrackingReport(customerId: $customerId, where: {
          ${filters}
        }){
      id
    }
  }`;
}

/**
 * Creates a Config with all required reports.
 * @param {Reports} config The Report Configuration: AllReports | RunReports | GeneralReports
 * @default AllReports
 * @returns {ReportObject} Report Object.
 */
// export function getReports(config?: string) {
//     console.log((Reports)[config]);

//     return (Reports)[config] ?? Reports['AllReports'];
// }
