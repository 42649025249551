export interface IiabId{
    id:string;
}

export interface IiabIdName extends IiabId {
    name: string
}

export interface IiabBilling extends IiabIdName {
    billingGroup: string
}

export interface IiabEntity extends IiabIdName {
    created?:       string;
    createdBy?:     string;
    lastUpdated?:   string;
    lastUpdatedBy?: string;
}