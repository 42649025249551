import { IiabId } from "./iiab-reference";

export interface DomainReference extends IiabId {
    allowAllOnOnDomain:             boolean;
    created:                      string;
    createdBy:                    string;
    domain:                       string;
    emailAddress:                 string[];
    lastUpdated:                  string;
    lastUpdatedBy:                string;
}