<div class="general-page-container fit-absolute">
    <div class="general-page-header">
        <ng-container>
          <div class="form-group">
            <label>Customer</label>
            <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth
              name="Customer" placeholder="Customer name or id" [(ngModel)]="selectedCustomer" [ngModelOptions]="{standalone: true}" [ngModelOptions]="{standalone: true}"/>
            <button *ngIf="hasKeys(filterObject)" class="col-md-4" size="small" nbButton status="primary" (click)="searchCustomers()">
              Search
            </button>
          </div>
        </ng-container>
      </div>
    <div class="general-page-body">
      <div class="general-page-body">
        <div class="scroll p-10">
          <ng-container *ngIf="filteredOptions$ | async as customers">
            <div *ngFor="let customer of customers">
              <nb-reveal-card [showToggleButton]="false" [revealed]="revealed" class="card mb-10 shadow">
                <nb-card-front>
                  <nb-card>
                    <nb-card-header class="card-header p-10 reveal-card" (click)="selectCustomer(customer)">
                      <p class="card-title">
                        {{customer.name}}
                        <small>{{ customer?.billingGroup ? customer?.billingGroup : undefined}}</small>
                      </p>
                    </nb-card-header>
                    <nb-card-body>
                      <ng-container *ngTemplateOutlet="customerId; context: {customerId: customer?.id}"></ng-container>
                      <div class="actions">
                        <button nbButton (click)="delete(customer?.id, false)">
                          <nb-icon [icon]="deleteConfirm ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
                        </button>
                        <button nbButton (click)="deleteConfirm ? delete(customer?.id, true) : toggleView()" [status]="deleteConfirm ? 'danger' : 'basic'">
                          <nb-icon [icon]="deleteConfirm? 'check-circle' : 'info-circle'" pack="oakmoon" status="info"></nb-icon>
                        </button>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </nb-card-front>
                <nb-card-back>
                  <nb-card>
                    <nb-card-header class="card-header p-10 reveal-card" (click)="selectCustomer(customer)">
                      <p class="card-title small">
                        <b>Created: </b><small>({{customer?.created?.split('T')[0]}})</small><br/>
                        <small>{{customer?.createdBy}}</small>
                      </p>
                      <p class="card-title small" *ngIf="customer?.lastUpdatedBy">
                        <b>Modified: </b><small>({{customer?.lastUpdated?.split('T')[0]}})</small><br/>
                        <small>{{customer?.lastUpdatedBy}}</small>
                      </p>
                    </nb-card-header>
                    <nb-card-body>
                      <ng-container *ngTemplateOutlet="customerId; context: {customerId: customer?.id}"></ng-container>
                      <div class="actions">
                        <button nbButton (click)="delete(customer?.id, false)">
                          <nb-icon [icon]="deleteConfirm ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
                        </button>
                        <button nbButton (click)="deleteConfirm ? delete(customer?.id, true) : toggleView()" [status]="deleteConfirm ? 'danger' : 'basic'">
                          <nb-icon [icon]="deleteConfirm? 'check-circle' : 'navigate-left'" pack="oakmoon" status="info"></nb-icon>
                        </button>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </nb-card-back>
              </nb-reveal-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="general-page-footer">
      <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
        Cancel
      </button>
      <button class="col-md-6" size="small" nbButton status="primary" routerLink="/admin/customers">
        Create
      </button>
    </div>
  </div>

  <ng-template #customerId let-customerId="customerId">
    <p class="customer-id">
      <small>{{ customerId }}</small>
      <ifs-copy [value]="customerId"></ifs-copy>
    </p>
  </ng-template>