import { Router } from '@angular/router';
import { infoslipAnimation } from './../../../../../shared/src/lib/shared.module';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { InfoSlip, Tab } from './../../models/infoslip';
import { map, filter } from 'rxjs/operators';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NbDialogService, NbMenuService, NbTagComponent, NbTagInputAddEvent } from '@nebular/theme';
import { InfoSlipsService, ThemeService } from '@InfoSlips/iiab-api';
import { ForwardRequestModel } from '@InfoSlips/models';
import { AntiForgeryService } from '@InfoSlips/iiab-anti-forgery';

@Component({
  selector: 'ifs-view-modal',
  animations: [infoslipAnimation],
  template: `
    <div class="modal" [class.adhoc]="isAdhoc" [class.theme]="theme !== 'InfoSlips'" *ngIf="infoslipDetails$ | async as infoslip">
    <ng-template #forwardDialog let-data let-ref="dialogRef" style="z-index:99999 !important">
    <nb-card>
        <nb-card-header>Forward Document</nb-card-header>
        <nb-card-body>

    <form [formGroup]="forwardForm" (ngSubmit)="forwardDocument()">
        <label>To:</label>
        <nb-tag-list (tagRemove)="onEmailRemove($event)">
          <nb-tag status="primary" *ngFor="let email of forwardEmails" [text]="email" removable></nb-tag>
          <input #emailInput type="text" nbTagInput (tagAdd)="onEmailAdd($event)" fullWidth>
        </nb-tag-list>

        <div class="form-group">
          <label>Subject (Optional)</label>  
          <input
              formControlName="subject"
              nbInput
              fullWidth
              fieldSize="medium"
              type="text"
              placeholder="Please enter your subject (optional)"
            />
        </div>
      </form>
      </nb-card-body>
    <nb-card-footer>
          <button class="mr-10" outline nbButton status="default" (click)="ref.close()">
        Cancel
      </button>
          <button
            [disabled]="forwardForm.invalid"
            nbButton
            status="primary"
            (click)="forwardDocument()"
          >
            Send
          </button>
        </nb-card-footer>
                </nb-card>
                </ng-template>
      <div class="modal-dialog modal-xl" role="dialog" aria-hidden="true">
        <div @infoslipAnimation class="modal-content pt-0">
          <div class="modal-header">
              <div class="branding" *ngIf="!isAdhoc && !isOnlineViewLink && !isThemed">
                <ifs-icon icon="ifs-logo-icon" color="#7e7e7e" width="35px" height="35px"></ifs-icon>
              </div>
              <div class="header-nav" *ngIf="infoslip.Tabs && infoslip.Tabs.length > 1">
                <a
                  class="link nav-text"
                  [ngClass]="{
                    active: tab.StartPage === iframeUrl
                  }"
                  *ngFor="let tab of infoslip?.Tabs; let i = index"
                  (click)="changeTab(tab)"
                  id="{{'ifsTabIndex'+i}}"
                >
                  {{ tab.TabName }}
                </a>
              </div>
              <div class="header-nav-mobile" *ngIf="infoslip.Tabs && infoslip.Tabs.length > 1">
              <nb-icon [nbContextMenu]="tabs"  nbContextMenuTag="header-menu" icon="navigation-menu-1" pack="oakmoon" class="text-white"></nb-icon>
              </div>

              <div class="header-actions">
                <ng-content select="ifs-document-actions"></ng-content>
                <button id="actionForward" nbButton *ngIf="isAdhoc && infoslip.AllowAdhocForward" (click)="open()">
                  <nb-icon icon="share" pack="oakmoon"></nb-icon>Forward
                </button>
                <a *ngIf="!isAdhoc && !isOnlineViewLink && !isOneTime" (click)="closeInfoSlip()" class="link nav-text close">
                  <nb-icon pack="oakmoon" icon="close" status="control"></nb-icon>
                </a>
              </div>


            </div>

          <div
            class="modal-body row"
            style="-webkit-overflow-scrolling:touch; overflow: auto"
          >
            <ng-content select="ifs-document-search"></ng-content>
            <ng-container *ngIf="iframeUrl">
            <object [data]="iframeUrl | safeIframe" width="100%" height="100%">
              <embed id="infoslipIframe" #infoslipIframe [src]="iframeUrl | safeIframe" width="100%" height="100%">
            </object>
                </ng-container>
          </div>
        </div>
    </div>
    <div class="modal-backdrop" aria-hidden="true"></div>
  `,
  styleUrls: ['./view-modal.component.scss']
})
export class ViewModalComponent implements OnInit {
  @Input()
  // infoslipDetails$: Observable<InfoSlipState>;
  infoslipDetails$: any;
  infoslipObject$: Observable<InfoSlip>;
  iframeUrl: string;
  @ViewChild('infoslipIframe', { static: false }) infoslipIframe: ElementRef;
  @ViewChild('emailInput') emailInput;
  @Output() tabChange = new EventEmitter<Tab>();
  @Input() theme;
  isAdhoc: boolean = this.router.url.toLowerCase().includes('adhoc');
  isOneTime: boolean = this.router.url.toLowerCase().includes('onetime');
  isOnlineViewLink: boolean = this.router.url.toLowerCase().includes('link') || this.router.url.toLowerCase().includes('onetime');
  forwardModal = false;
  isAdhocExpired: boolean;
  forwardForm = this.fb.group({
    subject: ['']
  });
  packageId: string;
  dialogRef;
  tabs = [];
  isThemed = false;
  forwardEmails: Set<string> = new Set();

  @ViewChild('forwardDialog') private forwardDialog: TemplateRef<any>;
  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private toaster: ToastrService, 
    private dialogService: NbDialogService,
    private infoSlipsService: InfoSlipsService,
    private nbMenuService: NbMenuService,
    private themeService: ThemeService,
    private antiForgeryService: AntiForgeryService
  ) {}
  
  ngOnInit() {
    this.themeService.theme$.subscribe(res => {
      if(res?.ColorPalette){
        this.isThemed = true;
      }
    })
    
    this.infoslipDetails$.subscribe(data=> {
      this.packageId = data?.Id;
      this.iframeUrl = this.antiForgeryService.verificationTokenQueryInjector(data?.Tabs[0].StartPage);
      data?.Tabs.forEach(tab => {
        this.tabs.push({title:tab.TabName, tab: tab})  
      });
      
    });
    this.infoslipObject$ = this.infoslipDetails$.pipe(
      map((data: any) => {
        return data.currentInfoSlip;
      })
    );


    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'header-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => {

        this.tabs.find(tab => {
          if(tab.title === title){
            this.changeTab(tab.tab)
          }
        });
    });
  }

  changeTab(tab: Tab) {
    console.log("changeTab:", tab);
    this.iframeUrl = this.antiForgeryService.verificationTokenQueryInjector(tab.StartPage);
    this.tabChange.emit(tab);
  }

  forwardDocument(){
    const forwardAddresses = [...this.forwardEmails].join(';');
    
    const forwardAdhocData:ForwardRequestModel = {
      Subject: this.forwardForm.get('subject').value,
      ToAddress: !forwardAddresses ? this.emailInput.nativeElement.value : forwardAddresses,
      PackageId: this.packageId,
      ForwardTypes: [0],
      RunTemplateId:null
    }
    
    this.infoSlipsService.forwardAdhoc(forwardAdhocData).subscribe((result: any)=>{
      if(result.IsSuccess){
        this.toaster.success("Document has been sent!");
      }else{
        this.toaster.error("Failed to forward the document.");
      }
      this.dialogRef.close();
    });
    this.forwardModal = false;
  }
  
  cancelForward(){
    this.forwardModal = false;
    this.forwardForm.reset();
  }

  createEmail(email) {
    return this.fb.group(email, Validators.email);
  }

  open() {
    this.dialogRef = this.dialogService.open(this.forwardDialog);
  }

  close(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  closeInfoSlip(){
    this.infoSlipsService.closeInfoSlip();
  }

  onEmailRemove(tagToRemove: NbTagComponent): void {
    this.forwardEmails.delete(tagToRemove.text);
  }

  onEmailAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.forwardEmails.add(value);
    }
    input.nativeElement.value = '';
  }

}

