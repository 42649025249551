import { Component, Input, OnInit } from '@angular/core';
import { RunRecipientOutputChannel, StaticAttachmentModel } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { RecipientService } from '@InfoSlips/iiab-api';
@Component({
  selector: 'ifs-outputchannel-summary',
  templateUrl: './outputchannel-summary.component.html',
  styleUrls: ['./outputchannel-summary.component.scss']
})
export class OutputChannelSummaryComponent implements OnInit {

  @Input() RunRecipientOutputChannel: RunRecipientOutputChannel[];
  @Input() Channel: string;
  @Input() AllowForward = false;
  @Input() StaticRecipientAttachments: StaticAttachmentModel[];
  @Input() StaticRunAttachments: StaticAttachmentModel[];

  @Input() RunRecipientId: string;
  @Input() RunTemplateId: string;
  @Input() RunId: string;

  SelectedAction: string[] = [];
  ForwardPlaceHolder: string;
  forwardLocation: string[] = [];

  constructor(
    private toastrService: ToastrService,
    private recipientService: RecipientService,) { }

  get AllowResend(): boolean {
    const sendTo = this.RunRecipientOutputChannel[0].sendTo;
    if (this.Channel === "SMS" && sendTo?.length > 0) return true;
    else if (this.Channel === "Email" && sendTo?.length > 0 && sendTo != "#") return true;
    else return false;
  }

  ngOnInit(): void {
    this.setSelectedAction();
    this.ForwardPlaceHolder =  this.setForwardPlaceHolder();
  }

  getPages(pages?: number): string {
    return !pages || pages == 0 ?
      "" :
      `(Pages:${pages})`
  }

  sendAction(sequence:number){
    this.toastrService.success(`Going to perform: ${this.SelectedAction} for sequence:${sequence}`);
  }

  send(sequence: number) {
    if (this.SelectedAction[sequence] == "Resend") {    
      this.toastrService.info('Resending InfoSlip, please wait.')

      this.recipientService.resendRunRecipientInfoSlip(this.RunRecipientId).subscribe(res => {
        res ? this.toastrService.success("Resent Successful") : this.toastrService.error("Failed to resend");
      });
    }
    else if (this.SelectedAction[sequence] == "Forward") {     

      let forwardOptionsObject = {};
      forwardOptionsObject["RunRecipientId"] = this.RunRecipientId;
      if (this.Channel == "Email") {
        forwardOptionsObject["ToEmailAddress"] = this.forwardLocation[sequence];
      } else if (this.Channel == "SMS") {
        forwardOptionsObject["ToMobileNumber"] = this.forwardLocation[sequence];
      }

      if (sequence != 0) {
        forwardOptionsObject["Sequence"] = sequence;
      }
    
      this.toastrService.info('Forwarding InfoSlips, please wait.')

      this.recipientService.forwardInfoSlips(forwardOptionsObject).subscribe(res => {
        res.length > 0 && res[0]?.IsForward ? this.toastrService.success("Forward Successful") : this.toastrService.error("Failed to forward");
      });
    }
  }

  //#region Private
  private setSelectedAction() {
    if ((this.AllowResend && this.AllowForward) || (!this.AllowResend && !this.AllowForward))
      return
    
    if (this.AllowResend)
      this.SelectedAction[0] = "Resend";
    else (this.AllowForward)
      this.SelectedAction[0] = "Forward";
  }
  private setForwardPlaceHolder(){
    switch(this.Channel){
      case "Email":
        return "Enter email address";
      case "SMS":
        return "Enter mobile number";
    }
    return null;
  }
  //#endregion

}
