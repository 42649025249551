// eslint-disable-next-line max-len
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, HostListener, SimpleChanges, ComponentFactoryResolver, ViewChild, OnDestroy } from '@angular/core';
import { GenericComponentModel, MenuItem } from '../../services/menu-item';
import { MenuDirective } from '../../services/menu.directive';
import { MenuService } from '../../services/menu.service';
import { State, WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnChanges, OnDestroy {

@Input() context: string;
@ViewChild(MenuDirective, {static: true}) menuHost: MenuDirective;

@Output() closeWizard: EventEmitter<string> = new EventEmitter<string>();
@Output() status: EventEmitter<boolean> = new EventEmitter<boolean>();

step = 0;
contextMenu: MenuItem[] = [];
searchPhrase: string;

@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  this.close();
}

  constructor(
    private menuService: MenuService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private wizardService: WizardService
  ) { }


  ngOnInit(){
    this.setContext();
    this.wizardService.navigationState$.subscribe((state: State) => {
      this.step = state;
      if(state === 0){
        this.clearComponent();
      }
    })
  }

  ngOnDestroy(){
    this.clearComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('context' in changes) {
        this.step = 0;
     }

     if(this.context.length > 0){
      this.status.emit(true);
      this.setContext();
    }
}

  setContext(){
    switch(this.context){
      case 'Setup':{
        this.contextMenu = this.menuService.getSetupMenu();
        break;
      }
      case 'Customers and Users':{
        this.contextMenu = this.menuService.getCompaniesAndUsersMenu();
        break;
      }
      case 'Reports':{
        this.contextMenu = this.menuService.getReportsMenu();
        break;
      }
      case 'Profile':{
        this.contextMenu = this.menuService.getProfileMenu();
        break;
      }
      case 'Toolbox':{
        this.contextMenu = this.menuService.getToolboxMenu();
        break;
      }
      default: {
        return [];
      }

    }
  }

  loadComponent(index, component?: MenuItem, context?: string) {
    this.wizardService.setState(1);
    
    const menuItem = component ? component : this.contextMenu[index];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(menuItem.component);

    this.clearComponent();

    const componentRef = this.menuHost.viewContainerRef.createComponent<GenericComponentModel>(componentFactory);
    //@ts-ignore
    componentRef.instance.data = menuItem.data;
    componentRef.instance.context = context;
  }

  clearComponent(){
    this.menuHost.viewContainerRef.clear();
  }

  close(){
    this.closeWizard.emit('close');
    this.clearComponent()
  }

}
