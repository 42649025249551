import { Component, OnInit, Output, EventEmitter, OnChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@InfoSlips/iiab-auth';
import { NbThemeService } from '@nebular/theme';
import { WizardService } from 'libs/wizard/src/lib/services/wizard.service';
import { MonacoEditorConfig } from '../../monacoConfig';

@Component({
  selector: 'ifs-activity-bar-shared',
  templateUrl: './activity-bar.component.html',
  styleUrls: ['./activity-bar.component.scss']
})
export class ActivityBarSharedComponent {

  @Input() expand = false;
  @Input() context;
  @Output() status: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  
  profileOptions = [
    {title: 'Logout', icon: { icon: 'power', pack: 'oakmoon' }}
  ];

  menuConfig = {
    admin: {
      backButton: true,
      cmsThemeButton: false
    },
    cms: {
      backButton: true,
      cmsThemeButton: true
    },
    reports: {
      backButton: true,
      cmsThemeButton: false
    },
  }

  lightTheme = true;
  reports = null;
  selectedReport = null;
  currentReport = null;
  runId = null;
  reportType = null;

  constructor(
    private authService: AuthService,
    private themeService: NbThemeService,
    private monacoConfig: MonacoEditorConfig,
    private route: ActivatedRoute,
    private router: Router,
    public wizardSerivce: WizardService
  ) { }

  ngOnInit(): void {
    try {
      this.currentReport = this.router.url.split('report:')[1]?.split(')')[0];
      this.selectedReport = this.reports?.find(item => {
        return item.route === this.currentReport;
      }).name;   
    } catch (error) {
      console.log(error);
    }
    
    this.runId =  this.route.snapshot.paramMap.get('runId');   
    this.reportType = this.route.snapshot.paramMap.get('reportType');
    if(this.reportType != undefined){
      this.selectedReport = this.reports.find(item => {
        return item.route === this.reportType;
      }).name;  
    }
  }

  ngOnChanges(){
    if(!this.expand){
      this.closeWizard();
    }
  }

  backToAdmin(){
    if(this.context === 'cms'){
      this.router.url === '/cms' ? this.router.navigateByUrl('/') : this.router.navigateByUrl('/cms');
    } else {
      this.router.navigateByUrl(this.runId ?  `run/${this.runId}` : "/");
    }
  }

  launchWizard(context: string){
    this.wizardSerivce.toggleWizard(true);
    this.wizardSerivce.wizardContextSubject.next(context);
  }

  closeWizard(){
    this.status.emit(false);
    this.wizardSerivce.toggleWizard(false);
    this.wizardSerivce.wizardContextSubject.next("")
  }

  logout() {
    window.location.replace('/#/auth/logout');
  }

  changeTheme(){
    this.lightTheme ? this.lightTheme = !this.lightTheme : this.lightTheme = true;
    this.themeService.changeTheme(this.lightTheme ? 'light' : 'dark');
    this.monacoConfig.editorOptions.theme = this.lightTheme ? 'vs' : 'vs-dark'
  }

  launchCMS(){
    this.router.navigateByUrl('/cms');
  }

  routeHome() {     
    const originalReRouteStrat = this.router.routeReuseStrategy;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/']);
    this.router.onSameUrlNavigation = 'ignore';
    this.router.routeReuseStrategy = originalReRouteStrat;
  }

}