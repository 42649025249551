import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'timeago'})
export class TimeAgoPipe implements PipeTransform {
 getValue(input) {
    if (!input) return "never"
    var substitute = function (stringOrFunction, number, strings) {
      var string = stringOrFunction;
      var value = (strings.numbers && strings.numbers[number]) || number;
      return string.replace(/%d/i, value);
    };
  
    var nowTime = new Date().getTime();
    var date = new Date(input).getTime();
    var dateDifference = nowTime - date;
    // var allowFuture = allowFutureVal || false;
    
    var strings = {
      suffixAgo: "ago",
      suffixFromNow: "from now",
      seconds: "less than a minute",
      minute: "about a minute",
      minutes: "%d minutes",
      hour: "about an hour",
      hours: "about %d hours",
      day: "a day",
      days: "%d days",
      month: "about a month",
      months: "%d months",
      year: "about a year",
      years: "%d years"
    };
  
    var seconds = Math.abs(dateDifference) / 1000;
    var minutes = seconds / 60;
    var hours = minutes / 60;
    var days = hours / 24;
    var years = days / 365;
  
    // var strings = this.settings.strings;
  
    var suffix = strings.suffixAgo;
    
    var words = seconds < 45 && substitute(strings.seconds, Math.round(seconds), strings) ||
      seconds < 90 && substitute(strings.minute, 1, strings) ||
      minutes < 45 && substitute(strings.minutes, Math.round(minutes), strings) ||
      minutes < 90 && substitute(strings.hour, 1, strings) ||
      hours < 24 && substitute(strings.hours, Math.round(hours), strings) ||
      hours < 42 && substitute(strings.day, 1, strings) ||
      days < 30 && substitute(strings.days, Math.round(days), strings) ||
      days < 45 && substitute(strings.month, 1, strings) ||
      days < 365 && substitute(strings.months, Math.round(days / 30), strings) ||
      years < 1.5 && substitute(strings.year, 1, strings) ||
      substitute(strings.years, Math.round(years), strings);
    return (words + " " + suffix);
  
  };

  transform(date: Date): string {
    return this.getValue(date);
  }
}

