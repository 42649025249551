<div class="general-page-container">
    <div class="general-page-body">
        <div class="table-responsive">
            <div class="table-header">
                <div class="table-row">
                    <div class="">Created</div>
                    <div class="">Log Type</div>
                    <div class="">Processor Types</div>
                    <div class="">Run Recipient</div>
                    <div class="message">Message</div>
                    <div class="">Stacktrace</div>
                </div>
            </div>
            <div class="table-body"
                infiniteScroll 
                [infiniteScrollDistance]="1" 
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false" 
                (scrolled)="onScroll()">
                <div class="">
                    <p *ngIf="this.selectedRunService.processorAuditLog.isLoading$ | async" [nbSpinner]="true"nbSpinnerStatus="basic" nbSpinnerSize="large" nbSpinnerMessage="Loading ...">&nbsp;</p>
                    <div class="table-row"
                        *ngFor="let log of logs">
                        <div class="">{{log.created | date: 'dd/MM/yy HH:mm:ss'}}</div>
                        <div class="">{{log.logType}}</div>
                        <div class="">{{log.processorTypes}}</div>
                        <div class="">{{log.runRecipient?.name}}</div>
                        <div class="message">{{log.message}}</div>
                        <div class="">{{log.stacktrace}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>