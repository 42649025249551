import { Component, Input, OnInit } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import { RunRecipientSummary } from '@InfoSlips/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifs-recipient-not-to-send',
  templateUrl: './recipient-not-to-send.component.html',
  styleUrls: ['./recipient-not-to-send.component.scss']
})
export class RecipientNotToSendComponent implements OnInit {

  @Input() selectedRunService: SelectedRunService;

  constructor(  ) { }

  runRecipients$: Observable<RunRecipientSummary[]>;

  ngOnInit() {
    this.selectedRunService.loadIgnored(null);
    this.runRecipients$ = this.selectedRunService.ignoredRunRecipients$;
  }

}
