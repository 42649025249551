import { Injectable } from '@angular/core';
import { StateMenuContext } from '@InfoSlips/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { RunStateService } from './run-state.service';

interface MenuItems {
  Primary: 'Run' | 'Runs',
  Secondary: ''
}

 interface MenuContextItems {
   name: string,
   context: StateMenuContext,
   style: {
    icon: string,
    pack: string
   }
 }

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {

  private _menuContext: string;
  public menuItems: MenuContextItems[] = [
    {
      name: 'Menu',
      context: StateMenuContext.Menu,
      style: {
        icon: 'menu-outline',
        pack: 'eva'
      }
    },
    {
      name: 'CMS',
      context: StateMenuContext.CMS,
      style: {
        icon: 'cms',
        pack: 'oakmoon-v2'
      }
    }
  ];

  private contextMenuSubject  = new BehaviorSubject<any[]>([]);
  public contextMenu$: Observable<any[]> = this.contextMenuSubject.asObservable();


  constructor(
    private runState: RunStateService
  ){

  }

  get MenuContext() {
    return this._menuContext
  }

  set MenuContext(context: string){
    this._menuContext = context;
  }




  debug(e: any){
    try {
      this.MenuContext = e.data._value.context;
    } catch (error) {
      console.log(error);
    }
    

    switch (this.MenuContext) {
      case 'Run':
        this.menuItems = this.runState.getRunMenu();
        this.runState.runMenu$.subscribe(res => {
          this.contextMenuSubject.next(res);  
        })

        break;
    
      default:
        this.setDefaultMenu();
        break;
    }
  }

  setDefaultMenu(){
    this.menuItems = [
      {
        name: 'Menu',
        context: StateMenuContext.Menu,
        style: {
          icon: 'menu-outline',
          pack: 'eva'
        }
      },
      {
        name: 'CMS',
        context: StateMenuContext.CMS,
        style: {
          icon: 'cms',
          pack: 'oakmoon-v2'
        }
      }
    ]
  }

}
