<div class="blacklist-container">
  <nb-card class="block" *ngIf="!manageBlacklist">
    <nb-card-header> 
      <div class="blacklist-header">
        <div class="header">
          <h4>Recipient Blacklist</h4>

          <button nbButton size="small" status="primary" (click)="toggleManageBlacklist()">Manage Blacklist</button>
        </div>

        <div class="filter">
          <input nbInput autocomplete="off" fullWidth type="text" name="searchRecipientBlacklist" #blacklistFilter placeholder="search by recipient" (input)="onChange()" [(ngModel)]="searchPhrase" class="search" />
        </div>
      </div>

      <div class="grid header-fields">
        <div class="flex item-recipient">
          <p class="item-text">
            Recipient
          </p>
        </div>

        <div class="flex item-info">
          <p class="item-text">
            Channel
          </p>
        </div>
    
        <div class="item-info">
          <p class="item-text">
            Reason
          </p>
        </div>

        <div class="item-info">
          <p class="item-text">
            Created Date
          </p>
        </div>

        <div class="text-right item-actions">
          <p class="item-text">
            Actions
          </p>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body infiniteScroll 
      [infiniteScrollDistance]="1" 
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false">

      <ng-container *ngFor="let recipient of blacklist$ | async">
        <div class="grid grid-body">
          <div class="flex item-recipient">
            <div class="item-text">
              <p>{{ recipient.recipient }}</p>
            </div>
          </div>

          <div class="flex item-info">
            <div class="">
              <p>{{ recipient.channel }}</p>
            </div>
          </div>

          <div class="flex item-info">
            <div class="">
              <p>{{ recipient.reason }}</p>
            </div>
          </div>

          <div class="flex item-info">
            <div class="">
              <p>{{ recipient.created }}</p>
            </div>
          </div>

          <div class="text-right item-actions">
            <button nbButton (click)="delete(recipient?.id, false)">
              <nb-icon 
                [icon]="deleteConfirm ? 'delete' : 'bin'" 
                pack="oakmoon" 
                status="danger"></nb-icon>
            </button>
            <button nbButton *ngIf="deleteConfirm" (click)="delete(recipient?.id, true)" status="danger">
              <nb-icon 
                class="danger"
                icon="check-circle" 
                pack="oakmoon" 
                status="secondary"></nb-icon>
            </button>
            <button nbButton *ngIf="!deleteConfirm" status="basic">
              <nb-icon 
                icon="info-circle" 
                pack="oakmoon" 
                status="info"
                [nbPopover]="entityInfo"
                nbPopoverTrigger="click"></nb-icon>
            </button>
          </div>
        </div>

        <ng-template #entityInfo>
            <div>Id: {{recipient.id}}</div>
            <div>Created: {{recipient.created}}</div>
            <div>Created By: {{recipient.createdBy}}</div>
            <div>Last Updated: {{recipient.lastUpdated}}</div>
            <div>Last Updated By: {{recipient.lastUpdatedBy}}</div>
        </ng-template>
      </ng-container>

    </nb-card-body>
  </nb-card>

  <nb-card class="block manager" *ngIf="manageBlacklist">
    <nb-tabset class="white-tabs" (changeTab)="closeManager($event)">
      <nb-tab tabTitle="Individual Manager" class="tab">
        
        <div class="header">
          <h4>Add Blacklisted Recipient</h4>
        </div>
        
        <div class="input-box">
          <label>
            <p>Channel</p>
          </label>
          <nb-select fullWidth [selected]="newBlacklistedRecipient.Channel" [(ngModel)]="newBlacklistedRecipient.Channel">
            <nb-option *ngFor="let type of channelTypes$ | async" [value]="type.id">{{ type.value }}</nb-option>
          </nb-select>
          <label>
            <p>Recipient</p>
          </label>
          <input type="text" nbInput fullWidth [(ngModel)]="newBlacklistedRecipient.Recipient">
          <label>
            <p>Reason</p>
          </label>
          <input type="text" nbInput fullWidth [(ngModel)]="newBlacklistedRecipient.Reason">
        </div>

        <button type="button" nbButton status="primary" (click)="addBlacklistedRecipient()"><nb-icon icon="plus-outline" pack="eva"></nb-icon>Add</button>
        <button type="button" nbButton status="basic" (click)="toggleManageBlacklist(true)">Cancel</button>
      </nb-tab>
      <nb-tab tabTitle="Batch Manager" class="tab">
        
        <div class="header">
          <h4>Batch Manage Recipient Blacklist</h4>
        </div>

        <div class="batch-removal">

          <p class="bold-font">Batch Removal</p>

          <p class="description">
            Exclude file for blacklist should NOT contain a header line, and have entries containing recipient.<br />
            Example: "recipient@email.com"
          </p>

          <div class="upload-box row" (drop)="fileDropBatchRemove($event)" (dragover)="onDragOverRemoval($event)">
            <div class="col-md-8">
              <p>
                <nb-icon [icon]="batchRemovalFilesAdded === 1 ? 'checkmark-circle-outline' : batchRemovalFilesAdded === 2 ? 'clock-outline' : ''"></nb-icon>
                Drag Batch Removal file here !
              </p>
            </div>
            <div class="col-md-4">
              <div class="file-actions flex-right">
                <span>
                  <input id="file-upload-removal" type="file" (change)="fileDropBatchRemove($event)" />
                  <label class="choose-file" nbButton outline for="file-upload-removal">
                    Choose File
                  </label>
                  <label class="file-name">{{ batchRemovalFileName }}</label>
                </span>
              </div>
            </div>
          </div>

          <button nbButton status="primary" (click)="uploadBatchRemoval()">Upload</button>
        </div>

        <div class="batch-include">

          <p class="bold-font">Batch Inclusion</p>

          <p class="description">
            Include file for blacklist should NOT contain a header line, and have comma-seperated values containing recipient, channel and reason.<br />
            Example: "recipient@email.com","SMS" ,"Reason"
          </p>

          <div class="upload-box row" (drop)="fileDropBatchInclude($event)" (dragover)="onDragOverInclude($event)">
            <div class="col-md-8">
              <p>
                <nb-icon [icon]="batchIncludeFilesAdded === 1 ? 'checkmark-circle-outline' : batchIncludeFilesAdded === 2 ? 'clock-outline' : ''"></nb-icon>
                Drag Batch Include file here !
              </p>
            </div>
            <div class="col-md-4">
              <div class="file-actions flex-right">
                <span>
                  <input id="file-upload-include" type="file" (change)="fileDropBatchInclude($event)" />
                  <label class="choose-file" nbButton outline for="file-upload-include">
                    Choose File
                  </label>
                  <label class="file-name">{{ batchIncludeFileName }}</label>
                </span>
              </div>
            </div>
          </div>

          <button nbButton status="primary" (click)="uploadBatchInclude()">Upload</button>
        </div>
      </nb-tab>
      <nb-tab tabIcon="close-outline" class="manager-close"></nb-tab>
    </nb-tabset>
  </nb-card>
</div>
