<div class="expand" [class.active]="wizardSerivce.wizardStatus$ | async">
  <ifs-wizard (closeWizard)="closeWizard()" [context]="wizardSerivce.wizardContext$ | async"></ifs-wizard>
</div>
<div class="activity-bar desktop-nav">
    <nb-list>
      <nb-list-item>
        <nb-icon routerLink="" class="logo" pack="oakmoon" icon="ifs-icon-white" (click)="routeHome()"></nb-icon>
      </nb-list-item>
      <nb-list-item *ngIf="menuService?.allowedMainMenu?.Setup == true"
        [class.active]="(wizardSerivce.wizardContext$ | async) === 'Setup'" 
        (click)="launchWizard('Setup')" nbTooltip="Setup Runs, Templates and Processors." 
        nbTooltipStatus="primary" nbTooltipPlacement="right">
        <nb-icon pack="oakmoon-v2" icon="setup"></nb-icon>
      </nb-list-item>
      <nb-list-item *ngIf="menuService?.allowedMainMenu?.CustomersAndUsers == true" class="has-badge" 
      [class.active]="(wizardSerivce.wizardContext$ | async) === 'Customers and Users'" 
        (click)="launchWizard('Customers and Users')" 
        nbTooltip="Manage and Create customers, users and recipients." nbTooltipStatus="primary" nbTooltipPlacement="right">
        <nb-icon pack="oakmoon-v2" icon="users"></nb-icon>
      </nb-list-item>
      <nb-list-item *ngIf="menuService?.allowedMainMenu?.Reports == true" class="has-badge" 
        [class.active]="(wizardSerivce.wizardContext$ | async) === 'Reports'" 
        (click)="launchWizard('Reports')" nbTooltip="Process new reports." 
        nbTooltipStatus="primary" nbTooltipPlacement="right">
        <nb-icon pack="oakmoon-v2" icon="reports"></nb-icon>
      </nb-list-item>
      <nb-list-item *ngIf="menuService?.allowedMainMenu?.Toolbox == true" 
        [class.active]="(wizardSerivce.wizardContext$ | async) === 'Toolbox'" 
        (click)="launchWizard('Toolbox')" nbTooltip="View Toolbox items." 
        nbTooltipStatus="primary" nbTooltipPlacement="right">
        <nb-icon pack="oakmoon-v2" icon="toolbox"></nb-icon>
      </nb-list-item>
      <nb-list-item *ngIf="menuService?.allowedMainMenu?.Cms == true" (click)="launchCMS()" class="has-badge" nbTooltip="Launch the Content Management System to manage your assets." nbTooltipStatus="primary" nbTooltipPlacement="right">
        <nb-icon nbTooltip="CMS" nbTooltipStatus="info" icon="cms" pack="oakmoon-v2"></nb-icon>
      </nb-list-item>
    </nb-list>

      <nb-list class="notifications">
        <nb-list-item [class.active]="(wizardSerivce.wizardContext$ | async) === 'Profile'" (click)="launchWizard('Profile')"><nb-icon nbTooltip="Profile" nbTooltipPlacement="right" nbTooltipStatus="success" icon="profile" pack="oakmoon-v2"></nb-icon></nb-list-item>
        <nb-list-item class="has-badge" (click)="changeTheme()">
          <nb-icon pack="oakmoon-v2" icon="theme"></nb-icon>
          <nb-badge nbTooltip="Experimental theme switcher! May contain visual bugs." nbTooltipStatus="success" nbTooltipPlacement="right" class="badge" text="New" status="success"></nb-badge>
        </nb-list-item>
        <nb-list-item (click)="logout()"><nb-icon nbTooltip="Sign Out" nbTooltipStatus="success" nbTooltipPlacement="right" icon="logout" pack="oakmoon-v2"></nb-icon></nb-list-item>
      </nb-list>
</div>



<ng-container [ngSwitch]="menuContext">
    <ng-template [ngSwitchCase]="0">
      <div [class.open]="isMenuOpen" class="mobile-menu-slideout overlay" (click)="isMenuOpen = !isMenuOpen">
       
        <div class="wizard">
          <div class="wizard-buttons">
            <!-- <ng-container *ngFor="let item of ">
              <button nbButton status="primary" [class.active]="(wizardSerivce.wizardContext$ | async) === 'Setup'" (click)="launchWizard('Setup')">
                <nb-icon [pack]="item.style.pack" [icon]="item.style.icon"></nb-icon> Setup
              </button>
            </ng-container> -->

            <button nbButton status="primary" [class.active]="(wizardSerivce.wizardContext$ | async) === 'Setup'" (click)="launchWizard('Setup')">
              <nb-icon pack="oakmoon-v2" icon="setup"></nb-icon> Setup
            </button>
    
            <button nbButton status="primary" [class.active]="(wizardSerivce.wizardContext$ | async) === 'Customers and Users'" (click)="launchWizard('Customers and Users')">
              <nb-icon pack="oakmoon-v2" icon="users"></nb-icon> Customers and Users
            </button>
    
            <button nbButton status="primary" [class.active]="(wizardSerivce.wizardContext$ | async) === 'Reports'" (click)="launchWizard('Reports')">
              <nb-icon pack="oakmoon-v2" icon="reports"></nb-icon> Reports
            </button>
    
            <button nbButton status="primary" [class.active]="(wizardSerivce.wizardContext$ | async) === 'Toolbox'" (click)="launchWizard('Toolbox')">
              <nb-icon pack="oakmoon-v2" icon="toolbox"></nb-icon> Toolbox
            </button>
          </div>
        </div>

      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="1">
      <div [class.open]="isMenuOpen" class="mobile-menu-slideout">
        <ifs-runs></ifs-runs>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="2">
      <div [class.open]="isMenuOpen" class="mobile-menu-slideout overlay">
        <div class="wizard">
          <div class="wizard-buttons">
            <ng-container *ngFor="let item of state.contextMenu$ | async; index as i">
              <button *ngIf="item.visible" nbButton status="primary" (click)="setRunTab(i)">
                {{item.name}}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
</ng-container>

<div class="mobile-nav">
    <div class="mobile-menu">

      <ng-container *ngFor="let action of state.menuItems">
        <div class="menu-item" (click)="action.context === 3 ? launchCMS() : openMobileMenu(action.context)">
          <nb-icon [pack]="action.style.pack" [icon]="action.style.icon"></nb-icon>
        </div>
      </ng-container>

      <!-- <div class="menu-item" (click)="launchCMS()">
        <nb-icon pack="oakmoon-v2" icon="cms" ></nb-icon>
      </div> -->

      <div class="menu-item" (click)="logout()">
        <nb-icon pack="oakmoon" icon="power"></nb-icon>
      </div>
    </div>

  <!-- <nb-actions size="medium" fullWidth>
    <nb-action (click)="launchWizard('Toolbox')" nbTooltip="View Toolbox items." nbTooltipStatus="primary" nbTooltipPlacement="right">
      <nb-icon pack="eva" icon="menu-outline"></nb-icon>
    </nb-action>

    <nb-action [class.active]="(wizardSerivce.wizardContext$ | async) === 'Setup'" (click)="launchWizard('Setup')">
      <nb-icon pack="oakmoon" icon="task-list-add" class="setup"></nb-icon>
    </nb-action>

    <nb-action >
      <nb-icon pack="oakmoon" icon="cursor-select" ></nb-icon>
    </nb-action>

    <nb-action (click)="logout()">
      <nb-icon pack="oakmoon" icon="power"></nb-icon>
    </nb-action>
  </nb-actions> -->
</div>