import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbTagComponent, NbTagInputAddEvent } from '@nebular/theme';
import { FormArray, FormBuilder } from '@angular/forms';
import { CmsService } from 'libs/cms/src/lib/services/cms.service';
import { CmsEntityReference } from '@InfoSlips/models';

@Component({
  selector: 'ifs-admin-run-search-data-crud',
  templateUrl: './run-search-data-crud.component.html',
  styleUrls: ['./run-search-data-crud.component.scss']
})
export class RunSearchDataCrudComponent implements OnInit {
  @Input() SearchFields;
  @Input() searchDataEntity;
  @Output() updateSearchFields = new EventEmitter();

  @Output() addRunSearchEntity = new EventEmitter();
  @Output() removeRunSearchEntity = new EventEmitter();

  searchFieldsForm = this.fb.group({
    fields: this.fb.array([])
  });

  baseFilter = {
    "cmsContentType": {
      "eq": "DATA_MODEL"
    }
  }

  cmsEntitiesCount$: any =  this.cmsService.cmsGlobalEntities.totalCount$;
  cmsEntities$: any =  this.cmsService.cmsGlobalEntities.Items$(this.baseFilter);
  searchValue;

  constructor(
    private fb: FormBuilder,
    private cmsService: CmsService
    ){}

  ngOnInit(): void {
      this.SearchFields.forEach(field => {
        this.fields.push(this.fb.control(field));
      });
  }

  get fields() {
    return this.searchFieldsForm.get('fields') as FormArray;
  }

  add(){
    this.fields.push(this.fb.control(''));
  }

  remove(index: number){
    this.fields.removeAt(index);
    this.updateSearchFields.emit(this.fields.value)
  }

  clearSearch(){
    this.cmsService.cmsGlobalEntities.loadPagedItems(this.baseFilter);
    this.searchValue = '';
  }

  filterEntities(e: string) {
    this.cmsService.cmsGlobalEntities.loadPagedItems({
      ...this.baseFilter, ...{
        or: [
          {
            name: {
              like: e
            }
          },
          {
            display: {
              like: e
            }
          }
        ]
      }
    })
  }

  isRestModel(searchDataEntity){
    return 'Type' in searchDataEntity;
  }
}