import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { CustomerModel, ExportTemplateModel, MiscellaneousReference, RunTemplateModel, UpdatedKeyModel, UpdateKeyTypes } from '@InfoSlips/models';
import { NbDialogService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'ifs-secure-keys',
  templateUrl: './secure-keys.component.html',
  styleUrls: ['./secure-keys.component.scss']
})
export class SecureKeysComponent {

  @Input() secureKey: string;
  @Input() type: UpdateKeyTypes;
  @Input() id: string;
  @Input() model: string;
  @Input() isNew = false;
  @Output() updatedKey: EventEmitter<UpdatedKeyModel> = new EventEmitter();
  
  securityForm: NgForm;
  dialogRef;

  constructor(
    private toastr: ToastrService,
    private dialogService: NbDialogService,
    private apiBaseService: ApiBaseService
  ) {}

  openUpdateDialog(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  savePassword(password: string){
    let body: any = {};
    body[this.model] = password;

    this.apiBaseService.executePost(`${this.type}/${this.id}/securekeys`, body, `Update ${this.type} secure key.`).subscribe((result: any) => {
      !result ? this.toastr.error(result) : undefined;

      if(this.type === 'runTemplate'){
        let response: RunTemplateModel = result;
        this.updatedKey.emit({
          value: response.ExportTemplate.Password
        });

      } else {
        let response: CustomerModel = result;
        this.updatedKey.emit({
          value: response[this.model],
          model: this.model
        });
      }

      this.toastr.success('Has been updated sucessfully.', `Secure key`);
      this.dialogRef.close()
    })
  }
}
