<nb-reveal-card [showToggleButton]="false" [revealed]="revealed" class="card mb-10 shadow">
    <nb-card-front>
      <nb-card>
        <nb-card-header class="card-header p-10 reveal-card" [routerLink]="determineRoute(isRunTemplate, runTemplate)">
          <!-- TODO: Perhaps make the text scrolling when to long instead of scrollable? -->
            <p class="card-title">
              {{runTemplate?.name}} <br/>
              <small>{{ isRunTemplate? runTemplate?.template?.name : runTemplate?.customer?.name}}</small>
            </p>
      
      </nb-card-header>
      <nb-card-body>
        <ng-container *ngTemplateOutlet="templateId"></ng-container>
        <div class="actions">
            <button nbButton (click)="delete(runTemplate?.id, false)">
              <nb-icon [icon]="deleteConfirm ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
            </button>
            <button nbButton (click)="deleteConfirm ? delete(runTemplate?.id, true) : toggleView()" [status]="deleteConfirm ? 'danger' : 'basic'">
              <nb-icon [icon]="deleteConfirm? 'check-circle' : 'info-circle'" pack="oakmoon" status="info"></nb-icon>
            </button>
            <ng-container *ngTemplateOutlet="favorite"></ng-container>
        </div>
    </nb-card-body>
      </nb-card>
    </nb-card-front>
    <nb-card-back>
      <nb-card>
        <nb-card-header class="card-header p-10 reveal-card" [routerLink]="determineRoute(isRunTemplate, runTemplate)">
          <p class="card-title small">
            <b>Created: </b><small>({{runTemplate?.created?.split('T')[0]}})</small><br/>
            <small>{{runTemplate?.createdBy}}</small>
          </p>

          <p class="card-title small" *ngIf="runTemplate?.lastUpdatedBy">
            <b>Modified: </b><small>({{runTemplate?.lastUpdated?.split('T')[0]}})</small><br/>
            <small>{{runTemplate?.lastUpdatedBy}}</small>
          </p>
      </nb-card-header>
        <nb-card-body>
            <ng-container *ngTemplateOutlet="templateId"></ng-container>
          <div class="actions">
              <button nbButton (click)="delete(runTemplate?.id, false)">
                <nb-icon [icon]="deleteConfirm ? 'delete' : 'bin'" pack="oakmoon" status="danger"></nb-icon>
              </button>
              <button nbButton (click)="deleteConfirm ? delete(runTemplate?.id, true) : toggleView()" [status]="deleteConfirm ? 'danger' : 'basic'">
                <nb-icon [icon]="deleteConfirm? 'check-circle' : 'navigate-left'" pack="oakmoon" status="info"></nb-icon>
              </button>
              <ng-container *ngTemplateOutlet="favorite"></ng-container>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-reveal-card>

  <ng-template #favorite>
    <button nbButton (click)="favoriteItem(runTemplate?.name, isRunTemplate ? 'admin/runtemplates/' + runTemplate?.id : 'admin/templates/' + runTemplate?.id)">
      <nb-icon class="favorite" [icon]="stars.isStar ? 'star' : 'star-outline'"  pack="eva"></nb-icon>
    </button>
  </ng-template>

  <ng-template #templateId>
    <p class="template-id">
      <small>{{ runTemplate?.id }}</small>
      <ifs-copy [value]="runTemplate?.id"></ifs-copy>
    </p>
  </ng-template>