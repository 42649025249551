import { AuthService } from '@InfoSlips/iiab-auth';
import { RouterOutlet, Router, NavigationEnd, ActivatedRoute  } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';

import {
  routeAnimation,
  headerAnimation,
} from '@InfoSlips/shared';
import { NbSidebarService, NbDialogService } from '@nebular/theme';
import { BookmarkService, RunsService, TemplateService, RunTemplateService } from '@InfoSlips/iiab-api';
import { filter, map } from 'rxjs/operators';
import { BookmarkContext } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';
import { IIABCacheKey } from '@InfoSlips/models';
import { StateManagerService, IiabLocalStorageService } from '@InfoSlips/iiab-state';

@Component({
  selector: 'ifs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimation, headerAnimation]
})
export class AppComponent implements OnInit {

  public innerWidth: number;
  
  title = 'admin';
  loggedIn$;
  user$;
  expiring$;
  expiresIn$;
  minutesLeft$ = new BehaviorSubject<number>(30);// Default to new session time  
  secondsLeft$ = new BehaviorSubject<number>(0);// Default to new session time  
  headerClass$ = new BehaviorSubject<string>('');
  showRefreshButton$ = new BehaviorSubject<boolean>(false);

  version = environment.version;

  feedbackDialog;
  feedbackSubject;
  feedbackText;
  feedbackMailto;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sidebarService: NbSidebarService,
    private dialogService: NbDialogService,
    private bookmarkService: BookmarkService,
    private route: ActivatedRoute,
    private runsService: RunsService,
    private templateService: TemplateService,
    private state: StateManagerService,
    private runTemplateService: RunTemplateService,
    private localStorageService: IiabLocalStorageService
  ) {
    this.loggedIn$ = this.authService.isAuthenticated$;
    this.user$ = this.authService.loggedInUser$;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), // Only get the event of NavigationEnd
      map(() => route), // Listen to activateRoute
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
    ).subscribe((e: any) => {

      this.state.debug(e);
      
      const routeDetails = {
        path: e.url?.value[0]?.path,
        value: e.url?.value[1]?.path
      }

      setTimeout(() => {
        if(routeDetails.path === "run"){
          this.runsService.selectedRun$.subscribe(res => {
            if(res){
              this.bookmarkService.createBookmark(BookmarkContext.recent, res.Name, { snapshot: { data: { 
                //@ts-ignore
                icon: e.data.value.icon,
                //@ts-ignore
                context: e.data.value.context,
                // route: 
              }}})
            }
          })
        } else if (routeDetails.path === 'runtemplates'){
          this.runTemplateService.selectedRunTemplate$.subscribe(res => {
            if(res && routeDetails.value){
              this.bookmarkService.createBookmark(BookmarkContext.recent, res.Name, { snapshot: { data: { 
                icon: routeDetails.path = 'run-template',
                context: routeDetails.path = 'Run Template',
                route: routeDetails.value
              }}})
            }
          })
        } else if (routeDetails.path === 'templates'){
          this.templateService.selectedTemplate$.subscribe(res => {
            if(res){
              this.bookmarkService.createBookmark(BookmarkContext.recent, res.Name, { snapshot: { data: { 
                icon: routeDetails.path = 'template',
                context: routeDetails.path = 'Template',
                route: routeDetails.value
              }}})
            }
          })
        }
      }, 2000);

      this.bookmarkService.isStar(this.router.url);
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.expiring$ = this.authService.expiring$;
    this.expiresIn$ = this.authService.expiresIn$;
    this.authService.sessionSecondsRemaining$.subscribe((secondsLeft:number)=>{
      this.processSecondsLeft(secondsLeft);
    });

    this.loggedIn$.subscribe((result: boolean) => {
      window.addEventListener('focus', (event)=>{
        if(result){
          this.authService.updateSecondsLeft();
          console.log('Received Window Focus', event);      
        }
      });

      //set return url when no user supplied in order to navigate to requested url after auto redirect when not logged in
      if (!result && !window.location.href.includes("auth")) {
        this.localStorageService.set<string>(IIABCacheKey.ReturnUrl, window.location.href.split("#")[1]);
      }

      if(window.location.href.includes("resetPassword")){
        const token = window.location.href.split("resetPassword/")[1];
        this.router.navigateByUrl(`auth/reset-password/${token}`);
        return result;
      }

      if(!result && window.location.href.includes("asyncreport")){
        this.router.navigateByUrl('auth/login');
        return result;
      }

      if(!result && window.location.href.includes("admin")){
        this.router.navigateByUrl('auth/login');
        return result;
      }

      if(!result && window.location.href.includes("cms")){
        this.router.navigateByUrl('auth/login');
        return result;
      }

      if(!result){
        this.router.navigateByUrl('auth/login');
        return result;
      }
      
      if(window.location.href.includes("t/")){
        return result;
      }

      if(window.location.href.includes("onetime")){
        const token = window.location.href.split("onetime/")[1];
        this.router.navigateByUrl(`onetime/${token}`);
        return result;
      }

      if(!result && window.location.href.includes("completeregistration")){
        const token = window.location.href.split("completeregistration/")[1];
        this.router.navigateByUrl(`auth/register/confirm/${token}`);
        return result;
      }

      if (result && this.authService.loggedInUser && this.authService.loggedInUser.resetPassword.toString().toLowerCase() === "true") {
        setTimeout(() => {
          this.router.navigateByUrl('changePassword');
        },500)
        return result;
      } 
      
      if(!result && !this.router.url.includes("auth/login")){
        if(window.location.href.includes("external-sso")){
          const authorizationUrl = new URL(window.location.href);
          this.router.navigate([`auth/sso`], {
            queryParams: {
              client_id: authorizationUrl.searchParams.get('client_id'),
              code: authorizationUrl.searchParams.get('code'),
            },
            queryParamsHandling: 'merge'
          });
        }
        else{  
          this.router.navigateByUrl('auth/login');
        }
      }
      else if(result && this.router.url.includes("auth/login")){
        this.router.navigateByUrl('/');
      }

      return result
    });

    
  }

  refresh(){
    this.authService.refreshToken();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.activatedRouteData['animation'] || 'overview';
  }

  toggle() {
    this.sidebarService.toggle(true, 'left');
  }

  logout() {
    this.authService.logout();
  }

  login() {
    location.replace(environment.oldAdminUrl);
  }

  sendFeedback(ref){
    this.feedbackDialog = this.dialogService.open(ref, {});
  }

  buildMailto($event, context){
    context === "subject" ? this.feedbackSubject = $event : this.feedbackText = $event;
    this.feedbackMailto= `mailto:oakmoonfeedback@infoslips.com?subject=${encodeURI(this.feedbackSubject)}&body=${encodeURI(this.feedbackText)}`
  }

  submitFeedback(){
    const mailTo = document.createElement("a");
    mailTo.href = this.feedbackMailto;
    mailTo.click();

    this.feedbackText = ""; 
    this.feedbackSubject = "";
    this.feedbackMailto = ""; 
    this.feedbackDialog.close();
  }

  private processSecondsLeft(sessionSecondsLeft:number){
    const minutesLeft = Math.floor(sessionSecondsLeft/60);
    const secondsLeft = Math.floor(sessionSecondsLeft - (minutesLeft*60));

    this.minutesLeft$.next(minutesLeft);
    this.secondsLeft$.next(secondsLeft);

    if(minutesLeft>3){
      this.headerClass$.next('');
      this.showRefreshButton$.next(false);
      return;
    }

    this.showRefreshButton$.next(true);

    if(minutesLeft>0){
      this.headerClass$.next('header-orange');
      return;
    }

    this.headerClass$.next('header-red');
  }
}
