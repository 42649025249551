import { IiabIdName } from "./iiab-reference";

export interface CustomerReference extends IiabIdName{
    billingGroup:string
}







