import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'ifs',
  templateUrl: './mail-response-log.component.html',
  styleUrls: ['./mail-response-log.component.scss']
})
export class MailResponseLogComponent {
  constructor(
    private reportsService: ReportsService
  ) { }

  reportDetails$ = this.reportsService.reportDetails$;
  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;

  pageReport(context: string){
    this.reportsService.pageReport(context);
  }
}
