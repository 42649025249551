import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ifs-run',
  template: `
      <ifs-logged-out-view>
      <ifs-password-confirm-form
      ></ifs-password-confirm-form>
    </ifs-logged-out-view>
  `,
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
