<nb-list>
  <nb-list-item>
    <a routerLinkActive="active" class="text-basic" routerLink="/profile">
      Profile
    </a>
  </nb-list-item>
  <nb-list-item>
    <a routerLinkActive="active" class="text-basic" routerLink="/security">
      Security
    </a>
  </nb-list-item>
  <nb-list-item>
    <a routerLinkActive="active" class="text-basic" routerLink="/changePassword">
      Change Password
    </a>
  </nb-list-item>
</nb-list>
