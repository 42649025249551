<ifs-loading-screen *ngIf="!userDataForm.value.Id && !isNew"></ifs-loading-screen>
<div class="general-page-container">
  <div class="general-page-header">
    <div class="d-flex">
        <div class="flex-1">
          <h4 class="heading">{{isNew ? 'Create' : 'Update'}} User: <b>{{userDataForm.value.UserName}}</b></h4>
        </div>
        <div class="text-right">
          <button size="medium" outline nbButton routerLink="/">
            Cancel
          </button>
          <button class="save" *ngIf="!isNew" size="medium" nbButton status="primary" (click)="updateUser()">
            Save
          </button>
          <button class="save" *ngIf="isNew" size="medium" nbButton status="primary" (click)="createUser()">
            Create
          </button>
        </div>
    </div>
  </div>
</div>

<!-- <div class="general-page-container">
  <div class="general-page-header">
    <div class="title-bar row">
      <div class="col-md-8">
        <h4>
          <nb-icon pack="oakmoon" icon="user"></nb-icon>
          <span>{{isNew ? 'Create' : 'Edit'}} User</span>
        </h4>
      </div>
      <div class="col-md-4 text-right">
        <nb-icon pack="oakmoon" icon="remove" routerLink=""></nb-icon>
      </div>
    </div>
  </div>
</div> -->

<nb-card>
  <nb-card-body>
    <div class="flex">
      <div class="general-side-nav">
        <div class="context-menu" *ngFor="let option of menuItems" (click)="setMenuItem(option.title)" [class.active]="option.title === menuItem">
          <ng-container *ngIf="option.display">
            <div class="body">
              <h4 class="title">{{ option.title }}</h4>
            </div>
            <div class="action text-center">
              <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
            </div>
          </ng-container>
        </div>
      </div>

      <div [ngSwitch]="menuItem" class="content">
        <ng-template [ngSwitchCase]="'User Data'">
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <form [formGroup]="userDataForm" autocomplete="off">
                  <label>
                    <p>User Name</p>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="UserName" *ngIf="isNew">
                  <span *ngIf="!isNew">{{userDataForm.UserName}}</span>
                  <label>
                      <p>Display name</p>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="DisplayName">
                  <label>
                      <p>Email address</p>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="Email">
                  <label>
                      <p>Date of Birth</p>
                  </label>
                  <input nbInput fullWidth placeholder="Pick Date" formControlName="DateOfBirth" [nbDatepicker]="dateTimePicker">
                  <nb-datepicker #dateTimePicker format="dd MMM yyyy"></nb-datepicker>

                  <div formGroupName="Country">
                    <nb-form-field>
                      <label>
                        <p>Country</p>
                      </label>
                      <input formControlName="name" nbInput fullWidth type="text" placeholder="Enter Country Name" [nbAutocomplete]="autoControl" (ngModelChange)="getCountry()" />
                      <button class="clear" nbSuffix nbButton ghost (click)="clearCountry()">
                        <nb-icon icon="close" pack="eva">
                        </nb-icon>
                      </button>

                      <nb-autocomplete #autoControl (selectedChange)="selectCountry($event)">
                        <nb-option *ngFor="let option of countryOptions$ | async" [value]="option">
                          {{ option.Name }}
                        </nb-option>
                      </nb-autocomplete>
                    </nb-form-field>
                  </div>

                  <div formGroupName="Mobile">
                    <label>
                      <p>Mobile number</p>
                    </label>
                    <nb-form-field>
                      <div *ngIf="userDataForm.value.Country?.id" status="primary" class="callingCode" nbPrefix nbButton ghost >
                        {{ userDataForm.value.Country.callingCode }}
                      </div>
                      <input class="mobileInput" type="number" nbInput fullWidth formControlName="Number">
                      <p class="warning" *ngIf="!userDataForm.value.Country?.id">Please select a country first.</p>
                    </nb-form-field>
                  </div>

                  <label>
                      <p>Password</p>
                  </label>
                  <input type="password" nbInput fullWidth formControlName="NewPassword" autocomplete="new-password">
                  <label>
                      <p>Confirm password</p>
                  </label>
                  <input type="password" nbInput fullWidth formControlName="ConfirmPassword" autocomplete="new-password">

                </form>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-box">
                <form [formGroup]="userDataForm">
                  <p><nb-checkbox formControlName="IsApproved" status="basic">Is approved</nb-checkbox></p>
                  <p><nb-checkbox formControlName="IsLockedOut" status="basic">Is locked</nb-checkbox></p>
                  <p><nb-checkbox formControlName="ForcePasswordReset" status="basic">Force Password Reset</nb-checkbox></p>
                  <p><button nbButton status="primary" (click)="sendForgotPassword()">Send Forgot Password E-Mail</button></p>
                </form>
              </div>
            </div>
        </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'Security'">
          <div class="row">
            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Customer Filter</p>
                </label>
                <nb-form-field>
                  <nb-tag-list (tagRemove)="onFilterTagRemove($event, 'CustomerFilter', customerTags, 0)">
                    <nb-tag status="primary" *ngFor="let tag of customerTags" [text]="tag.name" removable></nb-tag>
                    <input [(ngModel)]="searchValue.CustomerFilterInput" type="text" #CustomerFilterInput nbTagInput [nbAutocomplete]="customerFilterAutoComplete" fullWidth (ngModelChange)="filterModel($event, 0)">
                  </nb-tag-list>
                  <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
                </nb-form-field>
          
                <nb-autocomplete #customerFilterAutoComplete (selectedChange)="onFilterTagAdd($event, 'CustomerFilter', 0)">
                  <nb-option *ngFor="let option of customers$ | async" [value]="option">{{ option.name }}</nb-option>
                </nb-autocomplete>
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Run Template Filter</p>
                </label>
                <nb-form-field>
                  <nb-tag-list (tagRemove)="onFilterTagRemove($event, 'RunTemplateFilter', runTemplateTags, 1)">
                    <nb-tag status="primary" *ngFor="let tag of runTemplateTags" [text]="tag.name" removable></nb-tag>
                    <input [(ngModel)]="searchValue.RunTemplateFilterInput" type="text" #RunTemplateFilterInput nbTagInput [nbAutocomplete]="runTemplateFilterAutoComplete" fullWidth (ngModelChange)="filterModel($event, 1)">
                  </nb-tag-list>
                  <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
                </nb-form-field>
          
              <nb-autocomplete #runTemplateFilterAutoComplete (selectedChange)="onFilterTagAdd($event, 'RunTemplateFilter', 1)">
                <nb-option *ngFor="let option of runTemplates$ | async" [value]="option">{{ option.name }}</nb-option>
              </nb-autocomplete>
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Recipient Filter</p>
                </label>
                <nb-form-field>
                <nb-tag-list (tagRemove)="onFilterTagRemove($event, 'RecipientFilter')">
                  <nb-tag status="primary" *ngFor="let tag of userDataForm.value.RecipientFilter" [text]="tag" removable></nb-tag>
                  <input type="text" nbTagInput (tagAdd)="onTagAdd($event.value)" [nbAutocomplete]="autocomplete" fullWidth>
                </nb-tag-list>
                <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
              </nb-form-field>
          
              <!-- <nb-autocomplete #autocomplete (selectedChange)="onTagAdd($event)">
                <nb-option *ngFor="let option of tagOptions" [value]="option">{{ option }}</nb-option>
              </nb-autocomplete> -->
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Permissions'">
          <div class="row">
            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Role Groups</p>
                </label>
                <nb-form-field>
                  <nb-tag-list (tagRemove)="onTagRemove($event, 'RoleGroups')">
                    <nb-tag status="primary" *ngFor="let tag of RoleGroupsTags" [text]="tag" removable></nb-tag>
                    <input [disabled]="roleGroupsDisabled" [(ngModel)]="searchValue.RoleGroupsInput" type="text" #RoleGroupsInput nbTagInput [nbAutocomplete]="roleGroupsAutoComplete" fullWidth>
                  </nb-tag-list>
                  <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
                </nb-form-field>
            
                <nb-autocomplete #roleGroupsAutoComplete (selectedChange)="onTagAdd($event, 'RoleGroups')">
                  <nb-option *ngFor="let option of systemRoleGroups$ | async| filter :'value' : searchValue.RoleGroupsInput: 'includes'" [value]="option.value">{{ option.value }}</nb-option>
                </nb-autocomplete>
              </div>
            </div>

            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Added Roles</p>
                </label>
                <nb-form-field>
                  <nb-tag-list (tagRemove)="onTagRemove($event, 'RolesAdded')">
                    <nb-tag status="primary" *ngFor="let tag of RolesAddedTags" [text]="tag" removable></nb-tag>
                    <input [disabled]="roleGroupsDisabled" [(ngModel)]="searchValue.RolesAddedInput" type="text" #RolesAddedInput nbTagInput [nbAutocomplete]="rolesAddedAutoComplete" fullWidth>
                  </nb-tag-list>
                  <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
                </nb-form-field>

                <nb-autocomplete #rolesAddedAutoComplete (selectedChange)="onTagAdd($event, 'RolesAdded')">
                  <nb-option *ngFor="let option of systemRoles$ | async | filter :'value' : searchValue.RolesAddedInput: 'includes'" [value]="option.value">{{ option.value }}</nb-option>
                </nb-autocomplete>
              </div>
            </div>

            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Removed Roles</p>
                </label>
                <nb-form-field>
                  <nb-tag-list (tagRemove)="onTagRemove($event, 'RolesRemoved')">
                    <nb-tag status="primary" *ngFor="let tag of RolesRemovedTags" [text]="tag" removable></nb-tag>
                    <input [disabled]="roleGroupsDisabled" [(ngModel)]="searchValue.RolesRemovedInput" type="text" #RolesRemovedInput nbTagInput [nbAutocomplete]="rolesRemovedAutoComplete" fullWidth>
                  </nb-tag-list>
                  <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
                </nb-form-field>

                <nb-autocomplete #rolesRemovedAutoComplete (selectedChange)="onTagAdd($event, 'RolesRemoved')">
                  <nb-option *ngFor="let option of systemRoles$ | async| filter :'value' : searchValue.RolesRemovedInput: 'includes'" [value]="option.value">{{ option.value }}</nb-option>
                </nb-autocomplete>
              </div>
            </div>
        </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Effective Permissions'">
          <div class="row">
            <div class="col-md-12" *ngIf="hasEffectivePermissions">
              <div class="input-box" [nbSpinner]="effectiveRoles.length === 0 && userDataForm.value.RoleGroups.length>0" nbSpinnerStatus="primary" nbSpinnerSize="large" nbSpinnerMessage="Loading">
                <label>
                  <p>User Roles:</p>
                </label>
                <nb-tag-list>
                  <nb-tag status="basic" *ngFor="let role of effectiveRoles" [text]="role" [removable]="false"></nb-tag>
                </nb-tag-list>
              </div>
            </div>

            <div class="col-md-12" *ngIf="!hasEffectivePermissions">
              <div class="input-box">
                <label>
                  <p>User Roles:</p>
                </label>
                
                <p class="warning">Please select roles to determine Effective Permissions.</p>
              </div>
            </div>
        </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Additional Security'">
          <div class="row">
            <div class="col-md-12">
              <div class="input-box">
                <label>
                  <p>Additional Security</p>
                </label>

                <form [formGroup]="userDataForm">
                  <p><nb-checkbox formControlName="OtpEnabled" status="basic">Enable Google Authenticator</nb-checkbox></p>
                </form>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'User History'">
          <div class="input-box" [nbSpinner]="!historyRetrieved" nbSpinnerStatus="primary" nbSpinnerSize="large">
            <button nbButton status="primary" (click)="rebuildUserHistory()">
              Rebuild User History
            </button>

            <table width="100%">
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Run</th>
                  <th>Billing Date</th>
                  <th>Run Date</th>
                  <th>Source</th>
                  <th>Channels</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of userHistory">
                  <td>
                    <ng-container *ngIf="field?.customerId === null">{{ field?.customerName }}</ng-container>
                    <ng-container *ngIf="field?.customerId !== null"><a [routerLink]="'../../customers/' + field.customerId">{{ field.customerName }}</a></ng-container>
                  </td>
                  <td>                    
                    <ng-container *ngIf="field?.run.id === null">{{ field?.run.name }}</ng-container>
                    <ng-container *ngIf="field?.run.id !== null"><a [routerLink]="'../../../run/' + field.run.id">{{ field.run.name }}</a></ng-container>
                  <td>{{ field.run.bilingDateDescription }}</td>
                  <td>{{ field.run.runDate.split("T")[0] }} ({{ field.run.runDate.split("T")[1].split(".")[0] }})</td>
                  <td>{{ field.historyAgent?.name  }}</td>
                  <td class="center clickable" (click)="open(dialog, field)">
                    <nb-icon pack="eva" icon="info"></nb-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>

        <ng-template #dialog let-data let-ref="dialogRef">
          <nb-card class="dialog-md">
            <nb-card-body>
              <p><span class="bold">Channel Details</span></p>
              <p><span class="bold">Customer:</span> 
                <ng-container *ngIf="data?.customerId === null"> {{ data?.customerName }}</ng-container>
                <ng-container *ngIf="data?.customerId !== null"><a [routerLink]="'../../customers/' + data.customerId"> {{ data.customerName }}</a></ng-container>
              </p>
              <p><span class="bold">Run:</span>                    
                <ng-container *ngIf="data?.run.id === null"> {{ data?.run.name }}</ng-container>
                <ng-container *ngIf="data?.run.id !== null"><a [routerLink]="'../../../run/' + data.run.id"> {{ data.run.name }}</a></ng-container></p>
              <hr />
              <ng-container *ngFor="let sequence of data.sequenceCommunication; index as i">
                <p class="bold">Sequence: {{i}}</p>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>Has Compiled IFS</td>
                      <td>{{ sequence.hasCompiledIfs ? "✅" : "❌"}}</td>
                    </tr>
                    <tr>
                      <td>Has Email Body</td>
                      <td>{{ sequence.hasMailBody ? "✅" : "❌" }}</td>
                    </tr>
                    <tr>
                      <td>Has SMS Body</td>
                      <td>{{ sequence.hasSmsBody ? "✅" : "❌" }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </nb-card-body>
            <nb-card-footer>
              <button type="button" nbButton status="primary" (click)="ref.close()">
                Close
              </button>
            </nb-card-footer>
          </nb-card>
        </ng-template>
      </div>
    </div>
  </nb-card-body>
</nb-card>