import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ObservableWrapper } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';

/*
Documentation link: https://docs.infoslipscloud.com/e/en/internal/oakmoon-shared-controls
Please ensure it is kept in sync with new changes to the code
*/

@Component({ template:''})
export class IiabLookupBaseComponent<T extends IiabIdName> implements OnInit, OnChanges, AfterViewInit{
 
    @Output() public ItemSelected = new EventEmitter<IiabIdName>();
    @Input() SelectedItem: IiabIdName | undefined;
    @Input() additionalWhere = null;
  
    itemNameLike='';
    itemNameLikeSubject = new Subject<string>();
  
    selectedItem: IiabIdName | undefined;
    items$: Observable<T[]>;
    inputElements: Array<any>= [];
    disabled=false;
    showLabel=true;

    private where:any = null;
  
    constructor(
      private itemService:ObservableWrapper<T>
    ) {

      if (this.additionalWhere != null) {
        this.setWhere(this.additionalWhere);
      }
 
      this.itemService.loadPagedItems(this.where); 
      this.items$ = this.itemService.Items$();
  
      this.itemNameLikeSubject.pipe(
        debounceTime(500),
        distinctUntilChanged())
        .subscribe(value => {
          this.deselectItem();
          if ( /^[0-9A-Fa-f]{24}$/i.test(value) ) {
            this.itemService.filterById(value, this.where);
            const fakeSelected:IiabIdName = { id: value, name: value };
            this.onItemSelected(fakeSelected)
          } else if (value.length > 0)  {
            this.itemService.filterByName(value, this.where);
            this.selectedItem = undefined;
          }
          else {            
            this.clear();
          }
        });
    }

    ngOnInit(): void {
      this.selectedItem = this.SelectedItem;
    }

    ngAfterViewInit(): void {
      this.items$.subscribe(res => {
        if (res.length == 1) {
          const fakeSelected:IiabIdName = { id: res[0].id, name: res[0].name }
          this.onItemSelected(fakeSelected);
        }
      })
    }

    ngOnChanges(changes: SimpleChanges) {
      if (JSON.stringify(changes?.additionalWhere?.currentValue) != JSON.stringify(changes?.additionalWhere?.previousValue)) this.setWhere(changes?.additionalWhere?.currentValue);
    }
  
    public clear(){
      this.inputElements.forEach(input=>input.value='');
      this.itemNameLike = '';
      this.ItemSelected.emit(undefined);
    }
  
    displayItemName(selected:any):string{
      return (selected as IiabIdName).name !== undefined ?
        selected.name:
        selected;
    }
  
    onItemSelected(event:any):void{
      if (!event || !event.id) return;
      this.selectedItem = event;
      this.ItemSelected.emit(this.selectedItem);
      if(this.selectedItem)
        this.itemNameLike = this.selectedItem.name;
    }

    addInputElement(input:any, autoSelect = true){
      if(autoSelect){
        this.enableAutoSelect()
      }
      input.value = this.selectedItem?.name??'';
      this.inputElements.push(input);
    }

    enableAutoSelect(){
      const count = this.itemService.totalCount$.pipe(take(2)).toPromise();
      count.then((totalResults: number) => {
        if(totalResults === 1){
          const firstItem = this.items$.pipe(take(1), map(item => item[0])).toPromise();

          firstItem.then(item => {
            this.onItemSelected(item);
          })
        }
      })
    }

    setWhere(additionalWhere:any)
    {
      this.where=additionalWhere;
      this.itemService.loadPagedItems(this.where); 
    }

    getWhere():any{
      return this.where;
    }

    setSelectedItem(item: IiabIdName){
      this.onItemSelected(item);
    }

    private deselectItem() {
      this.selectedItem=undefined;
      if (this.where?.id) delete this.where.id;
      if (this.where?.name) delete this.where.name;
      if (this.additionalWhere?.id) delete this.additionalWhere?.id;
      if (this.additionalWhere?.name) delete this.additionalWhere?.name;
    }
  }
