import { BaseEntityIdModel, BaseEntityIdNameModel, BaseEntityModel } from "./BaseEntity";

export interface PreProcessorModel extends ProcessorModel {
    UseRawStreams: boolean;
}

export interface PreProcessorReferenceModel extends BaseEntityIdNameModel{
    ClassData: PreProcessorClassDataModel;
    UseRawStreams:boolean;
}

export interface ProcessorModel extends BaseEntityModel {
    Files:UploadedFileModel[];
    Classes: ClassDataModel[]
}

export interface PostProcessorReferenceModel extends BaseEntityIdNameModel {
    ClassData: ClassDataModel
}

export interface PreProcessorClassDataModel extends ClassDataModel {
    RequiredFiles: RequiredFileModel[];
    ExpectConfiguration: boolean;
    ConfigurationSample: string;
}

export interface ClassDataModel extends BaseEntityIdModel {
    ClassName: string;
    AssemblyName: string;
    Interfaces: string[];
    AssemblyIsNotCollectable: boolean;
}

export interface RequiredFileModel {
    Description:      string;
    FileName:         string;
    IsRequired:       boolean;
    DisableAutoUnzip: boolean;
}

export interface UploadedFileModel {
    FileName:       string;
    FileId:         string;
    UploadFile?:    string;
    Size:           number;
    Uploaded?:      Date;
    UploadedBy?:     string;
    ShortUrl?:      string;
}

/*
public class PreProcessorAssembliesModel : ModelObjectIdBase
  {
    public string Name { get; set; }
    public List<UploadedFilesModel> Files { get; set; }
    public List<PreProcessorClassDataModel> Classes { get; set; }
    public bool UseRawStreams { get; set; }
  }
  */