<div class="general-page-container">
  <div class="general-page-header">
    <div class="title-bar row">
      <div class="col-md-3">
        <h4>Run Preview</h4>
        <p class="sub-title">{{selectedRun?.Name}}</p>
      </div>
      <div class="col-md-3">
        <p class="sub-title">Select Output</p>
        <nb-select placeholder="Email/SMS/Razor" [(selected)]="selectedTab">
          <nb-option onselect="setTab($event)" value="Email">Email</nb-option>
          <nb-option onselect="setTab($event)" value="SMS">SMS</nb-option>
          <nb-option onselect="setTab($event)" value="Razor">Razor</nb-option>
        </nb-select>
      </div>
      <div class="col-md-3">
            <p class="sub-title">Select Recipient</p>
            <input fullWidth #autoInput nbInput type="text" (input)="onChange()" placeholder="Enter Recipient Name"
                [nbAutocomplete]="auto" />
            <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
                <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.name">
                    {{ option.name }}
                </nb-option>
            </nb-autocomplete>
            </div>

      <div class="col-md-3 text-right">
        <nb-icon (click)="toggleTheme()" pack="oakmoon" icon="theme"></nb-icon>
        <nb-icon (click)="close()" pack="oakmoon" icon="remove" class="close"></nb-icon>
      </div>
    </div>
  </div>
  <div class="general-page-body">
    <ifs-razor-template *ngIf="selectedTab === 'Razor'" [events]="eventsSubject.asObservable()" [theme]="theme" [selectedRun]="selectedRun" [selectedRecipientId]="selectedRecipientId"></ifs-razor-template>
    <ifs-sms-template *ngIf="selectedTab === 'SMS'" [events]="eventsSubject.asObservable()" [selectedRun]="selectedRun" [selectedRecipientId]="selectedRecipientId"></ifs-sms-template>
    <ifs-email-template *ngIf="selectedTab === 'Email'" [events]="eventsSubject.asObservable()" [theme]="theme" [selectedRun]="selectedRun"></ifs-email-template>
  </div>
  <div class="general-page-footer">
    <div class="row">
      <div class="col-md-12 text-right action-buttons">
        <button nbButton status="secondary" (click)="close()">Close</button>
        <button nbButton [disabled]="(selectedTab !== 'Email' && !selectedRecipientId) || !selectedRun.IsTrial" status="primary" (click)="emitEventToChild()">
          {{selectedTab !== 'Email' ? "Transform" : "Save"}}
        </button>
      </div>
    </div>
  </div>
</div>
