  <div class="dashboard-detail-container" [ngClass]="{'full-width' : expand}" [class.active]="!isNew">
    <div class="row flex-column">

      <div class="general-page-container fit-absolute pb-65" *ngIf="selectedEntity; else loading">
        <div class="general-page-header">
          <div class="title-bar row">
            <div class="col-md-8">
              <h4><span [class.expired]="selectedEntity?.IsDraft">{{selectedEntity?.Display}}</span>
                <div>
                  <small>Name: {{selectedEntity?.Name}}</small>
                  <small><i> - Version: {{selectedEntity.IsDraft?'Draft':selectedEntity.Version  }}</i></small>
                </div> 
                <div>
                  <small>Scope:<i> {{entityScope }}</i></small>
                </div>
              </h4>
              <a *ngIf="selectedEntity?.RunTemplate" [routerLink]="'/cms/runtemplates/' + selectedEntity?.RunTemplate?.Id">Update {{selectedEntity?.RunTemplate?.Name}} Entities</a>              
            </div>            

            <div class="col-md-4 text-right header-action-container">
              <ng-template #information>
                <div class="row popover-info">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        Last Updated
                      </div>
                      <div class="col-md-8">
                        {{selectedEntity?.LastUpdated | date: 'short' }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        Updated By
                      </div>
                      <div class="col-md-8">
                        {{selectedEntity?.LastUpdatedBy}}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        Public Id:
                      </div>
                      <div class="col-md-8">
                        {{selectedEntity?.PublicId}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

              <nb-actions size="small">

                <!-- <nb-action nbTooltip="Favorite Entity" nbTooltipStatus="primary" nbTooltipPlacement="bottom" class="favorite" [icon]="(isStar$ | async)?.isStar ? 'star' : 'star-outline'"
                  (click)="favorite(run?.Name)"></nb-action> -->

                <nb-action class="info" [nbPopover]="information" nbPopoverTrigger="click" nbPopoverPlacement="top"
                  icon="info"></nb-action>

                  
                <nb-action 
                  nbTooltip="Delete Entity" 
                  nbTooltipStatus="danger" 
                  nbTooltipPlacement="bottom" 
                  class="danger" 
                  icon="trash-2" 
                  (click)="open(confirmDeleteDialog)"
                  *ngIf="entityCrudAllowed()">
                </nb-action>

                <nb-action *ngIf="entityCrudAllowed()" (click)="update()" nbTooltip="Save" nbTooltipStatus="primary" nbTooltipPlacement="bottom" class="favorite" icon="save">
                  <button nbButton>Save</button>
                </nb-action>

                <nb-action class="close" icon="close-outline" routerLink="/cms"></nb-action>
              </nb-actions>
            </div>
          </div>
        </div>

        <div class="general-page-body">
          <div class="run-nav">

            <div class="scroll-nav">
              <div class="general-side-nav">
                <ng-container *ngFor="let menuItem of selectedEntity?.entityMenu; index as i" >
                  <div class="context-menu" *ngIf="menuItem.visible" (click)="setTab(i)"
                  [class.active]="i === activeTab">
                  <div class="body">
                    <h4 class="title">{{ menuItem.name  }}</h4>
                  </div>
                  <div class="action text-center">
                    <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                  </div>
                </div>

                </ng-container>    
                
                <div class="toggles">
                  <nb-toggle class="toggle" [(ngModel)]="selectedEntity.IsDraft">
                    <p>Is Draft?</p>
                  </nb-toggle>
                </div>
              </div>
            </div>
          </div>

          <div class="run-context-detail" [class.expired]="selectedEntity?.IsDraft">
              <cms-entity-details [displaName]="false" *ngIf="activeTab===0"></cms-entity-details>
              <!-- <cms-entity-file *ngIf="activeTab===1"></cms-entity-file> -->
              <!-- <cms-file-handler [(cmsContentModel)]="cmsEntityModel" ></cms-file-handler> -->
              <cms-file-handler *ngIf="activeTab===1" [(cmsContentModel)]="selectedEntity" (cmsUploadFile)="setFileName($event)" [readonly]="true"></cms-file-handler>
              <cms-content-handler *ngIf="activeTab===2" [(cmsContentModel)]="selectedEntity" (saveContent)="saveCmsContent()"></cms-content-handler>
              <!-- <cms-preview-entity
                *ngIf="activeTab===1"
                [publicId]="selectedEntity.PublicId"
                [version]="selectedEntity.Version"
                [cmsEntityType]="selectedEntity.CmsEntityType"
                [cmsContentType]="selectedEntity.CmsContentType"
                [contentType]="selectedEntity.ContentType"
                [baseUrl]="environment.baseUrl"
>
              </cms-preview-entity> -->
              <!-- <cms-entity-code *ngIf="activeTab===2"></cms-entity-code> -->
              <cms-entity-references *ngIf="activeTab===3"></cms-entity-references>
              <cms-entity-revisions *ngIf="activeTab===4"></cms-entity-revisions>
          </div>
        </div>
      </div>
      
      <ng-template #loading>
        <div class="ghost-card">
          <div class="general-page-header">
            <div class="title-bar row">
              <div class="col-md-4">
                <div class="ghost-card">
                  <div class="lines">
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="circles">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </ng-template>
      
    </div>
  </div>


  <ng-template dialogClass="run-options-dialog" #confirmDeleteDialog let-ref="dialogRef">
    <nb-card>
      <nb-card-header class="create-recipient-dialogue">
        Confirm <nb-icon (click)="dialogRef.close()" icon="close" class="close"></nb-icon>
      </nb-card-header>
      <nb-card-body class="confirmDeleteBody">
        <p *ngIf="this.entityReferenceCount > 0" class="warning">This entity is still linked to {{ this.entityReferenceCount }} Run Template<span *ngIf="this.entityReferenceCount > 1" class="warning">s</span>.</p>
        <p>Are you sure you want to delete the entity: <span class="warning">{{selectedEntity.Name}}</span>?</p>
        <p>This action can not be reverted.</p>
      </nb-card-body>
      <nb-card-footer class="confirmDeleteFooter">
        <button fullWidth nbButton status="warning" (click)="delete()">Delete</button>
        <button fullWidth nbButton (click)="dialogRef.close()">Cancel</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>