<p *ngIf="readonly">Please note that CMS entity files are currently readonly.</p>

<fieldset>
    <legend>Preview</legend>

    <button *ngIf="cmsContentType === 18" nbButton outline status="primary" fullWidth (click)="openHtmlPreview(dialog)">Preview HTML</button>
    <button *ngIf="publicId" nbButton outline status="info" fullWidth (click)="download()">Download</button>

    <ng-container *ngIf="uploadFilePath; else onlineContent">
        <div *ngIf="cmsContentType === 9" class="col-md-12">
            <ng-container *ngIf="uploadFilePath?.includes('image/svg'); else image">
                <div style="background-image: url({{uploadFilePath}}); background-repeat: no-repeat; height: 500px;"></div>
            </ng-container>
            <ng-template #image>
                <div class="preview">
                    <img [src]="uploadFilePath" style="width: 100%;" />
                </div>
            </ng-template>
        </div>

        <div *ngIf="uploadFilePath && uploadFilePath !== '' && cmsContentType === 10">
            <pdf-viewer [src]="uploadFilePath" [render-text]="true" style="display: block;"></pdf-viewer>
        </div>

        <div class="preview" *ngIf="cmsContentType < 9 || cmsContentType >= 17 && cmsContentType !== 20">
            <nb-card>
                <nb-card-body>
                    <ifs-monaco-editor disabled [data]="uploadFilePath" (onChange)="fileState.emit($event)" [readonly]="readonly">
                    </ifs-monaco-editor>
                </nb-card-body>
            </nb-card>
        </div>

   
        <div class="preview" *ngIf="cmsContentType === 11">
            <object type="application/zip" [data]="uploadFilePath"></object>
        </div>
        <div class="preview" *ngIf="cmsContentType === 12">
            <video [src]="uploadFilePath" preload="none" controls style="width: 100%;"></video>
        </div>
        <div class="preview" *ngIf="cmsContentType === 14">
            <audio [src]="uploadFilePath" preload="none" controls style="width: 100%;" autoplay></audio>
        </div>

        <div class="preview" *ngIf="cmsContentType === 20">
            <p>No preview available for this file type.</p>
        </div>
    </ng-container>

    <ng-template #onlineContent>
        <div class="preview" *ngIf="cmsContentType < 9 || cmsContentType >= 17 && cmsContentType !== 20">
            <nb-card>
                <nb-card-body>
                    <ifs-monaco-editor disabled url="cms/publicid/{{publicId}}/{{version}}" [readonly]="readonly">
                    </ifs-monaco-editor>
                </nb-card-body>
            </nb-card>
        </div>

        <div class="preview" *ngIf="(cmsContentType === 9 || contentType?.includes('image')) && !uploadFilePath && publicId">
            <img src="{{baseUrl}}api/cms/publicid/{{publicId}}/{{version}}" style="width: 100%;" />
        </div>

        <div class="preview" *ngIf="cmsContentType === 10">
            <object type="application/pdf" data="{{baseUrl}}api/cms/publicid/{{publicId}}/{{version}}"></object>
        </div>
        <div class="preview" *ngIf="cmsContentType === 11">
            <object type="application/zip" data="{{baseUrl}}api/cms/publicid/{{publicId}}/{{version}}"></object>
        </div>
        <div class="preview" *ngIf="cmsContentType === 12">
            <video preload="none">
                <source src="{{baseUrl}}api/cms/publicid/{{publicId}}/{{version}}" />
            </video>
        </div>
        <div class="preview" *ngIf="cmsContentType === 14">
            <audio preload="none">
                <source src="{{baseUrl}}api/cms/publicid/{{publicId}}/{{version}}" />
            </audio>
        </div>
        
        <div class="preview" *ngIf="cmsContentType === 20">
            <p>No preview available for this file type.</p>
        </div>
    </ng-template>
</fieldset>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="dialog-card">
      <nb-card-header class="dialog-header">
        <p>Markdown to HTML Preview</p>
        <nb-toggle status="primary" class="source-toggle" [(ngModel)]="editorPreviewEnabled">Source</nb-toggle>
    </nb-card-header>
      <nb-card-body>
        <div style="padding: 1rem; padding-top: 0px;" *ngIf="!editorPreviewEnabled">
            <object [innerHTML]="data" style="max-width: 100%"></object>
        </div>
        <ifs-monaco-editor *ngIf="editorPreviewEnabled" [data]="data" language="html"></ifs-monaco-editor>
      </nb-card-body>
      <nb-card-footer>
        <button fullWidth nbButton (click)="ref.close()">Close</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>