
    <div class="row no-margin full-height" [style]="theme === 'light' ? 'background-color: white; color: black' : 'background-color: #1e1e1e; color: white' ">
    <div class="col-md-8 no-padding">
        <nb-card>
            <nb-card-body>
            <div class="row no-margin full-height">
                <div class="col-md-4 general-side-nav">
                    <div class="context-menu" *ngFor="let file of razorFiles$ | async" (click)="fileSelected(file)" [class.active]="file===selectedFile">
                        <div class="body">
                            <h4 class="title">{{ file }}</h4>
                        </div>
                        <div class="action text-center">
                            <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                        </div>
                
                    </div>

                </div>
                <div class="col-md-8 no-padding">
                    <ngx-monaco-editor class="monaco-editor" [options]="monacoConfig.editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
                </div>
            </div>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-4 no-padding">
        <nb-card class="full-height">
            <nb-card-body>
                <textarea class="output" value="{{ razorPreview$ | async }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>
            </nb-card-body>
        </nb-card>
    </div>

</div>