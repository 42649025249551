import { Component, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { CustomerService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { CmsFilterService as FilterService } from 'libs/cms/src/lib/services/cms-filter.service';
import { IiabLookupBaseComponent } from '../base/iiab-look-up-base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ifs-customer-group-lookup',
  templateUrl: './customer-group-lookup.component.html',
  styleUrls: ['./customer-group-lookup.component.scss']
})
export class CustomerGroupLookupComponent extends IiabLookupBaseComponent<IiabIdName> implements AfterViewInit {

  @ViewChild('customerAutoInput')
  customerAutoInput!: ElementRef<HTMLInputElement>;

  @Input() showLabel = true;
  @Input() showClear = false;
  
  ngModel: IiabIdName | undefined;
  disabled = true;

  constructor(customerService: CustomerService, public filterService: FilterService) {
    super(customerService.customerGroups);    
  }

  ngAfterViewInit(): void {
    if('selectedCustomerGroup' in this.filterService.CmsFilterState){
      //@ts-ignore
      this.setSelectedItem(this.filterService.CmsFilterState.selectedCustomerGroup);
    }

    if(this.ngModel){
      this.filterService.CmsFilterState.selectedCustomerGroup = this.ngModel;
      this.setSelectedItem(this.ngModel);
    }
    
    this.addInputElement(this.customerAutoInput.nativeElement);
    setTimeout(() => {
      this.disabled = false;
    }, 500);
  }

}