
export interface RunQueueStatus {
    ApplianceType:                  string;
    IpAddress:                      string;
    PdfGenerationStatus:            PdfGenerationStatus;
    RunActivityHistory:             ActivityHistoryMessage;
    RunSpecificQueues:              QueuePerRunQueueItemStatus[];
    StageActivityHistory:           ActivityHistoryMessage;
}

export interface PdfGenerationStatus {
    CreateQueue:                    QueueManagerStatus;
    KeysWaitingToBeProcessed:       string;
    ProcessedQueue:                 QueueManagerStatus;
    WaitingToBeProcessedCount:      string;
}

export interface QueueManagerStatus {
    Publisher:                      QueuePerRunQueueItemDetail;
    Subscriber:                     QueuePerRunQueueItemDetail;
}

export interface QueuePerRunQueueItemDetail {
    HasConnection?:                  boolean;
    IsEnabled?:                      boolean;
    LastActivity?:                   string;
    PublisherOutstandingConfirms?:   number;
    SubscriberActiveMessages?:       any;
    SubscriberIsPaused?:             boolean;
}

export interface ActivityHistoryMessage {
    LastReceivedDateTime?:           boolean;
    LastReceivedMessages?:           any;
    LastSentDateTime?:               string;
    LastSentMessages?:               any;
}

export interface QueuePerRunQueueItemStatus {
    Publisher:                      QueuePerRunQueueItemDetail;
    Subscriber:                     QueuePerRunQueueItemDetail;
    RunId:                          string;
}

