<div class="flex">
  <div class="col-md-12 flex">
    <nb-card class="col-md-4 input-box">
      <nb-card-header>
        <h2>Run Search Fields</h2>
        <button nbButton type="button" (click)="add()">+ Add field</button>
      </nb-card-header>
      <nb-card-body class="padded pv-16">
        <form class="form-padding" [formGroup]="searchFieldsForm">
          <div formArrayName="fields">
            <div *ngFor="let field of fields.controls; let i = index">
              <nb-form-field>
                <label for="field-{{ i }}">
                  <p>Field:</p>
                </label>
                <input nbInput id="field-{{ i }}" type="text" [formControlName]="i" fullWidth (ngModelChange)="updateSearchFields.emit(fields.value)" />
                <button nbSuffix nbButton (click)="remove(i)" status="danger" style="margin-top: 34px">
                  <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                </button>
              </nb-form-field>
            </div>
          </div>
        </form>
      </nb-card-body>
      <nb-card-footer *ngIf="searchDataEntity">
        <cms-entity-list-component 
          [isRestModel]="isRestModel(searchDataEntity)" 
          [selected]="true" [cmsEntity]="searchDataEntity" 
          (entityRemoveEvent)="removeRunSearchEntity.emit($event)"
        ></cms-entity-list-component>
      </nb-card-footer>
    </nb-card>
  
    <nb-card class="col-md-8 input-box">
      <nb-card-header class="row">
        <div class="flex justify-between">
          <div class="col-md-6 entity-header">
              Available Data Models: 
              {{cmsEntitiesCount$._value}} 
          </div>
          <nb-form-field class="col-md-6 justify-end">
            <input nbInput fullWidth placeholder="Search linked entities..." [(ngModel)]="searchValue" (ngModelChange)="filterEntities($event)"  />
            <button nbSuffix nbButton ghost (click)="clearSearch()">
              <nb-icon icon="close-outline" pack="eva"></nb-icon>
            </button>
          </nb-form-field>
      </div>      
      </nb-card-header>

      <nb-card-body class="padded padded-list">
        <div class="row full-width m-0">
            <div class="col-sm-12 col-md-12" *ngFor="let entity of cmsEntities$ | async; let i = index">
              <cms-entity-list-component [cmsEntity]="entity" (entityAddEvent)="addRunSearchEntity.emit($event)"></cms-entity-list-component>
            </div>
          </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>