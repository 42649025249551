import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBaseService } from '@InfoSlips/iiab-api';

@Component({
  styles: [],
  template:  ``,
  selector: 'ifs-short-url',
})
export class ShortUrlComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router, 
    private baseApi: ApiBaseService
  ) { }

  ngOnInit(): void {
    const shortCode = this.activatedRoute.snapshot.paramMap.get('shortCode');

    this.baseApi.executeGetAny(`shortUrl/${shortCode}`, "Retreive Short Url route.", {responseType: "text"}).subscribe((redirectUrl: any) => {
      if (redirectUrl) {
        window.location = redirectUrl;
      } else {
        this.router.navigateByUrl("/login");
      }
    });
  }
}
