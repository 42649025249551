import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from '@InfoSlips/env';

import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from 'apollo-angular/http';
import { onError } from 'apollo-link-error';

import { ApolloClient, split, ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '@InfoSlips/iiab-auth';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [
  ],
  providers:[{
    provide: APOLLO_OPTIONS,
    
    useFactory(httpLink: HttpLink, environment: EnvironmentService, authService:AuthService) {
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ extensions }) => {
            if (extensions == null || extensions.message == null){
              console.log("GraphQL Module error: ", graphQLErrors);
            }
            // Handle unauthorized operation by logging the user out and taking them to the login screen.
            else if(extensions.message.toLowerCase().includes('unauthorized')){
              console.warn("Unauthorized, redirecting to login page."); 
              authService.logout();
            }
          });
          if (networkError) console.log(`[Network error]: ${networkError}`);
        });

      // Create an http link:
      const http = httpLink.create({
        uri: `${environment.apiUrl}graphql`,
      });

      let ws = null 
      let link = null;

      if(environment.applicationName === "admin"){
        //console.log("Websocket URL: ", environment.webSocketUrl);
        
        // Create a WebSocket link:
        ws = new WebSocketLink({
          uri: environment.webSocketUrl,
          options: {
            reconnect: true,
            connectionParams: {
              authToken: authService.loggedInUser?.access_token
            },
          },
        });
        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        link = split(
          // split based on operation type
          ({query}) => {
            //@ts-ignore
            const {kind, operation} = getMainDefinition(query);
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          ws,
          http,
        );
      }
      else {
        link = http;
      }

      return {
        link: errorLink.concat(link),
        cache: new InMemoryCache({
          typePolicies:{
            run:{
              keyFields: ["id"]
            },
            RunInstance:{
              fields:{
                progressCounters:{
                  merge(existing, incoming: any[]){
                    return incoming;
                  }
                }
              },
              keyFields: ["id"]
            },
            runRecipient:{
              keyFields: ["id"]
            },
            RunsConnection:{
              fields:{
                edges:{
                  merge(existing, incoming: any[]){
                    return incoming;
                  }
                }
              },
              keyFields:[]
            },
          }
        }),
      };
    },

    deps: [HttpLink, EnvironmentService, AuthService]
  }]
})

export class GraphqlModule{
  
}
