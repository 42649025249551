<ng-container [ngSwitch]="type">
    <ng-template [ngSwitchCase]="'general'">
        <nb-card class="card mt-0" [class.active]="selected">
            <nb-card-header>
                <p><b>{{ cmsEntity.display?.toUpperCase() ?? cmsEntity.display.name }}</b></p>
                <!-- <nb-icon pack="oakmoon" icon="delink"></nb-icon> -->
            </nb-card-header>
            <nb-card-body class="h-100">
                <div class="row card-body">

                    <ng-container *ngTemplateOutlet="placeHolderTemplate"></ng-container>

                    <div class="col-lg-9 right">
                        <div class="top">
                            <p>Type: <b>{{cmsEntity.cmsContentType}}</b></p>
                            <p>Version: {{ cmsEntity.version !== -1 ? cmsEntity?.version : 'Draft' }}</p>
                            <p>Notes: {{ cmsEntity.notes }}</p>
                            <p>Customer: {{cmsEntity.customer?.name}}</p>
                            <p>RunTemplate: {{cmsEntity.runTemplate?.name}}</p>
                        </div>
                    </div>
                </div>
            </nb-card-body>
            <nb-card-footer>
                <ng-container *ngTemplateOutlet="defaultCmsCardFooter"></ng-container>
            </nb-card-footer>
        </nb-card>
    </ng-template>

    <ng-template [ngSwitchCase]="'linked'">
        <nb-card class="card mt-0 no-hover" [class.active]="selected">
            <nb-card-header>
                <p><b>{{ cmsEntity.display?.toUpperCase() ?? cmsEntity.name }}</b></p>
                <nb-icon
                    (click)="displayEntityInfo(cmsEntityInfoDialog, cmsEntity.id)"
                    pack="oakmoon"
                    icon="info-circle"
                    status="info"
                    [ngStyle]="{'float': 'right', 'margin-top': '-35px', 'margin-right': !readOnly ? '60px' : null}">                    
                </nb-icon>
                <nb-icon 
                    *ngIf="!readOnly"
                    class="hover" 
                    (click)="removeEntity(cmsEntity)" 
                    pack="oakmoon" 
                    [icon]="linkIcon"
                    (mouseenter)="linkIcon = 'delink'" 
                    (mouseout)="linkIcon = 'link'"
                    style="float: right; margin-top: -38px;">
                </nb-icon>
            </nb-card-header>
            <nb-card-body class="h-100">
                <div class="row card-body">
                    <ng-container *ngTemplateOutlet="placeHolderTemplate"></ng-container>
                    <div class="col-lg-9 right">
                        <div class="top">
                            <p>Type: <b>{{cmsEntity.cmsContentType}}</b></p>
                            <nb-checkbox 
                                [(ngModel)]="cmsEntity.useOffline" 
                                [disabled]="readOnly"
                                (checkedChange)="modifiedEntity.emit(cmsEntity)">Use Offline?</nb-checkbox> <br />
        
                            <fieldset>
                                <legend>Specify Version</legend>
                                <nb-radio-group 
                                    [name]="cmsEntity.id" 
                                    [(value)]="selectedVersionType"
                                    (valueChange)="toggleSelectedVersionType($event)"
                                    [disabled]="readOnly">
                                    <nb-radio [value]="0">Draft</nb-radio>
                                    <nb-radio [value]="1">Latest Version</nb-radio>
                                    <nb-radio [value]="2">Specific Version</nb-radio>
                                </nb-radio-group>
        
                                <p *ngIf="cmsRevisions.length !== 0 && selectedVersionType===2">Specific Version:
                                    <nb-select *ngIf="selectedVersionType===2" 
                                        placeholder="Select Version"
                                        [selected]="cmsEntity.version" 
                                        [compareWith]="compareByVersion"
                                        (selectedChange)="selectVersion($event)"
                                        [disabled]="readOnly">
                                        <nb-option *ngFor="let revision of cmsRevisions" [value]="revision.version">{{
                                            revision.version }}</nb-option>
                                    </nb-select>
                                </p>
                                <nb-checkbox *ngIf="selectedVersionType===1" 
                                    [(ngModel)]="cmsEntity.lockLatestVersion"
                                    [(value)]="cmsEntity.lockLatestVersion"
                                    [disabled]="readOnly">Lock Latest Version?</nb-checkbox>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </nb-card-body>
            <nb-card-footer>
                <div *ngIf="linkErrorMessage?.length>0" style="color:red">{{linkErrorMessage}}</div>
                <nb-icon *ngIf="isDirty" (click)="resetLinked()" pack="eva" icon="undo-outline" style="float: right;">
                </nb-icon>
            </nb-card-footer>
        </nb-card>
    </ng-template>

    <ng-template [ngSwitchCase]="'linked-runtemplate'">
        <nb-card class="card mt-0 no-hover" [class.active]="selected">
            <nb-card-header>
                <p><b>{{ cmsEntity.display?.toUpperCase() ?? cmsEntity.name }}</b></p>
            </nb-card-header>
            <nb-card-body class="h-100">
                <div class="row card-body">
                    <ng-container *ngTemplateOutlet="placeHolderTemplate"></ng-container>
                    <div class="col-lg-9 right">
                        <div class="top">
                            <p>Type: <b>{{cmsEntity.cmsContentType}}</b></p>
                            <p>Version: <b>{{ cmsEntity.version !== -1 ? cmsEntity.version : 'Draft'}}</b></p>
                            <nb-checkbox [(ngModel)]="cmsEntity.useOffline" disabled>Use Offline?</nb-checkbox> <br />
                            <nb-checkbox *ngIf="!cmsEntity.isDraft" [value]="cmsEntity.version===0" disabled>Use Latest?
                            </nb-checkbox> <br />
                            <nb-checkbox *ngIf="!cmsEntity.isDraft" [(ngModel)]="cmsEntity.lockLatestVersion" disabled>
                                Lock Latest?</nb-checkbox>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </ng-template>

    <ng-template [ngSwitchCase]="'available'">
        <nb-card class="card mt-0 no-hover" [class.active]="selected">
            <nb-card-header>
                <p><b>{{ cmsEntity.display?.toUpperCase() ?? cmsEntity.name }}</b></p>
                <nb-icon class="hover" (click)="addEntity(cmsEntity)" pack="eva" [icon]="availableIcon"
                    (mouseenter)="availableIcon = 'link'" (mouseout)="availableIcon = 'plus-outline'"
                    style="float: right; margin-top: -38px;"></nb-icon>
                <!-- <nb-icon pack="oakmoon" icon="delink"></nb-icon> -->
            </nb-card-header>
            <nb-card-body class="h-100">
                <div class="row card-body">
                    <ng-container *ngTemplateOutlet="placeHolderTemplate"></ng-container>
                    <div class="col-lg-9 right">
                        <div class="top">
                            <p>Type: <b>{{cmsEntity.cmsContentType}}</b></p>
                            <p>Name: <b>{{cmsEntity.name}}</b></p>
                            <p>Version: {{ cmsEntity.version !== -1 ? cmsEntity?.version : 'Draft' }}</p>
                            <p>Notes: {{ cmsEntity.notes }}</p>
                        </div>
                    </div>
                </div>
            </nb-card-body>
            <nb-card-footer>
                <ng-container *ngTemplateOutlet="defaultCmsCardFooter"></ng-container>
            </nb-card-footer>
        </nb-card>
    </ng-template>
</ng-container>

<ng-template #placeHolderTemplate>
    <div class="col-lg-3 left">
        <div style="width: 100%; min-height: 60px;" *ngIf="hasContentIcon">
            <nb-icon pack="file-icons" [icon]="contentIcon" class="icon"></nb-icon>
        </div>
    </div>
</ng-template>

<ng-template #defaultCmsCardFooter>
    <div *ngIf="cmsEntity.lastUpdatedBy !== null">
        <p style="float:left">{{ cmsEntity.lastUpdatedBy }}</p>
        <p style="float:right">updated {{ cmsEntity.lastUpdated.split("T")[0] }} ({{
            cmsEntity.lastUpdated.split("T")[1].split(".")[0] }})</p>
    </div>
    <div *ngIf="cmsEntity.createdBy !== null && cmsEntity.lastUpdatedBy === null">
        <p style="float:left">{{ cmsEntity.createdBy }}</p>
        <p style="float:right">created {{ cmsEntity.created.split("T")[0] }} ({{
            cmsEntity.created.split("T")[1].split(".")[0] }})</p>
    </div>
</ng-template>

<ng-template dialogClass="entity-info-dialog" #cmsEntityInfoDialog let-ref="dialogRef" let-data>
    <nb-card class="dialog-card">
      <nb-card-header class="dialog-header">
        {{data.Display}} <nb-icon (click)="dialogRef.close()" icon="close" class="close"></nb-icon>
      </nb-card-header>
      <nb-card-body class="dialog-info">
        <table>
            <tr>
                <td>Name:</td>
                <td>{{data.Name}}</td>
            </tr>
            <tr>
                <td>Display Name:</td>
                <td>{{data.Display}}</td>
            </tr>
            <tr>
                <td>Id:</td>
                <td>{{data.Id}}</td>
            </tr>
            <tr>
                <td>Public Id:</td>
                <td>{{data.PublicId}}</td>
            </tr>
            <tr>
                <td>Cms Entity Type:</td>
                <td>{{data.CmsEntityType === 1 ? "File" : "Content"}}</td>
            </tr>
            <tr>
                <td>Cms Content Type:</td>
                <td>{{cmsService.getContentTypeEnum(data.CmsContentType)}}</td>
            </tr>
        </table>
      </nb-card-body>
    </nb-card>
  </ng-template>