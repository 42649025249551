import { Component, OnInit } from '@angular/core';
import { PersonalDetailsService, Detail } from '@InfoSlips/iiab-api';
import { Observable, forkJoin } from 'rxjs';
import { slideInAnimation } from '@InfoSlips/shared';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ifs-personal-details',
  template: `
  <div class="row">
    <div class="col-md-3">
      <ifs-nav class="content-nav"></ifs-nav>
    </div>
    <div class="col-md-9">
      <div class="content-detail"  @slideInAnimation>
            <ifs-personal-details-form
              [profileData]="profile$"
              (save)="savePersonalDetails($event)"
              (addAdditionalItem)="saveAdditionalItem($event)"
            ></ifs-personal-details-form>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./personal-details.component.scss'],
  animations: [slideInAnimation],
})
export class PersonalDetailsComponent implements OnInit {
  // countries$: Observable<Country[]>;
  profile$: Observable<any>;
  token: string;

  constructor(
    private service: PersonalDetailsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.profile$ = forkJoin(
      this.service.getProfile(),
      this.service.getCountries()
    );
  }

  savePersonalDetails(value) {
    this.service.addInfoSlipDetail(value);
  }

  saveAdditionalItem(value: Detail) {
    return this.service.addInfoSlipDetail(value);
  }
}
