import { IiabId } from "./iiab-reference";

export interface HistoryCardOverview {
    body: string;
    sequence: number;
    title: string;

}

export interface HistoryCardSequence {
    groupName: string;
    label: string;
    number: number,
    overview: HistoryCardOverview[];
}

export interface HistoryCard extends IiabId {
    customerId: string;
    customerName: string;
    documentType: string;
    image: string;
    period: string;
    publisherId: string;
    runDate: Date;
    runName: string;
    sequences: HistoryCardSequence[];
}