import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LoadingScreenService } from '../services/loading-screen.service';
import { EnvironmentService } from '@InfoSlips/env';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  activeRequests = 0;

  /**
   * URLs for which the loading screen should not be enabled
   */
  skipUrls = this.environment.applicationName === "admin" ? 
    ['/authrefresh', "/graphql"] : 
    // Viewer Urls
    ['/authrefresh'];

  constructor(
    private loadingScreenService: LoadingScreenService,
    private environment: EnvironmentService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;

    for (const skipUrl of this.skipUrls) {
      if (new RegExp(skipUrl).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
