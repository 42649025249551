import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsEntity, CmsEntityReference, RunTemplateSummary } from '@InfoSlips/models';
import { Observable } from 'rxjs';
import { CmsService } from '../../services/cms.service';
import { CustomerService } from '@InfoSlips/iiab-api';
@Component({
  selector: 'cms-update-run-template',
  templateUrl: './update-run-template.component.html',
  styleUrls: ['./update-run-template.component.scss']
})
export class UpdateRunTemplateComponent implements OnInit {

  runTemplateId = this.router.snapshot.paramMap.get('id');
  runTemplateSummary: RunTemplateSummary = null;
  showDetails$ = this.cmsService.showDetails$;
  cmsGlobalEntities$ = this.cmsService.cmsGlobalEntities.Items$();
  cmsTotalAvailable$: Observable<number> = this.cmsService.cmsGlobalEntities.totalCount$;
  linkedFilterText = '';
  searchAvailableValue = null;
  clearIconState = false;
  customerGroupId = "";
  filteredLinkedEntities: CmsEntityReference[] = [];
  linkedEntityIds = {
    "id": {
      "and": []
    }
  }


  constructor(
    private router: ActivatedRoute,
    public cmsService: CmsService,
    private customerService: CustomerService
  ) { }

  async ngOnInit() {
    const response = await this.cmsService.getLinkedCmsEntities(this.runTemplateId);
    this.runTemplateSummary = response;
    this.filteredLinkedEntities = response.cmsEntities;

    this.customerService.customerGroups.Items$().subscribe(res => {if (res.length > 0) this.customerGroupId = res[0].id;})
    this.customerService.customerGroups.loadPagedItems(this.getCustomerGroupFilter());

    this.getLinkedEntityIds(response.cmsEntities);
  }

  formatEntities(entities) {
    const entityArray: CmsEntity[] = [];

    entities.forEach(entity => {
      const keys = Object.entries(entity);
      const capsKeys = keys.map((key) => [key[0][0].toLowerCase() + key[0].slice(1), key[1]]);
      entityArray.push(Object.fromEntries(capsKeys));
    });

    entityArray.map(entity => {
      entity.display = entity.name;
    })

    return entityArray;
  }

  save() {
    console.log('ToBeSaved',{id:this.runTemplateId, entities:this.runTemplateSummary.cmsEntities})
    this.cmsService.linkCmsEntities(this.runTemplateId, this.runTemplateSummary.cmsEntities);
  }

  clear() {
    if (this.runTemplateSummary.cmsEntities.length == 0)
      return;

    this.runTemplateSummary.cmsEntities.splice(0, this.runTemplateSummary.cmsEntities.length);
    this.linkedFilterText = '';
    this.applyFilterLinkedEntities();
  }

  clearSearch(type: string) {
    if (type === 'linked') {
      this.linkedFilterText = '';
      this.applyFilterLinkedEntities();
    } else {
      this.searchAvailableValue = '';
      const filter = { ...this.getAvailableFilter(this.searchAvailableValue), ...this.linkedEntityIds };
      this.cmsService.cmsGlobalEntities.loadPagedItems(filter);
    }
  }

  clearHover(show: boolean) {
    this.clearIconState = show;
  }

  filterAvailableEntities(e: string) {
    const filter = { ...this.getAvailableFilter(e), ...this.linkedEntityIds };
    this.cmsService.cmsGlobalEntities.loadPagedItems(filter);
  }

  private applyFilterLinkedEntities() {
    const filteredItems = this.runTemplateSummary.cmsEntities.filter(item => {
      return this.linkedFilterText?.length > 0 ?
        item.name.toLowerCase().indexOf(this.linkedFilterText.toLowerCase()) !== -1 :
        true;
    });
    this.filteredLinkedEntities = filteredItems;
  }

  entityRemovedEvent(event){
    this.filteredLinkedEntities = event;
    this.getLinkedEntityIds(event);
  }

  getLinkedEntityIds(cmsEntities: CmsEntityReference[]){
    this.linkedEntityIds.id.and = [];

    cmsEntities.forEach(entity => {
      this.linkedEntityIds.id.and.push({
        'neq': entity.id
      })
    });
    
    const filter = { ...this.getAvailableFilter(this.searchAvailableValue), ...(this.linkedEntityIds.id.and.length > 0 ? this.linkedEntityIds : undefined) };
    
    this.cmsService.cmsGlobalEntities.loadPagedItems(filter);
  }

  onScroll() {
    this.cmsService.cmsGlobalEntities.loadNextPage();
  }

  private getAvailableFilter(searchValue: string) {

    let filter = {};
    
    if (searchValue?.length == 0 || !searchValue) {
      filter = {
        or: [
          { 
            and: [
              { customer: {id: {eq: null}}},
              { runTemplate: {id: {eq: null}}},
              { customerGroup: {id: {eq: null}}}
            ] 
          }
        ]
      }

      if (this.customerGroupId.length > 0) filter["or"].push({customerGroup: {id: { eq: this.customerGroupId } }});
      if (this.runTemplateSummary.customer.id.length > 0) filter["or"].push({customer: {id: { eq: this.runTemplateSummary.customer.id } }});
      if (this.runTemplateSummary.id.length > 0) filter["or"].push({runTemplate: {id: { eq: this.runTemplateSummary.id } }});
    }
    else {
      filter = {
        and: [
          {
            or: [
              {
                and: [
                  { customer: { id: { eq: null } } },
                  { runTemplate: { id: { eq: null } } },
                  { customerGroup: { id: { eq: null } } }
                ]
              }
            ]
          },
          {
            or: [
              { name: { like: searchValue } },
              { display: { like: searchValue } }
            ]
          }
        ]
      }

      if (this.runTemplateSummary.customer.id.length > 0) {
        filter["and"][0]["or"].push({and: [ { customer: { id: { eq: this.runTemplateSummary.customer.id } } } ] });

        if (this.runTemplateSummary.id.length > 0) filter["and"][0]["or"][1]["and"].push({
          or: [
            { runTemplate: {id: { eq: null } } },
            { runTemplate: {id: { eq: this.runTemplateSummary.id } }}
          ]
        });
      };
      if (this.customerGroupId.length > 0) filter["and"][0]["or"].push( { customerGroup: {id: { eq: this.customerGroupId } } });
    }

    return filter;
  }

  private getCustomerGroupFilter() {
    return {
      customers: {
        some: {
          id: { 
            eq : `${this.runTemplateSummary.customer.id}`}
          }
        }
      };
  }
}
