import { Component, OnInit } from '@angular/core';
import { slideInAnimation, routeAnimation } from '@InfoSlips/shared';
import { PasswordService } from '@InfoSlips/iiab-auth';
@Component({
  selector: 'ifs-change-password',
  template: `
  <div class="row">
    <div class="col-md-3">
      <ifs-nav class="content-nav"></ifs-nav>
    </div>
    <div class="col-md-9">
      <div class="content-detail"  @slideInAnimation>
        <ifs-change-password-form
          (update)="updatePassword($event)"
          @slideInAnimation
        ></ifs-change-password-form>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./change-password.component.scss'],
  animations: [slideInAnimation, routeAnimation]
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private passwordService: PasswordService
  ) {}

  ngOnInit() {}

  updatePassword(val) {
    const { currentPassword: CurrentPassword, password: NewPassword } = val;
    this.passwordService.changePassword({ CurrentPassword, NewPassword });
  }
}
