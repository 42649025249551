<nb-card class="file-handler">
    <nb-card-header>
        File Type
        <nb-select style="margin-top: -6px !important;" class="select" [(selected)]="cmsContentType" placeholder="Select File Type" (selectedChange)="contentTypeSelected($event)">
            <nb-option *ngFor="let item of cmsContentTypes$ | async" [value]="item">{{item.value}}</nb-option>
        </nb-select>
    </nb-card-header>
    <nb-card-body class="padded">
        <label>Upload File</label>
        <hr/>
        <div *ngIf="!uploadFile" class="upload-box row" (dragover)="handleDragOver($event)" (drop)="handleFileDrop($event)">
            <div class="col-md-8">
                <p>
                    Add Content File
                </p>
            </div>
            <div class="col-md-4">
                <div class="file-actions flex-right">
                    <span>
                        <input id="file-upload" type="file" (change)="handleFileInput($event)" />
                        <label class="choose-file" nbButton outline for="file-upload">
                            {{ cmsContentModel.PublicId ? "Replace File" : "Choose File"}}
                        </label>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="uploadFile" class="upload-box row" (dragover)="handleDragOver($event)" (drop)="handleFileDrop($event)">

            <div class="col-md-7">
                <p>
                    {{uploadFile.name}}<span *ngIf="uploadFile.size === 0">*</span>
                </p>
            </div>
            <div class="col-md-5">
                <ng-container *ngIf="uploadFile?.size > 0">
                    <div class="file-actions">
                        <div class="action">
                            <span *ngIf="uploadFile.size > 0">{{ uploadFile.size / 1024 / 1024 | number }} MB</span>
                        </div>
                        <div class="action">
                            <input id="file-upload" type="file" (change)="handleFileInput($event)" />
                            <label class="choose-file" nbButton outline for="file-upload">Choose Different File</label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <cms-preview-entity
            *ngIf="cmsContentType != null"
            [readonly]="readonly"
            [publicId]="cmsContentModel.PublicId"
            [version]="cmsContentModel.Version"
            [cmsEntityType]="cmsContentModel.CmsEntityType"
            [cmsContentType]="cmsContentModel.CmsContentType"
            [contentType]="cmsContentModel.ContentType"
            [baseUrl]="environment.baseUrl"
            [uploadFilePath]="uploadFilePath ? uploadFilePath : cmsContentModel.Data"
            [cmsEntity]="cmsContentModel"
            (fileState)="handleFileChanged($event)"></cms-preview-entity>
    </nb-card-footer>
  </nb-card>