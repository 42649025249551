import { NbDialogService } from '@nebular/theme';
import { NebularModule } from '@InfoSlips/nebular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OneTimePinComponent } from './components/one-time-pin/one-time-pin.component';
import { PreRegisterComponent } from './components/pre-register/pre-register.component';
import { SharedModule, AuthModule } from '@InfoSlips/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard  } from '@InfoSlips/shared';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { ViewComponent } from './containers/view/view.component';
import { ViewModalComponent } from './components/view-modal/view-modal.component';
import { DocumentActionsComponent } from './components/document-actions/document-actions.component';
import { SafeIframePipe } from './pipes/safe-iframe.pipe';
import { DataPromptsComponent } from './components/data-prompts/data-prompts.component';
import { DocumentSearchComponent } from './components/document-search/document-search.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { PukPromptComponent } from './components/puk-prompt/puk-prompt.component';

export const infoslipRoutes: Route[] = [
  {
    path: 'infoslip/:id/:sequence',
    pathMatch: 'full',
    component: ViewComponent,
    canActivate: [AuthGuard],
    data: {animation: 'infoslip'}
  },
  { path: 'link/:id/:sequence', pathMatch: 'full', component: ViewComponent, data: {animation: 'infoslip'} },
  { path: 'adhoc/:adhocId', pathMatch: 'full', component: ViewComponent, data: {animation: 'infoslip'} },
  { path: 'onetime/:id', pathMatch: 'full', component: ViewComponent, data: {animation: 'infoslip'} },
  {
    path: 'completepreregistration/:id/:sequence',
    pathMatch: 'full',
    component: ViewComponent,
    data: {animation: 'infoslip'}
  },
  {
    path: 'infoslip/:id/:sequence/:historyAgent',
    pathMatch: 'full',
    component: ViewComponent,
    data: {animation: 'infoslip'}
  }
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forChild(infoslipRoutes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AuthModule,
    NebularModule
  ],
  declarations: [
    ViewComponent,
    ViewModalComponent,
    SafeIframePipe,
    DocumentActionsComponent,
    DataPromptsComponent,
    PreRegisterComponent,
    DocumentSearchComponent,
    OneTimePinComponent,
    ErrorMessageComponent,
    PukPromptComponent
  ],
  exports: [SafeIframePipe, DataPromptsComponent, ViewModalComponent, DocumentActionsComponent],
  providers: [NbDialogService]
})
export class InfoslipModule {}
