import { Component, Input, SimpleChanges } from '@angular/core';
import { TemplateService } from '@InfoSlips/iiab-api';
import { MonacoEditorConfig  } from '../../../../../shared/src/lib/monacoConfig';
import { TemplateModel, TemplateSourceFile } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@InfoSlips/env';

@Component({
  selector: 'ifs-admin-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.scss']
})
export class PreviewTemplateComponent {

  @Input() template: TemplateModel;

  constructor(
    public templateService: TemplateService,
    public monacoConfig: MonacoEditorConfig,
    private toaster: ToastrService
  ) { }

  ext = 'razor'; // TODO: @nicon unhardmap
  nonTextSource:string|null=null;
  templateFiles: string | TemplateSourceFile[];
  razorModel:string;
  previewOutput:string;
  sampleDataModel = '{"field1": "value1"}';
  enabled = false;
  sampleDataEnabled = false;
  previewEnabled = false;

  ngOnChanges(changes: SimpleChanges){
   this.template = changes.template?.currentValue;
  }

  async getTemplateFiles(){
    this.templateService.getTemplateFiles(this.template.Id);
  }

  getTemplateFile(file){
    this.ext = file.icon;
    if(this.ext==='image'){
      this.nonTextSource = file.icon==='image'?this.templateService.getTemplateFileSource(this.template.Id, file.name):null;
      return;
    }

    this.templateService.getTemplateFile(this.template.Id, file.name).subscribe((res: any) => {
      this.razorModel = res;
      this.monacoConfig.editorOptions = { ... this.monacoConfig.editorOptions, language: file.ext };
      this.enabled = true;
    })
  }

  navigate(tab, ext:string){
    this.enabled=true;
    this.sampleDataEnabled=false;
    this.setMonacoLanguage(ext);
    this.previewEnabled=false;

    if(tab.tabTitle === 'Preview Output') {
      this.transform();
      this.enabled=false;
      this.previewEnabled=true;
    }
    else if(tab.tabTitle === 'Sample Data')
    {
      this.sampleDataEnabled=true;
      this.enabled=false;
      this.setMonacoLanguage('json');
    }
  }

  setMonacoLanguage(value){
    this.monacoConfig.editorOptions = { ... this.monacoConfig.editorOptions, language: value };
  }

  fileChange(event:Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.readFile(fileList[0]);
    }
  }  

  readFile(file:File): void{
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (e)=> {
      this.sampleDataModel = reader.result as string;
    };

    reader.onerror = ()=> {
      this.toaster.error('File reader error: ' + reader.error);
    };
  }

  transform(){
    this.toaster.success('Preview submitted');

    this.templateService.getRazorPreview(``, this.razorModel,this.sampleDataModel)
      .subscribe((res:any) => {
        if(res){
          res.includes('error')?
            this.toaster.warning('Preview has errors'):
            this.toaster.success('Preview processed successfully');

          this.toaster.success('Preview processed successfully');
          this.previewOutput = res;
        }
      },(e:Error)=>{
        this.toaster.error('Preview failed: ' + e.message);
      });     
  }

}
