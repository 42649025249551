import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

interface PageData {
  itemsPerPage: number,
  totalItems: number,
  hasNextPage: boolean,
  hasPreviousPage: boolean
}

@Component({
  selector: 'ifs-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input('size') size: string = "default";
  @Input('pageData') pageData: PageData;
  @Input('firstPageCursor') firstPage: PageData;
  @Input('isLoading') isLoading: boolean;
  @Output() pageChange: EventEmitter<string> = new EventEmitter<string>();
  
  totalPages: number = 1;

  private calcPage;
  get currentPage() {
    if (this.pageData.hasPreviousPage == false)
      return 1;
    else return this.calcPage
  }
  set currentPage(page: number) {
    this.calcPage = page;
  }

  constructor() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageData) {
      this.calculateTotalPages();
    }
  }

  ngOnInit(): void {
  }

  pageChanged(context: string){
    this.pageChange.emit(context)
  }

  private calculateTotalPages(): void {
    if (this.pageData && this.pageData.totalItems && this.pageData.itemsPerPage) {
      this.totalPages = Math.ceil(this.parseFormattedNumber(this.pageData.totalItems) / this.parseFormattedNumber(this.pageData.itemsPerPage));
    } else {
      this.totalPages = 0;
    }
  }

  private parseFormattedNumber(value: any): number {
    if (typeof value === 'string') {
      value = value.replace(/,/g, ''); // Remove thousand separators
    }
    return parseFloat(value);
  }
}
