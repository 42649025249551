import { Injectable } from '@angular/core';
import { StateMenuContext } from '@InfoSlips/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RunStateService {
  private runMenuSubject  = new BehaviorSubject<any[]>([]);
  public runMenu$: Observable<any[]> = this.runMenuSubject.asObservable();

  activeTab = 0;


  get RunMenu(): any[] {
    return this.runMenuSubject.getValue();
  }

  set RunMenu(context: any[]){    
    this.runMenuSubject.next(context);
  }

  
  getRunMenu(){
    return [
      {
        name: 'Menu',
        context: StateMenuContext.Menu,
        style: {
          icon: 'menu-outline',
          pack: 'eva'
        }
      },
      {
        name: 'Runs',
        context: StateMenuContext.Runs,
        style: {
          icon: 'run',
          pack: 'oakmoon-v2'
        }
      },
      {
        name: 'Run Menu',
        context: StateMenuContext.RunMenu,
        style: {
          icon: 'settings-2-outline',
          pack: 'eva'
        }
      }
    ]
  }

  setTab(id: number){
    this.activeTab = id;
  }
}
