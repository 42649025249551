<nb-form-field>
    <input [disabled]="!isNew" type="text" nbInput fullWidth [(ngModel)]="secureKey" (ngModelChange)="updatedKey.emit($event)" />
    <button style="margin-right: 56px;" *ngIf="!isNew" nbSuffix nbButton status="primary" outline (click)="openUpdateDialog(updateDialog)">
      Update
    </button>
</nb-form-field>

<!-- <div class="secure-key-table shadow">
    <div class="secure-key-item">
        <div class="key-descriptor">
            <nb-icon class="key-icon" pack="oakmoon" icon="secure-key"></nb-icon>
            <div class="content">
                <span class="title">{{ getType(immutedKey?.Type)?.value }}</span>
                <span class="sub-title">{{ immutedKey?.UserName }}</span>
            </div>
       </div>
       <div class="key-value">
            <div class="content">
                <span class="title">Password</span>
                <span class="sub-title">{{ immutedKey?.Password }}</span>
            </div>
       </div>
       <div class="actions">
        <button nbButton status="warning" (click)="deletePassword()">
            <nb-icon icon="trash-2-outline"></nb-icon>
        </button> 
        <button nbButton status="primary" outline (click)="openUpdateDialog(updateDialog)">
            Update
        </button> 
       </div>
    </div>
</div> -->

<ng-template #updateDialog let-data let-ref="dialogRef">
    <form #securityForm="ngForm" (ngSubmit)="savePassword(securityForm.value.password)">
        <nb-card status="primary" style="min-width: 500px;">
        <nb-card-header>Update Password</nb-card-header>
        <nb-card-body>
            <input fullWidth type="text" nbInput placeholder="New Password" name="password" ngModel #formModel="ngModel">
        </nb-card-body>
        <nb-card-footer class="dialog-footer">
            <button nbButton (click)="ref.close()">Close</button>
            <button status="primary" nbButton type="submit">Save</button>
        </nb-card-footer>
        </nb-card>
    </form>
</ng-template>