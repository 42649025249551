import { NbThemeService } from '@nebular/theme';
import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import * as models from '@InfoSlips/models';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RunModel } from '@InfoSlips/models';

@Component({
  selector: 'ifs-run-options',
  templateUrl: './run-options.component.html',
  styleUrls: ['./run-options.component.scss']
})
export class RunOptionsComponent implements OnInit {

  @Output() selectedRecipientChange: EventEmitter<string> = new EventEmitter();
  @Output() closeRunOptions: EventEmitter<string> = new EventEmitter<string>();
  @Input() context: string;
  @Input() selectedRunService: SelectedRunService;
  @ViewChild('autoInput') input;

  selectedRun: RunModel;
  runRecipients: any;
  selectedTab;
  smsText: string;
  smsPreview: string;
  selectedRecipientId: string;
  theme: string;
  eventsSubject: Subject<void> = new Subject<void>();
  options: any;
  filteredOptions$: Observable<string[]>;

  tabs = ['Razor','SMS', 'Email'];

  

  constructor(
    private themeService: NbThemeService,
  ) { 
  }

  ngOnInit(): void {

    this.runRecipients = this.selectedRunService.filteredRunRecipients$
      .subscribe(res => {
        this.options = res;
        this.filteredOptions$ = of(this.options);
      });
    this.selectedRunService.selectedRun$.subscribe(run=>this.selectedRun=run);
      
    this.selectedTab = 'Email';
    this.theme = this.themeService.currentTheme;
  }

  onChangeTab(event){
    this.selectedTab = event.tabTitle;
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));;
  }

  getFilteredOptions(value: string): Observable<string[]> {
    const FilterObject = {
      or: [
        { externalId: { like: value }},
        { name: { like:  value }}
      ]
    }
    this.selectedRunService.filterRunRecipients(FilterObject);

    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  onSelectionChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
    if($event){
      this.getFilteredOptions($event).subscribe((res: any) => {
        this.selectedRecipientId = res[0].id;
      });
    }
  }

  toggleTheme(){
    this.theme === 'light' ? this.theme = 'dark' :  this.theme = 'light';
    
  }

  close(){
    this.closeRunOptions.emit();
  }
  emitEventToChild() {
    this.eventsSubject.next();
  }
}
