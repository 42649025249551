import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerLookupComponent, RunTemplateLookupComponent } from '@InfoSlips/controls';
import { EnvironmentService } from '@InfoSlips/env';
import { AuthService } from '@InfoSlips/iiab-auth';
import { CmsCreateUpdateContentModel, CmsCrudModel, CmsEntityScope, IiabIdName } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { SelectedEntityService } from '../../services/selected-entity.service';
import { CmsService } from '../../services/cms.service';
import { NbDialogService } from '@nebular/theme';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'cms-crud',
  templateUrl: './cms-crud.component.html',
  styleUrls: ['./cms-crud.component.scss']
})
export class CmsCrudComponent implements OnInit {

  @ViewChildren(CustomerLookupComponent) customerLookups: QueryList<CustomerLookupComponent>;
  @ViewChildren(RunTemplateLookupComponent) runTemplateLookups: QueryList<RunTemplateLookupComponent>;

  private selectedCustomer: IiabIdName;
  private selectedRunTemplate: IiabIdName;
  private selectedCustomerGroup: IiabIdName;

  dialogRef;
  navigatorEvents:any;
  isNew=true;
  expand:true;
  selectedEntity:CmsCrudModel;
  activeTab=0;
  selectedEntityId:string|null;
  cmsUploadFile = null;
  invalidField: string;
  tags: any;
  entityScope: string;

  entityReferenceCount: number;

  constructor(
    private selectedEntityService: SelectedEntityService,
    private route: ActivatedRoute, 
    private dialogService: NbDialogService,
    private router: Router,
    private toastr: ToastrService,
    private cmsService: CmsService,
    public authService: AuthService,
    public environment: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.selectedEntityService.selectedEntity$.subscribe(entity=>{
      
      if(entity==null)
        return;

      this.activeTab = 0;
      this.selectedEntity=entity;
      this.selectedEntityService.buildMenu(entity);
      this.entityScope = this.selectedEntityService.getEntityScope(entity);

      this.tags = new Set(this.selectedEntity.Tags);
      if (entity.RunTemplate) {
        const runTemplateReference = { id: entity.RunTemplate.Id, name: entity.RunTemplate.Name };
        this.selectedRunTemplate = runTemplateReference;
        this.runTemplateLookups?.forEach(x => x.onItemSelected(runTemplateReference));
        const customerReference = { id: entity.Customer.Id, name: entity.Customer.Name };
        this.selectedCustomer = customerReference;
        return;
      }
      if (entity.Customer) {
        const customerReference = { id: entity.Customer.Id, name: entity.Customer.Name };
        this.selectedCustomer = customerReference;
        this.customerLookups?.forEach(x => x.onItemSelected(customerReference));
        this.runTemplateLookups?.forEach(x => x.onCustomerSelected(customerReference));
      }
      if (entity.CustomerGroup) {
        const customerGroupReference = { id: entity.CustomerGroup.Id, name: entity.CustomerGroup.Name };
        this.selectedCustomerGroup = customerGroupReference;
      }
    });

    this.selectedEntityService.linkedRunTemplates$.subscribe(linked=>{
      this.entityReferenceCount = linked?.length;
    });

    const entityId = this.route.snapshot?.params?.id;
    if(!entityId)
      return;

    this.selectedEntityId=entityId;
    this.selectedEntityService.setEntity(entityId); 
    this.isNew=false;
  }

  setTab(index:number){
    this.activeTab=index;
  }

  update() {
    if (!this.setCmsEntity())
      return;

    if (this.selectedEntityService.entityType != 'content') delete this.selectedEntity.Data;
    
    this.selectedEntityService.entityType === 'content' ? 
      this.cmsService.updateCmsContent(this.selectedEntity) :
      this.cmsService.cmsFileHandler(this.selectedEntity, this.cmsUploadFile, true);

    this.selectedEntityService.setEntity(this.selectedEntityId);
      
    this.invalidField = '';
  }

  open(ref){
    this.dialogRef = this.dialogService.open(ref, {});
  }

  delete(){
    this.toastr.info("Entity scheduled for deletion!");
    this.dialogRef.close();
    this.cmsService.deleteCmsEntity(this.selectedEntity.Id);
    this.router.navigateByUrl('/cms');
  }

  handleUploadFile(event: any){
    this.setFileName(event);
  }

  entityCrudAllowed() {
    if (this.selectedEntity.Scope == CmsEntityScope.Public && !this.authService.isSystemAdmin)
      return false;

    return true;
  }

  //#region Private
  private setCmsEntity(): boolean {
    if (!this.validateEntity)
      return false;

    // this.selectedEntity.Tags = Array.from(this.tags);
    if (this.selectedEntity.Name.includes(' ')) {
      this.selectedEntity.Name = this.selectedEntity.Name.toLowerCase().replace(/ /g, '_');
    }

    this.selectedEntity.RunTemplateId = this.selectedRunTemplate?.id;
    this.selectedEntity.CustomerId = this.selectedCustomer?.id;
    this.selectedEntity.CustomerGroupId = this.selectedCustomerGroup?.id;

    return true;
  }

  private validateEntity(entity: CmsCreateUpdateContentModel): boolean {

    if (entity.Name == undefined || entity.Name == '') {
      this.invalidField = 'Please Enter a unique name for the CMS Entity';
      return false;
    }
    if (entity?.Data == null || entity?.Data == '') {
      this.invalidField = 'Please Enter some content for the CMS Entity';
      return false;
    }
    if (entity.Display == undefined || entity.Display == '') {
      this.invalidField = 'Please Enter a Display Name for the Cms Entity';
      return false;
    }
    if (entity.CmsContentType == undefined) {
      this.invalidField = 'Please select the type of your CMS Entity';
      return false;
    }

    return true;
  }

  private setFileName(e) {
    this.cmsUploadFile = e;
  }
}
