/* eslint-disable max-len */
import { Component, OnInit, ViewChild } from '@angular/core';
import { RunsService, RunTemplateService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'cms-entity-link',
  templateUrl: './entity-link.component.html',
  styleUrls: ['./entity-link.component.scss']
})
export class EntityLinkComponent implements OnInit {

  @ViewChild('templateAutoInput') templateAutoInput;
  @ViewChild('customerAuto') customerAuto;
  @ViewChild('autoInput') input;

  constructor(
    private runService: RunsService,
    private cmsService: CmsService,
    private runTemplateService: RunTemplateService
  ) { }

  showDetails$ = this.cmsService.showDetails$;

  selectedCustomer;
  selectedTemplate;
  customerOptions$: Observable<string[]>;
  filteredOptions$: Observable<IiabIdName[]>;
  runTemplates$: Observable<IiabIdName[]>;
  FilterObject = {};
  showItems = false;

  customerId = null;
  selectedRunTemplate: string;
  selectedRunTemplateId: string;

  ngOnInit(): void {
    this.showItems = false;
    this.runTemplates$ = this.runTemplateService.runRunTemplates.Items$().pipe(exhaustMap((res) => of(res)));
  }

  onChange() {
    const wordSearch = this.input.nativeElement.value;
    this.runTemplates$ = of([{ id: 'Loading..', name: 'Loading..'}]);

    const FilterObject = wordSearch.length === 24 ? 
    { id: 
      { 
        eq: this.input.nativeElement.value 
      },
      customer: {id: { eq: this.customerId}}
    } 
    :
    { name: 
      { 
        like: this.input.nativeElement.value 
      },
      customer: {id: { eq: this.customerId}}
    }

    setTimeout(() => {
      if (wordSearch == this.input.nativeElement.value) {
          this.runTemplateService.runRunTemplates.loadPagedItems(this.input.nativeElement.value ? FilterObject : { customer: {id: { eq: this.customerId}}});
          this.runTemplates$ = this.runTemplateService.runRunTemplates.Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 600);    
  }

  onCustomerChange(context: any) {
    const filterValue = context?.value && context?.value.length > 0 ? context.value : null

    if(context.name === 'runTemplate'){
      filterValue ? this.FilterObject['runTemplate'] = {
       name: {
          like: filterValue
       }
      } : delete this.FilterObject['runTemplate'];
      this.runService.runTemplates.loadPagedItems(filterValue ? {
        name: {
           like: filterValue
        }
       } : null);
      this.filteredOptions$ = this.runService.runTemplates.Items$();
    }
    else {
      filterValue ? this.FilterObject['customer'] = {
        name: {
          like: filterValue
        }
      } : delete this.FilterObject['customer'];
      this.runService.runCustomers.loadPagedItems(filterValue ? {
        name: {
           like: filterValue
        }
       } : null);
      this.customerOptions$ = this.runService.runCustomers.Items$();

      this.customerOptions$.subscribe((customers: any) => {
        this.customerId = customers.filter((customer: any) => customer.name === filterValue)[0]?.id;
      })
    }
    this.cmsService.cmsEntities.loadPagedItems(Object.keys(this.FilterObject).length !== 0 ? this.FilterObject : null);
    this.loadRunTemplates();
  }

  onSelectionChange($event) {
    this.runTemplates$.subscribe((res: any) => {
       res.find(item => {
         if(item.name === this.input.nativeElement.value){
           this.selectedRunTemplateId = item.id;
           this.selectedRunTemplate = item.name;
           this.runTemplates$ = of([item]);
        }
      })
    })
  }

  loadRunTemplates(){
    this.runTemplateService.runRunTemplates.loadPagedItems(this.customerId ? { customer: {id: { eq: this.customerId}}} : undefined);
  }

}
