import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { RunsService } from '@InfoSlips/iiab-api';
import { IiabIdName, RunSummary } from '@InfoSlips/models';
import { IiabLookupBaseComponent } from '../base/iiab-look-up-base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ifs-run-lookup',
  templateUrl: './run-lookup.component.html',
  styleUrls: ['./run-lookup.component.css']
})
export class RunLookupComponent extends IiabLookupBaseComponent<IiabIdName> implements AfterViewInit {

  @Input() showLabel = true;
  @Input() showClear = false;
  @ViewChild('runAutoInput')
  runsAutoInput!: ElementRef<HTMLInputElement>;
  ngModel: IiabIdName | undefined;

  constructor(runsServices: RunsService) {
    super(runsServices.runs); 
  }

  ngAfterViewInit(): void {
    if(this.ngModel){
      this.setSelectedItem(this.ngModel);
    }

    this.addInputElement(this.runsAutoInput.nativeElement);
    setTimeout(() => {
      this.disabled = false;
    }, 500); 
  }

  onRunSelected(event: IiabIdName): void {
    this.onItemSelected(event);
  }

  onCustomerSelected(selectedCustomer: IiabIdName): void {

    if (selectedCustomer && selectedCustomer.id === undefined)
      return;

    const additionalWhere = selectedCustomer ?
      { customer: { id: { eq: selectedCustomer.id } } } :
      null;

    this.setWhere(additionalWhere);
  }

  onRunTemplateSelected(selectedRunTemplate: IiabIdName): void {
   
    if (selectedRunTemplate && selectedRunTemplate.id === undefined)
      return;

    const additionalWhere = selectedRunTemplate ?
      { runTemplate: { id: { eq: selectedRunTemplate.id } } } :
      null;

    this.setWhere(additionalWhere);
  }

}
