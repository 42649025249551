<nb-card *ngIf="recipientData" class="card mt-0 shadow">
  <nb-card-header class="card-header">
    <nb-badge *ngIf="recipientData?.ifsFileData?.length > 1" text="Selected Sequence: {{selectedSequence}}" status="success" position="top right"></nb-badge>
    <div class="row">
      <div class="col-md-12">
        <nb-icon *ngIf="recipientType && selectedRun?.TrialOptions.RecipientSelection === 4 && allowActions" class="pull-right close"
          (click)="removeFromTrialList(recipientData?.id)" pack="oakmoon" icon="remove"></nb-icon>
        <nb-icon *ngIf="(!recipientType && recipientData?.name) && selectedRun?.IsTrial && allowActions" class="pull-right close"
          (click)="removeFromRun(confirmDialog, recipientData?.id)" pack="oakmoon" icon="bin"></nb-icon>
        <nb-icon *ngIf="authService.isSystemAdmin && !selectedRun?.IsTrial && allowActions"
          class="pull-right close" status="danger" pack="oakmoon" icon="bin"
          (click)="expireFromRun(confirmDialog, recipientData?.id)">Expire
        </nb-icon>
        <p class="card-title">
          {{recipientData?.name }}
          {{recipientData?.runRecipient?.name}}
        </p>
        <div class="recipient-detail" [ngClass]="{'smaller-detail':displayAdditionalRunInfo == true}">
          <p class="card-sub-title">
            {{ recipientData?.externalId }}
            {{recipientData?.runRecipient?.externalId}}
          </p>
          <p class="card-sub-title">
            {{ recipientData?.email }}
            <span *ngIf="recipientData?.channel">{{ recipientData?.channel + ": " + recipientData?.recipient }}</span>
          </p>
          <p class="card-sub-title">
            {{ recipientData?.mobile }}
            <span *ngIf="recipientData?.reason">Reason: {{ recipientData?.reason }}</span>
          </p>
        </div>
        <div class="recipient-detail additional-run-info" *ngIf="displayAdditionalRunInfo == true">
          <p class="card-sub-title">
            {{ recipientData?.run?.name }}
          </p>
          <p class="card-sub-title">
            {{ recipientData?.run?.runDate }}
          </p>
          <p class="card-sub-title">
            {{ recipientData?.run?.bilingDateDescription }}
          </p>
        </div>
        <div class="dist-channels">
          <ng-container *ngFor="let output of distinctChannels$ | async">
            <span class="circle" [ngClass]="{'delivered': output.isDelivered == true, 'failure': output.failureType != 'NONE'}" *ngIf="output.channel === 'EMAIL'" [nbPopover]="output.resultMessage != null ? output.resultMessage : 'This recipient has Email send options enabled.'"
              nbPopoverTrigger="hover" nbPopoverPlacement="top">Email</span>

            <span class="circle" [ngClass]="{'delivered': output.isDelivered == true, 'failure': output.failureType != 'NONE'}" *ngIf="output.channel === 'EXPORT'"
              [nbPopover]="output.resultMessage != null ? output.resultMessage : 'This recipient has Export PDF send options enabled.'" nbPopoverTrigger="hover"
              nbPopoverPlacement="top">Export</span>
            
            <span class="circle" [ngClass]="{'delivered': output.isDelivered == true, 'failure': output.failureType != 'NONE'}" *ngIf="output.channel === 'SMS'" [nbPopover]="output.resultMessage != null ? output.resultMessage : 'This recipient has SMS send options enabled.'"
              nbPopoverTrigger="hover" nbPopoverPlacement="top">SMS</span>
          </ng-container>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-footer class="row card-footer" *ngIf="(recipientType || !selectedRun?.IsTrial) && allowActions">
    <div class="col-md-12 recipient-options">
      <div  class="recipient-option" [nbPopover]="information" nbPopoverTrigger="click" nbPopoverPlacement="top">
        <nb-icon pack="eva" icon="copy-outline"></nb-icon>
      </div>
      <div class="recipient-option" [nbPopover]="downloadItems" nbPopoverTrigger="click" nbPopoverPlacement="top">
        <nb-icon pack="eva" icon="download-outline"></nb-icon>
      </div>
      <ng-template #downloadItems>
        <button class="mb-10" status="primary" size="small" nbButton fullWidth (click)="ifsFileData(recipientData, selectedSequence)">
          <nb-icon pack="oakmoon" icon="file-code-download-1"></nb-icon> Data
        </button>
        <button
          *ngIf="hasRunRecipientSpecificFiles | async" (click)="specificFileData(recipientData)"
          class="mb-10" status="primary" size="small" nbButton fullWidth
          nbPopover="Download Run Recipient Specific Files" nbPopoverTrigger="hover">
          <nb-icon pack="oakmoon" icon="file-code-download-1"></nb-icon>Specific Data
        </button>
        <button class="mb-10" status="primary" size="small" nbButton fullWidth *ngIf="recipientData?.isCompiled"
          (click)="downloadInfoSlip(recipientData?.id, selectedSequence)">
          <nb-icon pack="oakmoon" icon="common-file-download"></nb-icon> IFS
        </button>
        <button class="mb-10" status="primary" size="small" nbButton fullWidth *ngIf="recipientData?.isExported"
          (click)="downloadExportedPdf(recipientData, selectedSequence)">
          <nb-icon pack="oakmoon" icon="office-file-pdf"></nb-icon> PDF
        </button>
      </ng-template>
      <div class="recipient-option" [nbPopover]="previewItems" nbPopoverTrigger="click" nbPopoverPlacement="top">
        <nb-icon pack="eva" icon="eye-outline"></nb-icon>
      </div>
      <ng-template #previewItems>
        <button class="mb-10" status="primary" size="small" nbButton fullWidth *ngIf="recipientConfig?.email"
          (click)="preview(previewDialog, 'email')">
          <nb-icon pack="oakmoon" icon="email-action-search-1"></nb-icon> Email
        </button>
        <button class="mb-10" status="primary" size="small" nbButton fullWidth *ngIf="recipientConfig?.sms"
          (click)="preview(previewDialog,'sms')">
          <nb-icon pack="oakmoon" icon="messages-bubble-text-1"></nb-icon> SMS
        </button>
        <button class="mb-10" status="primary" size="small" nbButton fullWidth *ngIf="recipientData?.isCompiled"
          (click)="preview(previewDialog, 'ifs')">
          <nb-icon pack="oakmoon" icon="data-file-bars-search"></nb-icon> IFS
        </button>
      </ng-template>
      <div (click)="getIfsInfo(ifsInfoDialog, recipientData?.id)" class="recipient-option">
        <nb-icon pack="eva" icon="info-outline"></nb-icon>
      </div>
      <div *ngIf="recipientData?.ifsFileData?.length > 1" (click)="showSequence = !showSequence" class="recipient-option" [ngClass]="showSequence ? 'focus': ''">
        <nb-icon pack="eva" [icon]="showSequence ? 'arrowhead-up-outline' : 'arrowhead-down-outline'"></nb-icon>
      </div>
      <div *ngIf="!selectedRun?.IsTrial" (click)="showActions(actionDialog)" class="recipient-option">
        <nb-icon pack="eva" icon="more-vertical"></nb-icon>
      </div>
    </div>

    <div *ngIf="showSequence" class="col-md-12 sequence-form">
      <p class="sequence-title">Sequences Available: {{ recipientData?.ifsFileData?.length ?? 0 }}</p>
      <input #sequenceInput
      nbInput
      fullWidth
      type="text"
      (input)="onChange()"
      placeholder="Enter sequence name and/or number."
      [nbAutocomplete]="auto" />

    <nb-autocomplete #auto (selectedChange)="onSequenceSelect($event)">

      <nb-option *ngFor="let option of sequenceOptions$ | async" [value]="option">
        {{ option.sequence }} - {{ option.label }}
      </nb-option>

    </nb-autocomplete>
    </div>
  </nb-card-footer>
</nb-card>

<ng-template dialogClass="preview-dialog" #previewDialog let-data let-ref="dialogRef">
  <ifs-preview [context]="previewContext" [selectedRunService]="selectedRunService" [recipientId]="recipientData?.id" [sequence]="selectedSequence" (closePreview)="closePreview()">
  </ifs-preview>
</ng-template>

<ng-template #confirmDialog let-data let-ref="dialogRef">
  <nb-card class="confirm-dialog">
    <nb-card-header>Please Confirm</nb-card-header>
    <nb-card-body>
      <p *ngIf="data.type === 'remove'">Would you like to remove {{data.recipId}} from the run?</p>
      <p *ngIf="data.type === 'expire'">Would you like to expire {{data.recipId}} from the run? <br /> If so please provide a reason below.</p>
      <input #expirationReason nbInput fullWidth type="text" [style.display]="data.type === 'expire' ? 'block' : 'none'">
    </nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="ref.close()" status="basic">Cancel</button>
      <button *ngIf="data.type === 'remove'" nbButton (click)="confirmRemoveRecipient()" outline status="primary">Remove Recipient</button>
      <button *ngIf="data.type === 'expire'" [disabled]="!expirationReason.value" nbButton (click)="confirmExpireRecipient(expirationReason.value)" outline status="danger" class="pull-right">Expire Recipient</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #ifsInfoDialog let-data let-ref="ifsInfoRef">
  <nb-card class="ifsdetail-dialog">
    <nb-card-body>

      <label class="search-label" for="search">Search:</label>
      <input nbInput [nbFilterInput]="dataSource" id="search" class="search-input">

      <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">

        <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
        <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

        <ng-container [nbTreeGridColumnDef]="customColumn">
          <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(customColumn)" *nbTreeGridHeaderCellDef>
            {{customColumn}}
          </th>
          <td nbTreeGridCell *nbTreeGridCellDef="let row">
            <nb-tree-grid-row-toggle [expanded]="row.expanded" *ngIf="row.data.kind === 'IFS'"></nb-tree-grid-row-toggle>
            {{row.data[customColumn]}}
          </td>
        </ng-container>

        <ng-container *ngFor="let column of defaultColumns; let index = index" [nbTreeGridColumnDef]="column"
          [showOn]="getShowOn(index)">
          <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
            {{column}}
          </th>
          <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column] || '-'}}</td>
        </ng-container>

      </table>

    </nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="ifsInfoRef.close()" status="basic">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #information>
  <ifs-clipboard [items]="[
  {
    name: 'Id',
    value: recipientData?.id
  },
  {
    name: 'Online Id',
    value: recipientData?.onlineId
  }]">
  </ifs-clipboard>
</ng-template>

<ng-template dialogClass="actions-dialog" #actionDialog let-data let-ref="dialogRef">
  <nb-card class="ifsdetail-dialog">
    <nb-card-header>
      <div>
        <h3>Delivery Report</h3>
        <!-- todo Add this back in and fetch from recipientData query once non nullable issue has been fixed-->
        <!-- <p *ngIf="runRecipient?.LastException" style="color:red">{{runRecipient.LastException}}</p> -->
      </div>
    </nb-card-header>
    <nb-card-body
      infiniteScroll 
      [infiniteScrollDistance]="1" 
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false" >
      <div *ngIf="outputChannels?.length > 0">
        <div *ngIf="getFilteredChannels('EMAIL').length > 0">
          <ifs-outputchannel-summary 
            Channel="Email" 
            [RunRecipientId]="recipientData.id"
            [RunTemplateId]="recipientData.runTemplate.id"
            [RunRecipientOutputChannel]="getFilteredChannels('EMAIL')"
            [AllowForward]="true"
            [StaticRecipientAttachments]="recipientData.staticAttachments"
            [StaticRunAttachments]="selectedRun?.Attachments">
          </ifs-outputchannel-summary>
        </div>

        <div *ngIf="getFilteredChannels('SMS').length > 0">
          <ifs-outputchannel-summary 
            Channel="SMS" 
            [RunRecipientId]="recipientData.id"
            [RunTemplateId]="recipientData.runTemplate.id"
            [RunRecipientOutputChannel]="getFilteredChannels('SMS')"
            [AllowForward]="true">
          </ifs-outputchannel-summary>
        </div>

        <div *ngIf="getFilteredChannels('EXPORT').length > 0">
          <ifs-outputchannel-summary 
            Channel="Export" 
            [RunRecipientId]="recipientData.id"
            [RunTemplateId]="recipientData.runTemplate.id"
            [RunRecipientOutputChannel]="getFilteredChannels('EXPORT')">
          </ifs-outputchannel-summary>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="dialogRef.close()" status="basic">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>