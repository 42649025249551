import { Component, OnInit } from '@angular/core';
import { CmsService } from './../../services/cms.service';

@Component({
  selector: 'cms-shell',
  styleUrls: ['./cms-shell.component.scss'],
  template: `
    <div class="bg-gray">
      <ifs-activity-bar-shared context="cms">
        <router-outlet></router-outlet>
      </ifs-activity-bar-shared>
    <div>
  `
})
export class DashboardShellComponent implements OnInit {

  constructor(
    private cmsService: CmsService,
  ) {}

  ngOnInit() {
    this.cmsService.cmsEntities.Items$().subscribe()
    this.cmsService.selectedEntity$.subscribe();
  }
}