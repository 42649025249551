<nb-card *ngIf="(processor$ | async) as processor">
    <nb-card-header *ngIf="processorId">Update {{ processor.name }}</nb-card-header>
    <nb-card-header *ngIf="!processorId">Create {{ context.charAt(0).toUpperCase() + context.slice(1)}}Processor</nb-card-header>
    
    <nb-card-body class="padded">
        <label >Name <br/>
            <input class="halfWidth" type="text" nbInput [placeholder]="'Enter ' +  context.charAt(0).toUpperCase() + context.slice(1) + 'Processor name' " [(ngModel)]="processor.name">
        </label>

        <hr/>
        <h3>Upload Files</h3>
        <p>This is where you upload data, either remove uploaded data or add new data if nothing has been uploaded yet <br/>
            <small>Configured files for this run - * required</small>
        </p>
        <div class="upload-box row" (drop)="onDrop($event, processor, context)" (dragover)="onDragOver($event)">
            <div class="col-md-8">
                <p>
                    Drag files here !
                </p>
            </div>
            <div class="col-md-4">
                <div class="file-actions flex-right">
                    <span>
                        <input id="file-upload" type="file" (change)="onDrop($event, processor, context)" />
                        <label class="choose-file" nbButton outline for="file-upload">
                            Choose File
                        </label>
                    </span>
                </div>
            </div>
        </div>
        <hr/>

        <h3 *ngIf="processor.files">Uploaded Files</h3>
        <table width="100%">
            <thead>
              <tr>
                <th>File Name</th>
                <th>File Size</th>
                <th>Upload Progress</th>
                <th class="text-center">Actions</th>
                <th class="text-center">Download</th>
                <!-- <th class="text-center">Preview</th> -->
                <th class="text-center">Info</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of processor.files">
                <td>{{ file.fileName }}</td>
                <td>{{ processorService.calculateSize(file.size) }}</td>
                <td><nb-progress-bar [value]="file.progress ? file.progress : 100" [status]="file.queued ? 'warning' : 'primary'">{{file.message ? file.message : '100%'}}</nb-progress-bar></td>
                <td class="text-center">
                    <nb-icon icon="bin" pack="oakmoon" status="danger" status="danger" nbTooltipStatus="danger" nbTooltip="Delete the file." (click)="removeFile(context, processor, file.fileName, file.queued)"></nb-icon>
                </td>
                <td class="text-center" (click)="processorService.download(context, this.processorId, file.fileName)">
                    <nb-icon pack="eva" icon="download" status="basic"></nb-icon>
                </td>
                <!-- <td>Preview</td> -->
                <td class="text-center">
                    <nb-icon pack="eva" icon="info" status="info" nbTooltipStatus="info" nbTooltipTrigger="click" [nbTooltip]="
                    'Uploaded By: ' + file.uploadedBy + '\n' +
                    'Uploaded: ' + file.uploaded"
                    ></nb-icon>
                </td>
              </tr>
            </tbody>
        </table>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="basic" routerLink="/">
            Cancel
        </button>
        <button nbButton status="primary" (click)="saveCreate(processor, context)">
            {{ processorId ? 'Save' : 'Create'}}
        </button>
    </nb-card-footer>
  </nb-card>