import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RunsService } from '@InfoSlips/iiab-api';
import { AuthService } from '@InfoSlips/iiab-auth';
import { CreateRunModel, LoggedInModel, IiabIdName, RunTemplateSummary } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { WizardService } from '../../services/wizard.service';

interface RunPeriod{
  Type: number,
  DateTime: string,
  Description: string
};

@Component({
  selector: 'ifs-wizard-setup-form',
  templateUrl: './setup-form.component.html',
  styleUrls: ['./setup-form.component.scss'],
  providers: [TitleCasePipe]
})
export class SetupFormComponent implements OnInit {
  @ViewChild('autoInput') input;
  
  constructor(
    private fb: FormBuilder, 
    public auth: AuthService,
    private runsService: RunsService,
    private toastr: ToastrService,
    private router: Router, 
    private wizardService: WizardService
  ) { }

  runDescription: string;
  runPeriodList: RunPeriod[];
  billingDate;
  user: LoggedInModel = this.auth.loggedInUser;
  run: CreateRunModel;
  notifyRunCreator: boolean;

  createRunForm = this.fb.group({
    RunTemplate: ['', Validators.required],
    BillingDate: ['', Validators.required],
    Name: ['', Validators.required],
    ReuseData: [false],
    ReceiveNotifications: ['', Validators.required]
  });

  selectedRunTemplate: IiabIdName = null;
  FilterObject = {};

  ngOnInit(): void {
    this.wizardService.getRunPeriodList().subscribe((runPeriodOptions: RunPeriod[]) => {
      this.billingDate = runPeriodOptions.find((month: any) => new Date(Date.parse(month.DateTime)).getMonth() === new Date().getMonth());
      this.runPeriodList = runPeriodOptions;
    });
  }
  
  toggleNotifications(checked: boolean) {
    this.notifyRunCreator = checked;
  }

  createRun(){
    this.run = {
      TrialOptions: {
        SendToUser: [this.user.email], 
        EmailGroups: [], 
        EmailGroupEmails: [],
        IsAutoTrialEnabled: false,
        RecipientSelection: 0,
        UserKeyOption: 2,
        FilterOutputType: 0,
        RecipientList: []
      },
      RunTemplateId: this.selectedRunTemplate.id,
      IsTrial: true,
      IsPukLess: true,
      // BilingDate: this.billingDate, 
      Name: this.runDescription
    };
    
    this.runsService.createRun(this.run).subscribe((data: any) => {
      if (data) {
        this.toastr.success(
          'Run Created!',
          'Navigating to run.'
        );

        this.runsService.runSummaries.loadPagedItems();
        this.router.navigateByUrl(`run/${data.Id}`);
        this.wizardService.setState(0);
        this.wizardService.toggleWizard(false);
      }
    });
  }

  updateDescription($event: any){
    this.runDescription = this.runDescription.split("- ")[0] + `- ${$event.Description}`;
  }
  
  cancel(){  
    this.wizardService.setState(0);
  }

  onRunTemplateSelected(event:IiabIdName):void{
    if(!event || !event?.id){
      return;
    }
    
    if(this.selectedRunTemplate === undefined && !event)
      return;

    this.runDescription = `${event.name} - ${this.billingDate.Description}`;
    this.selectedRunTemplate = event;
  }

}
