import { Injectable } from '@angular/core';
import { ApiBaseService } from '../services/base/api-base.service';
import { ObservableWrapper } from '../services/base/observable-wrapper';
import { PageGraphQLData } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


export interface Miscellaneous {
  name:  string;
  id:    string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  filteredItems$: Observable<Miscellaneous[]>;

  constructor(
    private apiBaseService: ApiBaseService,
  ) {}

  private _pageMiscEnumQuery(filter:any) {
    return `query MiscEnums($where: EnumModelTypeFilterInput = ${filter}) {
      miscEnums(first: 500, where: $where){
        edges {
          node {
            name
            id
            value
          }
        }
      }
    }`;
  }

  private _fetchMiscEnumData(rawData: any): PageGraphQLData<Miscellaneous> {
    try{
      return rawData.data.miscEnums as PageGraphQLData<Miscellaneous>;
    }
    catch(e)
    {
      console.log("miscEnumData That FAIlED", rawData);
      return null;
    }
  }

  miscellaneousContext(context: string, includeFilter?: (item:Miscellaneous)=>boolean) : Observable<Miscellaneous[]>{
    const filter = {
      "name": {
          "eq": context.toLowerCase()
      }
    }

    const miscEnums = new ObservableWrapper<Miscellaneous>(
      this.apiBaseService,
      this._pageMiscEnumQuery(filter),
      this._fetchMiscEnumData,
    false);

    miscEnums.loadPagedItems(filter);
    
    if(includeFilter==null)
      return miscEnums.Items$();

    return miscEnums.Items$()
      .pipe(map(items => items.filter(includeFilter)))

  }
}
