<cms-grid-item [cmsEntity]="selectedEntity"></cms-grid-item>

<div class="references" style="display: flex;">

    <ng-container 
      [ngTemplateOutlet]="linkedItem"
      [ngTemplateOutletContext]="{$implicit: {items:linkedRunTemplates, isRun:false}}"></ng-container>

    <ng-container 
      [ngTemplateOutlet]="linkedItem"
      [ngTemplateOutletContext]="{$implicit: {items:linkedRuns, isRun:true}}"></ng-container>
</div>

<ng-template #linkedItem let-data>
  <fieldset
    infiniteScroll 
    [infiniteScrollDistance]="1" 
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false" 
    (scrolled)="onScroll(data.isRun? 'Runs': 'RunTemplates')"
    *ngIf="data.items.length !== 0" style="width: 50%;">
    <legend>{{data.isRun? 'Runs': 'Run Template'}}</legend>
    <div *ngFor="let linked of data.items">
      <cms-grid-item [cmsEntity]="linked" type="reference" [routerLinkUrl]="(data.isRun?'/run/':'/admin/runtemplates/') + linked.referenceItem.id"></cms-grid-item>
    </div>    
  </fieldset>
</ng-template>
