import { Component, Input } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import { RunRecipientSummary } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ifs-trial-details',
  templateUrl: './trial-details.component.html',
  styleUrls: ['./trial-details.component.scss']
})
export class TrialDetailsComponent {

  @Input() selectedRunService: SelectedRunService;
  
  runRecipientSubject = new BehaviorSubject<RunRecipientSummary[]>([]);
  runRecipients$ = this.runRecipientSubject.asObservable();
  loading = false;

  setRecipients(event: RunRecipientSummary[]){
    this.runRecipientSubject.next(event);
  }

  setRecipientLoading(event: boolean) {
    this.loading = event;
  }
}
