<div class="pagination-container">
  <button 
    nbButton status="primary"
    [disabled]="!pageData.hasPreviousPage || isLoading" (click)="pageChanged('first');currentPage = 1"><nb-icon [ngClass]="{'disabled': !pageData.hasPreviousPage || isLoading}" pack="oakmoon" icon="pagination-arrow-first"></nb-icon></button>
  <button 
    nbButton status="primary"
    [disabled]="!pageData.hasPreviousPage || isLoading" (click)="pageChanged('previous');currentPage = currentPage - 1"><nb-icon [ngClass]="{'disabled': !pageData.hasPreviousPage || isLoading}" pack="oakmoon" icon="pagination-arrow-back"></nb-icon></button>
  <span *ngIf="size === 'default'">Page: {{ currentPage }} of {{ totalPages }}</span>
  <span *ngIf="size === 'minimal'">{{ (currentPage * pageData.itemsPerPage) - (pageData.itemsPerPage - 1) }} - {{ currentPage * pageData.itemsPerPage }} </span>
  <button 
    nbButton status="primary"
    [disabled]="!pageData.hasNextPage || isLoading" (click)="pageChanged('next');currentPage = currentPage + 1"><nb-icon [ngClass]="{'disabled': !pageData.hasNextPage || isLoading}" pack="oakmoon" icon="pagination-arrow-forward"></nb-icon></button>
  <button 
    nbButton status="primary"
    [disabled]="!pageData.hasNextPage || isLoading" (click)="pageChanged('last');currentPage = totalPages"><nb-icon [ngClass]="{'disabled': !pageData.hasNextPage || isLoading}" pack="oakmoon" icon="pagination-arrow-last"></nb-icon></button>
</div>