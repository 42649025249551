import { IiabEntity } from "./iiab-reference";

export interface PreProcessor extends IiabEntity{
    classes:       ProcessorClass[];
    files:         ProcessorFile[];
    __typename:    string;
}


export interface ProcessorClass {
    assemblyIsNotCollectable: boolean;
    __typename:               string;
}

export interface ProcessorFile {
    fileName:   string;
    __typename: string;
}


export interface ProcessorLogs {
    id: string;
    message:        string;
    logType:        string;
    processorTypes: string;
    runRecipient:   null;
    created:        string;
    stacktrace:     string;
    __typename:     string;
  }