import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableWrapper, RunTemplateService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'ifs-wizard-run-template-form',
  templateUrl: './run-template-form.component.html',
  styleUrls: ['./run-template-form.component.scss'],
  providers: [RunTemplateService]
})
export class RunTemplateFormComponent implements OnInit {
  @Input() data: any;
  @ViewChild('autoInput') input;

  constructor(
    private wizardService: WizardService,
    private runTemplateService: RunTemplateService,
    private router: Router
  ) { }

  runTemplates$: Observable<IiabIdName[]>;
  runTemplatesQuery: ObservableWrapper<IiabIdName>;

  filterObject = {};

  selectedRunTemplate: string;
  selectedRunTemplateId: string;

  ngOnInit(): void {
    this.runTemplatesQuery = this.data.title === 'Run Search Data' ? this.runTemplateService.runRunTemplatesWithSearchData : this.runTemplateService.runRunTemplates;
    
    this.runTemplatesQuery.loadPagedItems(this.loadSearchHistory());
    
    this.runTemplates$ = this.runTemplatesQuery.Items$().pipe(
      exhaustMap((res) => of(res))
    );
  }

  onChange() {
    const wordSearch = this.input.nativeElement.value;

    this.filterObject = wordSearch.length === 24 ? { id: { eq: this.input.nativeElement.value }} : { name: { like:  this.input.nativeElement.value }}
  }

  searchRunTemplates() {    
    this.runTemplates$ = of([{ id: "Loading..", name: "Loading.."}]);
    
    this.runTemplatesQuery.loadPagedItems(this.input.nativeElement.value ? this.filterObject : null);
    this.runTemplates$ = this.runTemplatesQuery.Items$().pipe(exhaustMap((res) => of(res)));
    this.wizardService.addSearchHistory("RunTemplates", this.filterObject)
  }
  
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.searchRunTemplates();
  }

  onSelectionChange($event) {
      this.runTemplates$.subscribe((res: any) => {
         res.find(item => {
           if(item.name === this.input.nativeElement.value){
             this.selectedRunTemplateId = item.id;
             this.selectedRunTemplate = item.name;
             this.runTemplates$ = of([item]);
             this.router.navigateByUrl(`/admin/runtemplates/${item.id}`)
          }
        })
      })
  }

  cancel(){  
    this.wizardService.setState(0);
    this.wizardService.removeSearchHistory("RunTemplates")
  }
  
  hasKeys(obj: any): boolean {
    return obj != null && Object.keys(obj).length > 0;
  }

  private loadSearchHistory() {
    if (this.wizardService.wizardSearchHistory['RunTemplates']) {
      const searchHistory = this.wizardService.wizardSearchHistory['RunTemplates'];
      
      if (searchHistory.name != null) this.selectedRunTemplate = searchHistory.name.like;
      if (searchHistory.id != null) this.selectedRunTemplateId = searchHistory.id.eq;
      this.filterObject = searchHistory;
      return searchHistory;
    } else return null;
  }
}
