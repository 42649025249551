
export interface RazorSMS {
    Body:                   string;
    CharacterCount?:        number;
    SmsCount?:              number;
    IsEnabled?:             boolean;
    Message?:               string;
}

export interface RazorTransform {
    Body:           string;
    Message?:       string;
}
