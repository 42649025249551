import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { DashboardService, RunsService } from '@InfoSlips/iiab-api';
import { AuthService } from '@InfoSlips/iiab-auth';
import { NbDialogService } from '@nebular/theme';
import { WizardService } from 'libs/wizard/src/lib/services/wizard.service';
import { ToastrService } from 'ngx-toastr';
import { RunFilterService, FilterObject } from '../../services/run-filter.service';

enum WizardContext {
  Setup = 'Setup',
  CustomersAndUsers = 'Customers and Users',
  Reports = 'Reports'
}

enum MenuItems {
  // Setup
  Run = 'Run',
  RunTemplate = 'Run Template',
  Template = 'Template',
  PreProcessor = 'Pre Processor',
  PostProcessor = 'Post Processor',
  // Customers And Users
  Users = 'Users',
  Customers = 'Customers',
  Recipients = 'Recipients',
  RunRecipients = 'Run Recipients'
}

@Component({
  selector: 'ifs-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.scss']
})
export class DashboardDetailComponent implements OnInit {


  innerWidth = window.innerWidth;
  isFilterExpanded = false;
  dialogRef;
  runFilterObject: FilterObject;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    public authService: AuthService,
    private wizardService: WizardService,
    private dialogService: NbDialogService,
    private toastr: ToastrService,
    public dashboardService: DashboardService,
    private runService: RunsService,
    private runFilterService: RunFilterService
  ) { }

  get searchPhrase(): string {
    return this.runFilterService.searchPhrase;
  }
  set searchPhrase(input: string) {
    this.runFilterService.searchPhrase = input;
  }

  ngOnInit() {
       this.runFilterService.filterObject$.subscribe((filterObject: FilterObject) => {
        this.runFilterObject = filterObject;
      });
  }

  get runFilterObjectLength(): number {
    return this.runFilterObject ? Object.keys(this.runFilterObject).length : 0;
  }

  openActionsSetup(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, {});
  }

  openWizard(context: string, menuItem: string){      
    this.wizardService.toggleWizard(true);
    this.wizardService.wizardContextSubject.next(WizardContext[context]);
    setTimeout(() => {
      document.getElementById(MenuItems[menuItem]).click(); 
    }, 100);
  }

  enableAction(e){
    const enabledActions = this.dashboardService.availableActions.filter(action => action.enabled);
    if(enabledActions.length >= 6 ){
      const firstActionIndex = this.dashboardService.availableActions.findIndex(action => action.name === enabledActions[0].name);
      this.dashboardService.availableActions[firstActionIndex].enabled = false;
    }
  }

  setActions(){
    const enabledActions = this.dashboardService.availableActions.filter(action => action.enabled);

    if(enabledActions.length < 6){
      this.toastr.error("You need to select 6 quick actions.")
      return
    }
    this.dashboardService.setQuickActions(enabledActions);
    this.toastr.info('Quick actions have been updated.')
    this.dialogRef.close();
  }

  toggleFilter(){
    this.isFilterExpanded = !this.isFilterExpanded
  }

  getActionNames(actions){
    return actions.map(x => x?.name).join(', ');
  }

  search(){
    this.runFilterService.userInputAction.next(true);
    this.runService.runSummaries.loadPagedItems(Object.keys(this.runService.runsFilterObject).length !== 0 ? this.runService.runsFilterObject : null);
  }
}
