<ng-container [ngSwitch]="selectedRun?.Status">

    <ng-template [ngSwitchCase]="'Scheduled'">        
        <ng-container *ngTemplateOutlet="scheduledOn"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'Complete'">
        <ng-container *ngTemplateOutlet="doneProcessing"></ng-container>
        <ng-container *ngTemplateOutlet="sentLive"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'Failed'">
        <ng-container *ngTemplateOutlet="doneProcessing"></ng-container>
        <ng-container *ngTemplateOutlet="sentLive"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'Processed'">        
        <ng-container *ngTemplateOutlet="doneProcessing"></ng-container>
        <ng-container *ngTemplateOutlet="sentLive"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'Expired'">        
        <ng-container *ngTemplateOutlet="expiredRun"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'Initiating'">        
        <ng-container *ngTemplateOutlet="sendLiveAgain"></ng-container>
    </ng-template>
</ng-container>

<ng-container *ngIf="!isTrial && (selectedRun?.Instance?.Status == 3 || selectedRun?.Instance?.Status == 4 || selectedRun?.Instance?.Status == 5 || selectedRun?.Instance?.Status == 8)">
    <ng-container *ngTemplateOutlet="resendResultEmail"></ng-container>
</ng-container>

<ng-template #doneProcessing>
    <nb-card *ngIf="isTrial || selectedRun?.Instance?.Status != 3" class="input-box section-card">
        <nb-card-header *ngIf="selectedRun?.Instance?.Status != 4">
            Run ready to go live.
        </nb-card-header>
        <nb-card-header *ngIf="selectedRun?.Instance?.Status === 4">
            Retry sending live run.
        </nb-card-header>
        <nb-card-body class="padded">
            <p *ngIf="!isScheduled">Would you like to schedule the Run for Later or Start Live Run now?</p>
            <nb-form-field class="calendar" *ngIf="isScheduled">
                <input nbInput fullWidth placeholder="Pick Date/Time" [nbDatepicker]="dateTimePicker" [(ngModel)]="scheduleDate" name="scheduleDate" (ngModelChange)="scheduleRun($event)" >
                <nb-date-timepicker #dateTimePicker><nb-icon icon="calendar" pack="oakmoon"></nb-icon></nb-date-timepicker>
            </nb-form-field>
        </nb-card-body>
        <nb-card-footer>
            <button *ngIf="!selectedRun.IsCompileOnly" size="medium" nbButton class="btn" [status]="!isScheduled ? 'info' : 'basic'" (click)="toggleIsScheduled(isScheduled)">{{ !isScheduled ? 'Schedule Run for Later' : 'Cancel' }}</button>
            <button [disabled]="scheduleDate == null && isScheduled" size="medium" nbButton class="btn" status="primary" (click)="sendLive()">{{isScheduled ? 'Schedule' : 'Start Live Run Now'}}</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #sentLive>
    <nb-card *ngIf="!isTrial && selectedRun?.Instance?.Status === 3" class="input-box section-card">
        <nb-card-header>
            Done!
        </nb-card-header>
        <nb-card-body class="padded">
            <p>Live run was processed {{ selectedRun?.Instance?.Completed | date :'MM dd, yyyy'}}</p>
        </nb-card-body>
        <nb-card-footer *ngIf="!isTrial && mayExpireRun">
            <button size="medium" nbButton class="btn" status="danger" 
            (click)="openConfirmationDialog()"
            >Expire This Run</button>
            <input required nbInput type="text" size="medium"
                [(ngModel)]="expirationMessage" placeholder="Run Expiration Message" 
                />
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #scheduledOn>    
    <nb-card class="input-box section-card">
        <nb-card-header>
            Run scheduled to release on: {{selectedRun.ScheduleDateTime | localDate}}
            <nb-toggle style="margin-top: -6px !important;" class="toggle" [(ngModel)]="isScheduled">Reschedule?</nb-toggle>
        </nb-card-header>
        <nb-card-body class="padded">
            <nb-form-field class="calendar" *ngIf="isScheduled">
                <input nbInput fullWidth placeholder="Pick Date/Time" [nbDatepicker]="dateTimePicker" [(ngModel)]="scheduleDate" name="scheduleDate" (ngModelChange)="scheduleRun($event)" >
                <nb-date-timepicker #dateTimePicker><nb-icon icon="calendar" pack="oakmoon"></nb-icon></nb-date-timepicker>
            </nb-form-field>
        </nb-card-body>
        <nb-card-footer>
            <button size="medium" nbButton class="btn" status="danger" (click)="cancelScheduledRun()">Cancel Run</button>
            <button [disabled]="scheduleDate == null && isScheduled" size="medium" nbButton class="btn" status="primary" (click)="isScheduled ? updateScheduledRun() : startScheduledRun()">{{isScheduled ? 'Schedule' : 'Start Live Run Now'}}</button>
        </nb-card-footer>
      </nb-card>
</ng-template>

<ng-template #expiredRun>    
    <nb-card class="input-box section-card">
        <nb-card-header>
            Expired
        </nb-card-header>
        <nb-card-body class="padded">
            Run was expired {{ selectedRun.LastUpdated | date :'MM dd, yyyy' }}.
        </nb-card-body>
        <nb-card-footer>
            <button size="medium" nbButton class="btn" status="danger" (click)="cancelScheduledRun()">Cancel Run</button>
            <button [disabled]="scheduleDate == null && isScheduled" size="medium" nbButton class="btn" status="primary" (click)="isScheduled ? updateScheduledRun() : startScheduledRun()">{{isScheduled ? 'Schedule' : 'Start Live Run Now'}}</button>
        </nb-card-footer>
      </nb-card>
</ng-template>

<ng-template #resendResultEmail>
    <nb-card class="section-card resend-run-result">
        <nb-card-header>
            Resend Run Result <button class="pull-right" nbButton status="primary"><nb-icon pack="oakmoon" icon="add" (click)="addAdditionalEmail()"></nb-icon></button>
        </nb-card-header>
        <nb-card-body class="resend-result">
            <div *ngFor="let emailAddress of runResultMails | async; let i = index">
                <input class="additional-emails" required nbInput type="text" size="medium"
                [ngModel]="(runResultMails | async)[i]" (change)="updateEmailEntry($event, i)" placeholder="Additional Email Address" 
                /><nb-icon class="pull-right" pack="oakmoon" icon="close" (click)="removeAddAddress(i)"></nb-icon>
            </div>
        </nb-card-body>
        <nb-card-footer *ngIf="!isTrial && mayExpireRun">   
            <button size="medium" nbButton class="btn" status="primary" 
            (click)="resendRunResult()"
            [disabled]="(runResultMails | async) == null"
            >Resend Run Result</button>            
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #sendLiveAgain>
    <nb-card class="input-box section-card">
        <nb-card-header>
            Send Live Run again?
        </nb-card-header>
        <nb-card-body class="padded">
            <p>Would you like send the run live again?</p>
        </nb-card-body>
        <nb-card-footer>  
            <button size="medium" nbButton class="btn" status="primary" (click)="sendLive()">Start Live Run Now</button>          
        </nb-card-footer>
    </nb-card>
</ng-template>
