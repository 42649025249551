import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { AuthGuard, SharedModule } from '@InfoSlips/shared';
import { NavComponent } from './components/nav/nav.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PersonalDetailsComponent } from './containers/personal-details/personal-details.component';
import { PersonalDetailsFormComponent } from './components/personal-details-form/personal-details-form.component';
import { SecurityComponent } from './containers/security/security.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecurityFormComponent } from './components/security-form/security-form.component';
import { NebularModule } from '@InfoSlips/nebular';
import { ManageProfileComponent } from './containers/manage-profile/manage-profile.component';

export const profileRoutes: Route[] = [
  {
    path: 'profile',
    component: PersonalDetailsComponent,
    canActivate: [AuthGuard],
    data: {animation: 'profile'}
  },
  {
    path: 'security',
    component: SecurityComponent,
    canActivate: [AuthGuard],
    data: {animation: 'security'}
  },
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {animation: 'password'}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(profileRoutes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NebularModule
  ],
  declarations: [
    PersonalDetailsComponent,
    PersonalDetailsFormComponent,
    SecurityComponent,
    SecurityFormComponent,
    ChangePasswordComponent,
    NavComponent,
    ChangePasswordFormComponent,
    ManageProfileComponent,
  ],
  exports: [PersonalDetailsComponent, ManageProfileComponent,]
})
export class ProfileModule {}
