import { Component, OnInit } from '@angular/core';
import { PasswordService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-forgot-password',
  template: `
    <ifs-logged-out-view>
      <ifs-forgot-password-form
        (forgotPassword)="forgotPassword($event)"
      ></ifs-forgot-password-form>
    </ifs-logged-out-view>
  `,
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private passwordService: PasswordService,
  ) {}

  ngOnInit() {}

  forgotPassword(email) {
    this.passwordService.forgotPassword(email);
  }
}
