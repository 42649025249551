import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EnvironmentService } from '@InfoSlips/env';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { CmsCreateUpdateContentModel } from '@InfoSlips/models';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'cms-theme-config',
  templateUrl: './theme-config.component.html',
  styleUrls: ['./theme-config.component.scss']
})
export class ThemeConfigComponent {

  filePath: string;
  root: any = document.querySelector('.nb-theme-light');

  colors = [
    { name: 'Primary', value: '#7BB026' },
    { name: 'Secondary', value: '#202120' },
    { name: 'Success', value: '#78BE20' },
    { name: 'Info', value: '#00B2A9' },
    { name: 'Warning', value: '#F2A900' },
    { name: 'Danger', value: '#D22630' },
    { name: 'Modal', value: '#2b2b2b' },
    { name: 'Active Tab', value: '#202120' },
    { name: 'Actions', value: '#2b2b2b' },
    { name: 'Actions Hover', value: '#2b2b2b' },
    { name: 'Header', value: '#63921A' },
  ]

  logo = null;
  logoPosition = 0;
  logoVisible = true;
  logoToggleEnabled = true;

  logoPositionTop = 0;
  logoPositionLeft = 0;

  domainName = null;
  themePalette = {};
  themeImageConfig = {
    'display-login-logo': 'inline-block',
    'login-logo-text-display': 'inherit',
    'logo-container-height': '72.72px'
  }
  themeImage = null;
  themeImageWidth = 100;
  themeImageHeight = 100;
  themeImagePosition = 'center';

  themeLoader = null;
  themeLoaderPath = null;

  isDomainSet = false;

  constructor(
    public fb: FormBuilder,
    private cmsService: CmsService,
    private environment: EnvironmentService,
    private apiBaseService: ApiBaseService
  ) {}

  themeChange(e){
    this.themePalette = {...this.themePalette, ...e};
  
    for (const [key, value] of Object.entries({...this.themePalette, ...this.themeImageConfig })) {
      if(key != 'header-background-color' && key != 'login-logo-text-image'){
        this.root.style.setProperty(`--${key}`, value);
      }
    }
  }

  logoChange(){
    this.themeImageConfig['display-login-logo'] = 'none'; // Hide IFS Logo
    this.themeImageConfig['login-logo-text-display'] = 'none'; // Hide IFS Logo Text
    // Override Logo
    this.themeImageConfig['login-logo-text-image'] = `url(${this.logo}) 
      ${this.themeImagePosition} / ${this.themeImageWidth}px ${this.themeImageHeight}px no-repeat`;
    this.themeImageConfig['logo-container-height'] = '125px';

    for (const [key, value] of Object.entries(this.themeImageConfig)) {
      this.themePalette[key] = value;
      this.root.style.setProperty(`--${key}`, value);
    }

    this.logoToggleEnabled = false;
  }

  setSize(size: number, axis: string){
    axis === 'width' ? this.themeImageWidth = size : this.themeImageHeight = size;
    this.calculateAspectRatioFit( size, axis, 358, 125);
  
    this.themeImageConfig['login-logo-text-image'] = `url(${this.logo}) 
      ${this.themeImagePosition} / ${this.themeImageWidth}px ${ this.themeImageHeight}px no-repeat`;
    this.root.style.setProperty(`--login-logo-text-image`, this.themeImageConfig['login-logo-text-image']);
  }

  logoToggle(){
    this.logoVisible = !this.logoVisible;
    this.root.style.setProperty(`--display-login-logo`, this.logoVisible ? 'inline-block' : 'none');
    this.root.style.setProperty(`--login-logo-text-display`, this.logoVisible ? 'inherit' : 'none');
  }

  changePosition(direction: string){
    switch (direction) {
      case 'up':
        this.logoPositionTop -= 1;
        break;

      case 'down':
        this.logoPositionTop += 1;
        break;

      case 'left':
        this.logoPositionLeft -= 1;
        break;

      case 'right':
        this.logoPositionLeft += 1;
        break;
    }
    this.root.style.setProperty(`--login-logo-text-image`, `url(${this.logo}) 
      ${this.logoPositionLeft}% ${this.logoPositionTop}% / ${this.themeImageWidth}px ${this.themeImageHeight}px no-repeat`);
  }

  setPosition(direction: string){
    this.themeImagePosition = direction;
    this.themeImageConfig['login-logo-text-image'] = `url(${this.logo}) 
      ${this.themeImagePosition} / ${this.themeImageWidth}px ${this.themeImageHeight}px no-repeat`;
    this.root.style.setProperty(`--login-logo-text-image`, `url(${this.logo}) 
      ${this.themeImagePosition} / ${this.themeImageWidth}px ${this.themeImageHeight}px no-repeat`);
  }
  
  imagePreview(e) {    
    e.preventDefault();

    this.isDomainSet = false;
    this.themeImage = (e.dataTransfer || e.target as HTMLInputElement).files[0];

    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
      //Initiate the JavaScript Image object.
      const image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = this.filePath;

      //Validate the File Height and Width.
      image.onload = () => {
        this.themeImageWidth = image.width;
        this.themeImageHeight = image.height;
        this.logo = reader.result;
        this.logoPosition = 0;
        this.logoChange();
      };
    }
    reader.readAsDataURL(this.themeImage);
  }

  setLoader(e){
    e.preventDefault();

    this.themeLoader = (e.dataTransfer || e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.themeLoader);
  }

  save(){
    if(this.logo && !this.isDomainSet){
      this.saveLogo();
    } else {
      this.savePalette(this.themePalette);
    }

    if(this.themeLoader){
      this.saveLoader();
    }
  }

  savePalette(data){
    const response: CmsCreateUpdateContentModel = {
      Data: JSON.stringify(data),
      Display: `${this.domainName} - palette`,
      Name: `${this.domainName}-palette`,
      Tags: [this.domainName],
      CmsContentType:  'ThemePalette',
      IsDraft: false,
      ContentType: "application/json"
    }
    this.cmsService.createTheme(response);
  }

  saveLogo(){
    const object = {...this.themePalette, ...this.themeImageConfig };
    const responseLogo: CmsCreateUpdateContentModel = {
      Display: `${this.domainName} - image`,
      Name: `${this.domainName}-image`,
      Tags: [this.domainName],
      CmsContentType:  'ThemeImages',
      IsDraft: false,
      ContentType: null
    }

    this.cmsService.createThemeFile(responseLogo, this.themeImage).subscribe(res => {
      object['login-logo-text-image'] = `url('{url}') 
        ${this.themeImagePosition} / ${this.themeImageWidth}px ${this.themeImageHeight}px no-repeat`;
      this.savePalette(object)
    });
  }

  saveLoader(){
    const responseLoader: CmsCreateUpdateContentModel = {
      Display: `${this.domainName} - loader`,
      Name: `${this.domainName}-loader`,
      Tags: [this.domainName],
      CmsContentType:  'ThemeImages',
      IsDraft: false,
      ContentType: "image/gif"
    }

    this.cmsService.createThemeFile(responseLoader, this.themeLoader).subscribe();
  }

  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  calculateAspectRatioFit(size, axis, maxWidth, maxHeight) {
    let width = this.themeImageWidth;
    let height = this.themeImageHeight;

    axis === 'width' ? width = size : height = size;
    const ratio = Math.min(maxWidth / width, maxHeight / height);

    axis === 'width' ? this.themeImageHeight = (height * ratio) :  this.themeImageWidth = (width * ratio);
  }

  download(){
    const file = new Blob([JSON.stringify(this.themePalette)], {type: 'json'});
    const a = document.createElement('a'), url = URL.createObjectURL(file);
        a.href = url;
        a.download = `${this.domainName}-theme-palette`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
  }

  setDomain(){
    this.apiBaseService.executeGet(`viewer/themes/${this.domainName}`, 'Retrieve theme by domain.').subscribe((res: any) => {
      if(res.ColorPalette){
        this.colors = [
          { name: 'Primary', value: res.ColorPalette["color-primary-500"] || '#7BB026' },
          { name: 'Secondary', value: res.ColorPalette["color-secondary-500"] || '#202120' },
          { name: 'Success', value: res.ColorPalette["color-success-500"] || '#78BE20' },
          { name: 'Info', value: res.ColorPalette["color-info-500"] || '#00B2A9' },
          { name: 'Warning', value: res.ColorPalette["color-warning-500"] || '#F2A900' },
          { name: 'Danger', value: res.ColorPalette["color-danger-500"] || '#D22630' },
          { name: 'Modal', value:  res.ColorPalette["modal-color"] ||'#2b2b2b' },
          { name: 'Active Tab', value:  res.ColorPalette["modal-tab-color"] ||'#494c49' },
          { name: 'Actions', value:  res.ColorPalette["modal-action-color"] ||'#2b2b2b' },
          { name: 'Actions Hover', value:  res.ColorPalette["modal-action-hover-color"] ||'#2b2b2b' },
          { name: 'Header', value:  res.ColorPalette["header-background-color"] ||'#63921A' },
        ];
        this.themeChange(res.ColorPalette);
      }

      if(res.Images.length != 0){
        this.logo = res.Images.find(image => image.Name === `${this.domainName}-image`)?.Path || null;
        this.logo ? this.setDomainImage(res) : undefined;
      }
    })
  }

  setDomainImage(theme){
    this.themeImageConfig['display-login-logo'] = 'none'; // Hide IFS Logo
    this.themeImageConfig['login-logo-text-display'] = 'none'; // Hide IFS Logo Text
    // Override Logo
    this.themeImageConfig['login-logo-text-image'] = theme.ColorPalette['login-logo-text-image'];
    this.themeImageConfig['logo-container-height'] = '125px';

    for (const [key, value] of Object.entries(this.themeImageConfig)) {
      this.themePalette[key] = value;
      if(key === 'login-logo-text-image'){
        this.root.style.setProperty(`--${key}`, value.replace('{url}', this.logo));
      } else {
        this.root.style.setProperty(`--${key}`, value);
      }
    }

    this.isDomainSet = true;
    this.logoToggleEnabled = false;
  }
}
