
<h5>Post-Processors</h5>

<table width="100%">
    <thead>
        <tr>
            <th>NAME</th>
            <th>CLASS</th>
            <th>NON-COLLECTABLE</th>
            <th>REMOVE</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let processor of postProcessors; index as i">
            <td>
            <input
                [ngModel]="processor?.Name"
                [disabled]="disabled"
                (ngModelChange)="onPostProcessorChange($event)"
                nbInput
                fullWidth
                type="text"
                [nbAutocomplete]="postProcessorModel" 
                filled 
                [status]="processor.Name !== _PostProcessor[i]?.Name ? 'info' : 'basic'"
            />
      
            <nb-autocomplete #postProcessorModel>
                <nb-option *ngFor="let option of postProcessors$ | async" (click)="selectProcessor(option, i)">
                {{ option.Name }}
                </nb-option>
            </nb-autocomplete>
            </td>
            <td>
                <nb-select placeholder="Select Class" [ngModel]="processor.ClassData?.ClassName" fullWidth filled 
                    [status]="processor.ClassData?.ClassName !== _PostProcessor[i]?.ClassData?.ClassName ? 'info' : 'basic'"> 
                    <nb-option *ngFor="let option of processor.Classes" (click)="selectClass(option, i)" [value]="option.ClassName">
                        {{ option.ClassName }}
                    </nb-option>
                </nb-select>
            </td>
            <td>
                <nb-checkbox status="basic" [(ngModel)]="processor.ClassData.AssemblyIsNotCollectable" (ngModelChange)="selectNonCollectableAssembly()"   
                    [status]="processor.ClassData?.AssemblyIsNotCollectable !== _PostProcessor[i]?.ClassData?.AssemblyIsNotCollectable ? 'info' : 'basic'">
                    <span [style]="processor.ClassData?.AssemblyIsNotCollectable !== _PostProcessor[i]?.ClassData?.AssemblyIsNotCollectable ? 'color: #00b3ff' : ''">Is Assembly Non-Collectable?</span>
                </nb-checkbox>
            </td>
            <td>
                <button nbButton (click)="remove(i)">
                    <nb-icon icon="bin" pack="oakmoon"></nb-icon>
                </button>
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td>
                <button nbButton status="primary" (click)="add()">Add Post-Processor</button>
            </td>
        </tr>
    </tfoot>
</table>

<br/>