export interface AccessTokenModel{
    Type:number;
    Name:string;
    AuthData:AuthenticationDataModel;
    AuthHeaderName:string;
    AuthHeaderPrefix:string;
}

export interface AuthenticationDataModel {
    HostUrl:string;
    ApiUrl:string;
    Parameters: any[]
}