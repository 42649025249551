import { ToastrService } from 'ngx-toastr';
import { NbDialogService, NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InfoSlipsService, RunRecipientService, SelectedRunService } from '@InfoSlips/iiab-api';
import { RunModel, RunRecipientOutputChannel, RunRecipientReference, RunRecipientSummary } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@InfoSlips/iiab-auth';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  name: string;
  size: string;
  kind: string;
  items?: number;
}

@Component({
  selector: 'ifs-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.scss']
})
export class RecipientComponent implements OnInit {

  @Input() recipientType: boolean;
  @Input() recipientData: any;
  @Input() selectedRunService: SelectedRunService;
  @Input() allowActions: boolean = true;
  @Input() displayAdditionalRunInfo: boolean = false;

  @ViewChild('sequenceInput') input;

  constructor(
    private runRecipientService: RunRecipientService,
    private infoSlipsService: InfoSlipsService,
    private dialogService: NbDialogService,
    private toastr: ToastrService,
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    public authService: AuthService
  ) {
  }

  selectedRun: RunModel;
  recipientConfig;
  previewContext: string;
  dialogRef;
  ifsInfoRef;
  hasRunRecipientSpecificFiles;

  sequenceOptions$: Observable<string[]>;
  showSequence = false;
  selectedSequence = 0;
  flipCard = false;
  
  distinctChannels$: Observable<RunRecipientOutputChannel[]>;
  distinctChannels: RunRecipientOutputChannel[];
  
  outputChannels$: Observable<RunRecipientOutputChannel[]>;
  outputChannels: RunRecipientOutputChannel[];

  ngOnInit() {
    if (this.selectedRunService != undefined && this.selectedRunService != null) 
      this.selectedRunService.selectedRun$.subscribe(run=>{
        this.selectedRun=run;

        this.recipientConfig = {
          email: this.selectedRun?.EmailTemplate ? this.selectedRun?.EmailTemplate?.IsEnabled : false,
          pdf: this.selectedRun?.ExportTemplate ? this.selectedRun?.ExportTemplate?.IsEnabled : false,
          sms: this.selectedRun?.SmsTemplate ? this.selectedRun?.SmsTemplate?.IsEnabled : false,
        };

        this.sequenceOptions$ = of(this.recipientData.ifsFileData);
      });

    this.hasRunRecipientSpecificFiles = this.runRecipientService.hasRunRecipientSpecificFiles(this.recipientData);

    this.distinctChannels$ = this.runRecipientService.getOutputChannelsDistinct(this.recipientData.id, this.recipientData?.run?.id);
    this.distinctChannels$.subscribe(res => this.distinctChannels = res);
  }

  preview(ref, context: string) {
    this.previewContext = context;
    this.dialogRef = this.dialogService.open(ref, {
      closeOnBackdropClick: false
    });

    this.dialogRef.onBackdropClick.subscribe(res => {
      if (res.type === "click") {
        this.closePreview()
      }
    })
  }

  downloadInfoSlip(recipientId: string, sequence: number) {
    this.infoSlipsService.download(`RunRecipient/${recipientId}/IfsFile/${sequence}`).subscribe();
  }

  ifsFileData(recipientData: RunRecipientReference, sequence: number) {
    this.runRecipientService.ifsFileData(recipientData, sequence);
  }

  specificFileData(recipientData: RunRecipientReference) {
    this.runRecipientService.runRecipientSpecificFiles(recipientData);
  }

  downloadExportedPdf(recipientData: RunRecipientReference, sequence: number) {
    this.runRecipientService.downloadExportedPdf(recipientData, sequence);
  }

  closePreview() {
    this.dialogRef.close();
  }

  removeFromRun(ref, recipientId:string) {
    this.dialogRef = this.dialogService.open(ref, { context: {'recipId':recipientId, type:'remove'} });
  }

  confirmRemoveRecipient() {
    this.runRecipientService.deleteAndDecrementRunCounters(this.recipientData?.id).subscribe(res => {
      if (res) {
        this.selectedRunService.filterRunRecipients(null);
        this.selectedRunService.getRecipientCount();
        this.dialogRef.close();
        this.toastr.success("Recipient Successfully Removed")
      } else {
        this.toastr.error("Recipient Could not be Removed")
      }
    });
  }

  expireFromRun(ref, recipientId:string) {
    this.dialogRef = this.dialogService.open(ref, { context: {'recipId':recipientId, type:'expire'} });
  }
  confirmExpireRecipient(expirationReason: string) {
    this.runRecipientService.expireLiveRunRecipient(this.recipientData?.id, expirationReason).subscribe(res => {
      if (res) {
        this.selectedRunService.filterRunRecipients(null);
        this.selectedRunService.getRecipientCount();
        this.dialogRef.close();
        this.toastr.success("Recipient Successfully Expired")
      } else {
        this.toastr.error('Failed to expire recipient!', res.Message)
      }
    });
  }

  getIfsInfo(ref, recipientId: string) {
    this.runRecipientService.getIfsInfo(recipientId).subscribe((res: any) => {
      this.ifsInfoRef = this.dialogService.open(ref, { context: res });

      const data: TreeNode<FSEntry>[] = [];
      res.InfoSlips.forEach((item, index) => {
        const children = [];

        for (const [key, value] of Object.entries(item.Content)) {
          children.push({
            data: { name: key, kind: key.split('.')[1].toUpperCase(), size: this.calculateSize(value) }
          })
        }

        data.push({
          data: { name: `Sequence: ${index}`, size: this.calculateSize(item.Size), items: Object.keys(item.Content).length, kind: 'IFS' },
          children: children
        })
      });
      this.dataSource = this.dataSourceBuilder.create(data);
    });
  }

  customColumn = 'name';
  defaultColumns = ['size', 'kind', 'items'];
  allColumns = [this.customColumn, ...this.defaultColumns];

  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  copy(value) {
    navigator.clipboard.writeText(value);
    this.toastr.info('Copied to Clipboard!');
  }

  showActions(dialog: any) {

    this.outputChannels$ = this.runRecipientService.getOutputChannels(this.recipientData.id, this.recipientData?.run?.id);
    this.outputChannels$.subscribe(res => this.outputChannels = res);

    this.dialogRef = this.dialogService.open(dialog, { context: dialog });
  }

  getJsonString(item: any) {
    return JSON.stringify(item, null, '\t');
  }
  
  onChange() {
    this.sequenceOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  onSequenceSelect(e) {
    this.showSequence = false;
    this.selectedSequence = e.sequence;
    this.toastr.info(`Recipient sequence set to: ${e.sequence}`, 'Sequence Changed');
  }

  removeFromTrialList(id) {
    const index = this.selectedRunService.selectedRunRecipients.map(x => {
      return x.id;
    }).indexOf(id);

    this.selectedRunService.selectedRunRecipients.splice(index, 1);
    this.selectedRun.TrialOptions.RecipientList=this.selectedRunService.selectedRunRecipients.map(item=>item.externalId);
    this.selectedRunService.broadCastRunChanged(this.selectedRun);
    
    const FilterObject = {
      externalId: {
        nin: this.selectedRun.TrialOptions.RecipientList
      }
    }
    this.selectedRunService.filterRunRecipients(FilterObject);
  }
  
  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => {        
        return this.recipientData.ifsFileData.filter(option => {
          const optionLabel = `${ option.sequence } - ${ option.label }`.toLowerCase();
          
          if(optionLabel.includes(filterString.toLowerCase()))
            return option
        });
      }),
    );
  }  

  toggleCard() { 
    this.flipCard = !this.flipCard; 
  }

  getFilteredChannels(type: string): RunRecipientOutputChannel[] {
    return this.outputChannels.filter(channel => channel.channel === type);
  }

  private calculateSize(bytes: any) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    // return item >= 1000 ? `${(item / 1024).toFixed(2)} MB` : `${(item).toFixed(2)} KB`;
  }
}