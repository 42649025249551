import { InjectionToken } from '@angular/core';
/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */

export interface EnvConfig {
  name?:string,
  applicationName: string,
  version: string,
  production: boolean;
  baseUrl?: string;
  webSocketUrl?: string;
  secondsToWarn:number;
  apiUrl: null | string;
  oldAdminEnabled: boolean;
  oldAdminUrl?: string;
  tinyMceKey?: string;
  isProxyEnabled?: boolean;
  passwordAutoComplete?: boolean;
}

export const EnvConfigToken = new InjectionToken<EnvConfig>('EnvConfig');
