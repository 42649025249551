<div class="queue-status-container">
  <nb-accordion *ngFor="let server of runQueueStatus$ | async">
    <nb-accordion-item *ngIf="server != null">
      <nb-accordion-item-header>
        <div class="width-50">
          {{server.applianceType}}: {{server.ipAddress}}
        </div>
        <div class="width-50">
          <button class="mb-10 pull-right" status="primary" size="small" nbButton (click)="executeQueueStatusCommandPost('http://'+server.ipAddress+'/api/QueueStatus/PauseRunQueues')">
            <nb-icon icon="pause-circle-outline"></nb-icon> Pause All Runs
          </button>
          
          <button class="mb-10 pull-right" status="primary" size="small" nbButton (click)="executeQueueStatusCommandPost('http://'+server.ipAddress+'/api/QueueStatus/ResumeRunQueues')">
            <nb-icon icon="play-circle-outline"></nb-icon> Resume All Runs
          </button>
        </div>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="run-container width-50">
          <ng-container *ngIf="server.runSpecificQueues?.length == 0">
            <p>No Run Queue available</p>
          </ng-container>
          <ng-container *ngIf="server.runSpecificQueues?.length > 0">
            <nb-accordion class="" *ngFor="let run of server.runSpecificQueues">
              <nb-accordion-item>
                <nb-accordion-item-header>
                  <div class="width-50">
                    Run Id: {{run.runId}}
                  </div>
                  <div class="width-50">
                    <button class="mb-10" status="secondary" size="small" nbButton (click)="executeQueueStatusCommandPost('http://'+server.ipAddress+'/api/QueueStatus/PauseRunQueue/'+run.runId+'')">
                      <nb-icon icon="pause-circle-outline"></nb-icon> Pause Run
                    </button>
                    <button class="mb-10" status="secondary" size="small" nbButton (click)="executeQueueStatusCommandPost('http://'+server.ipAddress+'/api/QueueStatus/ResumeRunQueue/'+run.runId+'')">
                      <nb-icon icon="play-circle-outline"></nb-icon> Resume Run
                    </button>
                  </div>
                </nb-accordion-item-header>
                
                <nb-accordion-item-body>
                  <div class="table-container width-50">
                    <table>
                      <tr colspan="2">
                        <th>
                          Publisher
                        </th>
                      </tr>
                      <tr>
                        <td>Is Enabled</td>
                        <td>{{run.publisher?.isEnabled}}</td>
                      </tr>
                      <tr>
                        <td>Has Connection</td>
                        <td>{{run.publisher?.hasConnection}}</td>
                      </tr>
                      <tr>
                        <td>Last Activity</td>
                        <td>{{run.publisher?.lastActivity}}</td>
                      </tr>
                      <tr>
                        <td>Outstanding Confirms</td>
                        <td>{{run.publisher?.publisherOutstandingConfirms}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="table-container width-50">
                    <table>
                      <tr colspan="2">
                        <th>
                          Subscriber
                        </th>
                      </tr>
                      <tr>
                        <td>Is Enabled</td>
                        <td>{{run.subscriber?.isEnabled}}</td>
                      </tr>
                      <tr>
                        <td>Has Connection</td>
                        <td>{{run.subscriber?.hasConnection}}</td>
                      </tr>
                      <tr>
                        <td>Last Activity</td>
                        <td>{{run.subscriber?.lastActivity}}</td>
                      </tr>
                      <tr>
                        <td>Outstanding Confirms</td>
                        <td>{{run.subscriber?.subscriberActiveMessages}}</td>
                      </tr>
                    </table>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </ng-container>
        </div>

        <div class="width-50 server-details">
          <p class="detail-heading">Run Activity History</p>
          
          <p>Last Received Date Time: <span>{{server.runActivityHistory?.lastReceivedDateTime}}</span></p>
          <p>Last Sent Date Time: <span>{{server.runActivityHistory?.lastSentDateTime}}</span></p>
          
          <div>Last Received Messages: 
            <p *ngFor="let message of server.runActivityHistory?.lastReceivedMessages">{{message}}</p>
          </div>
          
          <div>Last Sent Messages: 
            <p *ngFor="let message of server.runActivityHistory?.lastSentMessages">{{message}}</p>
          </div>

          <hr />

          <p class="detail-heading">Stage Activity History</p>
          
          <p>Last Received Date Time: <span>{{server.stageActivityHistory?.lastReceivedDateTime}}</span></p>
          <p>Last Sent Date Time: <span>{{server.stageActivityHistory?.lastSentDateTime}}</span></p>
          
          <div>Last Received Messages: 
            <p *ngFor="let message of server.stageActivityHistory?.lastReceivedMessages">{{message}}</p>
          </div>
          
          <div>Last Sent Messages: 
            <p *ngFor="let message of server.stageActivityHistory?.lastSentMessages">{{message}}</p>
          </div>

          <hr />

          <p class="detail-heading">PDF Generation Status</p>
          
          <p>Waiting to be processed: <span>{{server.pdfGenerationStatus?.waitingToBeProcessedCount}}</span></p>
          
          <p>Processed Queue</p>
          <div class="table-container width-50">
            <table>
              <tr colspan="2">
                <th>
                  Publisher
                </th>
              </tr>
              <tr>
                <td>Is Enabled</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.publisher?.isEnabled}}</td>
              </tr>
              <tr>
                <td>Has Connection</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.publisher?.hasConnection}}</td>
              </tr>
              <tr>
                <td>Last Activity</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.publisher?.lastActivity}}</td>
              </tr>
              <tr>
                <td>Outstanding Confirms</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.publisher?.publisherOutstandingConfirms}}</td>
              </tr>
            </table>
          </div>
          <div class="table-container width-50">
            <table>
              <tr colspan="2">
                <th>
                  Subscriber
                </th>
              </tr>
              <tr>
                <td>Is Enabled</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.subscriber?.isEnabled}}</td>
              </tr>
              <tr>
                <td>Has Connection</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.subscriber?.hasConnection}}</td>
              </tr>
              <tr>
                <td>Last Activity</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.subscriber?.lastActivity}}</td>
              </tr>
              <tr>
                <td>Outstanding Confirms</td>
                <td>{{server.pdfGenerationStatus?.processedQueue?.subscriber?.subscriberActiveMessages}}</td>
              </tr>
            </table>
          </div>
          
          <br />

          <div>Keys Waiting To Be Processed: 
            <p *ngFor="let key of server.pdfGenerationStatus?.keysWaitingToBeProcessed">{{key}}</p>
          </div>            
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>
