import { DashboardService } from './../../services/dashboard.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ifs-create-recipients',
  templateUrl: './create-recipients.component.html',
  styleUrls: ['./create-recipients.component.scss']
})
export class CreateRecipientsComponent implements OnInit {
  createRecipients = true;
  templates: any;
  runTemplate: string;
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.dashboardService.getRunTemplates().subscribe((data: any )=>this.templates = data.runTemplates.nodes);
  }

  toggleCreateRecipients(event){
    this.createRecipients = event;
  }

  createRegistrationRun(){
    console.log("ready to create registration run");
  }

}
