<ng-container [ngSwitch]="type">
  <ng-template [ngSwitchCase]="'general'">
    <ng-container *ngTemplateOutlet="card; context: 
      { 
        cardSize: 'col-md-4',
        heading: 'assets available',
        headingEnabled: true,
        type: 'general',
        data: entitySummaries$,
        assetCount: assetCount,
        showDetailsEnabled: true
      }">
    </ng-container>
  </ng-template>
  
  <ng-template [ngSwitchCase]="'linked'">
    <ng-container *ngTemplateOutlet="card; context: 
      { 
        cardSize: 'col-md-12',
        heading: 'assets linked',
        headingEnabled: false,
        type: 'linked',
        data: entities$,
        assetCount: 0,
        showDetailsEnabled: false
      }">
    </ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'linked-runtemplate'">
    <ng-container *ngTemplateOutlet="card; context: 
      { 
        cardSize: 'col-md-6',
        heading: 'assets linked',
        headingEnabled: false,
        type: 'linked-runtemplate',
        data: entities$,
        assetCount: 0,
        showDetailsEnabled: false
      }">
    </ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'general-list'">
    <ng-container *ngTemplateOutlet="card; context: 
      { 
        cardSize: 'col-md-12',
        heading: 'assets available',
        headingEnabled: true,
        type: 'general',
        data: entitySummaries$,
        assetCount: assetCount,
        showDetailsEnabled: false
      }">
    </ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'available'">
    <ng-container *ngTemplateOutlet="card; context: 
      { 
        cardSize: 'col-md-12',
        heading: 'assets available',
        headingEnabled: false,
        type: 'available',
        data: entities$,
        assetCount: assetCount,
        showDetailsEnabled: false
      }">
    </ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'custom'">
    <ng-container *ngTemplateOutlet="card; context: options">
    </ng-container>
  </ng-template>
  
</ng-container>

<ng-template #card let-heading="heading" let-cardSize="cardSize" let-type="type" let-data$="data" 
  let-assetCount="assetCount" let-headingEnabled="headingEnabled" let-showDetailsEnabled="showDetailsEnabled">
  <div class="general-page-container">
    <div>
      <p class="heading" *ngIf="headingEnabled">{{assetCount}} {{ heading }}</p>
    </div>
    <div class="general-page-body">
  
      <div class="ifs-card-container" [ngClass]="isFilterExpanded ? 'isFilterExpanded' : ''" (scrolled)="onScroll()">
        <ng-container *ngIf="data$ | async as entitySummaries">
          <ng-container *ngIf="!loading$ && entitySummaries.length < 1; then empty; else cards"></ng-container>
          
          <ng-template #cards>
            <entity-card-component [type]="type" class="ifs-card p1" [class]="(showDetails$ | async) ? '' : cardSize" 
              *ngFor="let entity of entitySummaries" [selected]="selectedEntity?.Id === entity.id" 
              [cmsEntity]="entity" (click)="showDetailsEnabled ? selectEntity(entity) : undefined"
              [selectedRunTemplate]="selectedRunTemplate"> </entity-card-component>
          </ng-template>
          
          <ng-template #empty>
            <div class="flex-grid mt-3 p-3">
              <div class="col text-center empty-state-card">
                <div class="card-header">
                  <p>No Documents found</p>
                </div>
                <div class="card-block">
                  <ifs-icon size="md" icon="folder-dash"></ifs-icon>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
  
        <ng-container *ngIf="(loading$ | async);">
          <div *ngFor="let it of ghosts" class="ifs-card">
            <nb-card class="shadow">
              <nb-card-header>
              </nb-card-header>
              <nb-card-body>
                <ifs-ghost-cards> </ifs-ghost-cards>
              </nb-card-body>
              <nb-card-footer>
              </nb-card-footer>
            </nb-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  
</ng-template>