import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PersonalDetailsService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-verify-detail',
  styleUrls: ['./verify-detail.component.scss'],
  template: `
    <ifs-logged-out-view>
      <ifs-verify-detail-form [verified]="verified"></ifs-verify-detail-form>
    </ifs-logged-out-view>
  `
})
export class VerifyDetailComponent implements OnInit {
  token: string;
  verified: any;

  constructor(
    private route: ActivatedRoute,
    private personalDetailsService: PersonalDetailsService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.personalDetailsService.verifyInfoSlipDetail(this.token);
    this.personalDetailsService.verifyInfoSlipDetail$.subscribe(res => this.verified = res);
  }
}
