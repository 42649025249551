import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { NbDialogService, NbTagComponent } from '@nebular/theme';
import { Country, CustomerService, Miscellaneous, MiscService, RunTemplateService, UserService } from '@InfoSlips/iiab-api';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IiabIdName, UserHistory, UserReference } from '@InfoSlips/models';

enum FilterModels {
  customer,
  runTemplate,
  recipient
}
@Component({
  selector: 'ifs-admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('RoleGroupsInput') RoleGroupsInput: ElementRef<HTMLInputElement>;
  @ViewChild('RolesAddedInput') RolesAddedInput: ElementRef<HTMLInputElement>;
  @ViewChild('RolesRemovedInput') RolesRemovedInput: ElementRef<HTMLInputElement>;
  @ViewChild('CustomerFilterInput') CustomerFilterInput: ElementRef<HTMLInputElement>;
  @ViewChild('RunTemplateFilterInput') RunTemplateFilterInput: ElementRef<HTMLInputElement>;
  
  systemRoleGroups$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('SystemRoleGroups');
  systemRoles$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('SystemRoles');
  roleGroupsDisabled = true;
  countryOptions$: Observable<Country[]> = this.userService.countryOptions$;
  customers$: Observable<IiabIdName[]> = this.customerService.customers.Items$();
  runTemplates$: Observable<IiabIdName[]> = this.runTemplateService.runRunTemplates.Items$();

  customerTags: Set<IiabIdName> = new Set<IiabIdName>();
  runTemplateTags: Set<IiabIdName> = new Set<IiabIdName>();
  recipientTags: Set<IiabIdName> = new Set<IiabIdName>();
  RoleGroupsTags: Set<string> = new Set<string>();
  RolesAddedTags: Set<string> = new Set<string>();
  RolesRemovedTags: Set<string> = new Set<string>();

  id = this.route.snapshot.paramMap.get('id');
  isNew = !this.route.snapshot.paramMap.get('id');

  menuItem = "User Data";
  effectiveRoles: string[] = [];
  historyRetrieved = false;
  hasEffectivePermissions = false;
  userHistory: UserHistory[] = [];

  menuItems = [
    {
      title: 'User Data',
      display: true
    },
    {
      title: 'Security',
      display: true
    },
    {
      title: 'Permissions',
      display: true
    },
    {
      title: 'Effective Permissions',
      display: true
    },
    {
      title: 'Additional Security',
      display: true
    },
    {
      title: 'User History',
      display: this.route.snapshot.paramMap.get('id') ? true : false
    }
  ];

  searchValue = {
    RoleGroupsInput: '',
    RolesAddedInput: '',
    RolesRemovedInput: '',
    RunTemplateFilterInput: null,
    CustomerFilterInput: null,
  };

  userDataForm = this.fb.group({
    Id: [null],
    UserName: ['', Validators.required],
    DisplayName: ['', Validators.required],
    Email: ['', Validators.required],
    DateOfBirth: [null],
    Country: this.fb.group({
      name: [''],
      id: [''],
      callingCode: [[]]
    }),
    Mobile: this.fb.group({
      Number: [''],
      CountryCode: [''],
      CountryPrefix: [null]
    }),
    NewPassword: [''],
    ConfirmPassword: [''],
    IsApproved: [false],
    IsLockedOut: [false],
    ForcePasswordReset: [false],
    OtpEnabled: [false],
    RoleGroups: new Set<string>([]),
    RolesAdded: new Set<string>([]),
    RolesRemoved: new Set<string>([]),
    CustomerFilter: new Set<string>([]),
    RunTemplateFilter: new Set<string>([]),
    RecipientFilter: [[]]
  });

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private miscService: MiscService,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private runTemplateService: RunTemplateService,
    private router: Router,
    private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {
    
    if(!this.userDataForm.value.Country?.id){
      // Prevent Browser Autocomplete
      this.userDataForm.get('NewPassword').disable();
      this.userDataForm.get('Country').disable();
      this.userDataForm.get('Mobile').disable();
      setTimeout(() => {
        this.userDataForm.get('NewPassword').enable();
        this.userDataForm.get('Country').enable();
      }, 1000);
    } else {
      this.userDataForm.get('Mobile').enable();
    }

    if(!this.id){
      this.userDataForm.addControl('IsNew', this.fb.control(true));
      this.userDataForm.removeControl('Id');
      return;
    }
    
    this.userService.getUserById(this.id).subscribe((userReference: UserReference) => {
      this.userDataForm.patchValue(userReference);

      if (userReference['Country'] && userReference['Country'].id){
        this.userDataForm.get('Mobile').enable();
        if (this.userDataForm['Mobile'] && this.userDataForm['Mobile'].Number)
          this.userDataForm.patchValue({
            Mobile: {
              Number: userReference['Mobile'].replace(userReference['Country']?.callingCode[0], "")
            }
          });
      }
      
      this.userDataForm.patchValue({
        RoleGroups: new Set<string>(this.userDataForm.value.RoleGroups),
        RolesAdded: new Set<string>(this.userDataForm.value.RolesAdded),
        RolesRemoved: new Set<string>(this.userDataForm.value.RolesRemoved),
        CustomerFilter: new Set<string>(this.userDataForm.value.CustomerFilter),
        RunTemplateFilter: new Set<string>(this.userDataForm.value.RunTemplateFilter),
      })

      this.initializeRoles();
       
      this.filterAvailableRunTemplates();

      this.filterCustomers();
      this.filterRunTemplates();
      this.hasPermissions();
    })
  }

  setMenuItem(item){
    this.menuItem = item;
    this.roleGroupsDisabled = true;

    if(this.menuItem === 'Effective Permissions'){
      this.hasPermissions();
      this.getEffectiveRoles();
    }

    if(this.menuItem === 'User History'){
      this.getUserHistory();
    }

    setTimeout(() => {
      this.roleGroupsDisabled = false;
    }, 500);
  }

  onTagRemove(tagToRemove: NbTagComponent, model: string): void {
    if(model === 'RoleGroups' && tagToRemove.text === 'System Administrator'){
      this.toastr.error('You do not have permission to remove this role.', 'Permission Denied');
      return;
    }
    
    this.convertArrayToSet(model);
    this.userDataForm.value[model].delete(tagToRemove.text);
    this[`${model}Tags`].delete(tagToRemove.text);
  }

  onFilterTagRemove(tagToRemove: NbTagComponent, model: string, tagSet: Set<IiabIdName>, context: FilterModels): void {
    this.userDataForm.value[model].delete([...tagSet].filter(({name}) => name ===  tagToRemove.text)[0].id);
    tagSet.delete([...tagSet].filter(({name}) => name ===  tagToRemove.text)[0]);

    context === 0 ? this.filterCustomers() : this.filterRunTemplates();
    
    if (context === 0) this.filterAvailableRunTemplates();
  }

  onTagAdd(value: string, model: string): void {
    if(model !== 'RoleGroups' && value === 'System Administrator'){
      this.toastr.error('You do not have permission to add this role.', 'Permission Denied');
      return;
    }

    if (value) {
      this.convertArrayToSet(model);
      this.userDataForm.value[model].add(value);
      this[`${model}Tags`].add(value);
    }
    this[`${model}Input`].nativeElement.value = this.searchValue[`${model}Input`] = '';
  }

  onFilterTagAdd(value: IiabIdName, model: string, context: FilterModels): void {
    if (value.id) {
      this.userDataForm.value[model].add(value.id);
      context === 0 ? this.filterCustomers() : this.filterRunTemplates();
    }
    this[`${model}Input`].nativeElement.value = this.searchValue[`${model}Input`] = '';

    if (context === 0) this.filterAvailableRunTemplates();
  }

  updateUser(){
    if (!this.validateForm()) return;
    this.userService.updateUser(this.userDataForm.value);
  }

  createUser(){
    if (!this.validateForm()) return;

    const userModel = {...this.userDataForm.value};

    if(!this.userDataForm?.value?.Mobile?.Number){
      delete userModel.Mobile
    } else {
      userModel.Mobile.Number = `${this.userDataForm.value.Mobile.Number}`;
      userModel.Mobile.CountryCode = this.userDataForm.value.Country.id;
      userModel.Mobile.CountryPrefix = +this.userDataForm.value.Country.callingCode[0];
    }
    
    this.userService.createUser(userModel).subscribe(result => {
      if(result.isCreated){
        this.router.navigateByUrl(`/admin/users/${result.id}`);
      } else {
        this.userDataForm.patchValue({
          RoleGroups: new Set<string>(this.userDataForm.value.RoleGroups),
          RolesAdded: new Set<string>(this.userDataForm.value.RolesAdded),
          RolesRemoved: new Set<string>(this.userDataForm.value.RolesRemoved),
          CustomerFilter: new Set<string>(this.userDataForm.value.CustomerFilter),
          RunTemplateFilter: new Set<string>(this.userDataForm.value.RunTemplateFilter),
        })
      }
    });
  }

  sendForgotPassword(){
    this.userService.sendForgotPassword(this.userDataForm.value.Email);
  }

  getCountry(){
    if(this.userDataForm.value.Country?.name)
      this.userService.getCountry(this.userDataForm.value.Country.name);
  }

  selectCountry(e: Country){
    if(!e.Id){
      return;
    }

    this.userDataForm.patchValue({
      Country: {
        name: e.Name,
        id: e.Id,
        callingCode: e.CallingCode
      }
    });

    this.userDataForm.get('Mobile').enable();
  }

  filterModel(e, model: FilterModels){
    if(!e || e.id){
      return
    }

    switch (model) {
      case 0:
        this.customerService.customers.loadPagedItems({
          name: {
            like: e
          }
        });
        break;

      case 1:
        this.runTemplateService.runRunTemplates.loadPagedItems({
          name: {
            like: e
          }
        });
        break;
    }
  }

  open(dialog: TemplateRef<any>, field: UserHistory) {
    this.dialogService.open(dialog, { context: field });
  }

  clearCountry(){
    this.userDataForm.patchValue({
      Country: {
        name: '',
        id: null,
        callingCode: []
      },
      Mobile: {
        Number: '',
        CountryCode: '',
        CountryPrefix: null
      }
    })

    this.userDataForm.get('Mobile').disable();
    this.userService.getCountry('');
  }

  rebuildUserHistory() {
    this.userService.rebuildUserHistory(this.id);
  }

  //#region Private

  private getEffectiveRoles(){
    this.userService.getEffectiveRoles([...this.RoleGroupsTags], [...this.RolesAddedTags], [...this.RolesRemovedTags]).subscribe(res => {
      this.effectiveRoles = res;
    });
  }

  private getUserHistory(){
    if(this.historyRetrieved)
      return;

    this.userService.getUserHistory(this.userDataForm.value.Id).subscribe(res => {
      this.historyRetrieved = true;
      this.userHistory = res;
    })
  }

  private validateForm(): boolean{
    if (this.userDataForm.status === "INVALID") {
      this.toastr.error('Incomplete User Data', 'Error:');
      return false;
    }

    if (!(this.RoleGroupsTags.size)) {
      this.toastr.error('User Role Groups must not be empty', 'Error:');
      return false;
    }

    if (this.userDataForm.value?.Mobile?.Number != null) {
      this.userDataForm.value.Mobile.CountryCode = this.userDataForm.value.Country.id;
      this.userDataForm.value.Mobile.CountryPrefix = this.userDataForm.value.Country.callingCode[0];
    }

    this.userDataForm.patchValue({
      CustomerFilter: [...this.customerTags].map(({id}) => (id)),
      RunTemplateFilter: [...this.runTemplateTags].map(({id}) => (id)),
      RoleGroups: [...this.RoleGroupsTags].map(x => x),
      RolesAdded: [...this.RolesAddedTags].map(x => x),
      RolesRemoved: [...this.RolesRemovedTags].map(x => x),
    });

    return true;
  }

  private hasPermissions(){
    if(this.RoleGroupsTags.size !== 0 || this.RolesAddedTags.size !== 0){
      this.hasEffectivePermissions = true;
    }
  }

  private convertArrayToSet(roleType) {
    this.userDataForm.value[roleType] = new Set<string>(this.userDataForm.value[roleType]);
  }

  private filterAvailableRunTemplates() {
    if (this.userDataForm.value.CustomerFilter.size <= 0) this.runTemplateService.runRunTemplates.loadPagedItems();

    const filter = {
      or: []
    }
    this.userDataForm.value.CustomerFilter.forEach(item => {
      const customerFilter = {
        customer: {id: {eq: item}}
      }
      filter.or.push(customerFilter)
    });
    this.runTemplateService.runRunTemplates.loadPagedItems(filter)

  }

  private filterCustomers(){
    if(this.userDataForm.value.CustomerFilter.size <= 0)
      return;

    this.customerService.filterByCustomerIds(this.userDataForm.value.CustomerFilter).subscribe((customerReference: IiabIdName[]) => {
      customerReference.forEach(customer => {
        this.filterTags(this.customerTags, customer);
      });
    });
  }

  private filterRunTemplates(){
    if(this.userDataForm.value.RunTemplateFilter.size <= 0)
      return;

    this.runTemplateService.filterByRunTemplateIds(this.userDataForm.value.RunTemplateFilter).subscribe((runTemplateReference: IiabIdName[]) => {
      runTemplateReference.forEach(runTemplate => {
        this.filterTags(this.runTemplateTags, runTemplate);
      });
    });
  }

  private filterTags(tagSet: Set<IiabIdName>, filterObject: IiabIdName){
    ![...tagSet].some(({id}) => id === filterObject.id) ? tagSet.add(filterObject) : undefined;
  }

  private initializeRoles() {
    this.RoleGroupsTags = this.userDataForm.value.RoleGroups;
    this.RolesAddedTags = this.userDataForm.value.RolesAdded;
    this.RolesRemovedTags = this.userDataForm.value.RolesRemoved;
  }

  //#endregion
}
