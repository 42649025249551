// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { AntiForgeryService } from '@InfoSlips/iiab-anti-forgery';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {  
  constructor(
    public auth: AuthService,
    private antiForgeryService: AntiForgeryService
    ) {}
  
  intercept(
    request: HttpRequest<any>, 
    next: HttpHandler
    ): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        "Access-Control-Expose-Headers": "x-requestiiabverificationtoken"
      },
    })

    if(this.antiForgeryService.verificationToken){
      request = request.clone({
        setHeaders: {
          "X-RequestIIABVerificationToken": this.antiForgeryService.verificationToken
        },
      })
    }
    
    if(!this.auth.isAuthenticated)
    {
        return next.handle(request); 
    }
    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.loggedInUser.access_token}`,
      }
    });
    
    return next.handle(request);
  }
}