<ng-container *ngIf="!showClear">
    <label *ngIf="showLabel">Customer</label>
    <input autocomplete="off" #customerAutoInput nbInput type="text" 
        [disabled]="disabled"
        placeholder="Enter Customer Name or Id" 
        fullWidth
        [nbAutocomplete]="customerAuto" name="selectedCompany" [ngModel]="itemNameLike"
        [ngModelOptions]="{ standalone: true }" (ngModelChange)="itemNameLikeSubject.next($event)" />
</ng-container>

<ng-container *ngIf="showClear">
    <nb-form-field>
        <label *ngIf="showLabel">Customer</label>
        <input autocomplete="off" #customerAutoInput nbInput type="text" 
            [disabled]="disabled"
            placeholder="Enter Customer Name or Id" 
            fullWidth
            [nbAutocomplete]="customerAuto" name="selectedCompany" [ngModel]="itemNameLike"
            [ngModelOptions]="{ standalone: true }" (ngModelChange)="itemNameLikeSubject.next($event)" 
        />
        <button class="clear-btn" [ngClass]="!showLabel ? 'mt-0' : ''" nbSuffix nbButton ghost (click)="clear()">
            <nb-icon icon="close-outline" pack="eva"></nb-icon>
        </button>
    </nb-form-field>
</ng-container>

<nb-autocomplete #customerAuto (selectedChange)="onItemSelected($event)" [handleDisplayFn]="displayItemName">
    <nb-option *ngFor="let option of items$ | async" [value]="option">
        {{ option.name }}
    </nb-option>
</nb-autocomplete>