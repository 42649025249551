<div class="runs-container">
    <nb-card class="block" [ngClass]="isFilterExpanded ? 'isFilterExpanded' : ''">
      <nb-card-header>
        <div class="run-header">
          <h4>CMS ENTITIES</h4>   
          <button *ngIf="selectedEntities.length > 0" nbButton status="danger" (click)="openDialog(confirmDeleteSelectedDialog)">Delete Selected</button>       
        </div>
        <div class="grid header-fields">
          <div class="flex" style="width: 50%;">
                <p class="item-text">
                    <a (click)="filterField('name')">Name</a> <nb-icon *ngIf="gridSort.name" pack="eva" [icon]="gridSort.name === 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon> / 
                    <a (click)="filterField('customer')">Customer</a> <nb-icon *ngIf="gridSort.customer" pack="eva" [icon]="gridSort.customer === 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon>
                </p>
          </div>
  
          <div class="flex item-info">
                <p class="item-text">
                  <a (click)="filterField('runTemplate')">Run Template</a> <nb-icon *ngIf="gridSort.runTemplate" pack="eva" [icon]="gridSort.runTemplate === 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon> / 
                  <a (click)="filterField('version')">Version</a> <nb-icon *ngIf="gridSort.version" pack="eva" [icon]="gridSort.version === 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon>
                </p>
          </div>
      
          <div class="text-right item-info">
              <p class="item-text">
                <a (click)="filterField('lastUpdated')">Last Updated</a> <nb-icon *ngIf="gridSort.lastUpdated" pack="eva" [icon]="gridSort.lastUpdated === 'DESC' ? 'arrow-upward-outline' : 'arrow-downward-outline'"></nb-icon>
              </p>
          </div>

          <div class="text-center item-actions">
            <p class="item-text">
              Actions
            </p>
        </div>
      </div>
      </nb-card-header>
        <nb-card-body 
          infiniteScroll 
          [infiniteScrollDistance]="1" 
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false" 
          (scrolled)="onScroll()"
        >

          <cms-grid-item *ngFor="let entity of cmsEntities$ | async" [cmsEntity]="entity" (checkedEntity)="checkCmsEntity($event)"></cms-grid-item>

          <ng-container *ngIf="(loading$ | async);">
            <div class="row" *ngIf="(resultCount$ | async) < 1">
              <div *ngFor="let it of ghosts" class="col-md-12">
                <ifs-ghost-cards> </ifs-ghost-cards>
              </div>
            </div>
          </ng-container>          
        </nb-card-body>
        <nb-card-footer>
          <p class="total">Total Entities: <span>{{(cmsEntitiesCount$ | async | number)}}</span></p>
        </nb-card-footer>
    </nb-card>
</div>

<ng-template dialogClass="confirm-delete-dialog" #confirmDeleteSelectedDialog let-ref="dialogRef">
  <nb-card class="confirm-delete-dialog">
    <nb-card-header class="create-recipient-dialogue">
      Confirm <nb-icon (click)="dialogRef.close()" icon="close" class="close"></nb-icon>
    </nb-card-header>
    <nb-card-body class="confirmDeleteBody">
      <p>Are you sure you want to delete the following entities:</p>

      <nb-list infiniteScroll 
      [infiniteScrollDistance]="1" 
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false" >
        <nb-list-item *ngFor="let entity of selectedEntities">{{entity.name}}</nb-list-item>
    </nb-list>

      <p>This action can not be reverted.</p>
      <p class="warning">Warning: Any entities linked to Run Templates will also be deleted and available only as revisions.</p>
    </nb-card-body>
    <nb-card-footer class="confirmDeleteFooter">
      <button fullWidth nbButton status="warning" (click)="deleteSelectedEntities()">Delete</button>
      <button fullWidth nbButton (click)="dialogRef.close()">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>