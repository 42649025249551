import { CmsEntityReference } from "./cms";
import { CustomerReference } from "./customer";
import { IiabEntity, IiabIdName } from "./iiab-reference";

export interface RunTemplateSummary extends IiabEntity {
    customer: CustomerReference;
    template: IiabIdName
    __typename: string;
    cmsEntities: CmsEntityReference[]
    useCms: boolean;
}