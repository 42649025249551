<editor 
    [(ngModel)]="data" 
    name="emailEditor" 
    *ngIf="enabled"
    ngDefaultControl
    [disabled]="true"
    [apiKey]="environment.tinyMceKey" 
    [init]=tinyMceConfig>
</editor>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="copyDialog">
        <nb-card-header>Template Dialog</nb-card-header>
        <nb-card-body>
        <div class="form-group">
            <label>Template</label>
            <nb-select ngModel="replaceTemplate" (ngModelChange)="copy($event)" placeholder="Enter template name">
                <nb-option *ngFor="let t of templates" [value]="t">{{
                t.name
                }}</nb-option>
            </nb-select>
            <!-- <button nbButton status="primary" hero (click)="createRegistrationRun()">Create Recipients</button> -->
            </div>
            <iframe id="iFrameCopyTemplate" srcdoc="" class="iframe" style="min-height: 480px;"></iframe>
        </nb-card-body>
        <nb-card-footer>
        <button nbButton status="secondary" (click)="ref.close()">Close</button>
        <button nbButton status="primary" (click)="replace()">Replace</button>
        </nb-card-footer>
    </nb-card>
</ng-template>