import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { WizardService } from '../../services/wizard.service';
import { IiabIdName, RunRecipientSummary } from '@InfoSlips/models';
import { RecipientService, RunRecipientService } from '@InfoSlips/iiab-api';

enum FilterTypes {
  Name,
  ExternalId,
  Email,
  Mobile,
  CreatedFrom,
  CreatedTo
}

@Component({
  selector: 'ifs-wizard-recipients-form',
  templateUrl: './recipients-form.component.html',
  styleUrls: ['./recipients-form.component.scss']
})
export class RecipientsFormComponent implements OnInit {

  @ViewChild('autoInput') input;
  @ViewChild('customerDropdown') customerLookup;
  @ViewChild('runDropdown') runLookup;
  @Input() data: any;

  isRecipient:boolean;
  recipientName: string;
  recipientExternalId: string;
  recipientEmail: string;
  recipientMobile: string;
  selectedCustomerId: string;
  selectedCustomerName: string;
  runSelectedName: string;
  createdFrom: Date;
  createdTo: Date;

  loadingRecipients$ = of([this.emptySummary()]);
  recipients$: Observable<RunRecipientSummary[]>;
  isLoading$: Observable<boolean>;
  filterObject = {};

  customerSelected = false;
  runSelected = false;
  runTemplateSelected = false;

  constructor(
    private wizardService: WizardService,
    private recipientService: RecipientService,
    private runRecipientService: RunRecipientService
  ) { }

  ngOnInit(): void {
    this.isRecipient = this.data.context==='recipients';
    this.filterObject = {};
    this.loadPagedItems();
  }

  ngAfterViewInit(): void {
    if (this.isRecipient) {
      if (this.wizardService.wizardSearchHistory['Recipient'])  
        this.loadModels(this.wizardService.wizardSearchHistory, 'Recipient');
    } else {
      if (this.wizardService.wizardSearchHistory['RunRecipient'])  
        this.loadModels(this.wizardService.wizardSearchHistory, 'RunRecipient');
    }
  }
  
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.filterRecipients();
  }

  filterRecipients() {
    this.loadPagedItems();

    if (Object.keys(this.filterObject).length !== 0) {
      let additionalOptions = { 
        ...(this.filterObject['customer'] && { customerName: this.selectedCustomerName}),
        ...(this.filterObject['run'] && { runName: this.runSelectedName})
      };
      
      this.wizardService.addSearchHistory(
        this.isRecipient ? "Recipient" : "RunRecipient", 
        this.filterObject, 
        additionalOptions);
    }
  }
  
  buildFilter(value: any, type: FilterTypes){


    if(type!==FilterTypes.CreatedFrom && type!==FilterTypes.CreatedTo && (!value || value.trim()==''))
    {
      this.clearFilter(type);
      return;
    }

    switch (type) {
      case FilterTypes.Name:
        this.filterObject['name'] = { like: value }
        break;

      case FilterTypes.ExternalId:
        this.filterObject['externalId'] =  {eq: value.toLowerCase()}
        break;
      
      case FilterTypes.Email:
        this.filterObject['email'] = {
          eq: value.toLowerCase()
        }
        break;
      case FilterTypes.CreatedFrom:
        if(this.createdFrom!==value)
          this.createdFrom=value;        
        this._applyDateRangeFilter();
        break;
      case FilterTypes.CreatedTo:
        if(this.createdTo!==value)
          this.createdTo=value;        
        this._applyDateRangeFilter();
        break;
    }
  }
  
  cancel(){
    this.wizardService.setState(0);
    this.wizardService.removeSearchHistory(this.isRecipient ? "Recipient" : "RunRecipient");
  }

  clearFilter(type: FilterTypes){
    switch (type) {
      case FilterTypes.Name:
        this.recipientName = '';
        delete this.filterObject['name'];
        break;

      case FilterTypes.ExternalId:
        this.recipientExternalId = '';
        delete this.filterObject['externalId'];
        break;

      case FilterTypes.Email:
        this.recipientEmail = '';
        delete this.filterObject['email'];
        break;
    
      case FilterTypes.Mobile:
        this.recipientMobile = '';
        delete this.filterObject['mobile'];
        break;

      case FilterTypes.CreatedFrom:
        this.createdFrom = null;
        this._applyDateRangeFilter();
        break;

      case FilterTypes.CreatedTo:
        this.createdTo = null;
        this._applyDateRangeFilter();
        break;
      

    }
    if(this.isRecipient){
      this.recipientService.recipientSummaries.loadPagedItems(Object.keys(this.filterObject).length !== 0 ? this.filterObject : null);
    }
    else{
      this.runRecipientService.runRecipientSummaries.loadPagedItems(Object.keys(this.filterObject).length !== 0 ? this.filterObject : null);
    }  
  }

  onCustomerSelected(event){
    if(event === undefined){
      this.customerSelected = false;
      delete this.filterObject['customer'];
    }

    if ((!event) || !event.id)
      return;

    this.selectedCustomerName = event.name;
    this.filterObject['customer'] = {
      id: {
        eq: event.id
      }
    }

    this.customerSelected = true;
  }

  onLookupSelected(event: IiabIdName, model: string){
    if(event === undefined){
      this[model + 'Selected'] = false;
      delete this.filterObject[model]
    }
    
    if ((!event) || !event.id)
      return;

    this[model + 'Selected' + name] = event.name;
    this.filterObject[model] = {
      id: {
        eq: event.id
      }
    }

    this[model + 'Selected'] = true;
  }
  
  hasKeys(obj: any): boolean {
    return obj != null && Object.keys(obj).length > 0;
  }

  private loadPagedItems(){
    if(this.isRecipient){
      if (this.wizardService.wizardSearchHistory['Recipient'])
        this.filterObject = this.wizardService.wizardSearchHistory['Recipient'];

      this.recipientService.recipientSummaries.loadPagedItems(Object.keys(this.filterObject).length !== 0 ? this.filterObject : null);
      this.recipients$ = this.recipientService.recipientSummaries.Items$().pipe(
        exhaustMap((res) => of(res))
      );
      this.isLoading$ = this.recipientService.recipientSummaries.isLoading$;
    }
    else{
      if (this.wizardService.wizardSearchHistory['RunRecipient'])
        this.filterObject = this.wizardService.wizardSearchHistory['RunRecipient'];

      this.runRecipientService.runRecipientSummaries.loadPagedItems(Object.keys(this.filterObject).length !== 0 ? this.filterObject : null);
      this.recipients$ = this.runRecipientService.runRecipientSummaries.Items$().pipe(
        exhaustMap((res) => of(res))
      );
      this.isLoading$ = this.runRecipientService.runRecipientSummaries.isLoading$;
    }
  }

  private _applyDateRangeFilter():void{
    if(!this.createdTo && !this.createdFrom){
      delete this.filterObject['created'];
      return;
    }

    const createdFilter:any={};

    if(this.createdFrom)  
      createdFilter.gte=this.createdFrom.toISOString();
    if(this.createdTo)
      createdFilter.lte=this.createdTo.toISOString();
    
    this.filterObject['created']=createdFilter;
  }

  private emptySummary():RunRecipientSummary{
    return { 
      id: 'Loading..', 
      name: 'Loading..', 
      created:null, 
      createdBy:null, 
      lastUpdated:null, 
      lastUpdatedBy:null, 
      email:null, 
      externalId:null,
      mobile:null, 
      onlineId:null,
      run:null
    };
  }

  private loadModels(searchHistory:any, context: string) {
    if (searchHistory[context].name != null) this.recipientName = searchHistory[context].name.like;
    if (searchHistory[context].externalId != null) this.recipientExternalId = searchHistory[context].externalId.eq;
    if (searchHistory[context].email != null) this.recipientEmail = searchHistory[context].email.eq;
    if (searchHistory[context].created != null) this.createdFrom = searchHistory[context].created.gte;
    if (searchHistory[context].created != null) this.createdTo = searchHistory[context].created.lte;

    if (searchHistory[context].customer != null) {
      const customerReference = { id: searchHistory[context].customer.id.eq, name: searchHistory[`${context}AdditionalOptions`].customerName };
      this.customerLookup?.onItemSelected(customerReference);
    }

    if (searchHistory[context].run != null) {
      const runReference = { id: searchHistory[context].run.id.eq, name: searchHistory[`${context}AdditionalOptions`].runName };
      this.runLookup?.onItemSelected(runReference);
    }
  }
}
