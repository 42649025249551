<nb-card class="preview-container">
    <nb-card-header class="header">
      <nb-icon class="close" (click)="close()" pack="oakmoon" icon="remove"></nb-icon>
      <h3>Preview <span *ngIf="(!(ifsPreview$ | async) && view.ifs) || (!emailPreview && view?.email)">Loading<span [nbSpinner]="true" nbSpinnerStatus="basic" class="spinner">&nbsp;</span></span></h3>
    </nb-card-header>
    <nb-card-body>
      <nb-tabset fullWidth fullHeight>
        <nb-tab *ngIf="recipientConfig?.sms" tabTitle="SMS" [active]="view.sms">
          <h4>SMS Preview</h4>
          <textarea class="smsPreview" readonly [ngModel]="smsText"></textarea>
        </nb-tab>
        <nb-tab *ngIf="recipientConfig?.email" tabTitle="Email" [active]="view.email">
          <p *ngIf="showEmailLoading" [nbSpinner]="true" nbSpinnerStatus="basic" nbSpinnerMessage="Loading Content ..." nbSpinnerSize="tiny">&nbsp;</p>
          <iframe 
            [ngClass]="{'display-none' : showEmailLoading}"
            [srcdoc]="emailPreview" 
            width="100%"
            height="100%"
            (load)="onEmailLoad()"></iframe>
        </nb-tab>
        <nb-tab fullHeight tabTitle="IFS" [active]="view.ifs">
          <button nbButton *ngIf="canDownloadPdf && isPrintable" status="primary" class="pdfDownload" (click)="getPdf()">Download Pdf</button>
          <button nbButton *ngIf="isPrintable" [disabled]="!iframeUrl || showIfsLoading" status="primary" class="printPage" (click)="printPage()">Print Page</button>
          <p *ngIf="!iframeUrl || showIfsLoading" [nbSpinner]="true" nbSpinnerStatus="basic" nbSpinnerMessage="Loading Content ..." nbSpinnerSize="tiny">&nbsp;</p>
          <div class="row h-100" *ngIf="iframeUrl">
            <div class="col-md-2">
              <div class="general-side-nav">
                <div class="context-menu" *ngFor="let tab of tabs" (click)="setMenuItem(tab)" [class.active]="tab.TabName === menuItem">
                  <div class="body">
                      <h4 class="title">{{ tab.TabName }}</h4>
                  </div>
                  <div class="action text-center">
                      <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                  </div>
                </div>
              </div>
            </div>
     
            <div class="col-md-10">
              <iframe
                [src]="iframeUrl | safeIframe"
                id="infoslipIframe"
                #infoslipIframe
                width="100%"
                height="100%"
                (load)="onIfsLoad()"
              ></iframe>
            </div>
          </div>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>