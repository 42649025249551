import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MonacoEditorConfig } from 'libs/shared/src/lib/monacoConfig';
import { ToastrService } from 'ngx-toastr';
import { MiscService, Miscellaneous } from '@InfoSlips/iiab-api';
import { Observable } from 'rxjs';
import { CmsCreateUpdateContentModel } from '@InfoSlips/models';

@Component({
  selector: 'cms-content-handler',
  templateUrl: './content-handler.component.html',
  styleUrls: ['./content-handler.component.scss']
})
export class ContentHandlerComponent implements OnInit {

  @Input() cmsContentModel: CmsCreateUpdateContentModel;
  @Output() saveContent = new EventEmitter();

  cmsContentTypes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('CmsContentType', this.contentEnumFilter);

  isEnabledMonaco = true;
  isEnabledTiny = false;
  selectedContentType = null;
  contentTypes = [];
  editorExtension = '*.*';

  uploadFile = null;

  ngOnInit(): void{    
    this.cmsContentTypes$.subscribe(items=>{
      if(items.length==0)
        return;
      items.forEach(item=>{
        if(item.id==this.cmsContentModel.CmsContentType)
        {
          this.selectedContentType = item;
          this.contentTypeSelected(item, false);
          return;
        }
      });
    });

  }

  constructor(
    private monacoConfig: MonacoEditorConfig,
    public miscService: MiscService,
    private toaster: ToastrService
  ) { 
  }

  contentTypeSelected(type: Miscellaneous, updateModel: boolean = true) {
    this.isEnabledMonaco = false;
    this.isEnabledTiny = false;

    if(updateModel){
      this.cmsContentModel.ContentType = type.value;
      this.cmsContentModel.CmsContentType = type.id;
    }

    const selectedTypeId = type.id.toString();

    switch (selectedTypeId) {
      case '0':
        this.isEnabledTiny = true;
        this.setEditorExtension('html')
        break;
      case '1':
        this.setMonacoLanguage('razor');
        this.isEnabledMonaco = true;
        break;
      case '2':
        this.setMonacoLanguage('razor');
        this.isEnabledMonaco = true;
        break;
      case '3':
        this.setMonacoLanguage('json');
        this.isEnabledMonaco = true;
        break;
      case '4':
        this.setMonacoLanguage('html');
        this.isEnabledMonaco = true;
        break;
      case '5':
        this.setMonacoLanguage('css');
        this.isEnabledMonaco = true;
        break;
      case '6':
        this.setMonacoLanguage('javascript');
        this.isEnabledMonaco = true;
        break;
      case '7':
        this.setMonacoLanguage('plaintext');
        this.isEnabledMonaco = true;
        break;
      case '8':
        this.setMonacoLanguage('plaintext');
        this.isEnabledMonaco = true;
        break;
      case '9':
        this.setMonacoLanguage('plaintext');
        this.isEnabledMonaco = true;
        break;
      case '10':
        this.setMonacoLanguage('plaintext');
        this.isEnabledMonaco = true;
        break;
      case '18':
        this.setMonacoLanguage('markdown');
        this.isEnabledMonaco = true;
        break;
      case '19':
        this.setMonacoLanguage('json');
        this.isEnabledMonaco = true;
        break;
      case '21':
        this.setMonacoLanguage('xml');
        this.isEnabledMonaco = true;
        break;
      case '22':
        this.setMonacoLanguage('xslt');
        this.isEnabledMonaco = true;
        break;
      default:
        this.setMonacoLanguage('plaintext');
        this.isEnabledMonaco = true;
        break;
    }
  }

  setMonacoLanguage(editorLanguage: string) {
    this.setEditorExtension(editorLanguage);
    this.monacoConfig.editorOptions = { ... this.monacoConfig.editorOptions, language: editorLanguage };
  }

  private setEditorExtension(editorLanguage: string) {
    switch (editorLanguage) {
      case 'javascript':
        this.editorExtension = '.js,.razor'
        break;
      case 'plaintext':
        this.editorExtension = '.txt,.csv,.prn,.razor'
        break;
      case 'markdown':
        this.editorExtension = '.md,.razor'
        break;
      default:
        this.editorExtension = `.${editorLanguage},.razor`;
        break;
    }
  }

  fileChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.readFile(fileList[0]);
    }
  }

  readFile(file: File): void {
    this.uploadFile = file;
    
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (e) => {
      this.cmsContentModel.Data = reader.result as string;
    };

    reader.onerror = () => {
      this.toaster.error('File reader error: ' + reader.error);
    };
  }

  private contentEnumFilter(item:Miscellaneous):boolean{
    switch(item.value){
      case 'Image':
      case 'Pdf':
      case 'Compressed':
      case 'Video':
      case 'Video stream':
      case 'Audio':
      case 'Audio stream':
      case 'Theme images':
      case 'Theme palette':
      case 'Font':
      case 'Xml':
        return false;
      default: 
        return true;              
    }
  }

  toggleSource(){
    this.isEnabledMonaco = !this.isEnabledMonaco;
    this.isEnabledTiny = !this.isEnabledMonaco;
  }

}
