import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RunReportService {

  constructor(
    private apiBaseService: ApiBaseService,  
    private toaster: ToastrService
  ){};

  asyncReport(reportId: string) : Observable<any>{
    return new Observable<any>((observer) => {
      this.apiBaseService.executeGetAny<any>(`report/asyncreport/${reportId}`, "Start Report Download", {
        responseType: 'blob', 
        observe: 'response'
      })
      .pipe(tap(this.triggerDownloadEvent))
      .subscribe({
        next: (result) => {
          if (result) {
            this.toaster.success('Report download started!');
            observer.next(result)
          }
        },
        error: error => {
          this.toaster.error(
            'Report download failed.'
          );
          observer.error('Report download failed.')
        }
      });
    });
  }

  triggerDownloadEvent(data:any) {
    let filename = data.headers.get('Content-Disposition') || 'download.zip';
    const foundEq = filename.indexOf('=');
    if (foundEq > -1) {
      filename = filename.substr(foundEq + 1);
    }

    const url = window.URL.createObjectURL(data.body);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }
}
