<div class="scroll">
  <div class="stage-information">
    <ifs-run-stage-card
      class="stage-card"
      *ngFor="let stage of selectedRun?.Instance.ProgressCounters"
      [stage]="stage"
      [selectedRunService]="selectedRunService"
    >
    </ifs-run-stage-card>
  </div>

  <div class="p-10">

    <div class="processed-overview" *ngIf="selectedRun?.Created !== null || selectedRun?.Instance?.Completed !== null || selectedRun?.Instance?.Completed !== null">
        <nb-card *ngIf="selectedRun?.Instance?.Started !== null">
          <nb-card-header>
            Created:
          </nb-card-header>
          <nb-card-body>
            {{ selectedRun?.Created | date: 'MMM dd, yyyy' }}<br />
            {{ selectedRun?.Created | date: 'H:mm:ss' }}
          </nb-card-body>
        </nb-card>
        
        <nb-card *ngIf="selectedRun?.Instance?.Started !== null">
          <nb-card-header>
            Started:
          </nb-card-header>
          <nb-card-body>
            {{ selectedRun?.Instance.Started | date: 'MMM dd, yyyy' }}<br />
            {{ selectedRun?.Instance.Started | date: 'H:mm:ss' }}
          </nb-card-body>
        </nb-card>
        
        <nb-card class="col-md-4" *ngIf="selectedRun?.Instance?.Completed !== null">
          <nb-card-header>
            Completed:
          </nb-card-header>
          <nb-card-body>
            {{ selectedRun?.Instance.Completed | date: 'MMM dd, yyyy' }}<br />
            {{ selectedRun?.Instance.Completed | date: 'H:mm:ss' }}
          </nb-card-body>
        </nb-card>
    </div>


    <p class="title">Recipient Mail Information</p>

    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="row full-width">
            <div class="col-md-4">
              <span>Attachments</span>
            </div>
            <div class="col-md-8 flex">
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="attachment-1"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.MailAttachmentCount }}</span><br />
                <span class="sub-text">Total</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="attachment-1"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.MailStaticAttachmentsCount }}</span><br />
                <span class="sub-text">Static</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="app-window-link"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.MailRunrecipientStaticCount }}</span><br />
                <span class="sub-text">Linked</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="notes-paper-text"></nb-icon>
                <span>{{ 
                  selectedRun?.Instance.Statistics.MailAttachmentCount 
                  - selectedRun?.Instance.Statistics.MailRunrecipientStaticCount 
                  - selectedRun?.Instance.Statistics.MailStaticAttachmentsCount }}</span><br />
                <span class="sub-text">Dynamic</span>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-md-6"><b>Static</b><br /></div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <span>{{ selectedRun?.Instance.Statistics.MailStaticAttachmentsCount }}</span><br />
                </div>
                <div class="col-md-6 text-right">
                  <span>{{ selectedRun?.Instance.Statistics.MailStaticAttachmentSize / 1024 / 1024 | number: '.0-2' }}MB</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6"><b>Auto-linked</b><br /></div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <span>{{ selectedRun?.Instance.Statistics.MailRunrecipientStaticCount }}</span><br />
                </div>
                <div class="col-md-6 text-right">
                  <span>{{ selectedRun?.Instance.Statistics.MailRunrecipientStaticSize / 1024 / 1024 | number: '.0-2' }}MB</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6"><b>Dynamic</b><br /></div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <span>{{ 
                    selectedRun?.Instance.Statistics.MailAttachmentCount 
                    - selectedRun?.Instance.Statistics.MailRunrecipientStaticCount 
                    - selectedRun?.Instance.Statistics.MailStaticAttachmentsCount }}</span><br />
                </div>
                <div class="col-md-6 text-right">
                  <span
                    >{{
                      (
                        selectedRun?.Instance.Statistics.MailAttachmentSize 
                        - selectedRun?.Instance.Statistics.MailRunrecipientStaticSize 
                        - selectedRun?.Instance.Statistics.MailStaticAttachmentSize
                      ) / 1024 / 1024 | number: '.0-2'
                    }}MB</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6"><b>Total</b><br /></div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <span>{{
                    selectedRun?.Instance.Statistics.MailAttachmentCount
                  }}</span
                  ><br />
                </div>
                <div class="col-md-6 text-right">
                  <span
                    >{{
                      selectedRun?.Instance.Statistics.MailAttachmentSize /
                        1024 /
                        1024 | number: '.0-2'
                    }}MB</span
                  >
                </div>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="row full-width">
            <div class="col-md-4">Emails</div>
            <div class="col-md-8 flex">
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="email-send-2"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.MailSentSuccessCounter }}</span><br />
                <span class="sub-text">Success</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="delete"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.MailSentFailCounter }}</span><br />
                <span class="sub-text">Error</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="redo"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.EmailResendCounter }}</span><br />
                <span class="sub-text">Resend</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="email-share"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.EmailForwardCounter }}</span><br />
                <span class="sub-text">Forward</span>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-md-2">
              To:
              {{ selectedRun?.Instance.Statistics.MailToAddressCounter }}
            </div>
            <div class="col-md-2">
              Cc:
              {{ selectedRun?.Instance.Statistics.MailCcAddressCounter }}
            </div>
            <div class="col-md-2">
              Bcc:
              {{ selectedRun?.Instance.Statistics.MailBccAddressCounter }}
            </div>
            <div class="col-md-3">
              Total Emails
              {{ selectedRun?.Instance.Statistics.MailProcessedCounter }}
            </div>
            <div class="col-md-2">
              success
              {{ selectedRun?.Instance.Statistics.MailSentSuccessCounter }}
            </div>
            <div class="col-md-2">
              Failed
              {{ selectedRun?.Instance.Statistics.MailSentFailCounter }}
            </div>
            <div class="col-md-2">
              Resend
              {{ selectedRun?.Instance.Statistics.EmailResendCounter }}
            </div>
            <div class="col-md-2">
              Forward
              {{ selectedRun?.Instance.Statistics.EmailForwardCounter }}
            </div>
            <div class="col-md-3">
              Not Enabled
              {{ selectedRun?.Instance.Statistics.MailDisabledCount }}
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>

    <p class="title">Other Delivery Information</p>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="row full-width">
            <div class="col-md-4">SMS</div>
            <div class="col-md-8 flex">
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="email-send-2"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.SmsTotalSent - selectedRun?.Instance.Statistics.SmsFailed }}</span><br />
                <span class="sub-text">Success</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="delete"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.SmsFailed }}</span><br />
                <span class="sub-text">Error</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="redo"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.SmsResendCounter }}</span><br />
                <span class="sub-text">Resend</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="email-share"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.SmsForwardCounter }}</span><br />
                <span class="sub-text">Forward</span>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-md-2">
              Total SMS:
              {{ selectedRun?.Instance.Statistics.SmsTotalRecipients }}
            </div>
            <div class="col-md-2">
              Success:
              {{ selectedRun?.Instance.Statistics.SmsTotalSent - selectedRun?.Instance.Statistics.SmsFailed }}
            </div>
            <div class="col-md-2">
              Failed:
              {{ selectedRun?.Instance.Statistics.SmsFailed }}
            </div>
            <div class="col-md-2">
              Resend:
              {{ selectedRun?.Instance.Statistics.SmsResendCounter }}
            </div>
            <div class="col-md-2">
              Forward:
              {{ selectedRun?.Instance.Statistics.SmsForwardCounter }}
            </div>
            <div class="col-md-2">
              Not Enabled:
              {{ selectedRun?.Instance.Statistics.SmsDisabledCount }}
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="row full-width">
            <div class="col-md-4">
              <span>Export</span>
            </div>
            <div class="col-md-8 flex">
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="attachment-1"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.ExportPdfCount }}</span><br />
                <span class="sub-text">Total</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="delete"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.ExportPdfFail }}</span><br />
                <span class="sub-text">Error</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="notes-paper-text"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.ExportPdfPages }}</span><br />
                <span class="sub-text">Pages</span>
              </div>
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="database"></nb-icon>
                <span>{{ selectedRun?.Instance.Statistics.ExportPdfSize / 1024 / 1024 | number: '.0-2' }}MB</span><br />
                <span class="sub-text">Size</span>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-md-2">
              <b>PDF Sent</b>
            </div>
            <div class="col-md-2 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportPdfCount }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <b>PDF Pages</b>
            </div>
            <div class="col-md-2 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportPdfPages }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <b>PDF Size</b>
            </div>
            <div class="col-md-2 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportPdfSize / 1024 / 1024 | number: '.0-2' }}MB</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <b>Not Enabled</b>
            </div>
            <div class="col-md-2 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportDisabledCount }}</span>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <div class="row full-width">
            <div class="col-md-4">Recipients</div>
            <div class="col-md-8 flex">
              <div class="col-md-2">
                <nb-icon pack="oakmoon" icon="users-multiple"></nb-icon>
                <span></span><br />
                <span class="sub-text">Total</span>
              </div>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-md-6">
              <b>New Recipients:</b>
            </div>
            <div class="col-md-6 text-right">
              <span>{{ selectedRun?.Instance.Statistics.NewRecipientCount }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b>Remaining Verification: </b>
            </div>
            <div class="col-md-6 text-right">
              <span>{{ selectedRun?.TotalToVerify }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b>Export:</b>
            </div>
            <div class="col-md-6 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportIfsCount + selectedRun?.Instance.Statistics.ExportPdfCount }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b>Export fail:</b>
            </div>
            <div class="col-md-6 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportIfsFail + selectedRun?.Instance.Statistics.ExportPdfFail }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b>Export Size: </b>
            </div>
            <div class="col-md-6 text-right">
              <span>{{ selectedRun?.Instance.Statistics.ExportIfsSize + selectedRun?.Instance.Statistics.ExportPdfSize }}</span>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </div>
</div>
