import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { PageGraphQLData, DomainReference, DomainModel } from '@InfoSlips/models';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TestingRestrictionsService {

  domains: ObservableWrapper<DomainReference>;

  selectedDomainSubject = new BehaviorSubject<DomainModel>(null);
  selectedDomain$ = this.selectedDomainSubject.asObservable();
  
  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.domains = new ObservableWrapper<DomainReference>(
      this.apiBaseService,
      this._pageDomainsQuery(),
      this._fetchDomainsData,
    false);

    this.domains.loadPagedItems();
  }

  private _pageDomainsQuery() {
    return `query TestingRestrictions($where: AllowedEmailFilterInput = null) {
      testingRestrictions(where: $where, order: {created: DESC}) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            allowAllOnOnDomain
            created
            createdBy
            domain
            emailAddress
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }`
  }

  private _fetchDomainById(rawData: any): DomainReference {
    try {
        return rawData.data.testingRestrictionsById as DomainReference;
    }
    catch (e) {
        console.log('domainByIdQuery That FAILED', rawData);
        return null;
    }
  }
  private _domainByIdQuery(domainId: string): string {
    return `query getTestingRestrictionsById {
      testingRestrictionsById(id: "${domainId}") {
        id
        allowAllOnOnDomain
        created
        createdBy
        domain
        emailAddress
        lastUpdated
        lastUpdatedBy
      }
    }`
  }

  private _fetchDomainsData(rawData: any): PageGraphQLData<DomainReference> {
    try{
      return rawData.data.testingRestrictions as PageGraphQLData<DomainReference>;
    }
    catch(e)
    {
      console.log("domainData That FAILED", rawData);
      return null;
    }
  }

  async getDomainById(domainId: string) {
    const model = {
      query: this._domainByIdQuery(domainId),
      operationName: 'getTestingRestrictionsById'
    }

    return await this.apiBaseService.executeGQLPost(model, 'Get Testing Restriction Domain by ID.').pipe(map(res =>  {
      return this._fetchDomainById(res);
    }))
    .toPromise();
  }

  createDomain(domain: DomainModel){
    domain.EmailAddress = domain?.EmailAddress?.filter(address => address.trim() != '');
    this.apiBaseService.executePost<DomainModel>('TestingRestrictions', domain, 'Create Testing Restriction Domain').subscribe((res: any) => {
      if(res){
        this.toastr.success('Domain Created!');
        this.selectDomain(res);
      }
    });
  }

  updateDomain(domain: DomainModel){
    domain.EmailAddress = domain.EmailAddress.filter(address => address.trim() != '');
    this.apiBaseService.executePut<DomainModel>('TestingRestrictions', domain, 'Update Testing Restriction Domain').subscribe((res: any) => {
      if(res){
        this.toastr.success('Domain Updated!');
        this.selectDomain(res);
      }
    });
  }

  selectDomain(domain: DomainModel){
    this.selectedDomainSubject.next(domain);
  }

  deleteTestingDomain(domainId: string){
    this.apiBaseService.executeDelete<boolean>(`TestingRestrictions/${domainId}`, 'Delete Testing Restriction Domain').subscribe((res) => {
      if(res){
        this.toastr.info("Testing Restriction Domain Deleted!");
        this.domains.loadPagedItems();
      }
      else {
        this.toastr.error("Testing Restriction Domain Delete Failed!");
      }
    })
  }
}