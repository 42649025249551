<nb-card>
  <nb-card-header>
    <p class="pull-left title" data-e2e="cardTitle">
      Create account
    </p>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="registerConfirmForm" (ngSubmit)="onSubmit()" class="login">
      <div class="form-body row">
        <div class="col-md-12">
          <nb-form-field>
            <label>Name & Surname</label>
            <input nbInput class="custom-input" fullWidth type="text" formControlName="displayname" />
          </nb-form-field>
    
          <nb-form-field>
            <label>Username</label>
            <input nbInput class="custom-input" fullWidth type="text" formControlName="username" />
          </nb-form-field>
    
          <ng-container *ngIf="username.invalid && username.touched">
            <p class="caption status-danger" *ngIf="username.errors?.required">
              Username is required!
            </p>
          </ng-container>
    
          <nb-form-field>
            <label>Password</label>
            <input nbInput class="custom-input" type="password" fullWidth formControlName="password" />
            <button nbSuffix nbButton ghost [nbPopover]="passwordRulesTemplate" nbPopoverPlacement="end" nbPopoverTrigger="click" >
              <nb-icon nbSuffix icon="question-mark-outline" pack="eva"></nb-icon>
            </button>
          </nb-form-field>
    
          <ng-container *ngIf="password.invalid && password.touched">
            <p class="caption status-danger" *ngIf="password.errors?.required">
              Please enter a password. Password must contain 10 characters and must contain a special character, a uppercase character, a lowercase character and a digit.
            </p>
          </ng-container>
    
          <nb-form-field>
            <label>Confirm password</label>
            <input nbInput class="custom-input" type="password" fullWidth formControlName="confirmPassword" />
            <button nbSuffix nbButton ghost [nbPopover]="passwordRulesTemplate" nbPopoverPlacement="end" nbPopoverTrigger="click" >
              <nb-icon nbSuffix icon="question-mark-outline" pack="eva"></nb-icon>
            </button>
          </nb-form-field>
    
          <label><p>Country</p></label>
          <nb-select formControlName="country" fullWidth>
            <nb-option *ngFor="let c of countries" [value]="c.Id">{{
              c.Name
              }}</nb-option>
          </nb-select>
    
          <nb-form-field class="mt-20">
            <label>Mobile Number</label>
            <input nbInput class="custom-input" fullWidth type="text" formControlName="mobile" />
          </nb-form-field>
    
          <nb-form-field>
            <label>Email</label>
            <input nbInput class="custom-input" fullWidth type="text" formControlName="email" />
          </nb-form-field>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="form-footer">
      <button [nbSpinner]="loading" nbSpinnerStatus="info" [disabled]="registerConfirmForm.invalid || !passwordValidCheck()" nbButton
        fullWidth status="primary" data-e2e="submit" type="submit" (click)="onSubmit()">
        Sign in
      </button>
    </div>
  </nb-card-footer>
</nb-card>



<ng-template #passwordRulesTemplate>
  <div class="password-text">
    <p><b>Password Requirements:</b></p>
    <ol>
        <li *ngFor="let rule of passwordRules">
            <p>{{ rule.label }} <b class="password-rule">{{ rule.value }}</b> {{ rule.matched ? "✅" : "❌"}}</p>
        </li>
    </ol>  
  </div>
</ng-template>