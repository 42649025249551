import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';
import { Bookmark, BookmarkContext, IIABCacheKey } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  recentsSubject = new BehaviorSubject<Bookmark[]>(this.localStorageService.get<Bookmark[]>(IIABCacheKey.Recents, true));
  recents$ = this.recentsSubject.asObservable();

  starsSubject = new BehaviorSubject<Bookmark[]>(this.localStorageService.get<Bookmark[]>(IIABCacheKey.Stars, true));
  stars$ = this.starsSubject.asObservable();

  isStarSubject = new BehaviorSubject<Bookmark>(null);
  isStar$ = this.isStarSubject.asObservable();

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private localStorageService: IiabLocalStorageService
  ) { }

  createBookmark(context: BookmarkContext, bookmarkName: string, route: any){
    if(this.router.url === '/'){
      return;
    }

    const isRecent = context === BookmarkContext.star ? false : true;

    const key = !isRecent ? 
      IIABCacheKey.Stars:
      IIABCacheKey.Recents

    const bookmarkArray: Bookmark[] = this.localStorageService.get<Bookmark[]>(key,true) || [];
    const exists = this.exists(bookmarkArray);
    
    if(exists > -1){
      bookmarkArray.splice(exists, 1);
    }

    bookmarkArray.unshift({
      name: bookmarkName,
      context: route.snapshot.data['context'],
      icon: route.snapshot.data['icon'],
      route: this.router.url,
      isStar: context === BookmarkContext.star ? true : false
    });

    if (bookmarkArray.length >= 11) bookmarkArray.pop();    
    
    this.localStorageService.set<Bookmark[]>(key, bookmarkArray, true);

    if (!isRecent){
      this.isStarSubject.next(bookmarkArray[0]);
      this.starsSubject.next(bookmarkArray);
    } else {
      this.recentsSubject.next(bookmarkArray)
    }
  }

  exists(array: Bookmark[]): number{
    if(array.length > 0){
      const pos = array.findIndex(bookmark => {
        return bookmark.route === this.router.url;
      });
      return pos > -1 ? pos : -1;
    } else {
      return -1;
    }
  }

  removeExists(array: Bookmark[]){
    if(array.length > 0){
      
      const exists = array.filter(bookmark => {
        return bookmark.route === this.router.url;
      });

      return exists.length > 0 ? 
        array.filter(bookmark => { 
          if(bookmark.route != this.router.url){
            return bookmark;
          }
          else{
            return null;
        }
      }) : array;
    } 
    else {
      return array
    }
  }

  
  favorite(item: Bookmark){

    let favoritesArray: Bookmark[] =  this.localStorageService.get<Bookmark[]>(IIABCacheKey.Stars, true)  || [];
    let recentsArray: Bookmark[] = this.localStorageService.get<Bookmark[]>(IIABCacheKey.Recents, true) || [];

    const exists = favoritesArray.filter(bookmark => {
      return bookmark.route === item.route;
    }).length > 0 ? true : false

    favoritesArray = this.removeExists(favoritesArray);
    
    if(!exists){
      item.isStar = true;
      favoritesArray.unshift(item);

      recentsArray.map(bookmark => {
        if(bookmark.route === item.route){
          bookmark.isStar = true;
        }
      });
      
      item.isStar = true;
      this.isStarSubject.next(item);

      this.localStorageService.set<Bookmark[]>(IIABCacheKey.Recents, recentsArray, true);
      this[`recentsSubject`].next(recentsArray);
      this.toastr.success("Item added to favorites.")
    } else {
      favoritesArray = favoritesArray.filter(bookmark => {
        return bookmark.route !== item.route;
      });

      recentsArray.map(bookmark => {
        if(bookmark.route === item.route){
          bookmark.isStar = false;
        }
      });
      item.isStar = false;
      this.isStarSubject.next(item);

      this.localStorageService.set<Bookmark[]>(IIABCacheKey.Recents, recentsArray, true);

      this[`recentsSubject`].next(recentsArray);
      this.toastr.info("Item removed from favorites.")
    }

    favoritesArray.length === 11 ? 
      favoritesArray.shift() : 
      undefined;
    
      this.localStorageService.set<Bookmark[]>(IIABCacheKey.Stars, favoritesArray, true);

      this[`starsSubject`].next(favoritesArray);
    
  }

  isStar(route: string){
    let favorites = this.localStorageService.get<Bookmark[]>(IIABCacheKey.Stars, true) || [];
    
    try {
      favorites = favorites.filter((favorite: Bookmark) => favorite.route === route);

      const itemFavorite = favorites && favorites.length>0?
        favorites[0]:
        {
          isStar: false, 
          name:'',
          context:'',
          icon: '',
          route:''          
        };

      this.isStarSubject.next(itemFavorite);

      return favorites[0] || {
        isStar: false
      };
    } catch {
      return {
        isStar: false
      };
    }
  }

  removeRun(runId: string): void{
    this.handleRemoveRun(runId, IIABCacheKey.Recents);
    this.handleRemoveRun(runId, IIABCacheKey.Stars);
  }

  handleRemoveRun(runId: string, context: IIABCacheKey): void{
    const updatedBookmarks = this.localStorageService.get<Bookmark[]>(context, true).filter(
      item => item.route.split('/run/')[1] !== runId
    );

    this.localStorageService.set<Bookmark[]>(context, updatedBookmarks, true);
    context === IIABCacheKey.Recents ? this.recentsSubject.next(updatedBookmarks) : this.starsSubject.next(updatedBookmarks);
  }

  clear(context: BookmarkContext){
    const key = context===BookmarkContext.star?
      IIABCacheKey.Stars:
      IIABCacheKey.Recents; 

    this.localStorageService.set<Bookmark[]>(key, [], true);

    context === BookmarkContext.recent ? 
      this.recentsSubject.next([]) : 
      this.starsSubject.next([]);
  }


}
