import { Component, OnInit} from '@angular/core';
import { RunQueueStatus, PageGraphQLData } from '@InfoSlips/models';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'ifs-admin-queue-status',
  templateUrl: './queue-status.component.html',
  styleUrls: ['./queue-status.component.scss']
})
export class QueueStatusComponent implements OnInit {

  public runQueueStatus$: Observable<RunQueueStatus>;

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService
  ) { 
  }

  ngOnInit(): void {
    this.runQueueStatus$ = this._getQueueStatus();
  }

  executeQueueStatusCommandPost(endpoint: string){
    const headers = new HttpHeaders().set('content-type', 'text/json');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    this.apiBaseService.executePost<string>(`QueueStatus/ExecuteCommand`, JSON.stringify(endpoint), `Execute command: ${endpoint}`, false, requestOptions)
      .subscribe((response: string) => {
        response ? this.toastr.info(`Executed command ${endpoint}.`) : this.toastr.error(`Failed to execute command: ${endpoint}.`)
      }
    );
  }

  //#region Private

  private _getQueueStatus(){
    const subject = new Subject<any>();
    
    const model = {
      query: this._runQueueStatusQuery(),
      operationName: 'RunQueueStatus'
    }
    
    this.apiBaseService.executeGQLPost(model, 'Get Run Queue Status.').subscribe(async (res: any) => {
      subject.next(await this._fetchQueueStatusData(res));
    })

    return subject.asObservable();
  }

  private _fetchQueueStatusData(rawData: any): Promise<PageGraphQLData<RunQueueStatus>> {
    try{
      return new Promise(resolve => {
        resolve(rawData.data.runQueueStatus as PageGraphQLData<RunQueueStatus>)
      })
    }
    catch(e)
    {
      console.log("runQueueStatus FAILED", rawData);
      return null;
    }
  }

  private _runQueueStatusQuery() {
    return `query RunQueueStatus {
      runQueueStatus {
        ipAddress,
        applianceType,
        runSpecificQueues {
          runId,
          publisher {
            isEnabled,
            hasConnection,
            lastActivity,
            publisherOutstandingConfirms
          },
          subscriber {
            isEnabled,
            hasConnection,
            lastActivity,
            subscriberActiveMessages
          },
        },
        runActivityHistory{
          lastReceivedDateTime,
          lastSentDateTime,
          lastReceivedMessages,
          lastSentMessages
        },
        stageActivityHistory{
        lastReceivedDateTime,
        lastSentDateTime,
        lastReceivedMessages,
        lastSentMessages
      },
      pdfGenerationStatus {
          waitingToBeProcessedCount,
          keysWaitingToBeProcessed,
          createQueue {
            publisher {
              isEnabled,
              hasConnection,
              lastActivity,
              publisherOutstandingConfirms
            },
            subscriber {
              isEnabled,
              hasConnection,
              lastActivity,
              subscriberActiveMessages
            }
          },
          processedQueue  {
            publisher {
              isEnabled,
              hasConnection,
              lastActivity,
              publisherOutstandingConfirms
            },
            subscriber {
              isEnabled,
              hasConnection,
              lastActivity,
              subscriberActiveMessages
            }
          }
        }
      }
    }`;
  }
  
  //#endregion
}