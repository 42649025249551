<div class="general-page-container">
  <div class="general-page-header">
    <div class="d-flex">
        <div class="flex-1">
          <h4 class="heading">{{ editEntity ? "Update" : "Configure"}} Entity {{entityType?.charAt(0).toUpperCase() + entityType?.slice(1)}}</h4>
        </div>
        <div class="text-right">
          <span class="invalid-field">{{invalidField}}</span>
          <button size="medium" outline nbButton [routerLink]="lastUrl ? lastUrl : '../../'">Cancel</button>
          <button [disabled]="disableSave()" size="medium" class="save" nbButton status="primary" (click)="entityType === 'content' ? saveCmsContent(dialog) : saveCmsFile(dialog)">Save</button>
        </div>
    </div>
  </div>
</div>

<nb-card class="card-container">
  <nb-card-body>
    <div class="flex">
      <div class="scroll-nav">
        <div class="general-side-nav">
          <div class="context-menu" *ngFor="let option of options; let i = index" (click)="setMenuItem(option.title)"
            [class.active]="option.title === menuItem">
            <div class="body">
              <h4 class="title">{{ option.title }}</h4>
            </div>
            <div class="action text-center">
              <!-- <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon> -->
              <nb-toggle 
              [status]="option.title === menuItem ? 'control' : 'primary'" 
              *ngIf="option.hasToggle && !option.isRootLevel" 
              [(ngModel)]="template[option.model].IsEnabled">
            </nb-toggle>

            <nb-toggle 
            [status]="option.title === menuItem ? 'control' : 'primary'" 
            *ngIf="option.title === 'CMS'" 
            [(ngModel)]="template.UseCms" disabled>
          </nb-toggle>

            <nb-toggle 
              [status]="option.title === menuItem ? 'control' : 'primary'" 
              *ngIf="option.hasToggle && option.isRootLevel && option.title === 'Pre-Processor'" 
              [(ngModel)]="isPreProcEnabled"
              (checkedChange)="preProcEnabled($event)">
            </nb-toggle>
            </div>
          </div>

          <div class="toggles">
            <nb-toggle class="toggle" [(ngModel)]="cmsEntityModel.IsDraft">
              <p>Is Draft?</p>
            </nb-toggle>
          </div>
        </div>
      </div>
      

      <div [ngSwitch]="menuItem" class="content">
        <ng-template [ngSwitchCase]="'Overview'">
          <div class="flex details-content">
            <nb-card class="input-box section-card">
              <nb-card-header>
                Entity Details
              </nb-card-header>
              <nb-card-body class="padded">
                  <label><p>Name</p></label>
                  <input autofocus  [disabled]="editEntity" nbInput fullWidth type="text" fullWidth name="entityName" placeholder="Entity Name" required [(ngModel)]="cmsEntityModel.Name" (blur)="checkDisplay()" />
          
                  <label><p>Display Name</p></label>
                  <input nbInput fullWidth type="text" fullWidth name="entityDisplay" placeholder="Entity Display Name" required [(ngModel)]="cmsEntityModel.Display" />
          
                  <label><p>Tags</p></label>
                  <nb-tag-list (tagRemove)="onTagRemove($event)">
                      <nb-tag *ngFor="let tag of tags" [text]="tag" removable></nb-tag>
                      <input type="text" nbTagInput (tagAdd)="onTagAdd($event)" fullWidth>
                  </nb-tag-list>
                
                  <label><p>Notes</p></label>
                  <textarea name="entityNotes" placeholder="Notes" nbInput fullWidth [(ngModel)]="cmsEntityModel.Notes"></textarea>
                  <!-- <ng-container *ngIf="editEntity">
                    <label><p>Version</p></label>
                    <nb-select placeholder="Select Version" [selected]="cmsEntityModel.Version" [compareWith]="compareByVersion" (selectedChange)="selectVersion($event)">
                      <nb-option *ngFor="let revision of cmsRevisions$ | async" [value]="revision.version">{{ revision.version }}</nb-option>
                    </nb-select>
                  </ng-container> -->
              </nb-card-body>
          </nb-card>

          <nb-card class="input-box section-card">
            <nb-card-header>
              Security
            </nb-card-header>
            <nb-card-body class="padded">
              <label><p>Scope</p></label>
              <nb-select fullWidth placeholder="Select Entity Scope" [(ngModel)]="entitySecurity" [(selected)]="entitySecurity">
                <nb-option [value]="0" [disabled]="!authService.isSystemAdmin">Global Public</nb-option>
                <nb-option [value]="1">Global Secure</nb-option>
                <nb-option [value]="2">Secure</nb-option>
              </nb-select>


              <div class="lookup-input" *ngIf="entitySecurity === 2">
                <label>
                  <p>Customer</p>
                </label>
                <ifs-customer-lookup [showClear]="true" [showLabel]="false" (ItemSelected)="onCustomerSelected($event)"></ifs-customer-lookup>
              </div>
      
              <div class="lookup-input" *ngIf="entitySecurity === 2">
                <label>
                    <p>Run Template</p>
                </label>
                <ifs-run-template-lookup [showClear]="true" [autoSelect]="false" [showLabel]="false" (ItemSelected)="onRunTemplateSelected($event)"></ifs-run-template-lookup>
              </div>

              <div class="lookup-input" *ngIf="entitySecurity === 1">
                <label>
                  <p>Customer Group</p>
                </label>
                <ifs-customer-group-lookup [showClear]="true" [showLabel]="false" (ItemSelected)="onCustomerGroupSelected($event)"></ifs-customer-group-lookup>
              </div>
            </nb-card-body>
            <nb-card-footer>
              <p *ngIf="entitySecurity === 0">
                This entity will be available for use by anyone.
              </p>

              <p *ngIf="entitySecurity === 1">
                This entity will only be available for the specified customer group.
              </p>

              <p *ngIf="entitySecurity === 2">
                This entity will only be available for the specified customer or run template.
              </p>
            </nb-card-footer>
        </nb-card>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="entityType === 'content' ? 'Content' : 'File'">
          <cms-content-handler *ngIf="entityType === 'content'" [(cmsContentModel)]="cmsEntityModel" (saveContent)="saveCmsContent()"></cms-content-handler>
          <cms-file-handler *ngIf="entityType === 'file'" [(cmsContentModel)]="cmsEntityModel" (cmsUploadFile)="setFileName($event)" [loadedFile]="cmsUploadFile"></cms-file-handler>
        </ng-template>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="dialog-card">
    <nb-card-header class="dialog-header">
      <p>{{data.title}}</p>
  </nb-card-header>
    <nb-card-body>
      <p>{{data.body}}</p>
    </nb-card-body>
    <nb-card-footer>
      <button fullWidth nbButton (click)="dialogAction('home')">Home</button>
      <button fullWidth nbButton (click)="dialogAction('Yes')" status="primary">Yes</button>
    </nb-card-footer>
  </nb-card>
</ng-template>