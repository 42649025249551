import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { EnvironmentService } from '@InfoSlips/env';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpOptions } from './http-options';

enum SortTypes {
  ASC,
  DESC
}
interface RunTemplateSortInput {
  lastUpdated?: SortTypes;
  runTemplateName?: SortTypes;
}

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {

  constructor(
    private apollo: Apollo,
    private environment: EnvironmentService,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  //#region REST

  executeGet<T>(relativeUrl: string, requestTitle: string, headers?: HttpOptions): Observable<T> {
    return this.http.get<T>(`${this.environment.apiUrl}api/${relativeUrl}`, headers).pipe(
      catchError(error => {
        return this.handleError(error, requestTitle);
      }));
  }
  executeGetAny<T>(relativeUrl: string, requestTitle: string, headers?: any): Observable<HttpEvent<T>> {
    return this.http.get<T>(`${this.environment.apiUrl}api/${relativeUrl}`, headers).pipe(
      catchError(error => {
        return this.handleError(error, requestTitle);
      }));
  }

  executeDelete<T>(relativeUrl: string, requestTitle: string, headers?: HttpOptions): Observable<T> {
    return this.http.delete<T>(`${this.environment.apiUrl}api/${relativeUrl}`, headers).pipe(
      catchError(error => {
        return this.handleError(error, requestTitle);
      }));
  }

  executePut<T>(relativeUrl: string, model: any, requestTitle: string): Observable<T> {
    return this.http.put<T>(`${this.environment.apiUrl}api/${relativeUrl}`, model).pipe(
      catchError(
        error => {
          this.handleError(error, requestTitle);
          return of(error as T);
        }
      )
    );
  }

  executePost<T>(relativeUrl: string, model: any, requestTitle: string, suppressError?: boolean, headers?: HttpOptions): Observable<T> {
    return this.http.post<T>(`${this.environment.apiUrl}api/${relativeUrl}`, model, headers).pipe(
      catchError(
        error => {
          this.handleError(error, requestTitle, suppressError);
          return of(undefined as T);
        }
      )
    );
  }
  executePostAny<T>(relativeUrl: string, model: any, requestTitle: string, suppressError?: boolean, headers?: any): Observable<HttpEvent<T>> {
    return this.http.post<T>(`${this.environment.apiUrl}api/${relativeUrl}`, model, headers).pipe(
      catchError(
        error => {
          return this.handleError(error, requestTitle,suppressError);
        }
      )
    );
  }
  
  executePostWithProgress<T>(relativeUrl: string, model: any, requestTitle: string, progress?:BehaviorSubject<number>,suppressError?: boolean): Observable<T> {
       
    const options = {
      reportProgress:true,
      observe: 'events',
      reposeType: 'json'
    }

    const result = new BehaviorSubject<T>(null); 
    const req = new HttpRequest('POST', `${this.environment.apiUrl}api/${relativeUrl}`, model, options);

     // Use a subject to keep track of the status
     progress = progress ?? new BehaviorSubject<number>(0);


    this.http.request(req).pipe(
      catchError(
        (error) => {
          return this.handleError(error, requestTitle, suppressError);
        }
      )
    ).subscribe((event) => {

      if (event.type === HttpEventType.UploadProgress) {        
        // Report progress
        progress.next(Math.round(100 * event.loaded / event.total));
      } else if (event.type === HttpEventType.Response) {
        // Report progress and complete the Observable
        progress.next(100);
        progress.complete();
        result.next(event.body as T);
      }
    });

    return result.asObservable();
  }

  executePostProgress<T>(relativeUrl: string, model: any, requestTitle: string, suppressError?: boolean) {
    const req = new HttpRequest('POST', `${this.environment.apiUrl}api/${relativeUrl}`, model, {
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(
        (error) => {
          return this.handleError(error, requestTitle, suppressError);
        }
      )
    );
  }


  //#endregion

  //#region GRAPHQL

  executeGQLPost<T>(model: any, requestTitle: string, surpressError?: boolean, headers?: HttpOptions): Observable<T> {
    return this.http.post<T>(`${this.environment.apiUrl}graphql`, model, headers).pipe(
      catchError(
        (error) => {
          return this.handleError(error, requestTitle, surpressError);
        }
      )
    );
  }

  getGraphQLPagedArray<T>(
    queryString: string,
    vars: any): any {
      
    const queryNode = gql(queryString)

    return this.apollo
      .query<any>(
        {
          query: queryNode,
          variables: vars,
          fetchPolicy: 'network-only'
        })
      .pipe();
  }

  // getGraphQLPagedArraySorted<T>(
  //   queryString: string,
  //   pageSize: number = 10,
  //   afterCursor: string = null,
  //   where: any = null,
  //   order: any = {}): any {

  //   let sortObject;
  //   const { lastUpdated, runTemplateName } = order;
  //   console.log("lastUpdated", lastUpdated);
  //   console.log("runTemplateName", runTemplateName);
  
  
  //   if(lastUpdated){
  //     sortObject = `{ lastUpdated: ${lastUpdated === SortTypes.DESC ? 'DESC': 'ASC'} }`
  //   }
  
  //   if(runTemplateName){
  //     sortObject = `{ name: ${runTemplateName === SortTypes.DESC ? 'DESC': 'ASC'} }`
  //   }

  //   console.log(sortObject);
  //   if(!sortObject){
  //     sortObject = { name: "DESC" }
  //   }
    

  //   const queryNode = gql(queryString)
  //   const vars: any = {
  //     first: pageSize,
  //     afterCursor: afterCursor,
  //     where: where
  //   };

  //   Object.keys(order).length !== 0 ? vars['order'] = order : undefined;

  //   return this.apollo
  //     .query<any>(
  //       {
  //         query: queryNode,
  //         variables: vars,
  //         fetchPolicy: 'network-only'
  //       })
  //     .pipe();
  // }

  getGraphQLQuery(queryString: string): any {
    const queryNode = gql(queryString);
    return this.apollo
      .query<any>(
        {
          query: queryNode,
          fetchPolicy: 'network-only'
        })
      .pipe();
  }

  //#endregion

  //#region Private

  private handleError(error: any, title: string, suppressError?: boolean): Observable<never> {
    if(error){
      const errorString = JSON.stringify(error).toLocaleLowerCase();
      if(errorString.includes('unauthorized') || errorString.includes('401')){
        console.log('Unauthorized-Logout', error);
        window.location.replace('/#/auth/logout'); //@nico - Will this work?
      }
    }
    
    if (suppressError) {
      return throwError(error);
    }

    if (error.error instanceof ErrorEvent) {
      this.toastr.error(`An error has occurred:${error.error.message}`, title ?? error.error.message);
    } else {
      console.table({
        "Title": title,
        "Debug": error.error
      })
      this.toastr.error(`Error code: ${error.status}`, `${JSON.stringify(error.error.Message)}`);
    }
    return throwError(error);
  }
  //#endregion
}