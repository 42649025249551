import { BaseEntityIdModel, BaseEntityIdNameModel } from "./BaseEntity";

export interface RegisterModel {
  country: string;
  register: string;
}
export interface RegisterActivation extends BaseEntityIdModel {
  Type: number;
  ExpiredDate: string;
  Token: string;
  AccessedDate: null;
  Data: RegisterActivationData;
}
export interface RegisterActivationData {
  UserId: string;
  RegistrationId: string;
  Name: string;
  Email: string;
  Mobile: string;
  RegistrationType: number;
}

export interface Country extends BaseEntityIdNameModel {
  CallingCode: string[];
}

export interface IPInfo {
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  postal: string;
  org: string;
}
export interface UserRegistrationModel {
  Token: string;
  UserName: string;
  Email: string;
  Mobile: string;
  Password: string;
  DisplayName: string;
  Country: string;
  DateOfBirth: string;
}

