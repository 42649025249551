import { Injectable, OnDestroy } from '@angular/core';
import * as models from '@InfoSlips/models';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { CreateRunModel, RunModel,  RunTemplateModel} from '@InfoSlips/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { AuthService } from '../../../../iiab-auth/src/lib/services/auth.service';
import { fetchRunRecipientsSummaryData, _fetchProcessorAuditLogData, _fetchProgressCountersData, _fetchRunCustomersData, _fetchRunsData, _fetchRunSearchData, _fetchRunSummaryData, _fetchRunTemplatesData, _pagedRunRecipientSummariesQuery, _pagedRunsQuery, _pagedRunSummariesQuery, _pageRunCustomersQuery, _pageRunSearchData, _pageRunTemplatesQuery, _recentRunsSubQuery } from './graphql/run-queries';
import { RunFilterService } from './../../../../dashboard/src/lib/services/run-filter.service';

@Injectable({
  providedIn: 'root'
})

export class RunsService implements OnDestroy {

  public runs: ObservableWrapper<models.IiabIdName>;
  public runSummaries: ObservableWrapper<models.RunSummary>;
  public runRecipientSummaries: ObservableWrapper<models.RunRecipientSummary>;
  public runTemplates: ObservableWrapper<models.IiabIdName>;
  public runCustomers: ObservableWrapper<any>;
  public runSearchData: ObservableWrapper<models.RunTrialRecipientSummary>;

  public recipientList: any = [];
  selectedRunSubject = new BehaviorSubject<RunModel>(null);
  selectedRun$ = this.selectedRunSubject.asObservable();
  selectedRun:any;

  public loadingRuns = true;

  runSummariesPerPage = 10;

  recentRunSubscription: Subscription

  constructor(
    private apiBaseService: ApiBaseService,
    private router: Router, 
    private apollo:Apollo,
    private authService: AuthService,
    private RunFilterService: RunFilterService) {

    this.runs = new ObservableWrapper<models.IiabIdName>(
      this.apiBaseService,
      _pagedRunsQuery(),
      _fetchRunsData,
    false);

    this.runSummaries = new ObservableWrapper<models.RunSummary>(
      this.apiBaseService,
      _pagedRunSummariesQuery(),
      _fetchRunSummaryData,
    false);

    this.runRecipientSummaries = new ObservableWrapper<models.RunRecipientSummary>(
      this.apiBaseService,
      _pagedRunRecipientSummariesQuery(),
      fetchRunRecipientsSummaryData,
    false);

    this.runTemplates = new ObservableWrapper<models.IiabIdName>(
      this.apiBaseService,
      _pageRunTemplatesQuery(),
      _fetchRunTemplatesData,
    false);

    this.runCustomers = new ObservableWrapper<models.IiabIdName>(
      this.apiBaseService,
      _pageRunCustomersQuery(),
      _fetchRunCustomersData,
    false);

    this.runSearchData = new ObservableWrapper<models.RunTrialRecipientSummary>(
      this.apiBaseService,
      _pageRunSearchData(),
      _fetchRunSearchData,
    false);

    this.authService.isAuthenticated$.subscribe(isAuthenticated=>{
      if (!isAuthenticated){
        this.unsubscribeAll(true);
      }
    });

    //TODO:Unsubscribe when not required and visa versa
    this.getRecentRuns();
  }

  get runsFilterObject(): {} {
    return this.RunFilterService.runsFilterObject;
  }

  get runStatusOptions(): string[] { return this.RunFilterService.runStatusOptions };
  get runStateOptions(): string[] { return this.RunFilterService.runStateOptions };;

  ngOnDestroy() {
    this.unsubscribeAll(true);
  }
  
  setRunSubject = (run: RunModel):any => {
    this.selectedRun=run;    
    this.selectedRunSubject.next(run);
  };

  getRunDetails(id: string){
    return this.apiBaseService.executeGet<RunModel>(`run/${id}`, "GetRun");
  }

  createRun(run: CreateRunModel){
    // TODO: @Nicon CreateRun, selected run to use returned object's value
    return this.apiBaseService.executePost<any>(`run/createRun`, run, "Create Run");
  }

  createBaseRun(runTemplate: RunTemplateModel){
    this.getRunPeriodList().subscribe((runPeriodList: any) => {
      if(runPeriodList){
        const billingDate = runPeriodList.find((month: any) => new Date(Date.parse(month.DateTime)).getMonth() === new Date().getMonth());
        const runModel: CreateRunModel = {
          Name: `${runTemplate.Name} - ${billingDate.Description}`,
          IsTrial: true,
          IsPukLess: runTemplate.IsPukLess,
          RunTemplateId: runTemplate.Id,
          TrialOptions: {
            SendToUser: [this.authService.loggedInUser.email], 
            EmailGroups: [], 
            EmailGroupEmails: [],
            IsAutoTrialEnabled: false,
            RecipientSelection: 0,
            UserKeyOption: 2,
            FilterOutputType: 0,
            RecipientList: []
          },
        }
    
        this.createRun(runModel).subscribe((res: any) => {
          this.router.navigateByUrl(`/run/${res.Id}`);
        })
      }
    })
  }

  getRunPeriodList(){
    return this.apiBaseService.executeGet("runPeriod", "Get Run Period");
  }  

  getRecentRuns(){
    if(this.recentRunSubscription || !this.authService.isAuthenticated)
      return;

    console.log('Subscribing to recent runs');
    
    this.recentRunSubscription = this.apollo.subscribe<string>({query: gql(_recentRunsSubQuery())})
      .subscribe(
        data=>{
          if (!this.runSummaries.GetItems().some(x => x.id == data)){
            this.runSummaries.loadPagedItems(Object.keys(this.runsFilterObject).length !== 0 ? this.runsFilterObject : null, null, null, null, this.runSummariesPerPage);
          }
        },
        err=>{console.error(err)}
      );
  }

  clearMemory() {
    this.unsubscribeAll(true);
    this.runSummaries.ClearItems$();
  }

  navigateHomeAndFilterRuns(runsFilter:any){
    if (runsFilter.runTemplate != null) 
      this.RunFilterService.selectedTemplate = runsFilter.runTemplate;
      
    if (runsFilter.customer != null) 
      this.RunFilterService.selectedCustomer = runsFilter.customer;
      
    this.router.navigateByUrl('/');
  }

  pageRunSummaries($event?: string){  
    this.runSummaries.ClearSubject();
    switch ($event) {
      case "next":
        this.runSummaries.loadSpecificPage($event, this.runSummariesPerPage);
        break;
      case "previous":
        this.runSummaries.loadSpecificPage($event, null, this.runSummariesPerPage);
        break;
      case "first":
        this.runSummaries.loadSpecificPage($event, this.runSummariesPerPage);
        break;
      case "last":
        this.runSummaries.loadSpecificPage($event, null, this.runSummariesPerPage);
        break;
    }

    this.runSummaries.hasPreviousPage$.subscribe(res => {
      if (res === false)
        this.getRecentRuns();
      else
        this.unsubscribeAll(true);
    })
  }

  unsubscribeAll(includeRecent:boolean){
    
    console.log('Unsubscribe All Subscriptions');
    
    if(includeRecent)
    {
      this._unsubscribeFrom(this.recentRunSubscription);
      console.log("Unsubscribed From RecentStatus");
      this.recentRunSubscription=null;
    }
  }

  //#region private
  private _unsubscribeFrom(sub:Subscription)
  {
    if(sub && !sub.closed)
    {
      try{
        sub.unsubscribe();
      }
      catch(e){console.error(e)}
    }
  }

  //#endregion
}
