<div class="general-page-container fit-absolute">
  <div class="general-page-header">
      <form class="p-10">
          <div class="form-block">
            <div class="form-group">
              <label>Find Template</label>
              <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth
                name="template" placeholder="Filter by Name or ID" [(ngModel)]="selectedTemplate" [ngModelOptions]="{standalone: true}"/>
                
              <button *ngIf="hasKeys(filterObject)" class="col-md-4" size="small" nbButton status="primary" (click)="searchTemplates()">
                Search
              </button>
            </div>
          </div>
        </form>
  </div>
  <div class="general-page-body">
      <div class="general-page-body">
          <div class="scroll p-10">
          <ng-container *ngFor="let template of templates$ | async">
            <ifs-wizard-run-template-card [runTemplate]="template" [isRunTemplate]="false"></ifs-wizard-run-template-card>
          </ng-container>
        </div>
      </div>
  </div>
  <div class="general-page-footer">
    <button class="col-md-6" size="small" nbButton status="basic" (click)="cancel()">
      Cancel
    </button>
    <button class="col-md-6" size="small" nbButton status="primary" routerLink="/admin/templates">
      Create
    </button>
  </div>
</div>