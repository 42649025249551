import { Component, OnInit } from '@angular/core';
import { PasswordService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})
export class ManageProfileComponent implements OnInit {

  constructor(
    private passwordService: PasswordService
  ) {}

  ngOnInit(): void {
  }

  updatePassword(val) {
    const { currentPassword: CurrentPassword, password: NewPassword } = val;
    this.passwordService.changePassword({ CurrentPassword, NewPassword });
  }

}
