import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { ObservableWrapper } from './base/observable-wrapper';
import { IiabIdName, PageGraphQLData, TemplateModel, TemplateSourceFile } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { AuthService } from '@InfoSlips/iiab-auth';
import { EnvironmentService } from '@InfoSlips/env';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private environment: EnvironmentService,
    private apiBaseService: ApiBaseService,    
    private toastr: ToastrService,
    private dialogService: NbDialogService,
    authService: AuthService
  ) {
    this.templates = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageTemplatesQuery(),
      this._fetchTemplatesData,
    false);

    if(authService.isAuthenticated)
      {
        this.templates.loadPagedItems();
      }
   }

  public templates: ObservableWrapper<IiabIdName>;
  selectedTemplateSubject = new BehaviorSubject<TemplateModel>(null);
  selectedTemplate$ = this.selectedTemplateSubject.asObservable();

  templateFilesSubject = new BehaviorSubject<any>(null);
  templateFiles$ = this.templateFilesSubject.asObservable();

  private _pageTemplatesQuery() {
    return `
    query Templates($first: Int = 16, $where: TemplateFilterInput = null) { 
      templates(first: $first, where: $where, order: {name: ASC})
      {
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges{
          node{
            name
            id
            customer {
              name
              id
            }
            created
            createdBy
            lastUpdated
            lastUpdatedBy
          }
        }
      }
    }`;
  }

  private _fetchTemplatesData(rawData: any): PageGraphQLData<IiabIdName> {
    try{
      return rawData.data.templates as PageGraphQLData<IiabIdName>;
    }
    catch(e)
    {
      console.log("runRunTemplatesData That FAIlED", rawData);
      return null;
    }
  }

  getTemplate(templateId: string){
    this.apiBaseService.executeGet<TemplateModel>(`Template/${templateId}`, "Get Template.").subscribe((res: any) => {
      this.selectTemplate(res);
    });
  }

  selectTemplate(template: TemplateModel){
    if(template.ExportTemplate === null){
      template.ExportTemplate = {
          "Type": 0,
          "Host": null,
          "Port": null,
          "UserName": null,
          "Password": null,
          "Run": null,
          "Customer": null,
          "ControlFileLineRazorTemplate": null,
          "IsEnabled": false
      }
    }

    if(template.AdditionalLocationRunReport === null){
      template.AdditionalLocationRunReport = {
        "AdditionalType": null,
        "sFtpUrl": null,
        "sFtpUsername": null,
        "sFtpPassword": null,
        "ApiUrl": null
      }
    }

    this.selectedTemplateSubject.next(template);
  }

  deleteTemplate(templateId: string){
    this.apiBaseService.executeDelete<boolean>(`Template/${templateId}`, 'Delete Template').subscribe((res) => {
      if(res){
        this.toastr.info("Template Deleted!");
        this.templates.loadPagedItems();
      }
      else {
        this.toastr.error("Template Delete Failed!");
      }
    })
  }

  async getTemplateFiles(templateId:string){
    try {
      return await this.apiBaseService.executeGet<string[]>(`Template/gettemplatefiles/${templateId}`, "Get Template Files.").pipe(
        map(res=>{
          const fileList:TemplateSourceFile[] = []
          res.forEach((file:string) => {
            const ext = file.split('.')[file.split('.').length - 1];
            let icon = ext;
      
            if(icon === 'png' || icon === 'jpg' || icon === 'gif'){
              icon = 'image';
            }

            if(icon === 'eot' || icon === 'woff'){
              icon = 'font';
            }
      
            if(icon === 'htm'){
              icon = 'html';
            }
      
            fileList.push({
              name: file,
              icon: icon,
              ext: ext 
            })
          });
          this.templateFilesSubject.next(fileList);
          return fileList.length > 0 ? true : false;
        }         
        ),
        catchError(error => {
          return this.handleError(error);
        })
      ).toPromise();
    } catch {
      throw false;
    }
  }

  getTemplateFile(templateId: string, fileName: string){
    return this.apiBaseService.executeGetAny<string[]>(`Template/gettemplatefile/${templateId}/${fileName}`, "Get Template File.", {responseType: 'text'});
  }
  getTemplateFileSource(templateId: string, fileName: string):string{    
    const apiUrl = this.environment.apiUrl+'api'
    return `${apiUrl}/Template/getAnyTemplateFile/${templateId}/${fileName}`;
  }
  updateTemplate(templateModel: TemplateModel){
    return this.apiBaseService.executePut(`Template`, templateModel ,"Update Template.").subscribe(res => {
      res ? this.toastr.success("Template Updated!") : this.toastr.error("Update Failed!");
    });
  }

  createTemplate<T>(templateModel: TemplateModel, createRunTemplate?: boolean){
    return new Promise<T>((resolve: any) => {
      this.apiBaseService.executePost(`Template`, templateModel ,"Create Template.").subscribe((res: any) => {
        if(res){
          this.toastr.success("Template Created!")
          resolve({ 
            context: {
              title: 'Template Created!',
              body: 'Do you want to make additional changes? or Create a Run Template?',
              button: 'Create Run Template',
              routeEdit: `/admin/templates/${res.Id}`,
              routeNext: `/admin/runtemplates`,
            }
          })
        } else {
          this.toastr.error("Template Creation Failed!");
        }
      });
    });
  }

  getBaseTemplate(templateId: string) : Observable<TemplateModel>{
    return this.apiBaseService.executeGet(`Template/${templateId}`, 'Get Base Template');
  }

  private handleError(error: HttpErrorResponse) {
    const subject = new Subject<string>();

    if(error.error.Message === "An internal system error has occurred. The developers have been notified."){
      console.log("Template does not contain any files.");
      this.toastr.info("Template does not contain any files.")
      subject.next("Template does not contain any files.");
    }
    return throwError(error);
  }

  getRazorPreview(templateId:string, razor:string, data:string): Observable<HttpEvent<string>>{
    const previewModel = {
      RunTemplateId: templateId,
      Input: razor,
      File: data
    };
    return this.apiBaseService.executePostAny<string>(
      `Template/parseFile`, 
      previewModel, 
      'Get Razor Preview', 
      false, 
      {responseType: 'text'});
  }

  templateContext(filter:any, pageSize: number = null):Observable<IiabIdName[]>{
    const contextWrapper = new ObservableWrapper<IiabIdName>(
      this.apiBaseService,
      this._pageTemplatesQuery(),
      this._fetchTemplatesData,
    false);

    pageSize != null ? contextWrapper.loadPagedItems(filter, null, null, undefined, pageSize) : contextWrapper.loadPagedItems(filter);

    return contextWrapper.Items$();
  }

}
