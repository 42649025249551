<div class="general-page-container">
  <div class="general-page-header">
    <div class="d-flex">
        <div class="flex-1">
          <h4 class="heading">Create: <b>{{selectedReport?.name}}</b></h4>
        </div>
    </div>
  </div>
</div>

<nb-card class="bl-none">
  <nb-card-body>
    <div class="flex">
      <div class="report-actions">
        <input nbInput fullWidth type="text" name="search" placeholder="search reports..." [(ngModel)]="searchPhrase" class="search" />
        <div class="general-side-nav">
          <div class="context-menu" *ngFor="let report of (this.reportsService.allowedReportsForComponent$ | async) | filter :'name' : searchPhrase: 'includes';" (click)="select(report)" [class.active]="report.name === selectedReport?.name" [routerLink]="runId ? '/reports/' + runId + '/' + report?.route : '/reports/report/' + report?.route">
              <div class="body">
                <h4 class="title">{{ report.name }} <small class="warning" *ngIf="report.wip">WIP</small></h4>
              </div>
              <div class="action text-center">
                <nb-icon *ngIf="!report.wip" icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
                <nb-icon *ngIf="report.wip" icon="alert-triangle-outline" pack="eva" status="warning"></nb-icon>
              </div>
          </div>
        </div>
      </div>
      


      <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="input-box">
                <ifs-filter *ngIf="selectedReport && selectedReport.isGeneric" [(report)]="selectedReport"></ifs-filter>
              </div>
            </div>
            <div class="col-md-12" *ngIf="selectedReport?.graphql?.query && ((reportDetailsPaging$ | async)?.totalCount > 0 || (reportDetailsPaging$ | async)?.totalCount === -1)">
              <div class="input-box no-padding">
                <router-outlet></router-outlet>
              </div>
            </div>
        </div>

        <!-- <ng-template [ngSwitchCase]="false">
          <div class="row">
            <div class="col-md-12">
              <div class="input-box">
                <router-outlet [selectedReport]="selectedReport"></router-outlet>
              </div>
            </div>
          </div>
        </ng-template> -->

      </div>
    </div>
  </nb-card-body>
</nb-card>
