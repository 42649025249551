<div class="content-container">
  <div class="content-area">
    <div 
      class="login-wrapper" 
      (mouseenter)="returnIcons = true; rewind = false;"
      (mouseleave)="returnIcons = false; rewind = true;"
    >

      <ng-container *ngIf="showAnimation">
        <div class="floating-icons animate" [ngClass]="{ 'iconReturnTo': returnIcons, 'rewindIcons': rewind }">
          <span id="icon-robot" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>robot</title>
              <rect class="a" x="4.5" y="1" width="15" height="9" rx="1.5" ry="1.5" />
              <circle class="a" cx="8" cy="5.5" r="1.5" />
              <circle class="a" cx="16" cy="5.5" r="1.5" />
              <line class="a" x1="10.5" y1="12" x2="13.5" y2="12" />
              <line class="a" x1="10.5" y1="14" x2="13.5" y2="14" />
              <path class="a"
                d="M14.97,16H9.03a3.663,3.663,0,0,0-3.581,4.429l.466,2.176A.5.5,0,0,0,6.4,23H17.6a.5.5,0,0,0,.489-.395l.466-2.176A3.663,3.663,0,0,0,14.97,16Z" />
              <path class="a" d="M18.614,19.279l2.074-1.605A3.662,3.662,0,0,0,22,14l-.053-.186" />
              <path class="a" d="M23,10a2.5,2.5,0,0,1-4,3" />
              <path class="a" d="M5.387,19.279,3.312,17.674A3.662,3.662,0,0,1,2,14l.016-.2" />
              <path class="a" d="M1,10a2.5,2.5,0,0,0,4,3" />
              <path class="a" d="M15.5,23V19.5A.5.5,0,0,0,15,19H9a.5.5,0,0,0-.5.5V23" />
            </svg>
          </span>
          <span id="icon-xls" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>office-file-xls-1</title>
              <path class="a" d="M19.5,12.5V6.473A5.975,5.975,0,0,0,13.525.5H1.5a1,1,0,0,0-1,1v21a1,1,0,0,0,1,1h3" />
              <path class="a" d="M14.5.579V4.5a1,1,0,0,0,1,1h3.92" />
              <line class="a" x1="7.5" y1="23.473" x2="11.5" y2="15.473" />
              <line class="a" x1="11.5" y1="23.473" x2="7.5" y2="15.473" />
              <path class="a" d="M13.5,15.473v6.5a1.5,1.5,0,0,0,1.5,1.5h2.5" />
              <path class="a"
                d="M23.5,15.473H21.354a1.854,1.854,0,0,0-.829,3.513l1.95.975a1.854,1.854,0,0,1-.829,3.512H19.5" />
            </svg>
          </span>
          <span id="icon-lock" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>password-desktop-lock</title>
              <path class="a" d="M9.138,23.5a7.486,7.486,0,0,0,1.362-4" />
              <path class="a" d="M14.862,23.5a7.486,7.486,0,0,1-1.362-4" />
              <line class="a" x1="7.5" y1="23.5" x2="16.5" y2="23.5" />
              <line class="a" x1="0.5" y1="16.5" x2="23.5" y2="16.5" />
              <path class="a" d="M14.5.5H1.5a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h21a1,1,0,0,0,1-1v-5" />
              <path class="a" d="M12.5,11.5h-7a1,1,0,0,1-1-1v-4a1,1,0,0,1,1-1h7" />
              <rect class="a" x="14.5" y="4.5" width="9" height="7" rx="1" ry="1" />
              <path class="a" d="M19,7a1,1,0,1,0,1,1,1,1,0,0,0-1-1Z" />
              <path class="a" d="M16.5,4.5V3a2.5,2.5,0,0,1,5,0V4.5" />
              <path class="a" d="M8,8.25a.25.25,0,1,1-.25.25A.25.25,0,0,1,8,8.25" />
              <path class="a" d="M12,8.25a.25.25,0,1,1-.25.25A.25.25,0,0,1,12,8.25" />
            </svg>
          </span>
          <span id="icon-cloud" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>cloud-folder</title>
              <path class="a"
                d="M20.5,13.906A4.311,4.311,0,0,0,23,9.857a4.711,4.711,0,0,0-4.954-4.635A6.706,6.706,0,0,0,12,1.5,6.605,6.605,0,0,0,5.325,7.609,3.561,3.561,0,0,0,1,11.018,3.186,3.186,0,0,0,3.5,14.3" />
              <path class="a"
                d="M17,14.5H12a1,1,0,0,1-.8-.4l-.9-1.2a1,1,0,0,0-.8-.4H7a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1v-6A1,1,0,0,0,17,14.5Z" />
            </svg>
          </span>
          <span id="icon-database" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>database-2</title>
              <ellipse class="a" cx="12.001" cy="5.501" rx="9.5" ry="4" />
              <path class="a" d="M21.5,9.5c0,2.21-4.254,4-9.5,4s-9.5-1.79-9.5-4" />
              <path class="a" d="M21.5,14c0,2.21-4.254,4-9.5,4s-9.5-1.79-9.5-4" />
              <path class="a" d="M2.5,5.5v13c0,2.21,4.254,4,9.5,4s9.5-1.79,9.5-4V5.5" />
            </svg>
          </span>
          <span id="icon-email" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>email-action-at</title>
              <path class="a" d="M19.5,8.75l4,2.75V22A1.5,1.5,0,0,1,22,23.5H2A1.5,1.5,0,0,1,.5,22V11.5l4-2.75" />
              <polyline class="a" points="3.5 20.5 8.5 16.5 15.5 16.5 20.5 20.5" />
              <line class="a" x1="23.5" y1="11.5" x2="17.5" y2="15.5" />
              <line class="a" x1="0.5" y1="11.5" x2="6.5" y2="15.5" />
              <path class="a" d="M19.5,14.167V1.5a1,1,0,0,0-1-1H5.5a1,1,0,0,0-1,1V14.167" />
              <circle class="a" cx="12" cy="8" r="1.5" />
              <path class="a"
                d="M13.5,8v.75A1.25,1.25,0,0,0,14.75,10h0A1.25,1.25,0,0,0,16,8.75V8.186A4.124,4.124,0,0,0,12.1,4a4,4,0,0,0-1.052,7.89,4.092,4.092,0,0,0,1.977-.025" />
            </svg>
          </span>
        </div>
      </ng-container>

      <div class="logo-section">
        <div class="dots-wrapper" *ngIf="showAnimation">
          <div class="dots" [ngClass]="{
              active: returnIcons
            }">
            <span></span><span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>

        <div class="logo">
          <div class="shapeshifter" [ngClass]="{
              play: returnIcons === true && !undefined,
              rewind: rewind
            }" style="opacity: 0;"></div>
          <svg xmlns="http://www.w3.org/2000/svg" width="240.709" height="239.75" viewBox="0 0 240.709 239.75">
            <g id="Logo" transform="translate(-10.663 -5.786)">
              <path id="logoPath1"
                d="M147.455,33.664a15.355,15.355,0,0,1,.947,3.077,88.4,88.4,0,0,1,34.319,21.538,28.267,28.267,0,0,1,2.6,2.84,90.44,90.44,0,0,1,11.835,15.62,85.676,85.676,0,0,1,11.6,41.417v.236a50.047,50.047,0,0,1-15.147,36.448h-.238a49.358,49.358,0,0,1-36.448,15.384,22.352,22.352,0,0,1-16.8-7.1h-.237a21.475,21.475,0,0,1-6.863-14.911V76.739H83.787v25.8h17.277V156.5c.947,10.886,5.681,20.827,14.439,29.346,10.888,11.125,24.378,16.567,40.235,16.567,24.143,0,44.26-8.046,60.355-24.378h.236a81.482,81.482,0,0,0,15.386-21.063,5.908,5.908,0,0,1,.946-1.657,79.99,79.99,0,0,0,7.1-23.9,120.386,120.386,0,0,0,.947-13.254v-.236c-.237-4.5-.474-8.757-.947-13.018a111.72,111.72,0,0,0-7.1-28.163V76.5c-.236-.71-.474-1.183-.71-1.893a50.921,50.921,0,0,0-2.366-5.208,92.547,92.547,0,0,0-4.5-8.282,90.831,90.831,0,0,0-10.415-15.148,101.5,101.5,0,0,0-9.467-10.65C182.484,12.6,155.029.766,123.313.057H117.4C85.444.766,58.225,12.6,35.267,35.085l-.237.236C12.307,58.279.474,85.97,0,118.392v2.13c0,33.135,11.835,61.3,35.267,84.729,19.407,19.645,42.13,31,68.4,34.556l13.018-30.769c-22.722-.71-42.367-9.229-58.7-25.8-17.277-17.041-25.8-38.1-25.8-62.481v-2.368C32.9,95.2,41.42,75.319,57.987,58.279c8.757-8.521,18.226-15.147,28.64-19.407.236-1.185.474-2.366.71-3.313a31.6,31.6,0,0,1,8.521-14.911,30.05,30.05,0,0,1,22.011-9.231c8.521,0,15.622,3.077,22.011,9.231a31.48,31.48,0,0,1,7.574,13.016"
                transform="translate(10.663 5.729)" fill="#63a117" fill-rule="evenodd" />
              <path id="logoPath2"
                d="M64.817,45.75A18.288,18.288,0,0,0,77.6,50.958c4.97,0,8.995-1.894,12.78-5.208a18.284,18.284,0,0,0,5.208-12.78,17.473,17.473,0,0,0-5.208-12.78c-3.786-3.55-7.81-5.442-12.78-5.442a17.543,17.543,0,0,0-12.78,5.442c-3.551,3.313-5.208,7.573-5.208,12.78a17.319,17.319,0,0,0,5.208,12.78"
                transform="translate(50.935 15.654)" fill="#63a117" fill-rule="evenodd" />
            </g>
          </svg>
        </div>

        <p class="logo-text" [ngClass]="{
            active: returnIcons
          }">
          <svg xmlns="http://www.w3.org/2000/svg" width="229.022" height="66.723" viewBox="0 0 229.022 66.723">
            <g id="Text" transform="translate(-39.383)">
              <path id="Fill-1"
                d="M240.2,6.9a4.759,4.759,0,0,0,1.488,3.548,4.323,4.323,0,0,0,3.09,1.373h.344a4.658,4.658,0,0,0,3.434-1.373A4.758,4.758,0,0,0,250.045,6.9a4.761,4.761,0,0,0-1.487-3.548,4.981,4.981,0,0,0-6.867,0A4.761,4.761,0,0,0,240.2,6.9"
                transform="translate(-45.506 -0.372)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-2"
                d="M228.868,0l-8.584,2.174v42.8c-.115,5.379,1.831,8.126,5.723,8.126,3.2,0,5.493-1.259,6.752-3.777-2.632-.915-4.006-3.548-3.892-8.126V0"
                transform="translate(-41.726)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-3"
                d="M325.14,45.654a9.8,9.8,0,0,0-.8-4.12,8.22,8.22,0,0,0-1.373-2.4c-1.373-1.831-3.663-3.319-6.867-4.578-3.2-1.373-5.379-2.289-6.3-3.09a3.012,3.012,0,0,1-1.487-2.518c0-2.289,1.487-3.548,4.463-3.548,3.319,0,5.952.8,7.783,2.4l2.518-6.638a23.587,23.587,0,0,0-10.758-2.518c-3.777,0-6.867.8-9.271,2.632a8.666,8.666,0,0,0-3.663,7.439c0,4.692,3.2,8.355,9.5,10.987q4.463,1.716,5.837,3.09a4.35,4.35,0,0,1,1.487,3.433c0,2.4-1.6,3.548-4.921,3.548a13.8,13.8,0,0,1-8.7-2.976l-3.09,6.867a39.293,39.293,0,0,0,5.723,2.4,20.42,20.42,0,0,0,5.837.572c4.349,0,7.783-1.03,10.3-2.976a8.314,8.314,0,0,0,2.976-3.892,9.127,9.127,0,0,0,.8-4.12"
                transform="translate(-56.735 -3.537)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-4"
                d="M265.481,21.735v-2.4h-8.47V70.26h8.47V55.039a18.083,18.083,0,0,0,7.439,1.6c5.608,0,10.072-1.6,13.276-4.921,3.2-3.2,4.807-7.783,4.807-13.849q0-19.227-17.511-19.227A11.336,11.336,0,0,0,265.481,21.735Zm16.6,15.908c0,4.121-.8,7.1-2.518,8.928-1.716,1.945-4.578,2.861-8.47,2.861a9.488,9.488,0,0,1-5.608-1.945V28.258a7.812,7.812,0,0,1,5.608-2.4c3.892,0,6.753.915,8.47,2.632,1.716,1.831,2.518,4.807,2.518,9.156Z"
                transform="translate(-48.695 -3.537)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-5" d="M248.367,56.116V19.491H234.976v6.981h4.693V56.116h8.7"
                transform="translate(-44.514 -3.698)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-6" d="M48.522,52.953h8.928V2.825H48.522Z" transform="translate(-9.139 -0.536)"
                fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-7"
                d="M120.594,15.039a7.773,7.773,0,0,1,1.716-5.15,4.837,4.837,0,0,1,4.006-2.06,12.778,12.778,0,0,1,4.35.915l2.518-6.523A25.193,25.193,0,0,0,125.744.847a12.063,12.063,0,0,0-9.5,4.121,16.742,16.742,0,0,0-4.006,10.986h-5.379v6.981h5.379V52.578h8.47V22.935h7.439V15.954h-7.554v-.915"
                transform="translate(-20.207 -0.161)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-9"
                d="M165.138,24.138c-.115-.115-.229-.344-.458-.459-.572-.572-1.144-1.144-1.831-1.716a18.023,18.023,0,0,0-11.1-3.319c-5.379,0-9.614,1.716-12.818,5.264-3.2,3.434-4.807,8.012-4.807,13.62,0,5.837,1.487,10.414,4.578,13.848,3.09,3.548,7.439,5.265,13.047,5.265a17.428,17.428,0,0,0,11.1-3.548c.687-.572,1.259-1.03,1.831-1.6a2.487,2.487,0,0,1,.458-.572,10.744,10.744,0,0,0,1.373-2.06,20.832,20.832,0,0,0,2.861-11.33c0-4.464-.915-8.355-2.861-11.33A8.081,8.081,0,0,0,165.138,24.138Zm-4.693,13.39q0,5.836-2.4,8.927a7.23,7.23,0,0,1-6.3,3.2c-5.837,0-8.813-4.006-8.7-12.131,0-3.777.8-6.638,2.4-8.813a7.008,7.008,0,0,1,6.3-3.09c5.723-.115,8.7,3.891,8.7,11.9Z"
                transform="translate(-25.379 -3.537)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-10"
                d="M73.679,22.764l-1.6-3.434H65.9V55.955H74.48V29.517A7.122,7.122,0,0,1,77.8,26.884,7.942,7.942,0,0,1,81.8,25.74c2.747,0,4.693.686,5.952,2.174q1.716,2.06,1.716,6.867V55.955h8.584V33.523q0-7.21-3.777-10.986a13.984,13.984,0,0,0-10.3-3.892q-6.868,0-10.3,4.12"
                transform="translate(-12.435 -3.537)" fill="#63a117" fill-rule="evenodd" />
              <path id="Fill-15"
                d="M186.81,15.085a5.272,5.272,0,0,1,1.716-4.12,5.517,5.517,0,0,1,4.235-1.6h.458a16.967,16.967,0,0,1,9.843,3.319l2.747-7.668c-2.632-2.174-6.982-3.319-12.7-3.319h-.344A15.08,15.08,0,0,0,186.7,2.839a12.56,12.56,0,0,0-4.578,2.747A12.083,12.083,0,0,0,178.226,12a14.474,14.474,0,0,0-.343,3.2,16.63,16.63,0,0,0,.343,3.434,13.5,13.5,0,0,0,.8,2.289A10.589,10.589,0,0,0,182,25.386a32.91,32.91,0,0,0,4.692,3.09c.916.458,1.946,1.03,3.09,1.6s2.174,1.144,2.976,1.6a17.473,17.473,0,0,1,4.693,3.663,8.182,8.182,0,0,1,1.831,4.921c-.114,3.2-2.174,5.036-6.524,5.379h-1.373a17.24,17.24,0,0,1-4.693-.687,26.618,26.618,0,0,1-5.608-2.976l-3.319,8.012a.868.868,0,0,1,.458.229,22.928,22.928,0,0,0,8.47,3.09c1.259.115,2.4.23,3.777.23h2.289a18.849,18.849,0,0,0,10.53-3.892A12.413,12.413,0,0,0,208.1,39.577a15.078,15.078,0,0,0-1.144-6.066A12.318,12.318,0,0,0,203.52,28.7a27.123,27.123,0,0,0-7.21-4.578c-1.259-.686-2.518-1.259-3.548-1.945-4.006-2.4-5.951-4.807-5.951-7.1"
                transform="translate(-33.66 -0.322)" fill="#63a117" fill-rule="evenodd" />
            </g>
          </svg>
        </p>
        <hr [ngClass]="{
            active: returnIcons
          }" class="logo-separator" />
      </div>

      <ng-content></ng-content>
      <ng-container *ngIf="showAnimation">
        <div class="floating-icons" [ngClass]="{ 'iconReturnTo': returnIcons, 'rewindIcons': rewind, 'animate': showAnimation }">
          <span id="icon-pdf" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>office-file-pdf-1</title>
              <path class="a"
                d="M19.511,12.5V6.473A5.975,5.975,0,0,0,13.536.5H1.511a1,1,0,0,0-1,1v21a1,1,0,0,0,1,1H4.489" />
              <path class="a" d="M14.511.579V4.5a1,1,0,0,0,1,1h3.92" />
              <path class="a" d="M11.489,17.5a2,2,0,0,1-2,2h-2v-4h2A2,2,0,0,1,11.489,17.5Z" />
              <line class="a" x1="7.489" y1="23.499" x2="7.489" y2="19.499" />
              <path class="a" d="M13.489,15.5a4,4,0,1,1,0,8Z" />
              <path class="a" d="M19.489,23.5V17a1.5,1.5,0,0,1,1.5-1.5h2.5" />
              <line class="a" x1="19.489" y1="19.499" x2="21.989" y2="19.499" />
            </svg>
          </span>
          <span id="icon-pie" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>pie-line-graph-desktop</title>
              <circle class="a" cx="7" cy="8.5" r="3.5" />
              <polyline class="a" points="7 5 7 8.5 10.5 8.5" />
              <path class="a" d="M9,22.5a6.979,6.979,0,0,0,1.5-4" />
              <path class="a" d="M15,22.5a6.979,6.979,0,0,1-1.5-4" />
              <line class="a" x1="7.499" y1="22.5" x2="16.499" y2="22.5" />
              <line class="a" x1="0.5" y1="15.5" x2="23.5" y2="15.5" />
              <rect class="a" x="0.5" y="1.5" width="23" height="17" rx="1" ry="1" />
              <polyline class="a" points="13.5 7 15 5 18 7.5 20.5 4.5" />
              <line class="a" x1="19.5" y1="12.5" x2="19.5" y2="11" />
              <line class="a" x1="17.5" y1="12.5" x2="17.5" y2="10.5" />
              <line class="a" x1="15.5" y1="12.5" x2="15.5" y2="9.5" />
              <line class="a" x1="13.5" y1="12.5" x2="13.5" y2="11" />
            </svg>
          </span>
          <span id="icon-print" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>print-text</title>
              <line class="a" x1="7.5" y1="19.499" x2="16.5" y2="19.499" />
              <line class="a" x1="7.5" y1="16.499" x2="16.5" y2="16.499" />
              <path class="a"
                d="M5.5,16.5h-3a2.006,2.006,0,0,1-2-2v-6a2.006,2.006,0,0,1,2-2h19a2.006,2.006,0,0,1,2,2v6a2.006,2.006,0,0,1-2,2h-3" />
              <path class="a" d="M5.5,4.5V.5h9.586a1,1,0,0,1,.707.293l2.414,2.414a1,1,0,0,1,.293.707V4.5" />
              <path class="a" d="M18.5,22.5a1,1,0,0,1-1,1H6.5a1,1,0,0,1-1-1v-9h13Z" />
              <circle class="a" cx="3.5" cy="9.499" r="1" />
              <polyline class="a" points="14.5 0.499 14.5 4.499 18.5 4.499" />
            </svg>
          </span>
          <span id="icon-search" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>seo-search-page</title>
              <path class="a" d="M12,20.25H1.5a1,1,0,0,1-1-1v-16a1,1,0,0,1,1-1h19a1,1,0,0,1,1,1v6.9" />
              <polyline class="a" points="2.5 10.75 3.5 11.75 6.5 8.75" />
              <line class="a" x1="8" y1="11.25" x2="11.5" y2="11.25" />
              <line class="a" x1="9.5" y1="9.25" x2="12.5" y2="9.25" />
              <line class="a" x1="0.5" y1="6.25" x2="21.5" y2="6.25" />
              <circle class="a" cx="17.029" cy="14.779" r="4.529" />
              <line class="a" x1="23.5" y1="21.25" x2="20.252" y2="18.002" />
            </svg>
          </span>
          <span id="icon-video" class="cloudCloud">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: currentColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <title>video-player-movie-2</title>
              <rect class="a" x="0.5" y="0.5" width="23" height="23" rx="2" ry="2" />
              <line class="a" x1="0.5" y1="4.5" x2="23.5" y2="4.5" />
              <line class="a" x1="0.5" y1="19.5" x2="23.5" y2="19.5" />
              <path class="a" d="M3.75,2.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
              <path class="a" d="M7.875,2.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
              <path class="a" d="M20.25,2.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
              <path class="a" d="M16.125,2.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
              <path class="a" d="M12,2.25a.25.25,0,1,1-.25.25A.25.25,0,0,1,12,2.25" />
              <path class="a" d="M3.75,21.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
              <path class="a" d="M7.875,21.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
              <path class="a" d="M20.25,21.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
              <path class="a" d="M16.125,21.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
              <path class="a" d="M12,21.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
              <path class="a"
                d="M8.916,8.4A.654.654,0,0,0,8,9v6a.654.654,0,0,0,.916.6l6.626-2.9a.764.764,0,0,0,0-1.4Z" />
            </svg>
          </span>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="version-number">
    v{{version}}
  </div>
</div>