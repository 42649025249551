import { Injectable, Injector } from '@angular/core';
import { MenuItem } from './menu-item';

// Forms
import { CompaniesFormComponent } from '../components/companies-form/companies-form.component';
import { RunTemplateFormComponent } from '../components/run-template-form/run-template-form.component';
import { SetupFormComponent } from '../components/setup-form/setup-form.component';
import { TemplateFormComponent } from '../components/template-form/template-form.component';
import { UsersFormComponent } from '../components/users-form/users-form.component';
import { RecipientsFormComponent } from '../components/recipients-form/recipients-form.component';
import { TestingRestrictionsFormComponent } from '../components/testing-restrictions-form/testing-restrictions-form.component';

// Additional Imports
import { ProcessorFormComponent } from '../components/processor-form/processor-form.component';
import { ProcessorService, RecipientService, RunRecipientService, UserService } from '@InfoSlips/iiab-api';
import { PasswordFormComponent } from '../components/password-form/password-form.component';
import { QueueStatusComponent } from 'libs/iiab-admin/src/lib/components/queue-status/queue-status.component';
import { RecipientBlacklistComponent } from 'libs/iiab-admin/src/lib/components/recipient-blacklist/recipient-blacklist.component';
import { ReportsService } from 'libs/reports/src/lib/services/reports.service';
import { OakmoonMain } from '@InfoSlips/models';
import { AuthService } from '@InfoSlips/iiab-auth';
import { EnvironmentService } from '@InfoSlips/env';
import { SecurityFormComponent } from './../../../../profile/src/lib/components/security-form/security-form.component';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  
  allowedMenus: OakmoonMain[];
  allowedMainMenu;

  constructor(
    private injector: Injector,
    private userService: UserService,
    private reportService: ReportsService,
    private authService: AuthService,
    private environmentService: EnvironmentService
  ){ 
    this.userService.allowedMainMenu$.subscribe(res => {
      if (res) {
        this.allowedMenus = res;
        
        this.allowedMainMenu = {
          Setup: this.allowedMenus.some(main => main.id == "SETUP") ? true : false,
          CustomersAndUsers: this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS") ? true : false,
          Reports: this.allowedMenus.some(main => main.id == "REPORTS") ? true : false,
          Toolbox: this.allowedMenus.some(main => main.id == "TOOLBOX") ? true : false,
          Cms: this.allowedMenus.some(main => main.id == "CMS") ? true : false
        }
      }
    })

    this.authService.isAuthenticated$.subscribe(auth => {
      if (auth === true) this.userService.loadMainMenu();
    })
  }

  getSetupMenu() {
    return [
      this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "RUN")) &&
      new MenuItem(SetupFormComponent, { 
        title: 'Run', description: 
        'Create a New InfoSlips Run', 
        actions: [], 
        icon: 'run', 
        pack: 'oakmoon-v2',
        enabled: true
      }),
        
      this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "RUN_TEMPLATE")) &&
      new MenuItem(RunTemplateFormComponent, { 
        title: 'Run Template', 
        description: 'Configure a Run Template', 
        actions: ['create-external', 'search'], 
        icon: 'run-template', 
        pack: 'oakmoon-v2',
        enabled: true, 
        route: 'admin/runtemplates',
        createComponent: new MenuItem(TemplateFormComponent, { 
          title: 'Create Template', 
          description: 'Create a New Template', 
          actions: [], 
          icon: 'run-template', 
          pack: 'oakmoon-v2',
          enabled: true 
        })
      }),

      this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "TEMPLATE")) &&
      new MenuItem(TemplateFormComponent, { 
        title: 'Template',
        description: 'Define a Document Template', 
        actions: ['create-external', 'search'], 
        icon: 'template', 
        pack: 'oakmoon-v2',
        enabled: true,
        route: 'admin/templates',
        createComponent: new MenuItem(TemplateFormComponent, { 
          title: 'Create Template', 
          description: 'Create a New Template', 
          actions: [], 
          icon: 'template', 
          pack: 'oakmoon-v2',
          enabled: true 
        })
      }),

      this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "PRE_PROCESSOR")) &&
      new MenuItem(ProcessorFormComponent, { 
        title: 'Pre Processor', 
        description: 'Configure a Data Processor', 
        actions:['add','search'],
        icon: 'pre-processor', 
        pack: 'oakmoon-v2',
        route: 'admin/preprocessor',
        context: 'Pre',
        service: this.injector.get(ProcessorService)
      }),

      this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "POST_PROCESSOR")) &&
      new MenuItem(ProcessorFormComponent, { 
        title: 'Post Processor', 
        description: 'Configure a Data Processor', 
        actions:['add','search'],
        icon: 'post-processor', 
        pack: 'oakmoon-v2',
        route: 'admin/postprocessor',
        context: 'Post',
        service: this.injector.get(ProcessorService)
      })
    ].filter(Boolean);
  }

  getCompaniesAndUsersMenu() {
    return [
      this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "USERS")) &&
      new MenuItem(UsersFormComponent, {
        title:'Users',
        description:'Create a New InfoSlips User',
        actions:['add','search'],
        icon:'single-user-actions-add', 
        pack: 'oakmoon',
        route: 'admin/users',
        context: 'Users',
        service: this.injector.get(UserService)
      }),

      this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "CUSTOMERS")) &&
      new MenuItem(CompaniesFormComponent, {
        title:'Customers',
        description:'Create a New InfoSlips Customer',
        actions:['add', 'search'],
        icon:'building-1', 
        pack: 'oakmoon',
        route: 'admin/customers'
      }),

      this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "RECIPIENTS")) &&
      new MenuItem(RecipientsFormComponent, {
        title:'Recipients',
        description:'Lookup Recipients',
        actions:['search'],
        icon:'user', 
        pack: 'oakmoon',
        route: 'admin/recipients',
        context: 'recipients',
        service: this.injector.get(RecipientService)
      }),

      this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "RUN_RECIPIENTS")) &&
      new MenuItem(RecipientsFormComponent, {
        title:'Run Recipients',
        description:'Lookup Run Recipients',
        actions:['search'],
        icon:'user', 
        pack: 'oakmoon',
        route: 'admin/run-recipients',
        context: 'runRecipients',
        service: this.injector.get(RunRecipientService)
      }),

      this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "GREEN_BLACK_LIST" || sub.id == "BATCH_BLACKLIST" || sub.id == "BATCH_GREENLIST")) &&
      new MenuItem(RecipientBlacklistComponent, {
        title:'Manage Blacklist',
        description:'Add and Remove email addresses from the Blacklist',
        actions:[],
        icon:'book-open-outline', 
        pack: 'oakmoon',
        route: 'admin/recipient-blacklist',
        enabled: true,
        isRoute: true
      }),
    ].filter(Boolean);;
  }

  getReportsMenu() {
    let reportsMenu = []
    this.reportService.allowedReportsForMenu$.subscribe(res => reportsMenu = res);
    return reportsMenu;
  }

  getProfileMenu() {
    return [
      new MenuItem(PasswordFormComponent, {
        title: 'Change Password',
        description: 'Change your password.',
        actions: [],
        icon: 'lock', 
        pack: 'oakmoon',
        route: null}),

      new MenuItem(SecurityFormComponent, {
        title: 'Two-Factor Authentication',
        description: 'Enable Two-Factor Authentication.',
        actions: [],
        icon: 'lock',
        pack: 'oakmoon',
        enabled: false, 
        route: null}),

      new MenuItem(PasswordFormComponent, {
        title: 'External Authentication',
        description: 'Enable External Authentication.',
        actions: [],
        icon: 'lock', 
        pack: 'oakmoon',
        enabled: false,
        route: null})
    ];
  }

  getToolboxMenu(){
    return [
      this.allowedMenus.some(main => main.id == "TOOLBOX" && main.items.some(sub => sub.id == "RUN_SEARCH_DATA")) &&
      new MenuItem(RunTemplateFormComponent, {
        title: `Run Search Data`,
        description: `Query searchable data from runs.`,
        actions: ['search'],
        icon: 'run', 
        pack: 'oakmoon-v2',
        enabled: true, 
        route: 'admin/tools/run-search-data',
      }),
      (this.authService.isSystemAdmin) &&
      new MenuItem(QueueStatusComponent, {
        title: `Queue Status`,
        description: `View the queue status' for processing servers.`,
        actions: [],
        icon: 'server', 
        pack: 'oakmoon',
        enabled: true, 
        route: 'admin/tools/queue-status',
        isRoute: true
      }), 
      (this.authService.isSystemAdmin && !this.environmentService.name.includes("prod")) &&  
      new MenuItem(TestingRestrictionsFormComponent, {
        title: `Testing Restrictions`,
        description: `Configure Testing Restrictions`,
        actions: ['add','search'],
        icon: 'testing-restrictions', 
        pack: 'oakmoon',
        enabled: true, 
        route: 'admin/tools/testing-restrictions',
      })
    ].filter(Boolean)
  }

  getMainMenuOptions() {
    return {
      Setup: this.allowedMenus.some(main => main.id == "SETUP") ? true : false,
      CustomerAndUsers: this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS") ? true : false,
      Reports: this.allowedMenus.some(main => main.id == "REPORTS") ? true : false,
      Toolbox: this.allowedMenus.some(main => main.id == "TOOLBOX") ? true : false,
      Cms: this.allowedMenus.some(main => main.id == "CMS") ? true : false
    }
  }
}