<div class="general-page-container">
    <div class="general-page-header">
      <div class="title-bar">
        <h4 class="title">Theme Management</h4>
        <p class="subtitle"></p>
        <div>
            <button nbButton class="btn" size="small" (click)="download()">Download</button>
            <button nbButton class="btn" size="small" status="primary" (click)="save()" class="save" [disabled]="!domainName">Save</button>
        </div>
      </div>
    </div>
    <div class="general-page-body">
        <h5>Brand Colors</h5>
        <hr/>
        <div class="row">
            <div class="col-md-2 colors" *ngFor="let color of colors">
                <ifs-color-input [color]="color" (themeChange)="themeChange($event)"></ifs-color-input>
            </div>
        </div>
    </div>
</div>

<div class="row card-container">
    <div class="col-md-5">
        <nb-card>
            <nb-card-header>Domain Configuration</nb-card-header>
            <nb-card-body>
                <div class="logo-container pt-0">
                    <input required id="domain" name="domain" #domain="ngModel" nbInput placeholder="Enter Domain" [(ngModel)]="domainName" (ngModelChange)="domainName = $event.toLowerCase()" appForbiddenName="viewer">
                    <button status="primary" nbButton (click)="setDomain()">Set</button>

                    <div class="alert">
                        <div *ngIf="domain.errors?.required">
                            Domain is required.
                        </div>
                        <div *ngIf="domain.errors?.forbiddenName">
                            Domain cannot be Viewer.
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>

        <nb-card>
            <nb-card-header>Logo Configuration</nb-card-header>
            <nb-card-body>
                <div class="logo-container">
                    <label>Logo</label>
                    <hr/>
                    <div *ngIf="!themeImage" class="upload-box row" (drop)="imagePreview($event)" (dragover)="onDragOver($event)">
                        <div class="col-md-8">
                            <p>
                                Add custom logo
                            </p>
                        </div>
                        <div class="col-md-4">
                            <div class="file-actions flex-right">
                                <span>
                                    <input id="loader-upload" type="file" accept="image/*" (change)="imagePreview($event)" />
                                    <label class="choose-file" nbButton outline for="loader-upload">Choose File</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="themeImage" class="upload-box row" (drop)="imagePreview($event)" (dragover)="onDragOver($event)">
            
                        <div class="col-md-7">
                            <p>
                                {{themeImage.name}}<span *ngIf="themeImage.size === 0">*</span>
                            </p>
                        </div>
                        <div class="col-md-5">
                            <ng-container *ngIf="themeImage?.size > 0">
                                <div class="file-actions">
                                    <div class="action">
                                        <span *ngIf="themeImage.size > 0">{{ themeImage.size / 1024 / 1024 | number }} MB</span>
                                    </div>
                                    <div class="action">
                                        <input id="loader-upload" type="file" accept="image/*" (change)="imagePreview($event)" />
                                        <label class="choose-file" nbButton outline for="loader-upload">Choose Different File</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                
                    </div>
                    <br/>
                    <!-- <nb-toggle [disabled]="!logoToggleEnabled" (checkedChange)="logoToggle()">Toggle Logo</nb-toggle> -->
                </div>

                <div class="logo-container">
                    <label>Size</label>
                    <hr/>
                    <div class="size">
                        <label>Width (px):</label>
                        <input nbInput [(ngModel)]="themeImageWidth" [disabled]="logoToggleEnabled" (ngModelChange)="setSize($event, 'width')"/>

                        <label>Height (px):</label>
                        <input nbInput [(ngModel)]="themeImageHeight" [disabled]="logoToggleEnabled" (ngModelChange)="setSize($event, 'height')"/>

                        <!-- <label>Container Height (px):</label>
                        <input nbInput [(ngModel)]="themeImageConfig['logo-container-height']" [disabled]="logoToggleEnabled" /> -->
                    </div>
                    <br/>
                </div>

                <!-- TODO: @nicon find a better way to implement -->
                <!-- <div class="logo-container">
                    <label>Move</label>
                    <hr/>
                    <button nbButton (click)="changePosition('up')">Up</button>
                    <button nbButton (click)="changePosition('down')">Down</button>
                    <button nbButton (click)="changePosition('left')">Left</button>
                    <button nbButton (click)="changePosition('right')">Right</button>
                    <br/>
                </div> -->

                <div class="logo-container">
                    <label>Set Position</label>
                    <hr/>
                    <button nbButton (click)="setPosition('top')">Top</button>
                    <button nbButton (click)="setPosition('bottom')">Bottom</button>
                    <button nbButton (click)="setPosition('left')">Left</button>
                    <button nbButton (click)="setPosition('right')">Right</button>
                    <button nbButton (click)="setPosition('center')">Center</button>
                </div>
            </nb-card-body>
        </nb-card>

        <nb-card>
            <nb-card-header>Loader Configuration</nb-card-header>
            <nb-card-body>
                <div class="logo-container">
                    <label>Loader</label>
                    <hr/>
                    <div *ngIf="!themeLoader" class="upload-box row" (drop)="setLoader($event)" (dragover)="onDragOver($event)">
                        <div class="col-md-8">
                            <p>
                                Add custom loader
                            </p>
                        </div>
                        <div class="col-md-4">
                            <div class="file-actions flex-right">
                                <span>
                                    <input id="file-upload" type="file" accept="image/*" (change)="setLoader($event)" />
                                    <label class="choose-file" nbButton outline for="file-upload">Choose File</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="themeLoader" class="upload-box row" (drop)="setLoader($event)" (dragover)="onDragOver($event)">
            
                        <div class="col-md-7">
                            <p>
                                {{themeLoader.name}}<span *ngIf="themeLoader.size === 0">*</span>
                            </p>
                        </div>
                        <div class="col-md-5">
                            <ng-container *ngIf="themeLoader?.size > 0">
                                <div class="file-actions">
                                    <div class="action">
                                        <span *ngIf="themeLoader.size > 0">{{ themeLoader.size / 1024 / 1024 | number }} MB</span>
                                    </div>
                                    <div class="action">
                                        <input id="file-upload" type="file" accept="image/*" (change)="setLoader($event)" />
                                        <label class="choose-file" nbButton outline for="file-upload">Choose Different File</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                
                    </div>
                    <br/>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-7">
        <nb-card>
            <nb-card-header>Examples</nb-card-header>
            <nb-card-body>
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-header">
                            <div class="header-nav">
                              <a class="link nav-text active">Home</a>
                              <a class="link nav-text">Print</a>
                            </div>
              
                            <div class="header-actions">
                                <button nbButton>
                                    <nb-icon pack="oakmoon" icon="cloud-download"></nb-icon>
                                    <span class="desktop">Download</span>
                                </button>
                                
                                <button nbButton>
                                    <nb-icon pack="oakmoon" icon="print-text"></nb-icon>
                                    <span class="desktop">Print</span>
                                </button>
                                
                                <button nbButton>
                                    <nb-icon pack="oakmoon" icon="office-file-pdf"></nb-icon>
                                    <span class="desktop">Download PDF</span>
                                </button>
                                <a class="link nav-text close">
                                    <nb-icon pack="oakmoon" icon="close" status="control"></nb-icon>
                                </a>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-2">
                        <button nbButton status="primary">Primary</button><br/><br/>
                        <nb-toggle [checked]="true" status="primary"></nb-toggle>
                    </div>
                    <!-- <div class="col-md-2">
                        <button nbButton status="secondary">Secondary</button><br/><br/>
                        <nb-toggle [checked]="true" status="secondary"></nb-toggle>
                    </div> -->
                    <div class="col-md-2">
                        <button nbButton status="success">Success</button><br/><br/>
                        <nb-toggle [checked]="true" status="success"></nb-toggle>
                    </div>
                    <div class="col-md-2">
                        <button nbButton status="info">Info</button><br/><br/>
                        <nb-toggle [checked]="true" status="info"></nb-toggle>
                    </div>
                    <div class="col-md-2">
                        <button nbButton status="warning">Warning</button><br/><br/>
                        <nb-toggle [checked]="true" status="warning"></nb-toggle>
                    </div>
                    <div class="col-md-2">
                        <button nbButton status="danger">Danger</button><br/><br/>
                        <nb-toggle [checked]="true" status="danger"></nb-toggle>
                    </div>
                    <div class="col-md-12 data-prompt" [style.margin-top.px]="!logoToggleEnabled ? '-80' : ''">
                        <ifs-data-prompts [InfoSlip]="{ HideDataPromptsTitle: false }" [dataPrompts]="['Please enter your ID number to proceed']"></ifs-data-prompts>             
                    </div>
                </div>

            </nb-card-body>
        </nb-card>
    </div>
</div>