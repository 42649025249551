import { Injectable } from '@angular/core';
import { AllowedActionsModel, ForwardRequestModel, TwoFAValidationModel, PackageModel, RunRecipientModel, UserRegistrationModel } from '@InfoSlips/models';
import { ApiBaseService } from './base/api-base.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import {
  catchError,
  concatMap,
  map,
  tap,
  filter,
  take,
  switchMap, delay
} from 'rxjs/operators';
import { timer, throwError } from 'rxjs';
import { InfoSlipState } from '@InfoSlips/models';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentService } from '@InfoSlips/env';
import { AntiForgeryService } from '@InfoSlips/iiab-anti-forgery';


@Injectable({
  providedIn: 'root'
})
export class InfoSlipsService {

  selectedInfoSlipSubject = new BehaviorSubject<any>(null);
  selectedInfoSlip$ = this.selectedInfoSlipSubject.asObservable();

  actionStatesSubject = new BehaviorSubject<any>(null);
  actionStates$ = this.actionStatesSubject.asObservable();

  hasDataPromptsSubject = new BehaviorSubject<any>(null);
  hasDataPrompts$ = this.hasDataPromptsSubject.asObservable();

  getDataPromptsSubject = new BehaviorSubject<any>(null);
  getDataPrompts$ = this.getDataPromptsSubject.asObservable();

  hasOneTimePinSubject = new BehaviorSubject<any>(null);
  hasOneTimePin$ = this.hasOneTimePinSubject.asObservable();

  getPreRegDataSubject = new BehaviorSubject<any>(null);
  getPreRegData$ = this.getPreRegDataSubject.asObservable();

  getCurrentOtpSubject = new BehaviorSubject<any>(null);
  getCurrentOtp$ = this.getCurrentOtpSubject.asObservable();

  hasPukPromptSubject = new BehaviorSubject<any>(null);
  hasPukPrompt$ = this.hasPukPromptSubject.asObservable();

  errorSubject = new BehaviorSubject<any>(null);
  error$ = this.errorSubject.asObservable();

  constructor(
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService,
    private router: Router,
    private environment: EnvironmentService,
    private antiForgeryService: AntiForgeryService
  ) { }

  getInfoslip(token: string, sequence: number, historyAgent: string) {
    const hasHistoryAgent = historyAgent ? historyAgent.toString() : null;
    return this.apiBaseService.executeGet<any>(`viewer/${token}/${sequence}${hasHistoryAgent ? '/' + historyAgent : ''
      }`, "Get InfoSlip with or without history agent.")
      .pipe(
        delay(0),
        tap((currentInfoSlip: any) => {
          this.setInfoSlipState(currentInfoSlip);
        })
      );
  }

  setTabState(printable: boolean, allowedActions: AllowedActionsModel){
    const allowedActionsRunTime: AllowedActionsModel = {
      "DownloadIfs": this.router.url.toLowerCase().includes('adhoc') ? false : !allowedActions?.DownloadIfs ? false : true,
      "DownloadPdf": !allowedActions?.DownloadPdf ? false : printable,
      "Print": !allowedActions?.Print ? false : printable
    };

    this.actionStatesSubject.next(allowedActionsRunTime);
  }


  setInfoSlipState(currentInfoSlip: PackageModel) {
    if (currentInfoSlip?.Tabs != null) {
      this.setTabState(currentInfoSlip?.Tabs[0]?.Printable, currentInfoSlip.AllowedActions);
    }

    if (this.environment.isProxyEnabled) {
      if (currentInfoSlip?.StartPage?.startsWith("/api/viewer") && currentInfoSlip?.Tabs != null) {
        const proxyUrl = this.environment.apiUrl.slice(0,-1);
        currentInfoSlip.StartPage = proxyUrl + currentInfoSlip.StartPage;

        currentInfoSlip.Tabs.forEach(tab => {
          tab.StartPage.startsWith("/api/viewer") ? tab.StartPage = proxyUrl + tab.StartPage : undefined;
        });
      }
    }

    if (this.router.url.includes('completepreregistration')) {
      this.getPreRegistrationData(currentInfoSlip);
    }

    const oneTimePin = currentInfoSlip && currentInfoSlip.OneTimePin === null;
    this.selectedInfoSlipSubject.next(currentInfoSlip);
    this.hasDataPromptsSubject.next(currentInfoSlip && !currentInfoSlip.DataPromptsIsValid);
    this.getDataPromptsSubject.next(currentInfoSlip?.DataPrompts && currentInfoSlip.DataPrompts.length && currentInfoSlip.DataPrompts);
    this.hasPukPromptSubject.next(!currentInfoSlip?.IsPukLess);
    oneTimePin ? this.hasOneTimePinSubject.next(false) : this.hasOneTimePinSubject.next(currentInfoSlip && currentInfoSlip.OneTimePin && !currentInfoSlip.OneTimePin.Validated);

    this.getCurrentOtpSubject.next(currentInfoSlip && {
      hasRequestedOtp: false,
      otp: currentInfoSlip.OneTimePin
    });
  }

  getInfoSlipFromUrl(token: string, sequence: number) {
    return this.apiBaseService.executeGet<any>(`viewer/${token}/FromUrl/${sequence}`, "Get InfoSlip from URL", { observe: 'response' as 'body' }).pipe(
      delay(0),
      tap((currentInfoSlip: any) => {
        this.antiForgeryService.setVerificationToken(currentInfoSlip.headers);
        this.setInfoSlipState(currentInfoSlip.body);
      })
    );
  }

  getAdhoc(id: string) {
    return this.apiBaseService.executePost<any>(`viewer/adhoc/view/${id}`, null, "Get Adhoc slip", false, { observe: 'response' as 'body' }).pipe(
      delay(0),
      tap((currentInfoSlip: any) => {
        this.antiForgeryService.setVerificationToken(currentInfoSlip.headers);
        this.setInfoSlipState(currentInfoSlip.body.Package);
      }),
      catchError(
        (error: HttpErrorResponse): any => {
          this.errorSubject.next(error);
        },
      ),
    );
  }

  getOneTime(token: string) {
    return this.apiBaseService.executePost<any>(`viewer/onetime/view/${token}`, null, "Get Onetime slip", false, { observe: 'response' as 'body' }).pipe(
      delay(0),
      tap((currentInfoSlip: any) => {
        this.antiForgeryService.setVerificationToken(currentInfoSlip.headers);
        this.setInfoSlipState(currentInfoSlip.body.Package);
      })
    );
  }

  getPreRegistrationData(currentInfoSlip: PackageModel) {
    let url = currentInfoSlip.StartPage.replace('index.htm', 'processedData.js').split('/api/')[1];
    return this.apiBaseService.executeGetAny<any>(url, "Get InfoSlip JSON Data", { responseType: 'text' }).pipe(
      map(data => {
        let runRecipientJsonString = data
          .toString()
          .replace('var processedData =', '')
          .trim();

        if (runRecipientJsonString.substring(runRecipientJsonString.length - 1) === ';')
          runRecipientJsonString = runRecipientJsonString.substring(0, runRecipientJsonString.length - 1);

        const runRecipientJson = JSON.parse(runRecipientJsonString);
        return runRecipientJson;
      }),
    ).subscribe(res => {
      this.getPreRegDataSubject.next(res);
    });
  }

  downloadInfoSlip(router: Router, runRecipient: RunRecipientModel, ifsId: string) {
    const url = router.url;
    const packageInfo = {
      isLoggedIn: true,
      packageId: ifsId || null,
      runRecipientId: runRecipient != null ? runRecipient.Id : null
    };
    return this.downloadIfsFile(url, packageInfo);
  }

  downloadIfsFile(url: string, packageInfo: any) {
    const [_, urlType, id, sequence, historyAgent]: string[] = url.split('/');
    const urlPath = urlType === "infoslip" ?
      'IfsFile' :
      'IfsFileFromUrl'


    if (urlType === 'onetime') {
      const { packageId, runRecipientId } = packageInfo;
      return this.download(`viewer/onetime/ifs/${packageId}/${runRecipientId}`);
    }

    if (historyAgent != null) {
      return this.download(
        `RunRecipient/${id}/${historyAgent}/${urlPath}/${sequence}`
      );
    } else {
      return this.download(
        `RunRecipient/${id}/${urlPath}/${sequence}`
      );
    }
  }

  downloadPDF(infoslipState: InfoSlipState) {
    //destructure state into useable variables
    const { historyAgent } = infoslipState;
    const { Id, Name } = infoslipState.currentInfoSlip;
    const isAdhoc = Name === 'adhoc';
    const { TabName, StartPage } = infoslipState.activeTab;
    const fileName = StartPage.substring(
      infoslipState.activeTab.StartPage.lastIndexOf('/') + 1
    );
    //api calls and url
    const pollPDF = this.apiBaseService.executeGet<any>(`viewer/${Id}/GetPdfGenerationStatus/`, "Check PDF Status.");
    let pdfStatus = "";
    pollPDF.subscribe(res => pdfStatus = res.PdfStatus);
    
    const getPDF = `viewer/${Id}/GetPrintPdf/${fileName}/${isAdhoc}/${historyAgent ? historyAgent : undefined }/${TabName}`;
    const encodedQueryString = encodeURIComponent(infoslipState.activeTab.QueryString);
    const getPDFWithParams = `viewer/${Id}/GetPdfWithParams/${fileName}/${isAdhoc}/${infoslipState.activeTab.PathName}/${encodedQueryString}`;

    return timer(3000).pipe(
      concatMap(_ => {
        if (pdfStatus === "Error") {
          this.toaster.error("'Server Error: Failed to generate PDF. Please contact support.'");
          return throwError('Pdf Generation Error');
        }
        //turn each interval into a poll
        if (_ === 10) {
          this.toaster.info("Timeout: Pdf generation still busy, please try again in a couple of seconds.")
          return throwError('timeout reached'); //error, took too long
        }
        return pollPDF;
      }),
      filter((data: any) => {
        //filter out any PDF ready calls
        if (data.PdfStatus === 'Error' && data.Error === 'Invalid PackageId' && historyAgent) {
          return true;
        }
        return (
          data.PdfStatus === 'NotStarted' || data.PdfStatus === 'Completed'
        );
      }),
      take(1),
      catchError(error => {
        console.error(
          'Server Error: Failed to generate PDF. Please contact support.'
        );
        return throwError(error);
      }),
      switchMap(_ => {
        if(!infoslipState.activeTab.IsSinglePage)
          return this.download(getPDF)
        return this.download(getPDFWithParams)
      }) //download PDF
    );
  }

  download(path: string) {
    return this.apiBaseService.executeGetAny<any>(`${path}`, "Get InfoSlip Blob", {
      responseType: 'blob', // <-- changed to blob
      observe: 'response'
    }).pipe(tap(this.triggerDownloadEvent), catchError((error: HttpErrorResponse): Observable<any> => {
      if (error.status === 400) {
        error.error.text().then((text: string) => {
          this.toaster.info(text);
        });
      }
      return throwError(error);
    }));
  }

  triggerDownloadEvent(data: any) {
    let filename = data.headers.get('Content-Disposition') || 'download.zip';
    const foundEq = filename.indexOf('=');
    if (foundEq > -1) {
      filename = filename.substr(foundEq + 1);
    }

    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob) //IE & Edge
    {
      //msSaveBlob only available for IE & Edge
      // @ts-ignore
      window.navigator.msSaveBlob(data.body, filename);
    }
    else //Chrome & FF
    {
      const url = window.URL.createObjectURL(data.body); // <-- work with blob directly

      // create hidden dom element (so it works in all browsers)
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);

      // create file, attach to hidden element and open hidden element
      a.href = url;
      a.download = filename;
      a.click();
      return url;
    }
    return null;
  }

  closeInfoSlip() {
    this.router.navigateByUrl("/");
    this.setInfoSlipState(null);
  }

  forwardAdhoc(forwardModel: ForwardRequestModel) {
    return this.apiBaseService.executePost<any>(`viewer/adhoc/forward`, forwardModel, "Forward Adhoc slip");
  }

  validatePrompts(answers: any) {
    return this.apiBaseService.executePost<PackageModel>("viewer/validateDataPrompts", answers, "Validate InfoSlip Data Prompts.", true, { observe: 'response' as 'body' }).subscribe({
      next: (result: any) => {
        if (result?.headers) this.antiForgeryService.setVerificationToken(result.headers);

        if (result?.body?.DataPromptsIsValid) {
          this.toaster.success(
            'Data prompt validated!'
          );
          this.setInfoSlipState(result.body);
          return;
        }

        this.toaster.error(
          'Data prompt validation failed.'
        );
      },
      error: error => {
        this.toaster.error(
          'Data prompt validation failed.'
        );
      }
    });
  }

  requestOTP(id: string) {
    return this.apiBaseService.executeGet<any>(`viewer/otp/${id}/request`, "Request OTP for InfoSlip").pipe(
      map((response: any) => {
        this.toaster.success("OTP Requested");
        this.getCurrentOtpSubject.next({
          hasRequestedOtp: true,
          otp: response.OneTimePin
        });
        return response.OneTimePin.Validate;
      })
    );
  }
  validateOTP(PackageId: string, Code: string) {
    // this.facade.validateOTP(pin);
    const payload: TwoFAValidationModel = { PackageId, Code };

    return this.apiBaseService.executePost<any>(`viewer/otp/validate`, payload, "Validate OTP").pipe(
      map((infoslip: any) => {
        if ((infoslip.OneTimePin && infoslip.OneTimePin.Validated) || (infoslip.OneTimePin === null && !infoslip.DataPromptsIsValid)) {
          this.toaster.success("OTP Validated");
          this.hasOneTimePinSubject.next(false);
          this.setInfoSlipState(infoslip);
          return null;
        } else if (infoslip.OneTimePin && !infoslip.OneTimePin.Validated) {
          return this.toaster.error("PIN not correct");
        } else {
          return this.toaster.error("Internal error has occurred.");
        }
      })
    );
  }
  resendOTP(id: string) {
    // this.facade.resendOTP();
    return this.apiBaseService.executeGet<any>(`viewer/otp/${id}/resend`, "Resend OTP for InfoSlip").pipe(
      map((response: any) => {
        return this.toaster.info("OTP Requested");
      })
    );
  }

  submitPreRegistration(data: UserRegistrationModel) {
    return this.apiBaseService.executePost<any>(`registration`, data, "Submit Pre Registration.")
  }

  validatePUK(PackageId: string, PackagePuk: string) {
    const payload = { PackageId, PackagePuk };

    return this.apiBaseService.executePost<any>("viewer/validatePuk", payload, "Validate InfoSlip PUKs.", true, { observe: 'response' as 'body' }).subscribe({
      next: (result: any) => {
        this.antiForgeryService.setVerificationToken(result.headers);

        if (result.body.PukValidated) {
          this.toaster.success(
            'PUK validated!'
          );
          this.setInfoSlipState(result.body);
          return;
        }

        this.toaster.error(
          'PUK validation failed.'
        );
      },
      error: error => {
        this.toaster.error(
          'PUK validation failed.'
        );
      }
    });
  }

  test() {
    return "Valid";
  }
}
