import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { CustomerModel, IiabIdName, UpdatedKeyModel } from '@InfoSlips/models';
import { RunsService } from '@InfoSlips/iiab-api';
import { NbMenuItem, NbDialogService } from '@nebular/theme';
import { RunTemplateService, TemplateService } from '@InfoSlips/iiab-api';
import { ActivatedRoute } from '@angular/router';
import { CrudBaseService } from 'libs/iiab-api/src/lib/services/base/crud-base.service';
import { AuthService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-admin-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})


export class CustomersComponent implements OnInit {
  menuItem = "Customer Details";
  @Input() customer: CustomerModel;
  @Input() isNew: boolean;
  @Output() update = new EventEmitter<CustomerModel>();
  @Output() create = new EventEmitter<CustomerModel>();

  runTemplateNameFilter: string;
  templateNameFilter: string;
  linkedRunTemplates: IiabIdName[];
  linkedTemplates: IiabIdName[];

  add: string;
  AddCustomerContactForm: FormGroup;
  AddCustomerAddressForm: FormGroup;
  CustomerDetailForm: FormGroup;
  AddAccessTokenForm: FormGroup = this.fb.group({
    Name: ['', [Validators.required]],
    ApiUrl: ['', Validators.required],
    HostUrl: ['', Validators.required],
  });

  @ViewChild('dialog') private dialog: TemplateRef<any>;
  @ViewChild('accessToken') private accessTokenDialog: TemplateRef<any>;
  options: NbMenuItem[] = [
    {
      title: 'Customer Details',
      icon: {
        icon: "view",
        pack: "oakmoon"
      }
    },
    {
      title: 'Secure Keys',
      icon: {
        icon: "view",
        pack: "oakmoon"
      }
    },
    {
      title: 'Access Tokens',
      icon: {
        icon: "multiple-actions-settings",
        pack: "oakmoon"
      }
    },
    {
      title: 'Helpdesk Notes',
      icon: {
        icon: "multiple-actions-settings",
        pack: "oakmoon"
      }
    },
    {
      title: 'Contacts',
      icon: {
        icon: "file-code-download-1",
        pack: "oakmoon"
      }
    },
    {
      title: 'Addresses',
      icon: {
        icon: "multiple-actions-settings",
        pack: "oakmoon"
      }
    }
  ];

  customerDetails = [
    {
      "name": "Customer Name"
    },
    {
      "name": "Archive display name"
    },
    {
      "name": "Subdomain"
    },
    {
      "name": "Billing Group"
    },
    {
      "name": "S3 Upload Access ID"
    },
    {
      "name": "S3 Upload Access Key"
    },
    {
      "name": "Prefered SMS Senders"
    }
  ];

  contactTypes = [
    "Accounts",
    "Human resources",
    "Payroll",
    "Technical",
    "Marketing",
    "Administration",
    "Support",
    "General contact",
    "Contact person"
  ];

  addressTypes = [
    "Postal address",
    "Physical address",
    "Domicilium address"
  ];

  constructor(
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private runTemplateService: RunTemplateService,
    private templateService: TemplateService,
    private route: ActivatedRoute,
    private crudBaseService: CrudBaseService,
    private authService: AuthService,
    private runsService: RunsService,
  ) { }
  dialogRef;
  accessTokenDialogRef;

  async ngOnInit() {
    if (this.isNew) {
      this.customer = await this.crudBaseService.getDefault<CustomerModel>(0);
      
      setTimeout(() => {
        if (this.customer.Contacts.length == 0)
          this.customer.Contacts.push({
            Email: this.authService.loggedInUser.email,
            Name: this.authService.loggedInUser.displayName,
            ContactType: 7,
            ContactTypeDescription: "Customer Creator"
          });
      });
    }
    else {
      this.options.push(
        {
          title: 'Linked Items',
          icon: {
            icon: "multiple-actions-settings",
            pack: "oakmoon"
          }
        }    
      );
      this.linkedSearch('RunTemplate', null)
      this.linkedSearch('Template', null)
    }
  }

  setMenuItem(item) {
    this.menuItem = item;
  }

  createCustomer() {
    this.create.emit(this.customer)
  }

  updateCustomer() {
    this.update.emit(this.customer);
  }

  editItem(object, type, index) {
    this.add = type;
    object['IsNew'] = false;
    object['index'] = index;
    switch (type) {
      case "address":
        this.AddCustomerAddressForm = this.fb.group({
          AddressType: [object.AddressType, [Validators.required]],
          Line1: [object.Line1, [Validators.required]],
          Line2: [object.Line2],
          Line3: [object.Line3],
          City: [object.City, [Validators.required]],
          Country: [object.Country, [Validators.required]],
          Code: [object.Code, [Validators.required]],
          AddressTypeDescription: [object.AddressTypeDescription, [Validators.required]],
          Id: null
        });
        break;
      case "contact":
        this.AddCustomerContactForm = this.fb.group({
          ContactType: [object.ContactType, [Validators.required]],
          Name: [object.Name, [Validators.required]],
          Email: [object.Email, [Validators.required, Validators.email]],
          Phone: [object.Phone],
          ContactTypeDescription: [object.ContactTypeDescription],
          Id: null
        });
        break;
    }
    this.dialogRef = this.dialogService.open(this.dialog, { context: object });
  }

  addItem(type) {
    this.add = type;
    if (type === 'contact') {
      if (this.AddCustomerContactForm) {
        this.AddCustomerContactForm.reset();
      } else {
        this.AddCustomerContactForm = this.fb.group({
          ContactType: [0, [Validators.required]],
          Name: ['', [Validators.required]],
          Email: ['', [Validators.required, Validators.email]],
          Phone: null,
          ContactTypeDescription: [''],
          Id: null
        });
      }

    } else {
      if (this.AddCustomerAddressForm) {
        this.AddCustomerAddressForm.reset();
      } else {
        this.AddCustomerAddressForm = this.fb.group({
          AddressType: [0, [Validators.required]],
          Line1: ['', [Validators.required]],
          Line2: [''],
          Line3: [''],
          City: ['', [Validators.required]],
          Country: ['', [Validators.required]],
          Code: ['', [Validators.required]],
          AddressTypeDescription: ['', [Validators.required]],
          Id: null
        });
      }
    }
    this.dialogRef = this.dialogService.open(this.dialog, { context: {IsNew: true}});
  }

  openTokenDialogue() {
    const tokenBase = {
      Name: '',
      AuthData: {
        HostUrl: '',
        ApiUrl: '',
        Parameters: {}
      },
      AuthHeaderName: null,
      AuthHeaderPrefix: null,
      IsNew: true
    };
    this.dialogRef = this.dialogService.open(this.accessTokenDialog, { context: tokenBase })
  }

  addToken(token) {
    this.customer.AccessTokens.push(token);
    this.dialogRef.close();
  }

  removeItem(object, context) {
    this.customer[context] = this.customer[context].filter(item => item != object);
  }

  selectTokenToEdit(token, index) {
    token['IsNew'] = false;
    this.dialogRef = this.dialogService.open(this.accessTokenDialog, { context: {token: token, index: index } })
  }

  editExistingToken(token, index) {
    this.customer.AccessTokens[index] = token;
    this.dialogRef.close();
  }

  addCustomerContact(index?: number) {
    if (index != null && index != undefined)
      this.customer.Contacts[index] = this.AddCustomerContactForm.value
    else
      this.customer.Contacts.push(this.AddCustomerContactForm.value);
    this.dialogRef.close();
  }

  addCustomerAddress(index?: number) {
    if (index != null && index != undefined)
      this.customer.Addresses[index] = this.AddCustomerAddressForm.value
    else
      this.customer.Addresses.push(this.AddCustomerAddressForm.value);
    this.dialogRef.close();
  }

  onContactTypeChange(evt) {
    console.log(evt);
  }

  addParameter(params) {
    params['newParam'] = 'NewParamValue';
  }

  changeParameterKey($event, object, key) {
    this.changeKey(object, key, $event)
  }

  removeParameter(params, key) {
    delete params[key];
  }

  linkedSearch(linkType: string, filter: string): void {
    const nameFilter = {
      name: {like: filter??''},
      customer: {
        id: {
          eq: this.route.snapshot.paramMap.get('id')
        }
      }
    };

    if (filter!=null && filter.length < 3)
      return;

    if (linkType === 'RunTemplate') {
      this.runTemplateService.runTemplateContext(nameFilter, 500).subscribe(items=>{
        this.linkedRunTemplates=items;
      });
    }
    else {
      this.templateService.templateContext(nameFilter, 500).subscribe(items=>{
        this.linkedTemplates=items;
      });
    }
  }

  updateKey(secureKey: UpdatedKeyModel){
    this.customer[secureKey.model] = secureKey.value;
  }

  customerOnBlur(value){
    this.isNew ? this.customer.ArchiveDisplayName = value : undefined;
  }  

  goToRuns() {
    const newFilter = {
      "customer" : {
        "id": this.customer.Id,
        "name": this.customer.Name
      }
    }
    this.runsService.navigateHomeAndFilterRuns(newFilter);
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  //#region private

  private changeKey(obj: any, oldKey: string, newKey: string): void {
    if (oldKey !== newKey && obj.hasOwnProperty(oldKey)) {
      // Step 1: Add new key with old key's value
      obj[newKey] = obj[oldKey];
  
      // Step 2: Delete the old key
      delete obj[oldKey];
    }
  }
  //#endregion
}
