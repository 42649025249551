<nb-card class="input-box section-card">
  <nb-card-header>
      Content Type

      <div style="margin-top: -6px !important;" class="select">
        <nb-toggle *ngIf="cmsContentModel.CmsContentType === 0" status="info" class="source-toggle" (checkedChange)="toggleSource()">Source</nb-toggle>
        <nb-select [(ngModel)]="selectedContentType" placeholder="Select Content Type" (selectedChange)="contentTypeSelected($event)">
            <nb-option *ngFor="let item of cmsContentTypes$ | async" [value]="item">{{item.value}}</nb-option>
        </nb-select>
      </div>
  </nb-card-header>
  <nb-card-body class="padded" *ngIf="isEnabledMonaco || isEnabledTiny">
        <ifs-monaco-editor *ngIf="isEnabledMonaco" [(data)]="cmsContentModel.Data"
          (onChange)="cmsContentModel.Data = $event"></ifs-monaco-editor>
        <ifs-tiny-editor *ngIf="isEnabledTiny" [(data)]="cmsContentModel.Data"
          (dataChange)="cmsContentModel.Data = $event"></ifs-tiny-editor>
  </nb-card-body>
  <nb-card-footer>
    <div *ngIf="!uploadFile" class="upload-box row" (drop)="fileChange($event)" (dragover)="fileChange($event)">
      <div class="col-md-8">
          <p>
              Add Content File
          </p>
      </div>
      <div class="col-md-4">
          <div class="file-actions flex-right">
              <span>
                  <input id="file-upload" type="file" (change)="fileChange($event)" />
                  <label class="choose-file" nbButton outline for="file-upload">
                      {{ cmsContentModel.PublicId ? "Replace File" : "Choose File"}}
                  </label>
              </span>
          </div>
      </div>
  </div>
  <div *ngIf="uploadFile" class="upload-box row" (drop)="fileChange($event)" (dragover)="fileChange($event)">

      <div class="col-md-7">
          <p>
              {{uploadFile.name}}<span *ngIf="uploadFile.size === 0">*</span>
          </p>
      </div>
      <div class="col-md-5">
          <ng-container *ngIf="uploadFile?.size > 0">
              <div class="file-actions">
                  <div class="action">
                      <span *ngIf="uploadFile.size > 0">{{ uploadFile.size / 1024 / 1024 | number }} MB</span>
                  </div>
                  <div class="action">
                      <input id="file-upload" type="file" (change)="fileChange($event)" />
                      <label class="choose-file" nbButton outline for="file-upload">Choose Different File</label>
                  </div>
              </div>
          </ng-container>
      </div>
  </div>
  </nb-card-footer>
</nb-card>