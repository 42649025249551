<div class="general-page-container fit-absolute">
    <div class="general-page-header">
        <div class="title-bar row">
            <div class="col-md-8">
                <h4 class="title">{{context}}</h4>
            </div>
            <div class="col-md-4 text-right">
                <button nbButton ghost class="close" (click)="close()">
                    <nb-icon icon="close" pack="oakmoon" size="28"></nb-icon>
                </button>                
            </div>
        </div>
    </div>
    <div class="general-page-body">
        <div class="general-side-nav full-width">
            <ng-container *ngIf="step===0">
                <input
                    class="col-12 col-md-12"
                    nbInput
                    fullWidth 
                    type="text"
                    name="search"
                    placeholder="search..."
                    [(ngModel)]="searchPhrase"
                    class="search"
                    *ngIf="contextMenu[0]?.data.actions.length > 0 && contextMenu[0]?.data.actions[0].includes('filter')"
                />
                <div [ngClass]="context === 'Reports' ? 'context-menu-container' : ''">
                    <ng-container *ngFor="let option of contextMenu | filter :'data' : searchPhrase: 'title'; index as i">
                        <div id="{{option.data.title}}" class="context-menu" (click)="loadComponent(i)" [routerLink]="option.data.isRoute ? [option.data.route] : null">
                            <div class="icon text-center">
                                <nb-icon class="ml-15" [ngClass]="option.data.pack === 'oakmoon-v2' ? 'icon-v2' : ''" [icon]='option.data.icon' [pack]="option.data.pack" size="30"></nb-icon>
                            </div>
                            <div class="body">
                                <h4 class="title">{{option.data.title}}</h4>
                            </div>
                            <div class="action text-center" >
                                <ng-container *ngFor="let action of option.data.actions">
                                    <button *ngIf="action==='search'" (click)="loadComponent(i)" class="mr-15" nbButton size="small" status="primary">
                                        <nb-icon [icon]="action" pack="oakmoon" size="20"></nb-icon>
                                    </button>
                                    <button *ngIf="action==='add'" [routerLink]="[option.data.route]" class="mr-15" nbButton size="small" status="primary">
                                        <nb-icon [icon]="action" pack="oakmoon" size="20"></nb-icon>
                                    </button>
                                    <button *ngIf="action==='create'" (click)="loadComponent(i, option.data.createComponent, option.data.createComponent.data.title)" class="mr-15" nbButton size="small" status="primary">
                                        <nb-icon icon="add" pack="oakmoon" size="20"></nb-icon>
                                    </button>
                                    <button *ngIf="action==='create-external'" [routerLink]="[option.data.route]" class="mr-15" nbButton size="small" status="primary">
                                        <nb-icon icon="add" pack="oakmoon" size="20"></nb-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
            <ng-template menuHost #dynamicComponent></ng-template>
        </div>
    </div>
</div>