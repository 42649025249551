import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiBaseService, RunsService } from '@InfoSlips/iiab-api';
import { RunTemplateModel, ProtectionType } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';

interface SearchFieldTags {
  Key: string;
  Selected: boolean;
}

interface DataFilter {
  key: string;
  value: string;
  protectionType: string;
}

@Component({
  selector: 'ifs-admin-run-search-data',
  templateUrl: './run-search-data.component.html',
  styleUrls: ['./run-search-data.component.scss']
})
export class RunSearchDataComponent implements OnInit {

  runTemplateId: string = this.route.snapshot.paramMap.get('runTemplateId');
  runTemplate: RunTemplateModel;
  searchFields: SearchFieldTags[];
  dataFilter: DataFilter[] = [];

  hasRunSearch = false;

  optionalFields = [
    {
      key: 'runId',
      name: 'Run ID',
      value: null
    },
    {
      key: 'runRecipientId',
      name: 'Run Recipient ID',
      value: null
    },
    {
      key: 'externalId',
      name: 'External ID',
      value: null
    }
  ]
  optionalFromDate;
  optionalToDate;

  runSearchData$ = this.runsService.runSearchData.Items$();
  loadingRunSearchData$ = this.runsService.runSearchData.isLoading$;

  constructor(
    private route: ActivatedRoute,
    private apiBaseService: ApiBaseService,
    private runsService: RunsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.apiBaseService.executeGet(`RunTemplate/${this.runTemplateId}`, 'Get Run Template').subscribe((response: RunTemplateModel) => {
      this.runTemplate = response;
      this.searchFields = response.SearchFields.map((field: string) => {
        return {
          Key: field,
          Selected: false
        };
      })
    })
  }

  selectField(field: SearchFieldTags){
    field.Selected = !field.Selected;
    if(field.Selected){
      const protectedField = this.runTemplate.ProtectedFields.find(item => item.SearchFieldLinkedDescription === field.Key);

      this.dataFilter.push({
        key: field.Key,
        value: null,
        protectionType: ProtectionType[protectedField?.ProtectionType] ?? 'None'
      })
    } else {
      this.dataFilter = this.dataFilter.filter(item => item.key != field.Key);
    }
  }

  enquire(){
    if (this.optionalFromDate && !this.optionalToDate) {
      this.toastr.warning(`The date range requires both a from and a to date.`, 'Warning:');
      return;
    }

    let isValid = true;
    this.hasRunSearch = false;

    const filter = { 
      runTemplateId: this.runTemplateId, 
      customerId: this.runTemplate.Customer.Id,
      dataFilter: this.dataFilter.map(item => {
        const field = ({...item})
        if(!field.value){
          this.toastr.error(`Selected enquiry fields cannot be null. please enter a value for the ${item.key} field.`, 'Error:');
          isValid = false;
        }
        delete field.protectionType;
        return field;
      })
    }

    if (this.optionalFromDate) {
      filter['fromDate'] = this.optionalFromDate;
      filter['toDate'] = this.optionalToDate;
    }

    this.optionalFields.forEach(item => {
      if(item.value){
        filter[item.key] = item.value
      }
    })

    if(!isValid)
      return;

    this.hasRunSearch = true;
    this.runsService.runSearchData.loadPagedItems(filter);
  }

  focus(input) {
    document.getElementById(input).focus()
  }
}