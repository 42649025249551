export interface TwoFAInfoModel {
    Url: string;
    Code: string;
}

export interface SaveTwoFAModel {
    VerificationCode: string;
    OtpEnabled: boolean;
  }

export interface TwoFAValidationModel{
    PackageId:string;
    Code:string;
}
