import { tap } from 'rxjs/operators';
import { PasswordMatch } from '@InfoSlips/shared';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifs-pre-register',
  template: `
    <ifs-logged-out-view>
      <div class="login-wrapper" *ngIf="pipePreRegisterData$ | async">
        <form [formGroup]="preRegisterForm" (ngSubmit)="onSubmit()" class="login">
        
          <p class="pull-left title" data-e2e="cardTitle">
            Complete your registration
          </p>
          <br style="clear: both;"/>

          <div class="form-body row">
          <div class="col-md-12">
            <nb-form-field>
              <label>Name & Surname</label>
              <input nbInput type="text" fullWidth formControlName="DisplayName" />
            </nb-form-field>
            <nb-form-field>
              <label>Username</label>
              <input nbInput type="text" fullWidth formControlName="UserName" />
            </nb-form-field>
            <nb-form-field>
              <label>Password</label>
              <input [type]="getInputType()" nbInput formControlName="Password" fullWidth />
              <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva" [attr.aria-label]="showPassword ? 'hide password' : 'show password'"></nb-icon>
              </button>
              <!-- <clr-control-error>Error</clr-control-error> -->
            </nb-form-field>
            <nb-form-field>
              <label>Confirm password</label>
              <input [type]="getInputType()" nbInput formControlName="ConfirmPassword" fullWidth />
              <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva" [attr.aria-label]="showPassword ? 'hide password' : 'show password'"></nb-icon>
              </button>
              <div class="danger" *ngIf="!passwordMatch">Passwords do not match!</div>
            </nb-form-field>
            <nb-form-field>
              <label>Mobile Number</label>
              <input nbInput type="text" fullWidth formControlName="Mobile" />
            </nb-form-field>
            <nb-form-field>
              <label>Email</label>
              <input nbInput type="email" formControlName="Email" fullWidth />
              <!-- <clr-control-error>Invalid email</clr-control-error> -->
            </nb-form-field>

            <div class="alert alert-danger alert-sm" *ngIf="error" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <!-- <clr-icon
                      class="alert-icon"
                      shape="exclamation-circle"
                    ></clr-icon> -->
                  </div>
                  <div class="danger">Complete all fields</div>
                </div>
              </div>
              <!--
                  <button type="button" class="close" aria-label="Close">
                    <clr-icon aria-hidden="true" shape="close"></clr-icon>
                  </button>
                -->
            </div>

            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block mt-2" nbButton status="primary">
                  Complete Registration
                </button>
              </div>
            </div>
          </div>
          </div>
        </form>
      </div>
    </ifs-logged-out-view>
  `,
  styleUrls: ['./pre-register.component.scss']
})
export class PreRegisterComponent implements OnInit, OnChanges {
  error = false;
  passwordMatch = true;
  preRegisterForm: FormGroup;
  showPassword = false;

  @Input() preRegisterData$;
  @Output() submitRegistration$ = new EventEmitter<any>();
  pipePreRegisterData$: Observable<any>;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.preRegisterForm = this.fb.group(
      {
        DisplayName: ['', Validators.required],
        UserName: [{ value: '', disabled: true }, Validators.required],
        Password: ['', Validators.required],
        ConfirmPassword: ['', Validators.required],
        Mobile: [''],
        Email: ['', Validators.required],
        Token: [''],
        ErrorMessage: ['']
      },
      {
        validator: PasswordMatch('Password', 'ConfirmPassword')
      }
    );

    this.pipePreRegisterData$ = this.preRegisterData$.pipe(
      tap(data => {
        const patchedData = this.getDataForPatch(data);
        this.preRegisterForm.patchValue(patchedData);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {}

  getDataForPatch(processedData) {
    const { Name, Email, Mobile, ActivationLinkToken } = processedData;

    const newRegistration = {
      DisplayName: Name,
      UserName: '',
      Email: Email,
      Mobile: Mobile,
      Token: ActivationLinkToken,
      Password: '',
      ConfirmPassword: '',
      ErrorMessage: null
    };

    let isMobileRegistration = false;
    if (Mobile && (Email === '#' || Email.indexOf('@') === -1)) {
      const reg = /^\d+$/;
      isMobileRegistration = reg.test(Mobile);
    }

    if (isMobileRegistration) {
      // Mobile registration
      newRegistration.Email = Mobile + '@infoslipscloud.com';
      newRegistration.UserName = Mobile;
      return newRegistration;
    }

    if ((Email === '#' || Email.indexOf('@') === -1) && !Mobile) {
      this.error = true;
      return newRegistration;
    }

    // Email registration
    newRegistration.Mobile = '';
    newRegistration.UserName = Email;
    return newRegistration;
  }

  onSubmit() {
    console.log('form submit', this.preRegisterForm);
    if(this.preRegisterForm.value.Password != this.preRegisterForm.value.ConfirmPassword){
      this.passwordMatch = false;
      return;
    } 
    this.passwordMatch = true;
    this.preRegisterForm.value.UserName = this.preRegisterForm.value.Email;

    const form = this.preRegisterForm;
    if (form.invalid) {
      this.error = true;
      return;
    }
    // if (!this.dataPromptAnswers.length) {
    //   this.error = true;
    //   return;
    // }
    this.submitRegistration$.emit(form.value);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
}
