
export interface LoggedInModel {
    '.expires': string;
    '.issued': string;
    'access_token': string;
    'as:client_id': string;
    'displayName': string;
    'email': string;
    'expires_in': number;
    'permissions': string;
    'redirectUrl': string;
    'refresh_token': string;
    'resetPassword': string;
    'token_type': string;
    'userMetadata': string;
    'userName': string;
    'weakPassword': string;
    'weakPasswordError': string;
}

export interface LoginModel {
    username: string,
    password: string,
    otp?: string,
    oldAdmin?: boolean
}

export interface IAuthError {
    error: string,
    description: string
}
export class AuthError implements IAuthError {
    error: string
    description: string
}

export interface EchoModel{
    ServerDateTime:Date;
      Version:string 
      Environment:string
      MachineName:string 
      LinkedServer:EchoModel
      Status:any 
  }

export interface SsoModel{
    ClientId:string;
    Code:string;
}
  