import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from '@InfoSlips/iiab-api';
import { TwoFAInfoModel, SaveTwoFAModel } from '@InfoSlips/models';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthUrlsService } from './auth-urls-service';

@Injectable({
  providedIn: 'root'
})
export class TwoFAService {
 
  
  constructor(
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService,
    private urls: AuthUrlsService
  ) 
  {}

  getOtpInfo():Observable<TwoFAInfoModel> {
    return this.apiBaseService.executeGet<TwoFAInfoModel>(this.urls.GetTTwoFAUrl, "Retrieve OTP Info");
  }

  toggleOtp(enableTwoFA: boolean): Observable<boolean> {
    return this.apiBaseService.executeGet<boolean>(this.urls.ToggleTwoFAUrl(enableTwoFA), "Toggle OTP");
  }

  saveOtp(model: SaveTwoFAModel) {
    return this.apiBaseService.executePost<boolean>(this.urls.SaveTwoFAUrl, model, "Save OTP")
    .subscribe({
      next: (result) => {
        if (result) {          
          this.toaster.success(`OTP Enabled: $(model.OtpEnabled)`);
          return;
        }

        this.toaster.error('OTP activation failed.');
      },
      error: (error) => this.toaster.error(`OTP activation failed: $(error)`)
    });
  }  
}