<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header *ngIf="isNew">
                <nb-checkbox status="basic" [(ngModel)]="template.EmailTemplate.IsEnabled" [disabled]="template?.OpenRunSchedule && template?.OpenRunSchedule != 0">Send email to recipients?</nb-checkbox>
            </nb-card-header>
            <nb-card-body *ngIf="tinyEnabled && template.EmailTemplate.IsEnabled || monacoEnabled && template.EmailTemplate.IsEnabled">
                <nb-toggle *ngIf="!disabled" status="info" class="source-toggle" (checkedChange)="toggleSource()">Source</nb-toggle>
                <button *ngIf="disabled" class="source-toggle" nbButton status="primary" routerLink="/cms/edit/{{emailEntity.Id}}" [queryParams]="{ lastUrl: '/admin/runtemplates/' + template.Id}">
                    <nb-icon pack="eva" icon="edit-2"></nb-icon>Edit Email Entity</button>
                <nb-tabset>
                    <nb-tab tabTitle="Mailbody">
                        <div class="row row-editor">
                            <div class="col-md-12">
                                <ngx-monaco-editor *ngIf="monacoEnabled" height="800px" (ngModelChange)="template.EmailTemplate.Body = $event" [(ngModel)]="template.EmailTemplate.Body" [options]="monacoConfig.editorOptions"></ngx-monaco-editor>
                                <ifs-tiny-editor *ngIf="tinyEnabled" [(data)]="template.EmailTemplate.Body" theme="light" (dataChange)="template.EmailTemplate.Body = $event" [disabled]="disabled"></ifs-tiny-editor>
                            </div>
                        </div>                        
                    </nb-tab>
                    <nb-tab tabTitle="Settings">
                        <div class="email-options">
                            <div class="row">
                              <ng-container *ngFor="let setting of emailSettings; last as isLast">
                                <div [class]="'col-md-4'">
                                    <label><p>{{ setting.label }}</p></label>
                                    <input type="text" nbInput fullWidth [(ngModel)]="template.EmailTemplate[setting.name]" (ngModelChange)="settingChange($event, setting.name, 'email')">
                                </div>
                              </ng-container>
                              <div class="col-md-12">
                                <br/>
                                <label>
                                    <p>Additional Options</p>
                                </label>
                                <div class='inline-check'>
                                    <nb-checkbox (checkedChange)="settingChange($event, 'TrackReadEmail')" [(ngModel)]="template.EmailTemplate.TrackReadEmail">Track read email?</nb-checkbox>
                                    <nb-checkbox (checkedChange)="settingChange($event, 'IsAllowedAdhocForward')" [(ngModel)]="template.IsAllowedAdhocForward">Allow Adhoc Forward?</nb-checkbox>
                                    <nb-checkbox (checkedChange)="settingChange($event, 'DisableApiForward')" [(ngModel)]="template.DisableApiForward">Disable Api Adhoc Forward?</nb-checkbox>
                                </div>
                              </div>
                            </div>
                            </div>
                    </nb-tab>
                </nb-tabset>
            </nb-card-body>
        </nb-card>
    </div>
</div>