import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RunTemplateService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { AdditionalCaptureFieldsModel, RequiredSignaturesModel, CaptureFieldInputType } from '@InfoSlips/models';

@Component({
  selector: 'ifs-admin-digital-signatures',
  templateUrl: './digital-signatures.component.html',
  styleUrls: ['./digital-signatures.component.scss']
})
export class DigitalSignaturesComponent implements OnInit {

  @Input() template;

  @ViewChild('autoInput') input;
   
  constructor(    
    private runTemplateService: RunTemplateService
  ) {
  }

  captureFieldInputTypes = CaptureFieldInputType;

  runTemplates$: Observable<IiabIdName[]>;
  newRequiredSignature: RequiredSignaturesModel;
  editRequiredSignature: RequiredSignaturesModel;
  newCaptureField: AdditionalCaptureFieldsModel;
  editCaptureField: AdditionalCaptureFieldsModel;
  memorySigField: RequiredSignaturesModel;
  memoryCaptureField: AdditionalCaptureFieldsModel;
  
  selectedRunTemplate: string;
  selectedRunTemplateId: string;

  ngOnInit(): void {
    this.runTemplateService.runRunTemplates.loadPagedItems();
    this.runTemplates$ = this.runTemplateService.runRunTemplates.Items$().pipe(exhaustMap((res) => of(res)));

    this.newCaptureField = this.blankCaptureField();
    this.newRequiredSignature = this.blankRequiredSignature();

    this.editCaptureField = this.blankCaptureField();
    this.editRequiredSignature = this.blankRequiredSignature();
  }
  
  onChange(event){
    const wordSearch = this.input.nativeElement.value;
    this.runTemplates$ = of([{ id: 'Loading..', name: 'Loading..'}]);

    const FilterObject = wordSearch.length === 24 ? 
    { 
      id: 
      { 
        eq: this.input.nativeElement.value 
      }
    } 
    :
    { 
      name: 
      { 
        like: this.input.nativeElement.value 
      }
    }

    setTimeout(() => {
      if (wordSearch == this.input.nativeElement.value) {
          this.runTemplateService.runRunTemplates.loadPagedItems(FilterObject);
          this.runTemplates$ = this.runTemplateService.runRunTemplates.Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 600);
  }

  onSelectionChange($event) {
    this.runTemplates$.subscribe((res: any) => {
      res.find(item => {
        if(item.name === this.input.nativeElement.value){
          this.selectedRunTemplateId = item.id;
          this.selectedRunTemplate = item.name;
          this.runTemplates$ = of([item]);
        }
      })
    });
  }

  addRequiredSignature() {
    if (this.template.Signatures.RequiredSignatures == undefined) {
      this.template.Signatures.RequiredSignatures = [
        {
          "Name": this.newRequiredSignature.Name,
          "Required": this.newRequiredSignature.Required
        }
      ]
    }
    else this.template.Signatures.RequiredSignatures.push(this.newRequiredSignature);
    
    this.editRequiredSignature = this.blankRequiredSignature();
  }

  addCaptureField() {
    if (this.template.Signatures.AdditionalCaptureFields == undefined) {
      this.template.Signatures.AdditionalCaptureFields = [
        {
          "Name": this.newCaptureField.Name,
          "InputType": this.newCaptureField.InputType
        }
      ]
    }
    else this.template.Signatures.AdditionalCaptureFields.push(this.newCaptureField);
    
    this.editCaptureField = this.blankCaptureField()
  }

  removeRequiredSigItem(index: number){
    this.template.Signatures.RequiredSignatures.splice(index, 1);
  }

  removeCaptureField(index: number){
    delete this.template.Signatures.AdditionalCaptureFields[index];
  }

  InputTypeKeys() : Array<string> {
    const keys = Object.keys(this.captureFieldInputTypes);
    return keys.slice(keys.length / 2);
  }

  confirmRequiredSigItemEdit() {
    this.editRequiredSignature = this.blankRequiredSignature();
  }

  confirmCaptFieldEdit() {
    this.editCaptureField = this.blankCaptureField();
  }

  cancelRequiredSigItemEdit(index: number) {
    this.template.Signatures.RequiredSignatures[index] = this.memorySigField;
    this.editRequiredSignature = this.blankRequiredSignature();
    this.memorySigField = undefined;
  }

  cancelCaptFieldEdit(index: number) {
    this.template.Signatures.AdditionalCaptureFields[index] = this.memoryCaptureField;
    this.editCaptureField = this.blankCaptureField();
    this.memoryCaptureField = undefined;
  }

  activeEditSignatureField(sigField: RequiredSignaturesModel) {
    this.memorySigField = JSON.parse(JSON.stringify(sigField))
    this.editRequiredSignature = sigField;
  }

  activeEditCaptField(capField: AdditionalCaptureFieldsModel) {
    this.memoryCaptureField = JSON.parse(JSON.stringify(capField))
    this.editCaptureField = capField;
  }

  blankCaptureField(): AdditionalCaptureFieldsModel {
    return {
      "Name": "",
      "InputType": ""
    }
  }

  blankRequiredSignature(): RequiredSignaturesModel {
    return {
      "Name": "",
      "Required": false
    }
  }
}
