import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
  UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@InfoSlips/iiab-auth';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';
import { IIABCacheKey } from '@InfoSlips/models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private authService: AuthService,
    private localStorageService: IiabLocalStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // Store last active URL prior to logout, so user can be redirected on re-login
    if(state.url !== "/auth/login" && !state.url.includes("completepreregistration" ) && !state.url.includes("password") && !state.url.includes("register/confirm")){
      this.localStorageService.set<string>(IIABCacheKey.ReturnUrl, state.url, true);
    }

    return this.authService.isAuthenticated$.pipe(
      map(isAuthenticated => {

        // if(isAuthenticated){
        //   this.router.navigate(['/overview']);
        //   return true;
        // }

        if(this.router.url.includes("auth/login") || this.router.url === "/" || !isAuthenticated){
          return true;
        }
        
        if(next.queryParams.context && !isAuthenticated){
          return true;
        }

        return isAuthenticated;
      })
    );
  }
}
