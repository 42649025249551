export interface TemplateMergeFieldsModel {
    Name:     string;
    Property: null | string;
    Children: TemplateMergeFieldsModel[] | null;
}

export interface TemplateMergeFieldsNebular {
    data:     TemplateMergeFieldsData;
    children: TemplateMergeFieldsNebular[] | null;
}

export interface TemplateMergeFieldsData {
    name: string;
    path: null | string;
}