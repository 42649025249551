import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { ReportShellComponent } from './containers/report-shell/report-shell.component';
import { ReportComponent } from './containers/report/report.component';
import { AuthGuard, SharedModule } from '@InfoSlips/shared';
import { NebularModule } from '@InfoSlips/nebular';
import { DeliveryReportComponent } from './components/delivery-report/delivery-report.component';
import { ResendsComponent } from './components/resends/resends.component';
import { RunStatisticsComponent } from './components/run-statistics/run-statistics.component';
import { MailResponseLogComponent } from './components/mail-response-log/mail-response-log.component';
import { UrlTrackingReportComponent } from './components/url-tracking-report/url-tracking-report.component';
import { ViewerPostComponent } from './components/viewer-post/viewer-post.component';
import { HotSpotReportComponent } from './components/hot-spot-report/hot-spot-report.component';
import { FilterContainer } from './containers/filter/filter.component';
import { FilterComponent } from './components/filter/filter.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RecipientBlacklistComponent } from './components/recipient-blacklist/recipient-blacklist.component';
import { SmsResponseLogComponent } from './components/sms-response-log/sms-response-log.component';
import { SystemUsersComponent } from './components/system-users/system-users.component';
import { AdhocViewsLogComponent } from './components/adhoc-views-log/adhoc-views-log.component';
import { AdhocForwardDeliveriesComponent } from './components/adhoc-forward-deliveries/adhoc-forward-deliveries.component';
import { QueueActivityComponent } from './components/queue-activity/queue-activity.component';
import { ControlsModule } from '@InfoSlips/controls';
import { BillingReportComponent } from './components/billing-report/billing-report.component';

export const reportsChildRoutes: Route[] = [
  {
    path: '',
    component: ReportShellComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'delivery-report', 
    component: DeliveryReportComponent, 
    canActivate: [AuthGuard]
  },
  { 
      path: 'resends', 
      component: ResendsComponent, 
      canActivate: [AuthGuard]
  },
  { 
      path: 'run-statistics', 
      component: RunStatisticsComponent, 
      canActivate: [AuthGuard]
  },
  { 
      path: 'mail-response-log', 
      component: MailResponseLogComponent, 
      canActivate: [AuthGuard]
  },
  { 
      path: 'url-tracking-report', 
      component: UrlTrackingReportComponent, 
      canActivate: [AuthGuard]
  },
  { 
      path: 'viewer-post', 
      component: ViewerPostComponent, 
      canActivate: [AuthGuard]
  },
  { 
      path: 'hot-spot-report', 
      component: HotSpotReportComponent, 
      canActivate: [AuthGuard]
  },
  { 
    path: 'recipients-blacklist-report', 
    component: RecipientBlacklistComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'sms-response-log', 
    component: SmsResponseLogComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'system-users-report', 
    component: SystemUsersComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'adhoc-views-log', 
    component: AdhocViewsLogComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'adhoc-forward-delivery-report', 
    component: AdhocForwardDeliveriesComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'queue-activity-report', 
    component: QueueActivityComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'billing-reports', 
    component: BillingReportComponent, 
    canActivate: [AuthGuard]
  },
]

export const reportsRoutes: Route[] = [
  {
    path: 'report',
    component: ReportShellComponent,
    canActivate: [AuthGuard],
    children: reportsChildRoutes
  },
  {
    path: ':runId',
    component: ReportShellComponent,
    canActivate: [AuthGuard],
    children: reportsChildRoutes
  },
];

@NgModule({
  imports: [
    CommonModule, 
    RouterModule,
    NebularModule,
    FormsModule,
    SharedModule,
    NgxPaginationModule,
    ControlsModule
  ],
  declarations: [
    ReportShellComponent, 
    ReportComponent,
    DeliveryReportComponent, 
    ResendsComponent, 
    RunStatisticsComponent, 
    MailResponseLogComponent, 
    UrlTrackingReportComponent, 
    ViewerPostComponent, 
    HotSpotReportComponent, 
    FilterComponent,
    FilterContainer,
    RecipientBlacklistComponent,
    SmsResponseLogComponent,
    SystemUsersComponent,
    AdhocViewsLogComponent,
    AdhocForwardDeliveriesComponent,
    QueueActivityComponent,
    BillingReportComponent,
  ],
    exports: [ReportShellComponent]
})
export class ReportsModule {}
