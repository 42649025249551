
<nb-card class="block">
    <nb-card-header>
        
        <div class="row">
            <div class="col-md-10">
                <nb-tabset (changeTab)="changeContext($event)">
                    <nb-tab tabTitle="Recents"></nb-tab>
                    <nb-tab tabTitle="Stars"></nb-tab>
                </nb-tabset>
            </div>
            <div class="col-md-2">
                <nb-actions size="small">
                    <!-- <nb-action [icon]="searchEnabled ? 'close-circle-outline' : 'search-outline'" (click)="search()"></nb-action> -->
                    <nb-action icon="trash-2-outline" (click)="clear()"></nb-action>
                </nb-actions>
            </div>
        </div>
        <input placeholder="Search {{ contextVal }} items..." [(ngModel)]="searchValue" />
    </nb-card-header>
    <ng-container *ngIf="(data | async) as data">
        <nb-list *ngIf="isArray(data) && data.length > 0">
            <nb-list-item *ngFor="let item of data | filter :'name' : searchValue: 'includes'">
                <div class="container"> 
                    <div class="center">
                        <nb-icon icon="{{ item.icon }}" pack="oakmoon-v2" class="type"></nb-icon>
                    </div>
                    <div class="text" [routerLink]="item.route">
                        <p class="no-margin title">{{ item.name }}</p>
                        <small>{{ item.context }}</small>
                    </div>
                    <div class="center">
                        <nb-icon [icon]="item.isStar ? 'star' : 'star-outline'" pack="eva" [ngClass]="{active: item.isStar}" (click)="favorite(item)"></nb-icon>
                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </ng-container>
    <ng-container *ngIf="(data | async)?.length === 0 || (data | async) === null">
        <nb-list>
            <nb-list-item>
                <div class="container"> 
                    <div class="center">
                        <nb-icon icon="info" pack="eva" class="type"></nb-icon>
                    </div>
                    <div class="text">
                        <p class="no-margin title" *ngIf="contextVal === 'star'">Starred pages will appear here.</p>
                        <p class="no-margin title" *ngIf="contextVal === 'recent'">Recently visited pages will appear here.</p>
                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </ng-container>
</nb-card>