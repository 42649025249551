import { Component, Input, OnInit } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import { ProgressCounters, RunModel } from '@InfoSlips/models';
import { Observable } from 'rxjs';
import { RunStatusService } from '../../services/run-status.service';

@Component({
  selector: 'ifs-run-status',
  templateUrl: './run-status.component.html',
  styleUrls: ['./run-status.component.scss']
})
export class RunStatusComponent implements OnInit {

  @Input() selectedRunService: SelectedRunService;
  public innerWidth: number;
  public showStatus = false;
  
  selectedRun:RunModel;
  displayRunStatus$;
  progressCounters: Array<ProgressCounters> = []
  hasAllRequiredFiles=true;

  constructor(
    private display: RunStatusService,
  )
  {
    this.displayRunStatus$ = this.display.displayStatus$;
  }

  ngOnInit(): void {    
    this.innerWidth = window.innerWidth;

    this.selectedRunService.selectedRun$.subscribe(run=>{
      if(!run)
        return;
      this.selectedRun=run;
      this.hasAllRequiredFiles = this.selectedRunService.determineRequiredFiles(run);
    });

    this.selectedRunService.runProgressCounters$.subscribe(counters=>{
      if(counters==null || counters.length==0)
        return;
      this.progressCounters=counters[0].instance.progressCounters;
    });
  }

  toggleRunStatus() {
    this.display.toggleRunStatus();
  }

  getCounter(stageKey, counters): ProgressCounters{
    if(counters==null || counters.length==0)
      return {
        key:stageKey,
        value:{
        counter:0,
        name:stageKey,
        expectedCount:0,
        status:"Created",
        lastException:""
      }
    };
    return counters.find(({key}) => key === stageKey);
  }
}
