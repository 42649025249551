

<ng-container *ngTemplateOutlet="isRestModel ? listRest : listGraphql"></ng-container>

<ng-template #listRest>
    <div class="grid" [class.active]="selected">
        <div class="flex" style="width: 50%;">
            <nb-icon *ngIf="hasContentIcon" pack="file-icons" [icon]="contentIcon" class="icon"></nb-icon>
        
            <div>
                <p class="item-text">
                    {{ cmsEntity.Name }}
                </p>
            </div>
        </div>
    
        <div>
            <p class="item-text">
                {{ cmsEntity.Version !== -1 ? cmsEntity?.Version : 'Draft' }}
            </p>
        </div>
    
        <div>
            <button *ngIf="!selected" nbButton (click)="selectEntity(cmsEntity)">Select</button>
            <button *ngIf="selected" nbButton status="danger" (click)="removeEntity(cmsEntity)">Remove</button>
        </div>
    </div>
</ng-template>

<ng-template #listGraphql>
    <div class="grid" [class.active]="selected">
        <div class="flex" style="width: 50%;">
            <nb-icon *ngIf="hasContentIcon" pack="file-icons" [icon]="contentIcon" class="icon"></nb-icon>
        
            <div>
                <p class="item-name">
                    {{ cmsEntity.display?.toUpperCase() ?? cmsEntity.display.name }}
                </p>
                <p class="item-customer">{{cmsEntity.customer?.name}}</p>
            </div>
        </div>
    
        <div>
            <p class="item-text">
                Version - {{ cmsEntity.version !== -1 ? cmsEntity?.version : 'Draft' }}
            </p>
        </div>
    
        <div>
            <button *ngIf="!selected" nbButton (click)="selectEntity(cmsEntity)">Select</button>
            <button *ngIf="selected" nbButton status="danger" (click)="removeEntity(cmsEntity)">Remove</button>
        </div>
    </div>
</ng-template>