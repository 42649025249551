import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { CmsEntityReference } from '@InfoSlips/models';

@Component({
  selector: 'cms-entity-list-component',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss']
})
export class EntityListComponent implements OnInit {

  @Input() cmsEntity: any = null;
  @Input() selected = false;
  @Input() isRestModel = false;
  @Output() entityAddEvent: EventEmitter<CmsEntityReference> = new EventEmitter();
  @Output() entityRemoveEvent: EventEmitter<CmsEntityReference> = new EventEmitter();

  parsedContentType: number;
  contentIcon: string;
  hasContentIcon = false;

  constructor(
    private cmsService: CmsService
  ) { }

  ngOnInit() {
    this.parsedContentType = this.cmsService.getContentType(this.isRestModel ? this.cmsEntity.CmsContentType : this.cmsEntity.cmsContentType);
    this.contentIcon = this.cmsService.getContentIcon(this.isRestModel ? this.cmsEntity.CmsContentType : this.cmsEntity.cmsContentType);

    if (this.contentIcon != 'none') {
      this.hasContentIcon = true;
    }
  }

  selectEntity(cmsEntity){
    this.entityAddEvent.emit(cmsEntity);
  }

  removeEntity(cmsEntity){
    this.entityRemoveEvent.emit(cmsEntity)
  }
}