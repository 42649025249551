import { Component, Input, OnInit } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';
import { ProcessorLogs, RunModel } from '@InfoSlips/models';
import { Observable } from 'rxjs';
@Component({
  selector: 'ifs-processor-logs',
  templateUrl: './processor-logs.component.html',
  styleUrls: ['./processor-logs.component.scss']
})
export class ProcessorLogsComponent implements OnInit {
  @Input() selectedRunService:SelectedRunService;

  selectedRun: RunModel;
  logs: ProcessorLogs[];  
  
  constructor() {}
  
  ngOnInit(): void {
    this.selectedRunService.selectedRun$.subscribe(run=>{
      this.selectedRun = run;
      this.selectedRunService.loadProcessorLogs();
    });
    
    this.selectedRunService.processorAuditLog$.subscribe(res => {
      this.logs = res;
    });
  }

  onScroll() {
    this.selectedRunService.pageRunAuditLogs();
  }
}