import { Component, OnInit, Input } from '@angular/core';
import { trigger } from '@angular/animations';

import { fadeOut } from '@InfoSlips/shared';
@Component({
  selector: 'ifs-ghost-cards',
  template: `
  <div class="ghost-card card">  
    <div class="lines">
      <h3></h3><br/>
      <div class="ghost-logo"></div>
      <p></p>
    </div>
  </div>  
`,
  styleUrls: ['./ghost-cards.component.scss', './ghost.scss']
})
export class GhostCardsComponent {
}
