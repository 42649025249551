import { CmsContentType, CmsEntityScope, CmsEntityType } from "../Enums";
import { BaseEntityIdNameModel, BaseEntityModel } from "./BaseEntity";
import { CustomerReferenceModel } from "./CustomerModels";

export interface CmsEntityReferenceModel extends BaseEntityIdNameModel {
    Type: CmsEntityType;
    Scope: CmsEntityScope;
    CmsContentType: CmsContentType;
    PublicId: string;
    UseOffline: boolean;
    SpecificVersion: boolean;
    Version: number;
    Length: number;
    IsDraft: boolean;
    LockLatestVersion: boolean;
}

export interface CmsEntityCreateUpdateModel extends BaseEntityModel {
    Display: string;
    ContentType: CmsContentType | string;
    Notes?: string;
    Language?: string;
    IsDraft: boolean;
    Tags?: string[];
    CustomerId?: string;
    RunTemplateId?: string;
    CustomerGroupId?: string;
    CmsContentType?: CmsContentType | string;
    CmsEntityType?: CmsEntityType | string;
    PublicId?: string;
    Version?: number;
}

export interface CmsCreateUpdateContentModel extends CmsEntityCreateUpdateModel {
    Data?: string;
}

export interface CmsCreateUpdateFileModel extends CmsEntityCreateUpdateModel {
}

export interface CmsEntityModel extends BaseEntityModel {
    Display: string;
    ContentType: string;
    Notes?: string;
    Language?: string;
    IsDraft: boolean;
    Tags?: string[];
    CmsContentType?: CmsContentType | string;
    CmsEntityType?: CmsEntityType | string;
    PublicId?: string
    Data?: string;
    Customer: CustomerReferenceModel;
    RunTemplate: BaseEntityIdNameModel;
    CustomerGroup: BaseEntityIdNameModel;
    Scope: CmsEntityScope | string;
    Length: number;
    FileExtension: string;
    Hash: string;
    Version: number;
    LastAction: string;
}   

export interface CmsCrudModel extends CmsEntityModel {
    CustomerId?: string;
    RunTemplateId?: string;
    CustomerGroupId?: string;
}

export interface CmsThemeModel
  {
    ColorPalette:any;
    Images:CmsThemeImageModel[];
}
export interface CmsThemeImageModel{
    Name:string;
    Path:string;
}
