export interface DomainModel {
    EmailAddress: string[],
    Domain: string,
    AllowAllOnOnDomain: boolean,
    Id: string,
    Created: string,
    CreatedBy: string,
    LastUpdated: string,
    LastUpdatedBy: string
}
