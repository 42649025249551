import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { MiscService, Miscellaneous } from '@InfoSlips/iiab-api';
import { CmsEntityModel } from '@InfoSlips/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'cms-file-handler',
  templateUrl: './file-handler.component.html',
  styleUrls: ['./file-handler.component.scss']
})
export class FileHandlerComponent implements AfterViewInit {

  @Input() cmsContentModel: CmsEntityModel;
  @Input() loadedFile: any;
  @Input() readonly = false;
  @Output() cmsUploadFile = new EventEmitter();

  cmsContentTypes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('CmsContentType', this.filesEnumFilter);

  uploadFile = null;
  uploadFilePath = null;
  cmsContentType = null;

  isEnabledFileUpload = false;

  constructor(
    public miscService: MiscService,
    public environment: EnvironmentService
  ) { }
  
  ngAfterViewInit(): void {
    if(this.cmsContentModel){
      this.cmsContentTypes$.forEach(items=>{
        items.forEach(item=>{
          if(item.id==this.cmsContentModel.CmsContentType)
            {
              this.cmsContentType = item;           
              return;
            }
        })
      });
    }

    if(this.loadedFile){
      this.uploadFile = this.loadedFile;
    }
  }

  handleFileInput(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    if (fileList && fileList.length > 0) {
      this.readFile(fileList[0]);
    }
  }

  handleFileDrop(event: DragEvent): void {
    event.preventDefault();
    const fileList = event.dataTransfer?.files;

    if (fileList && fileList.length > 0) {
      this.readFile(fileList[0]);
    }
  }
  
  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  readFile(file: File): void {
    this.uploadFile = file;
    
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (e) => {
      this.cmsContentModel.Data = reader.result as string;
      this.uploadFilePath = reader.result as string;
      this.cmsUploadFile.emit(this.uploadFile);
    };

    reader.onerror = () => {
      // this.toaster.error('File reader error: ' + reader.error);
    };
  }

  handleFileChanged(uploadFilePath: any){
    this.uploadFilePath = this.cmsContentModel.Data = uploadFilePath;
  }

  contentTypeSelected(type){
    this.isEnabledFileUpload = true;
    this.cmsContentModel.ContentType = type.value;
    this.cmsContentModel.CmsContentType = type.id;
  }

  private filesEnumFilter(item:Miscellaneous):boolean{
    switch(item.value){
      case 'Data model':
      case 'Email body':
      case 'Email subject':
      case 'Sms body': 
      case 'Theme images':
      case 'Theme palette':
      case 'Json schema':
        return false;
      default: 
        return true;              
    }
  }
}
