import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CmsContentType, CmsEntityModel, CmsEntityType } from '@InfoSlips/models';
import { NbDialogService } from '@nebular/theme';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'cms-preview-entity',
  templateUrl: './cms-preview-entity.component.html',
  styleUrls: ['./cms-preview-entity.component.scss']
})
export class CmsPreviewEntityComponent {

  @Input() publicId:string;
  @Input() version:number;
  @Input() cmsEntityType:CmsEntityType;
  @Input() cmsContentType:CmsContentType;
  @Input() contentType:string
  @Input() baseUrl:string;
  @Input() uploadFilePath:string;
  @Input() cmsEntity: CmsEntityModel;
  @Input() readonly = false;
  @Output() fileState = new EventEmitter();

  editorPreviewEnabled = false;

  constructor(
    private dialogService: NbDialogService,
    private cmsService: CmsService
  ){}

  async openHtmlPreview(dialog: TemplateRef<any>) {
    const htmlResponse = await this.cmsService.markdownAsHtml(this.publicId, this.version);
    this.dialogService.open(dialog, { context: htmlResponse });
  }

  download(){
    this.cmsService.downloadCmsEntity(this.cmsEntity);
  }
}
