import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RegisterModel } from '@InfoSlips/models';

@Component({
  selector: 'ifs-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, OnChanges {
  registerForm: FormGroup;
  submitted = false;
  error = '';
  countries;
  registerOptionSelected = 'Email';

  @Input()loadingState: boolean;
  @Input() registerOptions;
  @Input() countryDetail;

  @Output() userRegister = new EventEmitter<RegisterModel>();
  @Output() registerOption = new EventEmitter<String>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.registerForm = this.fb.group({
      register: ['', Validators.required],
      country: ['', Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countryDetail && changes.countryDetail.currentValue) {
      const countries = changes.countryDetail.currentValue;
      const country = { country: "ZA"};
      this.countries = countries;
      this.registerForm?.patchValue(country);
    }
  }

  onSubmit() {
    const form = this.registerForm;
    // if (form.invalid) {
    //   return;
    // }
    this.userRegister.emit({ ...form.value });
  }

  setRegistrationOption(option){
    this.registerOptionSelected = option;
    this.registerOption.emit(this.registerOptionSelected);
  }
}
