import { Injectable, Inject } from '@angular/core';
import { EnvConfigToken, EnvConfig } from './env-token.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(@Inject(EnvConfigToken) private config: EnvConfig) {}

  get name(){
    // 
    const envName = this._ensureName(this.config.name, this.config.apiUrl);
    return envName;    
  }

  get applicationName() {
    return this.config.applicationName;
  }

  get version() {
    return this.config.version;
  }

  get baseUrl() {
    return this.config.baseUrl;
  }

  get apiUrl() {
    return this.config.apiUrl;
  }

  get webSocketUrl() {
    return this.config.webSocketUrl;
  }

  get production() {
    return this.config.production;
  }

  get secondsToWarn():number{
    return this.config.secondsToWarn;
  }

  get oldAdminEnabled(): boolean {
    return this.config.oldAdminEnabled;
  }

  get oldAdminUrl(): string {
    return this.config.oldAdminUrl;
  }

  get isProxyEnabled(): boolean {
    return this.config.isProxyEnabled;
  }

  get tinyMceKey(): string {
    return this.config.tinyMceKey;
  }

  get passwordAutoComplete(): boolean {
    return this.config.passwordAutoComplete;
  }

  //#region Private
  private _ensureName(configName:string, configApiUrl:string):string{
    if(configName)
      return configName;

    if(!configApiUrl)
      throw new Error("Invalid configuration: API Url not defined");
    
    const lowerApi = configApiUrl.toLocaleLowerCase();

    if(lowerApi.includes("localhost"))
      return "localhost";
    if(lowerApi.includes("dev-"))
      return "dev";
    if(lowerApi.includes("uat-"))
      return "uat";
    if(lowerApi.includes("infoslips.us"))
      return "produsa";

    return "prod";
  }
  //#endregion

}
