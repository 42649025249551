import { Country } from "./Register";

export interface UserModel {
    UserName:                         string;
    Email:                            string;
    Mobile:                           Mobile;
    Country:                          Country;
    DisplayName:                      string;
    NewPassword:                      null;
    IsApproved:                       boolean;
    IsLockedOut:                      boolean;
    ForcePasswordReset:               boolean;
    FailedPasswordAttemptCount:       number;
    IsDeleted:                        boolean;
    Deleted:                          null;
    LastLockedOutDate:                null;
    LastPasswordChangedDate:          null;
    LastLoginDate:                    null;
    LastActivityDate:                 null;
    FailedPasswordAttemptWindowStart: null;
    OtpEnabled:                       boolean;
    OtpVerificationCode:              null;
    Puk:                              null;
    DateOfBirth:                      null;
    RoleGroups:                       string[];
    RolesAdded:                       string[];
    RolesRemoved:                     any[];
    CustomerFilter:                   string[];
    RunTemplateFilter:                any[];
    RecipientFilter:                  any[];
    Id:                               string;
    Created:                          string;
    CreatedBy:                        string;
    LastUpdated:                      string;
    LastUpdatedBy:                    string;
}

export interface Mobile {
    Number: string;
}
