import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomerLookupComponent, RunTemplateLookupComponent } from '@InfoSlips/controls';
import { IiabIdName } from '@InfoSlips/models';
import { CmsFilterService as FilterService } from '../../services/cms-filter.service';

@Component({
  selector: 'cms-filter',
  templateUrl: './cms-filter.component.html',
  styleUrls: ['./cms-filter.component.scss']
})
export class CmsFilterComponent  {

  @Input() type = 'default';
  @Output() expandFilter = new EventEmitter();
  @ViewChild(CustomerLookupComponent) customerLookup:CustomerLookupComponent;
  @ViewChild(RunTemplateLookupComponent) runTemplateLookup:RunTemplateLookupComponent;

  get searchPhrase() {
    return this.filterService.CmsFilterState.searchPhrase;
  };
  set searchPhrase(input: string) {
    this.filterService.CmsFilterState.searchPhrase = input;
  }

  get entityType() {
    return this.filterService.CmsFilterState.cmsEntityType;
  }
  set entityType(input:any) {
    this.filterService.CmsFilterState.cmsEntityType = input;
  }

  get contentType() {
    return this.filterService.CmsFilterState.cmsContentType;
  }
  
  set contentType(input:any) {
    this.filterService.CmsFilterState.cmsContentType = input;
  }
  
  tags:Array<string>=[];
  customerId:string|null;
  runTemplateId:string|null;
  customerGroupId:string|null;

  toggleFilter = false;

  constructor(
    public filterService: FilterService
  ) { }

  onCustomerSelected(customer: IiabIdName){
    const model={selected:customer, currentIdFilter:this.customerId};
    if(!this._evalSelected(model))
      return;
      
    this.filterService.CmsFilterState.selectedCustomer = customer;

    if(this.runTemplateLookup){      
      this.runTemplateLookup.onCustomerSelected(customer);
    }

    this.customerId = model.currentIdFilter;
    if(this.customerLookup)
      this.customerLookup.showClear=this.customerId!=null;

    this.loadFilter();
  }

  onRunTemplateSelected(runTemplate: IiabIdName){

    const model = {selected:runTemplate, currentIdFilter:this.runTemplateId};
    if(!this._evalSelected(model))
      return;
      
    this.filterService.CmsFilterState.selectedRunTemplate = runTemplate;

    this.runTemplateId = model.currentIdFilter;
    if(this.runTemplateLookup)
      this.runTemplateLookup.showClear = this.runTemplateId != null;

    this.loadFilter();
  }
  
  loadFilter(){
    this.filterService.loadFilter();
  }

  clearFilters(){
    this.customerLookup.clear();
    this.runTemplateLookup.clear();
    this.searchPhrase=null;
    this.customerId=null;
    this.runTemplateId=null;
    this.customerGroupId=null;
    this.entityType=null;
    this.contentType=null;
    this.tags=[];
    this.filterService.clearFilters();
  }

  showFilter(){
    this.expandFilter.emit(this.toggleFilter);
    this.toggleFilter = !this.toggleFilter;
  }

  onTagRemove(tag:string){
    console.log('removed tag',tag);    
  }
  onTagAdd(tag:string){
    console.log('tag added', tag);
  }

  private _evalSelected(model:{selected:IiabIdName, currentIdFilter:string}):boolean{
    if(model.selected && !model.selected.id)
      return false;
      
    const newValue = !model.selected?.id ? null : model.selected.id;    
    if(newValue == model.currentIdFilter)
      return false;
    model.currentIdFilter = newValue;
    return true;
  }
}
