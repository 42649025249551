import { Component, Input, OnInit } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { CmsContentType, CmsEntityModel, CmsEntityType } from '@InfoSlips/models';
import { SelectedEntityService } from '../../services/selected-entity.service';

@Component({
  selector: 'cms-entity-details',
  templateUrl: './cms-entity-details.component.html',
  styleUrls: ['./cms-entity-details.component.scss']
})
export class CmsEntityDetailsComponent implements OnInit {

  @Input() displayName=true;
  selectedEntity: CmsEntityModel;
  isExisting=false;

  constructor(
    private selectedEntityService: SelectedEntityService,
    private env:EnvironmentService
  ) { }

  ngOnInit(): void {
    this.selectedEntityService.selectedEntity$.subscribe(entity=>{
      this.selectedEntity = entity;
      this.isExisting = true;
    });
  }

  showSecure():boolean{
    if(this.isExisting && this.selectedEntity.Customer)
      return true;
    return false; //TODO: Handle new
  }

  showCustGroup():boolean{
    if(this.isExisting && this.selectedEntity.CustomerGroup)
      return true;
    return false;
  }

  onTagRemove(tag:string){
    console.log('tag removed',tag);    
  }
  onTagAdd(tag:string){
    console.log('tag added', tag);
    return;
    if(!this.selectedEntity.Tags || this.selectedEntity.Tags.length==0 ){
      this.selectedEntity.Tags=[tag];
      return;
    }    

    this.selectedEntity.Tags.push(tag);

  }

  showImagePreview():boolean{
    if(this.selectedEntity.CmsEntityType!==CmsEntityType.File)
      return false;
    
    return  this.selectedEntity.CmsContentType===CmsContentType.Image ||
            this.selectedEntity.CmsContentType===CmsContentType.ThemeImages;

  }

  getImageSource():string{
    if(!this.selectedEntity || !this.showImagePreview())
      return '';
    const imgSrc = `${this.env.apiUrl}api/cms/${this.selectedEntity.PublicId}/${this.selectedEntity.Version}`;
    
    return imgSrc;
  }

}
