import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { EnvironmentService } from '@InfoSlips/env';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, interval, Observable, Subscription, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators'
import * as models from '@InfoSlips/models';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';
import { UrlEncodingCodec } from './url-encoding-codec.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private loggedInUserSubject$ = new BehaviorSubject<models.LoggedInModel>(null);
  private countDownSubscription: Subscription;
  private expiringSubject$ = new BehaviorSubject<boolean>(false);
  private expiresInSubject$ = new BehaviorSubject<number>(0);
  private sessionSecondsRemainingSubject$ = new BehaviorSubject<number>(0);
  private refreshTokenSubject$ = new BehaviorSubject<string>(null);
  private systemAdminSubject$ = new BehaviorSubject<boolean>(false);

  weakPasswordSubject = new BehaviorSubject<models.WeakPasswordModel>(null);
  weakPassword$ = this.weakPasswordSubject.asObservable();
  sessionCountDown$ = new Observable<number>();
  sessionSecondsUpdated = 0;

  private lastErrorSubject$ = new BehaviorSubject<models.AuthError>(null);

  constructor(
    private environment: EnvironmentService,
    private http: HttpClient,
    private toaster: ToastrService,
    private router: Router,
    private localStorageService: IiabLocalStorageService
  ) {
    this.loadFromStorage();
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.authenticatedSubject$.asObservable();
  }
  get isAuthenticated(): boolean {
    return this.authenticatedSubject$.getValue();
  }
  get loggedInUser$(): Observable<models.LoggedInModel> {
    return this.loggedInUserSubject$.asObservable();
  }
  get loggedInUser(): models.LoggedInModel {
    return this.loggedInUserSubject$.getValue();
  }
  get expiring$(): Observable<boolean> {
    return this.expiringSubject$.asObservable();
  }
  get expiresIn$(): Observable<number> {
    return this.expiresInSubject$.asObservable();
  }
  get sessionSecondsRemaining$(): Observable<number> {
    return this.sessionSecondsRemainingSubject$.asObservable();
  }
  get lastError$(): Observable<models.AuthError> {
    return this.lastErrorSubject$.asObservable();
  }
  get isSystemAdmin$(): Observable<boolean> {
    return this.systemAdminSubject$.asObservable();
  }
  get isSystemAdmin(): boolean {
    return this.systemAdminSubject$.getValue();
  }


  login(loginUser: models.LoginModel): void {
    this.stopCountDown();
    let body = new HttpParams({encoder: new UrlEncodingCodec()});
    body = body.append('client_id', 'ifscloudapp');
    body = body.append('grant_type', 'password');
    body = body.append('username', loginUser.username);
    body = body.append('password', loginUser.password);
    body = body.append('requestor', '0');

    if (loginUser.otp) {
      body = body.append('otp', loginUser.otp);
    }

    const headers = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      )
    };
    this.http.post<models.LoggedInModel>(
      this.environment.applicationName === 'admin' ? `${this.environment.apiUrl}oauth` : `${this.environment.apiUrl}api/oauth`,
      body.toString(),
      headers)
      // .pipe(catchError(this.handleError))
      .subscribe((result) => {
        if (
          result.redirectUrl &&
          result.redirectUrl != null &&
          result.redirectUrl !== ''
        ) {
          window.location.href = result.redirectUrl;
          return;
        }

        this.router.navigateByUrl('auth/login');
        this.setLoggedInState(result, loginUser.oldAdmin);
        this.weakPasswordSubject.next({
          weakPassword: result.weakPassword === 'True' ? true : false,
          weakPasswordError: result?.weakPasswordError || null
        })

        const returnUrl = this.localStorageService.get<string>(models.IIABCacheKey.ReturnUrl, true);
        const requestedUrl = this.localStorageService.get<string>(models.IIABCacheKey.ReturnUrl, false);
        if (loginUser.oldAdmin)
          location.replace(this.environment.oldAdminUrl)
        else {
          if (requestedUrl && !requestedUrl.includes("auth")) {
            this.router.navigate([requestedUrl || '/']);
          } else this.router.navigate([returnUrl || '/']);
        }
        this.localStorageService.remove(models.IIABCacheKey.ReturnUrl, false);

      }, (error) => {
        this._handleAuthError(error,'login');
    });
  }
  sso(ssoModel: models.SsoModel): void {
    this.stopCountDown();
    const headers = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/json'
      )
    };
    this.http.post<models.LoggedInModel>(
      `${this.environment.apiUrl}api/client/ExternalLogin`,
      ssoModel,
      headers)
      // .pipe(catchError(this.handleError))
      .subscribe((result) => {
        this.setLoggedInState(result, false);
        this.router.navigateByUrl('/');
      }, (error) => {
        this._handleAuthError(error, 'ExternalSSO');
      });
  }
  logout() {
    this.stopCountDown();
    this.setLoggedInState(null);
  }
  refreshToken(): void {
    if (!this.authenticatedSubject$.getValue()) {
      return;
    }
    this.stopCountDown();
    const refreshToken = this.loggedInUser.refresh_token;
    this.refreshTokenSubject$.next(refreshToken);

    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', 'ifscloudapp')
      .set('refresh_token', refreshToken);

    const headers = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded')
    };

    this.http.post<models.LoggedInModel>(
      this.environment.applicationName === 'admin' ? `${this.environment.apiUrl}oauth` : `${this.environment.apiUrl}api/oauth`,
      body.toString(),
      headers)
      .pipe(catchError(this.handleError))
      .subscribe((result) => {
        this.setLoggedInState(result);
      });
  }
  getEchoUrl(): string {
    return `${this.environment.apiUrl}api/health/echo`;
  }
  echo(): Observable<models.EchoModel> {
    return this.http.get<models.EchoModel>(this.getEchoUrl())
      .pipe(data => {
        return data;
      }, catchError(this.handleError))
  }
  updateSecondsLeft(): void {
    const secondsLastUpdate = (new Date().getTime() / 1000) - this.sessionSecondsUpdated;
    if (secondsLastUpdate < 5) {
      return; // recently update
    }

    try {
      const storedLoggedInToken = this.localStorageService.getLoggedInTokenModel()
      if(!storedLoggedInToken)
      {
        const currentUrl = window.location.toString().toLocaleLowerCase();
        if(!currentUrl.includes('login') || !currentUrl.includes('logout'))
        {
          console.log('Logout from URL:', currentUrl);          
          window.location.replace('/#/auth/logout');
        }
        return;
      }
      const expiresIn = new Date(storedLoggedInToken['.expires']).getTime();
      const timeLeft = (expiresIn - new Date().getTime()) / 1000;
      if (timeLeft < 5) {
        console.log('Not updating timer: logout');
        this.logout();
        return;
      }
      console.log('Updating timer: ', expiresIn);
      this.startSessionTimer(expiresIn);
    }
    catch (e) {
      console.log('Failed to update the session:', e);
    }
  }

  //#region Private
  private stopCountDown(): void {
    if (this.countDownSubscription && !this.countDownSubscription.closed) {
      this.countDownSubscription.unsubscribe();
    }
  }

  private setLoggedInState(result: models.LoggedInModel, oldAdmin?: boolean): void {
    this.expiresInSubject$.next(0);
    this.expiringSubject$.next(false);

    if (!result) {
      this.localStorageService.remove(models.IIABCacheKey.LOGGED_IN_STORE_KEY);

      this.loggedInUserSubject$.next(null);
      this.authenticatedSubject$.next(false);
      return;
    }

    // Expire the cached item 1min after the token has expired
    const cookieExpiryDate = new Date();
    cookieExpiryDate.setSeconds(result.expires_in + 60);

    this.loggedInUserSubject$.next(result);
    this.localStorageService.setWithExpiry<models.LoggedInModel>(models.IIABCacheKey.LOGGED_IN_STORE_KEY, result, cookieExpiryDate);

    result.permissions.toLowerCase().includes('system administrator') ? this.systemAdminSubject$.next(true)  : this.systemAdminSubject$.next(false);

    if (oldAdmin) {
      document.cookie = `authtoken=${JSON.stringify(result)};domain=infoslipscloud.com;`;
      setTimeout(() => { location.replace(this.environment.oldAdminUrl); }, 3000);
      // Localhost testing
      // document.cookie = `authtoken=${JSON.stringify(result)};domain=localhost;`;
      // location.replace("http://localhost:4200/#/home");
      return
    }
    this.authenticatedSubject$.next(true);
    this.startSessionTimer(new Date(result[".expires"]).getTime());
  }


  private startSessionTimer(expiresInSeconds: number): void {
    this.stopCountDown();
    // Get current time and divide time down to seconds to calc time left.
    const totalTimeLeft = (expiresInSeconds - new Date().getTime()) / 1000;
    this.sessionSecondsRemainingSubject$.next(totalTimeLeft);

    const timerInterval$ = interval(1000); //1s
    this.sessionCountDown$ = timerInterval$.pipe(take(totalTimeLeft));

    this.countDownSubscription = this.sessionCountDown$.subscribe((secondsTicked: any) => {
      const secondsLeft = Math.floor(totalTimeLeft - secondsTicked);

      this.broadcastTimeRemaining(secondsLeft);

      if (secondsLeft <= 1) {
        this.logout();
        return;
      }
      if (secondsLeft <= this.environment.secondsToWarn) {
        this.expiringSubject$.next(true);
        this.expiresInSubject$.next(secondsLeft);
      }
    });
  }
  private handleError(error: any) {
    const authError: models.AuthError = new models.AuthError();

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      authError.error = error.error.message;
      authError.description = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      if (error.error.error) {
        authError.error = error.error.error;
        authError.description = error.error.error_description;
      } else {
        authError.error = error.statusText;
        authError.description = error.message;
      }
    }
    return throwError(authError);
  };
  private loadFromStorage(): void {
    const loggedIn = this.localStorageService.get<models.LoggedInModel>(models.IIABCacheKey.LOGGED_IN_STORE_KEY);

    if (!loggedIn) {
      return;
    }

    const expires = new Date(loggedIn[".expires"]);
    const currentDate = new Date();

    if (expires <= currentDate) {
      this.localStorageService.remove(models.IIABCacheKey.LOGGED_IN_STORE_KEY);
      return;
    }

    this.setLoggedInState(loggedIn);
  }
  private broadcastTimeRemaining(secondsLeft: number) {
    let sendTimeLeft = false;

    this.sessionSecondsUpdated = new Date().getTime() / 1000;

    if (secondsLeft > 300) {
      sendTimeLeft = true;
    }
    else if (secondsLeft <= 60) {
      sendTimeLeft = true;
    }
    else if (secondsLeft <= 300) {
      if (secondsLeft % 30 == 0) {
        sendTimeLeft = true;
      }
    }

    if (sendTimeLeft)
      this.sessionSecondsRemainingSubject$.next(secondsLeft);
  }
  private _handleAuthError(error: any, source:string) {
    console.log(source, error);
    const authError: models.AuthError = new models.AuthError();
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      authError.error = error.error.message;
      authError.description = error.error.message;
      this.toaster.error(authError.description);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      if (error.error.error) {
        authError.error = error.error.error;
        authError.description = error.error.error_description;
      } else {
        authError.error = error.statusText;
        authError.description = error.message;
      }
      this.toaster.error(authError.description);
    }
    this.lastErrorSubject$.next(authError);
  }
  //#endregion
}