
<div class="row full-height no-margin" [style]="theme === 'light' ? 'background-color: white; color: black' : 'background-color: #1e1e1e; color: white' ">
  <div class="col-md-2" *ngIf=tinyEnabled>
    <div class="general-side-nav">
      <div class="context-menu" *ngFor="let option of items" (click)="setMenuItem(option.title)" [class.active]="option.title === menuItem">
        <div class="body">
            <h4 class="title">{{ option.title }}</h4>
        </div>
        <div class="action text-center">
            <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
        </div>
    </div>
    </div>

  </div>
  <div class="col-md-10 no-padding">
    <ng-container [ngSwitch]="menuItem">
      <ng-template [ngSwitchCase]="'Mailbody'">
        <ifs-tiny-editor *ngIf=tinyEnabled [(data)]="emailHtml" theme="light" (dataChange)="emailHtml=$event"></ifs-tiny-editor>
      </ng-template>
      
      <ng-template [ngSwitchCase]="'HTML'">
          <ngx-monaco-editor class="monaco-editor" [options]="monacoConfig.editorOptions" [(ngModel)]="emailHtml"></ngx-monaco-editor>
      </ng-template>
      
      <ng-template [ngSwitchCase]="'Settings'">
        <div class="email-options">
        <div class="row">
          <ng-container *ngFor="let setting of emailSettings; last as isLast">
            <div [class]="isLast ? 'col-md-12' : 'col-md-6'">
                <label><p>{{ setting.label }}</p></label>
                <input type="text" nbInput fullWidth [(ngModel)]="setting.model" (ngModelChange)="settingChange($event, setting.name)">
            </div>
          </ng-container>
          <div class="col-md-12">
            <br/>
            <p>Additional Options</p>
                <nb-checkbox (checkedChange)="settingChange($event, 'TrackReadEmail')" [(ngModel)]="selectedRun.EmailTemplate.TrackReadEmail">Track read email?</nb-checkbox>
          </div>
        </div>
        </div>
      </ng-template>
    </ng-container>
</div>
</div>