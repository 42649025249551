import { ReprocessStageCodes } from "../Enums";
import { CustomerReference } from "../graphql/customer";
import { BaseEntityIdNameModel, BaseEntityModel } from "./BaseEntity";
import { DataRetention } from "./DataRetention";
import { EmailTemplateModel } from "./EmailTemplateModel";
import { ExportTemplateModel } from "./ExportTemplateModel";
import { PostProcessorReferenceModel, PreProcessorModel, PreProcessorReferenceModel, UploadedFileModel } from "./Processors";
import { SMSTemplateModel } from "./SMSTemplateModel";
import { TrialOptionsModel } from "./TrialOptionsModel";

export interface RunModel extends BaseEntityModel {
    RunStartedBy:                              BaseEntityIdNameModel;
    RunCreatedBy:                              BaseEntityIdNameModel;
    CustomerName:                              string;
    ScheduleDateTime:                          Date;
    IsTrial:                                   boolean;
    RunRecipientSpecificStaticAttachmentsFile: any[];
    IsInDebugMode:                             boolean;
    IsPukLess:                                 boolean;
    IsPrivate:                                 boolean;
    ExcludeIfsFile:                            boolean;
    BilingDate:                                BillingDateModel;
    PackageVersion:                            number;
    SupportMessage:                            string;
    Status:                                    string;
    TotalToVerify:                             number;
    RunFiles:                                  UploadedFileModel[];
    Attachments:                               any[];
    Instance:                                  RunInstanceModel;
    PreProcessor:                              PreProcessorReferenceModel;
    TrialOptions:                              TrialOptionsModel;
    DataRetention:                             DataRetention;
    PostProcessors:                            PostProcessorReferenceModel[];
    Watchers:                                  BaseEntityIdNameModel[];
    NotificationEmailRecipients:               any[];
    NotifyRunCreator:                          boolean;
    DenyDelete:                                boolean;
    RunProcessingType:                         number;
    RunDataCopiedFrom:                         any;
    RunDataSourceType:                         number;
    DisableUrlSecurity:                        boolean;
    SupportingRun:                             any;
    IsCompileOnly:                             boolean;
    ScheduleQueueName:                         string;
    ProcessingContainer:                       any;
    Customer:                                  CustomerReference;
    RunTemplate:                               BaseEntityIdNameModel;
    OverrideRunTemplateBranch:                 string;
    EmailTemplate:                             EmailTemplateModel;
    SmsTemplate:                               SMSTemplateModel;
    ExportTemplate:                            ExportTemplateModel;
}

export interface BillingDateModel {
    Type:        number;
    DateTime:    string;
    Description: string;
}

export interface RunReferenceModel {
    BilingDateDescription: string;
    RunDate:               string;
    Name:                  string;
    Id:                    string;
}

export interface RunInstanceModel {
    Id:                       string;
    Status:                   number;
    Started:                  string;
    Completed:                null;
    LastException:            null;
    ShouldCreateRecipients:   boolean;
    ProcessDataOnly:          boolean;
    ProgressCounters:         ProgressCounterModel[];
    Statistics:               RunStatisticsModel;
    FilePreviews:             FilePreviewsModel;
    JsonRecipientDataPreview: FilePreviewsModel;
    HasRemovedRunRecipients:  boolean;
}

export interface FilePreviewsModel {
}

export interface ProgressCounterModel {
    Name:                string;
    Status:              number;
    Started:             null | Date;
    Completed:           null | Date;
    Counter:             number;
    ExpectedCount:       number;
    LastException:       null | string;
    ReRunCount:          number;
    TimeInSeconds:       string;
    ProcessingPerSecond: number;
    ProgressStageCode:   number;
}

export interface RunStatisticsModel {
    MailAttachmentCount:         number;
    MailAttachmentSize:          number;
    MailStaticAttachmentsCount:  number;
    MailStaticAttachmentSize:    number;
    MailRunrecipientStaticCount: number;
    MailRunrecipientStaticSize:  number;
    MailProcessedCounter:        number;
    MailSentSuccessCounter:      number;
    MailSentFailCounter:         number;
    MailToAddressCounter:        number;
    MailBccAddressCounter:       number;
    MailCcAddressCounter:        number;
    MailUnverifiedSaveCounter:   number;
    MailDisabledCount:           number;
    NewRecipientCount:           number;
    EmailResendCounter:          number;
    EmailForwardCounter:         number;
    SmsResendCounter:            number;
    SmsForwardCounter:           number;
    SmsTotalRecipients:          number;
    SmsTotalSent:                number;
    SmsFailed:                   number;
    SmsDisabledCount:            number;
    ExportPdfCount:              number;
    ExportPdfPages:              number;
    ExportPdfFail:               number;
    ExportPdfSize:               number;
    ExportIfsCount:              number;
    ExportIfsFail:               number;
    ExportIfsSize:               number;
    ExportDisabledCount:         number;
    PostProcessorTotalCount:     number;
    PostProcessorFailedCount:    number;
    PostProcessorSuccessCount:   number;
    ConsolidatedTotalCount:      number;
    GroupingCounts:              GroupingCountsModel;
}

export interface GroupingCountsModel {
    TRStatement: number;
}



export interface CreateRunModel {
    Name:          string;
    IsTrial:       boolean;
    IsPukLess:     boolean;
    RunTemplateId: string;
    TrialOptions:  TrialOptionsModel;
}

export interface ReProcessStagesModel{
    SendStage: ReprocessStageCodes;
    ExportStage: ReprocessStageCodes;
    PostProcessingStage: ReprocessStageCodes;
    RunId: string;
}