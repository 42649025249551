import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { UserRegistrationModel, RegisterActivation, Country } from '@InfoSlips/models';
import { RegisterService } from '@InfoSlips/iiab-auth';

@Component({
  selector: 'ifs-register-confirm',
  styleUrls: ['./register-confirm.component.scss'],
  template: `
    <ifs-logged-out-view>
      <ifs-register-confirm-form
        (userRegisterConfirm)="registerConfirm($event)"
        [activationDetailsForm$]="activationDetails$"
        [countries]="countries$"
      ></ifs-register-confirm-form>
    </ifs-logged-out-view>
  `
})
export class RegisterConfirmComponent implements OnInit {
  loading = false;
  error = '';
  token: string;
  activationDetails$: UserRegistrationModel;
  countries$: Country[];

  constructor(
    private registerService: RegisterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    forkJoin(
      this.registerService.getActivation(this.token),
      this.registerService.getCountries(),
    ).subscribe((values: any) => {
      const [activation, countries] = values;
      this.countries$ = countries;
      this.activationDetails$ = {
        Token: activation.Token,
        UserName: activation.Data.RegistrationId,
        Email: activation.Data.Email,
        Mobile: activation.Data.Mobile,
        Password: '',
        DisplayName: activation.Data.Email,
        Country: '',
        DateOfBirth: ''
      };
    });
  }

  registerConfirm(event: UserRegistrationModel) {
    this.loading = true;
    this.registerService.registerConfirm(event);
  }
}
