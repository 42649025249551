<ng-container *ngIf="type === 'card'; then card; else list"></ng-container>

<ng-template #card>
    <nb-card class="card mt-0 shadow card-{{runDetail?.instance?.status?.toLowerCase()}}" [class.selected]="selected && runDetail">
        <nb-card-header class="card-header" >
            <p class="card-title">{{runDetail.name}}</p>
        </nb-card-header>
        <nb-card-body>
       <div class="card-header row">
            <div class="col-9" [ngClass]="{'status-trial': runDetail.isTrial, 'status-live': !runDetail.isTrial}" [class]="runDetail.instance.status === 'FAILED' ? 'status-failed' : ''">
                <p class="card-sub-title">{{ runDetail.customer.name }}</p>
                <p class="card-sub-title description">{{runDetail.runTemplate.name}}</p>
            </div>
            <div class="col-3 p-0">
                <div class="status-pill pull-right text-center full-width">
                    <div class="top" [ngClass]="{'bg-warning': runDetail.isTrial, 'bg-success': !runDetail.isTrial}" [class]="runDetail.instance.status === 'FAILED' ? 'bg-danger' : ''">
                        {{runDetail.isTrial ? 'Trial' : 'Live'}}
                    </div>
                    <div *ngIf="selected && runDetail" class="bottom" [class]="runDetail.instance.status">
                        {{ runDetail.instance.status }}
                    </div>
                    <div *ngIf="!selected" class="bottom text-only" [class]="runDetail.instance.status">
                        {{ runDetail.instance.status }}
                    </div>
                </div>
            </div>
        </div>
        </nb-card-body>
        <nb-card-footer>
        <div class="card-footer">
            <div class="col-md-6">
                {{runDetail.createdBy}}
            </div>
            <div class="text-right col-md-6">
                updated {{runDetail.lastUpdated | timeago}}
            </div>
        </div>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #list>
    <nb-list-item>
        <p class="card-title">{{runDetail.name}}</p>
    </nb-list-item>
</ng-template>