import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'reports-billing-report',
  templateUrl: './billing-report.component.html',
  styleUrls: ['./billing-report.component.scss']
})
export class BillingReportComponent {

  constructor(
    private reportsService: ReportsService
  ) {}

  reportDetails$ = this.reportsService.reportDetails$;
  reportDetailsPaging$ = this.reportsService.reportDetailsPaging$;
  
  pageReport(context: string){
    this.reportsService.pageReport(context);
  }
}
