import { Injectable } from '@angular/core';
import { AggregatedPeriodRunStats, AggregatedRunStats, CustomerRunStatistics, IIABCacheKey, LoginModel, PageGraphQLData } from '@InfoSlips/models';
import { BehaviorSubject, from } from 'rxjs';
import { groupBy, mergeMap, reduce, toArray } from 'rxjs/operators';
import { ObservableWrapper } from './base/observable-wrapper';
import { ApiBaseService } from './base/api-base.service';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';

@Injectable({
  providedIn: 'root'
})


export class CustomerStatsService {

  customerStatistics: ObservableWrapper<CustomerRunStatistics>;  
  
  loadedStatisticsSubject = new  BehaviorSubject<CustomerRunStatistics[]>([]);
  loadedStatistics$ = this.loadedStatisticsSubject.asObservable();

  aggregatedStatisticsSubject= new BehaviorSubject<AggregatedRunStats>(null);
  aggregatedStatistics$ = this.aggregatedStatisticsSubject.asObservable();


  constructor(
    private apiBaseService: ApiBaseService,
    private iiabCache: IiabLocalStorageService
  ) {

    this.customerStatistics = new ObservableWrapper<CustomerRunStatistics>(
      this.apiBaseService,
      this._pageCustomerStatisticsQuery(),
      this._fetchStats,
      true);

    const billingPeriodFilter = {
      billingPeriod: {
        in: this.getMonthFilter()
      }
    };

    var cachedStats = this.iiabCache.getWithExpiry<AggregatedRunStats>(IIABCacheKey.AggregatedStats, true);
    if(cachedStats)
    {
      this.aggregatedStatisticsSubject.next(cachedStats);
      return;
    }

    // No cache found, load from the database
    this.customerStatistics.loadPagedItems(billingPeriodFilter);
    this.customerStatistics.Items$().subscribe(loadedItems=>{
      this.aggregatePeriodStats(loadedItems);
    })
  }


  //#region Query And Call
  private _pageCustomerStatisticsQuery() {
    return `
    query CustomerStatistics($where: CustomerStatisticsFilterInput = null) { 
      customerStatistics(first: 500, where: $where, order: { billingPeriod: DESC}){
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        }
        edges{
          node{
            id,        
            totalCompiledCount
            exportSuccessCount
            emailSuccessCount
            emailFailedCount
            emailTotalCount
            billingPeriod
            customer {
              id 
              name
            }
          }
        }
      }
    }`;
  }

  private _fetchStats(rawData: any): PageGraphQLData<CustomerRunStatistics> {
    try {
      return rawData.data.customerStatistics as PageGraphQLData<CustomerRunStatistics>;
    }
    catch (e) {
      console.log("CustomerRunStatistics That FAIlED", rawData);
      return null;
    }
  }
  //#endregion

//#region private
private aggregatePeriodStats(loadedStats:CustomerRunStatistics[]):void{

  const aggregatedRunStats: AggregatedRunStats = {
    totalCompiledCount: 0,
    exportSuccessCount: 0,
    emailSuccessCount: 0,
    emailFailedCount: 0,
    emailTotalCount: 0,
    periods:[]
  };

  from(loadedStats).pipe(
    groupBy(grp=>grp.billingPeriod),
    mergeMap(group=>group.pipe(
        reduce<AggregatedPeriodRunStats>((acc,cur)=>{

          aggregatedRunStats.emailFailedCount+=cur.emailFailedCount;
          aggregatedRunStats.emailSuccessCount+=cur.emailSuccessCount;
          aggregatedRunStats.emailTotalCount+=cur.emailTotalCount;
          aggregatedRunStats.exportSuccessCount+=cur.exportSuccessCount;
          aggregatedRunStats.totalCompiledCount+=cur.totalCompiledCount;

          acc.emailFailedCount+=cur.emailFailedCount;
          acc.emailSuccessCount+=cur.emailSuccessCount;
          acc.emailTotalCount+=cur.emailTotalCount;
          acc.exportSuccessCount+=cur.exportSuccessCount;
          acc.totalCompiledCount+=cur.totalCompiledCount;

          return acc;
        },
        {
          billingPeriod: group.key,
          emailFailedCount:0,
          emailSuccessCount:0,
          emailTotalCount:0,
          exportSuccessCount:0,
          totalCompiledCount:0,
        } as AggregatedPeriodRunStats)
      )
    ),
    toArray()
  ).subscribe(agg=>{

    aggregatedRunStats.periods=agg;
    this.aggregatedStatisticsSubject.next(aggregatedRunStats);
    
    const now = new Date();
    var lastDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    this.iiabCache.setWithExpiry<AggregatedRunStats>(
      IIABCacheKey.AggregatedStats, 
      aggregatedRunStats, 
      lastDayOfCurrentMonth,
      true);
  });

}

private getMonthFilter():string[]
{
  let months:string[]=[];
  for(let i=1;i<4;i++){
    var d = new Date();
    d.setMonth(d.getMonth() - i);
    months.push(`${d.getFullYear()}_${(d.getMonth() + 1).toString().padStart(2, "0")}`)
  }
  return months;
}
//#endregion
}
