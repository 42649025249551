
<ng-container [ngSwitch]="type">
    <ng-template [ngSwitchCase]="'default'">
        <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'small'">
        <ng-container *ngTemplateOutlet="small"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'revision'">
        <ng-container *ngTemplateOutlet="revision"></ng-container>
    </ng-template>

    <ng-template [ngSwitchCase]="'reference'">
        <ng-container *ngTemplateOutlet="reference"></ng-container>
    </ng-template>

</ng-container>


<ng-template #default>
    <div class="grid default">
        <div (click)="selectEntity(cmsEntity)" class="flex" style="width: 50%;">
            <nb-icon *ngIf="hasContentIcon" pack="file-icons" [icon]="contentIcon" class="icon"></nb-icon>
        
            <div style="width: 100%;">
                <p class="item-name">
                    {{ cmsEntity.display?.toUpperCase() ?? cmsEntity.display.name }}
                </p>
                <p class="item-customer">
                    <span *ngIf="!cmsEntity.customerGroup">{{ cmsEntity.customer?.name ?? 'Public' }}</span>
                    <span *ngIf="cmsEntity.customerGroup">Customer Group - {{cmsEntity.customerGroup.name}}</span>
                </p>
            </div>
        </div>

        <div (click)="selectEntity(cmsEntity)" class="flex item-info">
            <div style="width: 100%;">
                <p class="item-name">
                    {{ cmsEntity.runTemplate?.name ?? 'Global' }}
                </p>
                <p class="item-customer">Version - {{ getVersion(cmsEntity.version) }}</p>
            </div>
        </div>
    
        <!-- <div style="width: 10rem;">
            <p class="item-text">
                Version - {{ cmsEntity.version !== -1 ? cmsEntity?.version : 'Draft' }}
            </p>
        </div> -->
    
        <div (click)="selectEntity(cmsEntity)" class="text-right item-info">
            {{cmsEntity.lastUpdated | timeago}} 
        </div>

        <div class="text-right item-actions">
            <nb-icon
                *ngIf="parsedContentType === 9"
                pack="eva" 
                icon="eye" 
                (click)="openPreview(previewDialog)"
            ></nb-icon>

            <nb-icon 
                pack="eva" 
                icon="info" 
                [nbPopover]="entityInfo"
                nbPopoverPlacement="bottom"
                nbPopoverTrigger="hover"
            ></nb-icon>

            <nb-checkbox (checkedChange)="checkedEntity.emit({id: cmsEntity.id, name: cmsEntity.name})"></nb-checkbox>
        </div>
    </div>
</ng-template>

<ng-template #small>
    <div class="grid small" (click)="selectEntity(cmsEntity)">
        <div class="flex" style="width: 100%;">
            <div>
                <nb-icon *ngIf="hasContentIcon" pack="file-icons" [icon]="contentIcon" class="icon-small"></nb-icon>
            </div>
        
            <div>
                <p class="item-name">
                    {{ cmsEntity.display?.toUpperCase() ?? cmsEntity.display.name }}
                </p>
                <p class="item-customer">{{cmsEntity.customer?.name}}</p>
            </div>
        </div>
    
        <div class="text-right item-actions" style="width: 2rem;">
            <nb-icon 
                pack="eva" 
                icon="info" 
                style="margin-left: 8px;"
                [nbPopover]="entityInfo"
                nbPopoverPlacement="bottom"
                nbPopoverTrigger="hover"
                ></nb-icon>
        </div>
    </div>
</ng-template>

<ng-template #revision>
    <div class="grid revision">
        <div class="flex" style="width: 100%;">
            <div>
                <p class="item-name">
                    <a [routerLink]="'/cms/entity/' + cmsEntity.id" target="_blank" routerLinkActive="active">Version: {{ getVersion(cmsEntity.version) }}</a>
                </p>
                <p class="item-customer">Archived: {{cmsEntity.created | localDate }} (<span>by {{cmsEntity.createdBy}}</span>)</p>
            </div>
        </div>

        <div class="flex" style="width: 100%;">
            <div>
                <p class="item-name">
                    Public Id: <ifs-copy [value]="cmsEntity.publicId"></ifs-copy>
                </p>
                <p class="item-customer">
                    {{cmsEntity.publicId}}
                </p>
            </div>
        </div>
    
        <div class="flex" style="width: 50%;">
            <button nbButton style="float: right;">Use this revision</button>
        </div>
    </div>
</ng-template>

<ng-template #reference>
    <div class="grid reference">
        <div class="flex" style="width: 100%;">
            <div>
                <p class="item-name">
                    <a [routerLink]="[routerLinkUrl]" target="_blank" routerLinkActive="active">
                        {{cmsEntity.referenceItem.name}}
                    </a>
                </p>
                <p class="item-customer" *ngIf="cmsEntity.referenceItem.runDate">{{cmsEntity.referenceItem.runDate | localDate }}</p>
            </div>
        </div>

        <div class="flex" style="width: 100%;">
            <div>
                <p class="item-name">
                    Version: {{ getVersion(cmsEntity.cmsEntityReferences[0].version) }}
                </p>
                <p class="item-customer" *ngIf="getVersion(cmsEntity.cmsEntityReferences[0].specificVersion) !== undefined">
                    Specific Version: {{ getVersion(cmsEntity.cmsEntityReferences[0].specificVersion) }}
                </p>
            </div>
        </div>
    
        <div class="flex" style="width: 50%;" *ngIf="cmsEntity.cmsEntityReferences[0].useOffline !== undefined">
            <div>
                <p class="item-text">
                    UseOffline: {{cmsEntity.cmsEntityReferences[0].useOffline}}
                </p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #entityInfo>
    <div>By: {{cmsEntity.lastUpdatedBy}}</div>
    <div>EntityType: {{cmsEntity.cmsEntityType}}</div>
    <div>ContentType: {{cmsEntity.cmsContentType}}</div>
</ng-template>

<ng-template #previewDialog let-data let-ref="dialogRef">
    <nb-card class="dialog-card">
      <nb-card-header class="dialog-header">
        <p>Entity Image Preview</p>
    </nb-card-header>
      <nb-card-body>
        <div style="padding: 1rem; padding-top: 0px;">
            <img [src]="data" style="max-width: 100%" />
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button fullWidth nbButton (click)="ref.close()">Close</button>
      </nb-card-footer>
    </nb-card>
</ng-template>