import { IiabIdName, PageGraphQLData, ProcessorLogs, RunLogs, RunRecipientSummary, RunSummary, RunTrialRecipientSummary } from "@InfoSlips/models";

export function _pagedRunSummariesQuery() {
  return `query RunSummariesPaged($first: Int = null, $last: Int = null, $afterCursor: String = null, $beforeCursor: String = null, $where: RunFilterInput = null, $order: RunSortInput = {
    lastUpdated: DESC
  }) { 
    runs(first: $first, last: $last, after: $afterCursor, before: $beforeCursor, where: $where, order: [$order]) {
      totalCount,
      pageInfo{
        hasNextPage,
        hasPreviousPage,
        startCursor,
        endCursor
      },
      edges{
        cursor,
        node{
          name,
          id,
          runTemplate{
            name,
            id
          },
          isTrial,
          createdBy,
          customer{
            name,
            id
          },
          instance {
            id,
            status
          },
          scheduleDateTime
          lastUpdated
        }
      }
    }
  }`;
}

export function _fetchRunSummaryData(rawData: any): PageGraphQLData<RunSummary> {
  try{
    return rawData.data.runs as PageGraphQLData<RunSummary>;
  }
  catch(e)
  {
    console.log("runData That FAIlED", rawData);
    return null;
  }
}

export function _pagedRunsQuery() {
  return `query RunsIdNamePaged($first: Int = 10, $afterCursor: String = null, $where: RunFilterInput = null) { 
    runs(first: $first, after: $afterCursor, where: $where, order: {lastUpdated: DESC}) {
      totalCount,
      pageInfo{
        hasNextPage,
        hasPreviousPage,
        startCursor,
        endCursor
      }
      edges{
        cursor,
        node{
          name,
          id
        }
      }
    }
  }`;
}

export function _fetchRunsData(rawData: any): PageGraphQLData<IiabIdName> {
  try{
    return rawData.data.runs as PageGraphQLData<IiabIdName>;
  }
  catch(e)
  {
    console.log("runData That FAIlED", rawData);
    return null;
  }
}

export function _pagedRunRecipientSummariesQuery() {
  return `query RecipientsForRun($parentWhere:RunFilterInput=null, $first: Int = 10, $afterCursor:String=null, $childWhere: RunRecipientFilterInput = null) { 
    runs(where: $parentWhere) {
      nodes {
        name,
        id,
        runRecipients(first: $first, after: $afterCursor, where: $childWhere) {
          totalCount,
          pageInfo {
            hasNextPage,
            hasPreviousPage,
            startCursor,
            endCursor
          }
          edges {
            cursor,
            node {
              name,
              id,
              externalId,
              email,
              mobile,
              isCompiled,
              isExported,
              isMarkedForTrial,
              onlineId
              ifsFileData {
                sequence
                label
                groupName
              }
              staticAttachments {
                fileName
                size
              }
              run {
                id
              }
              runTemplate {
                id
              }
            }
          }                            
        },
      }
    }
  }`;
}

export function fetchRunRecipientsSummaryData(rawData: any): PageGraphQLData<RunRecipientSummary> {
  try {
    return rawData.data.runs.nodes[0].runRecipients as PageGraphQLData<RunRecipientSummary>;
  }
  catch (e) {
    console.log("runRecipientData That FAIlED", rawData);
    return null;
  }
}

export function _pagedTrialRecipientsQuery() {
  return `query TrialRecipientsForRun($parentWhere:RunFilterInput=null, $where: RunRecipientFilterInput = null) { 
    runs(where: $parentWhere){
      nodes {
        runRecipients(where: $where){
          edges {
            node {
              name,
              id,
              externalId,
              email,
              mobile,
              isCompiled,
              isExported,
              isMarkedForTrial,
              onlineId,
              outputChannels {
                key,
                value {
                  id,
                  channel,
                  isSent,
                  isDelivered,
                  filesSent {
                    fileName,
                    size
                  },
                  sendDate
                }
              },
              possibleOutputChannels {
                key,
                value
              },
              ifsFileData {
                sequence,
                label,
                groupName
              }
            }
          }
        }
      }
    },
  }`;
}

export function fetchRunTrialRecipientsData(rawData: any): PageGraphQLData<RunRecipientSummary> {
    try {
      return rawData.data.runs.nodes[0].runRecipients as PageGraphQLData<RunTrialRecipientSummary>;
    }
    catch (e) {
      console.log("runRecipientData That FAIlED", rawData);
      return null;
    }
}

export function _progressCountersQuery(){
  return `query RunProgressCounters($where: RunFilterInput = null) {
    runs(where: $where) {
      edges {
        node {
            id,
            instance {
              id,
              progressCounters {
                key
                value {
                  status
                  counter
                  expectedCount
                  name
                  lastException
                }
              }
            }
          }
      }
    }
  }`;
} 

export function _fetchProgressCountersData(rawData: any): PageGraphQLData<any> {
  try{
    return rawData.data.runs as PageGraphQLData<any>;
  }
  catch(e)
  {
    console.log("RunProgressCounters That FAIlED", rawData);
    return null;
  }
}

export function _pagedIgnoredRunRecipientsQuery() {
  return `query IgnoredRecipientsForRun($parentWhere:RunFilterInput=null, $childWhere: IgnoredRunRecipientFilterInput=null) { 
    runs(where: $parentWhere) {
      nodes{
        name,
        id,
        ignoredRunRecipients(where: $childWhere){
          edges {
            node {
              id,
              reason,
              recipient,
              runRecipient {
                externalId,
                id,
                name
              },
              channel
            }
          }
        }
      }
    }
  }`;
}

export function fetchIgnoredRunRecipientsData(rawData: any): PageGraphQLData<RunRecipientSummary> {
  try {
    return rawData.data.runs.nodes[0].ignoredRunRecipients as PageGraphQLData<RunRecipientSummary>;
  }
  catch (e) {
    console.log("runRecipientData That FAIlED", rawData);
    return null;
  }
}

export function _pageRunTemplatesQuery() {
  return `
  query RunTemplates($where: RunTemplateFilterInput = null) { 
    runTemplates(where: $where) {
      totalCount,
      pageInfo {
        hasNextPage,
        hasPreviousPage,
        startCursor,
        endCursor
      }
      edges {
        node{
          name
          id
        }
      }
    }
  }`;
}

export function _fetchRunTemplatesData(rawData: any): PageGraphQLData<IiabIdName> {
  try{
    return rawData.data.runTemplates as PageGraphQLData<IiabIdName>;
  }
  catch(e)
  {
    console.log("runTemplatesData That FAIlED", rawData);
    return null;
  }
}

export function _pageRunCustomersQuery() {
  return `
  query RunCustomers($where: CustomerFilterInput = null) { 
    customers(where: $where) {
      totalCount,
      pageInfo {
        hasNextPage,
        hasPreviousPage,
        startCursor,
        endCursor
      }
      edges {
        node {
          name
          id
        }
      }
    }
  }`;
}

export function _fetchRunCustomersData(rawData: any): PageGraphQLData<IiabIdName> {
  try{
    return rawData.data.customers as PageGraphQLData<IiabIdName>;
  }
  catch(e)
  {
    console.log("runCustomersData That FAIlED", rawData);
    return null;
  }
}

export function _pagedProcessorAuditLogQuery() {
  return `query RunsPaged($first: Int = 10, $afterCursor: String = null, $where: RunFilterInput = null) { 
    runs(first: $first, after: $afterCursor, where: $where, order: {lastUpdated: DESC}) {
      nodes {
        processorAuditLog {
          edges {
            node {
              message,
              logType,
              processorTypes,
              runRecipient {
                id
                name
                externalId
              },
              created,
              stacktrace
            }
          }
        }
      }
    }
  }`;
}

export function _fetchProcessorAuditLogData(rawData: any): PageGraphQLData<ProcessorLogs> {
  try{
    return rawData.data.runs.nodes[0].processorAuditLog as PageGraphQLData<ProcessorLogs>;
  }
  catch(e)
  {
    console.log("ProcessorAuditLogData That FAIlED", rawData);
    return null;
  }
}

export function _pagedRunAuditLogQuery() {
  return `query RunsPaged($first: Int = 10, $afterCursor: String = null, $where: RunFilterInput = null) { 
    runs(first: $first, after: $afterCursor, where: $where, order: {lastUpdated: DESC}) {
      nodes {
        runAuditLog {
          edges {
            node {
              id
              action
              additionalActionInformation
              created
              createdBy
              isTrial
              runId
              lastUpdated
              lastUpdatedBy
            }
          }
        }
      }
    }
  }`;
}

export function _fetchRunAuditLogData(rawData: any): PageGraphQLData<RunLogs> {
  try{
    return rawData.data.runs.nodes[0].runAuditLog as PageGraphQLData<RunLogs>;
  }
  catch(e)
  {
    console.log("ProcessorAuditLogData That FAIlED", rawData);
    return null;
  }
}

export function _pageRunSearchData() {
  return `
  query RunSearchData($where: RunSearchDataFilterModelInput = null) {
    runSearchData (filter: $where) {
        totalCount,
        pageInfo{
          hasNextPage,
          hasPreviousPage,
          startCursor,
          endCursor
        },
        edges{
          node {
            name
            id
            externalId
            email
            mobile
            isCompiled
            isExported  
            onlineId
            run {
              id
              name
              bilingDateDescription
              runDate
            }
            runTemplate {
              id
            }
          }
        }
    }
  }`
}

export function _fetchRunSearchData(rawData: any): PageGraphQLData<RunTrialRecipientSummary> {
  if(rawData.data.runSearchData !== null){
    try{
      return rawData.data.runSearchData as PageGraphQLData<RunTrialRecipientSummary>;
    }
    catch(e)
    {
      console.log("runSearchData That FAIlED", rawData);
      return null;
    }
  } else {
    console.log('runSearchData: No results found.');
    return {
      totalCount: 0,
      pageInfo: null,
      edges: []
    };
  }
}

export function _recentRunsSubQuery(){
  return `subscription recentRuns{
    onRecentRunsChanged
  }`;
} 

export function _runProcessedSubQuery(runId:string){
  return `subscription runsProcessing{
    onRunProcessing(runId:"${runId}")
  }`;
} 

export function _runChangedSubQuery(runId:string){
  return `subscription runsChanged{
    onRunChanged(runId:"${runId}")
  }`;
} 