import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cms-home',
  templateUrl: './cms-home.component.html',
  styleUrls: ['./cms-home.component.scss']
})
export class CmsHomeComponent {

  innerWidth = window.innerWidth;

  constructor() { }
}
