import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ifs-puk-prompt',
  templateUrl: './puk-prompt.component.html',
  styleUrls: ['./puk-prompt.component.scss']
})
export class PukPromptComponent {
  @Input() pukState;
  @Output() validatePUK = new EventEmitter<any>();
  pukCode: string;

  constructor() { }
}
