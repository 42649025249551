import { Component } from '@angular/core';

@Component({
  selector: 'cms-edit',
  templateUrl: './cms-edit.component.html',
  styleUrls: ['./cms-edit.component.scss']
})
export class CmsEditComponent  {

}
