import { RunModel } from '../../../../../models/src/lib/rest/Run';
import { PackageModel, TabModel } from "../../../../../models/src/lib/rest/PackageModel";
import { ApiBaseService, InfoSlipsService, RunActionsService, RunRecipientService, SelectedRunService } from '@InfoSlips/iiab-api';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingScreenService } from 'libs/shared/src/lib/services/loading-screen.service';
import { throwError, timer } from 'rxjs';
import { catchError, concatMap, filter, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { InfoSlipState } from '@InfoSlips/models';

@Component({
  selector: 'ifs-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  @Input() context;
  @Input() recipientId;
  @Input() sequence = 0;
  @Input() selectedRunService: SelectedRunService;
  @Output() closePreview: EventEmitter<string> = new EventEmitter<string>();

  selectedRun: RunModel;

  smsText: string;
  ifs: PackageModel;
  ifsPreview$;
  cleariframeUrl;
  iframeUrl;
  emailPreview;
  tabs: TabModel[];
  isPrintable: boolean = false;
  menuItem: string;
  infoslipState: InfoSlipState = {
    activeTab: null,
    currentInfoSlip: null,
    historyAgent: null
  };
  canDownloadPdf: boolean;
  showEmailLoading: boolean;
  showIfsLoading: boolean;

  view = {
    sms: false,
    email: false,
    ifs: false
  }
  recipientConfig;

  constructor(
    private runRecipientService: RunRecipientService, 
    private sanitizer: DomSanitizer,
    private loadingService: LoadingScreenService,
    private infoSlipsService: InfoSlipsService,
    private apiBaseService: ApiBaseService,
    private toaster: ToastrService, 
    private runActionsService: RunActionsService
  ) { 
    
  }

  ngOnInit(): void {

    this.view[this.context] = true;

    if(this.selectedRunService!=null){
      this.selectedRunService.selectedRun$.subscribe((res: RunModel) => {
        this.selectedRun = res;
  
        this.recipientConfig = {
          email: res?.EmailTemplate ? res?.EmailTemplate?.IsEnabled: false,
          pdf: res?.ExportTemplate ? res?.ExportTemplate?.IsEnabled : false,
          sms: res?.SmsTemplate ?  res?.SmsTemplate?.IsEnabled : false,
        }
  
        if(this.selectedRun){
          if(this.selectedRun.SmsTemplate.IsEnabled){
            this.runRecipientService.previewSMS(this.recipientId, null).subscribe((res: any) => {
              this.smsText = res.Body;
            });
          }
          
          if(this.selectedRun.EmailTemplate.IsEnabled){
            this.runRecipientService.previewRazor(this.recipientId, this.selectedRun.EmailTemplate.Body, this.sequence);
      
            this.runRecipientService.razorPreview$.subscribe(res => {
              if(res){
                this.showEmailLoading = true;
                this.emailPreview = this.sanitizer.bypassSecurityTrustHtml(res);
              }
            });
          }
        }
      });
      }

    this.showIfsLoading = true;
    this.ifsPreview$ = this.runActionsService.getTrialPreview(this.recipientId, this.sequence);

    this.ifsPreview$.subscribe((res: PackageModel) => {
      this.infoslipState.currentInfoSlip = res;
      this.infoslipState.activeTab = res?.Tabs[0];
      this.tabs = res?.Tabs;
      this.iframeUrl = res?.Tabs[0].StartPage;
      this.menuItem = res?.Tabs[0].TabName;
      this.isPrintable = res?.Tabs[0].Printable;
      this.canDownloadPdf = res.AllowedActions.DownloadPdf == true;
    });
  }

  close(){
    this.closePreview.emit();
  }

  setMenuItem(item){
    this.infoslipState.activeTab = item;
    this.menuItem = item.TabName;
    this.iframeUrl = item.StartPage;
    this.isPrintable = item.Printable;
    this.loadingService.intervalLoading(1000);
  }

  printPage() {
    try{
      var iframe =  document.getElementById('infoslipIframe');

      if(iframe==null || iframe==undefined)
      {
        console.log("Failed to resolve the IFrame");
        return;
      } 
      window.frames[0].focus();
      window.frames[0].print();
    }
    catch(e)
    {
      console.log("Print error",e);
    }
  }

  getPdf(){
    this.downloadPDF(this.infoslipState).subscribe(res => console.log(res)
    );
  }

  downloadPDF(infoslipState: InfoSlipState) {
    //destructure state into useable variables
    const { historyAgent } = infoslipState;
    const { Id } = infoslipState.currentInfoSlip;
    const { StartPage } = infoslipState.activeTab;
    const fileName = StartPage.substring(
      infoslipState.activeTab.StartPage.lastIndexOf('/') + 1
    );
    //api calls and url
    const pollPDF = this.apiBaseService.executeGet<any>(`viewer/${Id}/GetPdfGenerationStatus/`, "Check PDF Status.");
    let pdfStatus = "";
    
    pollPDF.subscribe(res => pdfStatus = res.PdfStatus);
    
    const getPDF = `viewer/${Id}/GetPrintPdf/${fileName}/`;
    const encodedQueryString = encodeURIComponent(infoslipState.activeTab.QueryString);
    const getPDFWithParams = `viewer/${Id}/GetPdfWithParams/${fileName}/false/${infoslipState.activeTab.PathName}/${encodedQueryString}`;

    return timer(0, 3000).pipe(
      concatMap(_ => {
        if (pdfStatus === "Error") {
          this.toaster.error("'Server Error: Failed to generate PDF. Please contact support.'");
          return throwError('Pdf Generation Error');
        }
        //turn each interval into a poll
        if (_ === 10){
          this.toaster.info("Timeout: Pdf generation still busy, please try again in a couple of seconds.")
          return throwError('timeout reached'); //error, took too long
        }
        return pollPDF;
      }),
      filter((data: any) => {
        //filter out any PDF ready calls
        if(data.PdfStatus === 'Error' && data.Error === 'Invalid PackageId' && historyAgent){
          return true;
        }
        return (
          data.PdfStatus === 'NotStarted' || data.PdfStatus === 'Completed'
        );
      }),
      take(1),
      catchError(error => {
        console.error(
          'Server Error: Failed to generate PDF. Please contact support.'
        );
        return throwError(error);
      }),
      switchMap(_ => {
        if(!infoslipState.activeTab.IsSinglePage)
          return this.infoSlipsService.download(getPDF);
        return this.infoSlipsService.download(getPDFWithParams);
      }) //download PDF
    );
  }

  onEmailLoad() {
    this.showEmailLoading = false;
  }

  onIfsLoad() {
    this.showIfsLoading = false;
  }

  private checkPrintableTabs(tabs: TabModel[]): boolean {
    if (tabs.some(x => x.Printable == true)) return true;
    else return false;
  }
}
