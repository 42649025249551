<div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Date</th>
        <th class="text-right">New recipient count</th>
        <th class="text-right">Mail processed counter</th>
        <th class="text-right">Mail sent failure</th>
        <th class="text-right">Sms total sent</th>
        <th class="text-right">Sms failed</th>
        <th class="text-right">Export Pdf count</th>
        <th class="text-right">Export Pdf pages</th>
        <th class="text-right">Export Pdf fail</th>
        <th class="text-right">Export Ifs count</th>
        <th class="text-right">Export Ifs fail</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of reportDetails$ | async">
        <td>{{ field?.name }}</td>
        <td>{{ field?.bilingDate?.dateTime.split("T")[0] }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.newRecipientCount }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.mailProcessedCounter }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.mailSentFailCounter }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.smsTotalSent }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.smsFailed }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.exportPdfCount }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.exportPdfPages }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.exportPdfFail }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.exportIfsCount }}</td>
        <td class="text-right">{{ field?.instance?.statistics?.exportIfsFail }}</td>
      </tr>
    </tbody>
  </table>

  <div class="row action-buttons">
    <div>
      <button
        [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasPreviousPage" nbButton (click)="pageReport('previous')">
        Previous
      </button>
      <button
        [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasNextPage" nbButton (click)="pageReport('next')">
        Next
      </button>
    </div>
    <p>{{ (reportDetailsPaging$ | async)?.totalCount }} Items</p>
  </div>
</div>
