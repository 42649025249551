import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PasswordService } from '@InfoSlips/iiab-auth';
import { ForgotPasswordModel } from '@InfoSlips/models';

@Component({
  selector: 'ifs-password-confirm-form',
  templateUrl: './password-confirm-form.component.html',
  styleUrls: ['./password-confirm-form.component.scss']
})
export class PasswordConfirmFormComponent implements OnInit {
  resetConfirmForm: FormGroup;
  submitted = false;
  showPassword = true;
  showPasswordConfirm = true;
  selectedDuplicateUserId:string = null;

  passwordResetBody:ForgotPasswordModel  = {
    ConfirmPassword: "",
    DuplicateUsers: null,
    Email: "",
    NewPassword: "",
    Token:  this.activatedRoute.snapshot.paramMap.get('token'),
    UserName: ""
  };
  
  passwordRules = [
    {
      label: 'At least one digit',
      value: `[0-9]`
    },
    {
      label: 'At least one lowercase character',
      value: `[a-z]`
    },
    {
      label: 'At least one uppercase character',
      value: `[A-Z]`
    },
    {
      label: 'At least one special character',
      value: `[*!@#$%^&(){}[]:;<>,.?/~_-=|\\]`
    },
  ];

  constructor(
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
  ) { }

  @Input() error;
  @Input() loading;
  @Output() openId = new EventEmitter<string>();

  ngOnInit(): void {

    this.resetConfirmForm = this.fb.group({
      userName: [''],
      email: [''],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.passwordService.forgotPasswordToken(this.activatedRoute.snapshot.paramMap.get('token')).subscribe(
      (res: any) => {
        this.passwordResetBody.Email = res.Email;
        this.passwordResetBody.DuplicateUsers = res.DuplicateUsers;

        this.resetConfirmForm.patchValue({
          email: res.Email,
          userName: res.DuplicateUsers != null ? res.DuplicateUsers[0] : ""
        })
      }
    )
  }

  get userName() {
    return this.resetConfirmForm.get('userName');
  }

  get email() {
    return this.resetConfirmForm.get('email');
  }

  get password() {
    return this.resetConfirmForm.get('password');
  }

  onSubmit() {
    this.submitted = true;
    const form = this.resetConfirmForm;
    if (form.invalid) {
      return;
    }
    this.loading = true;

    this.passwordResetBody.NewPassword = form.value.password;
    this.passwordResetBody.ConfirmPassword = form.value.confirmPassword;
    this.passwordResetBody.UserName = form.value.userName;
    
    console.log(this.passwordResetBody);
    
  
      this.passwordService.resetPassword(this.passwordResetBody);
  }

  getInputType(password) {
    if (password) {
      return 'password';
    }
    return 'text';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowPasswordConfirm() {
    this.showPasswordConfirm = !this.showPasswordConfirm;
  }
}
