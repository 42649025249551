export const baseEnvironment = {
  applicationName: 'admin',
  secondsToWarn:30,
  oldAdminEnabled: true,
  tinyMceKey: 'barxj19jf16ka2leev0kz8tnzimw8x0y5w3pxm4tp6c0h3uk',
};

export function getGlobalVariable(key: string): any {
  if (window && window['customEnv']) 
    return window['customEnv'][key];

  return null;
}