<nb-card>
    <nb-card-header *ngIf="isNew">
      <nb-checkbox status="basic" [(ngModel)]="template.SmsTemplate.IsEnabled">Send sms to recipients?</nb-checkbox>
    </nb-card-header>
    <ng-container *ngIf="isSmsEnabled">
        <nb-card-body *ngIf="template.SmsTemplate.IsEnabled">
            <!-- <ngx-monaco-editor *ngIf="isSmsEnabled" height="800px" (ngModelChange)="onChange($event)" [(ngModel)]="template.SmsTemplate.Body" [options]="monacoConfig.editorOptions"></ngx-monaco-editor> -->
            <ifs-monaco-editor *ngIf="isSmsEnabled" [(data)]="template.SmsTemplate.Body" language="plaintext" (onChange)="onChange($event)"></ifs-monaco-editor>
          </nb-card-body>
    </ng-container>
 
</nb-card>

<!-- <div class="col-md-4">
    <nb-card>
        <nb-card-body class="padded">
            <p style="color: red"><i>Please note that the counts below DOES NOT include placeholders</i></p>
            <p>Character Count: {{ template.SmsTemplate.CharacterCount }}</p>
            <p>SMS Count: {{ template.SmsTemplate.SmsCount }}</p>
        </nb-card-body>
    </nb-card>
</div> -->