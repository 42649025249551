import { Component, OnInit } from '@angular/core';
import { BlacklistModel, BlacklistReference } from '@InfoSlips/models';
import { ApiBaseService, Miscellaneous, MiscService } from '@InfoSlips/iiab-api';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { exhaustMap } from 'rxjs/operators';
import { RecipientsBlacklistService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-admin-recipient-blacklist',
  templateUrl: './recipient-blacklist.component.html',
  styleUrls: ['./recipient-blacklist.component.scss']
})
export class RecipientBlacklistComponent implements OnInit {
  
  blacklist$: Observable<BlacklistReference[]>;
  channelTypes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('OutputChannelCodes');
  newBlacklistedRecipient: BlacklistModel;

  searchPhrase: string;
  batchRemovalFile = null;
  batchIncludeFile = null;
  batchRemovalFilesAdded= 0;
  batchIncludeFilesAdded= 0;
  batchRemovalFileName = "";
  batchIncludeFileName = "";

  deleteConfirm = false;
  revealed = false;
  manageBlacklist = false;

  constructor(
    private apiBaseService: ApiBaseService,
    private toastr: ToastrService,
    private recipientsBlacklistService: RecipientsBlacklistService,
    private miscService: MiscService,) 
  { }

  ngOnInit(): void {
    this.searchPhrase = '';
    this.blacklist$ = this.recipientsBlacklistService.blacklist.Items$().pipe(
      exhaustMap((res) => of(res)));

    this.newBlacklistedRecipient = this.blankBlacklistedModel();
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  toggleViewDeleteConfirm() { 
    this.revealed = !this.revealed; 
  }

  delete(id: string, confirmed: boolean){
    if(confirmed){
      this.apiBaseService.executeDelete(`recipientBlacklist/${id}`, `Delete Blacklisted Recipient.`).subscribe(isDeleted => {
        console.log("IsDeleted",isDeleted);
        isDeleted ? this.toastr.info(`Blacklisted Recipient deleted!`) : this.toastr.error(`Blacklisted Recipient deletion failed.`);
      });
    }
    this.deleteConfirm = !this.deleteConfirm;
  }

  onChange() {
    const wordSearch = this.searchPhrase;
    this.blacklist$ = of([{ recipient: "Loading...", channel: null, reason: "", created: "", id: "Loading..."}]);

    const FilterObject = { recipient: { like:  this.searchPhrase }}

    setTimeout(() => {
      if (wordSearch == this.searchPhrase) {
        this.recipientsBlacklistService.blacklist.loadPagedItems(this.searchPhrase ? FilterObject : null);
        this.blacklist$ = this.recipientsBlacklistService.blacklist.Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 600);    
  }

  toggleManageBlacklist(clear = false){
    if (clear) {
      this.newBlacklistedRecipient = null;
    }
    this.manageBlacklist = !this.manageBlacklist;
  }

  closeManager(event) {
    if (event.tabIcon === "close-outline") this.toggleManageBlacklist();
  }

  addBlacklistedRecipient() {
    let validRecipient = true;
    if (this.newBlacklistedRecipient.Reason == "") { validRecipient = false; this.toastr.error('Please enter a valid reason') };
    if (this.newBlacklistedRecipient.Recipient == "") { validRecipient = false; this.toastr.error('Please enter a valid recipient') };
    if (this.newBlacklistedRecipient.Channel == null) { validRecipient = false; this.toastr.error('Please select a valid channel') };

    if (validRecipient) {
      this.apiBaseService.executePost<BlacklistModel>('recipientBlacklist', this.newBlacklistedRecipient, 'Blacklist Recipient').subscribe((res: any) => {
        if(res){
          this.toastr.success('Blacklisted Recipient Added');
          this.newBlacklistedRecipient = this.blankBlacklistedModel();
        }
      });
    }
  }

  fileDropBatchRemove(event){
    this.batchRemovalFilesAdded = 0;

    const dt = event.dataTransfer || event.target;
    const dtf = dt.files[0];
    const file = {
      fileName: dtf.name,
      file: dtf
    }
    this.batchRemovalFileName = file.fileName;
    event.preventDefault();
    this.batchRemovalFile = file;
  }

  fileDropBatchInclude(event){
    this.batchIncludeFilesAdded = 0;

    const dt = event.dataTransfer || event.target;
    const dtf = dt.files[0];
    const file = {
      fileName: dtf.name,
      file: dtf
    }
    this.batchIncludeFileName = file.fileName;
    event.preventDefault();
    this.batchIncludeFile = file;
  }

  async uploadBatchRemoval() {
    if (this.batchRemovalFile == null) this.toastr.info(`Please select a file to upload`);    
    else await this.executeUpload("Remove", this.batchRemovalFile);
  }

  async uploadBatchInclude() {
    if (this.batchIncludeFile == null) this.toastr.info(`Please select a file to upload`);
    else await this.executeUpload("Include", this.batchIncludeFile);
  }

  onDragOverRemoval(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onDragOverInclude(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private blankBlacklistedModel() {
    return {
      "Channel": null,
      "Recipient": "",
      "Reason": "",
      "Name": ""
    }
  }

  private async executeUpload(context: string, file){
    return new Promise(resolve => {
      this.recipientsBlacklistService.uploadBatchFile(context, file.file).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {   
          if (context === "Include") this.batchIncludeFilesAdded = 2;
          else this.batchRemovalFilesAdded = 2;
        } else if (event instanceof HttpResponse) {
          resolve(() => {
            if (context === "Include") {
              this.batchIncludeFile = [];
              this.batchIncludeFilesAdded = 1;
            } else {
              this.batchRemovalFile = [];
              this.batchRemovalFilesAdded = 1;
            }
            this.toastr.info(`Batch file successfully uploaded`)
          });
        }
      })
    })
  }
}