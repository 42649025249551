
    <form
      *ngIf="profileModel | async; else loading"
      [formGroup]="profileUpdateForm"
      (ngSubmit)="onSubmit()"
    >
      <section class="title"><h3>Profile Overview</h3></section>
      <div class="form-group">
        <label class="label">Name and Surname</label><br/>
        <input
          nbInput
          fullWidth
          fieldSize="medium"
          formControlName="DisplayName"
          type="text"
          name="nameandsurname"
          required
        />
      </div>
      <div class="form-group">
        <label class="label">Username</label><br/>
        <input
          disabled
          formControlName="Username"
          nbInput
          fullWidth
          fieldSize="medium"
          type="text"
          name="username"
          required
        />
      </div>
      <div class="form-group">
        <label class="label">Primary Email Address </label><br/>
        <input
          disabled
          formControlName="Email"
          nbInput
          fullWidth
          fieldSize="medium"
          type="text"
          name="email"
          required
        />
      </div>
      <div class="form-group">
        <label class="label">Additional Email Addresses &nbsp;
          <button
            type="button"
            (click)="addItem('email')"
            nbButton
          >
            <nb-icon icon="add" pack="oakmoon"></nb-icon>
          </button>
        </label><br/>
      </div>
      <div formArrayName="UserEmails" class="form-group">
        <div *ngFor="let email of userEmails; let i = index">
            <input 
            [value]="email" 
            disabled           
            nbInput
            fullWidth
            fieldSize="medium" 
            type="text" required />
        </div>
      </div>

      <div class="form-group">
        <label class="label">Primary Mobile Number</label><br/>
        <input
          disabled
          formControlName="Mobile"
          name="Number"
          nbInput
          fullWidth
          fieldSize="medium"
          type="text"
          required
        />
      </div>

      <div class="form-group" *ngIf="!mobile">
        <label class="label">Additional Mobile Numbers &nbsp;
          <button
            type="button"
            (click)="addItem('mobile')"
            nbButton
          >
          <nb-icon icon="add" pack="oakmoon"></nb-icon>
          </button>
        </label><br/>
      </div>

      <div formArrayName="MobileNumbers" class="additional-mobile-numbers form-group">
        <div
          *ngFor="let number of mobileNumbers.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="form-group">
            <label class="label">Country</label><br/>
            <nb-select
              formControlName="CountryCode"
              placholder="Country"
              fieldSize="medium"
            >
              <nb-option *ngFor="let c of countries" [value]="c.Id">{{
                c.Name
              }}</nb-option>
            </nb-select>
          </div>
          <div class="form-group">
            <label class="label">Number</label><br/>
            <input
              formControlName="Number"
              nbInput
              fullWidth
              fieldSize="medium"
              type="text"
              required
            />
          </div>
        </div>
        <button
          *ngIf="profileUpdateForm.dirty"
          type="submit"
          nbButton
          status="primary"
        >
          Save
        </button>
      </div>
    </form>
    <ng-template #loading> Loading Profile... </ng-template>

    <ng-template #dialog let-data let-ref="dialogRef">
      <nb-card class="dialog-md">
        <nb-card-header>Add to InfoSlip Detail</nb-card-header>
        <nb-card-body>
            <form
              [formGroup]="addInfoSlipDetailForm"
              (ngSubmit)="addInfoSlipDetail()"
            >

            <div *ngIf="add === 'mobile'" class="form-group">
                <nb-select placholder="Country" formControlName="CountryCode" placeholder="Country">
                  <nb-option *ngFor="let c of countries" [value]="c.Id">{{
                    c.Name
                  }}</nb-option>
                </nb-select>
                <input
                nbInput
                fullWidth
                fieldSize="medium"
                type="text"
                formControlName="Number"
                required
                placeholder="Mobile Number"
              />
            </div>

              
              <div class="form-group" *ngIf="add === 'email'">
                <label class="label">Additional Email Address</label><br/>
                <input
                  nbInput
                  fullWidth
                  fieldSize="medium"
                  type="email"
                  formControlName="Email"
                  required
                  placeholder="Email"
                />
                <ng-container *ngIf="Email.invalid && Email.touched">
                  <p class="caption status-danger" *ngIf="Email.errors?.required">
                    Please enter a valid email address.
                  </p>
                </ng-container>
              </div>
            </form>
        </nb-card-body>
        <nb-card-footer>
          <button type="button" nbButton outline (click)="ref.close()">
            Cancel
          </button>
          <button
            [disabled]="!addInfoSlipDetailForm.valid"
            nbButton
            status="primary"
            (click)="addInfoSlipDetail()"
          >
            Add
          </button>
        </nb-card-footer>
      </nb-card>
    </ng-template>
