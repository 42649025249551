
 <div *ngIf="innerWidth > 845" class="desktop" style="display: flex;">
    <div class="actions-container" style="max-width: 30%; background: #e1e1e1; min-width: 30%;">
        <div class="dashboard-detail-container">
            <div class="intro-container">
                <div class="intro">
                    <h4>Welcome {{ authService.loggedInUser.displayName}}</h4>
                    <p>What would you like to manage today?</p>
                </div>

                <nb-icon (click)="openActionsSetup(dialog)" pack="oakmoon" icon="settings" class="actions-icon"></nb-icon>
            </div>
        
            <div class="grid">
                <ng-container *ngFor="let quickAction of dashboardService.quickActions$ | async">
                    <div class="quick-action appearance-filled size-medium shape-rectangle status-basic nb-transition" nbButton (click)="openWizard(quickAction.wizard.context, quickAction.wizard.menuItem)">
                        <div class="action">
                            <nb-icon [pack]="quickAction.pack" [icon]="quickAction.icon"></nb-icon>
                            <span>{{ quickAction.name }}</span>
                        </div>

                        <div class="action-group">
                            <ng-container *ngFor="let action of quickAction.actions; index as i">
                                <ng-container *ngIf="action.useWizard">
                                    <button fullWidth nbButton status="primary" [outline]="i === 0 ? false : true">
                                        <nb-icon pack="eva" [icon]="action.name === 'create' ? 'plus' : action.name"></nb-icon>
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="!action.useWizard">
                                    <button fullWidth nbButton status="primary" [outline]="i === 0 ? false : true" [routerLink]="action.routerLink">
                                        <nb-icon pack="eva" [icon]="action.name === 'create' ? 'plus' : action.name"></nb-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div style="padding: 1rem; padding-top: 0px;">
                <ifs-bookmark-list></ifs-bookmark-list>
            </div>
        </div>
    </div>

    <div class="content-container" style="background: #e1e1e1 !important;">
        <div style="display: flex;">
            <div class="actions-container" >
                <div class="grid-detail-container">
                    <div class="filter-container">
                      
                      <nb-form-field>
                        <input nbInput fullWidth type="text" name="search" placeholder="Search by Name or Id" [(ngModel)]="searchPhrase" (ngModelChange)="search()"/>
                        <button nbSuffix nbButton ghost (click)="toggleFilter()" [class.active]="isFilterExpanded">
                          <nb-icon [ngClass]="runFilterObjectLength > 0 ? 'filter-on' : 'filter-off'" icon="filter-on" pack="oakmoon"></nb-icon>
                        </button>
                    </nb-form-field>
                  
                    </div>
          
                    <nb-card *ngIf="isFilterExpanded">
                        <nb-card-body>
                            <ifs-run-filter type="grid" (toggleFilter)="toggleFilter()"></ifs-run-filter>
                        </nb-card-body>
                    </nb-card>
                 
                </div>
            </div>
            
        </div>
        <ifs-runs-grid [isFilterExpanded]="isFilterExpanded"></ifs-runs-grid>
    </div>
</div>

<div *ngIf="innerWidth < 845" class="mobile">
    <ifs-runs></ifs-runs>
    <!-- <div class="dashboard-detail-container">
        <div class="intro-container">
            <div class="intro">
                <h4>Welcome {{ authService.loggedInUser.displayName}}</h4>
                <p>What would you like to manage today?</p>
            </div>

            <nb-icon (click)="openActionsSetup(dialog)" pack="oakmoon" icon="settings" class="actions-icon"></nb-icon>
        </div>
    
        <div class="grid">
            <ng-container *ngFor="let quickAction of dashboardService.quickActions$ | async">
                <div class="quick-action appearance-filled size-medium shape-rectangle status-basic nb-transition" nbButton (click)="openWizard(quickAction.wizard.context, quickAction.wizard.menuItem)">
                    <div class="action">
                        <nb-icon pack="oakmoon" [icon]="quickAction.icon"></nb-icon>
                        <span>{{ quickAction.name }}</span>
                    </div>

                    <div class="action-group">
                        <ng-container *ngFor="let action of quickAction.actions; index as i">
                            <ng-container *ngIf="action.useWizard">
                                <button fullWidth nbButton status="primary" [outline]="i === 0 ? false : true">
                                    <nb-icon pack="eva" [icon]="action.name === 'create' ? 'plus' : action.name"></nb-icon>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="!action.useWizard">
                                <button fullWidth nbButton status="primary" [outline]="i === 0 ? false : true" [routerLink]="action.routerLink">
                                    <nb-icon pack="eva" [icon]="action.name === 'create' ? 'plus' : action.name"></nb-icon>
                                </button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <div style="padding: 1rem; padding-top: 0px;">
            <ifs-bookmark-list></ifs-bookmark-list>
        </div>
    </div> -->
</div>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card status="primary" style="min-width: 500px;">
      <nb-card-header>Quick Action Configuration (6 Required)</nb-card-header>
      <nb-card-body>
          <nb-list>
            <nb-list-item *ngFor="let action of dashboardService.availableActions" style="justify-content: space-between;">
              <nb-user [name]="action.name" [title]="getActionNames(action.actions)">
              </nb-user>
              <nb-toggle status="primary" [(ngModel)]="action.enabled" (checkedChange)="enableAction($event)"></nb-toggle>
            </nb-list-item>
          </nb-list>
      </nb-card-body>
      <nb-card-footer class="dialog-footer">
        <button nbButton (click)="ref.close()">Close</button>
        <button status="primary" nbButton (click)="setActions()">Save</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
 