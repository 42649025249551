import { BaseEntityIdNameModel, BaseEntityModel } from "./BaseEntity";
import { CustomerReferenceModel } from "./CustomerModels";

export interface TrialRecipients extends BaseEntityModel {
  Puk: null;
  IsNew: boolean;
  IsCompiled: boolean;
  LastException: null;
  IsMarkedForTrial: boolean;
  MailSentCount: number;
  OnlineId: string;
  IsExported: boolean;
  HasExpired: boolean;
  IfsFileData: IfsFileData[];
  StaticAttachments: any[];
  OutputChannels: OutputChannels;
  PossibleOutputChannels: PossibleOutputChannels;
  Customer: CustomerReferenceModel;
  ExternalId: string;
  Email: string;
  Mobile: string;
  PublicId: string;
  Run: {
    BilingDateDescription: string;
    RunDate: string;
    Name: string;
    Id: string;
  };
  RunTemplate: BaseEntityIdNameModel;
}


interface IfsFileData {
  Sequence: number;
  Label: string;
  ProcessedFiles: any[];
  GroupName: string;
}

interface OutputChannels {
}

interface PossibleOutputChannels {
  Email: number[];
}
