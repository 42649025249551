import { CustomerModel } from '@InfoSlips/models';
import { CustomerService } from '@InfoSlips/iiab-api';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { CrudBaseService } from 'libs/iiab-api/src/lib/services/base/crud-base.service';

@Component({
  selector: 'ifs-admin-customers-container',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersContainer implements OnInit {
  
  @ViewChild('nextStep') private nextStepDialogRef: TemplateRef<any>;

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute, 
    private dialogService: NbDialogService,
    private router: Router,
    private crudBaseService: CrudBaseService
  ) {}

  customer: CustomerModel;
  isNew = true;
  dialogRef;
  
  ngOnInit() {
    if(this.route.snapshot.params.id){
      this.isNew = false;
    }
    this.customerService.getCustomer(this.route.snapshot.params.id).subscribe(async (res) => {
      if(res){
        this.customer = res
      } else{
        this.customer = await this.crudBaseService.getDefault<CustomerModel>(0);
      }
    });
  }

  updateCustomer(customer:CustomerModel) {
    this.customerService.updateCustomer(customer)
  }

  async createCustomer(customer:CustomerModel){
    let response = await this.customerService.createCustomer(customer);

    if (response == true) {  
      this.dialogRef = this.dialogService.open(this.nextStepDialogRef, { context: {customerName: customer.Name, routeToModify: this.customerService.selectedCustomerSubject.getValue().Id}});
    }
  }

  modify(routerLink: string) {
    this.dialogRef.close();
    this.router.navigateByUrl(routerLink);
  }

  async reset() {
    this.dialogRef.close();
    this.customer = await this.crudBaseService.getDefault<CustomerModel>(0);
    this.router.navigateByUrl("/admin/customers");
  }

  home() {
    this.dialogRef.close();
    this.router.navigateByUrl("/");
  }
}
