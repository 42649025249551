<form [formGroup]="resetConfirmForm" (ngSubmit)="onSubmit()" class="login">
    <div class="row">
      <div class="col-md-12">
        <p class="pull-left title" data-e2e="cardTitle">
          Confirm Password Reset
        </p>
      </div>
    </div>

    <div class="form-body row">
      <div class="col-md-12">

        <nb-form-field *ngIf="passwordResetBody?.DuplicateUsers === null; else duplicateUsers">
          <label>Username/Email</label>
          <input nbInput class="custom-input" fullWidth data-e2e="email" type="text" name="email"
            formControlName="email" placeholder="Username/Email/Mobile" readonly/>
        </nb-form-field>
          
        <ng-template #duplicateUsers>
          <label>Username</label>
          <nb-select fieldSize="small" fullWidth placeholder="Select Username" data-e2e="userName" type="text" name="userName"
          formControlName="userName">
            <nb-option *ngFor="let user of passwordResetBody.DuplicateUsers" [value]="user">{{ user }}</nb-option>
          </nb-select>
        </ng-template>

        <nb-form-field>
          <label>Password</label>
          <input [type]="getInputType(showPassword)" class="custom-input" nbInput fullWidth data-e2e="password"
            formControlName="password" type="password" name="password" placeholder="Password" />
          <a nbSuffix nbButton ghost (click)="toggleShowPassword()">
            <nb-icon 
              [icon]="!showPassword ? 'view' : 'view-off'" 
              pack="oakmoon"
              [attr.aria-label]="!showPassword ? 'hide password' : 'show password'">
            </nb-icon>
          </a>
        </nb-form-field>
        <nb-form-field>
            <label>Confirm Password</label>
            <input [type]="getInputType(showPasswordConfirm)" class="custom-input" nbInput fullWidth data-e2e="password"
              formControlName="confirmPassword" type="password" name="confirmPassword" placeholder="Confirm Password" />
            <a nbSuffix nbButton ghost (click)="toggleShowPasswordConfirm()">
              <nb-icon [icon]="!showPasswordConfirm ? 'view' : 'view-off'" pack="oakmoon"
                [attr.aria-label]="!showPasswordConfirm ? 'hide password' : 'show password'">
              </nb-icon>
            </a>
          </nb-form-field>

          <p *ngFor="let rule of passwordRules" class="text-left">{{ rule.label }} <b class="caption status-warning">{{ rule.value }}</b></p>
          <p class="text-left">At least <b class="caption status-warning">10</b> characters in length</p>
          
        <ng-container *ngIf="password.invalid && password.touched">
          <p class="caption status-danger" *ngIf="password.errors?.required">
            Password is required!
          </p>
        </ng-container>
      </div>
    </div>
  
    <div class="form-footer">
      <div class="row">
        <div class="col-md-12">
          <button [nbSpinner]="loading" nbSpinnerStatus="info" [disabled]="resetConfirmForm.invalid" nbButton fullWidth
            status="primary" data-e2e="submit" type="submit">
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>