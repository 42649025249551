import { CmsAudit, CmsContentType, CmsEntityScope, CmsEntityType } from "../Enums";
import { CustomerReference } from "./customer";
import { IiabEntity, IiabIdName } from "./iiab-reference";

export interface CmsEntity extends IiabEntity {
    display?: string;
    contentType?: string;
    notes?: string;
    hash?: string;
    version?: number;
    publicId: string;
    language?: string;
    lastAction?: CmsAudit;
    tags?: string[];
    customer?: CustomerReference;
    runTemplate?: IiabIdName;
    customerGroup?: IiabIdName;
    cmsEntityType: CmsEntityType;
    cmsContentType: CmsContentType;
    scope?: CmsEntityScope
    data: string;
    length?: number;
    fileExtension?: string;
    isDraft: boolean;
    isRazor?: boolean;
}

export interface CmsEntitySummary extends IiabEntity{
    display?: string;
    version?: number;
    cmsEntityType: CmsEntityType;
    cmsContentType: CmsContentType;
    isDraft: boolean;
    customer?: CustomerReference;
    runTemplate?: IiabIdName;
    customerGroup?: IiabIdName;
}

export interface CmsRevision extends IiabIdName {
    display: string;
    version: number;
}

export interface CmsEntityReference extends IiabIdName{
    type: CmsEntityType;
    scope: CmsEntityScope;
    cmsContentType: CmsContentType;
    publicId: string;
    useOffline: boolean;
    specificVersion: boolean;
    version: number;
    length: number;
    isDraft: boolean;
    lockLatestVersion: boolean;
    display?: string;
}


export interface CmsLinkedToItem{
    id:string
    referenceItem:CmsReferenceItem,
    cmsEntityReferences:CmsEntityReference[]
}

export interface CmsReferenceItem extends IiabIdName{
    runDate: Date
}
