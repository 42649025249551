
<ifs-logged-out-view style="margin-top: -20%">
    <div class="login-wrapper">
      <form (ngSubmit)="onSubmit()" class="login">
        <p class="title">
          Two-Factor Authentication Required
        </p>
        <div class="row form-body">
            <div class="col-md-12 text-center">
              <div *ngIf="otpState.hasRequestedOtp">
                <label>Enter OTP</label>
                <input nbInput class="custom-input" fullWidth type="text" name="otp" [(ngModel)]="oneTimePin"
                  placeholder="OTP was sent to: {{ otpState.otp.Request.To }}" />
              </div>
  
              <div class="alert alert-danger alert-sm" *ngIf="error" role="alert">
                <div class="alert-items">
                  <div class="alert-item static">
                    <div class="alert-icon-wrapper"></div>
                    <div class="alert-text">OTP not valid!</div>
                  </div>
                </div>
                <button type="button" class="close" aria-label="Close"></button>
              </div>
            </div>
          </div>
        <div class="form-footer">
          <div class="row">
            <div class="col-md-12" *ngIf="!otpState.hasRequestedOtp">
              <button class="btn btn-primary btn-block mt-2" (click)="requestOTPevent()">
                Send OTP
              </button>
            </div>
              <ng-container *ngIf="otpState.hasRequestedOtp">
                <div class="col-md-6">
                    <button nbButton fullWidth status="default" type="button" (click)="resendOTPevent()">Resend OTP</button>
                  </div>
                <div class="col-md-6">
                  <button nbButton fullWidth status="primary" type="submit" (click)="validateOTPevent()">Validate
                    OTP</button>
                </div>
              </ng-container>

          </div>
          </div>
      </form>
    </div>
  </ifs-logged-out-view>