import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class AuthUrlsService {
    //#region TwoFA
    public GetTTwoFAUrl = 'profile/OtpInfo';
    public SaveTwoFAUrl = 'profile/SaveOtp';
    public ToggleTwoFAUrl(enableTwoFA: boolean) {
        return `profile/ToggleOtp/${enableTwoFA}`;
    }
    //#endregion

    //#region ForgotPassword
    RequestForgotPassword(email: string, requester: number): string {
        return `forgotpassword/${email}/${requester}`;
    }
    SendForgotPasswordToken(token: string): string {
        return `forgotpassword/${token}`;
    }
    ChangePassword(): string {
        return 'login/changePassword';
    }
    ResetPassword(): string {
        return 'forgotpassword/resetPassword';
    }
    //#endregion

    //#region Registration
    RegisterEmail(email:string){
        return `registration/email?id=${email}`;
    }
    RegisterMobile(mobile:string, country:string){
        return `registration/mobile?id=${mobile}&country=${country}`;
    }
    ConfirmRegistration(){
        return 'registration';
    }
    GetCountries(top:number){
        return `country/?$top=${top}`;
    }
    GetActivationLink(token:string){
        return `registration/${token}/activationlink`;
    }
    //#endregion
}