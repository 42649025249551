import { NebularModule } from '@InfoSlips/nebular';
import { IconComponent } from './components/icon/icon.component';
import { GhostCardsComponent } from './components/ghost-cards/ghost-cards.component';
import { LoadingScreenInterceptor } from './interceptors/loading.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { transition, style, animate, trigger, query, keyframes, stagger, group } from '@angular/animations';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { TinyEditorComponent } from './containers/tiny-editor/tiny-editor.component';
import { MonacoEditorComponent } from './containers/monaco-editor/monaco-editor.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ClipboardComponent } from './components/clipboard/clipboard.component';
import { CopyComponent } from './components/copy/copy.component';
import { LocalDate } from './pipes/date.pipe';
import { SecureKeysComponent } from './components/secure-keys/secure-keys.component';
import { ActivityBarSharedComponent } from './components/activity-bar/activity-bar.component';
import { SecurePipe } from './pipes/secure.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';

export const headerAnimation = trigger('headerAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0.8) translateY(100%)' }),
    animate('400ms', style({ opacity: 1, transform: 'scale(1)' }))
  ]),
  transition(':leave', [
    animate('400ms', style({ opacity: 0, transform: 'scale(0) translateY(1000px)' }))
  ])
]);

export const infoslipAnimation = trigger('infoslipAnimation', [
  transition(':enter', [
    style({ transform: 'scale(0.9)', opacity: 0.5 }),
    animate('300ms ease-in', style({ transform: 'scale(1)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1 }),
    animate('300ms ease-out', style({ transform: 'scale(0.9)', opacity: 0.5 }))
  ]),
]);

export const fadeOut = trigger('fadeOut', [
  transition('* => *', [
    query(':leave', [
      style({ opacity: 1 }),
      stagger('50ms', [
        animate('200ms', style({
          opacity: 0
        }))
      ])
    ], { optional: true })
  ])
])

export const staggerItems = trigger('staggerItems', [
  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(':enter', stagger('100ms', [
      animate('400ms ease-in', keyframes([
        style({ opacity: 0, transform: 'translateY(-30px)', offset: 0 }),
        style({ opacity: 0.5, transform: 'translateY(10px)', offset: 0.3 }),
        style({ opacity: 1, transform: 'translateY(0px)', offset: 1 }),
      ]))
    ]), { optional: true })
  ])
]);

export const slideInAnimation = trigger('slideInAnimation', [
  transition(':enter', [
    style({ transform: 'translateY(-10px) translateX(-10px)', opacity: 0 }),
    animate('700ms ease-in-out', style({ transform: 'translateY(0px) translateX(0px)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0px)', opacity: 0 }),
    animate('700ms ease-in-out', style({ transform: 'translateY(-100%)', opacity: 1 }))
  ]),
]);

const enterLeft = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
    ], { optional: true }),
    query(':leave', [
      style({ transform: 'translateX(0%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
    ], { optional: true }),
  ])];

const enterRight = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
    ], { optional: true }),
    query(':leave', [
      style({ transform: 'translateX(0%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
    ], { optional: true }),
  ])
]


const leaveSlip = [
  query(':leave', style({}), { optional: true }),
  group([
    query(':leave', [
      style({ transform: 'translateY(0px)', opacity: 1 }),
      animate('400ms ease-in-out', style({ transform: 'translateY(0px) scale(0.8)', opacity: 0 }))
    ], { optional: true })
  ])
]


const toProfile = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%', maxWidth: '1920px' }), { optional: true }),
  group([
    query(':enter', [
      style({ transform: 'scale(0.5) translateY(-20%) translateX(120%)', opacity: 1 }),
      animate('700ms ease-out', style({ transform: 'scale(1) translateY(0px) translateY(0px)', opacity: 1 }))
    ], { optional: true }),
    query(':leave', [
      style({ transform: 'scale(1) translateY(0%) translateX(0%)', opacity: 1 }),
      animate('700ms ease-out', style({ transform: 'scale(1) translateY(0%) translateY(0%)', opacity: 0 }))
    ], { optional: true }),
  ])
];

const fromProfile = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%', maxWidth: '1920px' }), { optional: true }),
  group([
    query(':enter', [
      style({ opacity: 0 }),
      animate('500ms ease-out', style({ opacity: 1 }))
    ], { optional: true }),
    query(':leave', [
      style({ transform: 'scale(1) translateY(0%) translateX(0%)', opacity: 1 }),
      animate('500ms ease-out', style({ transform: 'scale(0) translateY(-120%) translateX(150%)', opacity: 0 }))
    ], { optional: true }),
  ])
];

const loginZoom = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%', maxWidth: '1920px' }), { optional: true }),
  group([
    query(':enter', [
      style({ opacity: 0, transform: 'scale(0.8)' }),
      animate('400ms', style({ opacity: 1, transform: 'scale(1)' }))
    ], { optional: true }),
    query(':leave', [
      style({ opacity: 1, transform: 'scale(1)' }),
      animate('400ms', style({ opacity: 0, transform: 'scale(2)' }))
    ], { optional: true }),
  ])
];

const logoutZoom = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%', maxWidth: '1920px' }), { optional: true }),
  group([
    query(':enter', [
      style({ opacity: 0, transform: 'scale(2)' }),
      animate('400ms', style({ opacity: 1, transform: 'scale(1)' }))
    ], { optional: true }),
    query(':leave', [
      style({ opacity: 1, transform: 'scale(1)' }),
      animate('400ms', style({ opacity: 0, transform: 'scale(0)' }))
    ], { optional: true }),
  ])
];


export const routeAnimation = trigger('routeAnimation', [
  transition('void => *', loginZoom),
  transition('* => auth', logoutZoom),
  transition('auth => overview', loginZoom),
  transition('companies => profile', toProfile),
  transition('companies => security', toProfile),
  transition('companies => password', toProfile),
  transition('companies => theme', toProfile),
  transition('overview => theme', toProfile),
  transition('overview => password', toProfile),
  transition('overview => security', toProfile),
  transition('overview => profile', toProfile),
  transition('company => profile', toProfile),
  transition('company => security', toProfile),
  transition('company => password', toProfile),
  transition('company => theme', toProfile),
  transition('profile => companies', fromProfile),
  transition('security => companies', fromProfile),
  transition('password => companies', fromProfile),
  transition('theme => companies', fromProfile),
  transition('theme => overview', fromProfile),
  transition('password => overview', fromProfile),
  transition('security => overview', fromProfile),
  transition('profile => overview', fromProfile),
  transition('companies => overview', enterLeft),
  transition('overview => companies', enterRight),
  transition('companies => company', enterRight),
  transition('company => companies', enterLeft),
  transition('infoslip => *', leaveSlip),
]);

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
    ]),
    FormsModule,
    NebularModule,
    EditorModule,
    MonacoEditorModule
  ],
  declarations: [
    LoadingScreenComponent,
    TimeAgoPipe,
    GhostCardsComponent,
    IconComponent,
    TinyEditorComponent,
    MonacoEditorComponent,
    FilterPipe,
    ClipboardComponent,
    CopyComponent,
    LocalDate,
    SecureKeysComponent,
    ActivityBarSharedComponent,
    SecurePipe,
    TruncatePipe,
    PaginationComponent
  ],
  exports: [
    LoadingScreenComponent, 
    TimeAgoPipe, 
    GhostCardsComponent, 
    IconComponent, 
    TinyEditorComponent, 
    MonacoEditorComponent, 
    FilterPipe, 
    ClipboardComponent, 
    CopyComponent, 
    LocalDate, 
    SecureKeysComponent, 
    ActivityBarSharedComponent, 
    SecurePipe,
    TruncatePipe,
    PaginationComponent
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
  ]
})
export class SharedModule { }
