<nb-layout>
  <nb-layout-header class="header" [ngClass]="headerClass$ | async" *ngIf="loggedIn$ | async" @headerAnimation fixed>
    <div class="mobile-info" *ngIf="innerWidth < 480">
      <div>Signed in as: <span>{{(user$ | async)?.userName}}</span></div>

      <div>
        <div>Session: {{minutesLeft$ | async}}m <span *ngIf="(minutesLeft$ |async) < 3">{{secondsLeft$ | async}}s</span></div>
        <button nbButton status="info" (click)="refresh()" size="tiny" *ngIf="showRefreshButton$ | async">
          Refresh</button>
      </div>

    </div>

    <ng-container *ngIf="innerWidth > 480">
      <div class="version">v{{version}}</div>

      <div class="user">Session Time Remaining:&nbsp;</div>
      <span>
        {{minutesLeft$ | async}}m</span>&nbsp;
      <span *ngIf="(minutesLeft$ |async) < 3">
        {{secondsLeft$ | async}}s</span>&nbsp;&nbsp;
      <button nbButton status="info" (click)="refresh()"     size="tiny" *ngIf="showRefreshButton$ | async">
        Refresh</button>
  
      <div class="user">Signed in as: <span>{{(user$ | async)?.userName}}</span></div>
      <div class="warning">Not optimized for mobile yet.</div>
    </ng-container>

  </nb-layout-header>

  <nb-layout-column [@routeAnimation]="prepareRoute(outlet)">
    <div *ngIf="expiresIn$ | async as expiresIn" class="expiring-dialog">
        <nb-card>
          <nb-card-header>
            <h2>Your session is about to expire</h2>
          </nb-card-header>
          <nb-card-body>
           <h1>{{expiresIn}}</h1>
          </nb-card-body>
          <nb-card-footer class="action-buttons">
            <button type="button" nbButton outline (click)="logout()">
              Sign out
            </button>
            <button
              nbButton
              status="primary"
              (click)="refresh()"
            >
              Refresh
            </button>
          </nb-card-footer>
        </nb-card>
    </div>
    <router-outlet #outlet="outlet"></router-outlet>
  </nb-layout-column>

  <nb-layout-footer *ngIf="loggedIn$ | async" @headerAnimation fixed class="footer">

  <div>
    <button nbButton size="small" status="secondary" (click)="sendFeedback(feedbackDialog)">Send Feedback</button>
    <button nbButton size="small" status="primary" (click)="login()">Switch to Old Admin</button>
  </div>

  
  <ng-template #feedbackDialog let-data let-ref="dialogRef">
    <nb-card class="feedback">
      <nb-card-header>We value your feedback. </nb-card-header>
      <nb-card-body>
        <input nbInput fullWidth type="text" placeholder="Subject" [ngModel]="feedbackSubject" (ngModelChange)="buildMailto($event, 'subject')">
        <textarea placeholder="Message" nbInput fullWidth [ngModel]="feedbackText" (ngModelChange)="buildMailto($event, 'message')"></textarea>
      </nb-card-body>
      <nb-card-footer>
        <div style="float: right;">
          <button nbButton status="danger" (click)="ref.close()">Cancel</button>
          <a nbButton status="primary" (click)="submitFeedback()">Submit</a>
        </div>
      </nb-card-footer>
    </nb-card>
  </ng-template>

  </nb-layout-footer>
</nb-layout>