<nb-flip-card [showToggleButton]="false" [flipped]="cardIsFlipped">
    <nb-card-front class="stage-card" [class.not-started]="!stage.Started">
        <nb-card class="card mt-0 shadow">
            <nb-card-header>
                <ng-container *ngTemplateOutlet="stageCardHeader"></ng-container>
            </nb-card-header>
            <nb-card-body>
                <div class="row">
                    <div class="col-3 col-md-3 justify-center">
                        <nb-icon pack="oakmoon" icon="database-alt"></nb-icon>
                    </div>
                    <div class="col-9 col-md-9">
                        <span>
                            {{ (selectedRun?.Instance?.ProgressCounters)[0]?.Counter }}
                            {{
                            selectedRun?.Instance.ProgressCounters[0].Counter >= 1
                            ? 'Recipient'
                            : 'Recipients'
                            }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 col-md-3 justify-center">
                        <nb-icon pack="oakmoon" icon="time-clock-circle-alt"></nb-icon>
                    </div>
                    <div class="col-9 col-md-9">
                        {{ stage.TimeInSeconds ? stage.TimeInSeconds : '0ms' }}<br />
                        {{ stage.ProcessingPerSecond }} /s
                    </div>
                </div>
            </nb-card-body>
            <nb-card-footer>
                <ng-container *ngTemplateOutlet="stageCardFooter"></ng-container>                
            </nb-card-footer>
        </nb-card>
    </nb-card-front>
    <nb-card-back class="stage-card" [class.not-started]="!stage.Started">
        <nb-card>
            <nb-card-header>
                <ng-container *ngTemplateOutlet="stageCardHeader"></ng-container>
            </nb-card-header>
            <nb-card-body>
                <div *ngIf="!selectedRun?.IsTrial && (selectedRun?.Instance?.Status === 3 || selectedRun?.Instance?.Status === 4)">
                    <label style="position:relative; top:-5px">Re-process options:</label>
                    <nb-select  #reprocessOption [(ngModel)]="reProcessOptionSelected" 
                        [compareWith]="compareWith"
                        [fullWidth]="false"
                        size="tiny">
                        <nb-option value="0" selected>Skip</nb-option>
                        <nb-option value="1">Failed and not enabled</nb-option>
                        <nb-option value="2">Entire Stage</nb-option>
                        <nb-option value="4">Failed Only</nb-option>
                    </nb-select>
                    <nb-icon (click)="reProcess()" icon="arrow-right-circle" pack="oakmoon" 
                        *ngIf="reProcessOptionSelected>0" style="cursor:pointer; float: right;"
                        nbTooltip="Click to start"></nb-icon>
    
                    <div *ngIf="stage.Name === 'Export'">
                        <br/>
                        <label>Control File Option:</label>
                        <nb-icon (click)="reCreateControlFile()" nbTooltip="Re-create" 
                            icon="redo" pack="oakmoon" style="cursor:pointer; float: right;"></nb-icon>
                        <span style="cursor:pointer; float: right;"> | </span>
                        <nb-icon (click)="downloadControlFile()" nbTooltip="Download" 
                            icon="common-file-download" pack="oakmoon" style="cursor:pointer; float: right;"></nb-icon>
                    </div>
                </div>
            </nb-card-body>
            <nb-card-footer>
                <ng-container *ngTemplateOutlet="stageCardFooter"></ng-container>                
            </nb-card-footer>
        </nb-card>
    </nb-card-back>
</nb-flip-card>

<ng-template #stageCardFooter>
    <ng-container *ngIf="stage.Started && !stage.Completed">Started: {{ stage.Started | timeago }}
    </ng-container>
    <ng-container *ngIf="stage.Completed">Completed {{ stage.Completed | timeago }}</ng-container>
    <ng-container *ngIf="!stage.Started && !stage.Completed">Not Started</ng-container>
</ng-template>

<ng-template #stageCardHeader>
    <div class="card-header">
        <span>{{ stage.Name }}</span>
        <nb-icon (click)="toggleCard()" style="float: right; cursor: pointer;" pack="oakmoon" icon="navigation-menu-horizontal" *ngIf="
            stage.Name === 'Send' ||
            stage.Name === 'PostProcess' ||
            stage.Name === 'Export'
          "></nb-icon>
    </div>
    <div class="progress-on-stage">
        <div [ngClass]="
            stage.Status === 4
              ? 'Failed'
              : stage.Status === 2
              ? 'Processing'
              : stage.Status === 1
              ? 'Scheduled'
              : 'Complete'
          "></div>
        <span class="pill" [ngClass]="
            stage.Status === 4
              ? 'Failed'
              : stage.Status === 2
              ? 'Processing'
              : stage.Status === 1
              ? 'Scheduled'
              : 'Complete'
          ">{{ stageStatus(stage) }}</span>
    </div>
</ng-template>

