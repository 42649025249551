import { Injectable } from '@angular/core';
import { IiabLocalStorageService } from '@InfoSlips/iiab-state';
import { IIABCacheKey } from '@InfoSlips/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '@InfoSlips/iiab-api';
import { OakmoonMain } from '@InfoSlips/models';

interface QuickActionModel {
  name: string;
  icon: string;
  pack: string;
  actions?: ActionsConfig[];
  enabled: boolean;
  wizard: {
    context: string;
    menuItem: string;
  }
}

interface ActionsConfig {
  name: string;
  useWizard: boolean;
  routerLink: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  allowedMenus: OakmoonMain[];
  quickActionsSubject = new BehaviorSubject<QuickActionModel[]>([]);
  quickActions$: Observable<QuickActionModel[]> = this.quickActionsSubject.asObservable();

  availableActions: QuickActionModel[];
  
  constructor(
    private localStorageService: IiabLocalStorageService,
    private userService: UserService
  ) {
    const quickActions = this.localStorageService.get<any[]>(IIABCacheKey.QuickActions, true);
    let enabledActions: QuickActionModel[] = quickActions;
    
    this.userService.allowedMainMenu$.subscribe(res => {
      if (res) {
        this.allowedMenus = res;
        this.availableActions = this.buildAvailableActions();
    
        if(!quickActions || !('pack' in quickActions[0])){
          enabledActions = this.availableActions.slice(0, 6).map(action => {
            action.enabled = true;
            return action;
          });
        }
    
        this.availableActions.map(action => {
          if(enabledActions.some(item => item.name === action.name)){
            action.enabled = true;
          }
        })
    
        this.setQuickActions(enabledActions);
      }
    })
  }

  setQuickActions(quickActions: QuickActionModel[]){
    this.quickActionsSubject.next(quickActions);
    this.localStorageService.set<any[]>(IIABCacheKey.QuickActions, quickActions, true);
  }

  private buildAvailableActions() {
    const avActions = [];

    if (this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "RUN"))) {
      avActions.push(
        {
          name: 'Run',
          icon: 'run',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'Setup',
            menuItem: 'Run'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "USERS"))) {
      avActions.push(
        {
          name: 'User',
          icon: 'users',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: false,
              routerLink: '/admin/users'
            },
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'CustomersAndUsers',
            menuItem: 'Users'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "CUSTOMERS"))) {
      avActions.push(
        {
          name: 'Customer',
          icon: 'users',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: false,
              routerLink: 'admin/customers'
            },
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'CustomersAndUsers',
            menuItem: 'Customers'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "RUN_TEMPLATE"))) {
      avActions.push(
        {
          name: 'Run Template',
          icon: 'run-template',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: false,
              routerLink: 'admin/runtemplates'
            },
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'Setup',
            menuItem: 'RunTemplate'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "TEMPLATE"))) {
      avActions.push(
        {
          name: 'Template',
          icon: 'template',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: false,
              routerLink: 'admin/templates'
            },
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'Setup',
            menuItem: 'Template'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "PRE_PROCESSOR"))) {
      avActions.push(
        {
          name: 'Pre-Processor',
          icon: 'pre-processor',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: false,
              routerLink: 'admin/preprocessor',
            },
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'Setup',
            menuItem: 'PreProcessor'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "SETUP" && main.items.some(sub => sub.id == "POST_PROCESSOR"))) {
      avActions.push(
        {
          name: 'Post-Processor',
          icon: 'post-processor',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'create',
              useWizard: false,
              routerLink: 'admin/postprocessor',
            },
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'Setup',
            menuItem: 'PostProcessor'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "RECIPIENTS"))) {
      avActions.push(
        {
          name: 'Recipients',
          icon: 'users',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'CustomersAndUsers',
            menuItem: 'Recipients'
          }
        }
      )
    }

    if (this.allowedMenus.some(main => main.id == "CUSTOMERS_AND_USERS" && main.items.some(sub => sub.id == "RUN_RECIPIENTS"))) {
      avActions.push(
        {
          name: 'Run Recipients',
          icon: 'users',
          pack: 'oakmoon-v2',
          actions: [
            {
              name: 'search',
              useWizard: true,
              routerLink: null
            }
          ],
          enabled: false,
          wizard: {
            context: 'CustomersAndUsers',
            menuItem: 'RunRecipients'
          }
        }
      )
    }

    return avActions;
  }
}
