
  <nb-card>
    <nb-card-header>
      <form class="p-10">
        <div class=" row form-block">
          <div class="form-group">
            <label>Enter Customer Name</label>
            <input type="text"
            placeholder="Enter Customer Name"
            name="customer"
            required
            nbInput
            ngModel
            fullWidth
            #customer="ngModel"
            [status]="customer.invalid ? 'danger' : 'basic'"
            (input)="onCustomerChange(customer)"
            [nbAutocomplete]="customerAuto"
          />
          <nb-autocomplete #customerAuto (selectedChange)="onCustomerChange(customer)">
            <nb-option
              *ngFor="let option of customerOptions$ | async"
              [value]="option.name"
            >
              {{ option.name }}
            </nb-option>
          </nb-autocomplete>
        </div>
          <div class="form-group">
            <label>Find Run Template</label>
            <input fullWidth autocomplete="off" #autoInput nbInput type="text" (input)="onChange()" fullWidth
              [nbAutocomplete]="auto" name="runTemplate" placeholder="Filter by Name or ID" [(ngModel)]="selectedRunTemplate" [ngModelOptions]="{standalone: true}"/>
            <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
              <nb-option *ngFor="let option of runTemplates$ | async" [value]="option?.name">
                {{ option?.name }}
              </nb-option>
            </nb-autocomplete>
          </div>
        </div>
      </form>
    </nb-card-header>
    <nb-card-body>
      <div class="scroll p-10 row">
        <ng-container *ngFor="let runTemplate of runTemplates$ | async">
          <div class="col-md-6">
            <cms-run-template-card [runTemplate]="runTemplate"></cms-run-template-card>
          </div>
        </ng-container>
      </div>
    </nb-card-body>

  </nb-card>