import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RunRecipientService, RunsService } from '@InfoSlips/iiab-api';
import { MonacoEditorConfig } from '../../monacoConfig';
import { RunModel } from '@InfoSlips/models';

@Component({
  selector: 'ifs-razor-template',
  templateUrl: './razor-template.component.html',
  styleUrls: ['./razor-template.component.scss']
})
export class RazorTemplateComponent implements OnInit, OnChanges {

  @Input() events: Observable<void>;
  @Input() selectedRun: RunModel;
  @Input() theme:string;
  @Input() selectedRecipientId:string

  @ViewChild('autoInput') input;
  
  eventsSubscription:any;
  razorFiles$;
  razorInput$;
  razorPreview$;
  selectedFile:string;
  options: any;
  filteredOptions$: Observable<string[]>;
  code: string;

  constructor(
    public runsService: RunsService,
    private runRecipientService: RunRecipientService,
    public monacoConfig: MonacoEditorConfig
  ) { }
  
  ngOnInit() {
    this.runRecipientService.getTemplateFiles(this.selectedRun.RunTemplate.Id);
    this.runRecipientService.razorInput$.subscribe(res => this.code = res);
    this.eventsSubscription = this.events.subscribe(() => this.transform());
    this.razorFiles$ = this.runRecipientService.razorFiles$;
    this.razorInput$ = this.runRecipientService.razorInput$;
    this.razorPreview$ = this.runRecipientService.razorPreview$;
    this.filteredOptions$ = of(this.options);
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.theme?.currentValue !== changes.theme?.previousValue){
      this.setEditor();
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));;
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  fileSelected(fileName){
    this.selectedFile = fileName;
    this.runRecipientService.getTemplateFile(this.selectedRun.RunTemplate.Id, fileName);
  }

  transform(){
      this.runRecipientService.previewRazor(this.selectedRecipientId, this.code);
  }

  setEditor(){
    this.monacoConfig.editorOptions = {theme: this.theme === 'light' ?  'vs' : 'vs-dark', language: 'razor'};
  }
}