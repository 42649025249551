import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { UserService } from '@InfoSlips/iiab-api';
import { Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { WizardService } from '../../services/wizard.service';

enum FilterTypes {
  nameOrId,
  isApproved,
  email,
  customer,
}

@Component({
  selector: 'ifs-wizard-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
  providers: [UserService]
})
export class UsersFormComponent implements OnInit, AfterViewInit {

  @ViewChild('autoInput') input;
  @ViewChild('customerDropdown') customerLookup;
  @Input() data: any;
  // @Input() form: string;
  // @Output() cancelForm: EventEmitter<boolean> = new EventEmitter();
  // @ViewChild('autoInput') input;
  selectedUser: string;
  selectedUserId: string;
  selectedCustomer: string;
  selectedCustomerId: string;
  selectedUserEmail: string;
  selectedUserIsApproved: boolean;

  findUserForm: FormGroup;
  loadingUsers$ = of([this.loadingUserObject()]);
  users$: Observable<any[]>;
  isLoading: boolean;
  filteredOptions$: Observable<any[]>;
  searchPhrase: string;
  filter = false;
  bulkActions = false;
  filterObject = {};

  // selectedUsers;
  constructor(
    private userService: UserService,
    private wizardService: WizardService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.users$ = of([this.loadingUserObject()]);

    this.userService.users.isLoading$.subscribe(loading => {
      this.isLoading = loading
      if (this.isLoading) 
        this.users$ = of([this.loadingUserObject()]);
      else 
        this.users$ = this.userService.users.Items$().pipe(exhaustMap((res) => of(res)));
    });

    this.findUserForm = this.fb.group({
      Company: [''],
      NameOrId: [''],
      IsApproved: [''],
      Email: ['']
    });
  }

  ngAfterViewInit() :void {
    this.userService.users.loadPagedItems(this.loadSearchHistory('Users'));
  }

  filterResults(value: any, type: FilterTypes){
    switch (type) {
      case 0:
        value.length === 24 ? this.filterObject['id'] =  {eq: value} : this.filterObject['userName'] = { like: value }
        break;
    
      case 1:
        this.filterObject['isApproved'] = {
          eq: value
        }
        if(value === "" || value === null || value === undefined){
          delete this.filterObject['isApproved'];
        }
        break;

      case 2:
        this.filterObject['email'] = {
          like: value
        }
        break;

      case 3:
        if(!value?.id)
          return;
        this.selectedCustomer = value.name;
        this.filterObject['customerFilter'] = {
          some: {
            eq: value.id
          }
        }
       break;
    } 
  }

  searchUsers() {
    this.userService.users.loadPagedItems(Object.keys(this.filterObject).length !== 0 ? this.filterObject : null);

    this.wizardService.addSearchHistory("Users", this.filterObject, this.filterObject['customerFilter'] != null ? {customerName : this.selectedCustomer} : null);
  }
  
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.searchUsers();
  }

  onSelectionChange($event) {
    this.users$.subscribe((res: any) => {
      res.find(item => {
        if(item.userName === this.input.nativeElement.value){
          this.selectedUserId = item.id;
          this.selectedUser = item.userName;
          this.users$ = of([item]);
       }
     })
   })
  }
  
  cancel(){
    this.wizardService.setState(0);
  }

  clearFilter(type: FilterTypes){
    this.wizardService.removeSearchHistory("Users");
    this.users$ = of([{ id: 'Loading..', name: 'Loading..'}]);
    switch (type) {
      case 0:
        this.selectedUser = '';
        delete this.filterObject['userName'];
        break;

      case 1:
        this.selectedUserIsApproved = null;
        delete this.filterObject['isApproved'];
        break;
    
      case 2:
        this.selectedUserEmail = '';
        delete this.filterObject['email'];
        break;
    }
    this.data.service[`${this.data.context.toLowerCase()}`].isLoading$.subscribe(loading => {
      if (!loading) this.data.service[`${this.data.context.toLowerCase()}`].loadPagedItems(Object.keys(this.filterObject).length !== 0 ? this.filterObject : null);
    });
  }
  
  hasKeys(obj: any): boolean {
    return obj != null && Object.keys(obj).length > 0;
  }

  private loadingUserObject() {
    return { id: 'Loading..', name: 'Loading..'};
  }

  private loadSearchHistory(context: string) {
    if (this.wizardService.wizardSearchHistory['Users']) {
      const searchHistory = this.wizardService.wizardSearchHistory;

      if (searchHistory[context].userName != null) this.selectedUser = searchHistory[context].userName.like;
      if (searchHistory[context].id != null) this.selectedUser = searchHistory[context].id.eq;
      
      if (searchHistory[context].selectedUserIsApproved != null) this.selectedUser = searchHistory[context].isApproved.eq;
      if (searchHistory[context].email != null) this.selectedUserEmail = searchHistory[context].email.like;

      if (searchHistory[context].customerFilter != null) {
        const customerReference = { id: searchHistory[context].customerFilter.some.eq, name: searchHistory[`${context}AdditionalOptions`].customerName };
        this.customerLookup.onItemSelected(customerReference);
      }

      this.filterObject = searchHistory[context];
      return this.filterObject;
    } else return null;
  }
}
