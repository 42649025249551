<form [formGroup]="trialForm" class="trial">
  <div class="row">
    <div class="col-md-6">
      <div class="input-section">
        <span class="mt-10">To</span>
        <input
          size="medium"
          nbinput
          [disabled]="selectedRun?.Status == 'Created'"
          placeholder="To"
          name="SendToUser"
          class="trial-recipient-input"
          [ngModel]="selectedRun?.TrialOptions.SendToUser"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="setEmailUsers($event)"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-section">
        <span class="mt-10">Recipients to include</span>
        <div class="flex">
          <div class="inputBorder m-5">
            <nb-select 
              [disabled]="selectedRun?.Status == 'Created'" 
              [(ngModel)]="selectedRecipientOption"
              [selected]="recipientOptions[selectedRun?.TrialOptions.RecipientSelection]"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="refreshTrialRecipients()"
              size="medium"
            >
              <nb-option *ngFor="let option of filteredTrialOptions; let i = index" value="{{ option }}">{{ option }}</nb-option>
            </nb-select>
            <ng-container *ngIf="selectedRecipientOption === 'Individual Recipient Search' || selectedRun?.TrialOptions.RecipientSelection === 4">
              <input
                #autoInput
                nbInput
                type="text"
                (input)="onChange()"
                placeholder="Enter value"
                fullWidth
                class="trial-recipient-input"
                [nbAutocomplete]="auto"
              />
  
              <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
                <nb-option
                  *ngFor="let option of selectedRunService.filteredRunRecipients$ | async" [value]="option">
                  {{ option.name }} ({{ option.externalId }})
                </nb-option>
              </nb-autocomplete>
            </ng-container>
  
            <ng-container *ngIf="selectedRecipientOption === 'Batch Recipients' || selectedRun?.TrialOptions.RecipientSelection === 6">
              <textarea nbInput type="text" fullWidth class="batch-recipient-input" [ngModel]="batchExternalIds()" (ngModelChange)="updateBatchRecipients($event)" [ngModelOptions]="{ standalone: true }"></textarea>
            </ng-container>
          </div>
          <nb-icon
            *ngIf="selectedRecipientOption != 'Individual Recipient Search' && selectedRun?.TrialOptions.RecipientSelection != 4 && selectedRecipientOption != 'Batch Recipients' && selectedRun?.TrialOptions.RecipientSelection != 6"
            class="action refresh cursor-pointer"
            pack="oakmoon"
            icon="refresh-users"
            (click)="refreshTrialRecipients()"
          ></nb-icon>
        </div>
      </div>
    </div>
  </div>
  <br style="clear: both" />
  
  <div class="action-buttons">
    <button [disabled]="selectedRun?.Status == 'Created'" nbButton class="btn mr-10" size="small" status="primary" (click)="send()">
      Send Trial
    </button>
  
    <button *ngIf="selectedRun?.ExportTemplate?.IsEnabled && (isRunCompiled$ | async)" [disabled]="selectedRun?.Status == 'Created'" nbButton class="btn mr-10" size="small" outline status="primary" (click)="downloadExportFiles()">
      Download all export files
    </button>
    
    <button nbButton class="btn mr-10" size="small" outline status="primary" [nbPopover]="advancedOptions" nbPopoverTrigger="click" nbPopoverPlacement="bottom" class="action settings">
      <nb-icon pack="oakmoon" icon="cog"></nb-icon>
    </button>
  </div>

  <ng-template #advancedOptions>
    <form [formGroup]="trialForm" class="trial advanced">
      <div class="container">
        <nb-checkbox status="basic" formControlName="SkipRefreshTemplateFolder"
          ><span>Skip updating of template folder?</span></nb-checkbox
        >
      </div>

      <div class="container">
        <nb-checkbox
          status="primary"
          [(ngModel)]="overrideBranch"
          [ngModelOptions]="{ standalone: true }"
          ><span>Override template branch?</span>
          </nb-checkbox
        >
      </div>

      <div class="container select-branch" *ngIf="overrideBranch">
        <span>Branch</span>
        <input
          style="color: black !important"
          class="input text-black"
          name="OverrideRunTemplateBranch"
          formControlName="OverrideRunTemplateBranch"
        />
      </div>
    </form>
  </ng-template>
</form>
