<ng-container *ngIf="!showClear">
    <label *ngIf="showLabel">Run Template</label>
    <input autocomplete="off" #runTemplateAutoInput nbInput type="text" 
        [disabled]="disabled"
        placeholder="Enter RunTemplate Name or Id" fullWidth 
        [nbAutocomplete]="runTemplateAuto" name="runTemplateCompany"
        [ngModel]="itemNameLike" 
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="itemNameLikeSubject.next($event)" />
</ng-container>

<ng-container *ngIf="showClear">
    <nb-form-field>
        <label *ngIf="showLabel">Run Template</label>
        <input autocomplete="off" #runTemplateAutoInput nbInput type="text" 
            [disabled]="disabled"
            placeholder="Enter RunTemplate Name or Id" fullWidth 
            [nbAutocomplete]="runTemplateAuto" name="runTemplateCompany"
            [ngModel]="itemNameLike" 
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="itemNameLikeSubject.next($event)" 
        />
        <button class="clear-btn" [ngClass]="!showLabel ? 'mt-0' : ''" nbSuffix nbButton ghost (click)="clear()">
            <nb-icon icon="close-outline" pack="eva"></nb-icon>
        </button>
    </nb-form-field>
</ng-container>

<nb-autocomplete #runTemplateAuto (selectedChange)="onItemSelected($event)" [handleDisplayFn]="displayItemName">
    <nb-option *ngFor="let option of items$ | async" [value]="option">
        {{ option.name }}
    </nb-option>
</nb-autocomplete>