
import { Component, OnInit, Input } from '@angular/core';
import { RunSummary } from '@InfoSlips/models';

@Component({
  selector: 'ifs-run-card',
  templateUrl: './run-card.component.html',
  styleUrls: ['./run-card.component.scss']
})
export class RunCardComponent implements OnInit {

  @Input() runDetail: RunSummary = null;
  @Input() selected: boolean = false;
  @Input() type = 'card';
  
  constructor() {

   }

  ngOnInit() {}
}