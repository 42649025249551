import { Component, Input, OnInit } from '@angular/core';
import { SelectedRunService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-send-live',
  templateUrl: './send-live.component.html',
  styleUrls: ['./send-live.component.scss']
})
export class SendLiveComponent implements OnInit {

  @Input() selectedRunService: SelectedRunService;

  constructor() { }

  ngOnInit(): void {
  }

}
