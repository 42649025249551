import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component,EventEmitter,Input,OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RunActionsService, SelectedRunService } from '@InfoSlips/iiab-api';
import { RunModel } from '@InfoSlips/models';
import { Observable } from 'rxjs';
import { RunStatusService } from '../../services/run-status.service';
import { NbDialogService } from '@nebular/theme';

interface RunFile{
  fileName: string,
  file: File,
  context: string
}
@Component({
  selector: 'ifs-run-upload-form',
  templateUrl: './run-upload-form.component.html',
  styleUrls: ['./run-upload-form.component.scss']
})
export class RunUploadFormComponent implements OnInit {
  
  @Input() selectedRunService: SelectedRunService;
  @Output() public onProcessStarted= new EventEmitter();
  
  selectedRun: RunModel;
  runUploadForm: FormGroup;
  submitted = false;
  payLoad = '';
  filesAdded = false;
  processing = false;
  processStage = "";
  hasAllRequiredFiles = false;
  public progress: number;
  public message: string;

  fileList: RunFile[] = [];
  uploadList: RunFile[] = [];
  runFiles;
  additionalAttachments;
  recipientAttachments
  runId:string;
  requiredFiles;
  mandatoryFiles = {};

  dialogRef;

  constructor(
    private fb: FormBuilder, 
    private runsActionsService: RunActionsService,
    private displayStatus: RunStatusService,
    private dialogService: NbDialogService,
  ) {}

  ngOnInit() {
    this.requiredFiles = [];
    this.runUploadForm = this.fb.group({
      file1: ['', Validators.required],
    });

    this.selectedRunService.selectedRun$.subscribe((run: RunModel) => {
      this.selectedRun = run;
      
      this.runFiles =  run.RunFiles.map(file => {
        return {...file, ...run.PreProcessor.ClassData.RequiredFiles.find(({FileName}) => FileName === file.FileName)}
      });
      
      this.additionalAttachments = run.Attachments ? run.Attachments : null;
      this.runId = run.Id;
      this.recipientAttachments = run.RunRecipientSpecificStaticAttachmentsFile ? run.RunRecipientSpecificStaticAttachmentsFile : null;
      this.requiredFiles = run.PreProcessor?.ClassData?.RequiredFiles ?? []; 
    });

    this.selectedRunService.runStatus$.subscribe(runStatus => this.hasAllRequiredFiles = runStatus.hasAllRequiredFiles);
    this.selectedRunService.runProgressCounters$.subscribe(counters => {
      counters[0]?.instance?.progressCounters ? this.processStage = counters[0].instance.progressCounters.find(function(element) { return element.key === "PREPARING"})?.value?.status : "";
    })
  }


  onSubmit() {
    this.submitted = true;
    const form = this.runUploadForm;
    if (form.invalid) {
      return;
    }
  }

  async uploadFiles() {
    let uploadedFiles = 0;

    this.uploadList.reduce(async (item: any, file: any) => {
      await item;
      await this.uploadFile(file);

      uploadedFiles++;
      this.filesAdded = uploadedFiles !== this.fileList.length;
    }, undefined);
  }

  async uploadFile(file){
    return new Promise(resolve => {
    
      this.runsActionsService.uploadFile(this.runId, file.file, file.context, file.fileName).subscribe(event => {

        if (event.type === HttpEventType.UploadProgress) {      
            const progress = Math.round(100 * event.loaded / event.total)

            if(file.context === "attachment"){
              this.additionalAttachments.find(item => item.FileName ===  file.fileName).progress = progress;
              this.additionalAttachments.find(item => item.FileName ===  file.fileName).message = `${progress}%`;
            }else if(file.context === "recipientattachment"){
              this.recipientAttachments.find(item => item.FileName ===  file.fileName).progress = progress;
              this.recipientAttachments.find(item => item.FileName ===  file.fileName).message = `${progress}%`;
            }
            else {
              this.runFiles.find(item => item.FileName ===  file.fileName).progress = progress;
              this.runFiles.find(item => item.FileName ===  file.fileName).message = `${progress}%`;
            }
        } else if (event instanceof HttpResponse) {

          if(file.context === "attachment"){
            this.additionalAttachments.find(item => item.FileName ===  file.fileName).message = 'Uploaded!';
          }else if(file.context === "recipientattachment"){
            this.recipientAttachments.find(item => item.FileName ===  file.fileName).message = 'Uploaded!';
          }
          else {
            this.runFiles.find(item => item.FileName ===  file.fileName).message = 'Uploaded!';
            this.selectedRun.RunFiles.find(item => item.FileName === file.fileName).Size = file.file.size;
          }

          this.uploadList = this.uploadList.filter(uploadedFile => uploadedFile != file);

          resolve(this.hasAllRequiredFiles = this.selectedRunService.determineRequiredFiles(this.selectedRun));
        }
      })
    });
  }

  processFiles(){
    this.processing = true;
    this.runsActionsService.processData(this.selectedRun).subscribe(res => {
      this.processing = false;
      this.displayStatus.displayStatusSubject.next(true);
    });
  }


  onDrop(event, fileName, context) {
    const dt = event.dataTransfer;
    const dtf = dt.files[0];
    const file = {
      fileName: fileName ? fileName : dtf.name,
      file: dtf,
      context: context
    }
    event.preventDefault();
    this.fileList.push(file);
    this.uploadList.push(file);

    if(context === 'required'){
      this.runFiles.find(file => file.FileName === fileName).Size = dtf.size;
    }
    else if(context === 'attachment'){
      this.additionalAttachments.push({
        FileName: file.fileName,
        Size: file.file.size,
        Queued: true
      });
    }
    else if(context === 'recipientattachment'){
      this.recipientAttachments.push({
        FileName: file.fileName,
        Size: file.file.size,
        Queued: true
      });
    }

    this.filesAdded = true
  }

  fileChange(event, fileName?, context?) {
    const dt = event.target;
    const dtf = dt.files[0];

    const file = {
      fileName: fileName ? fileName : dtf.name,
      file: dtf,
      context: context
    }
    event.preventDefault();
    this.fileList.push(file);
    this.uploadList.push(file);

    if(context === 'required'){
      this.runFiles.find(file => file.FileName === fileName).Size = dtf.size;
    }else if(context === 'attachment'){
      this.additionalAttachments.push({
        FileName: file.fileName,
        Size: file.file.size,
        Queued: true
      });
    }else if(context === 'recipientattachment'){
      this.recipientAttachments.push({
        FileName: file.fileName,
        Size: file.file.size,
        Queued: true
      });
    }
    this.filesAdded = true
  }

  remove(fileName, context){
    this.fileList = this.fileList.filter(file => file.fileName !== fileName);

    if(context === 'required'){
      this.runFiles.find(file => file.FileName === fileName).Size = 0;
      this.selectedRun.RunFiles.find(item => item.FileName === fileName).Size = 0;
    }
    else if(context === 'attachment'){
      this.additionalAttachments.find(file => file.FileName === fileName).Size = 0;
    }
    else if(context === 'recipientattachment'){
      this.recipientAttachments.find(file => file.FileName === fileName).Size = 0;
    }
    
    this.runsActionsService.removeFile(this.runId, fileName, context).subscribe();
    this.hasAllRequiredFiles = this.selectedRunService.determineRequiredFiles(this.selectedRun);
  }

  cancelFileUpload(fileName, context){
    if(context === 'required'){
      this.runFiles.find(file => file.FileName === fileName).Size = 0;
    }
    else if(context === 'attachment'){
      this.additionalAttachments.find(file => file.FileName === fileName).Size = 0;
    }
    else if(context === 'recipientattachment'){
      this.recipientAttachments.find(file => file.FileName === fileName).Size = 0;
    }

    this.uploadList = this.uploadList.filter(file => file.fileName != fileName);
    this.fileList = this.fileList.filter(file => file.fileName !== fileName);
    this.filesAdded = this.uploadList.length > 0;
  }

  onDragOver(event) {
      event.stopPropagation();
      event.preventDefault();
  }

  downloadFile(file){
    this.runsActionsService.downloadRunFile(this.runId, file);
  }

  openConfirmationDialog(ref, fileName: string){
    this.dialogRef = this.dialogService.open(ref, { context: fileName });
  }
}