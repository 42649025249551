<!-- <ifs-loading-screen *ngIf="!selectedDomain$"></ifs-loading-screen> -->

<div class="general-page-container">
    <div class="general-page-header">
      <div class="d-flex">
        <div class="flex-1">
          <h4 class="heading">
            <nb-icon pack="oakmoon" icon="building-1"></nb-icon>{{ !domainId ? ' Create' : ' Update' }} Domain: <b>{{currentDomain?.Domain}}</b></h4>
        </div>
        <div class="text-right">
          <button size="medium" outline nbButton routerLink="/">
            Cancel
          </button>
          <button class="save" *ngIf="domainId" size="medium" nbButton status="primary" (click)="updateDomain()">
            Save
          </button>
          <button class="save" *ngIf="!domainId" size="medium" nbButton status="primary" (click)="createDomain()">
            Create
          </button>
        </div>
    </div>
  </div>
</div>

<nb-card>
  <nb-card-body>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="input-box">
          <label>
            <p>Domain</p>
          </label>
          <input type="text" nbInput fullWidth [(ngModel)]="currentDomain.Domain">

          <label>
            <p>Archive display name</p>
          </label>
          <nb-checkbox [(ngModel)]="currentDomain.AllowAllOnOnDomain">Allow for all on Domain</nb-checkbox>
          <br />

          <div *ngFor="let address of currentDomain.EmailAddress; let i = index; trackBy:trackByIdx">
            <label>
              <p>Email {{ i+1 }}</p>
            </label>
            <input type="text" nbInput class="email-addresses" [(ngModel)]="currentDomain.EmailAddress[i]">
            <nb-icon class="pull-right" pack="oakmoon" icon="close" (click)="removeEmailAddress(i)"></nb-icon>
          </div>

          <br />
          <button nbButton status="primary" (click)="addEmailAddress()">Add Email Address</button>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </nb-card-body>
</nb-card>