import { Component } from '@angular/core';
import { CmsEntityModel, CmsEntitySummary } from '@InfoSlips/models';
import { BehaviorSubject } from 'rxjs';
import { SelectedEntityService } from '../../services/selected-entity.service';

@Component({
  selector: 'cms-entity-revisions',
  templateUrl: './cms-entity-revisions.component.html',
  styleUrls: ['./cms-entity-revisions.component.scss']
})
export class CmsEntityRevisionsComponent {

  revisions:CmsEntitySummary[]=[];
  selectedEntity:CmsEntityModel;

  constructor(
    private selectedEntityService: SelectedEntityService
  ) { 
    this.selectedEntityService.selectedEntity$.subscribe(entity=>{
      this.selectedEntity=entity;      
      this.selectedEntityService.loadRevisions(entity.PublicId);
    });
    this.selectedEntityService.revisions$.subscribe(revisions=>
      {
        if(revisions==null)
          return;
                 
        this.revisions=revisions;
      }
      );
  }

  onScroll(){
    this.selectedEntityService.onScrollRevisions();
  }
}
