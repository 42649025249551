
export interface EmailTemplateModel {
    Bcc: string;
    Cc: string;
    From: string;
    ReplyTo: string;
    Subject: string;
    Body: string;
    TrackReadEmail: boolean;
    MaximumAttachmentsPerEmail: number;
    EmailCategoryHeader: string;
    AttachmentFilename: string;
    IgnoredAttachmentOutputContentCodes: number[];
    ExcludeAttachmentRegEx: string[];
    FromCondition: string;
    ReplyToCondition: string;
    IsEnabled: boolean;
}
