
<div class="row no-margin" *ngIf="!smsEnabled">
  <div class="col-md-12 form-group">
  <nb-checkbox (checkedChange)="toggleSms($event)" name="smsEnabled" [ngModel]="smsEnabled" status="basic">Send Sms to recipients?
  </nb-checkbox>
  </div>
</div>
<ng-container *ngIf="smsEnabled">
    <div class="row no-margin full-height">
      <div class="col-md-8 no-padding" [style]="theme === 'light' ? 'background-color: white; color: black' : 'background-color: #1e1e1e; color: white' ">
          <nb-card>
              <nb-card-body>
                <div class="row no-margin full-height">
                  <div class="col-md-4 general-side-nav">

                    <nb-checkbox (checkedChange)="toggleSms($event)" name="smsEnabled" [ngModel]="smsEnabled" status="basic">Send Sms to recipients?
                    </nb-checkbox>
                    <br/>
                    <p style="color: red"><i>Please note that the counts below DOES NOT include placeholders</i></p>
                    <p>Character Count: {{ selectedRun.SmsTemplate.CharacterCount }} <nb-icon pack="eva" icon="info" status="info" nbTooltip="This count is for strictly static text." nbTooltipStatus="info"></nb-icon></p>
                    <p>SMS Count: {{ selectedRun.SmsTemplate.SmsCount }}</p>
                    <hr/>
                    <br/>
                    <p style="color: red"><i>Transformed data count (please note this varies per recipient).</i></p>
                    <p>Character Count: {{ smsPreview.CharacterCount }} <nb-icon pack="eva" icon="info" status="info" nbTooltip="This count is calculated based on transformed text, InfoSlipUrl is the equivalent of 34 characters when transformed. Live SMS's include a tinyurl which is shorter." nbTooltipStatus="info"></nb-icon></p>
                    <p>SMS Count: {{ smsPreview.SmsCount }}</p>
                  </div>
                  <div class="col-md-8 no-padding" *ngIf="smsEnabled">
                      <ngx-monaco-editor class="monaco-editor" (ngModelChange)="onSMSChange($event)" [options]="monacoConfig.editorOptions" [(ngModel)]="smsText"></ngx-monaco-editor>
                  </div>
                </div>
              </nb-card-body>
          </nb-card>
      </div>
      <div class="col-md-4 no-padding">
          <nb-card class="full-height">
              <nb-card-body>
                  <!-- <textarea class="output" value="{{ razorPreview$ | async }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"></textarea> -->
                  <textarea class="full-height" name="smsPreview" [ngModel]="smsPreview.Body" nbInput fullWidth placeholder="Sms Output"></textarea>
              </nb-card-body>
          </nb-card>
      </div>
  </div>
</ng-container>
