/* eslint-disable max-len */
import { MonacoEditorConfig } from './../../../../../dashboard/src/lib/monacoConfig';
import { CustomerService, MiscService, RunsService, RunTemplateService, TemplateService, Miscellaneous, BookmarkService } from '@InfoSlips/iiab-api';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ElementRef
} from '@angular/core';
import { Bookmark, IiabIdName, RunModel, RunTemplateModel, TemplateModel, OpenRunSchedule, IiabBilling, CmsEntity, CustomerModel, UpdatedKeyModel, CmsEntityReference } from '@InfoSlips/models';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NbDialogService } from '@nebular/theme';
import { exhaustMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLookupComponent, RunTemplateLookupComponent } from '@InfoSlips/controls';
import { CmsService } from 'libs/cms/src/lib/services/cms.service';
import { AuthService } from '@InfoSlips/iiab-auth';

export interface DialogContext {
  context: {
    title: string;
    body: string;
    button: string;
    routeEdit: string;
    runTemplate: any;
  };
}

@Component({
  selector: 'ifs-admin-templates-crud',
  templateUrl: './templates-crud.component.html',
  styleUrls: ['./templates-crud.component.scss'],
})
export class TemplatesCrudComponent implements OnInit, AfterViewInit {
  @Input() template: RunTemplateModel;
  @Input() isRunTemplate: boolean;
  @Input() isNew = false;
  @ViewChildren(RunTemplateLookupComponent) runTemplateLookups: QueryList<RunTemplateLookupComponent>;
  @ViewChildren(CustomerLookupComponent) customerLookups: QueryList<CustomerLookupComponent>;

  tinyEnabled = false;
  isSmsEnabled = false;
  isPreProcEnabled = false;
  isOpenRunEnabled = false;
  IsProcessingContainerEnabled = false;
  @Input() theme?: string = 'light';

  customerLookup: CustomerLookupComponent;

  docTypes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('DocumentTypes');
  dataRetentionPeriods$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('DataRetentionPeriodCodes');
  sourceDataRetentionPeriods$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('SourceDataRetentionPeriodCodes');
  pushNotificationProviders$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('PushNotificationSenderType');
  smsSenderTypes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('SmsSenderType');
  runReportLocations$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('AdditionalRunReportLocation');
  exportTypeCodes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('ExportTypeCodes');
  templateRepositoryType$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('TemplateRepositoryType', this.filterGitServiceProvider);
  openRunSchedule$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('OpenRunSchedule');
  otpSenderTypes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('OtpSenderType');

  customers = [];
  selectedCustomer: any = undefined;
  retainedCustomer: any = undefined;
  templates$: Observable<IiabIdName[]>;
  runTemplateCount: number;
  linkedItems: IiabIdName[];

  emailSettings: any;
  runDetails: RunModel;
  isConsolidated: boolean;
  useDifferentFolder = false;
  sourceTemplate$: Observable<TemplateModel>;
  sourceTemplate: TemplateModel;
  isCustomerLocked = false;
  OpenRunSchedule = OpenRunSchedule;
  fetchingTemplateFiles = false;

  recipientOptions = [
    {desc: '1 Random Recipient', value: 0},
    {desc: '5 Random Recipients', value: 1},
    {desc: '10 Random Recipients', value: 2},
    {desc: '30 Random Recipients', value: 3},
    {desc: 'Batch Recipients', value: 6},
  ];

  customerName: string;
  isStar$: Observable<Bookmark> = this.bookmarkService.isStar$;
  filteredLinkedEntities: CmsEntityReference[] = [];
  cmsDataModelEntities: CmsEntityReference[] = [];

  menuItem = 'Details';

  options = [];
  searchValue = '';
  maskingValue = '**';
  maskingCount = 2;
  searchDataCmsEntity: CmsEntity;

  constructor(
    private runTemplateService: RunTemplateService,
    public monacoConfig: MonacoEditorConfig,
    private templateService: TemplateService,
    public customerService: CustomerService,
    private toastr: ToastrService,
    private dialogService: NbDialogService,
    private runsService: RunsService,
    private miscService: MiscService,
    private bookmarkService: BookmarkService,
    private route: ActivatedRoute,
    private router: Router,
    private cmsService: CmsService,
    public authService: AuthService
  ) {
  }

  @ViewChild('infoDialog') private infoDialog: TemplateRef<any>;
  @ViewChild('stepper') private stepper: any;
  @ViewChild('autoInput') input;
  @ViewChild('nextStep') private nextStepDialogRef: TemplateRef<any>;
  @ViewChild('folderInput', { read: ElementRef }) folderInput: ElementRef<HTMLInputElement>;
  dialogRef;

  ngOnInit(): void {
    this.options = [
      {
        title: 'Details',
      },
      {
        title: 'Email Template',
        hasToggle: true,
        model: 'EmailTemplate',
        isRootLevel: false
      },
      {
        title: 'SMS Template',
        hasToggle: true,
        model: 'SmsTemplate',
        isRootLevel: false
      },
      {
        title: 'Export Template',
        hasToggle: true,
        model: 'ExportTemplate',
        isRootLevel: false
      },
      {
        title: `${this.isRunTemplate ? 'Run ' : ''}Template Source`,
      }
    ]

    setTimeout(() => {
      if (this.template?.HasExternalProcessing) {
        this.isPreProcEnabled = false;
      } else {
        this.isPreProcEnabled = true;
      }

      if (this.template?.CmsEntities) {
        this.filteredLinkedEntities = this.cmsService.getCmsEntityReferences(this.template.CmsEntities);
      }
  
      if (this.isRunTemplate && this.isNew) {      
        this.template.DocumentType = null;
      }
    }, 3000);

    this.templateService.templateFilesSubject.next(null);
  }


  ngAfterViewInit(): void {
    if (this.isRunTemplate) {
      
      if (!this.isNew)
        this.runTemplateService.selectedRunTemplate$.subscribe(rt => {
          if (rt)
          {
            Promise.resolve().then(() => {
              this.template = rt;
              this.isConsolidated = rt === null || rt.ConsolidatedCountRazor === null ? false : true;
              this.isOpenRunEnabled = rt === null || rt.OpenRunSchedule === null || rt.OpenRunSchedule == 0 ? false : true;
              this.IsProcessingContainerEnabled = rt?.ProcessingContainer && rt.ProcessingContainer?.IsEnabled ? true : false;
              
              if (rt.Customer ) {
                const customerReference = { id: rt.Customer.Id, name: rt.Customer.Name };
                this.selectedCustomer = customerReference;
                this.customerLookups.forEach(x => x.onItemSelected(customerReference));
                this.isCustomerLocked = true;
              }
              
              this.sourceTemplate$ = this.templateService.getBaseTemplate(rt?.Template?.Id);
              this.sourceTemplate$.subscribe(res => {
                this.sourceTemplate = res;
              });
            });
          }
        });

      const runTemplateOptions = [{
          title: 'CMS',
          hasToggle: true,
          isRootLevel: true
        },
        {
          title: 'Pre-Processor',
          hasToggle: true,
          model: null,
          isRootLevel: true
        },
        {
          title: 'Post-Processor',
        },
        {
          title: 'Run Search Data',
        }
      ];

      if (this.isNew) {
        this.templates$ = this.templateService.templates.Items$().pipe(
          exhaustMap((res) => of(res))
        );
      } else {
        runTemplateOptions.push({
          title: 'Preview Template',
        });
      }

      runTemplateOptions.push({
        title: 'Signatures',
        hasToggle: true,
        model: 'Signatures',
        isRootLevel: false
      });

      runTemplateOptions.forEach(option => this.options.push(option));
    }
    else {
      this.options.push({
        title: 'Run Templates',
      })
      this.getRunTemplateCount(this.route.snapshot.paramMap.get('id'));

      this.templateService.selectedTemplate$.subscribe(template => {
        if (template?.Customer ) {
          const customerReference = { id: template.Customer.Id, name: template.Customer.Name };
          this.selectedCustomer = customerReference;
          this.customerLookups.forEach(x => x.onItemSelected(customerReference));
          this.isCustomerLocked = true;
        }
      })
    }

    this.customerLookups.changes.subscribe(x => {
      if (!x.first) {
        return
      }
      this.customerLookup = x.first;

      if (!this.template.Customer)
        return;

      x.first.onItemSelected({
        id: this.template.Customer.Id,
        name: this.template.Customer.Name
      })

      this.retainedCustomer = {
        id: this.template.Customer.Id,
        name: this.template.Customer.Name
      };
    })
  }

  async save() {
    if (this.isRunTemplate) {

      const RunTemplate = { ...this.template };

      //todo: Remove to implement Signatures on RunTemplates
      RunTemplate.Signatures = null;
      //todo: Remove to implement Signatures on RunTemplates

      if (this.template.SearchDataCmsEntity) {
        RunTemplate.SearchDataCmsEntity = await this.cmsService.getCmsEntity(this.searchDataCmsEntity?.id != null ? this.searchDataCmsEntity.id : this.template.SearchDataCmsEntity.Id);
      }

      if (!this.validRunTemplate(RunTemplate)) return;

      this.runTemplateService.updateRunTemplate(RunTemplate);
    } else {
      this.templateService.updateTemplate(this.template);
    }
  }

  async create() {
    let context: DialogContext;

    if (this.isRunTemplate) {

      const RunTemplate = { ...this.template };

      //todo: Remove to implement Signatures on RunTemplates
      RunTemplate.Signatures = null;
      //todo: Remove to implement Signatures on RunTemplates

      if (this.template.SearchDataCmsEntity) {
        RunTemplate.SearchDataCmsEntity = await this.cmsService.getCmsEntity(this.searchDataCmsEntity.id);
      }

      if (this.template.TemplateSource.Branch != "") {
        this.template.TemplateBranch = this.template.TemplateSource.Branch
      }

      if (!this.validRunTemplate(RunTemplate)) return;

      context = await this.runTemplateService.createRunTemplate<DialogContext>(RunTemplate);
    } else {
      context = await this.templateService.createTemplate<DialogContext>(this.template);
    }

    this.dialogRef = this.dialogService.open(this.nextStepDialogRef, context);
  }

  clearTemplateCache() {
    this.runTemplateService.clearTemplateCache(this.template.Id);
  }

  buildEmailArray(emails) {
    if (emails.includes(';')) {
      this.toastr.info('Email Recipients must be comma seperated', 'Please note:');
    }
    this.template.NotificationEmailRecipients = emails.split(',');
  }

  open(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog);
  }

  async createCustomer() {
    const customer: CustomerModel = await this.customerService.createBaseCustomer(this.customerName);

    this.template.Customer.Id = customer.Id;

    this.customers.push({
      name: customer.Name,
      id: customer.Id,
      __typename: 'Customer'
    });

    setTimeout(() => {
      this.dialogRef.close();
    }, 1000)
  }

  preProcEnabled(e: boolean) {
    this.isPreProcEnabled = e;
    this.template.HasExternalProcessing = !e;

    if (this.template.HasExternalProcessing) {
      this.template.PreProcessor = null;
    }
  }

  createRun(runTemplate: RunTemplateModel) {
    this.dialogRef.close();
    this.runsService.createBaseRun(runTemplate);
  }

  modify(routerLink: string) {
    this.dialogRef.close();
    this.router.navigateByUrl(routerLink);
  }

  onSelectionChange(e) {
    this.input.nativeElement.value = e.name;
    this.templateService.getBaseTemplate(e.id).subscribe((template: any) => {
      let additionalOptions = {
        TrialOptions: { IsAutoTrialEnabled: false },
        UseTemplate: true,
        Template: {
          Name: template.Name,
          Id: template.Id
        }
      }
      const allowedActions = {
        AllowedActions: {
          DownloadIfs: true,
          DownloadPdf: true,
          Print: true
        }
      }
      !template.AllowedActions ? additionalOptions = Object.assign({}, additionalOptions, allowedActions) : undefined;
      this.template = Object.assign({}, this.template, template, additionalOptions);
      template.Customer.Id ? this.isCustomerLocked = true : this.isCustomerLocked = false;
      if (template.Customer.Id) {
        this.customerLookup.setSelectedItem({ id: template.Customer.Id, name: template.Customer.Name });
        this.customerLookup.disabled = true;
      }
    })
  }

  onExistingTemplateChange() {
    const wordSearch = this.input.nativeElement.value;

    this.templates$ = of([{ id: 'Loading..', name: 'Loading..' }]);

    const FilterObject = wordSearch.length === 24 ? { id: { eq: this.input.nativeElement.value } } : { name: { like: this.input.nativeElement.value } }

    setTimeout(() => {
      if (wordSearch == this.input.nativeElement.value) {
        this.templateService.templates.loadPagedItems(this.input.nativeElement.value ? FilterObject : null);
        this.templates$ = this.templateService.templates.Items$().pipe(exhaustMap((res) => of(res)));
      }
    }, 600);
  }

  resetWizard() {
    console.log("Reset Clicked");

  }

  enableOpenRun(isEnabled: boolean, openRunSchedule: OpenRunSchedule) {
    if (isEnabled) {
      this.template.OpenRunSchedule = openRunSchedule;
    } else {
      this.template.OpenRunSchedule = 0;
    }
  }

  scheduleOpenRun(e: OpenRunSchedule) {
    this.template.OpenRunSchedule === 0 ? this.isOpenRunEnabled = false : this.isOpenRunEnabled = true;
    this.enableOpenRun(this.isOpenRunEnabled, e);
  }

  favoriteItem(name: string, route: string) {

    if (this.isNew) return;
    const bookmark: Bookmark = {
      name: name,
      context: this.isRunTemplate ? 'Run Template' : 'Template',
      icon: this.isRunTemplate ? 'run-template' : 'template',
      isStar: false,
      route: route
    }
    this.bookmarkService.favorite(bookmark);
  }

  setMenuItem(item, index) {
    this.menuItem = item;
    this.changeStep(index);
  }

  onCustomerSelected(event: IiabBilling): void {
    if (!event?.id) {
      return
    }

    event === undefined ? event = null : undefined;

    this.selectedCustomer = event;
    this.template.Customer = {
      Id: event.id,
      Name: event.name,
      BillingGroup: event.billingGroup
    }
  }

  resetCustomerSelection(): void {
    if (this.selectedCustomer) {
      this.customerLookups.forEach(x => x.onItemSelected(this.retainedCustomer));
      this.isCustomerLocked = true;
    }
  }

  clearSearch() {
    this.searchValue = '';
    this.filteredLinkedEntities = this.cmsService.getCmsEntityReferences(this.template.CmsEntities);
  }
  filterLinkedEntities() {
    const references = this.cmsService.getCmsEntityReferences(this.template.CmsEntities);
    this.filteredLinkedEntities = references.filter(item => {
      return item.name.includes(this.searchValue) || item.display?.includes(this.searchValue);
    });
  }

  updateExportTemplate(secureKey: UpdatedKeyModel) {
    this.template.ExportTemplate.Password = secureKey.value;
  }

  reset() {
    window.location.reload();
  }

  setMaxConcurrentRuns(e) {
    if (e === null) return null;

    if (e <= 1) {
      this.toastr.warning('Max Concurrent Runs cannot be less than 1.')
      return 1;
    } else {
      return e
    }
  }

  handleDialogRouting(route: string) {
    this.router.navigateByUrl(route);
    this.dialogRef.close();
  }

  handleSourceProviderChange() {

    this.template.TemplateSource.Type = this.getTemplateSourceClassType(this.template.TemplateSource.TemplateRepositoryType);

    if (this.template.TemplateSource.TemplateRepositoryType === 3)
      // eslint-disable-next-line no-prototype-builtins
      if (this.template.TemplateSource.hasOwnProperty('Group') === false)
        this.template.TemplateSource["Group"] = "";
  }
  
  async testTemplateSource() {

    this.fetchingTemplateFiles = true;
    const fileList = this.templateService.templateFilesSubject.getValue();
    
    if (!fileList) {
      try {
        await this.templateService.getTemplateFiles(this.template.Id).then(res => {
          this.fetchingTemplateFiles = false;
          if (res) this.toastr.success('Template Source Files Located');
        });
      } catch (error) {
        this.fetchingTemplateFiles = false;
      }
    } else {
      this.fetchingTemplateFiles = false;
      this.toastr.success('Template Source Files Located');
    }
  }

  toggleProcessingContainer() {
    if (this.template.ProcessingContainer == null) {
      this.template.ProcessingContainer = {
        "ContainerSize": null,
        "ContainerMessageHandler": null,
        "IsEnabled": true
      }
    }
    else {
      this.template.ProcessingContainer = null
    }
  }

  setAutoTrialAddress($event){
    this.template.TrialOptions.SendToUser = $event.split(/,|;/);;
  }

  getAutoRecipientValue(option: number){
    return this.recipientOptions.find(obj => obj.value === option).desc;
  }

  onRecipientlistChange($event){
    this.template.TrialOptions.RecipientList = $event.split(/,|;/);
  }

  shouldDisplayTooltip(): boolean {
    if (this.folderInput) {
      const element = this.folderInput.nativeElement;
      return element.scrollWidth > element.clientWidth;
    }
  }

  goToRuns() {
    const newFilter = {
      "runTemplate" : {
        "id": this.template.Id,
        "name": this.template.Name
      }
    }
    this.runsService.navigateHomeAndFilterRuns(newFilter);
  }

  //#region private

  private validRunTemplate(RunTemplate: RunTemplateModel): boolean {
    if (RunTemplate?.ProcessingContainer?.IsEnabled == true && 
      (RunTemplate?.ProcessingContainer?.ContainerSize == null || RunTemplate?.ProcessingContainer?.ContainerMessageHandler == null)) {
        this.toastr.warning('Incomplete Processing Container details', 'Please note:');
        return false;
      }

    if (RunTemplate?.Customer == null || RunTemplate?.Customer?.Id == null || RunTemplate?.Customer?.Id?.length == 0) {
      this.toastr.warning('Customer cannot be empty', 'Please note:');
      return false;
    }

    if (RunTemplate?.DocumentType == null){
      this.toastr.warning('Document Type cannot be empty', 'Please note:');
      return false;
    }

    if (!this.isConsolidated) RunTemplate.ConsolidatedCountRazor = null;

    return true;
  }

  private changeStep(index) {
    if (index === 1) {
      this.tinyEnabled = true;
    } else if (index === 2) {
      this.isSmsEnabled = true;
    } else {
      this.tinyEnabled = false;
    }
  }

  private stepValidation(currentStep) {
    let isValid = false;
    let errorMessage;

    switch (currentStep) {
      case 'General Details':
        this.template.Name ? isValid = true : [isValid, errorMessage] = [false, 'Template Name Required'];
        break;
      default:
        isValid = true;
        break;
    }

    isValid ? this.stepper.next() : this.toastr.error(errorMessage)
  }

  private customerSelect(e) {
    this.template.Customer = Object.assign({}, {
      Id: e,
      BillingGroup: null,
      Name: null
    })
  }

  private filterGitServiceProvider(item: Miscellaneous): boolean {
    return parseInt(item.id) >= 2;
  }

  private getRunTemplateCount(templateId: string): void {

    // Load run templates linked
    const templateFilter = {
      template: {
        id: { eq: templateId }
      }
    };

    this.runTemplateService.runTemplateContext(templateFilter, 500).subscribe(items => {
      this.runTemplateCount = items.length;
      this.linkedItems = items;
    });

  }

  private getTemplateSourceClassType(repoType: number) {
    switch (repoType) {
      case 0:
        this.toastr.error("Unsupported repository type: Custom");
        throw new Error("Unsupported repository type: Custom");
      case 1:
        this.toastr.error("Unsupported repository type: Bonobo22");
        throw new Error("Unsupported repository type: Bonobo22");
      case 2:
        return "BonoboGitTemplateSource";
      case 3:
        return "GitLabTemplateSource";

      default:
        this.toastr.error(`Unsupported repository type: ${repoType}`);
        throw new Error(`Unsupported repository type: ${repoType}`);
    }
  }

  //#endregion
}
