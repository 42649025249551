
export interface TemplateSourceModel {
    Server: string;
    User: string;
    Password: string;
    Branch: string;
    Project: string;
    Folder: string;
    Type: string;
    TemplateRepositoryType: number;
    Group: string;    
    IsAutoCreated: boolean;
}
