import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'ifs-one-time-pin',
  templateUrl: './one-time-pin.component.html',
  styleUrls: ['./one-time-pin.component.scss']
})
export class OneTimePinComponent implements OnInit, OnChanges {
  // error = false;
  oneTimePin;

  @Input() otpState;
  @Input() error;
  @Output() requestOTP = new EventEmitter<any>();
  @Output() validateOTP = new EventEmitter<any>();
  @Output() resendOTP = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    // console.log('dataPrompt init', this.dataPrompts);
  }

  requestOTPevent() {
    this.requestOTP.emit();
  }
  validateOTPevent() {
    this.validateOTP.emit(this.oneTimePin);
  }
  resendOTPevent() {
    this.resendOTP.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (
    //   changes.dataPrompts.currentValue !== changes.dataPrompts.previousValue
    // ) {
    //   console.log('dataPrompt changes', changes.dataPrompts.currentValue);
    //   this.dataPromptAnswers = [];
    // }
  }

  onSubmit() {
    // console.log('answers', this.dataPromptAnswers);
    // if (!this.dataPromptAnswers.length) {
    //   this.error = true;
    //   return;
    // }
    // this.submitDataPrompts.emit(this.dataPromptAnswers);
  }
}
