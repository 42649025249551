import { PasswordConfirmFormComponent } from './components/password-confirm-form/password-confirm-form.component';
import { NebularModule } from '@InfoSlips/nebular';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './containers/login/login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoggedOutViewComponent } from './components/logged-out-view/logged-out-view.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { RegisterComponent } from './containers/register/register.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { RegisterConfirmComponent } from './containers/register-confirm/register-confirm.component';
import { RegisterConfirmFormComponent } from './components/register-confirm-form/register-confirm-form.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { VerifyDetailComponent } from './containers/verify-detail/verify-detail.component';
import { VerifyDetailFormComponent } from './components/verify-detail-form/verify-detail-form.component';
import { AuthService, TokenInterceptor } from '@InfoSlips/iiab-auth';
import { AuthGuard } from './services/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SsoComponent } from './containers/sso/sso.component';

export const authRoutes: Route[] = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { animation: 'auth' } },
  { path: 'logout', component: LoginComponent, data: { animation: 'auth' } },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register/confirm/:token', component: RegisterConfirmComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'verify-detail/:token', component: VerifyDetailComponent },
  { path: 'sso', component: SsoComponent },
];

const COMPONENTS = [
  LoginComponent,
  LoginFormComponent,
  LoggedOutViewComponent,
  ForgotPasswordComponent,
  ForgotPasswordFormComponent,
  RegisterComponent,
  RegisterFormComponent,
  RegisterConfirmComponent,
  RegisterConfirmFormComponent,
  ResetPasswordComponent,
  PasswordConfirmFormComponent,
  VerifyDetailComponent,
  VerifyDetailFormComponent,
  SsoComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NebularModule
  ],
  declarations: [
    COMPONENTS
    // RegisterComponent,
    // RegisterFormComponent,
    // RegisterConfirmComponent,
    // RegisterConfirmFormComponent,
    // ForgotPasswordComponent,
    // ForgotPasswordFormComponent
  ],
  exports: [
    COMPONENTS
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthService
  ]
})

export class AuthModule {}