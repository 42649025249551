import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsEntityModel, CmsEntitySummary } from '@InfoSlips/models';
import { Observable } from 'rxjs';
import { CmsGridService } from '../../services/cms-grid.service';
import { CmsService } from '../../services/cms.service';
import { SelectedEntityService } from '../../services/selected-entity.service';

@Component({
  selector: 'cms-entities',
  templateUrl: './cms-entities.component.html',
  styleUrls: ['./cms-entities.component.scss']
})
export class CmsEntitiesComponent implements OnInit {

  showFilter=true;
  searchPhrase:string|null;
  activeTab='All';
  selectedEntity:CmsEntityModel;
  cmsEntities:Array<CmsEntitySummary> =[];
  cmsEntitiesCount$: Observable<number> =  this.cmsGridService.cmsService.cmsEntities.totalCount$;
  cmsRecent:Array<CmsEntitySummary> =[];
  cmsRecentCount$: Observable<number> =  this.cmsGridService.cmsRecents.totalCount$;
  isLoading$ = new Observable<boolean>();

  constructor(
    private cmsGridService: CmsGridService,
    private selectedEntityService: SelectedEntityService,
    private router: Router,
    private cmsService: CmsService
  ) { 
  }

  ngOnInit(): void {
    this.selectedEntityService.selectedEntity$.subscribe(entity=>{   
      
      if(entity==null)
        return;

      this.selectedEntity = this.cmsService.lowerCaseObjectKeys(entity);
      //@ts-ignore
      this.selectedEntity.cmsEntityType = this.cmsService.getEntityTypeString(this.selectedEntity.cmsEntityType);
      //@ts-ignore
      this.selectedEntity.cmsContentType = this.cmsService.getContentTypeString(this.selectedEntity.cmsContentType);
      
    });

    this.isLoading$ = this.cmsGridService.isLoading$
    this.cmsGridService.cmsService.cmsEntities$.subscribe(entities=>{      
      this.cmsEntities=entities;
    });
    this.cmsGridService.cmsRecents$.subscribe(entities=>{
      this.cmsRecent=entities;
    });
  }

  toggleFilter(){
    this.showFilter = !this.showFilter;
  }

  changeListType(selectedTab:any){   
    this.activeTab = selectedTab.tabTitle;
    if(this.activeTab==='Recent' && this.cmsRecent.length===0){
      this.cmsGridService.loadRecent();
    }
      
  }

  onScroll() {
    if(this.activeTab=='All'){ // Only expect scrolling on the All tab
      this.cmsGridService.loadEntitiesNextPage();
    }
  }

  selectEntity(entity: CmsEntitySummary){
    this.router.navigateByUrl(`cms/entity/${entity.id}`);
    this.selectedEntityService.setEntity(entity.id);
  }
}
