import { Component, Input, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { EnvironmentService } from '@InfoSlips/env';
import { CmsService } from '../../services/cms.service';
import { ToastrService } from 'ngx-toastr';
import { CmsEntity, CmsEntityReference, CmsRevision, RunTemplateSummary } from '@InfoSlips/models';
import { NbDialogService } from '@nebular/theme';

enum CmsVersionType {
  Draft,
  Latest,
  SpecificVersion
}

@Component({
  selector: 'entity-card-component',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss']
})
export class EntityCardComponent implements OnInit {

  @Input() cmsEntity: CmsEntityReference = null;
  @Input() selected = false;
  @Input() type = 'general';
  @Input() selectedRunTemplate: RunTemplateSummary;
  @Input() readOnly = false;
  @Output() modifiedEntity: EventEmitter<CmsEntityReference> = new EventEmitter();
  @Output() entityRemovedEvent: EventEmitter<CmsEntityReference[]> = new EventEmitter();
  @Output() entityAddEvent: EventEmitter<CmsEntityReference[]> = new EventEmitter();

  dialogRef;
  cmsRevisions: CmsRevision[] = [];
  cmsOriginal: CmsEntityReference = null;
  isDirty=false;
  originalVersionType= CmsVersionType.Latest;

  parsedContentType: number;
  contentIcon: string;
  baseUrl: string;
  hasContentIcon = false;
  linkIcon = 'link';
  availableIcon = 'plus-outline';

  entityRevision = null;
  selectedVersionType = CmsVersionType.Latest;
  linkErrorMessage: string = null

  constructor(
    private environment: EnvironmentService,
    private cmsService: CmsService,
    private dialogService: NbDialogService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    this.baseUrl = this.environment.baseUrl;
    this.parsedContentType = this.cmsService.getContentType(this.cmsEntity.cmsContentType);
    this.contentIcon = this.cmsService.getContentIcon(this.cmsEntity.cmsContentType);

    if (this.contentIcon != 'none') {
      this.hasContentIcon = true;
    }

    this.setLinkedState();
  }

  addEntity(cmsEntity: CmsEntity) {
    let exists = false;
    let foundDuplicate = false;

    const preventDuplicatesOf = ['EMAIL_BODY', 'EMAIL_SUBJECT', 'SMS_BODY'];

    this.selectedRunTemplate.cmsEntities.map(entity => {
      if (entity.id === cmsEntity.id) {
        exists = true;
      }
      // Duplicates not allowed
      if (preventDuplicatesOf.includes(cmsEntity.cmsContentType.toString())) {
        if (entity.cmsContentType === cmsEntity.cmsContentType) {
          foundDuplicate = true;
        }
      }
    });

    if (foundDuplicate) {
      this.toastr.info(`Duplicate ${cmsEntity.cmsContentType} detected.`);
      return;
    }

    const cmsEntityReference = this.cmsService.toCmsEntityReference(cmsEntity);

    if(!exists){
      this.selectedRunTemplate.cmsEntities.unshift(cmsEntityReference);
      this.entityAddEvent.emit(this.selectedRunTemplate.cmsEntities);
    } else {
      this.toastr.info('This entity is already linked.');
    }
  }

  removeEntity(cmsEntity: CmsEntityReference) {
    this.selectedRunTemplate.cmsEntities = this.selectedRunTemplate.cmsEntities.filter(linkedEntity => {
      if(linkedEntity.id != cmsEntity.id){
        return linkedEntity
      }
    });
    this.entityRemovedEvent.emit(this.selectedRunTemplate.cmsEntities);
  }

  compareByVersion(v1, v2): boolean {
    return v1 === v2;
  }

  selectVersion(e) {
    this.cmsEntity.version = e;
    this.isDirty=true;
    this.modifiedEntity.emit(this.cmsEntity);
  }

  loadRevisions() {
    this.cmsService.getCmsRevisions(this.cmsEntity.publicId).subscribe(revisions => {

      if(revisions==null || revisions.length == 0)
        return;

      this.linkErrorMessage = null;

      revisions.forEach(revision => {
        if (this.cmsRevisions.indexOf(revision) === -1)
          this.cmsRevisions.push(revision);
      });

      this.linkErrorMessage=null;
      if (this.cmsRevisions.length == 0 && this.selectedVersionType == CmsVersionType.SpecificVersion) {
        this.linkErrorMessage = 'No versions loaded for the selected';
      }
    });
  }

  toggleSelectedVersionType(event: CmsVersionType, selectedVersion?: number, fromSetState?: boolean) {
    this.linkErrorMessage = null;

    if(!fromSetState)
      this.isDirty=true;

    switch (event) {
      case CmsVersionType.Draft:
        this.cmsEntity.isDraft = true;
        this.cmsEntity.version = -1;
        this.cmsEntity.lockLatestVersion = false;
        break;
      case CmsVersionType.Latest:
        this.cmsEntity.isDraft = false;
        this.cmsEntity.version = 0;
        break;
      case CmsVersionType.SpecificVersion:
        this.cmsEntity = {...this.cmsEntity, ...{
          isDraft: false,
          version: selectedVersion ?? 1,
          lockLatestVersion: false
        }}
        this.loadRevisions();
        break;
      default:
        break;
    }

    if(this.isDirty)
      this.modifiedEntity.emit(this.cmsEntity);
  }

  resetLinked():void{
    this.cmsEntity = Object.assign({}, this.cmsOriginal);    
    this.isDirty=false;
    this.setLinkedState(true);
  }

  async displayEntityInfo(ref: TemplateRef<any>, entityId: string) {
    const cmsEntity = await this.cmsService.getCmsEntity(entityId);
    
    this.openEntityInfoDialog(ref, cmsEntity)
  }

  //#region Private
  private setLinkedState(isReset:boolean=false): void {
    if (this.type !== 'linked')
      return;

    if(!isReset){  
      // Clone cmsEntity to have a before and after    
      this.cmsOriginal = Object.assign({}, this.cmsEntity);
      if(this.cmsOriginal.version===-1)
        this.cmsEntity.isDraft=true;
    }

    if (!this.cmsEntity.isDraft) {
      if (this.cmsEntity.version == 0) {
        this.selectedVersionType = CmsVersionType.Latest;
        this.toggleSelectedVersionType(CmsVersionType.Latest, null, true);
        return;
      }
      this.cmsRevisions.push({
        id: this.cmsEntity.id,
        name: this.cmsEntity.name,
        display: this.cmsEntity.name,
        version: this.cmsEntity.version
      });
      this.selectedVersionType = CmsVersionType.SpecificVersion;
      this.toggleSelectedVersionType(CmsVersionType.SpecificVersion, this.cmsEntity.version, true);
    }
    else {
      this.selectedVersionType = CmsVersionType.Draft;
      this.toggleSelectedVersionType(CmsVersionType.Draft, null, true);
    }

    if(!isReset){
      this.originalVersionType = this.selectedVersionType;
    }
  }

  private openEntityInfoDialog(ref: TemplateRef<any>, entityDetails){
    console.log("cmsEntityDetails",entityDetails)
    this.dialogRef = this.dialogService.open(ref, { context: entityDetails});
  }
  //#endregion
}
