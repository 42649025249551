import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { Country } from '@InfoSlips/models';

@Component({
  selector: 'ifs-personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['./personal-details-form.component.scss']
})
export class PersonalDetailsFormComponent implements OnInit, OnDestroy {
  add = "";
  subscription: any;
  @Output()
  save = new EventEmitter<any>();
  @Output()
  addAdditionalItem = new EventEmitter<any>();
  @Input()
  profileData: Observable<any>;
  @Input()
  countries: Country[];
  
  @ViewChild('dialog') private dialog: TemplateRef<any>;
  
  profileModel: Observable<any>;
  profileUpdateForm: FormGroup;
  addInfoSlipDetailForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  mobile: {};
  userEmails = [];
  dialogRef;

  constructor(private fb: FormBuilder, private dialogService: NbDialogService) {}

  get mobileNumbers(): FormArray {
    return this.profileUpdateForm.get('MobileNumbers') as FormArray;
  }
  setMobileNumbers(mobileNumbers: any[]) {
    const mobileFormArray = this.mobileNumbers;
    mobileNumbers.forEach(mobile => {
      mobileFormArray.push(this.fb.group({
        Number: [mobile.Number, Validators.required],
        CountryCode: [mobile.CountryCode],
        CountryPrefix: [mobile.CountryPrefix]
      }));
    });
  }

  ngOnInit() {
    this.add = "";
    this.profileModel = this.setProfile();

    this.profileUpdateForm = this.fb.group({
      DisplayName: ['', Validators.required],
      Username: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Mobile: ['',Validators.required],
      UserEmails: [],
      MobileNumbers: this.fb.array([])
    });

    this.addInfoSlipDetailForm = this.fb.group({
      Email: ['', [Validators.required, Validators.email]],
      Number: ['', [Validators.required]],
      CountryCode: [''],
      CountryPrefix: 0
    });
  }

  setProfile(){
    return this.profileData.pipe(
      tap(data => {
        const [profileInfo, countries] = data;
        this.countries = countries;
        const patchObj = {
          DisplayName: profileInfo.DisplayName,
          Username: profileInfo.UserName,
          Email: profileInfo.Email,
          UserEmails: profileInfo.UserEmails,
          Mobile: profileInfo.Mobile ? profileInfo.Mobile.Number : null,
          MobileNumbers: profileInfo.MobileNumbers
        };
        this.userEmails = profileInfo.UserEmails ? profileInfo.UserEmails : [];
        this.mobile = profileInfo.Mobile ? profileInfo.Mobile.Number : null;
        this.profileUpdateForm.patchValue(patchObj);
        
        this.setMobileNumbers(patchObj.MobileNumbers);
      })
    );
  }

  ngOnDestroy(): void {
    // this.subscription.unsubcribe();
  }

  addItem(type) {
    if(type==='email'){
      this.addInfoSlipDetailForm.controls.Email.setValidators([Validators.required, Validators.email]);
      this.addInfoSlipDetailForm.controls.Number.setValidators([]);
    }else{
      this.addInfoSlipDetailForm.controls.Number.setValidators([Validators.required, Validators.minLength(9)]);
      this.addInfoSlipDetailForm.controls.Email.setValidators([]);
    }
    this.addInfoSlipDetailForm.reset();
    this.add = type;
    // this.dialogService.open(this.dialog);
    this.dialogRef = this.dialogService.open(this.dialog);
  }

  createMobile(obj) {
    const mobile = {
      Number: [obj.Number],
      CountryCode: [obj.CountryCode],
      CountryPrefix: obj.CountryPrefix,
      DisplayName: "",
      Email: ""
    };

    return this.fb.group(mobile);
  }

  addInfoSlipDetail() {
    if(this.add==='mobile' && this.createMobile(this.addInfoSlipDetailForm.value).value.Number){
      this.addAdditionalItem.emit({Detail: this.addInfoSlipDetailForm.value.Number, Country: this.addInfoSlipDetailForm.value.CountryCode})
      this.addInfoSlipDetailForm.reset();
      this.add = "";
      this.close();
    } else if(this.add==='email' && this.addInfoSlipDetailForm.value.Email){
      this.profileUpdateForm.value.UserEmails.push(this.addInfoSlipDetailForm.value.Email);
      this.addAdditionalItem.emit({Detail: this.addInfoSlipDetailForm.value.Email})
      this.addInfoSlipDetailForm.reset();
      this.add = "";
      this.close();
    }else{
      this.error = '';
    }
  }

  onSubmit() {
    this.save.emit(this.profileUpdateForm.value);

    setTimeout(() => {
      this.profileModel = this.setProfile();
      this.addInfoSlipDetailForm.reset();
      this.profileUpdateForm.reset();
    }, 100);

  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { autoFocus: false });
  }

  close() {
    this.dialogRef.close();
  }

  get Email() {
    return this.addInfoSlipDetailForm.get('Email');
  }

  closeModal(ref){
    ref.close();
  }

}