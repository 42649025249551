import { Injectable } from '@angular/core';
import { Miscellaneous, MiscService } from '@InfoSlips/iiab-api';
import { IiabIdName } from '@InfoSlips/models';
import { Observable, BehaviorSubject } from 'rxjs';


export interface FilterObject {
  id?: any,
  name?: any,
  runTemplate?: any,
  customer?: any,
  created?: any,
  instance?: any,
  isTrial?: any
}

interface DateRange {
  start?: Date,
  end?: Date
}

@Injectable({
  providedIn: 'root'
})
export class RunFilterService {
  
  filterObjectSubject: BehaviorSubject<FilterObject> = new BehaviorSubject<FilterObject>({});
  progressStatusCodes$: Observable<Miscellaneous[]> = this.miscService.miscellaneousContext('ProgressStatusCodes');
  runStatusOptions: string[];
  runStateOptions = ["Trial and Live","Trial Only","Live Only"];
  searchPhrase: string;
  selectedTemplate: IiabIdName;
  selectedCustomer: IiabIdName;
  selectedRunStatus: string[] = [];
  selectedRunState: string = this.runStateOptions[0];
  dateRange: DateRange = {
    start: null,
    end: null
  };
  customFilter: {}
  filterObject$: Observable<FilterObject> = this.filterObjectSubject.asObservable();

  userInputAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private miscService: MiscService) { 
    this.progressStatusCodes$.subscribe(codes => {
      this.runStatusOptions = codes.map(x => x.value);
    })
  }

  get runsFilterObject() {
    let filterObject: FilterObject = {};

    if (this.searchPhrase != null && this.searchPhrase.length > 0) 
      this.searchPhrase.length === 24 ? filterObject["id"] = { eq: this.searchPhrase } : filterObject["name"] = { like: this.searchPhrase };

    if (this.selectedTemplate != null && this.selectedTemplate.id) 
      filterObject['runTemplate'] = {id: { eq: this.selectedTemplate.id } };

    if (this.selectedCustomer != null && this.selectedCustomer.id) 
      filterObject['customer'] = {id: { eq: this.selectedCustomer.id } };


    if (this.dateRange.start != null) 
      filterObject['created'] = this.getDateRangeFilter();

    if (this.selectedRunStatus.length > 0) 
      filterObject["instance"] = {
        or: this.getStatusFilter()
      }

    if (this.selectedRunState != null && this.selectedRunState != "Trial and Live") 
    filterObject["isTrial"] = {
        eq: this.selectedRunState.includes("Trial") ? true : false
      };

    if (this.customFilter != null)
      filterObject = {...this.customFilter};


    this.filterObjectSubject.next(filterObject);

    
    return filterObject;
  };

  pushSelectedRunStatusValue(value: string) {
    this.selectedRunStatus.push(value.toUpperCase());
  }

  removeValueFromSelectedRunStatus(value: string) {
    this.selectedRunStatus = this.selectedRunStatus.filter(status => status != value.toUpperCase())
  }

  private getStatusFilter() {
    const statusArray: object[] = [];

    this.selectedRunStatus.forEach(item => {
      statusArray.push(
        { status: 
          { eq: item.toUpperCase() }
        }
      )
    });

    return statusArray;
  }

  private getDateRangeFilter(){
    return {
      gte: new Date(this.dateRange.start.setHours(0, 0, 0)),
      lte: new Date((this.dateRange.end === null ? this.dateRange.start : this.dateRange.end).setHours(23, 59, 59))
    }
  }
}
