<div class="runs-container">
  <div class="runs-header">
  
    <div class="run-header">
      <h4>CMS Entities</h4>
      <div #searchBox class="search-box">
        <cms-filter></cms-filter>
      </div>
    </div>
  
    <nb-tabset fullWidth (changeTab)="changeListType($event)">
      <nb-tab tabTitle="All" class="tab" [badgeText]="activeTab === 'All' ? (cmsEntitiesCount$ | async | number) : ''" badgeStatus="basic"></nb-tab>
      <nb-tab tabTitle="Recent" class="tab" [badgeText]="activeTab === 'Recent' ? (cmsRecentCount$ | async | number) : ''" badgeStatus="basic"></nb-tab>
    </nb-tabset>
      
    <div *ngIf="selectedEntity" class="selected-run">
      <cms-grid-item [cmsEntity]="selectedEntity" type="small"></cms-grid-item>
      <hr/>
    </div>
      
    <div 
      class="runs-body"
      infiniteScroll 
      [infiniteScrollDistance]="1" 
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false" 
      (scrolled)="onScroll()"
    >

      <div *ngIf="activeTab==='All'">
        <div *ngFor="let entity of cmsEntities">
          <span *ngIf="selectedEntity?.id !== entity.id">
            <cms-grid-item [cmsEntity]="entity" type="small" (click)="selectEntity(entity)"></cms-grid-item>
          </span>
        </div>
      </div>

      <div *ngIf="activeTab==='Recent'">
        <div *ngFor="let entity of cmsRecent">
          <span *ngIf="selectedEntity?.id !== entity.id">
            <cms-grid-item [cmsEntity]="entity" type="small" (click)="selectEntity(entity)"></cms-grid-item>
          </span>
        </div>
      </div>
  
      <ng-container *ngIf="(isLoading$ | async);">
        <div class="row">
          <div *ngFor="let it of ghosts" class="col-md-12">
            <ifs-ghost-cards> </ifs-ghost-cards>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>