<ifs-loading-screen *ngIf="!customer"></ifs-loading-screen>

<div class="general-page-container">
    <div class="general-page-header">
      <div class="d-flex">
        <div class="flex-1">
          <h4 class="heading">
            <nb-icon pack="oakmoon" icon="building-1"></nb-icon> 
            {{isNew ? 'Create' : 'Update'}} Customer: <b>{{customer?.Name}}</b>
          </h4>
        </div>
        <div class="text-right">
          <button *ngIf="!isNew" size="medium" outline nbButton (click)="goToRuns()" style="margin-right: 15px;">
            View Runs
          </button>
          <button size="medium" outline nbButton routerLink="/">
            Cancel
          </button>
          <button class="save" *ngIf="!isNew" size="medium" nbButton status="primary" (click)="updateCustomer()">
            Save
          </button>
          <button class="save" *ngIf="isNew" size="medium" nbButton status="primary" (click)="createCustomer()">
            Create
          </button>
        </div>
    </div>
  </div>
</div>

<nb-card *ngIf="customer">
  <nb-card-body>
    <div class="flex">
      <div class="general-side-nav">
        <div class="context-menu" *ngFor="let option of options" (click)="setMenuItem(option.title)"
          [class.active]="option.title === menuItem">
          <div class="body">
            <h4 class="title">{{ option.title }}</h4>
          </div>
          <div class="action text-center">
            <nb-icon icon="arrow-right" pack="oakmoon" size="20"></nb-icon>
          </div>
        </div>
      </div>

      <div [ngSwitch]="menuItem" class="content">
        <ng-template [ngSwitchCase]="'Customer Details'">
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <label>
                  <p>Customer Name</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.Name" (blur)="customerOnBlur(customer.Name)">

                <label>
                  <p>Archive display name</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.ArchiveDisplayName">

                <label>
                  <p>Subdomain</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.Subdomain">
                <label>
                  <p>Billing Group</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.BillingGroup">
                <label>
                  <p>Preferred SMS Senders</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.PreferedSMSSenders">
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-box">
                <p>
                  <nb-checkbox disabled [(ngModel)]="customer.EnableEmailVerification">Enable Email Verification</nb-checkbox>
                </p>
                <p>
                  <nb-checkbox [(ngModel)]="customer.IsPrivate">Disable Online View On Live Runs</nb-checkbox>
                </p>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Secure Keys'">
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <label>
                  <p>S3 Upload Access ID</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.S3UploadAccessId">
                <label>
                  <p>S3 Upload Access Key</p>
                </label>
                <ifs-secure-keys [isNew]="isNew" (updatedKey)="updateKey($event)" [secureKey]="customer.S3UploadAccessKey" [type]="'customer'" [id]="customer.Id" model="S3UploadAccessKey"></ifs-secure-keys>
              </div>

              <div class="input-box" *ngIf="customer.UseSmtpMailServer">
                <label>
                  <p>SMTP Host</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.SmtpHost">

                <label>
                  <p>SMTP Server Port</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.SmtpPort">

                <p>
                  <nb-checkbox [(ngModel)]="customer.SmtpUseSsl">SMTP Use Ssl</nb-checkbox>
                </p>

                <label>
                  <p>SMTP Login Domain</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.SmtpLoginDomain">

                <label>
                  <p>SMTP Username</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.SmtpUsername">

                <label>
                  <p>SMTP Password</p>
                </label>
                <ifs-secure-keys [isNew]="isNew" (updatedKey)="updateKey($event)" [secureKey]="customer.SmtpPassword" [type]="'customer'" [id]="customer.Id" model="SmtpPassword"></ifs-secure-keys>

                <label>
                  <p>SMTP Default From</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.SmtpDefaultFrom">
                
                <label>
                  <p>SMTP Bounce Return Email Address</p>
                </label>
                <input type="text" nbInput fullWidth [(ngModel)]="customer.SmtpBounceReturnEmailAddress">
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-box">
                <p>
                  <nb-checkbox [(ngModel)]="customer.UseSmtpMailServer">Use SMTP Mail Server</nb-checkbox>
                </p>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'Contacts'">
          <div class="input-box full">
            <table width="100%" class="input-table">
                <thead>
                    <tr>
                        <th>Contact Type</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Info</th>
                        <th>Remove</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let contact of customer.Contacts; let i = index">
                        <td>
                            {{contactTypes[contact.ContactType]}}
                        </td>
                        <td>
                            {{contact.Name}}
                        </td>
                        <td>
                            {{contact.Email}}
                        </td>
                        <td>
                            {{contact.Phone}}
                        </td>
                        <td>
                          <button nbButton [nbPopover]="contactInfo" nbPopoverTrigger="click" nbPopoverPlacement="bottom">
                            <nb-icon pack="oakmoon" icon="info-circle"></nb-icon>
                          </button>
                        </td>
                        <td>
                          <button nbButton (click)="removeItem(contact, 'Contacts')">
                              <nb-icon pack="oakmoon" icon="bin"></nb-icon>
                          </button>
                        </td>
                        <td>
                          <button nbButton (click)="editItem(contact, 'contact', i)">
                            <nb-icon icon="edit-2-outline"></nb-icon>
                          </button>
                        </td>
        
                        <ng-template #contactInfo>
                            <p *ngIf="contact.Id">Id: {{contact.Id}}</p>
                            <p *ngIf="contact.LastUpdated">Last Updated: {{contact.LastUpdated}}</p>
                            <p *ngIf="contact.LastUpdatedBy">Last Updated By: {{contact.LastUpdatedBy}}</p>
                            <p *ngIf="contact.Created">Created: {{contact.Created}}</p>
                            <p *ngIf="contact.CreatedBy">Created By: {{contact.CreatedBy}}</p>
                        </ng-template>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6">
                            <button type="button" (click)="addItem('contact')" nbButton status="primary">
                                <nb-icon icon="plus-outline" pack="eva"></nb-icon> Add Contact
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </table>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'Addresses'">
          <div class="input-box full">
            <table width="100%" class="input-table">
                <thead>
                    <tr>
                        <th>Address Type</th>
                        <th>Address</th>
                        <th>Info</th>
                        <th>Remove</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let address of customer.Addresses; let i = index">
                        <td>
                            {{addressTypes[address.AddressType]}}
                        </td>
                        <td>
                          <span *ngIf="address.Line1">{{address.Line1}},<br/></span>
                          <span *ngIf="address.Line2">{{address.Line2}},<br/></span>
                          <span *ngIf="address.Line3">{{address.Line3}},<br/></span>
                          <span *ngIf="address.City">{{address.City}},<br/></span>
                          <span *ngIf="address.Country">{{address.Country}},<br/></span>
                          <span *ngIf="address.Code">{{address.Code}}</span>
                        </td>
                        <td>
                          <button nbButton [nbPopover]="addressInfo" nbPopoverTrigger="click" nbPopoverPlacement="bottom">
                            <nb-icon pack="oakmoon" icon="info-circle"></nb-icon>
                          </button>
                        </td>
                        <td>
                          <button nbButton (click)="removeItem(address, 'Addresses')">
                            <nb-icon pack="oakmoon" icon="bin"></nb-icon>
                          </button>
                        </td>
                        <td>
                          <button nbButton (click)="editItem(address, 'address', i)">
                            <nb-icon icon="edit-2-outline"></nb-icon>
                          </button>
                        </td>
        
                        <ng-template #addressInfo>
                            <p>Id: {{address.Id}}</p>
                            <p>Last Updated: {{address.LastUpdated}}</p>
                            <p>Last Updated By: {{address.LastUpdatedBy}}</p>
                            <p>Created: {{address.Created}}</p>
                            <p>Created By: {{address.CreatedBy}}</p>
                        </ng-template>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4">
                          <button status="primary" type="button" (click)="addItem('address')" nbButton>
                              <nb-icon status="primary" icon="plus-outline" pack="eva"></nb-icon> Add Address
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'Access Tokens'">
          <div class="input-box full">
            <table width="100%" class="input-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>API Endpoint</th>
                        <th>Remove</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let accessToken of customer.AccessTokens; let i = index">
                        <td>
                          {{accessToken.Name}}
                        </td>
                        <td>
                          {{accessToken.AuthData.ApiUrl}}
                        </td>
                        <td>
                          <button nbButton (click)="removeItem(accessToken, 'AccessTokens')">
                              <nb-icon icon="bin" pack="oakmoon"></nb-icon>
                          </button>
                        </td>
                        <td>
                          <button nbButton (click)="selectTokenToEdit(accessToken, i)">
                              <nb-icon icon="edit-2-outline" pack="eva"></nb-icon>
                          </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4">
                          <button status="primary" type="button" (click)="openTokenDialogue()" nbButton>
                              <nb-icon status="primary" icon="plus-outline" pack="eva"></nb-icon> Add Access Token
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </table>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'Helpdesk Notes'">
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <label>
                  <p>
                    Notes for helpdesk users
                  </p>
                </label>
                <textarea nbInput fullWidth>{{customer.SupportInstructions}}</textarea>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'Linked Items'">
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <label>
                    Filter linked Run Templates:&nbsp; 
                </label>
                <input type="text" nbInput [(ngModel)]="runTemplateNameFiler" (input)="linkedSearch('RunTemplate', $event.target.value)" />
                <button ngInput (click)="linkedSearch('Both', null)">Load</button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-box">
                <label>
                    Filter linked Templates:&nbsp;
                </label>
                <input type="text" nbInput [(ngModel)]="templateNameFiler"  (input)="linkedSearch('Template', $event.target.value)"/>
                <button ngInput (click)="linkedSearch('Both', null)">Load</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="input-box">
                <ul>
                  <li *ngFor="let linked of linkedRunTemplates" class="pointer-cursor" routerLink="/admin/runtemplates/{{linked.id}}">
                    <a>{{linked.name}} - {{linked.id}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-box">
                <ul>
                  <li *ngFor="let linked of linkedTemplates" class="pointer-cursor" routerLink="/admin/templates/{{linked.id}}">
                    <a>{{linked.name}} - {{linked.id}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="dialog-md">
    <nb-card-header>{{data.IsNew ? 'Add' : 'Edit'}} {{add}}</nb-card-header>
    <nb-card-body>
      <div *ngIf="add === 'contact'" class="form-group">
        <form [formGroup]="AddCustomerContactForm" (ngSubmit)="addCustomerDetail($event)">

          <nb-select (onChange)="onContactTypeChange($event)" fullWidth formControlName="ContactType" name="ContactType" placeholder="Select Type">
            <nb-option *ngFor="let type of contactTypes" [value]="contactTypes.indexOf(type)">{{type}}</nb-option>
          </nb-select>
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="Name" required
            placeholder="Name" />
          <input nbInput fullWidth fieldSize="medium" type="email" formControlName="Email" required
            placeholder="Email" />
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="Phone"
            placeholder="Phone" />
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="ContactTypeDescription"
            required placeholder="Description" />
        </form>
      </div>


      <div class="form-group" *ngIf="add === 'address'">
        <form [formGroup]="AddCustomerAddressForm" class="form-padding" (ngSubmit)="addCustomerDetail($event)">
          <nb-select fullWidth formControlName="AddressType" name="AddressType" placeholder="Select Type">
            <nb-option *ngFor="let type of addressTypes" [value]="addressTypes.indexOf(type)">{{type}}</nb-option>
          </nb-select>
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="Line1" required
            placeholder="Line1" />
          <input *ngIf="AddCustomerAddressForm.get('Line1').value" nbInput fullWidth fieldSize="medium" type="text" formControlName="Line2"
            placeholder="Line2" />
          <input *ngIf="AddCustomerAddressForm.get('Line2').value" nbInput fullWidth fieldSize="medium" type="text" formControlName="Line3"
            placeholder="Line3" />
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="City" required
            placeholder="City" />
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="Country" required
            placeholder="Country" />
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="Code" required
            placeholder="Code" />
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="AddressTypeDescription"
            required placeholder="Description" />
        </form>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button type="button" nbButton outline (click)="ref.close()">
        Cancel
      </button>
      <button *ngIf="add === 'contact'" [disabled]="!AddCustomerContactForm.valid" nbButton status="primary"
        (click)="addCustomerContact(data?.index)">
        {{data.IsNew ? 'Add' : 'Edit'}}
      </button>
      <button *ngIf="add === 'address'" [disabled]="!AddCustomerAddressForm.valid" nbButton status="primary"
        (click)="addCustomerAddress(data?.index)">
        {{data.IsNew ? 'Add' : 'Edit'}}
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #accessToken let-data let-ref="dialogRef">
  <nb-card class="dialog-token">
    <nb-card-header>{{data.token.IsNew ? 'Add' : 'Edit'}} Access Token</nb-card-header>
    <nb-card-body>
      <div class="form-group">
        <form class="form-padding" [formGroup]="AddAccessTokenForm" (ngSubmit)="data.token.IsNew ? addToken($event) : ref.close()">
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="Name" required placeholder="Token Name" [(ngModel)]="data.token.Name"/>
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="HostUrl" required placeholder="Host Endpoint" [(ngModel)]="data.token.AuthData.HostUrl"/>
          <input nbInput fullWidth fieldSize="medium" type="text" formControlName="ApiUrl" required placeholder="API Endpoint" [(ngModel)]="data.token.AuthData.ApiUrl"/>

          <label>
            <p>Auth Parameters</p>
          </label>
          <div class="row">
            <ng-container *ngIf="(data.token.AuthData.Parameters | json) != '{}'; else noParams">
              <ng-container *ngFor="let key of objectKeys(data.token.AuthData.Parameters)">
                <div class="col-md-5">
                  <input nbInput fullWidth fieldSize="medium" type="text" required placeholder="Parameter" [ngModel]="key" (blur)="changeParameterKey($event.target.value, data.token.AuthData.Parameters, key)" [ngModelOptions]="{standalone:true}"/>
                </div>
                <div class="col-md-5">
                  <input nbInput fullWidth fieldSize="medium" type="text" required placeholder="Value" [(ngModel)]="data.token.AuthData.Parameters[key]" [ngModelOptions]="{standalone:true}" />
                </div>
                <div class="col-md-2">
                  <button class="nav-button danger pull-left" nbButton (click)="removeParameter(data.token.AuthData.Parameters, key)">
                    <nb-icon icon="bin" pack="oakmoon"></nb-icon>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noParams>
              <p class="col-md-12">No Parameters.</p>
            </ng-template>
          </div>
         
        </form>
        <hr/>
        <br/>
        <button class="nav-button danger pull-left" nbButton (click)="addParameter(data.token.AuthData.Parameters)">
          <nb-icon icon="plus-outline" pack="eva"></nb-icon>
          Add Parameter
        </button>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button type="button" nbButton outline (click)="ref.close()">
        Cancel
      </button>
      <button *ngIf="data.token.IsNew" [disabled]="!AddAccessTokenForm.valid" nbButton status="primary"
        (click)="data.token.IsNew ? addToken(data) : ref.close()">Add
      </button>
      <button *ngIf="!data.token.IsNew" [disabled]="!AddAccessTokenForm.valid" nbButton status="primary"
        (click)="!data.token.IsNew ? editExistingToken(data.token, data.index) : ref.close()">Edit
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>