<div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Date</th>
        <th>User Agent</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of (reportDetails$ | async)">
        <td>{{ field?.created }}</td>
        <td>{{ field?.userAgent }}</td>
      </tr>
    </tbody>
  </table>

  <div class="row action-buttons">
    <div>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasPreviousPage" nbButton (click)="pageReport('previous')">Previous</button>
      <button [disabled]="!(reportDetailsPaging$ | async)?.pageInfo?.hasNextPage"  nbButton (click)="pageReport('next')">Next</button>
    </div>
    <p>{{(reportDetailsPaging$ | async)?.totalCount}} Items</p>
  </div>
</div>