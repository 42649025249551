<div class="general-page-container">
    <div class="file-list">
        <form class="" (ngSubmit)="onSubmit()" [formGroup]="runUploadForm">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                    <h6 class="no-margin mb-10">Run Files</h6>
                </div>
            </div>
            <div *ngFor="let file of runFiles; let i = index" class="upload-box row" [class.loaded]="file.Size > 0 && !file.progress" (drop)="onDrop($event, file.FileName, 'required')" (dragover)="onDragOver($event)" >
                <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                    <p class="text">
                        <nb-icon *ngIf="file.UploadedBy && file.Size > 0" icon="checkmark-circle-outline" class="uploaded"></nb-icon>
                        <nb-icon *ngIf="!file.UploadedBy && file.Size > 0" class="info" icon="clock-outline"></nb-icon>
                        <nb-icon *ngIf="file.IsRequired && file.Size === 0" class="warning" icon="alert-triangle-outline"></nb-icon>
                        <span class="file-status">{{ file.FileName }}</span> <span class="description" *ngIf="file.Description">
                            <span class="dash">- </span>{{ file.Description }}</span>
                        <nb-tag-list>
                            <nb-tag *ngIf="file.IsRequired" status="warning" appearance="outline" text="Required*" size="small"></nb-tag>
                        </nb-tag-list>
                    </p>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-md-4">
                    <ng-container *ngIf="file.progress > 0">
                        <div class="upload-progress">
                            <nb-progress-bar [value]="file.progress" status="success">{{ file.message }}</nb-progress-bar>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="file.Size === 0 || !file.Size">
                        <div class="file-actions flex-right">
                            <span>
                                <input class="black" type="file" id="{{file.FileName}}" (change)="fileChange($event, file.FileName, 'required')"/>
                                <label class="choose-file" nbButton outline for="{{file.FileName}}">Choose File
                                </label>
                            </span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="file.Size > 0 && !file.progress">
                        <div class="file-actions">
                            <div class="action">
                                <span *ngIf="file.Size >= 1000">{{ ((file.Size / 1024 / 1024) | number: '.0-2') }} MB</span>
                                <span *ngIf="file.Size < 1000">{{ ((file.Size / 1024 ) | number: '.0-2') }} KB</span>
                            </div>
                            <div class="action" *ngIf="file.UploadedBy">
                                <nb-icon class="action download" pack="oakmoon" icon="common-file-download" (click)="downloadFile(file.FileName)"></nb-icon>
                            </div>
                            <div class="action" *ngIf="file.UploadedBy">
                                <nb-icon class="action danger" pack="oakmoon" icon="bin" (click)="file.FileName == 'ConfigForProcessing' ? openConfirmationDialog(confirmDeleteDialog, file.FileName)  : remove(file.FileName, 'required')"></nb-icon>
                            </div>
                            <div class="action" *ngIf="!file.UploadedBy">
                                <nb-icon class="action danger" pack="oakmoon" icon="remove" (click)="cancelFileUpload(file.FileName, 'required')"></nb-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
            <nb-accordion>
                <nb-accordion-item>
                    <nb-accordion-item-header>Attachments</nb-accordion-item-header>
                    <nb-accordion-item-body>
                        <h6 class="no-margin mb-10">Additional Attachments</h6>
                        <ng-container *ngFor="let file of additionalAttachments">
                            <div *ngIf="file.UploadedBy != null || file.Queued" class="upload-box row" (drop)="onDrop($event, file.FileName,'attachment')" (dragover)="onDragOver($event)">
                            
                                <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                                    <p>
                                        {{file.FileName}}<span *ngIf="file.Size === 0">*</span>
                                    </p>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-md-4">
                                    <ng-container *ngIf="file.progress > 0">
                                        <div class="upload-progress">
                                            <nb-progress-bar [value]="file.progress" status="success">{{ file.message }}</nb-progress-bar>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="file.Size === 0">
                                        <div class="file-actions flex-right">
                                            <span>
                                                <input type="file" id="{{file.FileName}}" (change)="fileChange($event, file.FileName, 'attachment')"/>
                                                <label class="choose-file" nbButton outline for="{{file.FileName}}">Choose File
                                                </label>
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="file.Size > 0 && !file.progress">
                                        <div class="file-actions">
                                            <div class="action">
                                                <span *ngIf="file.Size > 0">{{ file.Size / 1024 / 1024 | number }} MB</span>
                                            </div>
                                            <div class="action">
                                                <nb-icon pack="oakmoon" icon="common-file-download" (click)="downloadFile(file.FileName)"></nb-icon>
                                            </div>
                                            <div class="action">
                                                <nb-icon pack="oakmoon" icon="bin" (click)="remove(file.FileName, 'attachment')"></nb-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                        
                            </div>
                        </ng-container>
                        <div class="upload-box row" (drop)="onDrop($event, null, 'attachment')" (dragover)="onDragOver($event)">
                            <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                                <p>
                                    New Additional Attachment
                                </p>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-4 col-md-4">
                                <div class="file-actions flex-right">
                                    <span>
                                        <input type="file" id="files" (change)="fileChange($event, null, 'attachment')"/>
                                        <label class="choose-file" nbButton outline for="files">Choose File
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                        <h6 class="no-margin mb-10">Recipient Attachments</h6>
                        <ng-container *ngFor="let file of recipientAttachments">
                            <div *ngIf="file.UploadedBy != null || file.Queued" class="upload-box row" (drop)="onDrop($event, file.FileName,'recipientattachment')" (dragover)="onDragOver($event)">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                                    <p>
                                        {{file.FileName}}
                                    </p>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-md-4">
                                    <ng-container *ngIf="file.progress > 0">
                                        <div class="upload-progress">
                                            <nb-progress-bar [value]="file.progress" status="success">{{ file.message }}</nb-progress-bar>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="file.Size === 0">
                                        <div class="file-actions flex-right">
                                            <span>
                                                <input type="file" id="{{file.FileName}}" (change)="fileChange($event, file.FileName, 'recipientattachment')"/>
                                                <label class="choose-file" nbButton outline for="{{file.FileName}}">Choose File
                                                </label>
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="file.Size > 0 && !file.progress">
                                        <div class="file-actions">
                                            <div class="action">
                                                <span *ngIf="file.Size > 0">{{ file.Size / 1024 / 1024 | number }} MB</span>
                                            </div>
                                            <div class="action">
                                                <nb-icon pack="oakmoon" icon="common-file-download" (click)="downloadFile(file.FileName)"></nb-icon>
                                            </div>
                                            <div class="action">
                                                <nb-icon pack="oakmoon" icon="bin" (click)="remove(file.FileName, 'recipientattachment')"></nb-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <div class="upload-box row" (drop)="onDrop($event, null, 'recipientattachment')" (dragover)="onDragOver($event)">
                            <div class="col-xs-12 col-sm-12 col-md-8 col-md-8">
                                <p>
                                    New Recipient Attachment
                                </p>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-4 col-md-4">
                                <div class="file-actions flex-right">
                                    <span>
                                        <input type="file" id="recipientFiles" (change)="fileChange($event, null, 'recipientattachment')"/>
                                        <label class="choose-file" nbButton outline for="recipientFiles">Choose File
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </nb-accordion-item-body>
                </nb-accordion-item>
            </nb-accordion>
            
            <div class="spacer"><br /></div>
        </form>
    </div>
    <div class="general-page-footer">
        <button (click)="uploadFiles()" nbButton status="primary" style="margin-right: 12px" [disabled]="!filesAdded">Upload</button>
        <button [disabled]="!hasAllRequiredFiles || filesAdded || processStage === 'COMPLETE'" (click)="processFiles()" nbButton status="primary" [nbSpinner]="processing || processStage === 'PROCESSING' || processStage === 'PENDING'">Process</button>
    </div>
</div>


<ng-template dialogClass="confirm-delete-dialog" #confirmDeleteDialog let-data let-ref="dialogRef">
    <nb-card class="confirm-delete-dialog">
        <nb-card-header class="confirmDeleteHeader">
            Confirm <nb-icon (click)="dialogRef.close()" icon="close" class="close"></nb-icon>
        </nb-card-header>
        <nb-card-body class="confirmDeleteBody">
            <p>Are you sure you want to delete the configuration file for this run.</p>
            <p>This Run Template requires a configuration file in order to process.</p>
        </nb-card-body>
        <nb-card-footer class="confirmDeleteFooter">
            <button fullWidth nbButton status="warning" (click)="remove(data, 'required')">Delete</button>
            <button fullWidth nbButton status="basic" (click)="dialogRef.close()">Cancel</button>
        </nb-card-footer>
    </nb-card>
</ng-template>