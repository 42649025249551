import { Component, Input, OnInit } from '@angular/core';
import { RunModel } from '@InfoSlips/models';
import { SelectedRunService } from '@InfoSlips/iiab-api';

@Component({
  selector: 'ifs-run-report',
  templateUrl: './run-report.component.html',
  styleUrls: ['./run-report.component.scss']
})
export class RunReportComponent implements OnInit {
  @Input() selectedRunService: SelectedRunService;
  
  selectedReport = "deliveryReport";
  selectedRun: RunModel;

  constructor(){ }

  ngOnInit(){
    this.selectedRunService.selectedRun$.subscribe(run=>this.selectedRun=run);
  }

  stageStatus(stage){
    const runStatusCodes = ["Created", "Scheduled", "Processing", "Complete", "Failed", "Cancelled", "Processed", "Resetting", "Expired"]
    return runStatusCodes[stage.Status]
  }

  
}
