<h5>{{Channel}}</h5>
<table class="padded-table">
    <thead>
        <tr>
            <th>Sequence</th>
            <th>Is Sent</th>
            <th>Is Delivered</th>
            <th>Date</th>
            <th>Error</th>
            <th>Files</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of RunRecipientOutputChannel">
            <td>{{item.sequence}}</td>
            <td>
                <!-- <span class="circle" *ngIf="recipientConfig?.email" nbPopover="This recipient has Email send options enabled."
            nbPopoverTrigger="hover" nbPopoverPlacement="top">Email</span> -->
                <nb-icon pack="eva" [icon]="item.isSent ? 'checkmark-circle-2' : 'close-circle'"></nb-icon>
            </td>
            <td>
                <nb-icon pack="eva" [icon]="item.isDelivered ? 'checkmark-circle-2' : 'close-circle'"></nb-icon>
            </td>
            <td>{{item.sendDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            <td>{{item.resultMessage}}</td>
            <td>
                <ul>
                    <li *ngFor="let file of item.filesSent">
                        {{file?.fileName}} | {{file?.size}} ({{getPages(file?.pages)}})
                    </li>
                </ul>
            </td>
            <td>
                <ng-container *ngIf="Channel == 'SMS' || Channel == 'Email'">
                    <nb-radio-group [(ngModel)]="SelectedAction[item.sequence]" [(value)]="SelectedAction[item.sequence]" name="selectedActionGroup">
                        <nb-radio *ngIf="AllowResend" value="Resend">Resend</nb-radio>
                        <nb-radio *ngIf="AllowForward" value="Forward">Forward</nb-radio>
                    </nb-radio-group>
                    <input *ngIf="AllowForward && SelectedAction[item.sequence] == 'Forward'" nbInput [placeholder]="ForwardPlaceHolder" #forwardInput [(ngModel)]="forwardLocation[item.sequence]"/>
                    <button nbButton *ngIf="AllowResend || AllowForward" [disabled]="SelectedAction[item.sequence] != 'Resend' && (!forwardLocation[item.sequence] || forwardLocation[item.sequence]?.length == 0)" (click)="send(item.sequence)">Send</button>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>
<div *ngIf="StaticRecipientAttachments?.length>0">
    <ul>
        Static Recipient Attachments:
        <li *ngFor="let attachment of StaticRecipientAttachments">
            {{attachment.fileName}} | {{attachment.size}}
        </li>
    </ul>
</div>
<div *ngIf="StaticRunAttachments?.length>0">
    <ul>
        Static Run Attachments:
        <li *ngFor="let attachment of StaticRunAttachments">
            {{attachment.FileName}} | {{attachment.Size}}
        </li>
    </ul>
</div>